import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuPanelService } from './menu-panel.service'
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

declare var d3:any;


import { Router } from "@angular/router";
import { NavigationStart, Event as NavigationEvent } from '@angular/router';
import { Location } from '@angular/common';

@Component({
	selector: 'app-menu-panel',
	templateUrl: './menu-panel.component.html',
	styleUrls: ['./menu-panel.component.css'],
	providers: [MenuPanelService, MessageService]
})

export class MenuPanelComponent implements OnInit {
	@Input() isExpanded: boolean=false;
    @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();
	activeState: boolean[] = [false, false, false, false, false, false, false, false, false,false];
	activeString = 'report';

	constructor(private messageService: MessageService, private menuPanelService: MenuPanelService, private router: Router, private location: Location) {
		//this.toggleSidebar.emit(!this.isExpanded);
		d3.select(".tibo-right-container").transition().delay(0).duration(310).style("left","230px").ease("linear")
			d3.select(".tibo-topbar-title").transition().delay(0).duration(0).style("left","230px").ease("linear")
			d3.select("#menu-btn").style("transform","rotate(180deg)")
			d3.select(".menu-button").style({"position":"relative","right":"-4px"})
	}
	
	naviEventSubscrip: Subscription;
	
	ngOnChanges() {}
	
	onNavigate(path: string){
		//this.isExpanded=false;
		d3.selectAll(".tibo-menupanel-btn").style("background-color","transparent");
		d3.selectAll(".tibo-menupanel-largepanel-accrdn-wrapper .p-accordion p-accordiontab .p-accordion-tab a").style("background-color","transparent");
		d3.selectAll("#lrg-header4 label,#lrg-header5 label").style("color","#f8f8f8");

		if(path == "/discovery"){
			d3.selectAll(".tibo-menupanel-minpanel-searchicon").style("background-color","#3b586e");
			d3.select("#lrg-header1 a").style("background-color","#3b586e");
		} 
		else if(path == "/my-dashboard"){
			d3.selectAll(".tibo-menupanel-minpanel-dashboardicon").style("background-color","#3b586e");
			d3.select("#lrg-header2 a").style("background-color","#3b586e");
		}
		else if(path == "/new-dashboard"){
			d3.selectAll(".tibo-menupanel-minpanel-newdashboardicon").style("background-color","#3b586e");
			d3.select("#lrg-header11 a").style("background-color","#3b586e");
		}
		else if(path == "/dashboard"){
			d3.selectAll(".tibo-menupanel-minpanel-newdashboardicon").style("background-color","#3b586e");
			d3.select("#lrg-header12 a").style("background-color","#3b586e");
		}
		else if(path == "/dashboard-v3"){
			d3.selectAll(".tibo-menupanel-minpanel-newdashboardicon").style("background-color","#3b586e");
			d3.select("#lrg-header13 a").style("background-color","#3b586e");
		}
		else if(path == "/create-dashboard"){
			d3.selectAll(".tibo-menupanel-minpanel-createdashboardicon").style("background-color","#3b586e");
			d3.select("#lrg-header3 a").style("background-color","#3b586e");
		}
		else if(path == "/data-analytics"){
			d3.selectAll(".tibo-menupanel-minpanel-analyticsicon").style("background-color","#3b586e");
		
			setTimeout(function(){
				d3.selectAll(".tibo-menupanel-largepanel-accrdn-wrapper .p-accordion p-accordiontab .p-accordion-tab a").style("background-color","transparent");
			},200)
			d3.selectAll("#lrg-header4 label,#lrg-header5 label").style("color","#f8f8f8");
			d3.selectAll("#lrg-header4 label").style("color","aqua");
		}
		else if(path == "/my-favorites"){
			d3.selectAll(".tibo-menupanel-minpanel-favoriteicon").style("background-color","#3b586e");
			d3.select("#lrg-header7 a").style("background-color","#3b586e");
		}
		else if(path == "/build-report"){
			d3.selectAll(".tibo-menupanel-minpanel-buildreporticon").style("background-color","#3b586e");
	
			d3.select("#lrg-header10   a").style("background-color","#3b586e");
		}

		else if(path == "/my-reports"){
			d3.selectAll(".tibo-menupanel-minpanel-reportandscheduleicon").style("background-color","#3b586e");
			//this.isExpanded=false;
			setTimeout(function(){
				d3.selectAll(".tibo-menupanel-largepanel-accrdn-wrapper .p-accordion p-accordiontab .p-accordion-tab a").style("background-color","transparent");
			},200)
			d3.selectAll("#lrg-header4 label,#lrg-header5 label").style("color","#f8f8f8");
			d3.selectAll("#lrg-header5 label:nth-child(1)").style("color","aqua");
		}
		else if(path == "/my-report-inbox"){
			d3.selectAll(".tibo-menupanel-minpanel-inboxicon").style("background-color","#3b586e");
			d3.select("#lrg-header6 a").style("background-color","#3b586e");
		}
		else if(path == "/scheduled-reports"){ 
			d3.selectAll(".tibo-menupanel-minpanel-reportandscheduleicon").style("background-color","#3b586e");
			
			setTimeout(function(){
				d3.selectAll(".tibo-menupanel-largepanel-accrdn-wrapper .p-accordion p-accordiontab .p-accordion-tab a").style("background-color","transparent");
			},200)
			d3.selectAll("#lrg-header4 label,#lrg-header5 label").style("color","#f8f8f8");
			d3.selectAll("#lrg-header5 label:nth-child(2)").style("color","aqua");
		}
		else if(path == "/schedule-history"){
			d3.selectAll(".tibo-menupanel-minpanel-reportandscheduleicon").style("background-color","#3b586e");
		
			setTimeout(function(){
				d3.selectAll(".tibo-menupanel-largepanel-accrdn-wrapper .p-accordion p-accordiontab .p-accordion-tab a").style("background-color","transparent");
			},200)
			d3.selectAll("#lrg-header4 label,#lrg-header5 label").style("color","#f8f8f8");
			d3.selectAll("#lrg-header5 label:nth-child(3)").style("color","aqua");
		}
		else if(path == "/settings"){
			d3.selectAll(".tibo-menupanel-minpanel-settingsicon").style("background-color","#3b586e");
			d3.select("#lrg-header8 a").style("background-color","#3b586e");
		}

	}

	ngOnInit(): void {
		d3.select(".tibo-right-container").transition().delay(0).duration(310).style("left","230px").ease("linear")
			d3.select(".tibo-topbar-title").transition().delay(0).duration(0).style("left","230px").ease("linear")
			d3.select("#menu-btn").style("transform","rotate(180deg)")
			d3.select(".menu-button").style({"position":"relative","right":"-4px"})
		this.onNavigate(this.location.path());
		this.router.events.subscribe(
			(event: NavigationEvent) => {
				if(event instanceof NavigationStart) {
					this.onNavigate(event.url);
					setTimeout(function(){
						this.toggleSidebar.emit(this.isExpanded);
				
		//  if(Number(d3.select("#tibo-large-leftpanel-container").node().getBoundingClientRect().width) == 64){
					
		// 	d3.select(".tibo-right-container").transition().delay(160).duration(310).style("left","64px").ease("linear")
		// 	d3.select(".tibo-topbar-title").transition().delay(160).duration(250).style("left","64px").ease("linear")
		// 	d3.select("#menu-btn").style("transform","rotate(0deg)")
		// 	d3.select(".menu-button").style({"position":"relative","right":"-150px"})
		// 					}
		// 				 else if(Number(d3.select("#tibo-large-leftpanel-container").node().getBoundingClientRect().width) == 230){
		
		// 	d3.select(".tibo-right-container").transition().delay(0).duration(310).style("left","230px").ease("linear")
		// 	d3.select(".tibo-topbar-title").transition().delay(0).duration(0).style("left","230px").ease("linear")
		// 	d3.select("#menu-btn").style("transform","rotate(180deg)")
		// 	d3.select(".menu-button").style({"position":"relative","right":"-4px"})
						
		// 			} 
		
					}, 120);
				}
			}
		);
		document.addEventListener("scroll", this.bodyScrollFunc);
	}
	
	ngOnDestroy() {}
	
	bodyScrollFunc() {
		if(window.pageXOffset > 0){
			//d3.select("#tibo-min-leftpanel-container").style("left","0px")
			d3.select("#tibo-large-leftpanel-container").style("left","0px")
			d3.select(".tibo-right-container").style("left","230px")
		
		}
	}



	
	menuFunc() {
		this.toggleSidebar.emit(!this.isExpanded);
		
		if(Number(d3.select("#tibo-large-leftpanel-container").node().getBoundingClientRect().width) == 64){
			
			d3.select(".tibo-right-container").transition().delay(0).duration(0).style("left","230px").ease("linear")
			d3.select(".tibo-topbar-title").transition().delay(0).duration(0).style("left","230px").ease("linear")
			d3.select("#menu-btn").style("transform","rotate(0deg)")
			d3.select(".menu-button").style({"position":"relative","right":"-150px"})
		
		}
		else if(Number(d3.select("#tibo-large-leftpanel-container").node().getBoundingClientRect().width) == 230){
			
			d3.select(".tibo-right-container").transition().delay(0).duration(0).style("left","64px").ease("linear")
			d3.select(".tibo-topbar-title").transition().delay(0).duration(0).style("left","64px").ease("linear")
		    d3.select("#menu-btn").style("transform","rotate(180deg)")
			d3.select(".menu-button").style({"position":"relative","right":"-4px"})
		}
		
	}

	onLinkClick() {
		this.toggleSidebar.emit(!this.isExpanded);
		
		if(Number(d3.select("#tibo-large-leftpanel-container").node().getBoundingClientRect().width) == 230){
			
			d3.select(".tibo-right-container").transition().delay(0).duration(0).style("left","64px").ease("linear")
			d3.select(".tibo-topbar-title").transition().delay(0).duration(0).style("left","64px").ease("linear")
			d3.select("#menu-btn").style("transform","rotate(180deg)")
			d3.select(".menu-button").style({"position":"relative","right":"0px"})
		
		}else if(Number(d3.select("#tibo-large-leftpanel-container").node().getBoundingClientRect().width) == 64) {
			this.toggleSidebar.emit(this.isExpanded);
			d3.select("#menu-btn").style("transform","rotate(180deg)")
			d3.select(".menu-button").style({"position":"relative","right":"-4px"})
			d3.select(".tibo-right-container").transition().delay(0).duration(0).style("left","64px").ease("linear")
		}
		
	}
	
	toggle(index: number) {
		
        this.activeState = [false, false, false, false, false, false, false, false, false, false];

		if(index == 0){
			this.router.navigate(['/discovery']);
			
		}
		else if(index == 1){
			this.router.navigate(['/my-dashboard']);
		}
		else if(index == 2){
			this.router.navigate(['/create-dashboard']);
		}
		if(index == 3 || index == 4){
		

			setTimeout(function(){  
				// d3.select("#tibo-min-leftpanel-container").transition().delay(0).duration(150).style("left","-40px").ease("linear")
				d3.select("#tibo-large-leftpanel-container").transition().delay(160).duration(310).style("left","0px").ease("linear")
				d3.select(".tibo-right-container").transition().delay(160).duration(310).style("left","231px").ease("linear")
			}, 250);
			
			
		}
		else if(index == 5){
			this.router.navigate(['build-report']);
		}
		else if(index == 6){
			this.router.navigate(['/my-report-inbox']);
		}
		else if(index == 7){
			this.router.navigate(['/my-favorites']);
		}
	
		else if(index == 8){
			this.router.navigate(['/settings']);
		}
		else if(index == 10){
			this.router.navigate(['new-dashboard']);
		}
		else if(index == 11){
			this.router.navigate(['dashboard']);
		}
		else if(index == 12){
			this.router.navigate(['dashboard-v3']);
		}
		
	}
	
	navigateFunc(event:any, routeName:string) {
		
		d3.selectAll(".tibo-menupanel-largepanel-accrdn-wrapper .p-accordion p-accordiontab .p-accordion-tab a").style("background-color","transparent");

		if(routeName == "discovery"){ 
			this.router.navigate(['/discovery']);
		}
		else if(routeName == "my-dashboard"){ 
			this.router.navigate(['/my-dashboard']);
		}
		else if(routeName == "create-dashboard"){ 
			this.router.navigate(['/create-dashboard']);
		}
		else if(routeName == "data-analytics"){ 
			this.router.navigate(['/data-analytics']);
		}
	
		else if(routeName == "my-reports"){ 
			this.router.navigate(['/my-reports']); 
		}
		else if(routeName == "scheduled-reports"){ 
			this.router.navigate(['/scheduled-reports']); 
		}
		else if(routeName == "schedule-history"){ 
			this.router.navigate(['/schedule-history']); 
		}
	
		else if(routeName == "my-report-inbox"){ 
			this.router.navigate(['/my-report-inbox']); 
		}
		else if(routeName == "my-favorites"){
			this.router.navigate(['/my-favorites']); 
		}
		else if(routeName == "settings"){ 
			this.router.navigate(['/settings']); 
		}
		else if(routeName == "build-report"){
			this.router.navigate(['/build-report']); 
		}
		else if(routeName == "new-dashboard"){
			this.router.navigate(['new-dashboard']); 
		}
		else if(routeName == "dashboard"){
			
			this.router.navigate(['dashboard']); 
		}
		else if(routeName == "dashboard-v3"){
			this.router.navigate(['dashboard-v3']); 
		}
		
	}

	onTabOpen(e){
		if((e.index == 3) || (e.index == 4)){
			d3.selectAll("#lrg-header4 a,#lrg-header5 a").style("background-color","transparent")
		}
	}

	activefun(value:string){
		this.activeString = value;
	}
	
}







