import { Component, OnInit, OnDestroy } from '@angular/core';
import { CreateDashboardService } from './create-dashboard.service'
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { DragDropModule } from 'primeng/dragdrop';
//import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

declare var d3:any;
declare var acinfotech: any;

interface DashboardInfo {
	name: string,
	code: string
}

@Component({
  selector: 'app-create-dashboard',
  templateUrl: './create-dashboard.component.html',
  styleUrls: ['./create-dashboard.component.css'],
  providers: [CreateDashboardService,MessageService]
})
export class CreateDashboardComponent implements OnInit, OnDestroy {
	sample: any;
	chartElement9: any;
	chartElement12: any;
	chartElement13: any;
	detail9: any;
	detail12: any;
	detail13: any;
	chartElement1: any;
	chartElement2: any;
	chartElement3: any;
	detail1: any;
	detail2: any;
	detail3: any;
	wigetBackgroundColor: any="#d9d9d9";
	selectedChartsColorSet: any="three";
	wid: any;
	selectedChart: any;
	selectedWidgets: any[]=[];

  constructor(private creatDashService: CreateDashboardService, private messageService: MessageService) { }

  alertSeverity:string = "error";
  alertMsg:string = "";

  text: string = "";
  dashboardsList = [];
  metricSearchBool:boolean = false;
  metricFrmDashBool:boolean = false;
  widgetsList = [];
  srchResDashNames = [];
  searchType: string = "";
  chartId: string = "";
  saveDashDialBoxDisplayBool: boolean = false;
  domainsList = [];
  dashboardName: string = "";
  searchOrDashResult: string = "";
  searchOrDashResultArray = [];
  saveDashboardDomainId: number;
  saveDashboardReqPayload = { column:3, dashboardRequestorParameters:[], domainId:0, isUserCreateDashboard:"Y",
   mainTitle:"", parameterdetails:"", row:15, widgetControls:[], widgetName:"" };
  isParameterizedWidget: boolean = false;
  droppedParamWidgetDomainId:number;
  droppedParamWidgetParameters = [];
  json: string = "";
  
  selectedDashboard: DashboardInfo;

  savedSettingsSubscrip: Subscription;
  dashboardsListSubscrip: Subscription;
  getWidgetsSubscrip: Subscription;
  getADashboardSubscrip: Subscription;
  getWidgetDataSubscrip: Subscription;
  domainsListSubscrip: Subscription;
  createDashSubscrip: Subscription;
  
  rows = [];
  columns = [1,2,3];
  widgets = [];
  dragIndex:number;

  ngOnInit(): void {

	acinfotech.requestType(false); // Letting chart library know it's a create dashboard module

	d3.select(".tibo-topbar-title").text("Home / Create Dashboard"); // setting title

    // if(Number(d3.select("#tibo-large-leftpanel-container").node().getBoundingClientRect().width) == 64){
	// 		setTimeout(function(){
	// 			d3.select(".tibo-right-container").transition().delay(0).duration(0).style("left","64px").ease("linear");
	// 		},10)
	// 	}

	this.json = JSON.stringify(this.saveDashboardReqPayload);

    this.initialServiceCalls();
	this.selectedWidgets=[];
  }

  ngOnDestroy() {
	if(this.savedSettingsSubscrip){ 
		this.savedSettingsSubscrip.unsubscribe();
	}
    if(this.dashboardsListSubscrip){
		this.dashboardsListSubscrip.unsubscribe();
	}
    if(this.getADashboardSubscrip){
		this.getADashboardSubscrip.unsubscribe();
	}
    if(this.getWidgetsSubscrip){
		this.getWidgetsSubscrip.unsubscribe();
	}
    if(this.getWidgetDataSubscrip){
		this.getWidgetDataSubscrip.unsubscribe();
	}
	if(this.domainsListSubscrip){
		this.domainsListSubscrip.unsubscribe();
	}
	if(this.createDashSubscrip){
		this.createDashSubscrip.unsubscribe();
	}
  }

  initialServiceCalls(){
			this.rows=[0,0,0]
			console.log(this.rows)
		this.dashboardsList=[{label:'Disability-Client',value:'1'},{label:'Leave',value:'2'}]
		// [
		// 	{label:'Continous vs Intermittent',value:'13'},
		// 	{label:'New Leave BY Reason',value:'12'},
		// 	{label:'Open  Leave By Reason',value:'9'}
		// ];
		console.log(this.dashboardsList)
			this.domainsList=[{
				"label": "Disability",
				"value": 233
			},
			{
				"label": "Leave",
				"value": 238
			}]
			console.log(this.domainsList)
			
  }

  search() {

	d3.select(".tibo-mydash-widget-spinner").style("display","block");
	
	this.searchOrDashResultArray = [];
    this.widgetsList = [];
	this.srchResDashNames = [];
    
	this.searchOrDashResult = "search result";
	const data = {widgetControls: [
		{chartTitle:'New Leave BY Reason', controlMasterId:12}, 
		{chartTitle:'Continous vs Intermittent', controlMasterId:13},
		{chartTitle:'Open  Leave By Reason', controlMasterId:9}]}
	this.searchOrDashResultArray = [data];
	// this.widgetsList = [...data?.widgetControls];
	let list=[
		{name:'Continous vs Intermittent',id:'13'},
		{name:'New Leave BY Reason',id:'12'},
		{name:'Open  Leave By Reason',id:'9'}
	];
	list.forEach(data=>{
		if(data.name.toLowerCase().includes(this.text.toLowerCase())){
			this.widgetsList.push(data)
	}
	});
	this.srchResDashNames=['Continous vs Intermittent','New Leave BY Reason','Open  Leave By Reason']

	d3.select(".tibo-mydash-widget-spinner").style("display","none");
  }
 
  radioSwitch(searchType){
    searchType == "search metric" ? (this.metricSearchBool = true, this.metricFrmDashBool = false) : (this.metricSearchBool = false, this.metricFrmDashBool = true) 
  }

onDashboardSelect(event){
	console.log(event)
	this.searchOrDashResultArray = [];
	this.widgetsList = [];
	this.selectedDashboard = {name: "", code: event.value }
	const data = {widgetControls: [
		{chartTitle:'New CLaims', controlMasterId:1}, 
		{chartTitle:'Avg Duration', controlMasterId:2},
		{chartTitle:'Claims Paid in Million', controlMasterId:3},
		{chartTitle:'New Leave BY Reason', controlMasterId:12}, 
		{chartTitle:'Continous vs Intermittent', controlMasterId:13},
		{chartTitle:'Open  Leave By Reason', controlMasterId:9}]}
	this.searchOrDashResultArray = [data];
	this.widgetsList = [...data?.widgetControls];
console.log("search array", this.searchOrDashResultArray);

	this.searchOrDashResult = "dashboard result";
	let list1=[
		{name:'New Leave BY Reason',id:'12'},
		{name:'Continous vs Intermittent',id:'13'},
		{name:'Open  Leave By Reason',id:'9'},
	];
	let list2=[
		{name:"New CLaims",id:'1'},
		{name:'Avg Duration(Closed Claims)n',id:'2'},
		{name:'Claims Paid in Million',id:'3'}
	];
	
	if(event.value == '1'){
		this.widgetsList=list2;
	}
	if(event.value == '2'){
		this.widgetsList=list1;
	}

}

delete(event){

	console.log("delete",event)
	
	let datasss= this.searchOrDashResultArray[0].widgetControls[this.dragIndex];
	d3.select(event.target).attr("widgetInfo",
						JSON.stringify({parameters:this.searchOrDashResultArray[this.dragIndex].dashboardRequestorParameters}));
	console.log('hi',datasss);
	this.chartId="";
	//var testContainer = document.querySelector('#tibo-mydash-nonparamdash-scrollpanel');
	this.widgetsList[this.dragIndex].chartTitle="";
	this.searchOrDashResultArray[this.dragIndex].widgetName ="";
}
getWidgetData(widget){
	console.log(widget)
	console.log("get widget data called");
	setTimeout(() => { 
		this.chartElement1= d3.select('#chart1');
		this.detail1={
			data:[
				{
				 "YEAR": "2021",
				 "STD": 1759,
				 "VP": 518
				},
				{
				 "YEAR": "2022",
				 "STD": 1730,
				 "VP": 477
				},
				{
				 "YEAR": "2023",
				 "STD": 484,
				 "VP": 120
				}
			   ],
			title:"New CLaims",
			keyLabel:'YEAR',
			keyGroup: ['STD','VP'],
			xLable: "YEAR",
			yLable: "",
		} },1000)
		setTimeout(() => {
		this.chartElement2= d3.select('#chart2')
		this.detail2={
			data:[
				{
				 "CLIENT": 388,
				 "YEAR_VALUE": "2021",
				 "AVERAGE_DURATION": 64
				},
				{
				 "CLIENT": 388,
				 "YEAR_VALUE": "2022",
				 "AVERAGE_DURATION": 66
				},
				{
				 "CLIENT": 388,
				 "YEAR_VALUE": "2023",
				 "AVERAGE_DURATION": 75
				}
			   ],
			title:"Avg Duration(Closed Claims)",
			keyLabel: "YEAR_VALUE",
			keyGroup:"AVERAGE_DURATION",
			xLable: "YEAR",
			yLable:"AVERAGE_DURATION" ,
		}},1000)
		setTimeout(() => {
		this.chartElement3= d3.select('#chart3')
		this.detail3={
			data:[
				{
				 "YEAR": "2021",
				 "STD": 15.27,
				 "VP": 6.2
				},
				{
				 "YEAR": "2022",
				 "STD": 16.09,
				 "VP": 5.85
				},
				{
				 "YEAR": "2023",
				 "STD": 3.74,
				 "VP": 1.4
				}
			   ],
			title:"Claims Paid in Million",
			keyLabel:'YEAR',
			keyGroup: ['STD','VP'],
			xLable: "YEAR",
			yLable: "",
		}},1000)
	setTimeout(() => {
		this.chartElement9= d3.select('#chart9');
		this.detail9={
			data:[
			{
			 "MONTH": "Jan",
			 "Own": 17,
			 "Child": 4,
			 "Military": 0,
			 "Newborn": 4,
			 "Parent": 7,
			 "Personal": 0,
			 "Pregnancy": 1,
			 "Spouse": 2,
			 "Surrogacy": 0
			},
			{
			 "MONTH": "Feb",
			 "Own": 10,
			 "Child": 3,
			 "Military": 1,
			 "Newborn": 8,
			 "Parent": 3,
			 "Personal": 4,
			 "Pregnancy": 0,
			 "Spouse": 5,
			 "Surrogacy": 0
			},
			{
			 "MONTH": "Mar",
			 "Own": 15,
			 "Child": 3,
			 "Military": 0,
			 "Newborn": 4,
			 "Parent": 6,
			 "Personal": 3,
			 "Pregnancy": 0,
			 "Spouse": 4,
			 "Surrogacy": 0
			},
			{
			 "MONTH": "Apr",
			 "Own": 12,
			 "Child": 1,
			 "Military": 1,
			 "Newborn": 11,
			 "Parent": 4,
			 "Personal": 2,
			 "Pregnancy": 0,
			 "Spouse": 5,
			 "Surrogacy": 1
			},
			{
			 "MONTH": "May",
			 "Own": 8,
			 "Child": 0,
			 "Military": 0,
			 "Newborn": 9,
			 "Parent": 5,
			 "Personal": 3,
			 "Pregnancy": 0,
			 "Spouse": 1,
			 "Surrogacy": 0
			},
			{
			 "MONTH": "Jun",
			 "Own": 20,
			 "Child": 1,
			 "Military": 0,
			 "Newborn": 10,
			 "Parent": 12,
			 "Personal": 2,
			 "Pregnancy": 0,
			 "Spouse": 7,
			 "Surrogacy": 0
			},
			{
			 "MONTH": "Jul",
			 "Own": 20,
			 "Child": 3,
			 "Military": 0,
			 "Newborn": 8,
			 "Parent": 7,
			 "Personal": 3,
			 "Pregnancy": 1,
			 "Spouse": 1,
			 "Surrogacy": 0
			},
			{
			 "MONTH": "Aug",
			 "Own": 25,
			 "Child": 3,
			 "Military": 0,
			 "Newborn": 16,
			 "Parent": 5,
			 "Personal": 3,
			 "Pregnancy": 2,
			 "Spouse": 4,
			 "Surrogacy": 0
			},
			{
			 "MONTH": "Sep",
			 "Own": 39,
			 "Child": 0,
			 "Military": 1,
			 "Newborn": 18,
			 "Parent": 8,
			 "Personal": 3,
			 "Pregnancy": 4,
			 "Spouse": 3,
			 "Surrogacy": 0
			},
			{
			 "MONTH": "Oct",
			 "Own": 68,
			 "Child": 8,
			 "Military": 2,
			 "Newborn": 41,
			 "Parent": 20,
			 "Personal": 4,
			 "Pregnancy": 16,
			 "Spouse": 6,
			 "Surrogacy": 0
			},
			{
			 "MONTH": "Nov",
			 "Own": 87,
			 "Child": 4,
			 "Military": 1,
			 "Newborn": 81,
			 "Parent": 23,
			 "Personal": 6,
			 "Pregnancy": 16,
			 "Spouse": 6,
			 "Surrogacy": 0
			},
			{
			 "MONTH": "Dec",
			 "Own": 131,
			 "Child": 4,
			 "Military": 1,
			 "Newborn": 77,
			 "Parent": 25,
			 "Personal": 4,
			 "Pregnancy": 12,
			 "Spouse": 2,
			 "Surrogacy": 1
			}
		   ],
		title:" Open  Leave By Reason",
		//keyLabel:['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
		keyLabel:"MONTH",
		keyGroup:["Own","Child","Military","Newborn","Parent","Personal","Pregnancy","Spouse","Surrogacy"],
		xLable: "MONTH",
		yLable: "",
			
		}},1000);
	setTimeout(() => {
		this.chartElement12= d3.select('#chart12');
		this.detail12={
			data:[
				{
				 "Total": 5,
				 "Leave Type": "Bereave",
				 "Leave_Type": "Bereave"
				},
				{
				 "Total": 37,
				 "Leave Type": "Child",
				 "Leave_Type": "Child"
				},
				{
				 "Total": 8,
				 "Leave Type": "Military",
				 "Leave_Type": "Military"
				},
				{
				 "Total": 326,
				 "Leave Type": "Newborn",
				 "Leave_Type": "Newborn"
				},
				{
				 "Total": 1,
				 "Leave Type": "Other",
				 "Leave_Type": "Other"
				},
				{
				 "Total": 519,
				 "Leave Type": "Own",
				 "Leave_Type": "Own"
				},
				{
				 "Total": 134,
				 "Leave Type": "Parent",
				 "Leave_Type": "Parent"
				},
				{
				 "Total": 41,
				 "Leave Type": "Personal",
				 "Leave_Type": "Personal"
				},
				{
				 "Total": 65,
				 "Leave Type": "Pregnancy",
				 "Leave_Type": "Pregnancy"
				},
				{
				 "Total": 53,
				 "Leave Type": "Spouse",
				 "Leave_Type": "Spouse"
				},
				{
				 "Total": 2,
				 "Leave Type": "Surrogacy",
				 "Leave_Type": "Surrogacy"
				}
			   ],
		title:"New Leave BY Reason",
		keyLabel:"Leave Type",
		keyGroup: "Total",
		xLable: "Leave Type",
		yLable: "Total",
		}
	},1000);
	setTimeout(() => {
		this.chartElement13= d3.select('#chart13')
		this.detail13={
			data:[
				{
				 "Continuous": 13,
				 "Intermittent": 12,
				 "Month": "Jan"
				},
				{
				 "Continuous": 11,
				 "Intermittent": 13,
				 "Month": "Feb"
				},
				{
				 "Continuous": 11,
				 "Intermittent": 13,
				 "Month": "Mar"
				},
				{
				 "Continuous": 14,
				 "Intermittent": 11,
				 "Month": "Apr"
				},
				{
				 "Continuous": 13,
				 "Intermittent": 8,
				 "Month": "May"
				},
				{
				 "Continuous": 22,
				 "Intermittent": 14,
				 "Month": "Jun"
				},
				{
				 "Continuous": 13,
				 "Intermittent": 14,
				 "Month": "Jul"
				},
				{
				 "Continuous": 29,
				 "Intermittent": 16,
				 "Month": "Aug"
				},
				{
				 "Continuous": 25,
				 "Intermittent": 29,
				 "Month": "Sep"
				},
				{
				 "Continuous": 55,
				 "Intermittent": 57,
				 "Month": "Oct"
				},
				{
				 "Continuous": 76,
				 "Intermittent": 75,
				 "Month": "Nov"
				},
				{
				 "Continuous": 90,
				 "Intermittent": 88,
				 "Month": "Dec"
				}
			   ],
			title:"Continous vs Intermittent",
			keyLabel:'Month',
			keyGroup: ['Continuous', 'Intermittent'],
			xLable: "Month",
			yLable: "",
		}},1000);
		if(widget.id == '1'){
			this.chartId="chart"+widget.id;
			setTimeout(() => { this.groupedBarChart(this.chartElement1, this.detail1) },1000)
		}
		else if(widget.id == '2'){
			this.chartId="chart"+widget.id;
			setTimeout(() => { this.donutChart(this.chartElement2, this.detail2) },1000);
		}
		else if(widget.id == '3'){
			this.chartId="chart"+widget.id;
			setTimeout(() => { this.groupedBarChart(this.chartElement3, this.detail3) },1000);	
		}
		else if(widget.id == '9'){
			this.chartId="chart"+widget.id;
			setTimeout(() => { this.stackedBarChart(this.chartElement9, this.detail9)},1000);
		}
		else if(widget.id == '12'){
			this.chartId="chart"+widget.id;
			setTimeout(() => { this.pieChart(this.chartElement12, this.detail12)},1000);
		}
		else if(widget.id == '13'){
			this.chartId="chart"+widget.id;
			setTimeout(() => { this.lineChart(this.chartElement13, this.detail13)},1000);
		}
}
// getWidgetData(){

// }
variio:any;

pieChart(chartElement,widgetdetails){
	let options = {
		data: widgetdetails.data, // for chart
		dataForTable: [], // for table if there is no data send empty array ([])
		isDataDetailedForTable: false, 
		keys: {
			label: widgetdetails.keyLabel,
			value: widgetdetails.keyGroup
		},
		chartTitle:widgetdetails.title,
		showTitle: true,
		showLegend: true,
		legendSide: "right", //"right" or "left"
		BGcolor:this.wigetBackgroundColor,
		colorSet: this.selectedChartsColorSet, //"one" or "two" or "three"
		gradient: "none", //"none" or "smooth" or "elliptical" or "circular" or "radial"
		drillDownColumnName:widgetdetails.keyLabel,
		showTableIcon: true, //false - hide, true - show
		showTable: false, //false - hide, true - show
		showComments: false,
		comments:"",
		resizeButtonID: document.getElementById("resizeBtn").id
	}
	
	setTimeout(() => { acinfotech.pieChart(chartElement, options);},1000)
	}
  lineChart(chartElement,widgetdetails){
		let options = {
			data: widgetdetails.data,
			dataForTable: [],
			isDataDetailedForTable: false,
			keys: {
				label: widgetdetails.keyLabel, // X-Axis labels
				lines:widgetdetails.keyGroup
			},
			chartTitle: widgetdetails.title,
			xAxisLable: widgetdetails.xLable,
			yAxisLable: "",
			showTitle: true,
			colorSet: this.selectedChartsColorSet, //"one" or "two" or "three"
			BGcolor:this.wigetBackgroundColor,
			lineStyle: "linear", // "cardinal" or "linear" or "step"
			drillDownColumnName:"",
			showTable: false,
			showComments: false,
			comments:"",
			resizeButtonID: document.getElementById("resizeBtn").id
		}
		
		setTimeout(() => { acinfotech.lineChart(chartElement, options) },1000)
  }
  stackedBarChart(chartElement,widgetdetails){
			let options = {
				data: widgetdetails.data,
				dataForDrillDown: [],
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label: widgetdetails.keyLabel, // X-Axis labels
					stackedBars: widgetdetails.keyGroup
				},
				chartTitle:widgetdetails.title,
				xAxisLable:widgetdetails.xLable,
				yAxisLable:widgetdetails.yLable,
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three"
				BGcolor:this.wigetBackgroundColor,
				lineStyle: "cardinal", // "cardinal" or "linear" or "step"
				drillDownColumnName:"",
				showTable: false,
				showComments: false,
				comments:"",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
				
			setTimeout(() => { acinfotech.stackedBarChart(chartElement, options) },1000)
		
  }
  groupedBarChart(chartElement,widgetdetails){
	let options = {
		data: widgetdetails.data,
		dataForDrillDown: [],
		dataForTable: [],
		isDataDetailedForTable: false,
		keys: {
			label:widgetdetails.keyLabel,
			groupBars:widgetdetails.keyGroup,
		},
		chartTitle:widgetdetails.title,
		xAxisLable: widgetdetails.xLable,
		yAxisLable:widgetdetails.yLable,
		showTitle: true,
		colorSet: this.selectedChartsColorSet, //"one" or "two" or "three"
		BGcolor:this.wigetBackgroundColor,
		drillDownColumnName: "",
		showTable: false,
		showComments: false,
		comments:"",
		resizeButtonID: document.getElementById("resizeBtn").id
	}
	
	setTimeout(() => { acinfotech.groupedBarChart(chartElement, options) },1500)

}
donutChart(chartElement,widgetdetails){
	let options = {
		data: widgetdetails.data, // for chart
		dataForTable: [], // for table if there is no data send empty array ([])
		isDataDetailedForTable: false, 
		keys: {
			label: widgetdetails.keyLabel,
			value: widgetdetails.keyGroup
		},
		chartTitle: widgetdetails.title,
		showTitle: true,
		showLegend: true,
		legendSide: "right", //"right" or "left"
		colorSet: this.selectedChartsColorSet, //"one" or "two" or "three"
		BGcolor:this.wigetBackgroundColor,
		gradient: "none", //"none" or "smooth" or "elliptical" or "circular" or "radial"
		drillDownColumnName:widgetdetails.keyLabel,
		showTableIcon: true, //false - hide, true - show
		showTable: false, //false - hide, true - show
		showComments: false,
		comments:"",
		resizeButtonID: document.getElementById("resizeBtn").id
	}
	setTimeout(() => { acinfotech.donutChart(chartElement, options) },1000)
}

	saveDashboard(){
		

		var checkDroppedWidgetsBool = false;

		d3.selectAll(".create-dashboard-widget-container")[0].forEach(wid => {
			if(d3.select(wid).attr("widgetInfo") != null){
				checkDroppedWidgetsBool = true;
			   
			}
		})

		if(checkDroppedWidgetsBool){
			this.saveDashDialBoxDisplayBool = true;
		}
		else {
			this.alertSeverity = "error";
			this.alertMsg = "Please drag and drop widgets on drop boxes.";
			this.addSingle();
		}

	}

	onDomainSelect(event){
		this.saveDashboardDomainId = Number(event.value)
	}

	createDashboard(){

		var totalDroppedWidgetsArray = [];
		
		

		d3.selectAll(".create-dashboard-widget-container")[0].forEach(wid => {
			if(d3.select(wid).attr("widgetInfo") != null){
				var widgetInfo = JSON.parse(d3.select(wid).attr("widgetInfo"));
				totalDroppedWidgetsArray.push(widgetInfo.controls);
			}
		})
		
		this.saveDashboardReqPayload.domainId = this.saveDashboardDomainId;
		this.saveDashboardReqPayload.widgetName = d3.select("#dashboard-name-id").node().value;
		this.saveDashboardReqPayload.dashboardRequestorParameters = this.droppedParamWidgetParameters;
		this.saveDashboardReqPayload.widgetControls = totalDroppedWidgetsArray;

		/*var saveDashboardReqPayloadCopy = { 
			column:3, 
			dashboardRequestorParameters:this.saveDashboardReqPayload.dashboardRequestorParameters, 
			domainId:this.saveDashboardDomainId, 
			isUserCreateDashboard:"Y",
   			mainTitle:"", 
			parameterdetails:"", 
			row:15, 
			widgetControls:this.saveDashboardReqPayload.widgetControls, 
			widgetName:d3.select("#dashboard-name-id").node().value 
		};*/

		//this.json = JSON.stringify(this.searchOrDashResultArray);
		
		this.saveDashboardReqPayload.dashboardRequestorParameters.forEach((d) => {
			d.dashboardParameterId = 0;
			d.authorizationId = 0;
		})

		this.saveDashboardReqPayload.widgetControls.forEach((d) => { 
			d.widgetControlId = 0;
		})

		if((this.saveDashboardDomainId == undefined) || (d3.select("#dashboard-name-id").node().value.length == 0)){
			this.alertSeverity = "error";
			this.alertMsg = "Please select a domain and enter dashboard name."
			this.addSingle();
		}
		else {
			this.createDashSubscrip = this.creatDashService.createDashboard(this.saveDashboardReqPayload).subscribe( (response: any) => { // widgets list
				
				this.saveDashDialBoxDisplayBool = false;

				if(response.status == 200){
					this.alertSeverity = "success";
					this.alertMsg = '"' + this.saveDashboardReqPayload.widgetName + '" dashboard created successfully!';
					this.addSingle();
				}
			
			}, error => { 
			
				this.alertSeverity = "error";
				
				if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
				else if(Number(error.status) == 409){ this.alertMsg = 'Sorry, duplicate name not allowed!' }
				else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
				else { this.alertMsg = "Please try after some time." }
				
				this.addSingle();
			}, () => {})
		}
	}

  dialogBoxOnCloseFunc(event){
	
  }

  	addSingle() { this.messageService.add({severity:this.alertSeverity, summary:"", detail:this.alertMsg}); }

	clear() { this.messageService.clear(); }

  
  	finalCall(){
		setTimeout(() => {
			d3.selectAll(".widget-class")[0].map(function(d, ind){ // removing menu button from each chart after loading the chart!
				if(d.children.length > 0){
					//d3.select(d).select(".p-progress-spinner").style("display","none"); //hidding widget box spinner after loading the chart
					var div = d3.select(d).select("div > img[src='images/menuIcon.png']")[0][0]
					if(d3.select(div).node()?.parentNode != null){
						d3.select(d3.select(div).node().parentNode).style("display","none");
					}
					
				}
			})
		}, 1000);
	}
	drop(event) {
		console.log(event)
		//if((event.target.children.length == 0) && (event.target.hasAttribute("draggable") == false)){
		
			
		if((event.target.querySelector('.p-progress-spinner') != null) && (event.target.hasAttribute("draggable") == false)){
			
			  event.preventDefault();
			
			  if(this.searchOrDashResult == "search result"){
				var droppedWidget = this.searchOrDashResultArray[this.dragIndex].widgetControls[0];
				this.variio = this.searchOrDashResultArray[this.dragIndex].widgetControls[0];
				
				if(this.searchOrDashResultArray[this.dragIndex].dashboardRequestorParameters.length > 0){
	
					if(this.isParameterizedWidget == false){
						this.isParameterizedWidget = true;
	
						this.droppedParamWidgetDomainId = Number(this.searchOrDashResultArray[this.dragIndex].dashboardRequestorParameters[0].authorizationId);
						
						this.droppedParamWidgetParameters = this.searchOrDashResultArray[this.dragIndex].dashboardRequestorParameters; // Storing parameterized widget parameters
	
						this.saveDashboardReqPayload.dashboardRequestorParameters = this.searchOrDashResultArray[this.dragIndex].dashboardRequestorParameters;
						droppedWidget["objectCordinates"] = d3.select(event.target).attr("objectCordinates");
						this.saveDashboardReqPayload.widgetControls.push(droppedWidget);
	
						d3.select(event.target).attr("widgetInfo",
							JSON.stringify({parameters:this.searchOrDashResultArray[this.dragIndex].dashboardRequestorParameters,
								controls:droppedWidget}));
	
	
						event.target.innerHTML = "<div style='width: 100%; height:0%; background-color:#c7c7c7; position: absolute; top:5px; font-size:16px; font-family:Roboto; color:#0f528c; text-indent:5px;'>"+this.widgetsList[this.dragIndex].chartTitle+"</div>"+
						"<p class='p-mx-3' style='position: relative; top:20%; font-family:Roboto; font-size:16px; color:#0f528c; text-align:left;'>Dashboard: "+ this.searchOrDashResultArray[this.dragIndex].widgetName +"</p>"+
						"<p class='p-mx-3' style='position: relative; top:40%; font-family:Roboto; font-size:16px; color:#25c475; text-align:center;'>Widget added to dashboard. It's a parameterized widget!</p>";
	
						var z = document.createElement('button'); // is a node
						z.setAttribute("style","position:absolute; right:-5px; top:-10px; width:23px; height:23px; border-radius:100%; background-image:url('../../assets/images/cancel.png'); background-repeat: no-repeat; background-size: 100% 100%; background-position: center; border:0; z-index:1000;");
						z.addEventListener("click", () => {
							
							event.target.innerHTML = "";
							event.target.id = "";
							event.target.removeAttribute("widgetInfo");
	
							var hasParameterizedWidget = false;
							d3.selectAll(".create-dashboard-widget-container")[0].forEach(wid => {
								if(d3.select(wid).attr("widgetInfo") != null){
									var widgetInfo = JSON.parse(d3.select(wid).attr("widgetInfo"));
									if(widgetInfo.parameters.length > 0){
										hasParameterizedWidget = true;
									}
								}
							});
	
							if(!hasParameterizedWidget){
								this.droppedParamWidgetDomainId = null;
								this.isParameterizedWidget = false;
								this.droppedParamWidgetParameters = []; // Empty the stored parameterized widget parameters
							}
	
						}, false);
						event.target.appendChild(z);
						/*var data = event.dataTransfer.getData("text");
						var nodeCopy = document.getElementById(data).cloneNode(true);
						var testContainer = document.querySelector('#tibo-mydash-nonparamdash-scrollpanel');
						var fourChildNode = testContainer.querySelector('.tibo-mydash-nonparamdash-scrollpanel .p-scrollpanel-wrapper .p-scrollpanel-content');
						fourChildNode.insertBefore(document.getElementById(data), fourChildNode.children[this.dragIndex]);
								
						d3.select(nodeCopy).attr("class","chart-div")
						this.chartId = "chartid-" + Math.floor(Math.random() * 10000) + 1;
						d3.select(event.target).attr("id", this.chartId)
							
						var z = document.createElement('button'); // is a node
						z.setAttribute("style","position:absolute; right:-5px; top:-10px; width:23px; height:23px; border-radius:100%; background-image:url('../../assets/images/cancel.png'); background-repeat: no-repeat; background-size: 100% 100%; background-position: center; border:0; z-index:1000;")
						z.addEventListener("click", () => {
							event.target.innerHTML = "";
						}, false);
						event.target.appendChild(z);
							
						this.getWidgetData(this.widgetsList[this.dragIndex]);*/
	
					}
					else {
	
						if(this.droppedParamWidgetDomainId == Number(this.searchOrDashResultArray[this.dragIndex].dashboardRequestorParameters[0].authorizationId) ){
	
							this.droppedParamWidgetParameters = this.searchOrDashResultArray[this.dragIndex].dashboardRequestorParameters; // Storing parameterized widget parameters
								
					
							
							//this.saveDashboardReqPayload.dashboardRequestorParameters = this.searchOrDashResultArray[this.dragIndex].dashboardRequestorParameters;
							droppedWidget["objectCordinates"] = d3.select(event.target).attr("objectCordinates");
							this.saveDashboardReqPayload.widgetControls.push(droppedWidget);
	
							d3.select(event.target).attr("widgetInfo",
								JSON.stringify({parameters:this.searchOrDashResultArray[this.dragIndex].dashboardRequestorParameters,
										controls:droppedWidget}));
	
							event.target.innerHTML = "<div style='width: 100%; height:0%; background-color:#c7c7c7; position: absolute; top:5px; font-size:16px; font-family:Roboto; color:#0f528c; text-indent:5px;'>"+this.widgetsList[this.dragIndex].chartTitle+"</div>"+
							"<p class='p-mx-3' style='position: relative; top:20%; font-family:Roboto; font-size:16px; color:#0f528c; text-align:left;'>Dashboard: "+ this.searchOrDashResultArray[this.dragIndex].widgetName +"</p>"+
							"<p class='p-mx-3' style='position: relative; top:40%; font-family:Roboto; font-size:16px; color:#25c475; text-align:center;'>Widget added to dashboard. It's a parameterized widget!</p>";
	
							var z = document.createElement('button'); // is a node
							z.setAttribute("style","position:absolute; right:-5px; top:-10px; width:23px; height:23px; border-radius:100%; background-image:url('../../assets/images/cancel.png'); background-repeat: no-repeat; background-size: 100% 100%; background-position: center; border:0; z-index:1000;")
							z.addEventListener("click", () => {
							
								event.target.innerHTML = "";
								event.target.id = "";
								event.target.removeAttribute("widgetInfo");
	
								var hasParameterizedWidget = false;
								d3.selectAll(".create-dashboard-widget-container")[0].forEach(wid => {
									if(d3.select(wid).attr("widgetInfo") != null){
										var widgetInfo = JSON.parse(d3.select(wid).attr("widgetInfo"));
										if(widgetInfo.parameters.length > 0){
											hasParameterizedWidget = true;
										}
									}
								});
	
								if(!hasParameterizedWidget){
									this.droppedParamWidgetDomainId = null;
									this.isParameterizedWidget = false;
									this.droppedParamWidgetParameters = []; // Empty the stored parameterized widget parameters
								}
	
							}, false);
							event.target.appendChild(z);
							/*var data = event.dataTransfer.getData("text");
							var nodeCopy = document.getElementById(data).cloneNode(true);
							var testContainer = document.querySelector('#tibo-mydash-nonparamdash-scrollpanel');
							var fourChildNode = testContainer.querySelector('.tibo-mydash-nonparamdash-scrollpanel .p-scrollpanel-wrapper .p-scrollpanel-content');
							fourChildNode.insertBefore(document.getElementById(data), fourChildNode.children[this.dragIndex]);
									
							d3.select(nodeCopy).attr("class","chart-div")
							this.chartId = "chartid-" + Math.floor(Math.random() * 10000) + 1;
							d3.select(event.target).attr("id", this.chartId)
								
							var z = document.createElement('button'); // is a node
							z.setAttribute("style","position:absolute; right:-5px; top:-10px; width:23px; height:23px; border-radius:100%; background-image:url('../../assets/images/cancel.png'); background-repeat: no-repeat; background-size: 100% 100%; background-position: center; border:0; z-index:1000;")
							z.addEventListener("click", () => {
								event.target.innerHTML = "";
							}, false);
							event.target.appendChild(z);
								
							this.getWidgetData(this.widgetsList[this.dragIndex]);*/
	
						}
						else {
							alert();
						}
	
					}
					
				}
				else {
	
					droppedWidget["objectCordinates"] = d3.select(event.target).attr("objectCordinates");
					this.saveDashboardReqPayload.widgetControls.push(droppedWidget);
	
					d3.select(event.target).attr("widgetInfo",
						JSON.stringify({parameters:this.searchOrDashResultArray[this.dragIndex].dashboardRequestorParameters,
										controls:droppedWidget}));
	
					
					var data = event.dataTransfer.getData("text");
					var nodeCopy = document.getElementById(data).cloneNode(true);
					var testContainer = document.querySelector('#tibo-mydash-nonparamdash-scrollpanel');
					var fourChildNode = testContainer.querySelector('.tibo-mydash-nonparamdash-scrollpanel .p-scrollpanel-wrapper .p-scrollpanel-content');
					fourChildNode.insertBefore(document.getElementById(data), fourChildNode.children[this.dragIndex]);
							
					d3.select(nodeCopy).attr("class","chart-div")
					this.chartId = "chartid-" + Math.floor(Math.random() * 10000) + 1;
					d3.select(event.target).attr("id", this.chartId)
					
					
					var spinner = event.target.querySelector('.tibo-mydash-widget-spinner1');
					var clonedSpinner = spinner.cloneNode(true);
					spinner.setAttribute("style","position:absolute; left:45%; top:45%; width:40px; height:40px; display:block;")
					
					var z = document.createElement('button'); // is a node
					z.setAttribute("style","position:absolute; right:-5px; top:-10px; width:23px; height:23px; border-radius:100%; background-image:url('../../assets/images/cancel.png'); background-repeat: no-repeat; background-size: 100% 100%; background-position: center; border:0; z-index:1000;")
					z.addEventListener("click", () => {
						
						event.target.innerHTML = "";
						event.target.id = "";
						event.target.removeAttribute("widgetInfo");
						event.target.appendChild(clonedSpinner);
					}, false);
					event.target.appendChild(z);
						
					this.getWidgetData(this.widgetsList[this.dragIndex]);
	
				}
	
			}
			  else if(this.searchOrDashResult == "dashboard result"){
				console.log(this.searchOrDashResultArray[0]?.widgetControls[this.dragIndex])

			
				 var droppedWidget = this.searchOrDashResultArray[0]?.widgetControls[this.dragIndex];
				console.log('dropwid', this.dragIndex);
				console.log('dropwid', this.searchOrDashResultArray);
				
				
				if(this.searchOrDashResultArray[0].dashboardRequestorParameters?.length > 0){
	
					if(this.isParameterizedWidget == false){
						this.isParameterizedWidget = true;
	
						this.droppedParamWidgetDomainId = Number(this.searchOrDashResultArray[0].dashboardRequestorParameters[0].authorizationId);
						
						this.droppedParamWidgetParameters = this.searchOrDashResultArray[0].dashboardRequestorParameters; // Storing parameterized widget parameters
	
						this.saveDashboardReqPayload.dashboardRequestorParameters = this.searchOrDashResultArray[0].dashboardRequestorParameters;
						droppedWidget["objectCordinates"] = d3.select(event.target).attr("objectCordinates");
						this.saveDashboardReqPayload.widgetControls.push(droppedWidget);
	
						d3.select(event.target).attr("widgetInfo",
							JSON.stringify({parameters:this.searchOrDashResultArray[0].dashboardRequestorParameters,
											controls:droppedWidget}));
	
						event.target.innerHTML = "<div style='width: 100%; height:0%; background-color:#c7c7c7; position: absolute; top:5px; font-size:16px; font-family:Roboto; color:#0f528c; text-indent:5px;'>"+this.widgetsList[this.dragIndex].chartTitle+"</div>"+
						"<p class='p-mx-3' style='position: relative; top:20%; font-family:Roboto; font-size:16px; color:#0f528c; text-align:left;'>Dashboard: "+ this.searchOrDashResultArray[0].widgetName +"</p>"+
						"<p class='p-mx-3' style='position: relative; top:40%; font-family:Roboto; font-size:16px; color:#25c475; text-align:center;'>Widget added to dashboard. It's a parameterized widget!</p>";
	
						var z = document.createElement('button'); // is a node
						z.setAttribute("style","position:absolute; right:-5px; top:-10px; width:23px; height:23px; border-radius:100%; background-image:url('../../assets/images/cancel.png'); background-repeat: no-repeat; background-size: 100% 100%; background-position: center; border:0; z-index:1000;")
						z.addEventListener("click", () => {
						
							event.target.innerHTML = "";
							event.target.id = "";
							event.target.removeAttribute("widgetInfo");
	
							var hasParameterizedWidget = false;
							d3.selectAll(".create-dashboard-widget-container")[0].forEach(wid => {
								if(d3.select(wid).attr("widgetInfo") != null){
									var widgetInfo = JSON.parse(d3.select(wid).attr("widgetInfo"));
									if(widgetInfo.parameters.length > 0){
										hasParameterizedWidget = true;
									}
								}
							});
	
							if(!hasParameterizedWidget){
								this.droppedParamWidgetDomainId = null;
								this.isParameterizedWidget = false;
								this.droppedParamWidgetParameters = []; // Empty the stored parameterized widget parameters
							}
	
						}, false);
						event.target.appendChild(z);
						/*var data = event.dataTransfer.getData("text");
						var nodeCopy = document.getElementById(data).cloneNode(true);
						var testContainer = document.querySelector('#tibo-mydash-nonparamdash-scrollpanel');
						var fourChildNode = testContainer.querySelector('.tibo-mydash-nonparamdash-scrollpanel .p-scrollpanel-wrapper .p-scrollpanel-content');
						fourChildNode.insertBefore(document.getElementById(data), fourChildNode.children[this.dragIndex]);
								
						d3.select(nodeCopy).attr("class","chart-div")
						this.chartId = "chartid-" + Math.floor(Math.random() * 10000) + 1;
						d3.select(event.target).attr("id", this.chartId)
							
						var z = document.createElement('button'); // is a node
						z.setAttribute("style","position:absolute; right:-5px; top:-10px; width:23px; height:23px; border-radius:100%; background-image:url('../../assets/images/cancel.png'); background-repeat: no-repeat; background-size: 100% 100%; background-position: center; border:0; z-index:1000;")
						z.addEventListener("click", () => {
							event.target.innerHTML = "";
						}, false);
						event.target.appendChild(z);
							
						this.getWidgetData(this.widgetsList[this.dragIndex]);*/
	
					}
					else {
						if(this.droppedParamWidgetDomainId == Number(this.searchOrDashResultArray[0].dashboardRequestorParameters[0].authorizationId) ){
	
							this.droppedParamWidgetParameters = this.searchOrDashResultArray[0].dashboardRequestorParameters; // Storing parameterized widget parameters
	
							//this.saveDashboardReqPayload.dashboardRequestorParameters = this.searchOrDashResultArray[0].dashboardRequestorParameters;
							droppedWidget["objectCordinates"] = d3.select(event.target).attr("objectCordinates");
							this.saveDashboardReqPayload.widgetControls.push(droppedWidget);
	
							d3.select(event.target).attr("widgetInfo",
							JSON.stringify({parameters:this.searchOrDashResultArray[0].dashboardRequestorParameters,
											controls:droppedWidget}));
	
							event.target.innerHTML = "<div style='width: 100%; height:0%; background-color:#c7c7c7; position: absolute; top:5px; font-size:16px; font-family:Roboto; color:#0f528c; text-indent:5px;'>"+this.widgetsList[this.dragIndex].chartTitle+"</div>"+
							"<p class='p-mx-3' style='position: relative; top:20%; font-family:Roboto; font-size:16px; color:#0f528c; text-align:left;'>Dashboard: "+ this.searchOrDashResultArray[0].widgetName +"</p>"+
							"<p class='p-mx-3' style='position: relative; top:40%; font-family:Roboto; font-size:16px; color:#25c475; text-align:center;'>Widget added to dashboard. It's a parameterized widget!</p>";
	
							var z = document.createElement('button'); // is a node
							z.setAttribute("style","position:absolute; right:-5px; top:-10px; width:23px; height:23px; border-radius:100%; background-image:url('../../assets/images/cancel.png'); background-repeat: no-repeat; background-size: 100% 100%; background-position: center; border:0; z-index:1000;")
							z.addEventListener("click", () => {
								
								event.target.innerHTML = "";
								event.target.id = "";
								event.target.removeAttribute("widgetInfo");
	
								var hasParameterizedWidget = false;
								d3.selectAll(".create-dashboard-widget-container")[0].forEach(wid => {
									if(d3.select(wid).attr("widgetInfo") != null){
										var widgetInfo = JSON.parse(d3.select(wid).attr("widgetInfo"));
										if(widgetInfo.parameters.length > 0){
											hasParameterizedWidget = true;
										}
									}
								});
	
								if(!hasParameterizedWidget){
									this.droppedParamWidgetDomainId = null;
									this.isParameterizedWidget = false;
									this.droppedParamWidgetParameters = []; // Empty the stored parameterized widget parameters
								}
	
								
							}, false);
							event.target.appendChild(z);
							/*var data = event.dataTransfer.getData("text");
							var nodeCopy = document.getElementById(data).cloneNode(true);
							var testContainer = document.querySelector('#tibo-mydash-nonparamdash-scrollpanel');
							var fourChildNode = testContainer.querySelector('.tibo-mydash-nonparamdash-scrollpanel .p-scrollpanel-wrapper .p-scrollpanel-content');
							fourChildNode.insertBefore(document.getElementById(data), fourChildNode.children[this.dragIndex]);
									
							d3.select(nodeCopy).attr("class","chart-div")
							this.chartId = "chartid-" + Math.floor(Math.random() * 10000) + 1;
							d3.select(event.target).attr("id", this.chartId)
								
							var z = document.createElement('button'); // is a node
							z.setAttribute("style","position:absolute; right:-5px; top:-10px; width:23px; height:23px; border-radius:100%; background-image:url('../../assets/images/cancel.png'); background-repeat: no-repeat; background-size: 100% 100%; background-position: center; border:0; z-index:1000;")
							z.addEventListener("click", () => {
								event.target.innerHTML = "";
							}, false);
							event.target.appendChild(z);
								
							this.getWidgetData(this.widgetsList[this.dragIndex]);*/
	
						}
						else {
							alert();
						}
	
					}
					
				}
				else {
					console.log( d3?.select(event.target)?.attr("objectCordinates"))
					droppedWidget["objectCordinates"] = d3?.select(event.target)?.attr("objectCordinates");
					this.saveDashboardReqPayload.widgetControls.push(droppedWidget);
	
					d3.select(event.target).attr("widgetInfo",
							JSON.stringify({parameters:this.searchOrDashResultArray[0].dashboardRequestorParameters,
											controls:droppedWidget}));
	
					var data = event.dataTransfer.getData("text");
					var nodeCopy = document.getElementById(data).cloneNode(true);
					var testContainer = document.querySelector('#tibo-mydash-nonparamdash-scrollpanel');
					var fourChildNode = testContainer.querySelector('.tibo-mydash-nonparamdash-scrollpanel .p-scrollpanel-wrapper .p-scrollpanel-content');
					fourChildNode.insertBefore(document.getElementById(data), fourChildNode.children[this.dragIndex]);
							
					d3.select(nodeCopy).attr("class","chart-div")
					this.chartId = "chart" + this.widgetsList[this.dragIndex].id;
					d3.select(event.target).attr("id", this.chartId)
					
	
					var spinner = event.target.querySelector('.tibo-mydash-widget-spinner1');
					var clonedSpinner = spinner.cloneNode(true);
					spinner.setAttribute("style","position:absolute; left:45%; top:45%; width:40px; height:40px; display:block;")
					
					var z = document.createElement('button'); // is a node
					z.setAttribute("style","position:absolute; right:-5px; top:-10px; width:23px; height:23px; border-radius:100%; background-image:url('../../assets/images/cancel.png'); background-repeat: no-repeat; background-size: 100% 100%; background-position: center; border:0; z-index:1000;")
					z.addEventListener("click", () => {
						event.target.setAttribute("style","background-color:white");
						event.target.innerHTML = "";
						event.target.id = "";
						event.target.removeAttribute("widgetInfo");
						event.target.appendChild(clonedSpinner);
					}, false);
					event.target.appendChild(z);
	
					
					this.getWidgetData(this.widgetsList[this.dragIndex]);
					
	
				}
	
			}
	
		}
		
	}
	allowDrop(event) {
		//event.stopPropagation();
		event.preventDefault();
	  }
	
	  dragStart(event,ind) {
		event.dataTransfer.setData("text/plain", event.target.id);
		//event.dataTransfer.effectAllowed = "copy";
		this.dragIndex = ind;
	  }
	
	  dragEnd(event) {
		//document.getElementById("demo").innerHTML = "Dropped";
	  }

  removeWidget(id){
  }

}
