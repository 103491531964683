import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MyReportsService } from './my-reports.service';
import { Subscription } from 'rxjs';
import { Message, MessageService } from 'primeng/api';
import { SortEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { ViewChild } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AllOutputFiles } from './all-output-files';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridReadyEvent,ColumnApi, SideBarDef, GridOptions } from '@ag-grid-community/core';

import { Router } from '@angular/router';
import { log } from 'console';

interface TreeNode {
  label?: string;
  data?: any;
  icon?: any;
  expandedIcon?: any;
  collapsedIcon?: any;
  children?: any;
  leaf?: boolean;
  authorizationId?: any;
  hasChildren?: boolean;
  expanded?: boolean;
}

declare var d3: any;

@Component({
  selector: 'app-my-reports',
  templateUrl: './my-reports.component.html',
  styleUrls: ['./my-reports.component.css'],
  providers: [MyReportsService, MessageService, DialogService, AllOutputFiles],
})
export class MyReportsComponent implements OnInit, OnDestroy {
  @ViewChild('myreportstable', { static: true }) table: Table;

  

  files: TreeNode[];
  selectedFile: TreeNode;
  errorMsg = '';
  alertMessage: Message[];
  folderObjParent = {};
  folderObjChild = {};
  subFolderObjChild = {};
  directoryArray = [];
  directoryChildrenArray = [];
  totalRecords = 0;
  myReportRecords = [];
  authorizationId?: any;
  folderPanelToggleBool: boolean = false;
  stateOptions: any[];
  listOrTileView = 'List View';
  settingsResponse?: any;
  requestPayload = { sort: [], filters: [], filterType: 'or' };
  paginatorPageNo: number = 1;
  tableSortColumn = '';
  tableSortDir: number;
  customSortBool: boolean = false;
  tableEmptyMessage: string = '';
  repoCritPanelToggleBool: boolean = false;
  sideMenuCollapse: boolean = false;
  tileView: boolean = false;
	leaveStand: string;
	leaveStandUrl: any;
  gridApi: any;


  constructor(
    private messageService: MessageService,
    public dialogService: DialogService,
    private http: HttpClient,
    private myRepoService: MyReportsService,
    private router: Router
  ) {}

  directoryListSubscrip0: Subscription;
  directoryListSubscrip1: Subscription;
  directoryListSubscrip2: Subscription;
  recordsSubscrip: Subscription;
  settingsGetSubscrip: Subscription;
  settingsPostSubscrip: Subscription;
  addToFavoritesSubscrip: Subscription;

  ref: DynamicDialogRef;
  columnDefs: ColDef[] = [];
  public defaultColDef: ColDef = {
    cellStyle: {'padding-left': 0 ,'text-align':"center"},
    flex:1,
    minWidth: 150, 
    maxWidth: 250,
    resizable: true,
    sortable: true,
    filter: true,
    editable:true,
    getQuickFilterText: params => {
      return params.value.name;
  }
    
  };
  

  


  ngOnInit(): void {
    //this.sideMenuCollapse=true
	// this.selectedFile.label = 'Leave';
    d3.select('.tibo-topbar-title').text('Home / Reports'); // setting title
	d3.select('.my-reports-list-view').style('display', 'block');
  
    // if(Number(d3.select("#tibo-large-leftpanel-container").node().getBoundingClientRect().width) == 64){
    // 	setTimeout(function(){
    // 		d3.select(".tibo-right-container").transition().delay(0).duration(0).style("left","230px").ease("linear");
    // 	},10)
    // }

    // this.settingsGetSubscrip = this.myRepoService
    //   .settingsGetService()
    //   .subscribe(
    //     (response: any) => {
    //       // settings
    //       this.settingsResponse = response.body;

    //       if (this.settingsResponse.viewStyle == 'Tile') {
    //         this.listOrTileView = 'Tile View';
    //         d3.select('.my-reports-tile-view').style('display', 'block');
    //       } else if (this.settingsResponse.viewStyle == 'List') {
    //         this.listOrTileView = 'List View';
    //         d3.select('.my-reports-list-view').style('display', 'block');
    //       }
    //     },
    //     (error) => {
    //       /*if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
	// 		else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
	// 		else { this.errorMsg = "Please try after some time." }
			
	// 		this.addSingle()*/
    //     },
    //     () => {}
    //   );

    this.stateOptions = [
      { icon: 'pi pi-th-large', value: 'Tile View' },
      { icon: 'pi pi-list', value: 'List View' },
    ];

    // this.directoryListSubscrip0 = this.myRepoService
    //   .directoryService(0)
    //   .subscribe(
    //     (folderResp: any) => {
    //       // directorys list
    //       console.log('tree res', folderResp);

    //       this.folderObjParent = {
    //         label: folderResp.body[0].authorizationName,
    //         data: '',
    //         expandedIcon: 'pi pi-folder-open',
    //         collapsedIcon: 'pi pi-folder',
    //         children: [],
    //         authorizationId: folderResp.body[0].authorizationId,
    //         expanded: true,
    //         hasChildren: folderResp.body[0].hasChildren,
    //       };

    //       this.selectedFile = this.folderObjParent;

    //       if (folderResp.body[0].hasChildren == true) {
    //         this.directoryListSubscrip1 = this.myRepoService
    //           .directoryService(folderResp.body[0].authorizationId)
    //           .subscribe(
    //             (subFolderResp: any) => {
    //               // directorys list
    //               console.log('sub tree', subFolderResp);

    //               this.authorizationId = folderResp.body[0].authorizationId; // save authorization id globally

    //               subFolderResp.body.forEach((subFolder) => {
    //                 if (subFolder.hasChildren) {
    //                   this.folderObjChild = {
    //                     label: subFolder.authorizationName,
    //                     data: '',
    //                     expandedIcon: 'pi pi-folder-open',
    //                     collapsedIcon: 'pi pi-folder',
    //                     children: [{ label: '' }],
    //                     authorizationId: subFolder.authorizationId,
    //                     hasChildren: subFolder.hasChildren,
    //                   };

    //                   this.folderObjParent['children'].push(
    //                     this.folderObjChild
    //                   );
    //                   this.folderObjChild = {};
    //                 } else {
    //                   this.folderObjChild = {
    //                     label: subFolder.authorizationName,
    //                     data: '',
    //                     expandedIcon: 'pi pi-folder-open',
    //                     collapsedIcon: 'pi pi-folder',
    //                     children: [],
    //                     authorizationId: subFolder.authorizationId,
    //                     hasChildren: subFolder.hasChildren,
    //                   };

    //                   this.folderObjParent['children'].push(
    //                     this.folderObjChild
    //                   );
    //                   this.folderObjChild = {};
    //                 }
    //               });
	this.files = [
		{
		  label: 'Leave',
		  data: 'Documents Folder',
		  expandedIcon: 'pi pi-folder-open',
		  collapsedIcon: 'pi pi-folder',
		  // "children": [{
		  //    "label": "Standard Report",
		  //    "data": "Work Folder",
		  //    "expandedIcon": "pi pi-folder-open",
		  //    "collapsedIcon": "pi pi-folder",
		  //    "children": false ,
		  //  }]
		  children: [
			{
			  label: 'Standard Report',
			  icon: 'pi pi-file',
			  data: 'Invoices for this month',
			  id:'leaveStand',
			 
			},
		  ],
		},
		{
		  label: 'Disability',
		  data: 'Disability Folder',
		  expandedIcon: 'pi pi-folder-open',
		  collapsedIcon: 'pi pi-folder',
		  children: [
			{
			  label: 'Standard Report',
			  icon: 'pi pi-file',
			  data: 'Invoices for this month',
			  id:'summaryStand',
			},
		  ],
		},
	  ];
    this.selectedFile =this.files[0].children[0];
		this.myReportRecords = [
		  {
			authorizationId: 3274,
			authorizationName: 'Leave Details'+'.rptdesign',
			authorizationDesc: 'details',
			resourceId: 'reports',
			createdDate: 1448451133000,
			modifiedDate: 1667636576000,
			isSchedulable: 'N',
			isRunnable: 'Y',
			folderPath: null,
			parentId: 1,
			reportType: 'SQL',
			extension: 'birt',
			uniqueName: '1-05112022012254.rptdesign',
		  },
		  {
			authorizationId: 17510,
			authorizationName:  'Leave Summary'+'.rptdesign',
			authorizationDesc: 'Sample font test',
			resourceId: 'sample_font_test',
			createdDate: 1614932998000,
			modifiedDate: null,
			isSchedulable: 'Y',
			isRunnable: 'Y',
			folderPath: null,
			parentId: 1,
			reportType: 'Custom',
			extension: 'birt',
			uniqueName: '1-05032021012740.rptdesign',
		  },
		];
        this.tableEmptyMessage = 'No Records Found';
                // },
            //     (error) => {
            //       d3.select('.tibo-myrepo-reports-spinner').style(
            //         'display',
            //         'none'
            //       ); // hidding reports container spinner
            //       if (Number(error.status) == 401) {
            //         this.errorMsg = 'Please try after some time!';
            //       } else if (Number(error.status) == 500) {
            //         this.errorMsg = 'Internal error';
            //       } else {
            //         this.errorMsg = 'Please try after some time.';
            //       }

            //       this.addSingle();
            //     },
            //     () => {}
            //   ); // directoryService
        //   }

		//   this.myReportRecords = [
		// 	{
		// 		"authorizationId": 3274,
		// 		"authorizationName": "Leave Details.rptdesign",
		// 		"authorizationDesc": "details",
		// 		"resourceId": "reports",
		// 		"createdDate": 1448451133000,
		// 		"modifiedDate": 1667636576000,
		// 		"isSchedulable": "N",
		// 		"isRunnable": "Y",
		// 		"folderPath": null,
		// 		"parentId": 1,
		// 		"reportType": "SQL",
		// 		"extension": "birt",
		// 		"uniqueName": "1-05112022012254.rptdesign"
		// 	},
		// 	{
		// 		"authorizationId": 17510,
		// 		"authorizationName": "Leave Summary.rptdesign",
		// 		"authorizationDesc": "Sample font test",
		// 		"resourceId": "sample_font_test",
		// 		"createdDate": 1614932998000,
		// 		"modifiedDate": null,
		// 		"isSchedulable": "Y",
		// 		"isRunnable": "Y",
		// 		"folderPath": null,
		// 		"parentId": 1,
		// 		"reportType": "Custom",
		// 		"extension": "birt",
		// 		"uniqueName": "1-05032021012740.rptdesign"
		// 	}
		// ]
        //   this.recordsSubscrip = this.myRepoService
        //     .recordsService(
        //       folderResp.body[0].authorizationId,
        //       this.paginatorPageNo,
        //       this.requestPayload
        //     )
        //     .subscribe(
        //       (recordsResp: any) => {
        //         // records
		// 		console.log("table", recordsResp);
				
        //         // d3.select('.tibo-myrepo-reports-spinner').style(
        //         //   'display',
        //         //   'none'
        //         // ); // hidding reports container spinner
        //         this.totalRecords = recordsResp.body?.totalRecords;
        //         recordsResp.body?.records
        //           ? (this.myReportRecords = recordsResp.body.records)
        //           : (this.myReportRecords = []);

        //         this.myReportRecords.length > 0
        //           ? (this.tableEmptyMessage = '')
        //           : (this.tableEmptyMessage = 'No records found');
        //       },
        //       (error) => {
        //         d3.select('.tibo-myrepo-reports-spinner').style(
        //           'display',
        //           'none'
        //         ); // hidding reports container spinner
        //         if (Number(error.status) == 401) {
        //           this.errorMsg = 'Please try after some time!';
        //         } else if (Number(error.status) == 500) {
        //           this.errorMsg = 'Internal error';
        //         } else {
        //           this.errorMsg = 'Please try after some time.';
        //         }

        //         this.addSingle();
        //       },
        //       () => {}
        //     );
        // },
    //     (error) => {
    //       d3.select('.tibo-myrepo-reports-spinner').style('display', 'none'); // hidding reports container spinner
    //       if (Number(error.status) == 401) {
    //         this.errorMsg = 'Please try after some time!';
    //       } else if (Number(error.status) == 500) {
    //         this.errorMsg = 'Internal error';
    //       } else {
    //         this.errorMsg = 'Please try after some time.';
    //       }

    //       this.addSingle();
    //     },
    //     () => {}
    //   ); // directoryService
  }

  ngOnDestroy() {
    //if(this.directoryListSubscrip0){
    this.directoryListSubscrip0?.unsubscribe();
    //}
    if (this.directoryListSubscrip1) {
      this.directoryListSubscrip1.unsubscribe();
    }
    if (this.directoryListSubscrip2) {
      this.directoryListSubscrip2.unsubscribe();
    }
    if (this.recordsSubscrip) {
      this.recordsSubscrip.unsubscribe();
    }
    if (this.settingsGetSubscrip) {
      this.settingsGetSubscrip.unsubscribe();
    }
    if (this.settingsPostSubscrip) {
      this.settingsPostSubscrip.unsubscribe();
    }
    if (this.addToFavoritesSubscrip) {
      this.addToFavoritesSubscrip.unsubscribe();
    }
    if (this.ref) {
      this.ref.close();
    }
  }

  /*theadClick(event){
		event.target.attributes.class.value.includes("pi-filter-icon") ? this.customSortBool = false : this.customSortBool = true;
		console.log(this.customSortBool)
	}*/

  customSort(event: SortEvent) {
    this.tableSortColumn = event.field;
    this.tableSortDir = event.order;

    this.table.reset();
    this.table.sortField = this.tableSortColumn;
    this.table._sortOrder = this.tableSortDir;

    // console.log(this.tableSortColumn, this.tableSortDir, this.customSortBool)

    //if(this.customSortBool){
    //this.customSortBool = false;
    // console.log("sort")
    //}

    d3.select('.tibo-myrepo-reports-spinner').style('display', 'block'); // showing reports container spinner

    this.requestPayload.sort = [];

    if (event.order == 1) {
      this.requestPayload.sort.push({ property: event.field, dir: 'ASC' });
    } else if (event.order == -1) {
      this.requestPayload.sort.push({ property: event.field, dir: 'DESC' });
    }

    this.recordsSubscrip = this.myRepoService
      .recordsService(
        this.authorizationId,
        this.paginatorPageNo,
        this.requestPayload
      )
      .subscribe(
        (recordsResp: any) => {
          // records

          d3.select('.tibo-myrepo-reports-spinner').style('display', 'none'); // hidding reports container spinner

          let sortedData = [];
          recordsResp.body?.records
            ? (sortedData = recordsResp.body.records)
            : (sortedData = []);

          event.data.splice(0, event.data.length);

          sortedData.forEach((d) => {
            event.data.push(d);
          });
        },
        (error) => {
          d3.select('.tibo-myrepo-reports-spinner').style('display', 'none'); // hidding reports container spinner
          if (Number(error.status) == 401) {
            this.errorMsg = 'Please try after some time!';
          } else if (Number(error.status) == 500) {
            this.errorMsg = 'Internal error';
          } else {
            this.errorMsg = 'Please try after some time.';
          }

          this.addSingle();
        },
        () => {}
      );
  }

  nodeExpand(event) {
    if (event.node.hasChildren == true) {
      this.directoryListSubscrip2 = this.myRepoService
        .directoryService(event.node.authorizationId)
        .subscribe(
          (subFolderResp: any) => {
            // directorys list

            //console.log(subFolderResp.body)

            if (subFolderResp.body != null) {
              let arr = [];
              subFolderResp.body.forEach((subFolder) => {
                if (subFolder.hasChildren) {
                  this.folderObjChild = {
                    label: subFolder.authorizationName,
                    data: '',
                    expandedIcon: 'pi pi-folder-open',
                    collapsedIcon: 'pi pi-folder',
                    children: [{ label: '' }],
                    authorizationId: subFolder.authorizationId,
                    hasChildren: subFolder.hasChildren,
                  };
                  ////console.log(this.folderObjChild)
                  arr.push(this.folderObjChild);
                  ////console.log(event.node)
                  this.folderObjChild = {};
                  //event.node.expanded = true;
                } else {
                  this.folderObjChild = {
                    label: subFolder.authorizationName,
                    data: '',
                    expandedIcon: 'pi pi-folder-open',
                    collapsedIcon: 'pi pi-folder',
                    children: [],
                    authorizationId: subFolder.authorizationId,
                    hasChildren: subFolder.hasChildren,
                  };

                  arr.push(this.folderObjChild);
                  this.folderObjChild = {};
                  //event.node.expanded = true;
                }
              });

              event.node.children = arr;
              event.node.expanded = true;
            }
          },
          (error) => {
            if (Number(error.status) == 401) {
              this.errorMsg = 'Please try after some time!';
            } else if (Number(error.status) == 500) {
              this.errorMsg = 'Internal error';
            } else {
              this.errorMsg = 'Please try after some time.';
            }

            this.addSingle();
          },
          () => {}
        ); // directoryService
    }
  }

  nodeSelect(event,selectedFile1) {
    console.log(selectedFile1)
	this.leaveStandUrl = event;
    console.log("node", event);
    this.table?.clear();
    this.myReportRecords = [];

    this.tableEmptyMessage = '';

    this.authorizationId = event.node.authorizationId; // save authorization id globally
    this.myReportRecords = [];
    d3.select('.tibo-myrepo-reports-spinner').style('display', 'block'); // showing reports container spinner
    if (event.node.label === 'Standard Report') {
		let name1 = '';
		let name2 = '';
		this.leaveStand = event.node.id;
		if(event.node.id === 'leaveStand'){
		  name1 = 'Leave Details';
		  name2 = 'Leave Summary';
		}
		if(event.node.id === 'summaryStand'){
		  name1 = 'Disability Details';
		  name2 = 'Disability Summary';
		}
		this.myReportRecords = [
		  {
			authorizationId: 3274,
			authorizationName: name1+'.rptdesign',
			authorizationDesc: 'details',
			resourceId: 'reports',
			createdDate: 1448451133000,
			modifiedDate: 1667636576000,
			isSchedulable: 'N',
			isRunnable: 'Y',
			folderPath: null,
			parentId: 1,
			reportType: 'SQL',
			extension: 'birt',
			uniqueName: '1-05112022012254.rptdesign',
		  },
		  {
			authorizationId: 17510,
			authorizationName: name2+'.rptdesign',
			authorizationDesc: 'Sample font test',
			resourceId: 'sample_font_test',
			createdDate: 1614932998000,
			modifiedDate: null,
			isSchedulable: 'Y',
			isRunnable: 'Y',
			folderPath: null,
			parentId: 1,
			reportType: 'Custom',
			extension: 'birt',
			uniqueName: '1-05032021012740.rptdesign',
		  },
		];
	  } else {
		this.myReportRecords = [];
		this.tableEmptyMessage = 'No Records Found';
	  }
    // if (event.node.hasChildren == true) {
    //   this.directoryListSubscrip2 = this.myRepoService
    //     .directoryService(event.node.authorizationId)
    //     .subscribe(
    //       (subFolderResp: any) => {
    //         // directorys list

    //         if (subFolderResp.body != null) {
    //           let arr = [];
    //           subFolderResp.body.forEach((subFolder) => {
    //             if (subFolder.hasChildren) {
    //               this.folderObjChild = {
    //                 label: subFolder.authorizationName,
    //                 data: '',
    //                 expandedIcon: 'pi pi-folder-open',
    //                 collapsedIcon: 'pi pi-folder',
    //                 children: [{ label: '' }],
    //                 authorizationId: subFolder.authorizationId,
    //                 hasChildren: subFolder.hasChildren,
    //               };
    //               arr.push(this.folderObjChild);
    //               this.folderObjChild = {};
    //               //event.node.expanded = true;
    //             } else {
    //               this.folderObjChild = {
    //                 label: subFolder.authorizationName,
    //                 data: '',
    //                 expandedIcon: 'pi pi-folder-open',
    //                 collapsedIcon: 'pi pi-folder',
    //                 children: [],
    //                 authorizationId: subFolder.authorizationId,
    //                 hasChildren: subFolder.hasChildren,
    //               };

    //               arr.push(this.folderObjChild);
    //               this.folderObjChild = {};
    //               //event.node.expanded = true;
    //             }
    //           });

    //           event.node.children = arr;
    //           event.node.expanded = true;
    //         }
    //       },
    //       (error) => {
    //         d3.select('.tibo-myrepo-reports-spinner').style('display', 'none'); // hidding reports container spinner
    //         if (Number(error.status) == 401) {
    //           this.errorMsg = 'Please try after some time!';
    //         } else if (Number(error.status) == 500) {
    //           this.errorMsg = 'Internal error';
    //         } else {
    //           this.errorMsg = 'Please try after some time.';
    //         }

    //         this.addSingle();
    //       },
    //       () => {}
    //     ); // directoryService
    // }

    // this.recordsSubscrip = this.myRepoService
    //   .recordsService(
    //     event.node.authorizationId,
    //     this.paginatorPageNo,
    //     this.requestPayload
    //   )
    //   .subscribe(
    //     (recordsResp: any) => {
    //       // records

    //       d3.select('.tibo-myrepo-reports-spinner').style('display', 'none'); // hidding reports container spinner

    //       this.totalRecords = recordsResp.body.totalRecords;
    //       recordsResp.body?.records
    //         ? (this.myReportRecords = recordsResp.body.records)
    //         : (this.myReportRecords = []);

    //       this.myReportRecords.length > 0
    //         ? (this.tableEmptyMessage = '')
    //         : (this.tableEmptyMessage = 'No records found');
    //     },
    //     (error) => {
    //       d3.select('.tibo-myrepo-reports-spinner').style('display', 'none'); // hidding reports container spinner
    //       if (Number(error.status) == 401) {
    //         this.errorMsg = 'Please try after some time!';
    //       } else if (Number(error.status) == 500) {
    //         this.errorMsg = 'Internal error';
    //       } else {
    //         this.errorMsg = 'Please try after some time.';
    //       }

    //       this.addSingle();
    //     },
    //     () => {}
    //   );
  }

  nodeUnselect(event) {
    //this.messageService.add({severity: 'info', summary: 'Node Unselected', detail: event.node.label});
    console.log(this.selectedFile)
  }

  paginate(event) {
    d3.select('.tibo-myrepo-reports-spinner').style('display', 'block'); // showing reports container spinner

    this.paginatorPageNo = event.page + 1;

    //this.table.clear();
    this.myReportRecords = [];

    this.recordsSubscrip = this.myRepoService
      .recordsService(this.authorizationId, event.page + 1, this.requestPayload)
      .subscribe(
        (recordsResp: any) => {
          // records

          d3.select('.tibo-myrepo-reports-spinner').style('display', 'none'); // hidding reports container spinner
          this.totalRecords = recordsResp.body.totalRecords;
          recordsResp.body?.records
            ? (this.myReportRecords = recordsResp.body.records)
            : (this.myReportRecords = []);
        },
        (error) => {
          d3.select('.tibo-myrepo-reports-spinner').style('display', 'none'); // hidding reports container spinner
          if (Number(error.status) == 401) {
            this.errorMsg = 'Please try after some time!';
          } else if (Number(error.status) == 500) {
            this.errorMsg = 'Internal error';
          } else {
            this.errorMsg = 'Please try after some time.';
          }

          this.addSingle();
        },
        () => {}
      );
  }

  changeViewFunc(event) {
	this.tileView = !this.tileView;
    if (event.value == 'Tile View') {
      d3.select('.my-reports-tile-view').style('display', 'block');
      d3.select('.my-reports-list-view').style('display', 'none');
      this.settingsResponse['viewStyle'] = 'Tile';

      this.settingsPostSubscrip = this.myRepoService
        .settingsPostService(this.settingsResponse)
        .subscribe(
          (response: any) => {
            // records
          },
          (error) => {
            if (Number(error.status) == 401) {
              this.errorMsg = 'Please try after some time!';
            } else if (Number(error.status) == 500) {
              this.errorMsg = 'Internal error';
            } else {
              this.errorMsg = 'Please try after some time.';
            }

            this.addSingle();
          },
          () => {}
        );
    } else if (event.value == 'List View') {
      d3.select('.my-reports-tile-view').style('display', 'none');
      d3.select('.my-reports-list-view').style('display', 'block');
      this.settingsResponse['viewStyle'] = 'List';

      this.settingsPostSubscrip = this.myRepoService
        .settingsPostService(this.settingsResponse)
        .subscribe(
          (response: any) => {
            // records
          },
          (error) => {
            if (Number(error.status) == 401) {
              this.errorMsg = 'Please try after some time!';
            } else if (Number(error.status) == 500) {
              this.errorMsg = 'Internal error';
            } else {
              this.errorMsg = 'Please try after some time.';
            }

            this.addSingle();
          },
          () => {}
        );
    }
  }

  tabelResetFunc(table) {
    table.clear();
  }

  folderPanelToggle() {
	//this.files = this.directoryArray;
	if (this.folderPanelToggleBool == false) {
		d3.selectAll('#myreport-folder-panel-scroll-div').transition().delay(200).duration(400).style('display', 'none');
		d3.select('#myreports-folderview-panel').transition().delay(200).duration(500).style('width', '40px');
		d3.select('#myreports-tile-list-scroll-view').transition().delay(200).duration(500).style({left: '50px',width: '96%',height: '100%','animation-name': 'reducetime',
				float: 'right',
			}); //.style("width","calc(100% - 50px)")
		d3.select('#myreports-folderview-panel').select('button').style('transform', 'rotate(180deg)');
		d3.select('#all-reports-id').transition().delay(100).duration(100).style({ left: '-14px', top: '70px' }).style('transform', 'rotate(-90deg)');
		this.folderPanelToggleBool = true;
	} else {
		d3.select('#myreport-folder-panel-scroll-div').transition().delay(300).duration(400).style('display', 'block');
		//d3.select("#submitbutton-id").transition().delay(500).duration(400).style("display","block")
		d3.select('#myreports-folderview-panel').transition().delay(200).duration(500).style('width', '350px');
		d3.select('#myreports-tile-list-scroll-view').transition().delay(200).duration(500).style({ left: '360px', width: '75%', height: '100%' }); //.style("width","calc(100% - 360px)")
		d3.select('#myreports-folderview-panel').select('button').style('transform', 'rotate(0deg)');
		d3.select('#all-reports-id').transition().delay(250).duration(100).style({ left: '10px', top: '10px' }).style('transform', 'rotate(0deg)');
		this.folderPanelToggleBool = false;
	}
}

  repoCriteriaPanelToggle() {
    //this.files = this.directoryArray;

    if (this.repoCritPanelToggleBool == false) {
      d3.selectAll('#myrepo-output-paramspanl-scrollpanel, #submitbutton-id').transition().delay(200).duration(400).style('display', 'none');
      d3.select('#myrepo-output-parameters-panel').transition().delay(200).duration(500).style('width', '40px');
      d3.select('#myrepo-output-container').transition().delay(200).duration(500).style('left', '50px'); //.style("width","calc(100% - 50px)")
      d3.select('#myrepo-output-parameters-panel').select('button').style('transform', 'rotate(180deg)');
      d3.select('#report-criteria-id').transition().delay(100).duration(100).style({ left: '-25px', top: '85px' }).style('transform', 'rotate(-90deg)');
      this.repoCritPanelToggleBool = true;
    } else {
      d3.select('#myrepo-output-paramspanl-scrollpanel').transition().delay(300).duration(400).style('display', 'block');
      d3.select('#submitbutton-id').transition().delay(500).duration(400).style('display', 'block');
      d3.select('#myrepo-output-parameters-panel').transition().delay(200).duration(500).style('width', '350px');
      d3.select('#myrepo-output-container').transition().delay(200).duration(500).style('left', '360px'); //.style("width","calc(100% - 360px)")
      d3.select('#myrepo-output-parameters-panel').select('button').style('transform', 'rotate(0deg)');
      d3.select('#report-criteria-id').transition().delay(250).duration(100).style({ left: '10px', top: '10px' }).style('transform', 'rotate(0deg)');
      this.repoCritPanelToggleBool = false;
    }
  }

//   onRunButtonClick() {
	
// 	if(this.leaveStand == "leaveStand"){
// 		this.router.navigate(['/leave-table'])
// 	}else if (this.leaveStand == "summaryStand"){
// 		this.router.navigate(['/disability-table'])
// 	}
//   }

onRunButtonClick(data:any) {

    const a = [{...this.leaveStandUrl}]
	console.log('hiii',data)
    console.log("leave",a)
    let value = a[0].node?.id;
    if(value==""||value==undefined||value==null){
      value = 'leaveStand';
    }
    if(value === 'leaveStand'){
		if(data == 3274){
			this.router.navigate(['/leave-table']);
		}
		else if(data == 17510){
			this.router.navigate(['/leave-summary']);
		}
      console.log("leave called");
      
    }else if(value === 'summaryStand'){
		if(data == 3274){
			this.router.navigate(['/disability-table']);
		}
		else if(data == 17510){
			this.router.navigate(['/disability-summary']);
		}
      // this.router.navigate(['']);
      console.log("summary called");
    }
  }


  onScheduleBtnClick(reportId, reportName, authDesc) {
    this.router.navigate(['./scheduler'], {
      queryParams: { id: reportId, name: reportName, authDesc: authDesc },
    });
  }

  onAddToMyFavoritesBtnClick(reportId, reportName, authDesc) {
    this.addToFavoritesSubscrip = this.myRepoService
      .reportAddToFavoritesService(reportId)
      .subscribe(
        (recordsResp: any) => {
          // records
          this.messageService.add({
            severity: 'success',
            summary: '',
            detail: 'Successfully added to your favorites!',
          });
        },
        (error) => {
          if (Number(error.status) == 401) {
            this.errorMsg = 'Please try after some time!';
          } else if (Number(error.status) == 500) {
            this.errorMsg = 'Internal error';
          } else {
            this.errorMsg = 'Please try after some time.';
          }

          this.addSingle();
        },
        () => {}
      );
  }

  addSingle() {
    this.messageService.add({
      severity: 'error',
      summary: '',
      detail: this.errorMsg,
    });
  }

  clear() {
    this.messageService.clear();
  }

  showAllOutputFilesBtnClick(reportId) {
    this.ref = this.dialogService.open(AllOutputFiles, {
      data: {
        reportId: reportId,
      },
      header: 'Output Files',
      width: '70%',
      height: '80%',
      contentStyle: {
        'min-height': '350px',
        height: '100%',
        'background-color': '#f1f1f4',
        overflow: 'hidden',
      },
      baseZIndex: 10000,
    });
  }

  collapseSideMenu() {
    this.sideMenuCollapse = !this.sideMenuCollapse;
  }

}
