import { Component, OnInit } from '@angular/core';
import { SchedulerService } from './scheduler.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';

declare var d3: any;

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.css'],
  providers: [SchedulerService, MessageService],
})
export class SchedulerComponent implements OnInit {
  header: any;

  constructor(
    public activatedRoute: ActivatedRoute,
    private schedulerService: SchedulerService,
    private messageService: MessageService
  ) {}

  reportId?: any;
  reportName?: any;
  authDesc?: any;
  parameterElementsArray = [];
  stateOptions: any[];
  Options: any[];
  fileFormatOptions = [];
  scheduleOccurrenceValue = '';
  fileValue = '';
  reportOutputType = '';
  minDate: Date;
  monthlyOccurList1?: any;
  monthlyOccurList2?: any;
  userEmail = '';
  parameters = {};
  dailyOccurrenceDayVal = 1;
  dailyOccurrence = {
    advHours: null,
    advMinutes: null,
    improvedTriggerType: 'daily',
    isEveryWeekday: '',
    recurrenceInterval: 1,
    startDatetime: '',
    endDatetime: '',
  };
  weeklyOccurrence = {
    advHours: null,
    advMinutes: null,
    improvedTriggerType: 'weekly',
    isEveryWeekday: 'Y',
    startDatetime: '',
    endDatetime: '',
    weekDays: [],
  };
  monthlyOccurrenceDayVal1 = 1;
  monthlyOccurrenceMonthVal = 1;
  monthlyOccurrenceMonthVal2 = 1;
  monthlyOccurrence = {
    advHours: null,
    advMinutes: null,
    improvedTriggerType: 'monthly',
    isEveryWeekday: 'Y',
    isDayOrDate: '',
    monthDays: 1,
    recurrenceInterval: 1,
    startDatetime: '',
    endDatetime: '',
  };

  reportIdSubscrip: Subscription;
  scheFormSerSubscrip: Subscription;
  reportParamSerSubscrip: Subscription;
  scheRepoSerSubscrip: Subscription;

  alertSeverity: string = 'error';
  alertMsg: string = '';

  lang = [
    { name: 'ABC COMPANY' },
    // { name: "2" },
    // { name: "3" },
    // { name: "4" },
    // { name: "5" },
  ];

  ngOnInit(): void {
    d3.select('.tibo-topbar-title').text('Home / Scheduled Reports');

    if (
      Number(
        d3
          .select('#tibo-large-leftpanel-container')
          .node()
          .getBoundingClientRect().width
      ) == 64
    ) {
      setTimeout(function () {
        d3.select('.tibo-right-container')
          .transition()
          .delay(0)
          .duration(0)
          .style('left', '64px')
          .ease('linear');
      }, 10);
    }

    this.userEmail = localStorage.getItem('userEmail');
    this.header = this.activatedRoute.snapshot.queryParams.name.replace(
      '.rptdesign',
      ''
    );
    this.minDate = new Date();

    this.monthlyOccurList1 = [
      { label: 'First', value: 'First' },
      { label: 'Second', value: 'Second' },
      { label: 'Third', value: 'Third' },
      { label: 'Fourth', value: 'Fourth' },
      { label: 'Last', value: 'Last' },
    ];

    this.monthlyOccurList2 = [
      { label: 'Day', value: 'Day' },
      { label: 'Sunday', value: 'Sunday' },
      { label: 'Monday', value: 'Monday' },
      { label: 'Tuesday', value: 'Tuesday' },
      { label: 'Wednesday', value: 'Wednesday' },
      { label: 'Thursday', value: 'Thursday' },
      { label: 'Friday', value: 'Friday' },
      { label: 'Saturday', value: 'Saturday' },
    ];

    this.reportIdSubscrip = this.activatedRoute.queryParams.subscribe(
      (params) => {
        //console.log(params)
        // this.reportId = params.id;
        // this.reportName = params.name; //.split('.').slice(0, -1).join('.');
        // this.authDesc = params.authDesc;

        this.scheFormSerSubscrip = this.schedulerService
          .scheduleFormService(params.id)
          .subscribe(
            (response: any) => {
              // report parameters
              //console.log(response.body);
              this.reportOutputType =
                response.body.outputFormatsList[0].toUpperCase();
              response.body.outputFormatsList.forEach((d) => {
                let temp = { label: d.toUpperCase(), value: d.toUpperCase() };
                this.fileFormatOptions.push(temp);
              });
            },
            (error) => {
              //console.log("error")
              this.alertSeverity = 'error';

              if (Number(error.status) == 401) {
                this.alertMsg = 'Please try after some time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal error';
              } else {
                this.alertMsg = 'Please try after some time.';
              }
              this.addSingle();
            },
            () => {}
          );

        this.reportParamSerSubscrip = this.schedulerService
          .reportParameterService(params.id)
          .subscribe(
            (response: any) => {
              // report parameters
              this.parameterElementsArray = response.body?.reportInputControl;
              //console.log(response.body);
            },
            (error) => {
              //console.log("error")
              this.alertSeverity = 'error';

              if (Number(error.status) == 401) {
                this.alertMsg = 'Please try after some time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal error';
              } else {
                this.alertMsg = 'Please try after some time.';
              }
              this.addSingle();
            },
            () => {}
          );
      }
    );

    this.scheduleOccurrenceValue = 'Run Now';
    this.stateOptions = [
      { label: 'Run Now', value: 'Run Now' },
      { label: 'Daily', value: 'Daily' },
      { label: 'Weekly', value: 'Weekly' },
      { label: 'Monthly', value: 'Monthly' },
    ];

    d3.select('#daily-scheduler').style('display', 'none');
    d3.select('#weekly-scheduler').style('display', 'none');
    d3.select('#monthly-scheduler').style('display', 'none');

    this.Options = [
      { label: 'XLS', value: 'XLS' },
      { label: 'XLSX', value: 'XLSX' },
    ];
  }

  onChange(event, refEl) {
    if (refEl != undefined) {
      if (refEl.el.nativeElement.nodeName == 'P-CALENDAR') {
        var value =
          ('0' + (event.getMonth() + 1)).slice(-2) +
          '/' +
          ('0' + event.getDate()).slice(-2) +
          '/' +
          event.getFullYear();
        var obj =
          this.parameterElementsArray[
            Number(refEl.el.nativeElement.attributes.selectInfoIndex.value)
          ];

        obj.paramElementFilledBool = true;

        this.parameters[obj.id] = value;

        //this.requestPayloadParameterObj.parameters[obj.id] = value;
        ////console.log(value)
      } else if (refEl.el.nativeElement.nodeName == 'P-MULTISELECT') {
        var obj =
          this.parameterElementsArray[
            Number(refEl.el.nativeElement.attributes.selectInfoIndex.value)
          ];

        if (event.value.length == 0) {
          obj.paramElementFilledBool = false;
        } else {
          obj.paramElementFilledBool = true;
        }

        //this.requestPayloadParameterObj.parameters[obj.id] = event.value;

        this.parameters[obj.id] = event.value;

        ////console.log(event.value)
      } else if (refEl.el.nativeElement.nodeName == 'P-DROPDOWN') {
        var value1 = event.value;
        var obj =
          this.parameterElementsArray[
            Number(refEl.el.nativeElement.attributes.selectInfoIndex.value)
          ];

        obj.paramElementFilledBool = true;

        this.parameters[obj.id] = value1;

        //this.requestPayloadParameterObj.parameters[obj.id] = value1;
        ////console.log(value1)
      }
    } else if (refEl == undefined) {
      var value1 = event.target.value;
      var obj =
        this.parameterElementsArray[
          Number(event.target.attributes.selectInfoIndex.value)
        ];

      if (event.target.type == 'text') {
        if (event.target.value.length == 0) {
          obj.paramElementFilledBool = false;
        } else {
          obj.paramElementFilledBool = true;
        }
      }

      this.parameters[obj.id] = value1;

      //this.requestPayloadParameterObj.parameters[obj.id] = value1;
      ////console.log(value1)
    }
  } // onChange function

  changeSchedulerView(event) {
    if (event.value == 'Run Now') {
      d3.select('#daily-scheduler').style('display', 'none');
      d3.select('#weekly-scheduler').style('display', 'none');
      d3.select('#monthly-scheduler').style('display', 'none');
    } else if (event.value == 'Daily') {
      d3.select('#daily-scheduler').style('display', 'block');
      d3.select('#weekly-scheduler').style('display', 'none');
      d3.select('#monthly-scheduler').style('display', 'none');
    } else if (event.value == 'Weekly') {
      d3.select('#daily-scheduler').style('display', 'none');
      d3.select('#weekly-scheduler').style('display', 'block');
      d3.select('#monthly-scheduler').style('display', 'none');
    } else if (event.value == 'Monthly') {
      d3.select('#daily-scheduler').style('display', 'none');
      d3.select('#weekly-scheduler').style('display', 'none');
      d3.select('#monthly-scheduler').style('display', 'block');
    }
  }

  changeFileFormat(event) {
    //console.log(event.value)
  }

  dailyOccurSchedFunc(event, refEl) {
    ////console.log(refEl.el.nativeElement.id)

    if (refEl == undefined) {
      if (event.target.value == 'every days(s)') {
        this.dailyOccurrence.isEveryWeekday = 'N';
      } else if (event.target.value == 'every week day') {
        this.dailyOccurrence.isEveryWeekday = 'Y';
        this.dailyOccurrence.recurrenceInterval = 1;
      } else if (event.target.value == 'every day') {
        this.dailyOccurrence.isEveryWeekday = 'N';
        this.dailyOccurrence.recurrenceInterval = 1;
      }
    } else {
      if (refEl.el.nativeElement.nodeName == 'P-INPUTNUMBER') {
        this.dailyOccurrence.recurrenceInterval = event.value;
      } else {
        if (refEl.el.nativeElement.id == 'daily-occurrence-report-time') {
          this.dailyOccurrence.advHours = event.getHours();
          this.dailyOccurrence.advMinutes = event.getMinutes();
        } else if (
          refEl.el.nativeElement.id == 'daily-occurrence-report-start-date'
        ) {
          this.dailyOccurrence.startDatetime =
            event.getFullYear() +
            '-' +
            ('0' + (event.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + event.getDate()).slice(-2) +
            ' ' +
            '00:00:00';
        }
        if (refEl.el.nativeElement.id == 'daily-occurrence-report-end-date') {
          this.dailyOccurrence.endDatetime =
            event.getFullYear() +
            '-' +
            ('0' + (event.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + event.getDate()).slice(-2) +
            ' ' +
            '23:59:59';
        }
      }
    }

    //console.log(this.dailyOccurrence)
  }

  weeklyOccurSchedFunc(event, refEl) {
    if (refEl.inputViewChild != undefined) {
      // to check if it's check box
      if (refEl.checked) {
        // if true
        this.weeklyOccurrence.weekDays.push(Number(refEl.value));
      } else {
        // if false
        let index = this.weeklyOccurrence.weekDays.indexOf(refEl.value);
        this.weeklyOccurrence.weekDays.splice(index, 1);
      }
    } else {
      if (refEl.el.nativeElement.id == 'weekly-occurrence-report-time') {
        this.weeklyOccurrence.advHours = event.getHours();
        this.weeklyOccurrence.advMinutes = event.getMinutes();
      } else if (
        refEl.el.nativeElement.id == 'weekly-occurrence-report-start-date'
      ) {
        this.weeklyOccurrence.startDatetime =
          event.getFullYear() +
          '-' +
          ('0' + (event.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + event.getDate()).slice(-2) +
          ' ' +
          '00:00:00';
      }
      if (refEl.el.nativeElement.id == 'weekly-occurrence-report-end-date') {
        this.weeklyOccurrence.endDatetime =
          event.getFullYear() +
          '-' +
          ('0' + (event.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + event.getDate()).slice(-2) +
          ' ' +
          '23:59:59';
      }
    }

    //console.log(this.weeklyOccurrence)
  }

  monthlyOccurSchedFunc(event, refEl) {
    if (refEl == undefined) {
      if (event.target.value == 'first') {
        //(document.getElementById('refElmval1') as HTMLInputElement).disabled = false;
        this.monthlyOccurrence.isDayOrDate = 'date';
        delete this.monthlyOccurrence['dayUnit'];
        delete this.monthlyOccurrence['dayUnitValue'];
      } else if (event.target.value == 'second') {
        this.monthlyOccurrence.isDayOrDate = 'day';
        this.monthlyOccurrence.monthDays = null;
        this.monthlyOccurrence['dayUnit'] = '';
        this.monthlyOccurrence['dayUnitValue'] = '';
      }
    } else {
      if (refEl.el.nativeElement.nodeName == 'P-INPUTNUMBER') {
        ////console.log(refEl.el.nativeElement.id)
        if (refEl.el.nativeElement.id == 'refElmval1') {
          this.monthlyOccurrence.monthDays = event.value;
        } else if (refEl.el.nativeElement.id == 'refElmval2') {
          this.monthlyOccurrence.recurrenceInterval = event.value;
        } else if (refEl.el.nativeElement.id == 'refElmval3') {
          this.monthlyOccurrence.recurrenceInterval = event.value;
        }
      }
      if (refEl.el.nativeElement.nodeName == 'P-DROPDOWN') {
        ////console.log(refEl.el.nativeElement.id)
        if (refEl.el.nativeElement.id == 'monthly-occurrence-drop-one') {
          this.monthlyOccurrence['dayUnit'] = event.value;
        } else if (refEl.el.nativeElement.id == 'monthly-occurrence-drop-two') {
          this.monthlyOccurrence['dayUnitValue'] = event.value;
        }
      } else {
        if (refEl.el.nativeElement.id == 'monthly-occurrence-report-time') {
          this.monthlyOccurrence.advHours = event.getHours();
          this.monthlyOccurrence.advMinutes = event.getMinutes();
        } else if (
          refEl.el.nativeElement.id == 'monthly-occurrence-report-start-date'
        ) {
          this.monthlyOccurrence.startDatetime =
            event.getFullYear() +
            '-' +
            ('0' + (event.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + event.getDate()).slice(-2) +
            ' ' +
            '00:00:00';
        }
        if (refEl.el.nativeElement.id == 'monthly-occurrence-report-end-date') {
          this.monthlyOccurrence.endDatetime =
            event.getFullYear() +
            '-' +
            ('0' + (event.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + event.getDate()).slice(-2) +
            ' ' +
            '23:59:59';
        }
      }
    }
    //console.log(this.monthlyOccurrence)
  }

  onScheduleBtnClick() {
    let paramsAlertBool: Boolean = false;
    let scheOccurrenceBool: Boolean = false;
    let scheParam: any;

    if (d3.select('#email-text-field-id').node().value.length != 0) {
      // Email to textfied check if block

      this.parameterElementsArray.forEach((ele) => {
        if (
          ele.visible == true &&
          ele.mandatory == true &&
          ele.paramElementFilledBool != true
        ) {
          paramsAlertBool = true;
        }
      });

      if (d3.select('#daily-scheduler').style('display') == 'block') {
        //dailyOccurrence = {advHours:null, advMinutes:null, improvedTriggerType:"daily", isEveryWeekday:"", recurrenceInterval:1, startDatetime:"", endDatetime:""};
        if (
          this.dailyOccurrence.advHours == null ||
          this.dailyOccurrence.advMinutes == null ||
          this.dailyOccurrence.isEveryWeekday == '' ||
          this.dailyOccurrence.startDatetime == '' ||
          this.dailyOccurrence.endDatetime == ''
        ) {
          scheOccurrenceBool = true;
        } else {
          scheParam = {
            haveAttachments: 'Y',
            triggerType: 'improved',
            startType: 26,
            ocurrenceCount: -1,
            advMonths: [],
            weekDays: [],
            improvedTriggerType: 'daily',
            jobName: d3.select('#job-name-id').node().value,
            outputLocale: 0,
            comment: '',
            outputName: d3.select('#file-name-id').node().value,
            mailTo: d3.select('#email-text-field-id').node().value,
            mailSubject: d3.select('#subject-text-field-id').node().value,
            timeZone: 'America/Los_Angeles',
            monthDays: '',
            isEveryWeekday: this.dailyOccurrence.isEveryWeekday,
            mailMessage: d3.select('#email-message-field-id').node().value,
            outputType: [this.reportOutputType],
            recurrenceInterval: this.dailyOccurrence.recurrenceInterval,
            startDatetime: this.dailyOccurrence.startDatetime,
            endDatetime: this.dailyOccurrence.endDatetime,
            advHours: this.dailyOccurrence.advHours,
            advMinutes: this.dailyOccurrence.advMinutes,
            parameters: this.parameters,
            isSftp: 'N',
            isPasswordProtect: 'N',
            clientCode: '',
            isPgpEncrypt: 'N',
            sftpConfigId: null,
            sftpOutDirectoryPath: '',
            sftpHostName: '',
          };

          //console.log(scheParam)

          this.scheRepoSerSubscrip = this.schedulerService
            .scheduleReportService(this.reportId, scheParam)
            .subscribe(
              (response: any) => {
                // schedule report

                if (response.status == 200) {
                  this.alertSeverity = 'success';
                  this.alertMsg = 'Report scheduled successfully!';
                  this.addSingle();
                }
              },
              (error) => {
                //console.log("error")
                this.alertSeverity = 'error';

                if (Number(error.status) == 401) {
                  this.alertMsg = 'Please try after some time!';
                } else if (Number(error.status) == 500) {
                  this.alertMsg = 'Internal error';
                } else {
                  this.alertMsg = 'Please try after some time.';
                }
                this.addSingle();
              },
              () => {}
            );
        }
      } else if (d3.select('#weekly-scheduler').style('display') == 'block') {
        //weeklyOccurrence = {advHours:null, advMinutes:null, improvedTriggerType:"weekly", isEveryWeekday:"Y", startDatetime:"", endDatetime:"", weekDays:[]};
        if (
          this.weeklyOccurrence.advHours == null ||
          this.weeklyOccurrence.advMinutes == null ||
          this.weeklyOccurrence.weekDays.length == 0 ||
          this.weeklyOccurrence.startDatetime == '' ||
          this.weeklyOccurrence.endDatetime == ''
        ) {
          scheOccurrenceBool = true;
        } else {
          scheParam = {
            haveAttachments: 'Y',
            triggerType: 'improved',
            startType: 26,
            ocurrenceCount: -1,
            advMonths: [],
            weekDays: this.weeklyOccurrence.weekDays,
            improvedTriggerType: 'weekly',
            jobName: d3.select('#job-name-id').node().value,
            outputLocale: 0,
            comment: '',
            outputName: d3.select('#file-name-id').node().value,
            mailTo: d3.select('#email-text-field-id').node().value,
            mailSubject: d3.select('#subject-text-field-id').node().value,
            timeZone: 'America/Los_Angeles',
            monthDays: '',
            isEveryWeekday: 'Y',
            mailMessage: d3.select('#email-message-field-id').node().value,
            outputType: [this.reportOutputType],
            startDatetime: this.weeklyOccurrence.startDatetime,
            endDatetime: this.weeklyOccurrence.endDatetime,
            advHours: this.weeklyOccurrence.advHours,
            advMinutes: this.weeklyOccurrence.advMinutes,
            parameters: this.parameters,
            isSftp: 'N',
            isPasswordProtect: 'N',
            clientCode: '',
            isPgpEncrypt: 'N',
            sftpConfigId: null,
            sftpOutDirectoryPath: '',
            sftpHostName: '',
          };

          //console.log(scheParam)

          this.scheRepoSerSubscrip = this.schedulerService
            .scheduleReportService(this.reportId, scheParam)
            .subscribe(
              (response: any) => {
                // schedule report

                if (response.status == 200) {
                  this.alertSeverity = 'success';
                  this.alertMsg = 'Report scheduled successfully!';
                  this.addSingle();
                }
              },
              (error) => {
                //console.log("error")
                this.alertSeverity = 'error';

                if (Number(error.status) == 401) {
                  this.alertMsg = 'Please try after some time!';
                } else if (Number(error.status) == 500) {
                  this.alertMsg = 'Internal error';
                } else {
                  this.alertMsg = 'Please try after some time.';
                }
                this.addSingle();
              },
              () => {}
            );
        }
      } else if (d3.select('#monthly-scheduler').style('display') == 'block') {
        //monthlyOccurrence = {advHours:null, advMinutes:null, improvedTriggerType:"monthly", isDayOrDate:"", monthDays:1, recurrenceInterval:1, startDatetime:"", endDatetime:""};

        if (
          this.monthlyOccurrence.advHours == null ||
          this.monthlyOccurrence.advMinutes == null ||
          this.monthlyOccurrence.isDayOrDate == '' ||
          this.monthlyOccurrence.startDatetime == '' ||
          this.monthlyOccurrence.endDatetime == ''
        ) {
          scheOccurrenceBool = true;
        } else {
          scheOccurrenceBool = false;

          scheParam = {
            haveAttachments: 'Y',
            triggerType: 'improved',
            startType: 26,
            ocurrenceCount: -1,
            advMonths: [],
            weekDays: [],
            improvedTriggerType: 'monthly',
            jobName: d3.select('#job-name-id').node().value,
            outputLocale: 0,
            comment: '',
            outputName: d3.select('#file-name-id').node().value,
            mailTo: d3.select('#email-text-field-id').node().value,
            mailSubject: d3.select('#subject-text-field-id').node().value,
            timeZone: 'America/Los_Angeles',
            monthDays: this.monthlyOccurrence.monthDays,
            isEveryWeekday: 'Y',
            mailMessage: d3.select('#email-message-field-id').node().value,
            outputType: [this.reportOutputType],
            isDayOrDate: 'date',
            recurrenceInterval: this.monthlyOccurrence.recurrenceInterval,
            startDatetime: this.monthlyOccurrence.startDatetime,
            endDatetime: this.monthlyOccurrence.endDatetime,
            advHours: this.monthlyOccurrence.advHours,
            advMinutes: this.monthlyOccurrence.advMinutes,
            parameters: this.parameters,
            isSftp: 'N',
            isPasswordProtect: 'N',
            clientCode: '',
            isPgpEncrypt: 'N',
            sftpConfigId: null,
            sftpOutDirectoryPath: '',
            sftpHostName: '',
          };

          //console.log(scheParam)

          if (!this.monthlyOccurrence.hasOwnProperty('dayUnit')) {
            this.scheRepoSerSubscrip = this.schedulerService
              .scheduleReportService(this.reportId, scheParam)
              .subscribe(
                (response: any) => {
                  // schedule report

                  if (response.status == 200) {
                    this.alertSeverity = 'success';
                    this.alertMsg = 'Report scheduled successfully!';
                    this.addSingle();
                  }
                },
                (error) => {
                  //console.log("error")
                  this.alertSeverity = 'error';

                  if (Number(error.status) == 401) {
                    this.alertMsg = 'Please try after some time!';
                  } else if (Number(error.status) == 500) {
                    this.alertMsg = 'Internal error';
                  } else {
                    this.alertMsg = 'Please try after some time.';
                  }
                  this.addSingle();
                },
                () => {}
              );
          }

          if (this.monthlyOccurrence.hasOwnProperty('dayUnit')) {
            if (
              this.monthlyOccurrence['dayUnit'] == '' ||
              this.monthlyOccurrence['dayUnitValue'] == ''
            ) {
              scheOccurrenceBool = true;
            } else {
              /*//console.log(d3.select("#job-name-id").node().value)
							//console.log(this.reportOutputType)
							//console.log(d3.select("#file-name-id").node().value)
							//console.log(d3.select("#email-text-field-id").node().value)
							//console.log(d3.select("#subject-text-field-id").node().value)
							//console.log(d3.select("#email-message-field-id").node().value)
							//console.log(this.parameters)*/

              scheParam = {
                haveAttachments: 'Y',
                triggerType: 'improved',
                startType: 26,
                ocurrenceCount: -1,
                advMonths: [],
                weekDays: [],
                improvedTriggerType: 'monthly',
                jobName: d3.select('#job-name-id').node().value,
                outputLocale: 0,
                comment: '',
                outputName: d3.select('#file-name-id').node().value,
                mailTo: d3.select('#email-text-field-id').node().value,
                mailSubject: d3.select('#subject-text-field-id').node().value,
                timeZone: 'America/Los_Angeles',
                monthDays: null,
                isEveryWeekday: 'Y',
                mailMessage: d3.select('#email-message-field-id').node().value,
                outputType: [this.reportOutputType],
                isDayOrDate: 'day',
                dayUnit: this.monthlyOccurrence['dayUnit'],
                dayUnitValue: this.monthlyOccurrence['dayUnitValue'],
                recurrenceInterval: this.monthlyOccurrence.recurrenceInterval,
                startDatetime: this.monthlyOccurrence.startDatetime,
                endDatetime: this.monthlyOccurrence.endDatetime,
                advHours: this.monthlyOccurrence.advHours,
                advMinutes: this.monthlyOccurrence.advMinutes,
                parameters: this.parameters,
                isSftp: 'N',
                isPasswordProtect: 'N',
                clientCode: '',
                isPgpEncrypt: 'N',
                sftpConfigId: null,
                sftpOutDirectoryPath: '',
                sftpHostName: '',
              };

              //console.log(scheParam)

              this.scheRepoSerSubscrip = this.schedulerService
                .scheduleReportService(this.reportId, scheParam)
                .subscribe(
                  (response: any) => {
                    // schedule report

                    if (response.status == 200) {
                      this.alertSeverity = 'success';
                      this.alertMsg = 'Report scheduled successfully!';
                      this.addSingle();
                    }
                  },
                  (error) => {
                    //console.log("error")
                    this.alertSeverity = 'error';

                    if (Number(error.status) == 401) {
                      this.alertMsg = 'Please try after some time!';
                    } else if (Number(error.status) == 500) {
                      this.alertMsg = 'Internal error';
                    } else {
                      this.alertMsg = 'Please try after some time.';
                    }
                    this.addSingle();
                  },
                  () => {}
                );
            }
          }
        }
      } else {
        if (paramsAlertBool == false) {
          scheParam = {
            haveAttachments: 'Y',
            triggerType: 'NONE',
            startType: 25,
            ocurrenceCount: -1,
            advMonths: [],
            weekDays: [],
            improvedTriggerType: '',
            jobName: d3.select('#job-name-id').node().value,
            outputLocale: 0,
            comment: '',
            outputName: d3.select('#file-name-id').node().value,
            mailTo: d3.select('#email-text-field-id').node().value,
            mailSubject: d3.select('#subject-text-field-id').node().value,
            timeZone: 'America/Los_Angeles',
            monthDays: '',
            isEveryWeekday: 'Y',
            mailMessage: d3.select('#email-message-field-id').node().value,
            outputType: [this.reportOutputType],
            startDatetime: '',
            endDatetime: '',
            parameters: this.parameters,
            isSftp: 'N',
            isPasswordProtect: 'N',
            clientCode: '',
            isPgpEncrypt: 'N',
            sftpConfigId: null,
            sftpOutDirectoryPath: '',
            sftpHostName: '',
          };

          //console.log(scheParam)

          this.scheRepoSerSubscrip = this.schedulerService
            .scheduleReportService(this.reportId, scheParam)
            .subscribe(
              (response: any) => {
                // schedule report

                if (response.status == 200) {
                  this.alertSeverity = 'success';
                  this.alertMsg = 'Report scheduled successfully!';
                  this.addSingle();
                }
              },
              (error) => {
                //console.log("error")
                this.alertSeverity = 'error';

                if (Number(error.status) == 401) {
                  this.alertMsg = 'Please try after some time!';
                } else if (Number(error.status) == 500) {
                  this.alertMsg = 'Internal error';
                } else {
                  this.alertMsg = 'Please try after some time.';
                }
                this.addSingle();
              },
              () => {}
            );
        }
      }

      if (paramsAlertBool == true || scheOccurrenceBool == true) {
        this.alertMsg = "Please fill out mandatory ' * ' fields!";
        this.addSingle();
      }
    } else {
      // Email to textfied check else block
      this.alertMsg = "Please fill out mandatory ' * ' fields!";
      this.addSingle();
    }
  }

  addSingle() {
    this.messageService.add({
      severity: this.alertSeverity,
      summary: '',
      detail: this.alertMsg,
    });
  }

  clear() {
    this.messageService.clear();
  }
}
