<div class="sidebar" [ngClass]="{ 'expanded': isExpanded}" id="tibo-large-leftpanel-container">

    <div class="content">
        <div class="link">
            <div class="logo">
                <span> <img src="../../assets/TB LOGO.png" alt=""></span>
                <div class="logo_name">Timebound</div>
            </div>
        </div><br><br>
       
        <div class="alignment">
            <ul>
                <li >
                    <a href="#" routerLink="home" (click)="onLinkClick()" (click)="activefun('home')" [ngClass]="{'active':activeString === 'home'}"  pTooltip="Home" tooltipPosition="right" [tooltipDisabled]="isExpanded">
                        <i class='bx bx-home' ></i>
                        <span class="group_name">Home</span>
                    </a>
                    <!-- <a> <span class="tooltip ">Home</span></a> -->
                </li>
                <li>
                <li>
                    <a href="#" routerLink="discovery" (click)="onLinkClick()" (click)="activefun('dataDiscovery')" [ngClass]="{'active':activeString === 'dataDiscovery'}" pTooltip="Data Discovery" tooltipPosition="right" [tooltipDisabled]="isExpanded">
                        <i class='bx bx-search'></i>
                        <span class="group_name">Data Discovery</span>
                    </a>
                    <!-- <span class="tooltip">Data Discovery</span> -->
                </li>

            </ul>
        </div>

        <!-- <div class="link">
            <div class="content">
                <div class="title"><span>Dashboard</span></div>

            </div>
        </div><br> -->

        <div class="alignment">
            <ul>
                <!-- <li>
                    <a href="#" routerLink = "create-dashboard" (click) = "onLinkClick()">
                        <i class='bx bx-pie-chart-alt-2'></i>
                        <span class="group_name ">Create Dashboard</span>
                    </a>
                    <span class="tooltip ">Create Dashboard</span>
                </li> -->
                <li>
                <!-- <li>
                    <a routerLink = "dashboard-v1">
                        <i class='bx bx-pie-chart-alt-2'></i>
                        <span class="group_name ">My Dashboard</span>
                    </a>
                    <span class="tooltip">My Dashboard</span>
                </li><br> -->
                <li>
                    <a routerLink = "dashboard" (click)="onLinkClick()" (click)="activefun('dashboard')" [ngClass]="{'active':activeString === 'dashboard'}" pTooltip="Dashboard" tooltipPosition="right" [tooltipDisabled]="isExpanded">
                        <i class='bx bx-grid-alt'></i>
                        <span class="group_name ">Dashboard</span>
                    </a>
                    <!-- <span class="tooltip">Dashboard</span> -->
                </li>
                <!-- <li>
                    <a routerLink = "dashboard-v3" (click)="onLinkClick()">
                        <i class='bx bx-grid-alt'></i>
                        <span class="group_name ">Dashboard V-1</span>
                    </a>
                    <span class="tooltip">Dashboard V-1</span>
                </li><br> -->
                <!-- <li>
                    <a routerLink = "my-dashboard" (click)="menuFunc()">
                        <i class='bx bx-grid-alt'></i>
                        <span class="group_name ">Dashboard V-2</span>
                    </a>
                    <span class="tooltip">Dashboard V-2</span>
                </li><br> -->
            </ul>
        </div>

        <!-- <div class="link">
            <div class="content">
                <div class="title"><span>Reports</span></div>
            </div>
        </div><br> -->
        <div class="alignment">
            <ul>
                <li>
                    <a routerLink = "build-report" (click)="onLinkClick()" (click)="activefun('buildReport')" [ngClass]="{'active':activeString === 'buildReport'}" pTooltip="Build Report" tooltipPosition="right" [tooltipDisabled]="isExpanded">
                        <i class='bx bx-notepad'></i>
                        <span class="group_name ">Build A Report</span>
                    </a>
                    <!-- <span class="tooltip ">Build Report</span> -->
                </li>
                <!-- <li>
                    <a routerLink = "build-report" (click)="onLinkClick()" (click)="activefun('adHocReport')" [ngClass]="{'active':activeString === 'adHocReport'}" pTooltip="Ad-hoc Report" tooltipPosition="right" [tooltipDisabled]="isExpanded">
                        <i class='bx bxs-report'></i>
                        <span class="group_name ">Ad-hoc Report</span>
                    </a> -->
                    <!-- <span class="tooltip ">Ad-hoc Report</span> -->
                <!-- </li> -->
                
                <li>
                <a routerLink = "my-reports" (click)="onLinkClick()" (click)="activefun('report')" [ngClass]="{'active':activeString === 'report'}" pTooltip="Report" tooltipPosition="right" [tooltipDisabled]="isExpanded">
                        <i class='bx bx-folder'></i>
                        <span class="group_name ">Reports</span>
                    </a>
                    <!-- <span class="tooltip">Report</span> -->
                </li>
                <!-- <li>
                    <a routerLink = "scheduled-reports" (click)="onLinkClick()" (click)="activefun('scheduleReport')" [ngClass]="{'active':activeString === 'scheduleReport'}" pTooltip="Schedule Report" tooltipPosition="right" [tooltipDisabled]="isExpanded">
                        <i class='bx bx-file'></i>
                        <span class="group_name ">Schedule Report</span>
                    </a> -->
                    <!-- <span class="tooltip">Schedule Report</span> -->
                <!-- </li> -->
                <!-- <li>
                    <a routerLink = "schedule-history" (click)="onLinkClick()" (click)="activefun('scheduleHistory')" [ngClass]="{'active':activeString === 'scheduleHistory'}" pTooltip="Schedule History" tooltipPosition="right" [tooltipDisabled]="isExpanded">
                        <i class='bx bx-file-blank'></i>
                        <span class="group_name ">Schedule History</span>
                    </a> -->
                    <!-- <span class="tooltip">Schedule History</span> -->
                <!-- </li> -->
             
                <!-- <li>
                    <a routerLink = "my-report-inbox" (click)="onLinkClick()" (click)="activefun('reportInbox')" [ngClass]="{'active':activeString === 'reportInbox'}" pTooltip="Report Inbox" tooltipPosition="right" [tooltipDisabled]="isExpanded">
                        <i class='bx bx-data'></i>
                        <span class="group_name ">Report Inbox</span>
                    </a> -->
                    <!-- <span class="tooltip">Report Inbox</span> -->
                <!-- </li> -->
                <!-- <div class="link">
                    <div class="content">
                        <div class="title"><span>General</span></div>
                    </div>
                </div><br> -->
                <div class="alignmentS">
                <li>
                    <a routerLink = "settings" (click)="onLinkClick()" (click)="activefun('settings')" [ngClass]="{'active':activeString === 'settings'}" pTooltip="Settings" tooltipPosition="right" [tooltipDisabled]="isExpanded">
                        <i class='bx bx-cog'></i>
                        <span class="group_name ">Settings</span>
                    </a>
                    <!-- <span class="tooltip ">Settings</span> -->
                </li>
                <!-- <li>
                    <a routerLink = "help" (click)="onLinkClick()" (click)="activefun('help')" [ngClass]="{'active':activeString === 'help'}" pTooltip="Help" tooltipPosition="right" [tooltipDisabled]="isExpanded">
                        <i class='bx bx-help-circle'></i>
                        <span class="group_name ">Help</span>
                    </a>
                    <span class="tooltip ">Help</span>
                </li> -->
                
            </div>
            </ul>
        </div>

    </div>
    <div class="menu-button">
        <ul class="nav-list">
            <li class="menu_btn">
                <i class='bx bx-chevrons-left menu-btn' (click)="menuFunc()" id="menu-btn"></i>
            </li>
        </ul>
    </div>

</div>