import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ModalService } from '../modal/modal.service';
import { element } from 'protractor';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { runInThisContext } from 'vm';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Customer, Leave } from './customer';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import {FilterPipe} from './filter.pipe'
import { PaginatorModule } from 'primeng/paginator';
import { Stats } from 'fs';
import { BuildReportService } from './build-report.service';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridReadyEvent,ColumnApi, SideBarDef, GridOptions } from '@ag-grid-community/core';
import { Observable } from 'rxjs';
import { saveAs } from 'file-saver';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
declare var d3: any;

@Component({
  selector: 'app-build-report',
  templateUrl: './build-report.component.html',
  styleUrls: ['./build-report.component.css'],
  providers: [ConfirmationService, MessageService],
})
export class BuildReportComponent implements OnInit {


  selectedValue;
  folderCollapse = false;
  onDemand = false; 0
  demand: any;
  userName = "";
  name: any;
  date = true;
  disabilityForm: any;
  folderPanelToggleBool: boolean = false;
  table = false;
  // selecthide = false;
  leavefilter = false;
  panel = false;
  leaveForm: any;
  runtable: boolean = false;
  leavetable: boolean = false;
  save: boolean = false;
  Is: boolean = false;
  errorMsg = "";
  availableData: any = [];
  leaveAvailableData: any = [];
  page: any = [];
  alertMessage: Message[];
  marked = false;
  mark = false;
  claims = false;
  dates = false;
  filter = false;
  masterSelected: boolean;
  checkedList: any;
  selectedData: any = [];
  leavedData: any = [];
  rechecked: any;
  header = false;
  available = false;
  unmark = false;
  selectItems: any;
  dt: Table | undefined;
  datePicker = true;
  runButtonClicked = false;
  leaveheader = false;
  runavailable: any = [];
  leaveAvailable: any = [];
  // filteredColumnArray = [];
  totalRecords: number;
  loading: boolean;
  customers: Customer[];
  datasource: Customer[];
  filteredColumnArray: Customer[];
  leaveColumnArray: Leave[];
  employee: Leave[];
  cols: any[];
  ref: DynamicDialogRef;
  public empData: any;
  public empData1: any;
  public leaveData: any;
  public activeField: any;
  // startDate: any;
  // endDate: any;
  dateSet: any;
  temp: any = []
  public filteredExport: any;
  public leaveExport: any;
  dataSetType: any;
  dataType: any;
  leavetype: any;
  selectedDate: any;
  leavedDate: any
  disabilty: any;
  empLeave: any;
  startDate='01/01/2022';
  endDate='12/31/2022';
  //Ag grid
  selectedColumn: string[] = [];
  coldata:any=[];
  public gridOptions: GridOptions;
  columnDefs: ColDef[] = [];
  public rowData:any=[];
  public defaultColDef: ColDef = {
    cellStyle: {'padding-left': 0 ,'text-align':"center"},
    flex:1,
    minWidth: 150, 
    maxWidth: 250,
    resizable: true,
    sortable: true,
    filter: true,
    editable:true,
    getQuickFilterText: params => {
      return params.value.name;
  }
    
  };
  arr:any=[];
  data1:any=[];
  private gridApi;
  coldata1: any=[];
  value1: any=false;
  value2: any=false;
  selectedCol: boolean;
  value;
  searchText;
  globalSearch;
  showdropdown: boolean=false;
  showmenu:boolean=false;
  dropDownData2: any;
  disabledStored:boolean=false;
  isChecked: any=false;
  Hdata: any;
  Rdata: any;
  selectedDomain: any;
  listOfFilter: boolean=false;
  savedData=[];
  savedData1=[];
  selectedcompany: any;
  selectedDRp: any;
  selectedDisability:any='detail';
  selectedLeave:any='Detail';
  leaveSummaryData: any;
  coldata11: any;
   constructor(private customerService: BuildReportService, private datePipe: DatePipe, private confirmationService: ConfirmationService, private modalService: ModalService, private messageService: MessageService, private router: Router, private fb: FormBuilder, private http: HttpClient,
    public dialogService: DialogService) {


    this.masterSelected = false;
    this.availabeArray();
    this.getCheckedItemList();
    this.leaveArray();

    this.disabilityForm = this.fb.group({
      name: ['', Validators.required],
      value: ['', Validators.required],
      data: ['', Validators.required],
      calendar: ['', Validators.required],
      calendar1: ['', Validators.required],
    })

    this.leaveForm = this.fb.group({
      clientname: ['', Validators.required],
      leavetype: ['', Validators.required],
      datatype: ['', Validators.required],
      startdate: ['', Validators.required],
      enddate: ['', Validators.required],

    })
    
    this.gridOptions = {
      rowSelection: 'single',
      context: {},
      pagination: true,
      //paginationPageSize: 25,
      paginationAutoPageSize: true,
      onGridReady: (params) => {
        params.api.sizeColumnsToFit();
      },
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      getMainMenuItems: () => {
        return ['generalMenuTab', 'columnsMenuTab']
       },
       
       
    }
  }

  leaveArray() {
    this.leaveAvailableData = [
      { id: 1, value: 'Last Name', isSelected: false, columnField: 'LAST_NAME' },
      { id: 2, value: 'First Name', isSelected: false, columnField: 'FIRST_NAME' },
      { id: 3, value: 'Leave No', isSelected: false, columnField: 'LEAVE_NO' },
      { id: 4, value: 'Employee Id', isSelected: false, columnField: 'EMPLOYEE_ID' },
      { id: 5, value: 'Reason', isSelected: false, columnField: 'REASON' },
      { id: 6, value: 'Absence Type', isSelected: false, columnField: 'ABSENCE_TYPE' },
      { id: 7, value: 'Policy Type', isSelected: false, columnField: 'POLICY_TYPE' },
      { id: 8, value: 'Policy Name', isSelected: false, columnField: 'POLICY_NAME' }, { id: 9, value: 'Policy Status', isSelected: false, columnField: 'POLICY_STATUS' }, { id: 10, value: 'Policy Time Used', isSelected: false, columnField: 'POLICY_TIME_USED' },
      { id: 11, value: 'Leave Status', isSelected: false, columnField: 'LEAVE_STATUS' }, { id: 12, value: 'Notification Date', isSelected: false, columnField: 'NOTIFICATION_DATE' }, { id: 13, value: 'Requested Start Date', isSelected: false, columnField: 'REQUESTED_START_DATE' },
      { id: 14, value: 'Requested End Date', isSelected: false, columnField: 'REQUESTED_END_DATE' }, { id: 15, value: 'Cetification Start Date', isSelected: false, columnField: 'CERTIFICATION_START_DATE' }, { id: 16, value: 'Certification End Date', isSelected: false, columnField: 'CERTIFICATION_END_DATE' },
      { id: 17, value: 'Approved Start Date', isSelected: false, columnField: 'APPROVED_START_DATE' }, { id: 18, value: 'Approved End Date', isSelected: false, columnField: 'APPROVED_END_DATE' }, { id: 19, value: 'Exhaust Date', isSelected: false, columnField: 'EXHAUST_DATE' },
      { id: 20, value: 'Estimated RTW Date', isSelected: false, columnField: 'ESTIMATED_RTW_DATE' }, { id: 21, value: 'Actual RTW Date', isSelected: false, columnField: 'ACTUAL_RTW_DATE' }, { id: 22, value: 'Estimated Delivery Date', isSelected: false, columnField: 'ESTIMATED_DELIVERY_DATE' },
      { id: 23, value: 'Intake No', isSelected: false, columnField: 'INTAKE_NO' }, { id: 24, value: 'Reduced Leave', isSelected: false, columnField: 'REDUCED_LEAVE' }, { id: 25, value: 'Work State(A)', isSelected: false, columnField: '(a) Work State' },
      { id: 26, value: ' Location(B)', isSelected: false, columnField: 'Location(b)' }, { id: 27, value: ' Department(C)', isSelected: false, columnField: 'Department(c)' }, { id: 28, value: ' Division(D)', isSelected: false, columnField: 'Division(d)' }, { id: 29, value: 'Ledger(E)', isSelected: false, columnField: 'Ledger(e)' },
      { id: 30, value: 'Company Code(F)', isSelected: false, columnField: 'Company Code(f)' }, { id: 31, value: 'Last 4 Digit SSN', isSelected: false, columnField: 'LAST_4_DIGIT_SSN' }, { id: 32, value: 'Gender', isSelected: false, columnField: 'GENDER' },
      { id: 30, value: 'Leave Start Date', isSelected: false, columnField: 'LEAVE_START_DATE' }, { id: 30, value: 'Leave End Date', isSelected: false, columnField: 'LEAVE_END_DATE' }, { id: 30, value: 'Closed Indicator', isSelected: false, columnField: 'CLOSED_INDICATOR' },
      { id: 33, value: 'Closure Date', isSelected: false, columnField: 'CLOSURE_DATE' }, { id: 34, value: 'Total Time Used', isSelected: false, columnField: 'TOTAL_TIME_USED' }, { id: 35, value: 'TTU Leave Unit', isSelected: false, columnField: 'TTU_LEAVE_UNIT' },
      { id: 36, value: 'Total Time Used This Leave', isSelected: false, columnField: 'TOTAL_TIME_USED_THIS_LEAVE' }, { id: 37, value: 'TTU This Leave Unit', isSelected: false, columnField: 'TTU_THIS_LEAVE_UNIT' }, { id: 38, value: 'Min Start Date', isSelected: false, columnField: 'MIN_START_DATE' },
      { id: 39, value: 'Max End Date', isSelected: false, columnField: 'MAX_END_DATE' }, { id: 40, value: 'Physician Name', isSelected: false, columnField: 'PHYSICIAN_NAME' }, { id: 41, value: 'Intake Physician Name', isSelected: false, columnField: 'INTAKE_PHYSICIAN_NAME' }, { id: 42, value: 'Supervisor Name', isSelected: false, columnField: 'SUPERVISOR_NAME' }, { id: 43, value: 'SUP Email Address', isSelected: false, columnField: 'SUP_EMAILADDRESS' },
      { id: 44, value: 'Cert Due Date', isSelected: false, columnField: 'CERT_DUE_DATE' }
    ];


  }

  availabeArray() {
    this.availableData = [
      { id: 1, value: 'Last Name', isSelected: false, columnField: 'CLAIMANT_LAST_NAME' },
      { id: 2, value: 'Middle Name', isSelected: false, columnField: 'CLAIMANT_MIDDLE_NAME' },
      { id: 3, value: 'First Name', isSelected: false, columnField: 'CLAIMANT_FIRST_NAME' },
      { id: 4, value: 'Employee Id', isSelected: false, columnField: 'EMPLOYEE_ID' },
      { id: 5, value: 'Exempt', isSelected: false, columnField: 'EXEMPT' },
      { id: 6, value: 'Program Type', isSelected: false, columnField: 'PROGRAM_TYPE' },
      { id: 7, value: 'Claim Status', isSelected: false, columnField: 'CLAIM_STATUS' },
      { id: 8, value: 'Disability Type', isSelected: false, columnField: 'DISABILITY_TYPE' }, { id: 9, value: 'Disability Start Date', isSelected: false, columnField: 'DISABILITY_START_DATE' }, { id: 10, value: 'Disability End Date', isSelected: false, columnField: 'DISABILITY_END_DATE' },
      { id: 11, value: 'Duration', isSelected: false, columnField: 'DURATION' }, { id: 12, value: 'Gross Paid On Claim', isSelected: false, columnField: 'GROSS_PAID_ON_CLAIM' }, { id: 13, value: 'Claim No', isSelected: false, columnField: 'CLAIM_NO' },
      { id: 14, value: 'Last SSN', isSelected: false, columnField: 'LAST_4_SSN' }, { id: 15, value: 'Gender', isSelected: false, columnField: 'GENDER' }, { id: 16, value: 'Full Time Indicator', isSelected: false, columnField: 'FULL_TIME_INDICATOR' },
      { id: 17, value: 'Work State(A)', isSelected: false, columnField: '(a) Work State' }, { id: 18, value: 'Location(B)', isSelected: false, columnField: 'Location(b)' }, { id: 19, value: 'Department(C)', isSelected: false, columnField: 'Department(c)' },
      { id: 20, value: 'Division(D)', isSelected: false, columnField: 'Division(d)' }, { id: 21, value: 'Ledger(E)', isSelected: false, columnField: 'Ledger(e)' }, { id: 22, value: 'Company Code(F)', isSelected: false, columnField: 'Company Code(f)' },
      { id: 23, value: 'Client', isSelected: false, columnField: 'Client' }, { id: 24, value: 'Claim Status Desc', isSelected: false, columnField: 'DIS_CLAIM_STATUS_DESC' }, { id: 25, value: 'Claim Closed Date(C)', isSelected: false, columnField: 'DATE_CLAIM_CLOSED' },
      { id: 26, value: 'Expected RTW Date', isSelected: false, columnField: 'EXPECTED_RTW_DATE' }, { id: 27, value: 'Actual RTW Date', isSelected: false, columnField: 'ACTUAL_RTW_DATE' }, { id: 28, value: 'Notification Date', isSelected: false, columnField: 'NOTIFICATION_DATE' }, { id: 29, value: 'Policy Number', isSelected: false, columnField: 'POLICY_NUMBER' },



    ];

  }



  ngOnInit(): void {
    d3.select(".tibo-topbar-title").text("Home / Build A Report");

    if (Number(d3.select("#tibo-large-leftpanel-container").node().getBoundingClientRect().width) == 64) {
      setTimeout(function () {
        d3.select(".tibo-right-container").transition().delay(0).duration(0).style("left", "64px").ease("linear");
      }, 10)
    }

    this.runavailable.push(this.availableData);
    this.leaveAvailable.push(this.leaveAvailableData)
    this.customerService.getCustomersLarge().then((customers) => {

      this.customers = customers;
      this.initializeDT();

    });
    this.customerService.getLeave().then((leave) => {
      this.employee = leave;
    });
    this.value1=false;
    this.value2=false;
    this.rowData=0;
    this.columnDefs=[];
    this.showdropdown=false
    this.showmenu=false;
    // this.startDate='01/01/2022';
    // this.endDate='12/31/2022';

    //this.selectedLeave='Detail';
    //this.selectedDisability;
  }
  // folderPanelToggle() {
  //   //this.files = this.directoryArray;
  //   if (this.folderPanelToggleBool == false) {
  //     d3.selectAll("#buildreport-folder-panel-scroll-div").transition().delay(200).duration(400).style("display", "none")
  //     d3.select("#buildreports-folderview-panel").transition().delay(200).duration(500).style("width", "40px")
  //     d3.select("#buildreports-tile-list-scroll-view").transition().delay(200).duration(500).style("left", "50px")//.style("width","calc(100% - 50px)")
  //     d3.select("#buildreports-folderview-panel").select("button").style("transform", "rotate(180deg)")
  //     d3.select("#all-reports-id").transition().delay(100).duration(100)
  //       .style({ 'left': '-35px', 'top': '80px' }).style("transform", "rotate(-90deg)")
  //     this.folderPanelToggleBool = true;
  //   }
  //   else {
  //     d3.select("#buildreport-folder-panel-scroll-div").transition().delay(300).duration(400).style("display", "block")
  //     //d3.select("#submitbutton-id").transition().delay(500).duration(400).style("display","block")
  //     d3.select("#buildreports-folderview-panel").transition().delay(200).duration(500).style("width", "350px")
  //     d3.select("#buildreports-tile-list-scroll-view").transition().delay(200).duration(500).style("left", "360px")//.style("width","calc(100% - 360px)")
  //     d3.select("#buildreports-folderview-panel").select("button").style("transform", "rotate(0deg)")
  //     d3.select("#all-reports-id").transition().delay(250).duration(100)
  //       .style({ 'left': '10px', 'top': '10px' }).style("transform", "rotate(0deg)")
  //     this.folderPanelToggleBool = false;
  //   }
  // }
  folderPanelToggle() {
		//this.files = this.directoryArray;
		if(this.folderPanelToggleBool == false){
			d3.selectAll("#myreport-folder-panel-scroll-div").transition().delay(200).duration(400).style("display","none")
			d3.select("#myreports-folderview-panel").transition().delay(200).duration(500).style("width","40px")
			d3.select("#myreports-tile-list-scroll-view").transition().delay(200).duration(500).style({"left":"50px",'width':'96%','height':'100%','animation-name':'reducetime','float':'right'})//.style("width","calc(100% - 50px)")
			d3.select("#myreports-folderview-panel").select("button").style("transform","rotate(180deg)")
			d3.select("#all-reports-id").transition().delay(100).duration(100)
			.style({'left':'-36px','top':'85px'}).style("transform","rotate(-90deg)")
			this.folderPanelToggleBool = true;
		}
		else {
			d3.select("#myreport-folder-panel-scroll-div").transition().delay(300).duration(400).style("display","block")
			//d3.select("#submitbutton-id").transition().delay(500).duration(400).style("display","block")
			d3.select("#myreports-folderview-panel").transition().delay(200).duration(500).style("width","350px")
			d3.select("#myreports-tile-list-scroll-view").transition().delay(200).duration(500).style({"left":"360px",'width':'75%','height':'100%'})//.style("width","calc(100% - 360px)")
			d3.select("#myreports-folderview-panel").select("button").style("transform","rotate(0deg)")
			d3.select("#all-reports-id").transition().delay(250).duration(100)
			.style({'left':'10px','top':'10px'}).style("transform","rotate(0deg)")
			this.folderPanelToggleBool = false;
		}
	}

  initializeDT() {

    this.datasource = this.customers;

    this.totalRecords = this.customers.length;

    //  this.loading = true;

  }

  nextPage(event: LazyLoadEvent) {
    // this.loading = true;
    if (this.datasource) {
      this.filteredColumnArray = this.datasource.slice(event.first, (event.first + event.rows));
    
      this.filteredColumnArray.push(this.page);
      // this.loading = false;
    }
   

  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }



  disabiltyType(type: any) {
    this.disabilty = type;
  }
  public leaveDatas: any;

  leaveType(type: any) {

    this.empLeave = type;
   
  }

  selectAll() {
    // marked =false //start
    if (this.marked == false) {
      this.availableData.forEach((res: any) => {
        res.isSelected = true;
      })
      this.marked = true;
    } else {
      this.availableData.forEach((res: any) => {
        res.isSelected = false;
      })
      this.marked = false;
    }
  }
  select() {
    // marked =false //start
    if (this.mark == false) {
      this.leaveAvailableData.forEach((res: any) => {
        res.isSelected = true;
      })
      this.mark = true;
    } else {
      this.leaveAvailableData.forEach((res: any) => {
        res.isSelected = false;
      })
      this.mark = false;
    }
  }

  selected() {
    if (this.available == false) {
      this.selectedData.forEach((res: any) => {
        res.isSelected = true;
      })
      this.available = true;
    } else {
      this.selectedData.forEach((res: any) => {
        res.isSelected = false;
      })
      this.available = false;
    }
  }
  selectlist() {
    if (this.unmark == false) {
      this.leavedData.forEach((res: any) => {
        res.isSelected = true;
      })
      this.unmark = true;
    } else {
      this.leavedData.forEach((res: any) => {
        res.isSelected = false;
      })
      this.unmark = false;
    }
  }


  confirm() {
    this.runtable = false;
    this.Is = true;
    this.demand = false;
    this.selectedValue = true;
    this.confirmationService.confirm({
      header: 'Do you want to schedule ?',
    });
  }

  cancel() {
    this.confirmationService.close();
    this.demand = true;
    this.selectedValue = 'onDemand';

  }

  ok() {
    this.router.navigate(['/scheduler']);
  }

  resetForm() {

    this.disabilityForm.reset();
    this.leaveForm.reset();
  }
  dropdownChange(event)
  {
    this.ngOnInit();
    this.value1=false;
    this.value2=false;
  }

  disability() {
    this.startDate='01/01/2022';
    this.endDate='12/31/2022';
    this.ngOnInit();
    this.value1=true;
    this.selectedData = []
    this.availabeArray()
    this.marked = false;
    this.available = true;
    this.Is = false;
    this.header = false;
    this.leavetable = false;
    this.empData;
    this.save = false;
    this.filter = false;
    this.runButtonClicked = true;
   
    if(this.selectedDisability == 'detail')
    {
      this.coldata=[]
      this.customerService.getCustomersLarge().then((res: any) => {
        const data = res;
        this.empData = data.filter(res => {

          if (this.disabilty !== 'both') {
            return res.PROGRAM_TYPE == this.disabilty && res.CLAIM_STATUS == this.dataSetType
          } else {
            return res.CLAIM_STATUS == this.dataSetType
          }
        })
        const keys = Object.keys(this.empData[0]);
          keys.forEach((key, index) => {
            let mappedColumn = {
              headerName: key.replace(/[^\w\s]/gi, "").trim().replace("CLAIMANT_","").replace("_"," ").replace(/\b\w/g, (s) => s.toUpperCase()),
              field: key,
              checked:true
            }
            this.coldata.push(mappedColumn);
            });
            this.columnDefs=this.coldata;
          this.rowData=this.empData;
      })

      if (this.startDate && this.endDate) {

        this.customerService.getCustomersLarge().then((res: any) => {
          const data: any = res;

          const term = data.filter(res => {

            if (this.disabilty !== 'both' && this.selectedDate == "OPEN") {
              return res.PROGRAM_TYPE == this.disabilty && res.CLAIM_STATUS == this.selectedDate;
            } else if (this.disabilty === 'both' && this.selectedDate == "OPEN") {
              return res.CLAIM_STATUS == this.selectedDate
            }

            else if (this.selectedDate == 'ACTUAL_RTW_DATE' && this.disabilty === 'both') {
              return new Date(res?.ACTUAL_RTW_DATE) >= new Date(this.startDate) && new Date(res?.ACTUAL_RTW_DATE) <= new Date(this.endDate);
            }
            else if (this.selectedDate == 'ACTUAL_RTW_DATE' && this.disabilty !== 'both') {
              return new Date(res?.ACTUAL_RTW_DATE) >= new Date(this.startDate) && new Date(res?.ACTUAL_RTW_DATE) <= new Date(this.endDate) && res.PROGRAM_TYPE == this.disabilty;
            }
            else if (this.selectedDate == 'EXPECTED_RTW_DATE' && this.disabilty === 'both') {
              return new Date(res?.EXPECTED_RTW_DATE) >= new Date(this.startDate) && new Date(res?.EXPECTED_RTW_DATE) <= new Date(this.endDate);
            }
            else if (this.selectedDate == 'EXPECTED_RTW_DATE' && this.disabilty !== 'both') {
              return new Date(res?.EXPECTED_RTW_DATE) >= new Date(this.startDate) && new Date(res?.EXPECTED_RTW_DATE) <= new Date(this.endDate) && res.PROGRAM_TYPE == this.disabilty;
            }
            else if (this.selectedDate == 'CLAIM' && this.disabilty === 'both') {
              return new Date(res?.NOTIFICATION_DATE) >= new Date(this.startDate) && new Date(res?.NOTIFICATION_DATE) <= new Date(this.endDate);
            }
            else if (this.selectedDate == 'CLAIM' && this.disabilty !== 'both') {
              return new Date(res?.NOTIFICATION_DATE) >= new Date(this.startDate) && new Date(res?.NOTIFICATION_DATE) <= new Date(this.endDate) && res.PROGRAM_TYPE == this.disabilty;
            }
            else if (this.selectedDate == 'CLOSED' && this.disabilty === 'both') {
              return new Date(res?.DATE_CLAIM_CLOSED) >= new Date(this.startDate) && new Date(res?.DATE_CLAIM_CLOSED) <= new Date(this.endDate) && res.CLAIM_STATUS == this.selectedDate;
            }
            else if (this.selectedDate == 'CLOSED' && this.disabilty !== 'both') {
              return new Date(res?.DATE_CLAIM_CLOSED) >= new Date(this.startDate) && new Date(res?.DATE_CLAIM_CLOSED) <= new Date(this.endDate) && res.CLAIM_STATUS == this.selectedDate && res.PROGRAM_TYPE == this.disabilty;
            }
          })
          this.empData = term;

          const keys = Object.keys(this.empData[0]);
          keys.forEach((key, index) => {
            let mappedColumn = {
              headerName: key.replace(/[^\w\s]/gi, "").trim().replace("CLAIMANT_","").replace("_"," ").replace(/\b\w/g, (s) => s.toUpperCase()),
              field: key,
              checked:true
            }
            this.coldata.push(mappedColumn);
            });
            this.columnDefs=this.coldata;
            this.rowData=this.empData;
          
        })
      
      }
    }
    if(this.selectedDisability  == 'summary'){
      this.coldata=[]
        if (this.startDate && this.endDate) {

          this.customerService.getCustomersSummaryLarge().then((res: any) => {
            const data1: any = res;

            const term = data1.filter(res => {

              if (this.disabilty !== 'both' && this.selectedDate == "OPEN") {
                return res.PROGRAM_TYPE == this.disabilty && res.CLAIM_STATUS == this.selectedDate;
              } else if (this.disabilty === 'both' && this.selectedDate == "OPEN") {
                return res.CLAIM_STATUS == this.selectedDate
              }

              else if (this.selectedDate == 'ACTUAL_RTW_DATE' && this.disabilty === 'both') {
                return new Date(res?.ACTUAL_RTW_DATE) >= new Date(this.startDate) && new Date(res?.ACTUAL_RTW_DATE) <= new Date(this.endDate);
              }
              else if (this.selectedDate == 'ACTUAL_RTW_DATE' && this.disabilty !== 'both') {
                return new Date(res?.ACTUAL_RTW_DATE) >= new Date(this.startDate) && new Date(res?.ACTUAL_RTW_DATE) <= new Date(this.endDate) && res.PROGRAM_TYPE == this.disabilty;
              }
              else if (this.selectedDate == 'EXPECTED_RTW_DATE' && this.disabilty === 'both') {
                return new Date(res?.EXPECTED_RTW_DATE) >= new Date(this.startDate) && new Date(res?.EXPECTED_RTW_DATE) <= new Date(this.endDate);
              }
              else if (this.selectedDate == 'EXPECTED_RTW_DATE' && this.disabilty !== 'both') {
                return new Date(res?.EXPECTED_RTW_DATE) >= new Date(this.startDate) && new Date(res?.EXPECTED_RTW_DATE) <= new Date(this.endDate) && res.PROGRAM_TYPE == this.disabilty;
              }
              else if (this.selectedDate == 'CLAIM' && this.disabilty === 'both') {
                return new Date(res?.NOTIFICATION_DATE) >= new Date(this.startDate) && new Date(res?.NOTIFICATION_DATE) <= new Date(this.endDate);
              }
              else if (this.selectedDate == 'CLAIM' && this.disabilty !== 'both') {
                return new Date(res?.NOTIFICATION_DATE) >= new Date(this.startDate) && new Date(res?.NOTIFICATION_DATE) <= new Date(this.endDate) && res.PROGRAM_TYPE == this.disabilty;
              }
              else if (this.selectedDate == 'CLOSED' && this.disabilty === 'both') {
                return new Date(res?.DATE_CLAIM_CLOSED) >= new Date(this.startDate) && new Date(res?.DATE_CLAIM_CLOSED) <= new Date(this.endDate) && res.CLAIM_STATUS == this.selectedDate;
              }
              else if (this.selectedDate == 'CLOSED' && this.disabilty !== 'both') {
                return new Date(res?.DATE_CLAIM_CLOSED) >= new Date(this.startDate) && new Date(res?.DATE_CLAIM_CLOSED) <= new Date(this.endDate) && res.CLAIM_STATUS == this.selectedDate && res.PROGRAM_TYPE == this.disabilty;
              }
            })
            this.empData1 = term;

            const keys = Object.keys(this.empData[0]);
            keys.forEach((key, index) => {
              let mappedColumn = {
                headerName: key.replace(/[^\w\s]/gi, "").trim().replace("CLAIMANT_","").replace("_"," ").replace(/\b\w/g, (s) => s.toUpperCase()),
                field: key,
                checked:true
              }
              this.coldata.push(mappedColumn);
              });
              this.columnDefs=this.coldata;
              this.rowData=this.empData1;
            
          })
        
        }
        else{
          this.customerService.getCustomersSummaryLarge().then((res: any) => {
            console.log(res)
          const data1 = res;
          this.empData1 = data1.filter(res => {

            if (this.disabilty !== 'both') {
              return res.PROGRAM_TYPE == this.disabilty && res.CLAIM_STATUS == this.dataSetType
            } else {
              return res.CLAIM_STATUS == this.dataSetType
            }
          })
          console.log(this.empData1)
          const keys = Object.keys(this.empData1[0]);
            keys.forEach((key, index) => {
              let mappedColumn = {
                headerName: key.replace(/[^\w\s]/gi, "").trim().replace("CLAIMANT_","").replace("_"," ").replace(/\b\w/g, (s) => s.toUpperCase()),
                field: key,
                checked:true
              }
              this.coldata.push(mappedColumn);
              });
              this.columnDefs=this.coldata;
            this.rowData=this.empData1;
        })
        }
  }
   
 
  }
  Selected(event){
    console.log(event);
    this.selectedLeave=event;
    this.leave();
  }
  SelectedDis(event){
    console.log(event);
    this.selectedDisability=event;
    this.disability();
  }
  
  binSelect(event,data){
  if(!event.target.checked){
    this.gridOptions.columnApi.setColumnVisible(event.target.name,false);
   }
  else{
    this.gridOptions.columnApi.setColumnVisible(event.target.name,true);
  }
  data.checked=event.target.checked;
 }

 Refresh(data){
  data.forEach(d=>{
    this.gridOptions.columnApi.setColumnVisible(d.field,true)
    d.checked=true;
  });
 }

  leave() {

    this.selectedColumn=[]
    this.ngOnInit()
    this.value2=true;
    this.leavedData = []
    this.leaveArray()
    this.mark = false;
    this.unmark = true;
    this.leavetable = true;
    //this.runtable = false;
    this.leaveData
    this.panel = false;
console.log("sel",this.selectedLeave)

if(this.selectedLeave == 'Detail'){
  this.coldata1=[]
    this.customerService.getLeave().then((res: any) => {

      const datas: any = res;
      const terms = datas.filter(res => {
        if (this.empLeave !== 'In' && this.selectedDate == "N") {

          return res.ABSENCE_TYPE == this.empLeave && res.CLOSED_INDICATOR == this.selectedDate;

        } else if (this.empLeave === 'In' && this.selectedDate == "N") {
          
          return res.CLOSED_INDICATOR == this.selectedDate
        }

      });
      this.leaveData = terms;
    console.log("leave",this.leaveData)
      const keys = Object.keys(this.leaveData[0]);
      keys.forEach((key, index) => {
        let mappedColumn = {
          headerName: key.replace(/[^\w\s]/gi, "").trim().replace("CLAIMANT_","").replace("_"," ").replace(/\b\w/g, (s) => s.toUpperCase()),
          field: key,
          checked:true
        }
        this.coldata1.push(mappedColumn);
        });
        this.columnDefs=this.coldata1;
        this.rowData=this.leaveData;
      });

      if (this.startDate && this.endDate) {
        this.customerService.getLeave().then((res: any) => {

          const datas: any = res;

        const terms = datas.filter(res => {
         
          if (this.selectedDate == 'Y' && this.empLeave === 'In') {

            return new Date(res?.LEAVE_START_DATE) >= new Date(this.startDate) && new Date(res?.LEAVE_START_DATE) <= new Date(this.endDate) && res.CLOSED_INDICATOR == this.selectedDate
          }
          else if (this.selectedDate == 'Y' && this.empLeave !== 'In') {

            return new Date(res?.LEAVE_START_DATE) >= new Date(this.startDate) && new Date(res?.LEAVE_START_DATE) <= new Date(this.endDate) && res.ABSENCE_TYPE == this.empLeave && res.CLOSED_INDICATOR==this.selectedDate;
          }
          else if (this.selectedDate == 'ACTUAL_RTW_DATE' && this.empLeave === 'In') {

            return new Date(res?.ACTUAL_RTW_DATE) >= new Date(this.startDate) && new Date(res?.ACTUAL_RTW_DATE) <= new Date(this.endDate);
          }
          else if (this.selectedDate == 'ACTUAL_RTW_DATE' && this.empLeave !== 'In' ) {

            
            return new Date(res?.ACTUAL_RTW_DATE) >= new Date(this.startDate) && new Date(res?.ACTUAL_RTW_DATE) <= new Date(this.endDate) && res.ABSENCE_TYPE == this.empLeave;
          }
          else if (this.selectedDate == 'ESTIMATED_RTW_DATE' && this.empLeave === 'In') {
           

            return new Date(res?.ESTIMATED_RTW_DATE) >= new Date(this.startDate) && new Date(res?.ESTIMATED_RTW_DATE) <= new Date(this.endDate);
          }
          else if (this.selectedDate == 'ESTIMATED_RTW_DATE' && this.disabilty !== 'In') {
       

            return new Date(res?.ESTIMATED_RTW_DATE) >= new Date(this.startDate) && new Date(res?.ESTIMATED_RTW_DATE) <= new Date(this.endDate) && res.ABSENCE_TYPE == this.empLeave;
          }
          else if (this.selectedDate == 'REQUESTED_START_DATE' && this.empLeave === 'In') {
       

            return new Date(res?.REQUESTED_START_DATE) >= new Date(this.startDate) && new Date(res?.REQUESTED_START_DATE) <= new Date(this.endDate);
          }
          else if (this.selectedDate == 'REQUESTED_START_DATE' && this.disabilty !== 'In') {
          

            return new Date(res?.REQUESTED_START_DATE) >= new Date(this.startDate) && new Date(res?.REQUESTED_START_DATE) <= new Date(this.endDate) && res.ABSENCE_TYPE == this.empLeave;
          }


        })
    
        this.leaveData = terms;
        console.log("leave",this.leaveData)
        const keys = Object.keys(this.leaveData[0]);
        keys.forEach((key, index) => {
          let mappedColumn = {
            headerName: key.replace(/[^\w\s]/gi, "").trim().replace("CLAIMANT_","").replace("_"," ").replace(/\b\w/g, (s) => s.toUpperCase()),
            field: key,
            checked:true
          }
          this.coldata1.push(mappedColumn);
          });
          this.columnDefs=this.coldata1;
          this.rowData=this.leaveData;
  });
      }
         // this.selectedColumn=this.coldata1;
    
  }
  if(this.selectedLeave == 'Lsummary'){
    this.coldata11=[];
    this.customerService.getLeaveSummary().then((res: any) => {

      const datas: any = res;
      const terms = datas.filter(res => {
        if (this.empLeave !== 'In' && this.selectedDate == "N") {

          return res.ABSENCE_TYPE == this.empLeave && res.CLOSED_INDICATOR == this.selectedDate;

        } else if (this.empLeave === 'In' && this.selectedDate == "N") {
          
          return res.CLOSED_INDICATOR == this.selectedDate
        }

      });
      this.leaveSummaryData = terms;
    

  // this.customerService. getLeaveSummary().then((res: any) => {
  //   console.log("res",res);
  //   this.leaveSummaryData=res;
    const keys = Object.keys(this.leaveSummaryData[0]);
      keys.forEach((key, index) => {
        let mappedColumn = {
          headerName: key.replace(/[^\w\s]/gi, "").trim().replace("CLAIMANT_","").replace("_"," ").replace(/\b\w/g, (s) => s.toUpperCase()),
          field: key,
          checked:true
        }
        this.coldata11.push(mappedColumn);
        });
        this.columnDefs=this.coldata11;
        this.rowData=this.leaveSummaryData;
  });

      if (this.startDate && this.endDate) {
        this.customerService.getLeaveSummary().then((res: any) => {

          const datas: any = res;

        const terms = datas.filter(res => {
         
          if (this.selectedDate == 'Y' && this.empLeave === 'In') {

            return new Date(res?.LEAVE_START_DATE) >= new Date(this.startDate) && new Date(res?.LEAVE_START_DATE) <= new Date(this.endDate) && res.CLOSED_INDICATOR == this.selectedDate
          }
          else if (this.selectedDate == 'Y' && this.empLeave !== 'In') {

            return new Date(res?.LEAVE_START_DATE) >= new Date(this.startDate) && new Date(res?.LEAVE_START_DATE) <= new Date(this.endDate) && res.ABSENCE_TYPE == this.empLeave && res.CLOSED_INDICATOR==this.selectedDate;
          }
          else if (this.selectedDate == 'ACTUAL_RTW_DATE' && this.empLeave === 'In') {

            return new Date(res?.ACTUAL_RTW_DATE) >= new Date(this.startDate) && new Date(res?.ACTUAL_RTW_DATE) <= new Date(this.endDate);
          }
          else if (this.selectedDate == 'ACTUAL_RTW_DATE' && this.empLeave !== 'In' ) {

            
            return new Date(res?.ACTUAL_RTW_DATE) >= new Date(this.startDate) && new Date(res?.ACTUAL_RTW_DATE) <= new Date(this.endDate) && res.ABSENCE_TYPE == this.empLeave;
          }
          else if (this.selectedDate == 'ESTIMATED_RTW_DATE' && this.empLeave === 'In') {
           

            return new Date(res?.ESTIMATED_RTW_DATE) >= new Date(this.startDate) && new Date(res?.ESTIMATED_RTW_DATE) <= new Date(this.endDate);
          }
          else if (this.selectedDate == 'ESTIMATED_RTW_DATE' && this.disabilty !== 'In') {
       

            return new Date(res?.ESTIMATED_RTW_DATE) >= new Date(this.startDate) && new Date(res?.ESTIMATED_RTW_DATE) <= new Date(this.endDate) && res.ABSENCE_TYPE == this.empLeave;
          }
          else if (this.selectedDate == 'REQUESTED_START_DATE' && this.empLeave === 'In') {
       

            return new Date(res?.REQUESTED_START_DATE) >= new Date(this.startDate) && new Date(res?.REQUESTED_START_DATE) <= new Date(this.endDate);
          }
          else if (this.selectedDate == 'REQUESTED_START_DATE' && this.disabilty !== 'In') {
          

            return new Date(res?.REQUESTED_START_DATE) >= new Date(this.startDate) && new Date(res?.REQUESTED_START_DATE) <= new Date(this.endDate) && res.ABSENCE_TYPE == this.empLeave;
          }


        })
       
        this.leaveSummaryData = terms;
        const keys = Object.keys(this.leaveSummaryData[0]);
      keys.forEach((key, index) => {
        let mappedColumn = {
          headerName: key.replace(/[^\w\s]/gi, "").trim().replace("CLAIMANT_","").replace("_"," ").replace(/\b\w/g, (s) => s.toUpperCase()),
          field: key,
          checked:true
        }
        this.coldata11.push(mappedColumn);
        });
        this.columnDefs=this.coldata11;
        this.rowData=this.leaveSummaryData;

      }
    );
       
  }
  }}

  report() {
    if (this.save = true) {
      this.runtable = false;
    }
    this.confirmationService.confirm({
      header: 'Enter a name',
    });
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }


  checkUncheckAll(e: any, d: any) {
    this.marked = e.target.checked;
    for (var i = 0; i < this.availableData.length; i++) {
      this.availableData[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();

  }

  isAllSelected() {
    this.masterSelected = this.availableData.every(function (available: any) {
      return available.isSelected == true;
    })
    this.getCheckedItemList();
  }


  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.availableData.length; i++) {
      if (this.availableData[i].isSelected)
        this.checkedList.push(this.availableData[i]);
    }


  }
  calling(index: any, b: any) {

    this.availableData[index].isSelected = !b;
  }

  leaveSelect(index: any, d: any) {
    this.leaveAvailableData[index].isSelected = !d;
  }

  back(index: any, c: any) {
    this.selectedData[index].isSelected = !c;
  }
  unSelect(index: any, e: any) {
    this.leavedData[index].isSelected = !e;
  }

  list() {
    // this.selecthide = true;
    this.availableData.forEach(el => {
      el.isSelected ? this.selectedData.push(el) : '';
    })
    this.availableData = this.availableData.filter(element => {
      return element.isSelected == false;
    })
  }

  add() {
    // this.leaveheader = false;
    this.leaveAvailableData.forEach(el => {
      el.isSelected ? this.leavedData.push(el) : '';
    })
    this.leaveAvailableData = this.leaveAvailableData.filter(element => {
      return element.isSelected == false;
    })
  }
  remove() {
    this.selectedData.forEach(element => {
      if (element.isSelected == true) {
        this.selectItems = element;
        this.availableData.push(element);
        let temp = this.selectedData.filter((res: any) => {
          return res.isSelected == false;
        })
        this.selectedData = temp;

      }
    })

  }
  reverse() {
    this.leavedData.forEach(element => {
      if (element.isSelected == true) {
        this.selectItems = element;
        this.leaveAvailableData.push(element);
        let temp = this.leavedData.filter((res: any) => {
          return res.isSelected == false;
        })
        this.leavedData = temp;

      }
    })
  }

  uparrow() {
    this.temp = [];
    this.selectedData.forEach((element: any, index: any) => {
      if (element.isSelected == true) {
        this.temp.push(index)
      }
    });

    let firstIndex: any = null;
    this.temp.forEach((res, i) => {
      if (i === 0) {
        if (res !== 0) {
          firstIndex = res;
          this.selectedData.splice(res - 1, 0, this.selectedData[res]);
          this.selectedData.splice(res + 1, 1);
        } else {
          firstIndex = 1;
        }
      } else if (i > 0) {
        this.selectedData.splice(firstIndex, 0, this.selectedData[res]);
        this.selectedData.splice(res + 1, 1);
        firstIndex++;
      }

    })
  }
  orderUp() {
    this.temp = [];
    this.leavedData.forEach((element: any, index: any) => {
      if (element.isSelected == true) {
        this.temp.push(index)
      }
    });

    let firstIndex: any = null;
    this.temp.forEach((res, i) => {
      if (i === 0) {
        if (res !== 0) {
          firstIndex = res;
          this.leavedData.splice(res - 1, 0, this.leavedData[res]);
          this.leavedData.splice(res + 1, 1);
        } else {
          firstIndex = 1;
        }
      } else if (i > 0) {
        this.leavedData.splice(firstIndex, 0, this.leavedData[res]);
        this.leavedData.splice(res + 1, 1);
        firstIndex++;
      }

    })

  }

  orderDown() {

    let isSelectedElementInds = []
    this.leavedData.forEach((el, index) => {
      if (el.isSelected == true) {
        isSelectedElementInds.push(index);
      }
    })

    let lastIndex: any = null;
    for (let i = (isSelectedElementInds.length - 1); i >= 0; i--) {
      if (i === (isSelectedElementInds.length - 1)) {
        if (isSelectedElementInds[i] !== (this.leavedData.length - 1)) {
          lastIndex = isSelectedElementInds[i];
          this.leavedData.splice(isSelectedElementInds[i] + 2, 0, this.leavedData[isSelectedElementInds[i]]);
          this.leavedData.splice(isSelectedElementInds[i], 1);
        } else {
          lastIndex = isSelectedElementInds[i] - 1;
        }
      } else if (i < (isSelectedElementInds.length - 1)) {
     
        this.leavedData.splice(lastIndex + 1, 0, this.leavedData[isSelectedElementInds[i]]);
        this.leavedData.splice(isSelectedElementInds[i], 1);
        lastIndex--;
      }

    }

  }

  

  downarrow() {

    let isSelectedElementInds = []
    this.selectedData.forEach((el, index) => {
      
      if (el.isSelected == true) {
      
        isSelectedElementInds.push(index);
      }
    })

    let lastIndex: any = null;
    for (let i = (isSelectedElementInds.length - 1); i >= 0; i--) {
   
      if (i === (isSelectedElementInds.length - 1)) {
        if (isSelectedElementInds[i] !== (this.selectedData.length - 1)) {
          lastIndex = isSelectedElementInds[i];
          this.selectedData.splice(isSelectedElementInds[i] + 2, 0, this.selectedData[isSelectedElementInds[i]]);
          this.selectedData.splice(isSelectedElementInds[i], 1);
        } else {
          lastIndex = isSelectedElementInds[i] - 1;
        }
      } else if (i < (isSelectedElementInds.length - 1)) {
        this.selectedData.splice(lastIndex + 1, 0, this.selectedData[isSelectedElementInds[i]]);
        this.selectedData.splice(isSelectedElementInds[i], 1);
        lastIndex--;
      }

    }
  }



  dateField() {
    this.datePicker = true;
    this.disabilityForm.addControl('calendar', this.fb.control('', [Validators.required]));
    this.disabilityForm.addControl('calendar1', this.fb.control('', [Validators.required]));
  }


  datavalue(openstat: any) {
    this.selectedDate = openstat;
    this.dataSetType = openstat;
    this.datePicker = false;
    this.disabilityForm.removeControl('calendar');
    this.disabilityForm.removeControl('calendar1');

  }

  dateByValue(data: any) {
    this.datePicker = true;
    this.disabilityForm.addControl('calendar', this.fb.control('', [Validators.required]));
    this.disabilityForm.addControl('calendar1', this.fb.control('', [Validators.required]));
    this.selectedDate = data;
    

  }
  currentLeave(status: any) {
    // this.startDate =undefined
    // this.endDate =undefined
    this.selectedDate=status;
    this.date = false;
    this.dataType = status;
    this.leaveForm.removeControl('startdate');
    this.leaveForm.removeControl('enddate');
  }

  leaveDate(status: any) {
    this.date = true;
    this.dataType = status;
    this.leaveForm.addControl('startdate', this.fb.control('', [Validators.required]));
    this.leaveForm.addControl('enddate', this.fb.control('', [Validators.required]));
    this.selectedDate = status;

  }

  // run() {
  //   if(this.folderPanelToggleBool == false){
	// 		d3.selectAll("#myreport-folder-panel-scroll-div").transition().delay(200).duration(400).style("display","none")
	// 		d3.select("#myreports-folderview-panel").transition().delay(200).duration(500).style("width","40px")
	// 		d3.select("#myreports-tile-list-scroll-view").transition().delay(200).duration(500).style({"left":"50px",'width':'96%','height':'100%','animation-name':'reducetime','float':'right'})//.style("width","calc(100% - 50px)")
	// 		d3.select("#myreports-folderview-panel").select("button").style("transform","rotate(180deg)")
	// 		d3.select("#all-reports-id").transition().delay(100).duration(100)
	// 		.style({'left':'-36px','top':'85px'}).style("transform","rotate(-90deg)")
	// 		this.folderPanelToggleBool = true;
	// 	}
	// 	else {
	// 		d3.select("#myreport-folder-panel-scroll-div").transition().delay(300).duration(400).style("display","block")
	// 		//d3.select("#submitbutton-id").transition().delay(500).duration(400).style("display","block")
	// 		d3.select("#myreports-folderview-panel").transition().delay(200).duration(500).style("width","350px")
	// 		d3.select("#myreports-tile-list-scroll-view").transition().delay(200).duration(500).style({"left":"360px",'width':'75%','height':'100%'})//.style("width","calc(100% - 360px)")
	// 		d3.select("#myreports-folderview-panel").select("button").style("transform","rotate(0deg)")
	// 		d3.select("#all-reports-id").transition().delay(250).duration(100)
	// 		.style({'left':'10px','top':'10px'}).style("transform","rotate(0deg)")
	// 		this.folderPanelToggleBool = false;
	// 	}

  //   this.header = true;
  //   let filteredColumn = [];
   
  //   this.empData.forEach(table => {
     

  //     let obj = {};
  //     this.selectedData.forEach((selectedColfield, index) => {
  //       obj[selectedColfield.columnField] = table[selectedColfield.columnField];
  //     })
  //     filteredColumn.push(obj);
    

  //   })

  //   filteredColumn.forEach((data, i) => {
  //     if (data['DISABILITY_START_DATE']) {
  //       filteredColumn[i]['DISABILITY_START_DATE'] = new Date(filteredColumn[i]['DISABILITY_START_DATE']);
  //     }
  //     if (data['DISABILITY_END_DATE']) {
  //       filteredColumn[i]['DISABILITY_END_DATE'] = new Date(filteredColumn[i]['DISABILITY_END_DATE']);
  //     }
  //     if (data['DATE_CLAIM_CLOSED']) {
  //       filteredColumn[i]['DATE_CLAIM_CLOSED'] = new Date(filteredColumn[i]['DATE_CLAIM_CLOSED']);
  //     }
  //     if (data['ACTUAL_RTW_DATE']) {
  //       filteredColumn[i]['ACTUAL_RTW_DATE'] = new Date(filteredColumn[i]['ACTUAL_RTW_DATE']);
  //     }
  //     if (data['EXPECTED_RTW_DATE']) {
  //       filteredColumn[i]['EXPECTED_RTW_DATE'] = new Date(filteredColumn[i]['EXPECTED_RTW_DATE']);
  //     }
  //     if (data['NOTIFICATION_DATE']) {
  //       filteredColumn[i]['NOTIFICATION_DATE'] = new Date(filteredColumn[i]['NOTIFICATION_DATE']);
  //     }

  //   })
  //   this.filteredColumnArray = Object.values(filteredColumn);
  //   this.filteredExport = this.filteredColumnArray;
    


  //   this.confirmationService.close();
  // }

  // tab() {
  //   if(this.folderPanelToggleBool == false){
	// 		d3.selectAll("#myreport-folder-panel-scroll-div").transition().delay(200).duration(400).style("display","none")
	// 		d3.select("#myreports-folderview-panel").transition().delay(200).duration(500).style("width","40px")
	// 		d3.select("#myreports-tile-list-scroll-view").transition().delay(200).duration(500).style({"left":"50px",'width':'96%','height':'100%','animation-name':'reducetime','float':'right'})//.style("width","calc(100% - 50px)")
	// 		d3.select("#myreports-folderview-panel").select("button").style("transform","rotate(180deg)")
	// 		d3.select("#all-reports-id").transition().delay(100).duration(100)
	// 		.style({'left':'-36px','top':'85px'}).style("transform","rotate(-90deg)")
	// 		this.folderPanelToggleBool = true;
	// 	}
	// 	else {
	// 		d3.select("#myreport-folder-panel-scroll-div").transition().delay(300).duration(400).style("display","block")
	// 		//d3.select("#submitbutton-id").transition().delay(500).duration(400).style("display","block")
	// 		d3.select("#myreports-folderview-panel").transition().delay(200).duration(500).style("width","350px")
	// 		d3.select("#myreports-tile-list-scroll-view").transition().delay(200).duration(500).style({"left":"360px",'width':'75%','height':'100%'})//.style("width","calc(100% - 360px)")
	// 		d3.select("#myreports-folderview-panel").select("button").style("transform","rotate(0deg)")
	// 		d3.select("#all-reports-id").transition().delay(250).duration(100)
	// 		.style({'left':'10px','top':'10px'}).style("transform","rotate(0deg)")
	// 		this.folderPanelToggleBool = false;
	// 	}
  //   this.leaveheader = true;
  //   this.panel = true;
 
  //   let leaveColumn = [];
 
  //   this.leaveData.forEach(table => {
  
     
  //     let obj = {};
  //     this.leavedData.forEach((selectedColfield, index) => {
      
  //       obj[selectedColfield.columnField] = table[selectedColfield.columnField];
  //     })
  
  //     leaveColumn.push(obj);
    

  //   })
  //   leaveColumn.forEach((data, i) => {
  //     if (data['REQUESTED_START_DATE']) {
  //       leaveColumn[i]['REQUESTED_START_DATE'] = new Date(leaveColumn[i]['REQUESTED_START_DATE']);
  //     }
  //     if (data['NOTIFICATION_DATE']) {
  //       leaveColumn[i]['NOTIFICATION_DATE'] = new Date(leaveColumn[i]['NOTIFICATION_DATE']);
  //     }
  //     if (data['REQUESTED_END_DATE']) {
  //       leaveColumn[i]['REQUESTED_END_DATE'] = new Date(leaveColumn[i]['REQUESTED_END_DATE']);
  //     }
  //     if (data['CERTIFICATION_START_DATE']) {
  //       leaveColumn[i]['CERTIFICATION_START_DATE'] = new Date(leaveColumn[i]['CERTIFICATION_START_DATE']);
  //     }
  //     if (data['CERTIFICATION_END_DATE']) {
  //       leaveColumn[i]['CERTIFICATION_END_DATE'] = new Date(leaveColumn[i]['CERTIFICATION_END_DATE']);
  //     }
  //     if (data['APPROVED_START_DATE']) {
  //       leaveColumn[i]['APPROVED_START_DATE'] = new Date(leaveColumn[i]['APPROVED_START_DATE']);
  //     }
  //     if (data['APPROVED_END_DATE']) {
  //       leaveColumn[i]['APPROVED_END_DATE'] = new Date(leaveColumn[i]['APPROVED_END_DATE']);
  //     }
  //     if (data['EXHAUST_DATE']) {
  //       leaveColumn[i]['EXHAUST_DATE'] = new Date(leaveColumn[i]['EXHAUST_DATE']);
  //     }
  //     if (data['ESTIMATED_RTW_DATE']) {
  //       leaveColumn[i]['ESTIMATED_RTW_DATE'] = new Date(leaveColumn[i]['ESTIMATED_RTW_DATE']);
  //     }
  //     if (data['ACTUAL_RTW_DATE']) {
  //       leaveColumn[i]['ACTUAL_RTW_DATE'] = new Date(leaveColumn[i]['ACTUAL_RTW_DATE']);
  //     }
  //     if (data['ESTIMATED_DELIVERY_DATE']) {
  //       leaveColumn[i]['ESTIMATED_DELIVERY_DATE'] = new Date(leaveColumn[i]['ESTIMATED_DELIVERY_DATE']);
  //     }
  //     if (data['LEAVE_START_DATE']) {
  //       leaveColumn[i]['LEAVE_START_DATE'] = new Date(leaveColumn[i]['LEAVE_START_DATE']);
  //     }
  //     if (data['LEAVE_END_DATE']) {
  //       leaveColumn[i]['LEAVE_END_DATE'] = new Date(leaveColumn[i]['LEAVE_END_DATE']);
  //     }
  //     if (data['CLOSURE_DATE']) {
  //       leaveColumn[i]['CLOSURE_DATE'] = new Date(leaveColumn[i]['CLOSURE_DATE']);
  //     }
  //     if (data['MIN_START_DATE']) { 
  //       leaveColumn[i]['MIN_START_DATE'] = new Date(leaveColumn[i]['MIN_START_DATE']);
  //     }
  //     if (data['MAX_END_DATE']) {
  //       leaveColumn[i]['MAX_END_DATE'] = new Date(leaveColumn[i]['MAX_END_DATE']);
  //     }
  //     if (data['CERT_DUE_DATE']) {
  //       leaveColumn[i]['CERT_DUE_DATE'] = new Date(leaveColumn[i]['CERT_DUE_DATE']);
  //     }


  //   })

  
  //   this.leaveColumnArray = Object.values(leaveColumn);
  //   console.log("leav",this.leaveColumnArray)  ;
  //   this.leaveExport = this.leaveColumnArray;
  //   console.log("leav",this.leaveExport)  ;

  //   this.confirmationService.close();
  // }
  asIsOrder(a, b) {
    return 1;
  }

  toggleVisibility(e: any, d: any) {
    this.marked = e.target.checked;
  }

  sort() {
    this.header = false;
    this.filter = true;
  }
  leavesort() {
    this.leaveheader = false;
    this.leavefilter = true;
  }

  selecttable() {
    this.header = true;
    this.filter = false;

  }
  leavefil() {
    this.leaveheader = true;
    this.leavefilter = false;

  }

  clear(table: Table) {
    table.clear();
    // if (this.hideMenuOnClear)
    // this.hide();
  }

  selecteDate(finaldate: any) {
    this.startDate = this.datePipe.transform(this.disabilityForm.get('calendar').value, 'MM/dd/yyyy')
    this.endDate = this.datePipe.transform(this.disabilityForm.get('calendar1').value, 'MM/dd/yyyy')
    this.dateSet = finaldate;
  }

  leaveStartDate(finaldate: any) {
    this.startDate = this.datePipe.transform(this.leaveForm.get('startdate').value, 'MM/dd/yyyy')
    this.endDate = this.datePipe.transform(this.leaveForm.get('enddate').value, 'MM/dd/yyyy')
    this.dateSet = finaldate;

  }

  onTableFiltered(date, data2) {
    this.filteredExport = '';
    this.filteredExport = data2;
    this.leaveExport = data2;
  }
   download1(val){
    this.Hdata=[];
    this.Rdata=[];
    if(val==1){
      this.Hdata=this.coldata;
      this.Rdata=this.empData;
      this.download("Disability",this.Hdata,this.Rdata);
    }
    if(val==2){
      this.Hdata=this.coldata1;
      this.Rdata=this.leaveData;
      this.download("Leave",this.Hdata,this.Rdata);
    }
   }
  download2(val){
    this.Hdata=[];
    this.Rdata=[];
    if(val==1){
      let dat=this.coldata.filter(item=>item.checked != false);
      let arr=[];
     for(let i=0;i<dat.length;i++){
        arr[i]=dat[i].field;
     }
     const result1 = this.empData.map(jsonData =>Object.entries(jsonData).filter(([key, value]) => arr.includes(key.toUpperCase())));
      var obj=[];
      for(let i=0;i<result1.length;i++){
       obj[i]= result1[i].reduce(function(result, currentArray) {
        result[currentArray[0]] = currentArray[1];
        return result;
    }, {});
  }
      this.Hdata=dat;
      this.Rdata=obj;
      this.download("Disability",this.Hdata,this.Rdata);
    }
    if(val==2){
      let dat=this.coldata1.filter(item=>item.checked != false);
      let arr=[];
     for(let i=0;i<dat.length;i++){
        arr[i]=dat[i].field;
     }
     const result1 = this.leaveData.map(jsonData =>Object.entries(jsonData).filter(([key, value]) => arr.includes(key.toUpperCase())));
      var obj=[];
      for(let i=0;i<result1.length;i++){
       obj[i]= result1[i].reduce(function(result, currentArray) {
        result[currentArray[0]] = currentArray[1];
        return result;
    }, {});
  }
      this.Hdata=dat;
      this.Rdata=obj;
      this.download("Leave",this.Hdata,this.Rdata);
    }

   }
  download(dat,hdata,rdata) {
   // Excel Title, Header, Data
  let arr=[];
  let arr1=[];
  let header =[];
  for(let i=0;i<hdata.length;i++){
    arr[i]=hdata[i].headerName;
  }
    const title = 'Build a'+ ' ' +dat+' '+'report';
    header = arr;
    console.log(arr);
    for(let i=0;i<rdata.length;i++){
      arr1[i]=Object.values(rdata[i]);
    }
    const data = arr1;
    console.log(data)

    // Create workbook and worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sharing Data');
    // Add Row and formatting
    const titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
    worksheet.addRow([]);
    // const subTitleRow = worksheet.addRow(['Date : 06-09-2020']);
    worksheet.mergeCells('A1:D2');
    // Blank Row
    worksheet.addRow([]);
    // Add Header Row
    const headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFF00' },
    bgColor: { argb: 'FF0000FF' }
  };
  cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
});

// Add Data and Conditional Formatting
    data.forEach(d => {
  const row = worksheet.addRow(d);
  let color = 'FF99FF99';
 }
);
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.addRow([]);
// Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data: any) => {
  const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  fs.saveAs(blob,title+'.xlsx');
});
  }
  close(){
    this.confirmationService.close();
  }
  Save(name){
    let model={};
    if(this.selectedValue == "leavee"){
      model={
        Name:name,
        DisabilityType:this.selectedValue,
        domain:this.selectedDomain,
        data:this.leaveForm.value
      }
      console.log(model)
      this.listOfFilter=true;
      this.savedData.push(model)
      this.confirmationService.close();
    }
    if(this.selectedValue == "onDemand"){
      model={
        Name:name,
        DisabilityType:this.selectedValue,
        domain:this.selectedDomain,
        data:this.disabilityForm.value
      }
      console.log(model)
      this.listOfFilter=true;
      this.savedData1.push(model)
      this.confirmationService.close();
    }
  }
  onSelect(list){
  if(list.DisabilityType == "leavee"){
    if(list.data.datatype == "N"){
      this.leaveForm.removeControl('startdate');
      this.leaveForm.removeControl('enddate');
      this.leaveForm.setValue({ clientname:list.data.clientname ,  leavetype:list.data.leavetype, datatype: list.data.datatype}); 
    }
    else{
      this.leaveForm.addControl('startdate', this.fb.control('', [Validators.required]));
      this.leaveForm.addControl('enddate', this.fb.control('', [Validators.required]));
      this.leaveForm.setValue({ clientname:list.data.clientname , leavetype:list.data.leavetype, datatype: list.data.datatype, startdate:list.data.startdate,enddate:list.data.enddate}) ;
  }
  }
  else{
    if(list.data.data == "OPEN"){
      this.disabilityForm.removeControl('calendar');
    this.disabilityForm.removeControl('calendar1');
      this.disabilityForm.setValue({ name:list.data.name ,  value:list.data.value, data: list.data.data}); 
    }
    else{
    this.disabilityForm.addControl('calendar', this.fb.control('', [Validators.required]));
    this.disabilityForm.addControl('calendar1', this.fb.control('', [Validators.required]));
    this.disabilityForm.setValue({ name:list.data.name ,  value:list.data.value, data: list.data.data, calendar:list.data.calendar,calendar1:list.data.calendar1}) ;
  }
  }
}
}

 

