import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Summury from '../../assets/Employee_data/Leave_Summary.json';
import { FormBuilder, Validators } from '@angular/forms';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import * as XLSX from 'xlsx';
import { PaginatorModule } from 'primeng/paginator';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridReadyEvent,ColumnApi, SideBarDef, GridOptions } from '@ag-grid-community/core';
import { saveAs } from 'file-saver';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';


declare var d3: any;

@Component({
  selector: 'app-leave-summary',
  templateUrl: './leave-summary.component.html',
  styleUrls: ['./leave-summary.component.css'],
  providers: [MessageService, ConfirmationService,AgGridAngular]
})
export class LeaveSummaryComponent implements OnInit {

  
  @ViewChild('TABLE') tableData: ElementRef;
  
  private gridApi;
  private gridColumnApi;

  sqlTableColumns?: any;
  reportOutput?: any;
  sqlTableSortColumn?: any;
  sqlTableSortDir?: any;
  folderPanelToggleBool: boolean = false;
  folderCollapse:Boolean = false;
   table:boolean = false;
  tabKey:any = [];
  tabValues: any = [];
  leaveForm: any;
  empLeave: any;
  alertMessage: Message[];
  public leaveData: any;
  mark: boolean;
  startDate = '01/01/2022';
  endDate = '12/31/2022';
  selectedDate: string;
  dateSet: any;
  datePicker: boolean = true;
  dataSetType: any;
  date: boolean = true;
  dataType: any;
  runDate: Date;
  headers: any = [];
  array: any = [];
  selectedColumn: string[] = [];
public gridOptions: GridOptions;
columnDefs: ColDef[] = [];
public rowData:any=[];
public defaultColDef: ColDef = {
  cellStyle: {'padding-left': 0 ,'text-align':"center"},
  flex:1,
  minWidth: 150, 
  maxWidth: 250,
  resizable: true,
  getQuickFilterText: params => {
    return params.value.name;
}
  
};
coldata:any=[];
  Hdata: any[];
  Rdata: any[];
  constructor(private http : HttpClient, private fb:FormBuilder, private datePipe: DatePipe, private messageService : MessageService, private confirmationService : ConfirmationService) {

    this.leaveForm = this.fb.group({
      clientname: ['', Validators.required],
      leavetype: ['', Validators.required],
      startdate: ['', Validators.required],
      enddate: ['', Validators.required],

    })


    this.gridOptions = {
      rowSelection: 'single',
      context: {},
      pagination: true,
      //paginationPageSize: 25,
      paginationAutoPageSize: true,
      onGridReady: (params) => {
        params.api.sizeColumnsToFit();
      },
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      getMainMenuItems: () => {
        return ['generalMenuTab', 'columnsMenuTab']
       },
       
       
    }

  }

  leave:any = Summury;

  ngOnInit(): void {
    d3.select(".tibo-topbar-title").text("Home / Report/ Leave Details"); // setting title
  
    if(Number(d3.select("#tibo-large-leftpanel-container").node().getBoundingClientRect().width) == 64){
      setTimeout(function(){
        d3.select(".tibo-right-container").transition().delay(0).duration(0).style("left","64px").ease("linear");
      },10)
    }

    this.getData();
    this.rowData=0;

  }
  

  folderPanelToggle() {
    //this.files = this.directoryArray;
    if(this.folderPanelToggleBool == false){
      d3.selectAll("#myreport-folder-panel-scroll-div").transition().delay(200).duration(400).style("display","none")
      d3.select("#myreports-folderview-panel").transition().delay(200).duration(500).style("width","40px")
      d3.select("#myreports-tile-list-scroll-view").transition().delay(200).duration(500).style({"left":"50px",'width':'96%','height':'100%','animation-name':'reducetime','float':'right'})//.style("width","calc(100% - 50px)")
      d3.select("#myreports-folderview-panel").select("button").style("transform","rotate(180deg)")
      d3.select("#all-reports-id").transition().delay(100).duration(100)
      .style({'left':'5px','top':'50px'}).style("transform","rotate(-90deg)")
      this.folderPanelToggleBool = true;
    }
    else {
      d3.select("#myreport-folder-panel-scroll-div").transition().delay(300).duration(400).style("display","block")
      //d3.select("#submitbutton-id").transition().delay(500).duration(400).style("display","block")
      d3.select("#myreports-folderview-panel").transition().delay(200).duration(500).style("width","350px")
      d3.select("#myreports-tile-list-scroll-view").transition().delay(200).duration(500).style({"left":"360px",'width':'75%','height':'100%'})//.style("width","calc(100% - 360px)")
      d3.select("#myreports-folderview-panel").select("button").style("transform","rotate(0deg)")
      d3.select("#all-reports-id").transition().delay(250).duration(100)
      .style({'left':'10px','top':'10px'}).style("transform","rotate(0deg)")
      this.folderPanelToggleBool = false;
    }
  }

  leaveType(type: any) {

    this.empLeave = type;
   
  }

  onRun() {

  

    this.runDate = new Date();

    this.table = true;


    console.log(this.leaveForm)
    

    if (!this.leaveForm.valid)
     {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Select The Mandatory Fields!' });
    } 
    else if (this.leaveForm.valid)

    this.confirmationService.confirm({
      header: 'Select Columns',
    });


   
   

this.http.get<any>('assets/Employee_data/Leave_Summary.json').subscribe((res) => {

    const datas: any = res;

  
         const terms = datas.filter(res => {
        if (this.empLeave === 'In') {


          return new Date(res?.LEAVE_START_DATE) >= new Date(this.startDate) && new Date(res?.LEAVE_START_DATE) <= new Date(this.endDate)
        }
        else if (this.empLeave !== 'In') {

          return new Date(res?.LEAVE_START_DATE) >= new Date(this.startDate) && new Date(res?.LEAVE_START_DATE) <= new Date(this.endDate) && res.ABSENCE_TYPE == this.empLeave
        }
      })
     
      this.leaveData = terms;

      const keys = Object.keys(this.leaveData[0]);
      keys.forEach((key, index) => {
        let mappedColumn = {
          headerName: key.replace(/[^\w\s]/gi, "").trim().replace("CLAIMANT_","").replace("_"," ").replace(/\b\w/g, (s) => s.toUpperCase()),
          field: key,
          checked:true
        }
        this.coldata.push(mappedColumn);
        });
        this.columnDefs=this.coldata;
        this.rowData=this.leaveData;
   
     
    
  })



}




getData(){
  this.leave.forEach((element:any) => {
    this.tabKey = Object.keys(element);
   this.headers = this.tabKey.map(item => item.replace("_"," "));
    this.tabValues.push(Object.values(element))
  })
  this.rowData=this.leaveData;
}



leaveStartDate(finaldate: any) {

  this.startDate = this.datePipe.transform(this.leaveForm.get('startdate').value, 'MM/dd/yyyy')
  this.endDate = this.datePipe.transform(this.leaveForm.get('enddate').value, 'MM/dd/yyyy')
  this.dateSet = finaldate;
  console.log(this.startDate +  "   "  + this.endDate)
}



selecteDate(finaldate: any) {
  this.startDate = this.datePipe.transform(this.leaveForm.get('calendar').value, 'MM/dd/yyyy')
  this.endDate = this.datePipe.transform(this.leaveForm.get('calendar1').value, 'MM/dd/yyyy')
  this.dateSet = finaldate;
  console.log(this.dateSet);
}


dateField() {
  this.datePicker = true;
}


datavalue(openstat: any) {
  this.selectedDate = openstat;
  this.dataSetType = openstat;
  this.datePicker = false;
}

dateByValue(data: any) {
  this.datePicker = true;
  this.selectedDate = data;
  

}
currentLeave(status: any) {
  this.startDate =undefined
  this.endDate =undefined

  this.selectedDate=status;
  this.date = false;
  this.dataType = status;
  this.leaveForm.removeControl('startdate');
  this.leaveForm.removeControl('enddate');

}

isDisableRun() : boolean{
      return this.leaveForm.invalid;
}

leaveDate(status: any) {
  this.date = true;
  this.selectedDate = status;
  this.dataType = status;
  this.leaveForm.addControl('startdate', this.fb.control('', [Validators.required]));
  this.leaveForm.addControl('enddate', this.fb.control('', [Validators.required]));

}


 download(){
    this.Hdata=this.coldata;
    this.Rdata=this.leaveData;
   let dat = "Leave Summary";
      // Excel Title, Header, Data
     let arr=[];
     let arr1=[];
     let header =[];
     for(let i=0;i<this.Hdata.length;i++){
       arr[i]=this.Hdata[i].headerName;
     }
       const title = dat;
       header = arr;
       console.log(arr);
       for(let i=0;i<this.Rdata.length;i++){
         arr1[i]=Object.values(this.Rdata[i]);
       }
       const data = arr1;
       console.log(data)
   
       // Create workbook and worksheet
       const workbook = new Workbook();
       const worksheet = workbook.addWorksheet('Sharing Data');
       // Add Row and formatting
       const titleRow = worksheet.addRow([title]);
       titleRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
       worksheet.addRow([]);
       const subTitleRow = worksheet.addRow(["Run Date:",this.runDate]);
       worksheet.addRow(["Start Date:" , this.startDate , "End Date:", this.endDate]);
       worksheet.mergeCells('A1:D2');
       // Blank Row
       worksheet.addRow([]);
       // Add Header Row
       const headerRow = worksheet.addRow(header);
       // Cell Style : Fill and Border
       headerRow.eachCell((cell, number) => {
     cell.fill = {
       type: 'pattern',
       pattern: 'solid',
       fgColor: { argb: '3492eb' },
       bgColor: { argb: 'FF0000FF' }
     };
     
     cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
   });
   
   // Add Data and Conditional Formatting
       data.forEach(d => {
     const row = worksheet.addRow(d);
     let color = 'FF99FF99';
    }
   );
       worksheet.getColumn(3).width = 30;
       worksheet.getColumn(4).width = 30;
       worksheet.addRow([]);
   // Generate Excel File with given name
       workbook.xlsx.writeBuffer().then((data: any) => {
     const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
     fs.saveAs(blob,title+'.xlsx');
   });
     }
  
 



onGridReady(params){
  this.gridApi = params.api;
  this.gridColumnApi= params.columnApi;
  this.http.get('../assets/Employee_data/leave-data.json').subscribe(data=>{
    params.api.setRowData(data);
  })
}


}


 