import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subscription } from 'rxjs';
import { Message,MessageService } from 'primeng/api';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { DashboardOutput } from './old-dashboard.output';

import { OverlayPanelModule } from 'primeng/overlaypanel';
import { jsPDF } from "jspdf";
import { DashboardService } from './old-dashboard.service';
import { Router } from '@angular/router';
import { event } from 'jquery';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridReadyEvent,ColumnApi, SideBarDef, GridOptions } from '@ag-grid-community/core';
import { Observable } from 'rxjs';
import { saveAs } from 'file-saver';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { FilterPipe } from 'src/app/build-report/filter.pipe';

declare var d3:any;
declare var acinfotech: any;
let dashListPanelToggleBool: boolean = false;
let isShow = true;
interface DashboardInfo {
	name: string,
	code: string
}

@Component({
	selector: 'app-old-dashboard',
	templateUrl: './old-dashboard.component.html',
	styleUrls: ['./old-dashboard.component.css'],
	providers: [DashboardService, MessageService, DialogService, DashboardOutput]
  })
  
  export class OldDashboardComponent implements OnInit{
	
	

	scrollHeight: string = '300px';
	dashboardInfo: DashboardInfo[];
	selectedDashboard;
	errorMsg = "";
	shareDashSuccMsg = "";
    searchText;
	searchValue;
	alertMessage: Message[];
	
	items: MenuItem[];
	moreItems: MenuItem[];
	wigetcolor: string;
	showClass: boolean=false;
	buttonIndex: any;
	allColorButton: boolean=false;
	items1: {};
	chartElement1: any;chartElement2: any;chartElement3: any;	chartElement4: any;chartElement5: any;chartElement6: any;chartElement7: any;chartElement8: any;chartElement9: any;
	chartElement10: any;chartElement11: any;chartElement12: any;chartElement13: any;



	DashboardsList: any=[];
	detail1:any;detail2:any;detail3:any;detail4:any;detail5:any;detail6:any;detail7:any;detail8:any;detail9:any;detail10:any;detail11:any;detail13:any;detail12:any;
	showDialog: boolean;
	firstClick: boolean=false;
	chartElement14: any;
	detail14:any;
	count: any=0;
	secondClick: boolean=false;
	title: string;
	DrillTableData=[
		{
		 "CLAIMANT_LAST_NAME": "Aarona",
		 "CLAIMANT_FIRST_NAME": "Neula",
		 "CLAIM_NO": "2022-10-07-0514-VP-01",
		 "DURATION": 108,
		 "DISABILITY_START_DATE": "09-30-2022 ",
		 "DATE_CLAIM_CLOSED": "01-17-2023 ",
		 "ACTUAL_RTW_DATE": "01-16-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Abad",
		 "CLAIMANT_FIRST_NAME": "Marcos",
		 "CLAIM_NO": "2020-12-11-0814-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "11-25-2020 ",
		 "DATE_CLAIM_CLOSED": "01-07-2021 ",
		 "ACTUAL_RTW_DATE": "12-14-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Abad",
		 "CLAIMANT_FIRST_NAME": "Marcos",
		 "CLAIM_NO": "2023-02-27-0262-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "02-27-2023 ",
		 "DATE_CLAIM_CLOSED": "03-20-2023 ",
		 "ACTUAL_RTW_DATE": "03-13-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Abader",
		 "CLAIMANT_FIRST_NAME": "Nermeen",
		 "CLAIM_NO": "2022-11-08-0573-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "12-05-2022 ",
		 "DATE_CLAIM_CLOSED": "01-11-2023 ",
		 "ACTUAL_RTW_DATE": "01-09-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Abalos",
		 "CLAIMANT_FIRST_NAME": "Angelica",
		 "CLAIM_NO": "2013-10-17-0177-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "10-28-2013 ",
		 "DATE_CLAIM_CLOSED": "02-06-2014 ",
		 "ACTUAL_RTW_DATE": "11-12-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Abalos",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2013-09-03-0394-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "08-26-2013 ",
		 "DATE_CLAIM_CLOSED": "03-11-2014 ",
		 "ACTUAL_RTW_DATE": "09-30-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Abano",
		 "CLAIMANT_FIRST_NAME": "John",
		 "CLAIM_NO": "2021-08-27-0647-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "08-24-2021 ",
		 "DATE_CLAIM_CLOSED": "09-30-2021 ",
		 "ACTUAL_RTW_DATE": "09-13-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Abbott",
		 "CLAIMANT_FIRST_NAME": "Rosenda",
		 "CLAIM_NO": "2015-07-28-0110-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "07-27-2015 ",
		 "DATE_CLAIM_CLOSED": "08-05-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Abbott",
		 "CLAIMANT_FIRST_NAME": "Rosenda",
		 "CLAIM_NO": "2015-08-19-0332-VP-01",
		 "DURATION": 87,
		 "DISABILITY_START_DATE": "08-07-2015 ",
		 "DATE_CLAIM_CLOSED": "11-06-2015 ",
		 "ACTUAL_RTW_DATE": "11-02-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Abbott",
		 "CLAIMANT_FIRST_NAME": "Leticia",
		 "CLAIM_NO": "2013-01-05-1968-VP-01",
		 "DURATION": 1106,
		 "DISABILITY_START_DATE": "05-16-2012 ",
		 "DATE_CLAIM_CLOSED": "01-15-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Abdul Al Muhaymin",
		 "CLAIMANT_FIRST_NAME": "Abdul",
		 "CLAIM_NO": "2015-03-18-0479-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "03-11-2015 ",
		 "DATE_CLAIM_CLOSED": "06-25-2015 ",
		 "ACTUAL_RTW_DATE": "03-23-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Abdul Al Muhaymin",
		 "CLAIMANT_FIRST_NAME": "Abdul",
		 "CLAIM_NO": "2015-01-14-0540-VP-01",
		 "DURATION": 6,
		 "DISABILITY_START_DATE": "01-09-2015 ",
		 "DATE_CLAIM_CLOSED": "02-06-2015 ",
		 "ACTUAL_RTW_DATE": "01-15-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Abdul Al Muhaymin",
		 "CLAIMANT_FIRST_NAME": "Abdul",
		 "CLAIM_NO": "2015-08-05-0317-VP-01",
		 "DURATION": 190,
		 "DISABILITY_START_DATE": "07-29-2015 ",
		 "DATE_CLAIM_CLOSED": "07-25-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Abe",
		 "CLAIMANT_FIRST_NAME": "Harry",
		 "CLAIM_NO": "2020-09-08-0931-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "09-08-2020 ",
		 "DATE_CLAIM_CLOSED": "11-10-2020 ",
		 "ACTUAL_RTW_DATE": "11-09-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Abedi",
		 "CLAIMANT_FIRST_NAME": "Saeed",
		 "CLAIM_NO": "2020-06-04-0730-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "06-29-2020 ",
		 "DATE_CLAIM_CLOSED": "08-11-2020 ",
		 "ACTUAL_RTW_DATE": "08-10-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Abel",
		 "CLAIMANT_FIRST_NAME": "Howard",
		 "CLAIM_NO": "2013-01-05-0191-VP-01",
		 "DURATION": 453,
		 "DISABILITY_START_DATE": "12-03-2012 ",
		 "DATE_CLAIM_CLOSED": "03-11-2014 ",
		 "ACTUAL_RTW_DATE": "03-01-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Abenes",
		 "CLAIMANT_FIRST_NAME": "Malleva",
		 "CLAIM_NO": "2016-05-19-0509-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "06-16-2016 ",
		 "DATE_CLAIM_CLOSED": "07-25-2016 ",
		 "ACTUAL_RTW_DATE": "07-15-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Abiang",
		 "CLAIMANT_FIRST_NAME": "Rosalynda",
		 "CLAIM_NO": "2014-05-23-0267-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "06-23-2014 ",
		 "DATE_CLAIM_CLOSED": "09-08-2014 ",
		 "ACTUAL_RTW_DATE": "09-08-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Abney",
		 "CLAIMANT_FIRST_NAME": "Toni",
		 "CLAIM_NO": "2022-12-01-0197-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "12-12-2022 ",
		 "DATE_CLAIM_CLOSED": "01-16-2023 ",
		 "ACTUAL_RTW_DATE": "01-09-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Abney",
		 "CLAIMANT_FIRST_NAME": "Toni",
		 "CLAIM_NO": "2022-05-25-0575-VP-01",
		 "DURATION": 107,
		 "DISABILITY_START_DATE": "05-16-2022 ",
		 "DATE_CLAIM_CLOSED": "10-20-2022 ",
		 "ACTUAL_RTW_DATE": "08-31-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Abriam",
		 "CLAIMANT_FIRST_NAME": "Cynthia",
		 "CLAIM_NO": "2016-10-28-0477-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "10-27-2016 ",
		 "DATE_CLAIM_CLOSED": "11-10-2016 ",
		 "ACTUAL_RTW_DATE": "11-03-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Abude",
		 "CLAIMANT_FIRST_NAME": "Andie",
		 "CLAIM_NO": "2013-02-05-0302-VP-01",
		 "DURATION": 66,
		 "DISABILITY_START_DATE": "02-05-2013 ",
		 "DATE_CLAIM_CLOSED": "05-15-2013 ",
		 "ACTUAL_RTW_DATE": "04-12-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Accurso",
		 "CLAIMANT_FIRST_NAME": "Roger",
		 "CLAIM_NO": "2017-01-20-0558-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "01-25-2017 ",
		 "DATE_CLAIM_CLOSED": "02-22-2017 ",
		 "ACTUAL_RTW_DATE": "02-06-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Acevedo",
		 "CLAIMANT_FIRST_NAME": "Bertha",
		 "CLAIM_NO": "2014-08-08-0440-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "08-19-2014 ",
		 "DATE_CLAIM_CLOSED": "11-12-2014 ",
		 "ACTUAL_RTW_DATE": "09-03-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Acevedo",
		 "CLAIMANT_FIRST_NAME": "Bertha",
		 "CLAIM_NO": "2013-05-15-0574-VP-01",
		 "DURATION": 6,
		 "DISABILITY_START_DATE": "05-14-2013 ",
		 "DATE_CLAIM_CLOSED": "08-03-2013 ",
		 "ACTUAL_RTW_DATE": "05-20-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Adame",
		 "CLAIMANT_FIRST_NAME": "Elvia",
		 "CLAIM_NO": "2019-11-18-0678-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "11-10-2019 ",
		 "DATE_CLAIM_CLOSED": "12-16-2019 ",
		 "ACTUAL_RTW_DATE": "12-13-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Adame",
		 "CLAIMANT_FIRST_NAME": "Elvia",
		 "CLAIM_NO": "2019-01-22-0569-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "01-11-2019 ",
		 "DATE_CLAIM_CLOSED": "04-25-2019 ",
		 "ACTUAL_RTW_DATE": "04-05-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Adame",
		 "CLAIMANT_FIRST_NAME": "Elvia",
		 "CLAIM_NO": "2016-09-30-0396-VP-01",
		 "DURATION": 126,
		 "DISABILITY_START_DATE": "08-02-2016 ",
		 "DATE_CLAIM_CLOSED": "12-21-2016 ",
		 "ACTUAL_RTW_DATE": "12-06-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Adame",
		 "CLAIMANT_FIRST_NAME": "Elvia",
		 "CLAIM_NO": "2018-04-25-0581-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "04-23-2018 ",
		 "DATE_CLAIM_CLOSED": "05-09-2018 ",
		 "ACTUAL_RTW_DATE": "05-07-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Adame",
		 "CLAIMANT_FIRST_NAME": "Elvia",
		 "CLAIM_NO": "2016-09-30-0113-VP-01",
		 "DISABILITY_START_DATE": "08-02-2016 ",
		 "DATE_CLAIM_CLOSED": "09-30-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Adame",
		 "CLAIMANT_FIRST_NAME": "Elvia",
		 "CLAIM_NO": "2016-01-11-0593-VP-01",
		 "DURATION": 168,
		 "DISABILITY_START_DATE": "01-10-2016 ",
		 "DATE_CLAIM_CLOSED": "09-30-2016 ",
		 "ACTUAL_RTW_DATE": "06-26-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Adams",
		 "CLAIMANT_FIRST_NAME": "Eric",
		 "CLAIM_NO": "2023-01-06-0904-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "01-06-2023 ",
		 "DATE_CLAIM_CLOSED": "01-31-2023 ",
		 "ACTUAL_RTW_DATE": "01-23-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Adams",
		 "CLAIMANT_FIRST_NAME": "Eric",
		 "CLAIM_NO": "2020-12-07-0476-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "11-30-2020 ",
		 "DATE_CLAIM_CLOSED": "01-12-2021 ",
		 "ACTUAL_RTW_DATE": "01-11-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Adams",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2016-07-04-0010-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "07-04-2016 ",
		 "DATE_CLAIM_CLOSED": "08-30-2016 ",
		 "ACTUAL_RTW_DATE": "07-25-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Adraneda",
		 "CLAIMANT_FIRST_NAME": "Reynaldo",
		 "CLAIM_NO": "2014-07-25-0376-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "07-22-2014 ",
		 "DATE_CLAIM_CLOSED": "07-25-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Agarwal",
		 "CLAIMANT_FIRST_NAME": "Meeta",
		 "CLAIM_NO": "2015-05-20-0302-VP-01",
		 "DURATION": 67,
		 "DISABILITY_START_DATE": "06-15-2015 ",
		 "DATE_CLAIM_CLOSED": "10-01-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Agpoon",
		 "CLAIMANT_FIRST_NAME": "Cristo",
		 "CLAIM_NO": "2013-02-28-0276-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "02-18-2013 ",
		 "DATE_CLAIM_CLOSED": "05-02-2013 ",
		 "ACTUAL_RTW_DATE": "03-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Agpoon",
		 "CLAIMANT_FIRST_NAME": "Cristo",
		 "CLAIM_NO": "2016-10-12-0198-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "10-04-2016 ",
		 "DATE_CLAIM_CLOSED": "11-30-2016 ",
		 "ACTUAL_RTW_DATE": "11-28-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Agpoon",
		 "CLAIMANT_FIRST_NAME": "Cristo",
		 "CLAIM_NO": "2018-01-30-0327-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "01-25-2018 ",
		 "DATE_CLAIM_CLOSED": "02-08-2018 ",
		 "ACTUAL_RTW_DATE": "02-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Agpoon",
		 "CLAIMANT_FIRST_NAME": "Cristo",
		 "CLAIM_NO": "2015-04-17-0299-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "04-10-2015 ",
		 "DATE_CLAIM_CLOSED": "05-04-2015 ",
		 "ACTUAL_RTW_DATE": "04-21-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Agpoon",
		 "CLAIMANT_FIRST_NAME": "Cristo",
		 "CLAIM_NO": "2016-05-23-0451-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "05-20-2016 ",
		 "DATE_CLAIM_CLOSED": "07-21-2016 ",
		 "ACTUAL_RTW_DATE": "06-06-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Agpoon",
		 "CLAIMANT_FIRST_NAME": "Cristo",
		 "CLAIM_NO": "2017-01-26-0200-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "01-20-2017 ",
		 "DATE_CLAIM_CLOSED": "03-30-2017 ",
		 "ACTUAL_RTW_DATE": "03-06-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Agrahara",
		 "CLAIMANT_FIRST_NAME": "Amaresh",
		 "CLAIM_NO": "2021-05-06-0794-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "05-07-2021 ",
		 "DATE_CLAIM_CLOSED": "06-08-2021 ",
		 "ACTUAL_RTW_DATE": "05-24-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Agrawal",
		 "CLAIMANT_FIRST_NAME": "Raka",
		 "CLAIM_NO": "2013-01-05-1840-VP-01",
		 "DURATION": 72,
		 "DISABILITY_START_DATE": "06-11-2012 ",
		 "DATE_CLAIM_CLOSED": "11-21-2013 ",
		 "ACTUAL_RTW_DATE": "08-22-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Agredano",
		 "CLAIMANT_FIRST_NAME": "Noel",
		 "CLAIM_NO": "2019-10-31-0667-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "10-23-2019 ",
		 "DATE_CLAIM_CLOSED": "11-21-2019 ",
		 "ACTUAL_RTW_DATE": "11-04-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aguiar",
		 "CLAIMANT_FIRST_NAME": "Miguel",
		 "CLAIM_NO": "2018-04-16-0360-VP-01",
		 "DURATION": 90,
		 "DISABILITY_START_DATE": "04-17-2018 ",
		 "DATE_CLAIM_CLOSED": "07-17-2018 ",
		 "ACTUAL_RTW_DATE": "07-16-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aguilar",
		 "CLAIMANT_FIRST_NAME": "Betsy",
		 "CLAIM_NO": "2015-08-20-0371-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "08-25-2015 ",
		 "DATE_CLAIM_CLOSED": "09-29-2015 ",
		 "ACTUAL_RTW_DATE": "09-22-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aguilar",
		 "CLAIMANT_FIRST_NAME": "Betsy",
		 "CLAIM_NO": "2018-07-09-0460-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "07-02-2018 ",
		 "DATE_CLAIM_CLOSED": "09-06-2018 ",
		 "ACTUAL_RTW_DATE": "09-04-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aguilar",
		 "CLAIMANT_FIRST_NAME": "Lucia",
		 "CLAIM_NO": "2014-07-10-0353-VP-01",
		 "DURATION": 37,
		 "DISABILITY_START_DATE": "07-23-2014 ",
		 "DATE_CLAIM_CLOSED": "11-12-2014 ",
		 "ACTUAL_RTW_DATE": "08-29-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aguilar",
		 "CLAIMANT_FIRST_NAME": "Jose",
		 "CLAIM_NO": "2016-01-27-0507-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "01-27-2016 ",
		 "DATE_CLAIM_CLOSED": "03-03-2016 ",
		 "ACTUAL_RTW_DATE": "02-10-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aguilar",
		 "CLAIMANT_FIRST_NAME": "Betsy",
		 "CLAIM_NO": "2016-05-11-0328-VP-01",
		 "DURATION": 38,
		 "DISABILITY_START_DATE": "06-03-2016 ",
		 "DATE_CLAIM_CLOSED": "07-13-2016 ",
		 "ACTUAL_RTW_DATE": "07-11-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aguilar",
		 "CLAIMANT_FIRST_NAME": "Ernesto",
		 "CLAIM_NO": "2017-08-28-0125-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "08-25-2017 ",
		 "DATE_CLAIM_CLOSED": "09-13-2017 ",
		 "ACTUAL_RTW_DATE": "09-04-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aguilar",
		 "CLAIMANT_FIRST_NAME": "Betsy",
		 "CLAIM_NO": "2021-04-22-0938-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "04-12-2021 ",
		 "DATE_CLAIM_CLOSED": "05-03-2021 ",
		 "ACTUAL_RTW_DATE": "04-26-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aguilar",
		 "CLAIMANT_FIRST_NAME": "Belinda",
		 "CLAIM_NO": "2023-01-17-1126-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "01-03-2023 ",
		 "DATE_CLAIM_CLOSED": "02-22-2023 ",
		 "ACTUAL_RTW_DATE": "01-18-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aguilar",
		 "CLAIMANT_FIRST_NAME": "Brittnie",
		 "CLAIM_NO": "2022-11-18-1139-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "12-13-2022 ",
		 "DATE_CLAIM_CLOSED": "02-27-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aguinaga",
		 "CLAIMANT_FIRST_NAME": "Cindy",
		 "CLAIM_NO": "2020-10-09-0629-VP-01",
		 "DURATION": 131,
		 "DISABILITY_START_DATE": "10-09-2020 ",
		 "DATE_CLAIM_CLOSED": "03-03-2021 ",
		 "ACTUAL_RTW_DATE": "02-17-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aguinaga",
		 "CLAIMANT_FIRST_NAME": "Cindy",
		 "CLAIM_NO": "2021-10-11-0859-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "10-11-2021 ",
		 "DATE_CLAIM_CLOSED": "11-16-2021 ",
		 "ACTUAL_RTW_DATE": "11-15-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aguinaga",
		 "CLAIMANT_FIRST_NAME": "Cindy",
		 "CLAIM_NO": "2018-08-27-0438-VP-01",
		 "DURATION": 44,
		 "DISABILITY_START_DATE": "08-20-2018 ",
		 "DATE_CLAIM_CLOSED": "10-14-2018 ",
		 "ACTUAL_RTW_DATE": "10-03-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Agustin",
		 "CLAIMANT_FIRST_NAME": "Renalyn",
		 "CLAIM_NO": "2019-05-15-0587-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "05-20-2019 ",
		 "DATE_CLAIM_CLOSED": "07-01-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ahmed",
		 "CLAIMANT_FIRST_NAME": "Noor",
		 "CLAIM_NO": "2014-10-09-0025-VP-01",
		 "DURATION": 519,
		 "DISABILITY_START_DATE": "09-29-2014 ",
		 "DATE_CLAIM_CLOSED": "10-05-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ahsan",
		 "CLAIMANT_FIRST_NAME": "Jeffrey",
		 "CLAIM_NO": "2023-03-14-0764-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "02-17-2023 ",
		 "DATE_CLAIM_CLOSED": "04-21-2023 ",
		 "ACTUAL_RTW_DATE": "03-13-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ajdelman",
		 "CLAIMANT_FIRST_NAME": "Marisa",
		 "CLAIM_NO": "2017-07-18-0565-VP-01",
		 "DURATION": 362,
		 "DISABILITY_START_DATE": "07-18-2017 ",
		 "DATE_CLAIM_CLOSED": "07-16-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Akbany",
		 "CLAIMANT_FIRST_NAME": "Asad",
		 "CLAIM_NO": "2015-09-15-0517-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "09-15-2015 ",
		 "DATE_CLAIM_CLOSED": "10-26-2015 ",
		 "ACTUAL_RTW_DATE": "09-30-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Akhter",
		 "CLAIMANT_FIRST_NAME": "Shaheen",
		 "CLAIM_NO": "2017-12-07-0332-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "11-20-2017 ",
		 "DATE_CLAIM_CLOSED": "11-28-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Akhter",
		 "CLAIMANT_FIRST_NAME": "Shaheen",
		 "CLAIM_NO": "2014-11-24-0215-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "11-17-2014 ",
		 "DATE_CLAIM_CLOSED": "12-02-2014 ",
		 "ACTUAL_RTW_DATE": "11-24-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Akins",
		 "CLAIMANT_FIRST_NAME": "Carole",
		 "CLAIM_NO": "2023-02-17-0149-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "02-08-2023 ",
		 "DATE_CLAIM_CLOSED": "03-24-2023 ",
		 "ACTUAL_RTW_DATE": "03-13-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Akins",
		 "CLAIMANT_FIRST_NAME": "Carole",
		 "CLAIM_NO": "2022-11-05-0085-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "11-07-2022 ",
		 "DATE_CLAIM_CLOSED": "01-10-2023 ",
		 "ACTUAL_RTW_DATE": "01-02-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alapati",
		 "CLAIMANT_FIRST_NAME": "Mitchell",
		 "CLAIM_NO": "2021-01-08-0623-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "12-16-2020 ",
		 "DATE_CLAIM_CLOSED": "02-11-2021 ",
		 "ACTUAL_RTW_DATE": "02-10-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alapati",
		 "CLAIMANT_FIRST_NAME": "Mitchell",
		 "CLAIM_NO": "2019-03-21-0295-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "03-14-2019 ",
		 "DATE_CLAIM_CLOSED": "04-17-2019 ",
		 "ACTUAL_RTW_DATE": "04-11-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alapati",
		 "CLAIMANT_FIRST_NAME": "Mitchell",
		 "CLAIM_NO": "2018-06-06-0111-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "05-29-2018 ",
		 "DATE_CLAIM_CLOSED": "06-27-2018 ",
		 "ACTUAL_RTW_DATE": "06-25-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alcala",
		 "CLAIMANT_FIRST_NAME": "Jose",
		 "CLAIM_NO": "2013-09-05-0280-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "09-12-2013 ",
		 "DATE_CLAIM_CLOSED": "02-06-2014 ",
		 "ACTUAL_RTW_DATE": "09-23-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alcantar",
		 "CLAIMANT_FIRST_NAME": "Alonzo",
		 "CLAIM_NO": "2019-01-22-0722-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "01-14-2019 ",
		 "DATE_CLAIM_CLOSED": "03-08-2019 ",
		 "ACTUAL_RTW_DATE": "02-25-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alcantara",
		 "CLAIMANT_FIRST_NAME": "Candice",
		 "CLAIM_NO": "2023-03-02-0736-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "02-20-2023 ",
		 "DATE_CLAIM_CLOSED": "03-22-2023 ",
		 "ACTUAL_RTW_DATE": "03-06-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alejandres",
		 "CLAIMANT_FIRST_NAME": "Jesus",
		 "CLAIM_NO": "2020-03-11-0756-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "03-31-2020 ",
		 "DATE_CLAIM_CLOSED": "03-23-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aleman",
		 "CLAIMANT_FIRST_NAME": "Maykaling",
		 "CLAIM_NO": "2015-09-10-0202-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "09-04-2015 ",
		 "DATE_CLAIM_CLOSED": "11-11-2015 ",
		 "ACTUAL_RTW_DATE": "09-14-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alivio",
		 "CLAIMANT_FIRST_NAME": "Lorenza",
		 "CLAIM_NO": "2015-03-18-0183-VP-01",
		 "DURATION": 212,
		 "DISABILITY_START_DATE": "03-18-2015 ",
		 "DATE_CLAIM_CLOSED": "10-23-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alivio",
		 "CLAIMANT_FIRST_NAME": "Lorenza",
		 "CLAIM_NO": "2014-05-19-0485-VP-01",
		 "DURATION": 96,
		 "DISABILITY_START_DATE": "05-19-2014 ",
		 "DATE_CLAIM_CLOSED": "12-15-2014 ",
		 "ACTUAL_RTW_DATE": "08-23-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Allaert",
		 "CLAIMANT_FIRST_NAME": "Jan",
		 "CLAIM_NO": "2017-03-10-0148-VP-01",
		 "DURATION": 306,
		 "DISABILITY_START_DATE": "03-08-2017 ",
		 "DATE_CLAIM_CLOSED": "01-10-2018 ",
		 "ACTUAL_RTW_DATE": "01-08-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Allen",
		 "CLAIMANT_FIRST_NAME": "Aine",
		 "CLAIM_NO": "2013-01-05-0070-VP-01",
		 "DURATION": 52,
		 "DISABILITY_START_DATE": "01-15-2013 ",
		 "DATE_CLAIM_CLOSED": "05-06-2013 ",
		 "ACTUAL_RTW_DATE": "03-08-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Almando",
		 "CLAIMANT_FIRST_NAME": "Roderic",
		 "CLAIM_NO": "2016-10-18-0341-VP-01",
		 "DURATION": 53,
		 "DISABILITY_START_DATE": "10-13-2016 ",
		 "DATE_CLAIM_CLOSED": "01-13-2017 ",
		 "ACTUAL_RTW_DATE": "12-05-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Almanza",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2018-05-29-0789-VP-01",
		 "DURATION": 108,
		 "DISABILITY_START_DATE": "05-18-2018 ",
		 "DATE_CLAIM_CLOSED": "09-04-2018 ",
		 "ACTUAL_RTW_DATE": "09-03-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Almanza",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2018-09-25-0091-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "10-03-2018 ",
		 "DATE_CLAIM_CLOSED": "11-28-2018 ",
		 "ACTUAL_RTW_DATE": "11-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Almaraz",
		 "CLAIMANT_FIRST_NAME": "Adriana",
		 "CLAIM_NO": "2014-11-12-0165-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "11-17-2014 ",
		 "DATE_CLAIM_CLOSED": "01-26-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Almaraz",
		 "CLAIMANT_FIRST_NAME": "Adriana",
		 "CLAIM_NO": "2018-02-05-0553-VP-01",
		 "DURATION": 112,
		 "DISABILITY_START_DATE": "02-19-2018 ",
		 "DATE_CLAIM_CLOSED": "05-07-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Almeida",
		 "CLAIMANT_FIRST_NAME": "Vilene",
		 "CLAIM_NO": "2022-04-27-0257-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "04-20-2022 ",
		 "DATE_CLAIM_CLOSED": "05-19-2022 ",
		 "ACTUAL_RTW_DATE": "05-16-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Almeida Souto",
		 "CLAIMANT_FIRST_NAME": "Diana",
		 "CLAIM_NO": "2020-04-02-0911-VP-01",
		 "DURATION": 85,
		 "DISABILITY_START_DATE": "04-09-2020 ",
		 "DATE_CLAIM_CLOSED": "07-06-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alonso",
		 "CLAIMANT_FIRST_NAME": "Sigifredo",
		 "CLAIM_NO": "2016-10-05-0515-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "10-06-2016 ",
		 "DATE_CLAIM_CLOSED": "10-27-2016 ",
		 "ACTUAL_RTW_DATE": "10-24-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alonso",
		 "CLAIMANT_FIRST_NAME": "Gerardo",
		 "CLAIM_NO": "2022-11-28-0578-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "11-29-2022 ",
		 "DATE_CLAIM_CLOSED": "12-05-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alonso",
		 "CLAIMANT_FIRST_NAME": "Sigifredo",
		 "CLAIM_NO": "2017-02-27-0628-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "02-24-2017 ",
		 "DATE_CLAIM_CLOSED": "03-21-2017 ",
		 "ACTUAL_RTW_DATE": "03-20-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alonso",
		 "CLAIMANT_FIRST_NAME": "Gerardo",
		 "CLAIM_NO": "2022-06-13-1009-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "06-27-2022 ",
		 "DATE_CLAIM_CLOSED": "10-12-2022 ",
		 "ACTUAL_RTW_DATE": "07-18-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alonzo",
		 "CLAIMANT_FIRST_NAME": "Alberto",
		 "CLAIM_NO": "2021-02-11-0431-VP-01",
		 "DURATION": 23,
		 "DISABILITY_START_DATE": "01-28-2021 ",
		 "DATE_CLAIM_CLOSED": "02-25-2021 ",
		 "ACTUAL_RTW_DATE": "02-20-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alonzo",
		 "CLAIMANT_FIRST_NAME": "Nicole",
		 "CLAIM_NO": "2021-04-09-0982-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "04-12-2021 ",
		 "DATE_CLAIM_CLOSED": "04-28-2021 ",
		 "ACTUAL_RTW_DATE": "04-26-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Altman",
		 "CLAIMANT_FIRST_NAME": "Meghan",
		 "CLAIM_NO": "2021-12-02-0734-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "01-31-2022 ",
		 "DATE_CLAIM_CLOSED": "03-16-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alvarado",
		 "CLAIMANT_FIRST_NAME": "Gioconda",
		 "CLAIM_NO": "2015-11-19-0150-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "12-10-2015 ",
		 "DATE_CLAIM_CLOSED": "01-13-2016 ",
		 "ACTUAL_RTW_DATE": "12-28-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alvarado",
		 "CLAIMANT_FIRST_NAME": "Damaris",
		 "CLAIM_NO": "2021-02-04-0438-VP-01",
		 "DURATION": 269,
		 "DISABILITY_START_DATE": "01-26-2021 ",
		 "DATE_CLAIM_CLOSED": "10-27-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alvarado",
		 "CLAIMANT_FIRST_NAME": "Marie",
		 "CLAIM_NO": "2014-06-16-7812-VP-01",
		 "DURATION": 4,
		 "DISABILITY_START_DATE": "06-09-2014 ",
		 "DATE_CLAIM_CLOSED": "08-11-2014 ",
		 "ACTUAL_RTW_DATE": "06-13-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alvarado",
		 "CLAIMANT_FIRST_NAME": "Amy",
		 "CLAIM_NO": "2013-05-22-0423-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "05-07-2013 ",
		 "DATE_CLAIM_CLOSED": "07-09-2013 ",
		 "ACTUAL_RTW_DATE": "06-05-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alvarado",
		 "CLAIMANT_FIRST_NAME": "Marie",
		 "CLAIM_NO": "2014-07-30-0259-VP-01",
		 "DURATION": 910,
		 "DISABILITY_START_DATE": "07-15-2014 ",
		 "DATE_CLAIM_CLOSED": "03-08-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alvarado",
		 "CLAIMANT_FIRST_NAME": "Amy",
		 "CLAIM_NO": "2013-01-05-0092-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "12-10-2012 ",
		 "DATE_CLAIM_CLOSED": "02-10-2013 ",
		 "ACTUAL_RTW_DATE": "01-07-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alvarado",
		 "CLAIMANT_FIRST_NAME": "Samantha",
		 "CLAIM_NO": "2022-02-02-0468-VP-01",
		 "DURATION": 181,
		 "DISABILITY_START_DATE": "01-25-2022 ",
		 "DATE_CLAIM_CLOSED": "08-04-2022 ",
		 "ACTUAL_RTW_DATE": "07-25-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alvarado",
		 "CLAIMANT_FIRST_NAME": "Julia",
		 "CLAIM_NO": "2021-10-19-1030-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "11-10-2021 ",
		 "DATE_CLAIM_CLOSED": "01-10-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alvarado",
		 "CLAIMANT_FIRST_NAME": "Damaris",
		 "CLAIM_NO": "2019-11-21-0753-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "11-12-2019 ",
		 "DATE_CLAIM_CLOSED": "03-10-2020 ",
		 "ACTUAL_RTW_DATE": "12-03-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alvarado",
		 "CLAIMANT_FIRST_NAME": "Yadira",
		 "CLAIM_NO": "2013-01-05-1911-VP-01",
		 "DURATION": 230,
		 "DISABILITY_START_DATE": "05-22-2012 ",
		 "DATE_CLAIM_CLOSED": "02-09-2013 ",
		 "ACTUAL_RTW_DATE": "01-07-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alvarado",
		 "CLAIMANT_FIRST_NAME": "Julia",
		 "CLAIM_NO": "2020-07-20-0479-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "07-21-2020 ",
		 "DATE_CLAIM_CLOSED": "08-05-2020 ",
		 "ACTUAL_RTW_DATE": "08-03-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alvarado",
		 "CLAIMANT_FIRST_NAME": "Damaris",
		 "CLAIM_NO": "2020-08-19-0623-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "08-14-2020 ",
		 "DATE_CLAIM_CLOSED": "09-23-2020 ",
		 "ACTUAL_RTW_DATE": "08-26-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alvarez",
		 "CLAIMANT_FIRST_NAME": "Anton",
		 "CLAIM_NO": "2021-01-28-0690-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "01-18-2021 ",
		 "DATE_CLAIM_CLOSED": "03-05-2021 ",
		 "ACTUAL_RTW_DATE": "01-27-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alvarez",
		 "CLAIMANT_FIRST_NAME": "Anton",
		 "CLAIM_NO": "2019-02-06-0420-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "01-28-2019 ",
		 "DATE_CLAIM_CLOSED": "02-19-2019 ",
		 "ACTUAL_RTW_DATE": "02-18-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alvarez",
		 "CLAIMANT_FIRST_NAME": "Daniel",
		 "CLAIM_NO": "2015-04-23-0361-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "06-01-2015 ",
		 "DATE_CLAIM_CLOSED": "07-13-2015 ",
		 "ACTUAL_RTW_DATE": "06-17-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alvarez",
		 "CLAIMANT_FIRST_NAME": "Anton",
		 "CLAIM_NO": "2018-01-02-0775-VP-01",
		 "DURATION": 164,
		 "DISABILITY_START_DATE": "01-17-2018 ",
		 "DATE_CLAIM_CLOSED": "07-05-2018 ",
		 "ACTUAL_RTW_DATE": "06-30-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Alvarez-Tostado",
		 "CLAIMANT_FIRST_NAME": "Gabriela",
		 "CLAIM_NO": "2020-01-22-0483-VP-01",
		 "DURATION": 38,
		 "DISABILITY_START_DATE": "01-13-2020 ",
		 "DATE_CLAIM_CLOSED": "02-26-2020 ",
		 "ACTUAL_RTW_DATE": "02-20-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Amador",
		 "CLAIMANT_FIRST_NAME": "Gregory",
		 "CLAIM_NO": "2021-02-14-0046-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "02-15-2021 ",
		 "DATE_CLAIM_CLOSED": "03-03-2021 ",
		 "ACTUAL_RTW_DATE": "03-01-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Amaya",
		 "CLAIMANT_FIRST_NAME": "Leticia",
		 "CLAIM_NO": "2016-10-03-0158-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "09-29-2016 ",
		 "DATE_CLAIM_CLOSED": "10-17-2016 ",
		 "ACTUAL_RTW_DATE": "10-10-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Amaya",
		 "CLAIMANT_FIRST_NAME": "Leticia",
		 "CLAIM_NO": "2020-05-01-0497-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "05-04-2020 ",
		 "DATE_CLAIM_CLOSED": "05-18-2020 ",
		 "ACTUAL_RTW_DATE": "05-18-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ambriz",
		 "CLAIMANT_FIRST_NAME": "Raul",
		 "CLAIM_NO": "2015-05-12-0086-VP-01",
		 "DISABILITY_START_DATE": "04-27-2015 ",
		 "DATE_CLAIM_CLOSED": "06-11-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ambrocio",
		 "CLAIMANT_FIRST_NAME": "Susan",
		 "CLAIM_NO": "2014-02-06-0441-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "02-03-2014 ",
		 "DATE_CLAIM_CLOSED": "05-01-2014 ",
		 "ACTUAL_RTW_DATE": "02-17-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ambrocio",
		 "CLAIMANT_FIRST_NAME": "Susan",
		 "CLAIM_NO": "2013-01-25-0060-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "01-22-2013 ",
		 "DATE_CLAIM_CLOSED": "08-01-2013 ",
		 "ACTUAL_RTW_DATE": "01-31-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ambrocio",
		 "CLAIMANT_FIRST_NAME": "Susan",
		 "CLAIM_NO": "2015-06-03-0419-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "06-03-2015 ",
		 "DATE_CLAIM_CLOSED": "08-06-2015 ",
		 "ACTUAL_RTW_DATE": "06-11-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ameya",
		 "CLAIMANT_FIRST_NAME": "Azeb",
		 "CLAIM_NO": "2015-05-28-0201-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "05-22-2015 ",
		 "DATE_CLAIM_CLOSED": "09-02-2015 ",
		 "ACTUAL_RTW_DATE": "08-14-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ameya",
		 "CLAIMANT_FIRST_NAME": "Azeb",
		 "CLAIM_NO": "2021-06-29-0393-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "06-24-2021 ",
		 "DATE_CLAIM_CLOSED": "09-02-2021 ",
		 "ACTUAL_RTW_DATE": "08-31-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Amezcua",
		 "CLAIMANT_FIRST_NAME": "Gabriela",
		 "CLAIM_NO": "2013-08-01-0084-VP-01",
		 "DURATION": 115,
		 "DISABILITY_START_DATE": "08-09-2013 ",
		 "DATE_CLAIM_CLOSED": "12-06-2013 ",
		 "ACTUAL_RTW_DATE": "12-02-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Amora",
		 "CLAIMANT_FIRST_NAME": "Leocadia",
		 "CLAIM_NO": "2015-12-11-0361-VP-01",
		 "DURATION": 154,
		 "DISABILITY_START_DATE": "01-04-2016 ",
		 "DATE_CLAIM_CLOSED": "06-09-2016 ",
		 "ACTUAL_RTW_DATE": "06-06-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Amora",
		 "CLAIMANT_FIRST_NAME": "Leocadia",
		 "CLAIM_NO": "2018-05-21-0706-VP-01",
		 "DURATION": 162,
		 "DISABILITY_START_DATE": "05-23-2018 ",
		 "DATE_CLAIM_CLOSED": "11-08-2018 ",
		 "ACTUAL_RTW_DATE": "11-01-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Amora",
		 "CLAIMANT_FIRST_NAME": "Leocadia",
		 "CLAIM_NO": "2017-06-16-0508-VP-01",
		 "DURATION": 144,
		 "DISABILITY_START_DATE": "06-22-2017 ",
		 "DATE_CLAIM_CLOSED": "11-20-2017 ",
		 "ACTUAL_RTW_DATE": "11-13-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Amora",
		 "CLAIMANT_FIRST_NAME": "Leocadia",
		 "CLAIM_NO": "2017-01-03-0302-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "12-26-2016 ",
		 "DATE_CLAIM_CLOSED": "03-06-2017 ",
		 "ACTUAL_RTW_DATE": "03-06-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Amora",
		 "CLAIMANT_FIRST_NAME": "Leocadia",
		 "CLAIM_NO": "2020-09-14-0875-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "09-21-2020 ",
		 "DATE_CLAIM_CLOSED": "09-30-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Amores",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2013-03-18-0202-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "03-18-2013 ",
		 "DATE_CLAIM_CLOSED": "05-15-2013 ",
		 "ACTUAL_RTW_DATE": "04-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Amores",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2013-05-02-0331-VP-01",
		 "DURATION": 146,
		 "DISABILITY_START_DATE": "05-01-2013 ",
		 "DATE_CLAIM_CLOSED": "10-01-2013 ",
		 "ACTUAL_RTW_DATE": "09-24-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Amram",
		 "CLAIMANT_FIRST_NAME": "Jose",
		 "CLAIM_NO": "2014-05-02-0234-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "05-05-2014 ",
		 "DATE_CLAIM_CLOSED": "09-03-2014 ",
		 "ACTUAL_RTW_DATE": "05-26-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Anaya",
		 "CLAIMANT_FIRST_NAME": "Ulices",
		 "CLAIM_NO": "2016-10-26-0503-VP-01",
		 "DURATION": 53,
		 "DISABILITY_START_DATE": "11-18-2016 ",
		 "DATE_CLAIM_CLOSED": "01-17-2017 ",
		 "ACTUAL_RTW_DATE": "01-10-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Anaya",
		 "CLAIMANT_FIRST_NAME": "Ulices",
		 "CLAIM_NO": "2021-12-06-1354-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "12-10-2021 ",
		 "DATE_CLAIM_CLOSED": "01-27-2022 ",
		 "ACTUAL_RTW_DATE": "01-24-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Anaya",
		 "CLAIMANT_FIRST_NAME": "Ulices",
		 "CLAIM_NO": "2020-04-03-0766-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "04-02-2020 ",
		 "DATE_CLAIM_CLOSED": "04-17-2020 ",
		 "ACTUAL_RTW_DATE": "04-15-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Anderson",
		 "CLAIMANT_FIRST_NAME": "Joel",
		 "CLAIM_NO": "2013-07-31-0225-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "07-23-2013 ",
		 "DATE_CLAIM_CLOSED": "10-30-2013 ",
		 "ACTUAL_RTW_DATE": "09-02-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Anderson",
		 "CLAIMANT_FIRST_NAME": "Joel",
		 "CLAIM_NO": "2013-04-30-0061-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "05-07-2013 ",
		 "DATE_CLAIM_CLOSED": "07-01-2013 ",
		 "ACTUAL_RTW_DATE": "06-17-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Anderson",
		 "CLAIMANT_FIRST_NAME": "Joel",
		 "CLAIM_NO": "2014-04-17-0068-VP-01",
		 "DURATION": 164,
		 "DISABILITY_START_DATE": "05-21-2014 ",
		 "DATE_CLAIM_CLOSED": "11-12-2014 ",
		 "ACTUAL_RTW_DATE": "11-01-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Anderson",
		 "CLAIMANT_FIRST_NAME": "Margaret",
		 "CLAIM_NO": "2017-03-22-0089-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "03-20-2017 ",
		 "DATE_CLAIM_CLOSED": "05-03-2017 ",
		 "ACTUAL_RTW_DATE": "04-24-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Anderson",
		 "CLAIMANT_FIRST_NAME": "Joel",
		 "CLAIM_NO": "2013-01-23-0395-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "03-05-2013 ",
		 "DATE_CLAIM_CLOSED": "07-11-2013 ",
		 "ACTUAL_RTW_DATE": "04-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Anderson-Bolden",
		 "CLAIMANT_FIRST_NAME": "Erika",
		 "CLAIM_NO": "2013-04-12-0135-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "05-13-2013 ",
		 "DATE_CLAIM_CLOSED": "07-15-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Anderson-Mckee",
		 "CLAIMANT_FIRST_NAME": "Jala",
		 "CLAIM_NO": "2013-12-09-0391-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "12-04-2013 ",
		 "DATE_CLAIM_CLOSED": "02-11-2014 ",
		 "ACTUAL_RTW_DATE": "12-16-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Andrada",
		 "CLAIMANT_FIRST_NAME": "Perla",
		 "CLAIM_NO": "2021-06-21-0912-VP-01",
		 "DURATION": 311,
		 "DISABILITY_START_DATE": "06-24-2021 ",
		 "DATE_CLAIM_CLOSED": "04-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Andrade",
		 "CLAIMANT_FIRST_NAME": "Noemielind",
		 "CLAIM_NO": "2020-02-07-0363-VP-01",
		 "DURATION": 120,
		 "DISABILITY_START_DATE": "01-27-2020 ",
		 "DATE_CLAIM_CLOSED": "05-27-2020 ",
		 "ACTUAL_RTW_DATE": "05-26-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Andrade",
		 "CLAIMANT_FIRST_NAME": "Pamela",
		 "CLAIM_NO": "2020-07-30-0578-VP-01",
		 "DURATION": 76,
		 "DISABILITY_START_DATE": "08-04-2020 ",
		 "DATE_CLAIM_CLOSED": "10-19-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Andrade",
		 "CLAIMANT_FIRST_NAME": "Rosa",
		 "CLAIM_NO": "2013-02-27-0422-VP-01",
		 "DURATION": 119,
		 "DISABILITY_START_DATE": "02-26-2013 ",
		 "DATE_CLAIM_CLOSED": "11-19-2013 ",
		 "ACTUAL_RTW_DATE": "06-25-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Andrade",
		 "CLAIMANT_FIRST_NAME": "Rosa",
		 "CLAIM_NO": "2014-06-03-0359-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "05-30-2014 ",
		 "DATE_CLAIM_CLOSED": "08-08-2014 ",
		 "ACTUAL_RTW_DATE": "06-16-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Andrade",
		 "CLAIMANT_FIRST_NAME": "Rosa",
		 "CLAIM_NO": "2015-06-05-0253-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "06-04-2015 ",
		 "DATE_CLAIM_CLOSED": "08-03-2015 ",
		 "ACTUAL_RTW_DATE": "07-06-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Andrade",
		 "CLAIMANT_FIRST_NAME": "Rosa",
		 "CLAIM_NO": "2013-09-12-0045-VP-01",
		 "DURATION": 201,
		 "DISABILITY_START_DATE": "09-12-2013 ",
		 "DATE_CLAIM_CLOSED": "04-14-2014 ",
		 "ACTUAL_RTW_DATE": "04-01-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Andrade",
		 "CLAIMANT_FIRST_NAME": "Frank",
		 "CLAIM_NO": "2017-04-13-0181-VP-01",
		 "DURATION": 173,
		 "DISABILITY_START_DATE": "04-19-2017 ",
		 "DATE_CLAIM_CLOSED": "10-10-2017 ",
		 "ACTUAL_RTW_DATE": "10-09-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Andrade",
		 "CLAIMANT_FIRST_NAME": "Frank",
		 "CLAIM_NO": "2019-08-26-0155-VP-01",
		 "DURATION": 232,
		 "DISABILITY_START_DATE": "09-03-2019 ",
		 "DATE_CLAIM_CLOSED": "05-08-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Andrade",
		 "CLAIMANT_FIRST_NAME": "Frank",
		 "CLAIM_NO": "2019-10-17-0328-VP-01",
		 "DURATION": 94,
		 "DISABILITY_START_DATE": "10-25-2019 ",
		 "DATE_CLAIM_CLOSED": "10-23-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Andrade",
		 "CLAIMANT_FIRST_NAME": "Angelica",
		 "CLAIM_NO": "2015-07-17-0213-VP-01",
		 "DURATION": 124,
		 "DISABILITY_START_DATE": "07-15-2015 ",
		 "DATE_CLAIM_CLOSED": "11-18-2015 ",
		 "ACTUAL_RTW_DATE": "11-16-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Andrade",
		 "CLAIMANT_FIRST_NAME": "Pamela",
		 "CLAIM_NO": "2022-03-11-0605-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "03-07-2022 ",
		 "DATE_CLAIM_CLOSED": "03-21-2022 ",
		 "ACTUAL_RTW_DATE": "03-18-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Angelini",
		 "CLAIMANT_FIRST_NAME": "Karen",
		 "CLAIM_NO": "2021-06-15-0573-VP-01",
		 "DURATION": 260,
		 "DISABILITY_START_DATE": "06-10-2021 ",
		 "DATE_CLAIM_CLOSED": "03-04-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Angus",
		 "CLAIMANT_FIRST_NAME": "Olivia",
		 "CLAIM_NO": "2018-05-08-0517-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "06-01-2018 ",
		 "DATE_CLAIM_CLOSED": "07-13-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Anid",
		 "CLAIMANT_FIRST_NAME": "Dorian",
		 "CLAIM_NO": "2021-06-01-0820-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "06-25-2021 ",
		 "DATE_CLAIM_CLOSED": "07-30-2021 ",
		 "ACTUAL_RTW_DATE": "07-26-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Antolak",
		 "CLAIMANT_FIRST_NAME": "Alyssa",
		 "CLAIM_NO": "2014-06-23-0401-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "07-28-2014 ",
		 "DATE_CLAIM_CLOSED": "01-12-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Antonio",
		 "CLAIMANT_FIRST_NAME": "Rina Rose",
		 "CLAIM_NO": "2021-07-12-0710-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "07-12-2021 ",
		 "DATE_CLAIM_CLOSED": "11-15-2021 ",
		 "ACTUAL_RTW_DATE": "07-26-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aochi",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2021-05-10-0583-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "05-04-2021 ",
		 "DATE_CLAIM_CLOSED": "09-07-2021 ",
		 "ACTUAL_RTW_DATE": "06-28-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aparicio",
		 "CLAIMANT_FIRST_NAME": "Patricia",
		 "CLAIM_NO": "2022-10-04-0717-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "09-22-2022 ",
		 "DATE_CLAIM_CLOSED": "10-20-2022 ",
		 "ACTUAL_RTW_DATE": "10-03-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Appel",
		 "CLAIMANT_FIRST_NAME": "Blanca",
		 "CLAIM_NO": "2013-11-13-0502-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "12-04-2013 ",
		 "DATE_CLAIM_CLOSED": "03-11-2014 ",
		 "ACTUAL_RTW_DATE": "01-06-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Applewhite",
		 "CLAIMANT_FIRST_NAME": "Leah",
		 "CLAIM_NO": "2015-12-16-0178-VP-01",
		 "DURATION": 94,
		 "DISABILITY_START_DATE": "12-09-2015 ",
		 "DATE_CLAIM_CLOSED": "06-03-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aquino",
		 "CLAIMANT_FIRST_NAME": "Priscilla",
		 "CLAIM_NO": "2016-09-19-0174-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "09-21-2016 ",
		 "DATE_CLAIM_CLOSED": "12-12-2016 ",
		 "ACTUAL_RTW_DATE": "11-28-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aquino",
		 "CLAIMANT_FIRST_NAME": "Priscilla",
		 "CLAIM_NO": "2021-08-16-1076-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "09-08-2021 ",
		 "DATE_CLAIM_CLOSED": "11-01-2021 ",
		 "ACTUAL_RTW_DATE": "10-26-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aquino",
		 "CLAIMANT_FIRST_NAME": "Priscilla",
		 "CLAIM_NO": "2019-11-01-0388-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "10-31-2019 ",
		 "DATE_CLAIM_CLOSED": "12-03-2019 ",
		 "ACTUAL_RTW_DATE": "11-11-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aradanas",
		 "CLAIMANT_FIRST_NAME": "Jocelyn P.",
		 "CLAIM_NO": "2022-10-20-0572-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "10-14-2022 ",
		 "DATE_CLAIM_CLOSED": "11-09-2022 ",
		 "ACTUAL_RTW_DATE": "10-25-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aradanas",
		 "CLAIMANT_FIRST_NAME": "Jocelyn P.",
		 "CLAIM_NO": "2020-06-17-0639-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "06-22-2020 ",
		 "DATE_CLAIM_CLOSED": "07-14-2020 ",
		 "ACTUAL_RTW_DATE": "07-13-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arafa",
		 "CLAIMANT_FIRST_NAME": "Anne",
		 "CLAIM_NO": "2019-07-08-0643-VP-01",
		 "DURATION": 67,
		 "DISABILITY_START_DATE": "08-12-2019 ",
		 "DATE_CLAIM_CLOSED": "10-28-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arana",
		 "CLAIMANT_FIRST_NAME": "Maricela",
		 "CLAIM_NO": "2018-03-08-0211-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "03-07-2018 ",
		 "DATE_CLAIM_CLOSED": "05-31-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arana",
		 "CLAIMANT_FIRST_NAME": "Maricela",
		 "CLAIM_NO": "2022-05-20-0013-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "05-16-2022 ",
		 "DATE_CLAIM_CLOSED": "05-27-2022 ",
		 "ACTUAL_RTW_DATE": "05-24-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arances",
		 "CLAIMANT_FIRST_NAME": "April",
		 "CLAIM_NO": "2018-01-30-0722-VP-01",
		 "DURATION": 158,
		 "DISABILITY_START_DATE": "01-30-2018 ",
		 "DATE_CLAIM_CLOSED": "07-09-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arances",
		 "CLAIMANT_FIRST_NAME": "Russel Ann",
		 "CLAIM_NO": "2021-09-10-0016-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "09-30-2021 ",
		 "DATE_CLAIM_CLOSED": "11-17-2021 ",
		 "ACTUAL_RTW_DATE": "11-15-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ararsa",
		 "CLAIMANT_FIRST_NAME": "Christina",
		 "CLAIM_NO": "2021-01-04-0683-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "02-15-2021 ",
		 "DATE_CLAIM_CLOSED": "04-04-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Araujo",
		 "CLAIMANT_FIRST_NAME": "Beatriz",
		 "CLAIM_NO": "2018-12-19-0414-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "01-14-2019 ",
		 "DATE_CLAIM_CLOSED": "02-17-2019 ",
		 "ACTUAL_RTW_DATE": "02-12-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Araujo",
		 "CLAIMANT_FIRST_NAME": "Janet",
		 "CLAIM_NO": "2018-06-18-0447-VP-01",
		 "DURATION": 119,
		 "DISABILITY_START_DATE": "06-18-2018 ",
		 "DATE_CLAIM_CLOSED": "10-16-2018 ",
		 "ACTUAL_RTW_DATE": "10-15-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aravinda",
		 "CLAIMANT_FIRST_NAME": "Sireesha",
		 "CLAIM_NO": "2019-05-01-0682-VP-01",
		 "DURATION": 65,
		 "DISABILITY_START_DATE": "05-20-2019 ",
		 "DATE_CLAIM_CLOSED": "07-25-2019 ",
		 "ACTUAL_RTW_DATE": "07-24-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arbelaez",
		 "CLAIMANT_FIRST_NAME": "Luz",
		 "CLAIM_NO": "2016-01-19-0527-VP-01",
		 "DURATION": 50,
		 "DISABILITY_START_DATE": "02-09-2016 ",
		 "DATE_CLAIM_CLOSED": "04-06-2016 ",
		 "ACTUAL_RTW_DATE": "03-30-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arbogast",
		 "CLAIMANT_FIRST_NAME": "Jana",
		 "CLAIM_NO": "2021-06-17-0917-VP-01",
		 "DURATION": 83,
		 "DISABILITY_START_DATE": "06-29-2021 ",
		 "DATE_CLAIM_CLOSED": "09-27-2021 ",
		 "ACTUAL_RTW_DATE": "09-20-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arbogast",
		 "CLAIMANT_FIRST_NAME": "Jana",
		 "CLAIM_NO": "2020-12-02-1041-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "11-30-2020 ",
		 "DATE_CLAIM_CLOSED": "02-09-2021 ",
		 "ACTUAL_RTW_DATE": "02-08-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arciosa",
		 "CLAIMANT_FIRST_NAME": "Crystal",
		 "CLAIM_NO": "2022-05-04-0689-VP-01",
		 "DURATION": 52,
		 "DISABILITY_START_DATE": "05-09-2022 ",
		 "DATE_CLAIM_CLOSED": "07-06-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arciosa",
		 "CLAIMANT_FIRST_NAME": "Crystal",
		 "CLAIM_NO": "2022-01-27-0411-VP-01",
		 "DURATION": 105,
		 "DISABILITY_START_DATE": "01-10-2022 ",
		 "DATE_CLAIM_CLOSED": "05-02-2022 ",
		 "ACTUAL_RTW_DATE": "04-25-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arellano",
		 "CLAIMANT_FIRST_NAME": "Alexandro",
		 "CLAIM_NO": "2020-02-10-0874-VP-01",
		 "DURATION": 6,
		 "DISABILITY_START_DATE": "02-18-2020 ",
		 "DATE_CLAIM_CLOSED": "03-04-2020 ",
		 "ACTUAL_RTW_DATE": "02-24-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arellano",
		 "CLAIMANT_FIRST_NAME": "Patricia",
		 "CLAIM_NO": "2022-04-06-0999-VP-01",
		 "DURATION": 114,
		 "DISABILITY_START_DATE": "04-07-2022 ",
		 "DATE_CLAIM_CLOSED": "08-17-2022 ",
		 "ACTUAL_RTW_DATE": "07-30-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arellano",
		 "CLAIMANT_FIRST_NAME": "Patricia",
		 "CLAIM_NO": "2019-12-09-0700-VP-01",
		 "DURATION": 88,
		 "DISABILITY_START_DATE": "12-05-2019 ",
		 "DATE_CLAIM_CLOSED": "03-03-2020 ",
		 "ACTUAL_RTW_DATE": "03-02-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arellano",
		 "CLAIMANT_FIRST_NAME": "Patricia",
		 "CLAIM_NO": "2018-10-31-0611-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "10-31-2018 ",
		 "DATE_CLAIM_CLOSED": "01-09-2019 ",
		 "ACTUAL_RTW_DATE": "01-03-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arellano",
		 "CLAIMANT_FIRST_NAME": "Patricia",
		 "CLAIM_NO": "2020-09-22-0028-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "09-22-2020 ",
		 "DATE_CLAIM_CLOSED": "11-25-2020 ",
		 "ACTUAL_RTW_DATE": "11-09-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arguello",
		 "CLAIMANT_FIRST_NAME": "Christopher",
		 "CLAIM_NO": "2016-07-28-0394-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "07-19-2016 ",
		 "DATE_CLAIM_CLOSED": "09-20-2016 ",
		 "ACTUAL_RTW_DATE": "09-12-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Argueta",
		 "CLAIMANT_FIRST_NAME": "Celia",
		 "CLAIM_NO": "2013-06-06-0378-VP-01",
		 "DURATION": 166,
		 "DISABILITY_START_DATE": "06-06-2013 ",
		 "DATE_CLAIM_CLOSED": "12-05-2013 ",
		 "ACTUAL_RTW_DATE": "11-19-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arias",
		 "CLAIMANT_FIRST_NAME": "Alondra",
		 "CLAIM_NO": "2019-12-20-0109-VP-01",
		 "DURATION": 65,
		 "DISABILITY_START_DATE": "01-02-2020 ",
		 "DATE_CLAIM_CLOSED": "03-09-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arias",
		 "CLAIMANT_FIRST_NAME": "Marlene",
		 "CLAIM_NO": "2015-10-02-0276-VP-01",
		 "DURATION": 88,
		 "DISABILITY_START_DATE": "09-28-2015 ",
		 "DATE_CLAIM_CLOSED": "01-22-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arias",
		 "CLAIMANT_FIRST_NAME": "Marlene",
		 "CLAIM_NO": "2014-04-14-0490-VP-01",
		 "DURATION": 80,
		 "DISABILITY_START_DATE": "04-14-2014 ",
		 "DATE_CLAIM_CLOSED": "07-08-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arias",
		 "CLAIMANT_FIRST_NAME": "Marlene",
		 "CLAIM_NO": "2013-09-23-0396-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "09-16-2013 ",
		 "DATE_CLAIM_CLOSED": "03-28-2014 ",
		 "ACTUAL_RTW_DATE": "11-14-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arias",
		 "CLAIMANT_FIRST_NAME": "Marlene",
		 "CLAIM_NO": "2020-08-19-0372-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "08-10-2020 ",
		 "DATE_CLAIM_CLOSED": "09-03-2020 ",
		 "ACTUAL_RTW_DATE": "08-24-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arias",
		 "CLAIMANT_FIRST_NAME": "Marlene",
		 "CLAIM_NO": "2017-02-06-0395-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "01-30-2017 ",
		 "DATE_CLAIM_CLOSED": "03-24-2017 ",
		 "ACTUAL_RTW_DATE": "02-13-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arias",
		 "CLAIMANT_FIRST_NAME": "Marlene",
		 "CLAIM_NO": "2018-10-24-0608-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "10-24-2018 ",
		 "DATE_CLAIM_CLOSED": "12-05-2018 ",
		 "ACTUAL_RTW_DATE": "12-03-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arias",
		 "CLAIMANT_FIRST_NAME": "Alondra",
		 "CLAIM_NO": "2018-05-18-0649-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "05-29-2018 ",
		 "DATE_CLAIM_CLOSED": "08-06-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arias",
		 "CLAIMANT_FIRST_NAME": "Marlene",
		 "CLAIM_NO": "2022-02-07-0873-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "01-29-2022 ",
		 "DATE_CLAIM_CLOSED": "03-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aripez",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2022-04-21-0634-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "04-22-2022 ",
		 "DATE_CLAIM_CLOSED": "09-19-2022 ",
		 "ACTUAL_RTW_DATE": "06-06-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aripez",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2015-05-04-0572-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "05-20-2015 ",
		 "DATE_CLAIM_CLOSED": "07-14-2015 ",
		 "ACTUAL_RTW_DATE": "07-01-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aripez",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2015-02-09-0641-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "02-09-2015 ",
		 "DATE_CLAIM_CLOSED": "02-23-2015 ",
		 "ACTUAL_RTW_DATE": "02-16-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aripez",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2022-09-26-0989-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "09-26-2022 ",
		 "DATE_CLAIM_CLOSED": "10-18-2022 ",
		 "ACTUAL_RTW_DATE": "10-06-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aripez",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2020-08-31-0595-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "08-31-2020 ",
		 "DATE_CLAIM_CLOSED": "09-11-2020 ",
		 "ACTUAL_RTW_DATE": "09-08-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aripez",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2023-01-03-1246-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "01-03-2023 ",
		 "DATE_CLAIM_CLOSED": "02-15-2023 ",
		 "ACTUAL_RTW_DATE": "02-13-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aripez",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2019-04-05-0011-VP-01",
		 "DURATION": 101,
		 "DISABILITY_START_DATE": "04-05-2019 ",
		 "DATE_CLAIM_CLOSED": "07-18-2019 ",
		 "ACTUAL_RTW_DATE": "07-15-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ariza",
		 "CLAIMANT_FIRST_NAME": "Richard",
		 "CLAIM_NO": "2022-08-23-0496-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "08-22-2022 ",
		 "DATE_CLAIM_CLOSED": "10-19-2022 ",
		 "ACTUAL_RTW_DATE": "09-15-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ariza",
		 "CLAIMANT_FIRST_NAME": "Richard",
		 "CLAIM_NO": "2022-11-30-0986-VP-01",
		 "DURATION": 103,
		 "DISABILITY_START_DATE": "11-30-2022 ",
		 "DATE_CLAIM_CLOSED": "03-17-2023 ",
		 "ACTUAL_RTW_DATE": "03-13-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arlia",
		 "CLAIMANT_FIRST_NAME": "Mario",
		 "CLAIM_NO": "2017-07-11-0682-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "07-07-2017 ",
		 "DATE_CLAIM_CLOSED": "07-25-2017 ",
		 "ACTUAL_RTW_DATE": "07-24-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Armenta",
		 "CLAIMANT_FIRST_NAME": "Tracie",
		 "CLAIM_NO": "2015-05-29-0241-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "05-29-2015 ",
		 "DATE_CLAIM_CLOSED": "09-04-2015 ",
		 "ACTUAL_RTW_DATE": "08-01-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Armenta",
		 "CLAIMANT_FIRST_NAME": "Tracie",
		 "CLAIM_NO": "2015-03-18-0497-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "03-05-2015 ",
		 "DATE_CLAIM_CLOSED": "07-13-2015 ",
		 "ACTUAL_RTW_DATE": "04-21-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Armstrong",
		 "CLAIMANT_FIRST_NAME": "Linda",
		 "CLAIM_NO": "2019-10-24-0426-VP-01",
		 "DURATION": 119,
		 "DISABILITY_START_DATE": "11-18-2019 ",
		 "DATE_CLAIM_CLOSED": "02-28-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Armstrong",
		 "CLAIMANT_FIRST_NAME": "Linda",
		 "CLAIM_NO": "2018-03-12-0728-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "04-09-2018 ",
		 "DATE_CLAIM_CLOSED": "06-13-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aromin",
		 "CLAIMANT_FIRST_NAME": "Roselle",
		 "CLAIM_NO": "2015-02-06-0226-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "02-23-2015 ",
		 "DATE_CLAIM_CLOSED": "04-24-2015 ",
		 "ACTUAL_RTW_DATE": "04-13-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aromin",
		 "CLAIMANT_FIRST_NAME": "Roselle",
		 "CLAIM_NO": "2017-09-20-0291-VP-01",
		 "DURATION": 44,
		 "DISABILITY_START_DATE": "10-04-2017 ",
		 "DATE_CLAIM_CLOSED": "11-27-2017 ",
		 "ACTUAL_RTW_DATE": "11-17-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aronov",
		 "CLAIMANT_FIRST_NAME": "Anna",
		 "CLAIM_NO": "2019-02-28-0652-VP-01",
		 "DURATION": 188,
		 "DISABILITY_START_DATE": "03-19-2019 ",
		 "DATE_CLAIM_CLOSED": "09-25-2019 ",
		 "ACTUAL_RTW_DATE": "09-23-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arora",
		 "CLAIMANT_FIRST_NAME": "Parul",
		 "CLAIM_NO": "2016-02-05-0474-VP-01",
		 "DURATION": 51,
		 "DISABILITY_START_DATE": "02-29-2016 ",
		 "DATE_CLAIM_CLOSED": "06-13-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arora",
		 "CLAIMANT_FIRST_NAME": "Deepika",
		 "CLAIM_NO": "2019-04-03-0533-VP-01",
		 "DURATION": 75,
		 "DISABILITY_START_DATE": "05-03-2019 ",
		 "DATE_CLAIM_CLOSED": "08-06-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arora",
		 "CLAIMANT_FIRST_NAME": "Swati",
		 "CLAIM_NO": "2022-05-09-0963-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "05-10-2022 ",
		 "DATE_CLAIM_CLOSED": "06-23-2022 ",
		 "ACTUAL_RTW_DATE": "05-30-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arreola",
		 "CLAIMANT_FIRST_NAME": "Marlene",
		 "CLAIM_NO": "2014-01-31-0099-VP-01",
		 "DURATION": 102,
		 "DISABILITY_START_DATE": "01-24-2014 ",
		 "DATE_CLAIM_CLOSED": "05-20-2014 ",
		 "ACTUAL_RTW_DATE": "05-06-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arriaga",
		 "CLAIMANT_FIRST_NAME": "Tammy",
		 "CLAIM_NO": "2014-07-21-0482-VP-01",
		 "DURATION": 30,
		 "DISABILITY_START_DATE": "07-08-2014 ",
		 "DATE_CLAIM_CLOSED": "12-09-2014 ",
		 "ACTUAL_RTW_DATE": "08-07-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arrieta",
		 "CLAIMANT_FIRST_NAME": "Eulieta",
		 "CLAIM_NO": "2023-01-07-0013-VP-01",
		 "DURATION": 93,
		 "DISABILITY_START_DATE": "01-18-2023 ",
		 "DATE_CLAIM_CLOSED": "04-25-2023 ",
		 "ACTUAL_RTW_DATE": "04-21-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arroyo",
		 "CLAIMANT_FIRST_NAME": "Axel",
		 "CLAIM_NO": "2013-07-01-0490-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "07-12-2013 ",
		 "DATE_CLAIM_CLOSED": "09-30-2013 ",
		 "ACTUAL_RTW_DATE": "07-26-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arroyo",
		 "CLAIMANT_FIRST_NAME": "Sheila",
		 "CLAIM_NO": "2013-10-18-0378-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "11-22-2013 ",
		 "DATE_CLAIM_CLOSED": "05-30-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arroyo",
		 "CLAIMANT_FIRST_NAME": "Gabriela",
		 "CLAIM_NO": "2019-09-10-0093-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "09-04-2019 ",
		 "DATE_CLAIM_CLOSED": "10-08-2019 ",
		 "ACTUAL_RTW_DATE": "09-23-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arroyo",
		 "CLAIMANT_FIRST_NAME": "Gabriela",
		 "CLAIM_NO": "2018-09-12-0255-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "09-10-2018 ",
		 "DATE_CLAIM_CLOSED": "10-01-2018 ",
		 "ACTUAL_RTW_DATE": "09-18-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arroyo",
		 "CLAIMANT_FIRST_NAME": "Sheila",
		 "CLAIM_NO": "2015-06-08-0521-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "06-08-2015 ",
		 "DATE_CLAIM_CLOSED": "07-25-2015 ",
		 "ACTUAL_RTW_DATE": "06-23-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arroyo",
		 "CLAIMANT_FIRST_NAME": "Gabriela",
		 "CLAIM_NO": "2019-01-04-0346-VP-01",
		 "DURATION": 5,
		 "DISABILITY_START_DATE": "01-03-2019 ",
		 "DATE_CLAIM_CLOSED": "01-22-2019 ",
		 "ACTUAL_RTW_DATE": "01-08-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arroyo",
		 "CLAIMANT_FIRST_NAME": "Gabriela",
		 "CLAIM_NO": "2019-05-07-0521-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "05-03-2019 ",
		 "DATE_CLAIM_CLOSED": "06-06-2019 ",
		 "ACTUAL_RTW_DATE": "05-25-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arteaga",
		 "CLAIMANT_FIRST_NAME": "Judith",
		 "CLAIM_NO": "2013-10-18-0045-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "10-28-2013 ",
		 "DATE_CLAIM_CLOSED": "05-30-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arteaga",
		 "CLAIMANT_FIRST_NAME": "Jose",
		 "CLAIM_NO": "2022-05-31-0724-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "05-18-2022 ",
		 "DATE_CLAIM_CLOSED": "09-20-2022 ",
		 "ACTUAL_RTW_DATE": "06-07-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arteaga",
		 "CLAIMANT_FIRST_NAME": "Judith",
		 "CLAIM_NO": "2016-02-12-0260-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "02-08-2016 ",
		 "DATE_CLAIM_CLOSED": "03-23-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arteaga",
		 "CLAIMANT_FIRST_NAME": "Jose",
		 "CLAIM_NO": "2014-03-07-0053-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "02-27-2014 ",
		 "DATE_CLAIM_CLOSED": "05-01-2014 ",
		 "ACTUAL_RTW_DATE": "04-03-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Arutti",
		 "CLAIMANT_FIRST_NAME": "Ludmila",
		 "CLAIM_NO": "2018-10-16-0702-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "10-17-2018 ",
		 "DATE_CLAIM_CLOSED": "12-11-2018 ",
		 "ACTUAL_RTW_DATE": "11-27-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Asakawa",
		 "CLAIMANT_FIRST_NAME": "Kaori",
		 "CLAIM_NO": "2013-01-05-1805-VP-01",
		 "DURATION": 50,
		 "DISABILITY_START_DATE": "07-09-2012 ",
		 "DATE_CLAIM_CLOSED": "01-15-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Asgari",
		 "CLAIMANT_FIRST_NAME": "Shoorideh",
		 "CLAIM_NO": "2022-07-28-0223-VP-01",
		 "DURATION": 105,
		 "DISABILITY_START_DATE": "08-15-2022 ",
		 "DATE_CLAIM_CLOSED": "10-25-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ashley",
		 "CLAIMANT_FIRST_NAME": "Juliana",
		 "CLAIM_NO": "2020-08-03-0019-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "07-30-2020 ",
		 "DATE_CLAIM_CLOSED": "08-19-2020 ",
		 "ACTUAL_RTW_DATE": "08-17-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ashwin",
		 "CLAIMANT_FIRST_NAME": "Varsha",
		 "CLAIM_NO": "2022-04-26-0701-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "04-04-2022 ",
		 "DATE_CLAIM_CLOSED": "05-20-2022 ",
		 "ACTUAL_RTW_DATE": "04-18-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Assardo",
		 "CLAIMANT_FIRST_NAME": "Victoria",
		 "CLAIM_NO": "2014-01-22-0181-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "02-10-2014 ",
		 "DATE_CLAIM_CLOSED": "12-05-2014 ",
		 "ACTUAL_RTW_DATE": "04-07-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Asuncion",
		 "CLAIMANT_FIRST_NAME": "Sherilyn",
		 "CLAIM_NO": "2019-09-25-0689-VP-01",
		 "DURATION": 67,
		 "DISABILITY_START_DATE": "10-21-2019 ",
		 "DATE_CLAIM_CLOSED": "02-25-2020 ",
		 "ACTUAL_RTW_DATE": "12-27-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Asuncion",
		 "CLAIMANT_FIRST_NAME": "Sherilyn",
		 "CLAIM_NO": "2017-05-09-0007-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "05-12-2017 ",
		 "DATE_CLAIM_CLOSED": "06-26-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Asuncion",
		 "CLAIMANT_FIRST_NAME": "Sherilyn",
		 "CLAIM_NO": "2021-10-01-0821-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "11-01-2021 ",
		 "DATE_CLAIM_CLOSED": "12-27-2021 ",
		 "ACTUAL_RTW_DATE": "12-27-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Atkin",
		 "CLAIMANT_FIRST_NAME": "Gail",
		 "CLAIM_NO": "2018-09-25-0114-VP-01",
		 "DURATION": 132,
		 "DISABILITY_START_DATE": "09-25-2018 ",
		 "DATE_CLAIM_CLOSED": "02-27-2019 ",
		 "ACTUAL_RTW_DATE": "02-04-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Atkin",
		 "CLAIMANT_FIRST_NAME": "Gail",
		 "CLAIM_NO": "2018-06-05-0337-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "05-29-2018 ",
		 "DATE_CLAIM_CLOSED": "06-27-2018 ",
		 "ACTUAL_RTW_DATE": "06-25-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Atlas",
		 "CLAIMANT_FIRST_NAME": "Sarah",
		 "CLAIM_NO": "2021-03-06-0047-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "04-09-2021 ",
		 "DATE_CLAIM_CLOSED": "05-24-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aulakh",
		 "CLAIMANT_FIRST_NAME": "Kanwaljit",
		 "CLAIM_NO": "2019-10-09-0534-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "10-21-2019 ",
		 "DATE_CLAIM_CLOSED": "12-10-2019 ",
		 "ACTUAL_RTW_DATE": "12-02-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aulakh",
		 "CLAIMANT_FIRST_NAME": "Kanwaljit",
		 "CLAIM_NO": "2019-10-17-0426-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "10-21-2019 ",
		 "DATE_CLAIM_CLOSED": "10-23-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aulakh",
		 "CLAIMANT_FIRST_NAME": "Kanwaljit",
		 "CLAIM_NO": "2015-12-27-0023-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "12-07-2015 ",
		 "DATE_CLAIM_CLOSED": "01-21-2016 ",
		 "ACTUAL_RTW_DATE": "01-04-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aune",
		 "CLAIMANT_FIRST_NAME": "Ronald",
		 "CLAIM_NO": "2013-12-21-0055-VP-01",
		 "DURATION": 365,
		 "DISABILITY_START_DATE": "12-19-2013 ",
		 "DATE_CLAIM_CLOSED": "01-21-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aune",
		 "CLAIMANT_FIRST_NAME": "Ronald",
		 "CLAIM_NO": "2013-02-21-0303-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "02-12-2013 ",
		 "DATE_CLAIM_CLOSED": "08-05-2013 ",
		 "ACTUAL_RTW_DATE": "02-21-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aungst",
		 "CLAIMANT_FIRST_NAME": "Richard",
		 "CLAIM_NO": "2022-03-31-0327-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "04-15-2022 ",
		 "DATE_CLAIM_CLOSED": "06-24-2022 ",
		 "ACTUAL_RTW_DATE": "05-31-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ausherman",
		 "CLAIMANT_FIRST_NAME": "Shawn",
		 "CLAIM_NO": "2015-12-28-0409-VP-01",
		 "DURATION": 292,
		 "DISABILITY_START_DATE": "12-11-2015 ",
		 "DATE_CLAIM_CLOSED": "10-03-2016 ",
		 "ACTUAL_RTW_DATE": "09-28-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Austin",
		 "CLAIMANT_FIRST_NAME": "Jess",
		 "CLAIM_NO": "2018-08-08-0174-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "08-14-2018 ",
		 "DATE_CLAIM_CLOSED": "09-12-2018 ",
		 "ACTUAL_RTW_DATE": "08-22-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Avalos",
		 "CLAIMANT_FIRST_NAME": "Rodolfo",
		 "CLAIM_NO": "2019-10-17-0618-VP-01",
		 "DURATION": 171,
		 "DISABILITY_START_DATE": "10-13-2019 ",
		 "DATE_CLAIM_CLOSED": "04-01-2020 ",
		 "ACTUAL_RTW_DATE": "04-01-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Avalos",
		 "CLAIMANT_FIRST_NAME": "Zulema",
		 "CLAIM_NO": "2019-03-05-0345-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "03-04-2019 ",
		 "DATE_CLAIM_CLOSED": "03-27-2019 ",
		 "ACTUAL_RTW_DATE": "03-19-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Avalos",
		 "CLAIMANT_FIRST_NAME": "Rozio",
		 "CLAIM_NO": "2022-03-08-0265-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "01-11-2022 ",
		 "DATE_CLAIM_CLOSED": "06-21-2022 ",
		 "ACTUAL_RTW_DATE": "02-23-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Avina",
		 "CLAIMANT_FIRST_NAME": "Timmie",
		 "CLAIM_NO": "2019-09-10-0564-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "09-09-2019 ",
		 "DATE_CLAIM_CLOSED": "10-08-2019 ",
		 "ACTUAL_RTW_DATE": "10-01-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Avveduto",
		 "CLAIMANT_FIRST_NAME": "Michael",
		 "CLAIM_NO": "2013-09-30-0286-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "09-24-2013 ",
		 "DATE_CLAIM_CLOSED": "12-12-2013 ",
		 "ACTUAL_RTW_DATE": "10-10-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Awai",
		 "CLAIMANT_FIRST_NAME": "Paige",
		 "CLAIM_NO": "2013-01-05-3786-VP-01",
		 "DURATION": 1352,
		 "DISABILITY_START_DATE": "10-17-2011 ",
		 "DATE_CLAIM_CLOSED": "10-27-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aweke",
		 "CLAIMANT_FIRST_NAME": "Girma",
		 "CLAIM_NO": "2013-04-02-0274-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "03-26-2013 ",
		 "DATE_CLAIM_CLOSED": "08-03-2013 ",
		 "ACTUAL_RTW_DATE": "04-09-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aweke",
		 "CLAIMANT_FIRST_NAME": "Girma",
		 "CLAIM_NO": "2015-11-10-0287-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "11-11-2015 ",
		 "DATE_CLAIM_CLOSED": "12-21-2015 ",
		 "ACTUAL_RTW_DATE": "11-25-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Axelrood",
		 "CLAIMANT_FIRST_NAME": "Ashley",
		 "CLAIM_NO": "2019-05-07-0419-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "04-29-2019 ",
		 "DATE_CLAIM_CLOSED": "05-28-2019 ",
		 "ACTUAL_RTW_DATE": "05-13-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ayala",
		 "CLAIMANT_FIRST_NAME": "Melissa",
		 "CLAIM_NO": "2019-11-04-0724-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "11-04-2019 ",
		 "DATE_CLAIM_CLOSED": "01-03-2020 ",
		 "ACTUAL_RTW_DATE": "12-30-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ayala",
		 "CLAIMANT_FIRST_NAME": "Patricia",
		 "CLAIM_NO": "2018-08-31-0165-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "09-10-2018 ",
		 "DATE_CLAIM_CLOSED": "12-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ayala",
		 "CLAIMANT_FIRST_NAME": "Karla",
		 "CLAIM_NO": "2022-05-02-0350-VP-01",
		 "DURATION": 181,
		 "DISABILITY_START_DATE": "04-27-2022 ",
		 "DATE_CLAIM_CLOSED": "05-02-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Aying",
		 "CLAIMANT_FIRST_NAME": "Vincent",
		 "CLAIM_NO": "2021-07-26-0485-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "08-02-2021 ",
		 "DATE_CLAIM_CLOSED": "09-16-2021 ",
		 "ACTUAL_RTW_DATE": "09-16-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ayson",
		 "CLAIMANT_FIRST_NAME": "Jo Anne",
		 "CLAIM_NO": "2022-07-07-0702-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "06-30-2022 ",
		 "DATE_CLAIM_CLOSED": "09-14-2022 ",
		 "ACTUAL_RTW_DATE": "07-11-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Azamey",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2017-01-13-0065-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "01-03-2017 ",
		 "DATE_CLAIM_CLOSED": "01-30-2017 ",
		 "ACTUAL_RTW_DATE": "01-16-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Azizkhan-Brooks",
		 "CLAIMANT_FIRST_NAME": "Bernadette",
		 "CLAIM_NO": "2022-05-11-0856-VP-01",
		 "DURATION": 119,
		 "DISABILITY_START_DATE": "07-06-2022 ",
		 "DATE_CLAIM_CLOSED": "09-14-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Azizkhan-Brooks",
		 "CLAIMANT_FIRST_NAME": "Bernadette",
		 "CLAIM_NO": "2021-11-15-0769-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "11-22-2021 ",
		 "DATE_CLAIM_CLOSED": "11-28-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Babauta",
		 "CLAIMANT_FIRST_NAME": "Editha",
		 "CLAIM_NO": "2018-11-05-0269-VP-01",
		 "DURATION": 78,
		 "DISABILITY_START_DATE": "10-29-2018 ",
		 "DATE_CLAIM_CLOSED": "02-17-2019 ",
		 "ACTUAL_RTW_DATE": "01-15-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Babauta",
		 "CLAIMANT_FIRST_NAME": "Editha",
		 "CLAIM_NO": "2017-12-21-0476-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "12-18-2017 ",
		 "DATE_CLAIM_CLOSED": "01-08-2018 ",
		 "ACTUAL_RTW_DATE": "01-04-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Babauta",
		 "CLAIMANT_FIRST_NAME": "Joshua",
		 "CLAIM_NO": "2018-09-26-0450-VP-01",
		 "DURATION": 30,
		 "DISABILITY_START_DATE": "09-17-2018 ",
		 "DATE_CLAIM_CLOSED": "10-23-2018 ",
		 "ACTUAL_RTW_DATE": "10-17-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Babauta",
		 "CLAIMANT_FIRST_NAME": "Editha",
		 "CLAIM_NO": "2019-01-31-0703-VP-01",
		 "DURATION": 291,
		 "DISABILITY_START_DATE": "01-17-2019 ",
		 "DATE_CLAIM_CLOSED": "03-27-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Babauta",
		 "CLAIMANT_FIRST_NAME": "Editha",
		 "CLAIM_NO": "2018-03-08-0165-VP-01",
		 "DURATION": 138,
		 "DISABILITY_START_DATE": "02-22-2018 ",
		 "DATE_CLAIM_CLOSED": "07-12-2018 ",
		 "ACTUAL_RTW_DATE": "07-10-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Babauta",
		 "CLAIMANT_FIRST_NAME": "Editha",
		 "CLAIM_NO": "2016-10-10-0064-VP-01",
		 "DURATION": 23,
		 "DISABILITY_START_DATE": "11-15-2016 ",
		 "DATE_CLAIM_CLOSED": "12-27-2016 ",
		 "ACTUAL_RTW_DATE": "12-08-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Babauta",
		 "CLAIMANT_FIRST_NAME": "Editha",
		 "CLAIM_NO": "2017-06-22-0316-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "06-15-2017 ",
		 "DATE_CLAIM_CLOSED": "08-10-2017 ",
		 "ACTUAL_RTW_DATE": "06-28-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Baca",
		 "CLAIMANT_FIRST_NAME": "Jaime",
		 "CLAIM_NO": "2013-01-05-0211-VP-01",
		 "DURATION": 111,
		 "DISABILITY_START_DATE": "11-21-2012 ",
		 "DATE_CLAIM_CLOSED": "07-11-2013 ",
		 "ACTUAL_RTW_DATE": "03-12-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Baca",
		 "CLAIMANT_FIRST_NAME": "Jaime",
		 "CLAIM_NO": "2014-10-15-0461-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "10-13-2014 ",
		 "DATE_CLAIM_CLOSED": "12-15-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Baca",
		 "CLAIMANT_FIRST_NAME": "Jaime",
		 "CLAIM_NO": "2020-08-17-0748-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "08-10-2020 ",
		 "DATE_CLAIM_CLOSED": "09-24-2020 ",
		 "ACTUAL_RTW_DATE": "09-21-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Badall",
		 "CLAIMANT_FIRST_NAME": "Ator",
		 "CLAIM_NO": "2014-07-03-0404-VP-01",
		 "DURATION": 104,
		 "DISABILITY_START_DATE": "06-10-2014 ",
		 "DATE_CLAIM_CLOSED": "10-01-2014 ",
		 "ACTUAL_RTW_DATE": "09-22-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Baddam",
		 "CLAIMANT_FIRST_NAME": "Phalguni",
		 "CLAIM_NO": "2014-09-11-0515-VP-01",
		 "DURATION": 72,
		 "DISABILITY_START_DATE": "09-15-2014 ",
		 "DATE_CLAIM_CLOSED": "12-09-2014 ",
		 "ACTUAL_RTW_DATE": "11-26-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Baddam",
		 "CLAIMANT_FIRST_NAME": "Phalguni",
		 "CLAIM_NO": "2019-12-03-0774-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "12-16-2019 ",
		 "DATE_CLAIM_CLOSED": "02-06-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bade",
		 "CLAIMANT_FIRST_NAME": "Veronica",
		 "CLAIM_NO": "2015-05-18-0558-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "06-01-2015 ",
		 "DATE_CLAIM_CLOSED": "06-30-2015 ",
		 "ACTUAL_RTW_DATE": "06-25-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Badhiwala",
		 "CLAIMANT_FIRST_NAME": "Mansi",
		 "CLAIM_NO": "2019-08-08-0511-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "09-13-2019 ",
		 "DATE_CLAIM_CLOSED": "11-11-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bains",
		 "CLAIMANT_FIRST_NAME": "Ranjit",
		 "CLAIM_NO": "2018-08-10-0389-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "08-14-2018 ",
		 "DATE_CLAIM_CLOSED": "09-12-2018 ",
		 "ACTUAL_RTW_DATE": "08-29-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bains",
		 "CLAIMANT_FIRST_NAME": "Ranjit",
		 "CLAIM_NO": "2018-03-07-0156-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "03-01-2018 ",
		 "DATE_CLAIM_CLOSED": "03-19-2018 ",
		 "ACTUAL_RTW_DATE": "03-15-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Baka",
		 "CLAIMANT_FIRST_NAME": "Karen",
		 "CLAIM_NO": "2013-01-24-0088-VP-01",
		 "DURATION": 160,
		 "DISABILITY_START_DATE": "01-22-2013 ",
		 "DATE_CLAIM_CLOSED": "10-16-2013 ",
		 "ACTUAL_RTW_DATE": "07-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Baldau",
		 "CLAIMANT_FIRST_NAME": "Gustave",
		 "CLAIM_NO": "2017-10-04-0558-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "10-09-2017 ",
		 "DATE_CLAIM_CLOSED": "10-24-2017 ",
		 "ACTUAL_RTW_DATE": "10-23-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Baldau",
		 "CLAIMANT_FIRST_NAME": "Gustave",
		 "CLAIM_NO": "2016-03-11-0146-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "03-28-2016 ",
		 "DATE_CLAIM_CLOSED": "06-16-2016 ",
		 "ACTUAL_RTW_DATE": "05-23-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Baldau",
		 "CLAIMANT_FIRST_NAME": "Gustave",
		 "CLAIM_NO": "2016-08-31-0373-VP-01",
		 "DURATION": 37,
		 "DISABILITY_START_DATE": "08-31-2016 ",
		 "DATE_CLAIM_CLOSED": "10-12-2016 ",
		 "ACTUAL_RTW_DATE": "10-07-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Baldau",
		 "CLAIMANT_FIRST_NAME": "Gustave",
		 "CLAIM_NO": "2022-04-21-0899-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "05-16-2022 ",
		 "DATE_CLAIM_CLOSED": "06-30-2022 ",
		 "ACTUAL_RTW_DATE": "06-27-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Baldau",
		 "CLAIMANT_FIRST_NAME": "Gustave",
		 "CLAIM_NO": "2019-01-15-0782-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "01-15-2019 ",
		 "DATE_CLAIM_CLOSED": "03-27-2019 ",
		 "ACTUAL_RTW_DATE": "03-27-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Baldwin",
		 "CLAIMANT_FIRST_NAME": "Wesley",
		 "CLAIM_NO": "2020-01-07-1170-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "01-19-2020 ",
		 "DATE_CLAIM_CLOSED": "01-13-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Baldwin",
		 "CLAIMANT_FIRST_NAME": "Wesley",
		 "CLAIM_NO": "2014-04-23-0297-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "04-18-2014 ",
		 "DATE_CLAIM_CLOSED": "08-06-2014 ",
		 "ACTUAL_RTW_DATE": "06-17-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bales",
		 "CLAIMANT_FIRST_NAME": "Natalee",
		 "CLAIM_NO": "2020-06-25-0501-VP-01",
		 "DURATION": 80,
		 "DISABILITY_START_DATE": "08-03-2020 ",
		 "DATE_CLAIM_CLOSED": "10-22-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ballesta",
		 "CLAIMANT_FIRST_NAME": "Antonio",
		 "CLAIM_NO": "2019-06-27-0281-VP-01",
		 "DURATION": 98,
		 "DISABILITY_START_DATE": "06-10-2019 ",
		 "DATE_CLAIM_CLOSED": "10-03-2019 ",
		 "ACTUAL_RTW_DATE": "09-16-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Balusu",
		 "CLAIMANT_FIRST_NAME": "Prasad",
		 "CLAIM_NO": "2023-03-06-0636-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "03-13-2023 ",
		 "DATE_CLAIM_CLOSED": "03-20-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Banag",
		 "CLAIMANT_FIRST_NAME": "Mellorin",
		 "CLAIM_NO": "2013-08-15-0069-VP-01",
		 "DURATION": 65,
		 "DISABILITY_START_DATE": "08-29-2013 ",
		 "DATE_CLAIM_CLOSED": "11-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Banana",
		 "CLAIMANT_FIRST_NAME": "Mae Ann",
		 "CLAIM_NO": "2018-09-07-0486-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "09-04-2018 ",
		 "DATE_CLAIM_CLOSED": "09-25-2018 ",
		 "ACTUAL_RTW_DATE": "09-17-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Banister",
		 "CLAIMANT_FIRST_NAME": "Sue",
		 "CLAIM_NO": "2022-04-07-0004-VP-01",
		 "DURATION": 124,
		 "DISABILITY_START_DATE": "03-28-2022 ",
		 "DATE_CLAIM_CLOSED": "09-16-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bannon",
		 "CLAIMANT_FIRST_NAME": "Donna",
		 "CLAIM_NO": "2020-09-21-0560-VP-01",
		 "DURATION": 177,
		 "DISABILITY_START_DATE": "09-21-2020 ",
		 "DATE_CLAIM_CLOSED": "03-18-2021 ",
		 "ACTUAL_RTW_DATE": "03-17-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barajas",
		 "CLAIMANT_FIRST_NAME": "Ana",
		 "CLAIM_NO": "2013-01-05-0404-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "11-19-2012 ",
		 "DATE_CLAIM_CLOSED": "01-20-2013 ",
		 "ACTUAL_RTW_DATE": "01-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barajas",
		 "CLAIMANT_FIRST_NAME": "Santiago",
		 "CLAIM_NO": "2019-06-13-0483-VP-01",
		 "DURATION": 101,
		 "DISABILITY_START_DATE": "05-28-2019 ",
		 "DATE_CLAIM_CLOSED": "12-30-2019 ",
		 "ACTUAL_RTW_DATE": "09-06-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barajas",
		 "CLAIMANT_FIRST_NAME": "Ana",
		 "CLAIM_NO": "2014-12-12-0488-VP-01",
		 "DURATION": 76,
		 "DISABILITY_START_DATE": "12-11-2014 ",
		 "DATE_CLAIM_CLOSED": "03-03-2015 ",
		 "ACTUAL_RTW_DATE": "02-25-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barajas",
		 "CLAIMANT_FIRST_NAME": "Ana",
		 "CLAIM_NO": "2015-05-08-0430-VP-01",
		 "DURATION": 241,
		 "DISABILITY_START_DATE": "05-08-2015 ",
		 "DATE_CLAIM_CLOSED": "01-07-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barajas",
		 "CLAIMANT_FIRST_NAME": "Santiago",
		 "CLAIM_NO": "2018-08-30-0580-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "08-16-2018 ",
		 "DATE_CLAIM_CLOSED": "11-28-2018 ",
		 "ACTUAL_RTW_DATE": "11-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barajas",
		 "CLAIMANT_FIRST_NAME": "Candelaria",
		 "CLAIM_NO": "2016-01-06-0570-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "12-31-2015 ",
		 "DATE_CLAIM_CLOSED": "01-21-2016 ",
		 "ACTUAL_RTW_DATE": "01-12-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barajas",
		 "CLAIMANT_FIRST_NAME": "Santiago",
		 "CLAIM_NO": "2019-09-19-0652-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "09-18-2019 ",
		 "DATE_CLAIM_CLOSED": "09-30-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barajas",
		 "CLAIMANT_FIRST_NAME": "Yolanda",
		 "CLAIM_NO": "2013-01-05-0949-VP-01",
		 "DURATION": 522,
		 "DISABILITY_START_DATE": "08-23-2012 ",
		 "DATE_CLAIM_CLOSED": "06-16-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Baranda",
		 "CLAIMANT_FIRST_NAME": "Cresencio",
		 "CLAIM_NO": "2013-07-23-0012-VP-01",
		 "DURATION": 259,
		 "DISABILITY_START_DATE": "07-16-2013 ",
		 "DATE_CLAIM_CLOSED": "04-30-2014 ",
		 "ACTUAL_RTW_DATE": "04-01-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barascout",
		 "CLAIMANT_FIRST_NAME": "Alex",
		 "CLAIM_NO": "2013-10-07-0510-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "10-28-2013 ",
		 "DATE_CLAIM_CLOSED": "12-09-2013 ",
		 "ACTUAL_RTW_DATE": "11-11-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barat",
		 "CLAIMANT_FIRST_NAME": "Subhashis",
		 "CLAIM_NO": "2021-02-25-0957-VP-01",
		 "DURATION": 650,
		 "DISABILITY_START_DATE": "03-22-2021 ",
		 "DATE_CLAIM_CLOSED": "10-19-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barbarino",
		 "CLAIMANT_FIRST_NAME": "Hilary",
		 "CLAIM_NO": "2021-08-16-1041-VP-01",
		 "DURATION": 72,
		 "DISABILITY_START_DATE": "09-07-2021 ",
		 "DATE_CLAIM_CLOSED": "11-22-2021 ",
		 "ACTUAL_RTW_DATE": "11-18-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barber",
		 "CLAIMANT_FIRST_NAME": "Camille",
		 "CLAIM_NO": "2015-09-11-0405-VP-01",
		 "DURATION": 61,
		 "DISABILITY_START_DATE": "10-01-2015 ",
		 "DATE_CLAIM_CLOSED": "04-06-2016 ",
		 "ACTUAL_RTW_DATE": "12-01-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barber",
		 "CLAIMANT_FIRST_NAME": "Camille",
		 "CLAIM_NO": "2018-06-18-0781-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "06-11-2018 ",
		 "DATE_CLAIM_CLOSED": "07-18-2018 ",
		 "ACTUAL_RTW_DATE": "07-16-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barberi",
		 "CLAIMANT_FIRST_NAME": "Elizabeth",
		 "CLAIM_NO": "2015-03-11-0174-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "03-23-2015 ",
		 "DATE_CLAIM_CLOSED": "07-10-2015 ",
		 "ACTUAL_RTW_DATE": "05-20-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barboza",
		 "CLAIMANT_FIRST_NAME": "Raymond",
		 "CLAIM_NO": "2015-09-24-0195-VP-01",
		 "DURATION": 192,
		 "DISABILITY_START_DATE": "09-22-2015 ",
		 "DATE_CLAIM_CLOSED": "12-10-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bariring",
		 "CLAIMANT_FIRST_NAME": "Wendell",
		 "CLAIM_NO": "2020-06-16-0419-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "06-16-2020 ",
		 "DATE_CLAIM_CLOSED": "07-28-2020 ",
		 "ACTUAL_RTW_DATE": "07-27-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bariring",
		 "CLAIMANT_FIRST_NAME": "Wendell",
		 "CLAIM_NO": "2022-04-29-0665-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "04-29-2022 ",
		 "DATE_CLAIM_CLOSED": "06-06-2022 ",
		 "ACTUAL_RTW_DATE": "05-30-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bariring",
		 "CLAIMANT_FIRST_NAME": "Wendell",
		 "CLAIM_NO": "2022-06-10-0801-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "06-08-2022 ",
		 "DATE_CLAIM_CLOSED": "09-20-2022 ",
		 "ACTUAL_RTW_DATE": "08-05-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bariring",
		 "CLAIMANT_FIRST_NAME": "Wendell",
		 "CLAIM_NO": "2018-12-21-0525-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "03-15-2019 ",
		 "DATE_CLAIM_CLOSED": "03-29-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barnes",
		 "CLAIMANT_FIRST_NAME": "Jael",
		 "CLAIM_NO": "2021-01-16-0010-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "01-18-2021 ",
		 "DATE_CLAIM_CLOSED": "03-05-2021 ",
		 "ACTUAL_RTW_DATE": "03-01-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barnes",
		 "CLAIMANT_FIRST_NAME": "Jael",
		 "CLAIM_NO": "2021-05-27-0720-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "06-07-2021 ",
		 "DATE_CLAIM_CLOSED": "07-23-2021 ",
		 "ACTUAL_RTW_DATE": "07-09-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barnes",
		 "CLAIMANT_FIRST_NAME": "Rene",
		 "CLAIM_NO": "2014-08-05-0275-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "09-03-2014 ",
		 "DATE_CLAIM_CLOSED": "10-01-2014 ",
		 "ACTUAL_RTW_DATE": "09-22-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barnes",
		 "CLAIMANT_FIRST_NAME": "Brian",
		 "CLAIM_NO": "2019-03-08-0374-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "03-25-2019 ",
		 "DATE_CLAIM_CLOSED": "06-04-2019 ",
		 "ACTUAL_RTW_DATE": "06-03-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barnes",
		 "CLAIMANT_FIRST_NAME": "Bonnie",
		 "CLAIM_NO": "2021-08-24-0587-VP-01",
		 "DURATION": 181,
		 "DISABILITY_START_DATE": "08-26-2021 ",
		 "DATE_CLAIM_CLOSED": "05-09-2022 ",
		 "ACTUAL_RTW_DATE": "02-23-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barnett",
		 "CLAIMANT_FIRST_NAME": "Sidney",
		 "CLAIM_NO": "2013-01-05-0614-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "10-11-2012 ",
		 "DATE_CLAIM_CLOSED": "06-03-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barone",
		 "CLAIMANT_FIRST_NAME": "Melinda",
		 "CLAIM_NO": "2013-02-22-0036-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "03-07-2013 ",
		 "DATE_CLAIM_CLOSED": "08-03-2013 ",
		 "ACTUAL_RTW_DATE": "03-18-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barraza",
		 "CLAIMANT_FIRST_NAME": "Cristal",
		 "CLAIM_NO": "2013-07-22-0315-VP-01",
		 "DURATION": 50,
		 "DISABILITY_START_DATE": "07-23-2013 ",
		 "DATE_CLAIM_CLOSED": "10-29-2013 ",
		 "ACTUAL_RTW_DATE": "09-11-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barraza",
		 "CLAIMANT_FIRST_NAME": "Cristal",
		 "CLAIM_NO": "2015-06-17-0333-VP-01",
		 "DURATION": 53,
		 "DISABILITY_START_DATE": "06-25-2015 ",
		 "DATE_CLAIM_CLOSED": "09-10-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barraza",
		 "CLAIMANT_FIRST_NAME": "Cristal",
		 "CLAIM_NO": "2013-01-05-0874-VP-01",
		 "DURATION": 252,
		 "DISABILITY_START_DATE": "09-10-2012 ",
		 "DATE_CLAIM_CLOSED": "07-12-2013 ",
		 "ACTUAL_RTW_DATE": "05-20-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barreto",
		 "CLAIMANT_FIRST_NAME": "Lorelie",
		 "CLAIM_NO": "2013-07-09-0035-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "07-15-2013 ",
		 "DATE_CLAIM_CLOSED": "09-23-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barrientos",
		 "CLAIMANT_FIRST_NAME": "Ma-Paz",
		 "CLAIM_NO": "2021-09-14-0676-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "10-21-2021 ",
		 "DATE_CLAIM_CLOSED": "12-07-2021 ",
		 "ACTUAL_RTW_DATE": "12-02-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barrios Anguizola",
		 "CLAIMANT_FIRST_NAME": "Edilma",
		 "CLAIM_NO": "2019-01-07-0436-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "02-18-2019 ",
		 "DATE_CLAIM_CLOSED": "04-25-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barrios Anguizola",
		 "CLAIMANT_FIRST_NAME": "Edilma",
		 "CLAIM_NO": "2021-04-09-0544-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "05-04-2021 ",
		 "DATE_CLAIM_CLOSED": "07-01-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barrozo",
		 "CLAIMANT_FIRST_NAME": "Catherine",
		 "CLAIM_NO": "2022-12-01-0631-VP-01",
		 "DURATION": 30,
		 "DISABILITY_START_DATE": "11-28-2022 ",
		 "DATE_CLAIM_CLOSED": "12-28-2022 ",
		 "ACTUAL_RTW_DATE": "12-28-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barrozo",
		 "CLAIMANT_FIRST_NAME": "Catherine",
		 "CLAIM_NO": "2021-03-26-0906-VP-01",
		 "DURATION": 107,
		 "DISABILITY_START_DATE": "03-16-2021 ",
		 "DATE_CLAIM_CLOSED": "07-06-2021 ",
		 "ACTUAL_RTW_DATE": "07-01-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barrozo",
		 "CLAIMANT_FIRST_NAME": "Catherine",
		 "CLAIM_NO": "2022-01-20-0780-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "01-21-2022 ",
		 "DATE_CLAIM_CLOSED": "02-03-2022 ",
		 "ACTUAL_RTW_DATE": "02-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Barrozo",
		 "CLAIMANT_FIRST_NAME": "Catherine",
		 "CLAIM_NO": "2014-03-19-0403-VP-01",
		 "DURATION": 83,
		 "DISABILITY_START_DATE": "03-11-2014 ",
		 "DATE_CLAIM_CLOSED": "11-03-2014 ",
		 "ACTUAL_RTW_DATE": "06-02-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bates",
		 "CLAIMANT_FIRST_NAME": "Jeffrey",
		 "CLAIM_NO": "2018-10-05-0638-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "10-09-2018 ",
		 "DATE_CLAIM_CLOSED": "12-11-2018 ",
		 "ACTUAL_RTW_DATE": "11-26-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Batoon",
		 "CLAIMANT_FIRST_NAME": "Anray",
		 "CLAIM_NO": "2021-08-04-0216-VP-01",
		 "DURATION": 96,
		 "DISABILITY_START_DATE": "09-27-2021 ",
		 "DATE_CLAIM_CLOSED": "01-05-2022 ",
		 "ACTUAL_RTW_DATE": "01-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Batoon",
		 "CLAIMANT_FIRST_NAME": "Anray",
		 "CLAIM_NO": "2014-10-08-0484-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "10-27-2014 ",
		 "DATE_CLAIM_CLOSED": "12-10-2014 ",
		 "ACTUAL_RTW_DATE": "12-08-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Batth",
		 "CLAIMANT_FIRST_NAME": "Simran",
		 "CLAIM_NO": "2022-04-15-0194-VP-01",
		 "DURATION": 99,
		 "DISABILITY_START_DATE": "05-12-2022 ",
		 "DATE_CLAIM_CLOSED": "06-30-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Batth",
		 "CLAIMANT_FIRST_NAME": "Simran",
		 "CLAIM_NO": "2021-02-01-0947-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "01-31-2021 ",
		 "DATE_CLAIM_CLOSED": "02-17-2021 ",
		 "ACTUAL_RTW_DATE": "02-15-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Baughman",
		 "CLAIMANT_FIRST_NAME": "William",
		 "CLAIM_NO": "2016-10-13-0474-VP-01",
		 "DURATION": 85,
		 "DISABILITY_START_DATE": "10-07-2016 ",
		 "DATE_CLAIM_CLOSED": "01-04-2017 ",
		 "ACTUAL_RTW_DATE": "12-31-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Baumgartner",
		 "CLAIMANT_FIRST_NAME": "Anna",
		 "CLAIM_NO": "2021-10-05-0544-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "11-10-2021 ",
		 "DATE_CLAIM_CLOSED": "01-06-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bautista",
		 "CLAIMANT_FIRST_NAME": "Alex",
		 "CLAIM_NO": "2021-11-23-0912-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "12-03-2021 ",
		 "DATE_CLAIM_CLOSED": "02-11-2022 ",
		 "ACTUAL_RTW_DATE": "02-11-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bautista",
		 "CLAIMANT_FIRST_NAME": "Wilfredo",
		 "CLAIM_NO": "2013-05-23-0039-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "05-08-2013 ",
		 "DATE_CLAIM_CLOSED": "08-03-2013 ",
		 "ACTUAL_RTW_DATE": "06-10-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bautista Garcia",
		 "CLAIMANT_FIRST_NAME": "Mayra",
		 "CLAIM_NO": "2013-01-05-0385-VP-01",
		 "DURATION": 111,
		 "DISABILITY_START_DATE": "11-14-2012 ",
		 "DATE_CLAIM_CLOSED": "03-10-2013 ",
		 "ACTUAL_RTW_DATE": "03-05-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bawa-Mamudu",
		 "CLAIMANT_FIRST_NAME": "Zenabu",
		 "CLAIM_NO": "2018-09-20-0007-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "10-01-2018 ",
		 "DATE_CLAIM_CLOSED": "11-05-2018 ",
		 "ACTUAL_RTW_DATE": "11-02-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bawa-Mamudu",
		 "CLAIMANT_FIRST_NAME": "Zenabu",
		 "CLAIM_NO": "2015-04-21-0341-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "05-11-2015 ",
		 "DATE_CLAIM_CLOSED": "07-27-2015 ",
		 "ACTUAL_RTW_DATE": "07-20-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bayogo",
		 "CLAIMANT_FIRST_NAME": "Rosita",
		 "CLAIM_NO": "2014-11-05-0411-VP-01",
		 "DURATION": 483,
		 "DISABILITY_START_DATE": "10-30-2014 ",
		 "DATE_CLAIM_CLOSED": "06-14-2016 ",
		 "ACTUAL_RTW_DATE": "02-25-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Baysal Gurbag",
		 "CLAIMANT_FIRST_NAME": "Canan",
		 "CLAIM_NO": "2013-10-23-0349-VP-01",
		 "DURATION": 405,
		 "DISABILITY_START_DATE": "11-11-2013 ",
		 "DATE_CLAIM_CLOSED": "02-11-2014 ",
		 "ACTUAL_RTW_DATE": "12-21-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Beach",
		 "CLAIMANT_FIRST_NAME": "Kathern",
		 "CLAIM_NO": "2018-10-22-0282-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "10-15-2018 ",
		 "DATE_CLAIM_CLOSED": "10-29-2018 ",
		 "ACTUAL_RTW_DATE": "10-22-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Beach",
		 "CLAIMANT_FIRST_NAME": "Kathern",
		 "CLAIM_NO": "2017-04-04-0089-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "03-29-2017 ",
		 "DATE_CLAIM_CLOSED": "06-12-2017 ",
		 "ACTUAL_RTW_DATE": "05-01-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Beach",
		 "CLAIMANT_FIRST_NAME": "Kathern",
		 "CLAIM_NO": "2020-07-10-0334-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "07-08-2020 ",
		 "DATE_CLAIM_CLOSED": "07-27-2020 ",
		 "ACTUAL_RTW_DATE": "07-23-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Beach",
		 "CLAIMANT_FIRST_NAME": "Kathern",
		 "CLAIM_NO": "2022-11-10-0902-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "11-09-2022 ",
		 "DATE_CLAIM_CLOSED": "11-21-2022 ",
		 "ACTUAL_RTW_DATE": "11-18-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bedal",
		 "CLAIMANT_FIRST_NAME": "Antonette",
		 "CLAIM_NO": "2013-12-02-0361-VP-01",
		 "DURATION": 75,
		 "DISABILITY_START_DATE": "12-18-2013 ",
		 "DATE_CLAIM_CLOSED": "03-19-2014 ",
		 "ACTUAL_RTW_DATE": "03-03-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Beisner",
		 "CLAIMANT_FIRST_NAME": "Delette",
		 "CLAIM_NO": "2013-04-09-0266-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "04-03-2013 ",
		 "DATE_CLAIM_CLOSED": "08-03-2013 ",
		 "ACTUAL_RTW_DATE": "04-22-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bekele",
		 "CLAIMANT_FIRST_NAME": "Girma",
		 "CLAIM_NO": "2014-03-04-0124-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "02-27-2014 ",
		 "DATE_CLAIM_CLOSED": "05-20-2014 ",
		 "ACTUAL_RTW_DATE": "04-14-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Belani",
		 "CLAIMANT_FIRST_NAME": "Prerna",
		 "CLAIM_NO": "2020-08-26-0591-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "08-24-2020 ",
		 "DATE_CLAIM_CLOSED": "10-20-2020 ",
		 "ACTUAL_RTW_DATE": "10-19-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Belcarris",
		 "CLAIMANT_FIRST_NAME": "Willie",
		 "CLAIM_NO": "2022-05-04-0396-VP-01",
		 "DURATION": 36,
		 "DISABILITY_START_DATE": "04-26-2022 ",
		 "DATE_CLAIM_CLOSED": "06-07-2022 ",
		 "ACTUAL_RTW_DATE": "06-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Belcarris",
		 "CLAIMANT_FIRST_NAME": "Willie",
		 "CLAIM_NO": "2019-12-19-0569-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "12-19-2019 ",
		 "DATE_CLAIM_CLOSED": "01-13-2020 ",
		 "ACTUAL_RTW_DATE": "01-02-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Belda",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2021-09-09-0915-VP-01",
		 "DURATION": 98,
		 "DISABILITY_START_DATE": "11-15-2021 ",
		 "DATE_CLAIM_CLOSED": "02-24-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bell",
		 "CLAIMANT_FIRST_NAME": "Deborah",
		 "CLAIM_NO": "2022-10-03-0949-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "10-17-2022 ",
		 "DATE_CLAIM_CLOSED": "11-07-2022 ",
		 "ACTUAL_RTW_DATE": "10-24-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bell",
		 "CLAIMANT_FIRST_NAME": "Deborah",
		 "CLAIM_NO": "2023-01-23-0212-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "01-30-2023 ",
		 "DATE_CLAIM_CLOSED": "03-24-2023 ",
		 "ACTUAL_RTW_DATE": "03-20-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bell",
		 "CLAIMANT_FIRST_NAME": "Lindsey",
		 "CLAIM_NO": "2016-04-13-0604-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "04-13-2016 ",
		 "DATE_CLAIM_CLOSED": "06-16-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bell",
		 "CLAIMANT_FIRST_NAME": "Deborah",
		 "CLAIM_NO": "2016-05-31-0502-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "05-30-2016 ",
		 "DATE_CLAIM_CLOSED": "06-28-2016 ",
		 "ACTUAL_RTW_DATE": "06-07-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bell",
		 "CLAIMANT_FIRST_NAME": "Lindsey",
		 "CLAIM_NO": "2019-04-08-0910-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "04-22-2019 ",
		 "DATE_CLAIM_CLOSED": "08-06-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bell",
		 "CLAIMANT_FIRST_NAME": "Deborah",
		 "CLAIM_NO": "2013-05-16-0310-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "05-15-2013 ",
		 "DATE_CLAIM_CLOSED": "08-15-2013 ",
		 "ACTUAL_RTW_DATE": "05-28-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bell",
		 "CLAIMANT_FIRST_NAME": "Garland",
		 "CLAIM_NO": "2013-01-18-0365-VP-01",
		 "DURATION": 327,
		 "DISABILITY_START_DATE": "01-10-2013 ",
		 "DATE_CLAIM_CLOSED": "02-14-2014 ",
		 "ACTUAL_RTW_DATE": "12-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bell",
		 "CLAIMANT_FIRST_NAME": "Deborah",
		 "CLAIM_NO": "2017-03-22-0503-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "03-20-2017 ",
		 "DATE_CLAIM_CLOSED": "05-22-2017 ",
		 "ACTUAL_RTW_DATE": "04-10-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bell",
		 "CLAIMANT_FIRST_NAME": "Lindsey",
		 "CLAIM_NO": "2013-11-05-0228-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "11-25-2013 ",
		 "DATE_CLAIM_CLOSED": "02-19-2014 ",
		 "ACTUAL_RTW_DATE": "01-07-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bell",
		 "CLAIMANT_FIRST_NAME": "Lindsey",
		 "CLAIM_NO": "2022-01-05-1305-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "12-30-2021 ",
		 "DATE_CLAIM_CLOSED": "02-24-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bell",
		 "CLAIMANT_FIRST_NAME": "John",
		 "CLAIM_NO": "2020-10-11-0021-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "09-30-2020 ",
		 "DATE_CLAIM_CLOSED": "11-12-2020 ",
		 "ACTUAL_RTW_DATE": "11-09-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bellam",
		 "CLAIMANT_FIRST_NAME": "Chandana",
		 "CLAIM_NO": "2013-11-15-0206-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "11-18-2013 ",
		 "DATE_CLAIM_CLOSED": "03-25-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bellew",
		 "CLAIMANT_FIRST_NAME": "Linda",
		 "CLAIM_NO": "2021-09-27-0430-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "09-28-2021 ",
		 "DATE_CLAIM_CLOSED": "11-23-2021 ",
		 "ACTUAL_RTW_DATE": "10-11-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Belton",
		 "CLAIMANT_FIRST_NAME": "Jeffrey",
		 "CLAIM_NO": "2022-08-13-0037-VP-01",
		 "DURATION": 23,
		 "DISABILITY_START_DATE": "08-09-2022 ",
		 "DATE_CLAIM_CLOSED": "09-20-2022 ",
		 "ACTUAL_RTW_DATE": "09-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Benanti",
		 "CLAIMANT_FIRST_NAME": "Gary",
		 "CLAIM_NO": "2022-06-29-0467-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "06-15-2022 ",
		 "DATE_CLAIM_CLOSED": "07-14-2022 ",
		 "ACTUAL_RTW_DATE": "07-06-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Benanti",
		 "CLAIMANT_FIRST_NAME": "Gary",
		 "CLAIM_NO": "2021-10-28-0017-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "10-04-2021 ",
		 "DATE_CLAIM_CLOSED": "11-15-2021 ",
		 "ACTUAL_RTW_DATE": "11-02-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Benanti",
		 "CLAIMANT_FIRST_NAME": "Gary",
		 "CLAIM_NO": "2022-10-20-0818-VP-01",
		 "DURATION": 288,
		 "DISABILITY_START_DATE": "11-16-2022 ",
		 "DATE_CLAIM_CLOSED": "03-20-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Benavidez",
		 "CLAIMANT_FIRST_NAME": "Gloria",
		 "CLAIM_NO": "2014-04-29-0425-VP-01",
		 "DURATION": 36,
		 "DISABILITY_START_DATE": "04-28-2014 ",
		 "DATE_CLAIM_CLOSED": "10-01-2014 ",
		 "ACTUAL_RTW_DATE": "06-03-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bennett",
		 "CLAIMANT_FIRST_NAME": "Chandra",
		 "CLAIM_NO": "2016-10-18-0489-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "11-07-2016 ",
		 "DATE_CLAIM_CLOSED": "12-27-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bennett",
		 "CLAIMANT_FIRST_NAME": "Chandra",
		 "CLAIM_NO": "2019-07-15-0942-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "07-29-2019 ",
		 "DATE_CLAIM_CLOSED": "09-17-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bennett",
		 "CLAIMANT_FIRST_NAME": "Alex",
		 "CLAIM_NO": "2016-04-25-0365-VP-01",
		 "DURATION": 123,
		 "DISABILITY_START_DATE": "04-25-2016 ",
		 "DATE_CLAIM_CLOSED": "08-30-2016 ",
		 "ACTUAL_RTW_DATE": "08-26-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bennett",
		 "CLAIMANT_FIRST_NAME": "Alex",
		 "CLAIM_NO": "2015-09-21-0497-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "09-22-2015 ",
		 "DATE_CLAIM_CLOSED": "11-25-2015 ",
		 "ACTUAL_RTW_DATE": "11-19-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Benshian",
		 "CLAIMANT_FIRST_NAME": "Sheri",
		 "CLAIM_NO": "2020-07-01-0299-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "07-09-2020 ",
		 "DATE_CLAIM_CLOSED": "08-11-2020 ",
		 "ACTUAL_RTW_DATE": "08-10-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Benson",
		 "CLAIMANT_FIRST_NAME": "Jack",
		 "CLAIM_NO": "2015-07-20-0035-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "07-28-2015 ",
		 "DATE_CLAIM_CLOSED": "09-01-2015 ",
		 "ACTUAL_RTW_DATE": "08-25-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bentello",
		 "CLAIMANT_FIRST_NAME": "Christina",
		 "CLAIM_NO": "2019-07-08-0509-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "07-09-2019 ",
		 "DATE_CLAIM_CLOSED": "08-27-2019 ",
		 "ACTUAL_RTW_DATE": "07-22-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bentello",
		 "CLAIMANT_FIRST_NAME": "Christina",
		 "CLAIM_NO": "2020-08-24-0660-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "09-01-2020 ",
		 "DATE_CLAIM_CLOSED": "09-23-2020 ",
		 "ACTUAL_RTW_DATE": "09-22-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bercasio",
		 "CLAIMANT_FIRST_NAME": "Anthony Carlos",
		 "CLAIM_NO": "2020-12-29-0795-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "01-01-2021 ",
		 "DATE_CLAIM_CLOSED": "01-04-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Berezhna",
		 "CLAIMANT_FIRST_NAME": "Svitlana",
		 "CLAIM_NO": "2018-06-05-0722-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "06-15-2018 ",
		 "DATE_CLAIM_CLOSED": "07-18-2018 ",
		 "ACTUAL_RTW_DATE": "07-16-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bereziuk",
		 "CLAIMANT_FIRST_NAME": "John",
		 "CLAIM_NO": "2022-10-18-0497-VP-01",
		 "DURATION": 69,
		 "DISABILITY_START_DATE": "10-11-2022 ",
		 "DATE_CLAIM_CLOSED": "12-22-2022 ",
		 "ACTUAL_RTW_DATE": "12-19-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bergelin",
		 "CLAIMANT_FIRST_NAME": "Carmen",
		 "CLAIM_NO": "2021-04-20-0693-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "05-04-2021 ",
		 "DATE_CLAIM_CLOSED": "05-24-2021 ",
		 "ACTUAL_RTW_DATE": "05-19-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Berman",
		 "CLAIMANT_FIRST_NAME": "Glenn",
		 "CLAIM_NO": "2013-01-05-0356-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "11-26-2012 ",
		 "DATE_CLAIM_CLOSED": "01-14-2013 ",
		 "ACTUAL_RTW_DATE": "01-14-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bermudez",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2019-05-20-0561-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "06-03-2019 ",
		 "DATE_CLAIM_CLOSED": "07-23-2019 ",
		 "ACTUAL_RTW_DATE": "06-27-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bernstein",
		 "CLAIMANT_FIRST_NAME": "Daniel",
		 "CLAIM_NO": "2017-06-05-0626-VP-01",
		 "DURATION": 50,
		 "DISABILITY_START_DATE": "07-03-2017 ",
		 "DATE_CLAIM_CLOSED": "08-29-2017 ",
		 "ACTUAL_RTW_DATE": "08-22-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Berry",
		 "CLAIMANT_FIRST_NAME": "Stephanie",
		 "CLAIM_NO": "2018-04-23-0803-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "05-03-2018 ",
		 "DATE_CLAIM_CLOSED": "06-15-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bhalla",
		 "CLAIMANT_FIRST_NAME": "Shivani",
		 "CLAIM_NO": "2021-11-16-1176-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "11-15-2021 ",
		 "DATE_CLAIM_CLOSED": "01-03-2022 ",
		 "ACTUAL_RTW_DATE": "12-26-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bhalla",
		 "CLAIMANT_FIRST_NAME": "Shivani",
		 "CLAIM_NO": "2018-09-06-0021-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "09-10-2018 ",
		 "DATE_CLAIM_CLOSED": "11-15-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bhaskarabhatla",
		 "CLAIMANT_FIRST_NAME": "Sheetal",
		 "CLAIM_NO": "2020-12-08-0636-VP-01",
		 "DURATION": 51,
		 "DISABILITY_START_DATE": "01-15-2021 ",
		 "DATE_CLAIM_CLOSED": "03-12-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bhatt",
		 "CLAIMANT_FIRST_NAME": "Swaroop",
		 "CLAIM_NO": "2016-08-03-0054-VP-01",
		 "DURATION": 73,
		 "DISABILITY_START_DATE": "09-05-2016 ",
		 "DATE_CLAIM_CLOSED": "11-17-2016 ",
		 "ACTUAL_RTW_DATE": "11-17-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bhatt",
		 "CLAIMANT_FIRST_NAME": "Janki",
		 "CLAIM_NO": "2019-01-27-0037-VP-01",
		 "DURATION": 109,
		 "DISABILITY_START_DATE": "02-14-2019 ",
		 "DATE_CLAIM_CLOSED": "04-30-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bhonsle",
		 "CLAIMANT_FIRST_NAME": "Suyashree",
		 "CLAIM_NO": "2019-12-09-0913-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "12-31-2019 ",
		 "DATE_CLAIM_CLOSED": "02-14-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bhounsule",
		 "CLAIMANT_FIRST_NAME": "Prajakta",
		 "CLAIM_NO": "2021-07-15-0004-VP-01",
		 "DURATION": 51,
		 "DISABILITY_START_DATE": "08-09-2021 ",
		 "DATE_CLAIM_CLOSED": "10-08-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bhow",
		 "CLAIMANT_FIRST_NAME": "Kiran",
		 "CLAIM_NO": "2014-01-27-0032-VP-01",
		 "DURATION": 38,
		 "DISABILITY_START_DATE": "01-03-2014 ",
		 "DATE_CLAIM_CLOSED": "05-01-2014 ",
		 "ACTUAL_RTW_DATE": "02-10-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bickel",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2021-11-22-0865-VP-01",
		 "DURATION": 76,
		 "DISABILITY_START_DATE": "11-17-2021 ",
		 "DATE_CLAIM_CLOSED": "02-02-2022 ",
		 "ACTUAL_RTW_DATE": "02-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bigler",
		 "CLAIMANT_FIRST_NAME": "Carol",
		 "CLAIM_NO": "2015-06-02-0220-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "05-29-2015 ",
		 "DATE_CLAIM_CLOSED": "06-16-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bin",
		 "CLAIMANT_FIRST_NAME": "Saruny",
		 "CLAIM_NO": "2019-07-30-0648-VP-01",
		 "DURATION": 23,
		 "DISABILITY_START_DATE": "07-22-2019 ",
		 "DATE_CLAIM_CLOSED": "09-04-2019 ",
		 "ACTUAL_RTW_DATE": "08-14-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bin",
		 "CLAIMANT_FIRST_NAME": "Eric",
		 "CLAIM_NO": "2020-06-25-0683-VP-01",
		 "DURATION": 126,
		 "DISABILITY_START_DATE": "06-22-2020 ",
		 "DATE_CLAIM_CLOSED": "10-28-2020 ",
		 "ACTUAL_RTW_DATE": "10-26-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bince",
		 "CLAIMANT_FIRST_NAME": "Zynda",
		 "CLAIM_NO": "2014-10-29-0171-VP-01",
		 "DURATION": 131,
		 "DISABILITY_START_DATE": "10-23-2014 ",
		 "DATE_CLAIM_CLOSED": "03-03-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bince",
		 "CLAIMANT_FIRST_NAME": "Zynda",
		 "CLAIM_NO": "2014-07-02-0780-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "06-30-2014 ",
		 "DATE_CLAIM_CLOSED": "12-16-2014 ",
		 "ACTUAL_RTW_DATE": "07-28-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Binga",
		 "CLAIMANT_FIRST_NAME": "Benjamin",
		 "CLAIM_NO": "2022-01-21-0310-STD-01",
		 "DISABILITY_START_DATE": "02-26-2022 ",
		 "DATE_CLAIM_CLOSED": "01-25-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Biorato",
		 "CLAIMANT_FIRST_NAME": "Isabel",
		 "CLAIM_NO": "2018-05-23-0602-VP-01",
		 "DURATION": 52,
		 "DISABILITY_START_DATE": "06-04-2018 ",
		 "DATE_CLAIM_CLOSED": "07-27-2018 ",
		 "ACTUAL_RTW_DATE": "07-26-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Birce-Ambriz",
		 "CLAIMANT_FIRST_NAME": "Donnette",
		 "CLAIM_NO": "2013-09-23-0294-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "09-16-2013 ",
		 "DATE_CLAIM_CLOSED": "01-09-2014 ",
		 "ACTUAL_RTW_DATE": "10-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Birce-Ambriz",
		 "CLAIMANT_FIRST_NAME": "Donnette",
		 "CLAIM_NO": "2013-01-05-0159-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "12-04-2012 ",
		 "DATE_CLAIM_CLOSED": "02-09-2013 ",
		 "ACTUAL_RTW_DATE": "12-16-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bird",
		 "CLAIMANT_FIRST_NAME": "Clemencia",
		 "CLAIM_NO": "2013-01-05-0183-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "12-13-2012 ",
		 "DATE_CLAIM_CLOSED": "01-20-2013 ",
		 "ACTUAL_RTW_DATE": "12-24-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Black",
		 "CLAIMANT_FIRST_NAME": "Shannon",
		 "CLAIM_NO": "2015-06-08-0017-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "06-01-2015 ",
		 "DATE_CLAIM_CLOSED": "06-11-2015 ",
		 "ACTUAL_RTW_DATE": "06-10-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Black",
		 "CLAIMANT_FIRST_NAME": "Jonathan",
		 "CLAIM_NO": "2021-02-12-0196-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "02-12-2021 ",
		 "DATE_CLAIM_CLOSED": "03-22-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Blackman",
		 "CLAIMANT_FIRST_NAME": "Rachel",
		 "CLAIM_NO": "2022-06-10-0904-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "06-07-2022 ",
		 "DATE_CLAIM_CLOSED": "06-24-2022 ",
		 "ACTUAL_RTW_DATE": "06-20-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Blanco",
		 "CLAIMANT_FIRST_NAME": "Charito",
		 "CLAIM_NO": "2020-01-23-0426-VP-01",
		 "DURATION": -657408,
		 "DISABILITY_START_DATE": "01-22-2020 ",
		 "DATE_CLAIM_CLOSED": "02-24-2020 ",
		 "ACTUAL_RTW_DATE": "2\/20\/0220",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Blasco",
		 "CLAIMANT_FIRST_NAME": "Diana",
		 "CLAIM_NO": "2019-06-14-0555-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "07-12-2019 ",
		 "DATE_CLAIM_CLOSED": "08-26-2019 ",
		 "ACTUAL_RTW_DATE": "08-26-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Blevins",
		 "CLAIMANT_FIRST_NAME": "Cynthia",
		 "CLAIM_NO": "2020-11-08-0005-VP-01",
		 "DURATION": 208,
		 "DISABILITY_START_DATE": "11-05-2020 ",
		 "DATE_CLAIM_CLOSED": "06-02-2021 ",
		 "ACTUAL_RTW_DATE": "06-01-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Blevins",
		 "CLAIMANT_FIRST_NAME": "Cynthia",
		 "CLAIM_NO": "2022-01-12-0798-VP-01",
		 "DURATION": 375,
		 "DISABILITY_START_DATE": "01-13-2022 ",
		 "DATE_CLAIM_CLOSED": "01-14-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Blevins",
		 "CLAIMANT_FIRST_NAME": "Cynthia",
		 "CLAIM_NO": "2016-04-04-0548-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "04-11-2016 ",
		 "DATE_CLAIM_CLOSED": "04-27-2016 ",
		 "ACTUAL_RTW_DATE": "04-25-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bloch",
		 "CLAIMANT_FIRST_NAME": "Valerie",
		 "CLAIM_NO": "2022-02-25-0903-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "03-07-2022 ",
		 "DATE_CLAIM_CLOSED": "04-19-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bloxham",
		 "CLAIMANT_FIRST_NAME": "Laurel",
		 "CLAIM_NO": "2021-04-08-0540-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "05-31-2021 ",
		 "DATE_CLAIM_CLOSED": "07-13-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Blumgold",
		 "CLAIMANT_FIRST_NAME": "Kc",
		 "CLAIM_NO": "2022-06-07-0890-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "06-27-2022 ",
		 "DATE_CLAIM_CLOSED": "07-18-2022 ",
		 "ACTUAL_RTW_DATE": "07-11-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bojo",
		 "CLAIMANT_FIRST_NAME": "Ma.",
		 "CLAIM_NO": "2022-09-09-0858-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "10-18-2022 ",
		 "DATE_CLAIM_CLOSED": "11-07-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bola",
		 "CLAIMANT_FIRST_NAME": "Randeep",
		 "CLAIM_NO": "2021-10-05-0942-VP-01",
		 "DURATION": 357,
		 "DISABILITY_START_DATE": "10-06-2021 ",
		 "DATE_CLAIM_CLOSED": "10-14-2022 ",
		 "ACTUAL_RTW_DATE": "09-28-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bonnand",
		 "CLAIMANT_FIRST_NAME": "George",
		 "CLAIM_NO": "2013-10-31-0264-VP-01",
		 "DURATION": 120,
		 "DISABILITY_START_DATE": "10-21-2013 ",
		 "DATE_CLAIM_CLOSED": "02-24-2014 ",
		 "ACTUAL_RTW_DATE": "02-18-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bonsol",
		 "CLAIMANT_FIRST_NAME": "Jasmin",
		 "CLAIM_NO": "2020-09-11-0026-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "09-10-2020 ",
		 "DATE_CLAIM_CLOSED": "10-14-2020 ",
		 "ACTUAL_RTW_DATE": "10-12-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Boone",
		 "CLAIMANT_FIRST_NAME": "Nancy",
		 "CLAIM_NO": "2016-10-17-0442-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "10-31-2016 ",
		 "DATE_CLAIM_CLOSED": "01-23-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Boone",
		 "CLAIMANT_FIRST_NAME": "Lauren",
		 "CLAIM_NO": "2018-10-26-0257-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "11-09-2018 ",
		 "DATE_CLAIM_CLOSED": "11-20-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Boone",
		 "CLAIMANT_FIRST_NAME": "Lauren",
		 "CLAIM_NO": "2018-04-06-0457-VP-01",
		 "DURATION": 278,
		 "DISABILITY_START_DATE": "03-30-2018 ",
		 "DATE_CLAIM_CLOSED": "01-08-2019 ",
		 "ACTUAL_RTW_DATE": "01-02-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Boone",
		 "CLAIMANT_FIRST_NAME": "Lauren",
		 "CLAIM_NO": "2015-05-21-0236-VP-01",
		 "DURATION": 179,
		 "DISABILITY_START_DATE": "05-21-2015 ",
		 "DATE_CLAIM_CLOSED": "11-17-2015 ",
		 "ACTUAL_RTW_DATE": "11-16-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bopp",
		 "CLAIMANT_FIRST_NAME": "Peter",
		 "CLAIM_NO": "2015-09-22-0270-VP-01",
		 "DURATION": 118,
		 "DISABILITY_START_DATE": "10-20-2015 ",
		 "DATE_CLAIM_CLOSED": "10-03-2016 ",
		 "ACTUAL_RTW_DATE": "02-15-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bopp",
		 "CLAIMANT_FIRST_NAME": "Peter",
		 "CLAIM_NO": "2013-01-05-2445-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "03-21-2012 ",
		 "DATE_CLAIM_CLOSED": "10-03-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bopp",
		 "CLAIMANT_FIRST_NAME": "Peter",
		 "CLAIM_NO": "2016-07-13-0286-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "07-13-2016 ",
		 "DATE_CLAIM_CLOSED": "09-19-2016 ",
		 "ACTUAL_RTW_DATE": "09-14-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Borbon",
		 "CLAIMANT_FIRST_NAME": "Jasmine",
		 "CLAIM_NO": "2022-06-03-0872-VP-01",
		 "DURATION": 141,
		 "DISABILITY_START_DATE": "07-13-2022 ",
		 "DATE_CLAIM_CLOSED": "10-19-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bose",
		 "CLAIMANT_FIRST_NAME": "Tanushree",
		 "CLAIM_NO": "2019-06-17-0820-VP-01",
		 "DURATION": 167,
		 "DISABILITY_START_DATE": "06-17-2019 ",
		 "DATE_CLAIM_CLOSED": "08-19-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bostic",
		 "CLAIMANT_FIRST_NAME": "Toniesha",
		 "CLAIM_NO": "2018-05-07-0137-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "05-31-2018 ",
		 "DATE_CLAIM_CLOSED": "06-25-2018 ",
		 "ACTUAL_RTW_DATE": "06-22-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bote",
		 "CLAIMANT_FIRST_NAME": "Marvin",
		 "CLAIM_NO": "2018-02-12-0728-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "02-13-2018 ",
		 "DATE_CLAIM_CLOSED": "02-19-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Boteju",
		 "CLAIMANT_FIRST_NAME": "Brian",
		 "CLAIM_NO": "2021-12-02-1063-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "11-29-2021 ",
		 "DATE_CLAIM_CLOSED": "02-22-2022 ",
		 "ACTUAL_RTW_DATE": "02-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Botham",
		 "CLAIMANT_FIRST_NAME": "Alicia",
		 "CLAIM_NO": "2022-06-06-1183-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "06-07-2022 ",
		 "DATE_CLAIM_CLOSED": "07-19-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bouthillette",
		 "CLAIMANT_FIRST_NAME": "Carl",
		 "CLAIM_NO": "2013-11-22-0169-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "11-25-2013 ",
		 "DATE_CLAIM_CLOSED": "02-12-2014 ",
		 "ACTUAL_RTW_DATE": "12-23-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bowman",
		 "CLAIMANT_FIRST_NAME": "Mariana",
		 "CLAIM_NO": "2022-11-10-0940-VP-01",
		 "DURATION": 120,
		 "DISABILITY_START_DATE": "11-16-2022 ",
		 "DATE_CLAIM_CLOSED": "03-17-2023 ",
		 "ACTUAL_RTW_DATE": "03-16-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Boyd",
		 "CLAIMANT_FIRST_NAME": "Jacqueline",
		 "CLAIM_NO": "2013-08-16-0169-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "08-12-2013 ",
		 "DATE_CLAIM_CLOSED": "10-30-2013 ",
		 "ACTUAL_RTW_DATE": "08-26-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Boyd",
		 "CLAIMANT_FIRST_NAME": "Christopher",
		 "CLAIM_NO": "2018-05-30-0385-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "05-30-2018 ",
		 "DATE_CLAIM_CLOSED": "08-16-2018 ",
		 "ACTUAL_RTW_DATE": "06-11-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Boyd",
		 "CLAIMANT_FIRST_NAME": "Jacqueline",
		 "CLAIM_NO": "2017-02-07-0500-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "02-06-2017 ",
		 "DATE_CLAIM_CLOSED": "02-27-2017 ",
		 "ACTUAL_RTW_DATE": "02-20-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Boyle",
		 "CLAIMANT_FIRST_NAME": "William",
		 "CLAIM_NO": "2020-11-13-0385-VP-01",
		 "DURATION": 175,
		 "DISABILITY_START_DATE": "11-23-2020 ",
		 "DATE_CLAIM_CLOSED": "05-21-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Boyle",
		 "CLAIMANT_FIRST_NAME": "Rachel",
		 "CLAIM_NO": "2021-09-24-0692-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "10-11-2021 ",
		 "DATE_CLAIM_CLOSED": "12-20-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Braden",
		 "CLAIMANT_FIRST_NAME": "Aaron",
		 "CLAIM_NO": "2017-03-24-0412-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "03-21-2017 ",
		 "DATE_CLAIM_CLOSED": "04-27-2017 ",
		 "ACTUAL_RTW_DATE": "04-10-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bradford",
		 "CLAIMANT_FIRST_NAME": "Paul",
		 "CLAIM_NO": "2016-12-28-0328-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "01-26-2017 ",
		 "DATE_CLAIM_CLOSED": "03-20-2017 ",
		 "ACTUAL_RTW_DATE": "03-09-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bradford",
		 "CLAIMANT_FIRST_NAME": "Paul",
		 "CLAIM_NO": "2013-10-07-0135-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "11-05-2013 ",
		 "DATE_CLAIM_CLOSED": "02-11-2014 ",
		 "ACTUAL_RTW_DATE": "12-09-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bradrick",
		 "CLAIMANT_FIRST_NAME": "Brittany",
		 "CLAIM_NO": "2013-07-15-0173-VP-01",
		 "DURATION": 23,
		 "DISABILITY_START_DATE": "08-12-2013 ",
		 "DATE_CLAIM_CLOSED": "09-11-2013 ",
		 "ACTUAL_RTW_DATE": "09-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bradrick",
		 "CLAIMANT_FIRST_NAME": "Brittany",
		 "CLAIM_NO": "2014-10-11-0003-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "10-13-2014 ",
		 "DATE_CLAIM_CLOSED": "12-15-2014 ",
		 "ACTUAL_RTW_DATE": "12-08-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bradwell",
		 "CLAIMANT_FIRST_NAME": "Christopher",
		 "CLAIM_NO": "2014-01-07-0220-VP-01",
		 "DURATION": 215,
		 "DISABILITY_START_DATE": "01-03-2014 ",
		 "DATE_CLAIM_CLOSED": "08-26-2014 ",
		 "ACTUAL_RTW_DATE": "08-06-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bradwell",
		 "CLAIMANT_FIRST_NAME": "Christopher",
		 "CLAIM_NO": "2013-04-16-0423-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "04-29-2013 ",
		 "DATE_CLAIM_CLOSED": "07-15-2013 ",
		 "ACTUAL_RTW_DATE": "07-09-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bragasin",
		 "CLAIMANT_FIRST_NAME": "Rhoneil",
		 "CLAIM_NO": "2019-04-26-0555-VP-01",
		 "DURATION": 61,
		 "DISABILITY_START_DATE": "04-12-2019 ",
		 "DATE_CLAIM_CLOSED": "06-17-2019 ",
		 "ACTUAL_RTW_DATE": "06-12-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brambila Ornelas",
		 "CLAIMANT_FIRST_NAME": "Armando",
		 "CLAIM_NO": "2022-05-02-0606-VP-01",
		 "DURATION": 161,
		 "DISABILITY_START_DATE": "05-02-2022 ",
		 "DATE_CLAIM_CLOSED": "10-19-2022 ",
		 "ACTUAL_RTW_DATE": "10-10-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brambila Ornelas",
		 "CLAIMANT_FIRST_NAME": "Armando",
		 "CLAIM_NO": "2019-09-04-0427-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "08-14-2019 ",
		 "DATE_CLAIM_CLOSED": "09-24-2019 ",
		 "ACTUAL_RTW_DATE": "09-09-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brannan",
		 "CLAIMANT_FIRST_NAME": "Lucas",
		 "CLAIM_NO": "2013-10-29-0365-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "10-08-2013 ",
		 "DATE_CLAIM_CLOSED": "06-03-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brannon",
		 "CLAIMANT_FIRST_NAME": "Dawn",
		 "CLAIM_NO": "2013-01-14-0017-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "11-12-2012 ",
		 "DATE_CLAIM_CLOSED": "05-23-2013 ",
		 "ACTUAL_RTW_DATE": "01-28-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bravo",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2020-10-19-0240-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "10-15-2020 ",
		 "DATE_CLAIM_CLOSED": "12-15-2020 ",
		 "ACTUAL_RTW_DATE": "12-01-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bravo",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2021-04-21-0865-VP-01",
		 "DURATION": 97,
		 "DISABILITY_START_DATE": "04-20-2021 ",
		 "DATE_CLAIM_CLOSED": "09-29-2021 ",
		 "ACTUAL_RTW_DATE": "07-26-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brazner-Morehead",
		 "CLAIMANT_FIRST_NAME": "Candace",
		 "CLAIM_NO": "2022-03-02-0529-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "03-07-2022 ",
		 "DATE_CLAIM_CLOSED": "04-06-2022 ",
		 "ACTUAL_RTW_DATE": "04-04-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brewer",
		 "CLAIMANT_FIRST_NAME": "Kathy",
		 "CLAIM_NO": "2013-02-04-0419-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "02-05-2013 ",
		 "DATE_CLAIM_CLOSED": "05-22-2013 ",
		 "ACTUAL_RTW_DATE": "02-27-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brewer",
		 "CLAIMANT_FIRST_NAME": "Alexis",
		 "CLAIM_NO": "2022-07-27-0512-VP-01",
		 "DURATION": 82,
		 "DISABILITY_START_DATE": "07-20-2022 ",
		 "DATE_CLAIM_CLOSED": "11-16-2022 ",
		 "ACTUAL_RTW_DATE": "10-10-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brewer",
		 "CLAIMANT_FIRST_NAME": "Kathy",
		 "CLAIM_NO": "2019-07-31-0747-VP-01",
		 "DURATION": 80,
		 "DISABILITY_START_DATE": "09-13-2019 ",
		 "DATE_CLAIM_CLOSED": "12-16-2019 ",
		 "ACTUAL_RTW_DATE": "12-02-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bright",
		 "CLAIMANT_FIRST_NAME": "Vicki",
		 "CLAIM_NO": "2022-03-07-0780-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "03-02-2022 ",
		 "DATE_CLAIM_CLOSED": "03-21-2022 ",
		 "ACTUAL_RTW_DATE": "03-10-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brokob",
		 "CLAIMANT_FIRST_NAME": "Nicole",
		 "CLAIM_NO": "2020-07-10-0629-VP-01",
		 "DURATION": 141,
		 "DISABILITY_START_DATE": "06-01-2020 ",
		 "DATE_CLAIM_CLOSED": "10-20-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brokob",
		 "CLAIMANT_FIRST_NAME": "Nicole",
		 "CLAIM_NO": "2019-12-31-0016-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "01-02-2020 ",
		 "DATE_CLAIM_CLOSED": "03-25-2020 ",
		 "ACTUAL_RTW_DATE": "03-23-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brooks",
		 "CLAIMANT_FIRST_NAME": "James",
		 "CLAIM_NO": "2019-10-03-0523-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "09-30-2019 ",
		 "DATE_CLAIM_CLOSED": "01-03-2020 ",
		 "ACTUAL_RTW_DATE": "12-30-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brown",
		 "CLAIMANT_FIRST_NAME": "Shela",
		 "CLAIM_NO": "2015-11-16-0058-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "11-13-2015 ",
		 "DATE_CLAIM_CLOSED": "01-06-2016 ",
		 "ACTUAL_RTW_DATE": "12-25-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brown",
		 "CLAIMANT_FIRST_NAME": "Cindy",
		 "CLAIM_NO": "2013-05-09-0208-VP-01",
		 "DURATION": 156,
		 "DISABILITY_START_DATE": "05-28-2013 ",
		 "DATE_CLAIM_CLOSED": "03-06-2014 ",
		 "ACTUAL_RTW_DATE": "10-31-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brown",
		 "CLAIMANT_FIRST_NAME": "Meghan",
		 "CLAIM_NO": "2013-02-05-0266-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "02-07-2013 ",
		 "DATE_CLAIM_CLOSED": "05-14-2013 ",
		 "ACTUAL_RTW_DATE": "02-20-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brown",
		 "CLAIMANT_FIRST_NAME": "Shela",
		 "CLAIM_NO": "2021-01-13-0731-VP-01",
		 "DURATION": 148,
		 "DISABILITY_START_DATE": "11-04-2020 ",
		 "DATE_CLAIM_CLOSED": "04-02-2021 ",
		 "ACTUAL_RTW_DATE": "04-01-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brown",
		 "CLAIMANT_FIRST_NAME": "Shela",
		 "CLAIM_NO": "2016-03-31-0022-VP-01",
		 "DURATION": 61,
		 "DISABILITY_START_DATE": "04-01-2016 ",
		 "DATE_CLAIM_CLOSED": "06-16-2016 ",
		 "ACTUAL_RTW_DATE": "06-01-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brown",
		 "CLAIMANT_FIRST_NAME": "Sara",
		 "CLAIM_NO": "2015-07-01-0381-VP-01",
		 "DURATION": 225,
		 "DISABILITY_START_DATE": "07-20-2015 ",
		 "DATE_CLAIM_CLOSED": "03-03-2016 ",
		 "ACTUAL_RTW_DATE": "03-01-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brown",
		 "CLAIMANT_FIRST_NAME": "Beatrice",
		 "CLAIM_NO": "2013-01-05-1372-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "08-08-2012 ",
		 "DATE_CLAIM_CLOSED": "01-20-2013 ",
		 "ACTUAL_RTW_DATE": "09-17-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brown",
		 "CLAIMANT_FIRST_NAME": "Kristen",
		 "CLAIM_NO": "2014-11-03-0174-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "11-05-2014 ",
		 "DATE_CLAIM_CLOSED": "01-12-2015 ",
		 "ACTUAL_RTW_DATE": "01-06-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brown",
		 "CLAIMANT_FIRST_NAME": "Shela",
		 "CLAIM_NO": "2013-11-20-0250-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "12-09-2013 ",
		 "DATE_CLAIM_CLOSED": "05-05-2014 ",
		 "ACTUAL_RTW_DATE": "01-20-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brown",
		 "CLAIMANT_FIRST_NAME": "Sara",
		 "CLAIM_NO": "2013-01-05-0750-VP-01",
		 "DURATION": 245,
		 "DISABILITY_START_DATE": "10-08-2012 ",
		 "DATE_CLAIM_CLOSED": "06-10-2013 ",
		 "ACTUAL_RTW_DATE": "06-10-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brown",
		 "CLAIMANT_FIRST_NAME": "Mallouce",
		 "CLAIM_NO": "2015-05-15-0379-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "06-01-2015 ",
		 "DATE_CLAIM_CLOSED": "06-16-2015 ",
		 "ACTUAL_RTW_DATE": "06-15-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brown",
		 "CLAIMANT_FIRST_NAME": "Jasmine",
		 "CLAIM_NO": "2022-02-17-0100-VP-01",
		 "DISABILITY_START_DATE": "01-24-2022 ",
		 "DATE_CLAIM_CLOSED": "02-17-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brown",
		 "CLAIMANT_FIRST_NAME": "Cindy",
		 "CLAIM_NO": "2013-01-28-0371-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "02-26-2013 ",
		 "DATE_CLAIM_CLOSED": "07-23-2013 ",
		 "ACTUAL_RTW_DATE": "05-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brown",
		 "CLAIMANT_FIRST_NAME": "Carmelita",
		 "CLAIM_NO": "2019-12-11-0472-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "12-05-2019 ",
		 "DATE_CLAIM_CLOSED": "01-03-2020 ",
		 "ACTUAL_RTW_DATE": "01-02-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brown",
		 "CLAIMANT_FIRST_NAME": "Licole",
		 "CLAIM_NO": "2016-03-28-0389-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "04-14-2016 ",
		 "DATE_CLAIM_CLOSED": "06-16-2016 ",
		 "ACTUAL_RTW_DATE": "05-26-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brown",
		 "CLAIMANT_FIRST_NAME": "Kristen",
		 "CLAIM_NO": "2013-01-23-0426-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "01-21-2013 ",
		 "DATE_CLAIM_CLOSED": "05-10-2013 ",
		 "ACTUAL_RTW_DATE": "03-20-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brown",
		 "CLAIMANT_FIRST_NAME": "Mallouce",
		 "CLAIM_NO": "2019-11-14-0363-VP-01",
		 "DURATION": 141,
		 "DISABILITY_START_DATE": "12-02-2019 ",
		 "DATE_CLAIM_CLOSED": "04-22-2020 ",
		 "ACTUAL_RTW_DATE": "04-21-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brown-Gallardo",
		 "CLAIMANT_FIRST_NAME": "Sarah",
		 "CLAIM_NO": "2020-07-17-0689-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "07-20-2020 ",
		 "DATE_CLAIM_CLOSED": "08-11-2020 ",
		 "ACTUAL_RTW_DATE": "08-10-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brown-Gallardo",
		 "CLAIMANT_FIRST_NAME": "Sarah",
		 "CLAIM_NO": "2019-10-24-0422-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "10-22-2019 ",
		 "DATE_CLAIM_CLOSED": "12-10-2019 ",
		 "ACTUAL_RTW_DATE": "12-02-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brownstein",
		 "CLAIMANT_FIRST_NAME": "Paula",
		 "CLAIM_NO": "2013-09-25-0039-VP-01",
		 "DURATION": 30,
		 "DISABILITY_START_DATE": "09-23-2013 ",
		 "DATE_CLAIM_CLOSED": "06-02-2014 ",
		 "ACTUAL_RTW_DATE": "10-23-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brownstein",
		 "CLAIMANT_FIRST_NAME": "Paula",
		 "CLAIM_NO": "2013-04-04-0355-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "05-02-2013 ",
		 "DATE_CLAIM_CLOSED": "06-08-2013 ",
		 "ACTUAL_RTW_DATE": "06-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brunkow",
		 "CLAIMANT_FIRST_NAME": "Alan",
		 "CLAIM_NO": "2022-06-28-0940-VP-02",
		 "DISABILITY_START_DATE": "06-27-2022 ",
		 "DATE_CLAIM_CLOSED": "08-15-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brunkow",
		 "CLAIMANT_FIRST_NAME": "Alan",
		 "CLAIM_NO": "2016-02-24-0519-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "02-24-2016 ",
		 "DATE_CLAIM_CLOSED": "03-09-2016 ",
		 "ACTUAL_RTW_DATE": "03-07-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brunkow",
		 "CLAIMANT_FIRST_NAME": "Alan",
		 "CLAIM_NO": "2022-06-28-0940-VP-01",
		 "DURATION": 189,
		 "DISABILITY_START_DATE": "06-27-2022 ",
		 "DATE_CLAIM_CLOSED": "01-06-2023 ",
		 "ACTUAL_RTW_DATE": "01-02-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brunkow",
		 "CLAIMANT_FIRST_NAME": "Alan",
		 "CLAIM_NO": "2022-09-10-0087-VP-01",
		 "DURATION": 117,
		 "DISABILITY_START_DATE": "09-07-2022 ",
		 "DATE_CLAIM_CLOSED": "09-16-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brunkow",
		 "CLAIMANT_FIRST_NAME": "Alan",
		 "CLAIM_NO": "2018-04-12-0460-VP-01",
		 "DURATION": 36,
		 "DISABILITY_START_DATE": "04-16-2018 ",
		 "DATE_CLAIM_CLOSED": "05-29-2018 ",
		 "ACTUAL_RTW_DATE": "05-22-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brunshwiler",
		 "CLAIMANT_FIRST_NAME": "Vicki",
		 "CLAIM_NO": "2013-07-18-0113-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "07-26-2013 ",
		 "DATE_CLAIM_CLOSED": "09-10-2013 ",
		 "ACTUAL_RTW_DATE": "08-12-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Brush",
		 "CLAIMANT_FIRST_NAME": "Michael",
		 "CLAIM_NO": "2015-10-26-0549-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "11-12-2015 ",
		 "DATE_CLAIM_CLOSED": "04-07-2016 ",
		 "ACTUAL_RTW_DATE": "11-30-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bryan",
		 "CLAIMANT_FIRST_NAME": "Erlinda",
		 "CLAIM_NO": "2021-09-07-0148-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "09-03-2021 ",
		 "DATE_CLAIM_CLOSED": "10-13-2021 ",
		 "ACTUAL_RTW_DATE": "09-20-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bu",
		 "CLAIMANT_FIRST_NAME": "Fei",
		 "CLAIM_NO": "2022-07-25-1094-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "08-11-2022 ",
		 "DATE_CLAIM_CLOSED": "10-12-2022 ",
		 "ACTUAL_RTW_DATE": "09-02-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bucaro",
		 "CLAIMANT_FIRST_NAME": "Olga",
		 "CLAIM_NO": "2020-06-03-0677-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "06-09-2020 ",
		 "DATE_CLAIM_CLOSED": "06-29-2020 ",
		 "ACTUAL_RTW_DATE": "06-29-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Buccola",
		 "CLAIMANT_FIRST_NAME": "Jana",
		 "CLAIM_NO": "2013-01-05-0778-VP-01",
		 "DURATION": 108,
		 "DISABILITY_START_DATE": "09-24-2012 ",
		 "DATE_CLAIM_CLOSED": "02-09-2013 ",
		 "ACTUAL_RTW_DATE": "01-10-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Buchner",
		 "CLAIMANT_FIRST_NAME": "Katherine",
		 "CLAIM_NO": "2019-01-24-0201-VP-01",
		 "DURATION": 113,
		 "DISABILITY_START_DATE": "03-11-2019 ",
		 "DATE_CLAIM_CLOSED": "04-23-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Buchner",
		 "CLAIMANT_FIRST_NAME": "Katherine",
		 "CLAIM_NO": "2021-07-14-0568-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "08-23-2021 ",
		 "DATE_CLAIM_CLOSED": "10-05-2021 ",
		 "ACTUAL_RTW_DATE": "10-04-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Buckley",
		 "CLAIMANT_FIRST_NAME": "Paul",
		 "CLAIM_NO": "2021-04-07-0410-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "04-05-2021 ",
		 "DATE_CLAIM_CLOSED": "05-12-2021 ",
		 "ACTUAL_RTW_DATE": "05-10-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Buckley",
		 "CLAIMANT_FIRST_NAME": "Paul",
		 "CLAIM_NO": "2019-11-05-0644-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "12-12-2019 ",
		 "DATE_CLAIM_CLOSED": "02-11-2020 ",
		 "ACTUAL_RTW_DATE": "02-10-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Budwal",
		 "CLAIMANT_FIRST_NAME": "Nirmaljit",
		 "CLAIM_NO": "2014-03-27-3207-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "03-26-2014 ",
		 "DATE_CLAIM_CLOSED": "12-09-2014 ",
		 "ACTUAL_RTW_DATE": "04-17-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Buechler",
		 "CLAIMANT_FIRST_NAME": "Alexandra",
		 "CLAIM_NO": "2021-06-18-0309-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "07-09-2021 ",
		 "DATE_CLAIM_CLOSED": "09-07-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Buell",
		 "CLAIMANT_FIRST_NAME": "Robin",
		 "CLAIM_NO": "2019-02-08-0335-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "02-25-2019 ",
		 "DATE_CLAIM_CLOSED": "03-12-2019 ",
		 "ACTUAL_RTW_DATE": "03-07-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Buenrostro",
		 "CLAIMANT_FIRST_NAME": "Heriberto",
		 "CLAIM_NO": "2017-01-04-0046-VP-01",
		 "DURATION": 105,
		 "DISABILITY_START_DATE": "01-05-2017 ",
		 "DATE_CLAIM_CLOSED": "02-17-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Burchard",
		 "CLAIMANT_FIRST_NAME": "Adriana",
		 "CLAIM_NO": "2014-08-15-0122-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "08-19-2014 ",
		 "DATE_CLAIM_CLOSED": "11-12-2014 ",
		 "ACTUAL_RTW_DATE": "09-01-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Burchard",
		 "CLAIMANT_FIRST_NAME": "Adriana",
		 "CLAIM_NO": "2014-03-13-0428-VP-01",
		 "DURATION": 38,
		 "DISABILITY_START_DATE": "03-24-2014 ",
		 "DATE_CLAIM_CLOSED": "05-20-2014 ",
		 "ACTUAL_RTW_DATE": "05-01-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Burgard",
		 "CLAIMANT_FIRST_NAME": "Michele",
		 "CLAIM_NO": "2016-07-01-0205-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "08-01-2016 ",
		 "DATE_CLAIM_CLOSED": "10-25-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Burgart",
		 "CLAIMANT_FIRST_NAME": "Justin",
		 "CLAIM_NO": "2014-03-12-0408-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "03-10-2014 ",
		 "DATE_CLAIM_CLOSED": "12-05-2014 ",
		 "ACTUAL_RTW_DATE": "03-31-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Burge",
		 "CLAIMANT_FIRST_NAME": "Ronalyn",
		 "CLAIM_NO": "2022-02-01-0698-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "02-01-2022 ",
		 "DATE_CLAIM_CLOSED": "02-17-2022 ",
		 "ACTUAL_RTW_DATE": "02-15-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Burgner",
		 "CLAIMANT_FIRST_NAME": "Mark",
		 "CLAIM_NO": "2013-01-05-0636-VP-01",
		 "DURATION": 375,
		 "DISABILITY_START_DATE": "10-05-2012 ",
		 "DATE_CLAIM_CLOSED": "11-11-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Burgos",
		 "CLAIMANT_FIRST_NAME": "Sigfredo",
		 "CLAIM_NO": "2014-11-06-0342-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "10-30-2014 ",
		 "DATE_CLAIM_CLOSED": "12-05-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Burke",
		 "CLAIMANT_FIRST_NAME": "Stephanie",
		 "CLAIM_NO": "2022-09-22-0777-VP-01",
		 "DURATION": 129,
		 "DISABILITY_START_DATE": "10-24-2022 ",
		 "DATE_CLAIM_CLOSED": "01-18-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Burlaza",
		 "CLAIMANT_FIRST_NAME": "Eymard",
		 "CLAIM_NO": "2018-01-04-0502-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "12-11-2017 ",
		 "DATE_CLAIM_CLOSED": "01-22-2018 ",
		 "ACTUAL_RTW_DATE": "01-15-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Burley",
		 "CLAIMANT_FIRST_NAME": "Jackie",
		 "CLAIM_NO": "2015-06-08-0281-VP-01",
		 "DURATION": 25,
		 "DISABILITY_START_DATE": "06-18-2015 ",
		 "DATE_CLAIM_CLOSED": "09-07-2015 ",
		 "ACTUAL_RTW_DATE": "07-13-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Burley",
		 "CLAIMANT_FIRST_NAME": "Jackie",
		 "CLAIM_NO": "2014-11-20-0363-VP-01",
		 "DURATION": 98,
		 "DISABILITY_START_DATE": "12-01-2014 ",
		 "DATE_CLAIM_CLOSED": "03-16-2015 ",
		 "ACTUAL_RTW_DATE": "03-09-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Burley",
		 "CLAIMANT_FIRST_NAME": "Jackie",
		 "CLAIM_NO": "2013-01-05-0355-VP-01",
		 "DURATION": 165,
		 "DISABILITY_START_DATE": "11-29-2012 ",
		 "DATE_CLAIM_CLOSED": "07-12-2013 ",
		 "ACTUAL_RTW_DATE": "05-13-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Burney",
		 "CLAIMANT_FIRST_NAME": "Valerie",
		 "CLAIM_NO": "2019-06-18-0386-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "06-10-2019 ",
		 "DATE_CLAIM_CLOSED": "07-25-2019 ",
		 "ACTUAL_RTW_DATE": "07-22-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Burnham",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2019-03-18-0777-VP-01",
		 "DURATION": 102,
		 "DISABILITY_START_DATE": "04-22-2019 ",
		 "DATE_CLAIM_CLOSED": "03-27-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bushey",
		 "CLAIMANT_FIRST_NAME": "Linda",
		 "CLAIM_NO": "2013-04-18-0106-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "04-15-2013 ",
		 "DATE_CLAIM_CLOSED": "07-01-2013 ",
		 "ACTUAL_RTW_DATE": "05-06-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Bushey",
		 "CLAIMANT_FIRST_NAME": "Linda",
		 "CLAIM_NO": "2013-12-16-0196-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "12-13-2013 ",
		 "DATE_CLAIM_CLOSED": "04-02-2014 ",
		 "ACTUAL_RTW_DATE": "01-03-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Butani",
		 "CLAIMANT_FIRST_NAME": "Rajani",
		 "CLAIM_NO": "2018-05-25-0453-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "06-13-2018 ",
		 "DATE_CLAIM_CLOSED": "07-09-2018 ",
		 "ACTUAL_RTW_DATE": "06-28-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Butler",
		 "CLAIMANT_FIRST_NAME": "Vincent",
		 "CLAIM_NO": "2021-05-17-0432-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "05-18-2021 ",
		 "DATE_CLAIM_CLOSED": "06-23-2021 ",
		 "ACTUAL_RTW_DATE": "06-21-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Butler",
		 "CLAIMANT_FIRST_NAME": "Georgia",
		 "CLAIM_NO": "2013-01-05-0107-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "01-15-2013 ",
		 "DATE_CLAIM_CLOSED": "03-10-2013 ",
		 "ACTUAL_RTW_DATE": "03-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Butler",
		 "CLAIMANT_FIRST_NAME": "Jacqueline",
		 "CLAIM_NO": "2020-10-29-0514-VP-01",
		 "DURATION": 69,
		 "DISABILITY_START_DATE": "01-19-2021 ",
		 "DATE_CLAIM_CLOSED": "03-30-2021 ",
		 "ACTUAL_RTW_DATE": "03-29-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Butler",
		 "CLAIMANT_FIRST_NAME": "Nancy",
		 "CLAIM_NO": "2018-09-14-0204-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "10-03-2018 ",
		 "DATE_CLAIM_CLOSED": "12-11-2018 ",
		 "ACTUAL_RTW_DATE": "11-15-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Butler",
		 "CLAIMANT_FIRST_NAME": "Vincent",
		 "CLAIM_NO": "2022-01-04-1460-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "12-28-2021 ",
		 "DATE_CLAIM_CLOSED": "01-18-2022 ",
		 "ACTUAL_RTW_DATE": "01-05-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Buzina",
		 "CLAIMANT_FIRST_NAME": "Ksenia",
		 "CLAIM_NO": "2020-03-04-0796-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "03-30-2020 ",
		 "DATE_CLAIM_CLOSED": "05-14-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Byers",
		 "CLAIMANT_FIRST_NAME": "Dominique",
		 "CLAIM_NO": "2021-10-13-0284-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "10-08-2021 ",
		 "DATE_CLAIM_CLOSED": "11-19-2021 ",
		 "ACTUAL_RTW_DATE": "10-25-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Byers",
		 "CLAIMANT_FIRST_NAME": "Dominique",
		 "CLAIM_NO": "2022-01-26-1069-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "01-27-2022 ",
		 "DATE_CLAIM_CLOSED": "02-08-2022 ",
		 "ACTUAL_RTW_DATE": "02-07-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Byrd",
		 "CLAIMANT_FIRST_NAME": "Benita",
		 "CLAIM_NO": "2013-01-05-2404-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "05-17-2012 ",
		 "DATE_CLAIM_CLOSED": "10-18-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Byrne",
		 "CLAIMANT_FIRST_NAME": "Mark",
		 "CLAIM_NO": "2019-06-07-0653-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "06-05-2019 ",
		 "DATE_CLAIM_CLOSED": "07-09-2019 ",
		 "ACTUAL_RTW_DATE": "06-24-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Caballero",
		 "CLAIMANT_FIRST_NAME": "Troy",
		 "CLAIM_NO": "2013-09-12-0280-VP-01",
		 "DURATION": 103,
		 "DISABILITY_START_DATE": "08-28-2013 ",
		 "DATE_CLAIM_CLOSED": "01-07-2014 ",
		 "ACTUAL_RTW_DATE": "12-09-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Caballero",
		 "CLAIMANT_FIRST_NAME": "Troy",
		 "CLAIM_NO": "2014-06-19-0193-VP-01",
		 "DURATION": 1134,
		 "DISABILITY_START_DATE": "06-03-2014 ",
		 "DATE_CLAIM_CLOSED": "08-10-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Caballero",
		 "CLAIMANT_FIRST_NAME": "Troy",
		 "CLAIM_NO": "2013-05-21-0488-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "05-13-2013 ",
		 "DATE_CLAIM_CLOSED": "07-10-2013 ",
		 "ACTUAL_RTW_DATE": "05-29-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Caberto",
		 "CLAIMANT_FIRST_NAME": "Eduardo",
		 "CLAIM_NO": "2015-07-30-0341-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "07-21-2015 ",
		 "DATE_CLAIM_CLOSED": "02-02-2016 ",
		 "ACTUAL_RTW_DATE": "09-08-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cabrera",
		 "CLAIMANT_FIRST_NAME": "Eddy",
		 "CLAIM_NO": "2014-04-08-0156-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "05-13-2014 ",
		 "DATE_CLAIM_CLOSED": "07-29-2014 ",
		 "ACTUAL_RTW_DATE": "06-02-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cabrera",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2014-03-25-6043-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "03-19-2014 ",
		 "DATE_CLAIM_CLOSED": "05-20-2014 ",
		 "ACTUAL_RTW_DATE": "05-04-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cabrera",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2013-01-05-0010-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "12-28-2012 ",
		 "DATE_CLAIM_CLOSED": "05-16-2013 ",
		 "ACTUAL_RTW_DATE": "02-11-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cabrera",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2016-01-19-0555-VP-01",
		 "DURATION": 39,
		 "DISABILITY_START_DATE": "01-14-2016 ",
		 "DATE_CLAIM_CLOSED": "03-31-2016 ",
		 "ACTUAL_RTW_DATE": "02-22-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cabrera",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2013-09-17-0331-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "09-19-2013 ",
		 "DATE_CLAIM_CLOSED": "01-14-2014 ",
		 "ACTUAL_RTW_DATE": "10-21-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cabrera",
		 "CLAIMANT_FIRST_NAME": "Belinda",
		 "CLAIM_NO": "2023-01-05-0948-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "01-03-2023 ",
		 "DATE_CLAIM_CLOSED": "02-22-2023 ",
		 "ACTUAL_RTW_DATE": "01-11-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cabrera",
		 "CLAIMANT_FIRST_NAME": "Mireya",
		 "CLAIM_NO": "2018-02-19-0125-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "02-15-2018 ",
		 "DATE_CLAIM_CLOSED": "04-30-2018 ",
		 "ACTUAL_RTW_DATE": "03-19-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cabrera-Rivero",
		 "CLAIMANT_FIRST_NAME": "Jose",
		 "CLAIM_NO": "2022-12-16-0442-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "12-13-2022 ",
		 "DATE_CLAIM_CLOSED": "01-18-2023 ",
		 "ACTUAL_RTW_DATE": "01-11-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cabrito Ednilao",
		 "CLAIMANT_FIRST_NAME": "Gracelda",
		 "CLAIM_NO": "2020-10-07-0898-VP-01",
		 "DURATION": 343,
		 "DISABILITY_START_DATE": "10-05-2020 ",
		 "DATE_CLAIM_CLOSED": "09-15-2021 ",
		 "ACTUAL_RTW_DATE": "09-13-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Caceres",
		 "CLAIMANT_FIRST_NAME": "Louie",
		 "CLAIM_NO": "2022-06-21-0959-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "06-14-2022 ",
		 "DATE_CLAIM_CLOSED": "08-10-2022 ",
		 "ACTUAL_RTW_DATE": "08-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cadsap",
		 "CLAIMANT_FIRST_NAME": "Rosabella",
		 "CLAIM_NO": "2013-01-05-1783-VP-01",
		 "DURATION": 560,
		 "DISABILITY_START_DATE": "06-14-2012 ",
		 "DATE_CLAIM_CLOSED": "01-07-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cagatin",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2013-05-28-0405-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "05-28-2013 ",
		 "DATE_CLAIM_CLOSED": "09-10-2013 ",
		 "ACTUAL_RTW_DATE": "06-17-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cain",
		 "CLAIMANT_FIRST_NAME": "Cynthia",
		 "CLAIM_NO": "2013-01-05-0246-VP-01",
		 "DURATION": 122,
		 "DISABILITY_START_DATE": "11-30-2012 ",
		 "DATE_CLAIM_CLOSED": "04-03-2013 ",
		 "ACTUAL_RTW_DATE": "04-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Caldwell",
		 "CLAIMANT_FIRST_NAME": "Maggie",
		 "CLAIM_NO": "2013-03-21-0003-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "03-19-2013 ",
		 "DATE_CLAIM_CLOSED": "06-04-2013 ",
		 "ACTUAL_RTW_DATE": "05-06-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Calunod",
		 "CLAIMANT_FIRST_NAME": "Alteles",
		 "CLAIM_NO": "2018-01-23-0715-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "02-26-2018 ",
		 "DATE_CLAIM_CLOSED": "04-16-2018 ",
		 "ACTUAL_RTW_DATE": "04-09-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Camahort",
		 "CLAIMANT_FIRST_NAME": "Andre",
		 "CLAIM_NO": "2016-08-15-0392-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "08-25-2016 ",
		 "DATE_CLAIM_CLOSED": "11-03-2016 ",
		 "ACTUAL_RTW_DATE": "10-24-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Camarena",
		 "CLAIMANT_FIRST_NAME": "Desiree",
		 "CLAIM_NO": "2013-01-05-3577-VP-01",
		 "DURATION": 272,
		 "DISABILITY_START_DATE": "11-03-2011 ",
		 "DATE_CLAIM_CLOSED": "02-25-2013 ",
		 "ACTUAL_RTW_DATE": "08-01-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Camarena",
		 "CLAIMANT_FIRST_NAME": "Jose",
		 "CLAIM_NO": "2013-05-01-0314-VP-01",
		 "DURATION": 426,
		 "DISABILITY_START_DATE": "04-30-2013 ",
		 "DATE_CLAIM_CLOSED": "07-14-2014 ",
		 "ACTUAL_RTW_DATE": "06-30-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Camarillo",
		 "CLAIMANT_FIRST_NAME": "Gabriela",
		 "CLAIM_NO": "2013-09-17-0156-VP-01",
		 "DURATION": 408,
		 "DISABILITY_START_DATE": "08-29-2013 ",
		 "DATE_CLAIM_CLOSED": "10-14-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cambare",
		 "CLAIMANT_FIRST_NAME": "Ben",
		 "CLAIM_NO": "2017-03-07-0536-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "02-22-2017 ",
		 "DATE_CLAIM_CLOSED": "05-03-2017 ",
		 "ACTUAL_RTW_DATE": "03-20-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Campbell",
		 "CLAIMANT_FIRST_NAME": "Irene",
		 "CLAIM_NO": "2018-10-15-0712-VP-01",
		 "DURATION": 150,
		 "DISABILITY_START_DATE": "10-11-2018 ",
		 "DATE_CLAIM_CLOSED": "03-12-2019 ",
		 "ACTUAL_RTW_DATE": "03-10-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Campbell",
		 "CLAIMANT_FIRST_NAME": "Irene",
		 "CLAIM_NO": "2017-02-10-0233-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "02-08-2017 ",
		 "DATE_CLAIM_CLOSED": "03-10-2017 ",
		 "ACTUAL_RTW_DATE": "02-27-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Campos",
		 "CLAIMANT_FIRST_NAME": "Gabriela",
		 "CLAIM_NO": "2022-07-18-0794-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "07-18-2022 ",
		 "DATE_CLAIM_CLOSED": "08-10-2022 ",
		 "ACTUAL_RTW_DATE": "08-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Campos",
		 "CLAIMANT_FIRST_NAME": "Imelda",
		 "CLAIM_NO": "2020-10-06-0529-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "09-30-2020 ",
		 "DATE_CLAIM_CLOSED": "10-27-2020 ",
		 "ACTUAL_RTW_DATE": "10-10-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Campos",
		 "CLAIMANT_FIRST_NAME": "Imelda",
		 "CLAIM_NO": "2022-01-24-0227-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "01-18-2022 ",
		 "DATE_CLAIM_CLOSED": "02-01-2022 ",
		 "ACTUAL_RTW_DATE": "01-31-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Canale",
		 "CLAIMANT_FIRST_NAME": "Obdulia",
		 "CLAIM_NO": "2014-01-10-0064-VP-01",
		 "DURATION": 157,
		 "DISABILITY_START_DATE": "01-10-2014 ",
		 "DATE_CLAIM_CLOSED": "08-04-2014 ",
		 "ACTUAL_RTW_DATE": "06-16-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Canales",
		 "CLAIMANT_FIRST_NAME": "Jaime",
		 "CLAIM_NO": "2019-05-21-0498-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "05-20-2019 ",
		 "DATE_CLAIM_CLOSED": "06-11-2019 ",
		 "ACTUAL_RTW_DATE": "06-10-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Candaza",
		 "CLAIMANT_FIRST_NAME": "Sheila",
		 "CLAIM_NO": "2021-05-14-0392-VP-01",
		 "DURATION": 170,
		 "DISABILITY_START_DATE": "05-13-2021 ",
		 "DATE_CLAIM_CLOSED": "12-09-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Canizalez",
		 "CLAIMANT_FIRST_NAME": "Nancy",
		 "CLAIM_NO": "2019-10-21-0774-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "10-29-2019 ",
		 "DATE_CLAIM_CLOSED": "12-16-2019 ",
		 "ACTUAL_RTW_DATE": "11-26-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Canizalez",
		 "CLAIMANT_FIRST_NAME": "Nancy",
		 "CLAIM_NO": "2023-01-24-0895-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "01-30-2023 ",
		 "DATE_CLAIM_CLOSED": "03-17-2023 ",
		 "ACTUAL_RTW_DATE": "03-13-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cannon",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2015-06-05-0394-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "06-23-2015 ",
		 "DATE_CLAIM_CLOSED": "10-27-2015 ",
		 "ACTUAL_RTW_DATE": "07-06-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Canosa",
		 "CLAIMANT_FIRST_NAME": "Learsi",
		 "CLAIM_NO": "2022-01-07-0494-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "01-10-2022 ",
		 "DATE_CLAIM_CLOSED": "01-18-2022 ",
		 "ACTUAL_RTW_DATE": "01-17-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cao",
		 "CLAIMANT_FIRST_NAME": "Tuyet Le",
		 "CLAIM_NO": "2020-05-15-0646-VP-01",
		 "DURATION": 783,
		 "DISABILITY_START_DATE": "05-14-2020 ",
		 "DATE_CLAIM_CLOSED": "07-18-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cao",
		 "CLAIMANT_FIRST_NAME": "Tuyet Le",
		 "CLAIM_NO": "2019-03-04-0858-VP-01",
		 "DURATION": 161,
		 "DISABILITY_START_DATE": "02-28-2019 ",
		 "DATE_CLAIM_CLOSED": "09-30-2019 ",
		 "ACTUAL_RTW_DATE": "08-08-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cao",
		 "CLAIMANT_FIRST_NAME": "Tuyet Le",
		 "CLAIM_NO": "2021-08-12-0970-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "07-01-2021 ",
		 "DATE_CLAIM_CLOSED": "09-01-2021 ",
		 "ACTUAL_RTW_DATE": "07-20-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Caoile",
		 "CLAIMANT_FIRST_NAME": "Marilou",
		 "CLAIM_NO": "2022-07-19-0416-VP-01",
		 "DURATION": 166,
		 "DISABILITY_START_DATE": "07-13-2022 ",
		 "DATE_CLAIM_CLOSED": "01-17-2023 ",
		 "ACTUAL_RTW_DATE": "12-26-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Capistrano",
		 "CLAIMANT_FIRST_NAME": "Cristoffer",
		 "CLAIM_NO": "2023-02-21-0044-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "02-16-2023 ",
		 "DATE_CLAIM_CLOSED": "03-20-2023 ",
		 "ACTUAL_RTW_DATE": "02-27-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Capistrano",
		 "CLAIMANT_FIRST_NAME": "Cristoffer",
		 "CLAIM_NO": "2020-07-21-0588-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "07-15-2020 ",
		 "DATE_CLAIM_CLOSED": "09-09-2020 ",
		 "ACTUAL_RTW_DATE": "07-24-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Caracol",
		 "CLAIMANT_FIRST_NAME": "Nicole",
		 "CLAIM_NO": "2021-04-05-0666-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "04-01-2021 ",
		 "DATE_CLAIM_CLOSED": "04-12-2021 ",
		 "ACTUAL_RTW_DATE": "04-09-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carbajal-Ruiz",
		 "CLAIMANT_FIRST_NAME": "Angelica",
		 "CLAIM_NO": "2021-05-04-0339-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "05-11-2021 ",
		 "DATE_CLAIM_CLOSED": "07-07-2021 ",
		 "ACTUAL_RTW_DATE": "06-22-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cardema",
		 "CLAIMANT_FIRST_NAME": "Rollie",
		 "CLAIM_NO": "2021-02-22-0271-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "02-04-2021 ",
		 "DATE_CLAIM_CLOSED": "03-05-2021 ",
		 "ACTUAL_RTW_DATE": "03-03-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cardenas",
		 "CLAIMANT_FIRST_NAME": "Francisco",
		 "CLAIM_NO": "2023-03-06-1132-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "03-02-2023 ",
		 "DATE_CLAIM_CLOSED": "03-24-2023 ",
		 "ACTUAL_RTW_DATE": "03-20-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cardenas",
		 "CLAIMANT_FIRST_NAME": "Lorena",
		 "CLAIM_NO": "2013-03-20-0385-VP-01",
		 "DURATION": 240,
		 "DISABILITY_START_DATE": "03-18-2013 ",
		 "DATE_CLAIM_CLOSED": "12-09-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cardenas",
		 "CLAIMANT_FIRST_NAME": "Nila",
		 "CLAIM_NO": "2019-09-04-0693-VP-01",
		 "DURATION": 128,
		 "DISABILITY_START_DATE": "09-26-2019 ",
		 "DATE_CLAIM_CLOSED": "02-06-2020 ",
		 "ACTUAL_RTW_DATE": "02-01-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cardenas",
		 "CLAIMANT_FIRST_NAME": "Nila",
		 "CLAIM_NO": "2021-05-28-0544-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "06-01-2021 ",
		 "DATE_CLAIM_CLOSED": "07-29-2021 ",
		 "ACTUAL_RTW_DATE": "07-27-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cardona",
		 "CLAIMANT_FIRST_NAME": "Freddy",
		 "CLAIM_NO": "2019-07-08-0747-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "07-03-2019 ",
		 "DATE_CLAIM_CLOSED": "07-15-2019 ",
		 "ACTUAL_RTW_DATE": "07-12-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carlos",
		 "CLAIMANT_FIRST_NAME": "Catherine",
		 "CLAIM_NO": "2020-10-05-0336-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "09-25-2020 ",
		 "DATE_CLAIM_CLOSED": "10-12-2020 ",
		 "ACTUAL_RTW_DATE": "10-09-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carlos",
		 "CLAIMANT_FIRST_NAME": "Catherine",
		 "CLAIM_NO": "2014-11-13-0508-VP-01",
		 "DURATION": 6,
		 "DISABILITY_START_DATE": "11-11-2014 ",
		 "DATE_CLAIM_CLOSED": "12-01-2014 ",
		 "ACTUAL_RTW_DATE": "11-17-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carlos",
		 "CLAIMANT_FIRST_NAME": "Catherine",
		 "CLAIM_NO": "2021-05-28-0644-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "05-27-2021 ",
		 "DATE_CLAIM_CLOSED": "08-02-2021 ",
		 "ACTUAL_RTW_DATE": "06-28-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carlos",
		 "CLAIMANT_FIRST_NAME": "Catherine",
		 "CLAIM_NO": "2017-02-27-0570-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "02-21-2017 ",
		 "DATE_CLAIM_CLOSED": "03-22-2017 ",
		 "ACTUAL_RTW_DATE": "03-07-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carlson-Sypek",
		 "CLAIMANT_FIRST_NAME": "Ben",
		 "CLAIM_NO": "2019-06-17-0505-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "06-14-2019 ",
		 "DATE_CLAIM_CLOSED": "07-08-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Caroc Cipriano",
		 "CLAIMANT_FIRST_NAME": "Juvy",
		 "CLAIM_NO": "2013-10-14-0319-VP-01",
		 "DURATION": 298,
		 "DISABILITY_START_DATE": "07-25-2013 ",
		 "DATE_CLAIM_CLOSED": "06-16-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Caroc Cipriano",
		 "CLAIMANT_FIRST_NAME": "Juvy",
		 "CLAIM_NO": "2013-01-15-0297-VP-01",
		 "DURATION": 131,
		 "DISABILITY_START_DATE": "01-14-2013 ",
		 "DATE_CLAIM_CLOSED": "01-22-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carpenter",
		 "CLAIMANT_FIRST_NAME": "Scott",
		 "CLAIM_NO": "2013-01-05-0607-VP-01",
		 "DURATION": 109,
		 "DISABILITY_START_DATE": "10-15-2012 ",
		 "DATE_CLAIM_CLOSED": "06-02-2014 ",
		 "ACTUAL_RTW_DATE": "02-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carpenter",
		 "CLAIMANT_FIRST_NAME": "Peggy",
		 "CLAIM_NO": "2013-01-05-0461-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "10-15-2012 ",
		 "DATE_CLAIM_CLOSED": "01-15-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carrasco",
		 "CLAIMANT_FIRST_NAME": "Rodrigo",
		 "CLAIM_NO": "2014-01-21-0421-VP-01",
		 "DURATION": 106,
		 "DISABILITY_START_DATE": "02-03-2014 ",
		 "DATE_CLAIM_CLOSED": "06-30-2014 ",
		 "ACTUAL_RTW_DATE": "05-20-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carrasquillo",
		 "CLAIMANT_FIRST_NAME": "Gabriela",
		 "CLAIM_NO": "2016-02-11-0323-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "02-08-2016 ",
		 "DATE_CLAIM_CLOSED": "02-22-2016 ",
		 "ACTUAL_RTW_DATE": "02-15-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carrasquillo",
		 "CLAIMANT_FIRST_NAME": "Gabriela",
		 "CLAIM_NO": "2021-02-01-1039-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "01-29-2021 ",
		 "DATE_CLAIM_CLOSED": "02-19-2021 ",
		 "ACTUAL_RTW_DATE": "02-15-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carrico",
		 "CLAIMANT_FIRST_NAME": "Sharon",
		 "CLAIM_NO": "2017-02-07-0416-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "02-15-2017 ",
		 "DATE_CLAIM_CLOSED": "03-07-2017 ",
		 "ACTUAL_RTW_DATE": "03-06-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carrillo",
		 "CLAIMANT_FIRST_NAME": "Samantha",
		 "CLAIM_NO": "2021-10-25-0892-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "10-20-2021 ",
		 "DATE_CLAIM_CLOSED": "11-24-2021 ",
		 "ACTUAL_RTW_DATE": "11-22-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carrillo",
		 "CLAIMANT_FIRST_NAME": "Samantha",
		 "CLAIM_NO": "2018-12-13-0461-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "12-11-2018 ",
		 "DATE_CLAIM_CLOSED": "03-01-2019 ",
		 "ACTUAL_RTW_DATE": "01-21-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carrillo",
		 "CLAIMANT_FIRST_NAME": "Ricardo",
		 "CLAIM_NO": "2020-09-04-0189-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "09-04-2020 ",
		 "DATE_CLAIM_CLOSED": "10-21-2020 ",
		 "ACTUAL_RTW_DATE": "10-19-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carrington",
		 "CLAIMANT_FIRST_NAME": "Darla",
		 "CLAIM_NO": "2014-05-02-0173-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "04-28-2014 ",
		 "DATE_CLAIM_CLOSED": "12-15-2014 ",
		 "ACTUAL_RTW_DATE": "05-19-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carrington",
		 "CLAIMANT_FIRST_NAME": "Darla",
		 "CLAIM_NO": "2014-10-07-0494-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "10-08-2014 ",
		 "DATE_CLAIM_CLOSED": "11-06-2014 ",
		 "ACTUAL_RTW_DATE": "11-06-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carroll",
		 "CLAIMANT_FIRST_NAME": "Donna",
		 "CLAIM_NO": "2021-12-27-1595-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "01-14-2022 ",
		 "DATE_CLAIM_CLOSED": "03-14-2022 ",
		 "ACTUAL_RTW_DATE": "03-11-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carroll",
		 "CLAIMANT_FIRST_NAME": "Danielle",
		 "CLAIM_NO": "2013-05-20-0458-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "06-13-2013 ",
		 "DATE_CLAIM_CLOSED": "08-26-2013 ",
		 "ACTUAL_RTW_DATE": "08-23-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carron",
		 "CLAIMANT_FIRST_NAME": "Clare",
		 "CLAIM_NO": "2015-10-07-0511-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "10-19-2015 ",
		 "DATE_CLAIM_CLOSED": "12-02-2015 ",
		 "ACTUAL_RTW_DATE": "11-30-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carson",
		 "CLAIMANT_FIRST_NAME": "Lynne",
		 "CLAIM_NO": "2018-03-07-0332-VP-01",
		 "DURATION": 23,
		 "DISABILITY_START_DATE": "03-06-2018 ",
		 "DATE_CLAIM_CLOSED": "06-12-2018 ",
		 "ACTUAL_RTW_DATE": "03-29-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carson",
		 "CLAIMANT_FIRST_NAME": "Lynne",
		 "CLAIM_NO": "2020-01-20-0369-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "02-25-2020 ",
		 "DATE_CLAIM_CLOSED": "03-18-2020 ",
		 "ACTUAL_RTW_DATE": "03-17-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carter",
		 "CLAIMANT_FIRST_NAME": "Tsutomu",
		 "CLAIM_NO": "2020-09-21-0184-VP-01",
		 "DURATION": 69,
		 "DISABILITY_START_DATE": "09-15-2020 ",
		 "DATE_CLAIM_CLOSED": "11-24-2020 ",
		 "ACTUAL_RTW_DATE": "11-23-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carter",
		 "CLAIMANT_FIRST_NAME": "Melanie",
		 "CLAIM_NO": "2014-11-06-0312-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "11-13-2014 ",
		 "DATE_CLAIM_CLOSED": "12-29-2014 ",
		 "ACTUAL_RTW_DATE": "12-26-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carter",
		 "CLAIMANT_FIRST_NAME": "Villa",
		 "CLAIM_NO": "2018-01-28-0003-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "02-20-2018 ",
		 "DATE_CLAIM_CLOSED": "04-04-2018 ",
		 "ACTUAL_RTW_DATE": "04-02-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carver",
		 "CLAIMANT_FIRST_NAME": "Christine",
		 "CLAIM_NO": "2014-02-03-0361-VP-01",
		 "DURATION": 30,
		 "DISABILITY_START_DATE": "01-27-2014 ",
		 "DATE_CLAIM_CLOSED": "05-01-2014 ",
		 "ACTUAL_RTW_DATE": "02-26-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carver",
		 "CLAIMANT_FIRST_NAME": "Christine",
		 "CLAIM_NO": "2015-03-24-0465-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "03-27-2015 ",
		 "DATE_CLAIM_CLOSED": "04-23-2015 ",
		 "ACTUAL_RTW_DATE": "04-13-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Carver",
		 "CLAIMANT_FIRST_NAME": "Christine",
		 "CLAIM_NO": "2017-02-02-0434-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "01-31-2017 ",
		 "DATE_CLAIM_CLOSED": "02-27-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Casaje",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2016-05-09-0239-VP-01",
		 "DURATION": 184,
		 "DISABILITY_START_DATE": "05-02-2016 ",
		 "DATE_CLAIM_CLOSED": "06-20-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Case",
		 "CLAIMANT_FIRST_NAME": "Mary",
		 "CLAIM_NO": "2019-01-25-0636-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "01-23-2019 ",
		 "DATE_CLAIM_CLOSED": "04-05-2019 ",
		 "ACTUAL_RTW_DATE": "02-11-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cason",
		 "CLAIMANT_FIRST_NAME": "Terence",
		 "CLAIM_NO": "2021-09-27-0563-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "09-20-2021 ",
		 "DATE_CLAIM_CLOSED": "11-16-2021 ",
		 "ACTUAL_RTW_DATE": "10-04-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cason",
		 "CLAIMANT_FIRST_NAME": "Terence",
		 "CLAIM_NO": "2020-07-06-0320-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "07-06-2020 ",
		 "DATE_CLAIM_CLOSED": "11-16-2020 ",
		 "ACTUAL_RTW_DATE": "10-05-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cason",
		 "CLAIMANT_FIRST_NAME": "Terence",
		 "CLAIM_NO": "2022-10-25-0928-VP-01",
		 "DURATION": 115,
		 "DISABILITY_START_DATE": "10-31-2022 ",
		 "DATE_CLAIM_CLOSED": "02-24-2023 ",
		 "ACTUAL_RTW_DATE": "02-23-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Casserly",
		 "CLAIMANT_FIRST_NAME": "Christopher",
		 "CLAIM_NO": "2019-09-17-0464-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "10-18-2019 ",
		 "DATE_CLAIM_CLOSED": "11-20-2019 ",
		 "ACTUAL_RTW_DATE": "11-18-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Casserly",
		 "CLAIMANT_FIRST_NAME": "Christopher",
		 "CLAIM_NO": "2022-03-02-1056-VP-01",
		 "DURATION": 53,
		 "DISABILITY_START_DATE": "04-29-2022 ",
		 "DATE_CLAIM_CLOSED": "06-23-2022 ",
		 "ACTUAL_RTW_DATE": "06-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Castellanos",
		 "CLAIMANT_FIRST_NAME": "Ana",
		 "CLAIM_NO": "2017-03-06-0347-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "03-02-2017 ",
		 "DATE_CLAIM_CLOSED": "05-09-2017 ",
		 "ACTUAL_RTW_DATE": "03-13-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Castillo",
		 "CLAIMANT_FIRST_NAME": "Pia",
		 "CLAIM_NO": "2016-06-29-0103-VP-01",
		 "DURATION": 74,
		 "DISABILITY_START_DATE": "06-24-2016 ",
		 "DATE_CLAIM_CLOSED": "10-03-2016 ",
		 "ACTUAL_RTW_DATE": "09-06-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Castillo",
		 "CLAIMANT_FIRST_NAME": "Debbie",
		 "CLAIM_NO": "2014-01-09-0181-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "01-06-2014 ",
		 "DATE_CLAIM_CLOSED": "02-24-2014 ",
		 "ACTUAL_RTW_DATE": "01-23-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Castillo",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2018-12-03-0476-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "11-30-2018 ",
		 "DATE_CLAIM_CLOSED": "12-27-2018 ",
		 "ACTUAL_RTW_DATE": "12-10-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Castillo",
		 "CLAIMANT_FIRST_NAME": "Bradley",
		 "CLAIM_NO": "2018-11-29-0504-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "11-27-2018 ",
		 "DATE_CLAIM_CLOSED": "01-28-2019 ",
		 "ACTUAL_RTW_DATE": "01-25-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Castillo",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2021-03-31-0690-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "03-24-2021 ",
		 "DATE_CLAIM_CLOSED": "05-14-2021 ",
		 "ACTUAL_RTW_DATE": "04-05-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Castillo",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2014-05-09-0368-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "05-07-2014 ",
		 "DATE_CLAIM_CLOSED": "10-01-2014 ",
		 "ACTUAL_RTW_DATE": "05-15-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Castillo",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2015-06-05-0129-VP-01",
		 "DURATION": 67,
		 "DISABILITY_START_DATE": "06-01-2015 ",
		 "DATE_CLAIM_CLOSED": "08-11-2015 ",
		 "ACTUAL_RTW_DATE": "08-07-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Castro",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2020-07-30-0016-VP-01",
		 "DURATION": 36,
		 "DISABILITY_START_DATE": "07-27-2020 ",
		 "DATE_CLAIM_CLOSED": "09-02-2020 ",
		 "ACTUAL_RTW_DATE": "09-01-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Castro",
		 "CLAIMANT_FIRST_NAME": "Hilda",
		 "CLAIM_NO": "2015-06-08-0367-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "06-05-2015 ",
		 "DATE_CLAIM_CLOSED": "08-03-2015 ",
		 "ACTUAL_RTW_DATE": "07-06-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Castro",
		 "CLAIMANT_FIRST_NAME": "Angelito",
		 "CLAIM_NO": "2013-04-09-0344-VP-01",
		 "DURATION": 92,
		 "DISABILITY_START_DATE": "05-09-2013 ",
		 "DATE_CLAIM_CLOSED": "08-13-2013 ",
		 "ACTUAL_RTW_DATE": "08-09-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Castro",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2013-01-05-0911-VP-01",
		 "DURATION": 65,
		 "DISABILITY_START_DATE": "10-15-2012 ",
		 "DATE_CLAIM_CLOSED": "07-29-2014 ",
		 "ACTUAL_RTW_DATE": "12-19-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Castro",
		 "CLAIMANT_FIRST_NAME": "Robert",
		 "CLAIM_NO": "2021-04-06-0451-VP-01",
		 "DURATION": 83,
		 "DISABILITY_START_DATE": "04-06-2021 ",
		 "DATE_CLAIM_CLOSED": "07-06-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Castro",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2014-12-31-0321-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "02-17-2015 ",
		 "DATE_CLAIM_CLOSED": "04-17-2015 ",
		 "ACTUAL_RTW_DATE": "04-06-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Castro",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2017-01-09-0607-VP-01",
		 "DURATION": 52,
		 "DISABILITY_START_DATE": "01-03-2017 ",
		 "DATE_CLAIM_CLOSED": "03-16-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Castro",
		 "CLAIMANT_FIRST_NAME": "Hilda",
		 "CLAIM_NO": "2021-01-25-1077-VP-01",
		 "DURATION": 82,
		 "DISABILITY_START_DATE": "01-13-2021 ",
		 "DATE_CLAIM_CLOSED": "04-06-2021 ",
		 "ACTUAL_RTW_DATE": "04-05-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Castro",
		 "CLAIMANT_FIRST_NAME": "Robert",
		 "CLAIM_NO": "2021-02-25-0385-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "02-25-2021 ",
		 "DATE_CLAIM_CLOSED": "03-09-2021 ",
		 "ACTUAL_RTW_DATE": "03-08-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Castro",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2018-06-05-0394-VP-01",
		 "DURATION": 184,
		 "DISABILITY_START_DATE": "06-04-2018 ",
		 "DATE_CLAIM_CLOSED": "12-12-2018 ",
		 "ACTUAL_RTW_DATE": "12-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Castro",
		 "CLAIMANT_FIRST_NAME": "Catherine",
		 "CLAIM_NO": "2021-01-12-0026-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "01-12-2021 ",
		 "DATE_CLAIM_CLOSED": "01-13-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Castro",
		 "CLAIMANT_FIRST_NAME": "Hilda",
		 "CLAIM_NO": "2013-04-25-0101-VP-01",
		 "DURATION": 282,
		 "DISABILITY_START_DATE": "10-16-2012 ",
		 "DATE_CLAIM_CLOSED": "10-01-2013 ",
		 "ACTUAL_RTW_DATE": "07-25-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Castro",
		 "CLAIMANT_FIRST_NAME": "Bibiana",
		 "CLAIM_NO": "2013-02-05-0466-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "02-04-2013 ",
		 "DATE_CLAIM_CLOSED": "05-07-2013 ",
		 "ACTUAL_RTW_DATE": "02-11-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Castro",
		 "CLAIMANT_FIRST_NAME": "Omar",
		 "CLAIM_NO": "2017-11-08-0519-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "11-07-2017 ",
		 "DATE_CLAIM_CLOSED": "12-04-2017 ",
		 "ACTUAL_RTW_DATE": "12-01-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Castro",
		 "CLAIMANT_FIRST_NAME": "Ruth",
		 "CLAIM_NO": "2016-03-14-0452-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "03-14-2016 ",
		 "DATE_CLAIM_CLOSED": "05-10-2016 ",
		 "ACTUAL_RTW_DATE": "05-09-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Castro Quirarte",
		 "CLAIMANT_FIRST_NAME": "Alba",
		 "CLAIM_NO": "2019-07-31-0553-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "08-16-2019 ",
		 "DATE_CLAIM_CLOSED": "09-17-2019 ",
		 "ACTUAL_RTW_DATE": "08-31-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Catap",
		 "CLAIMANT_FIRST_NAME": "Ghee",
		 "CLAIM_NO": "2018-01-29-0640-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "01-16-2018 ",
		 "DATE_CLAIM_CLOSED": "03-12-2018 ",
		 "ACTUAL_RTW_DATE": "02-19-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Catbagan",
		 "CLAIMANT_FIRST_NAME": "Philip",
		 "CLAIM_NO": "2021-05-05-0854-VP-01",
		 "DURATION": 67,
		 "DISABILITY_START_DATE": "04-26-2021 ",
		 "DATE_CLAIM_CLOSED": "07-07-2021 ",
		 "ACTUAL_RTW_DATE": "07-02-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cath",
		 "CLAIMANT_FIRST_NAME": "Shannon",
		 "CLAIM_NO": "2013-02-21-0419-VP-01",
		 "DURATION": 61,
		 "DISABILITY_START_DATE": "03-01-2013 ",
		 "DATE_CLAIM_CLOSED": "08-08-2013 ",
		 "ACTUAL_RTW_DATE": "05-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Catimbang",
		 "CLAIMANT_FIRST_NAME": "Lauro",
		 "CLAIM_NO": "2019-01-18-0302-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "01-07-2019 ",
		 "DATE_CLAIM_CLOSED": "02-27-2019 ",
		 "ACTUAL_RTW_DATE": "01-19-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Catimbang",
		 "CLAIMANT_FIRST_NAME": "Lauro",
		 "CLAIM_NO": "2020-03-13-0367-VP-01",
		 "DURATION": 275,
		 "DISABILITY_START_DATE": "03-12-2020 ",
		 "DATE_CLAIM_CLOSED": "01-13-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cavor",
		 "CLAIMANT_FIRST_NAME": "Ingrid",
		 "CLAIM_NO": "2013-09-17-0495-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "09-19-2013 ",
		 "DATE_CLAIM_CLOSED": "01-09-2014 ",
		 "ACTUAL_RTW_DATE": "10-07-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cayabyab",
		 "CLAIMANT_FIRST_NAME": "Sharon Mae",
		 "CLAIM_NO": "2019-03-02-0002-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "03-05-2019 ",
		 "DATE_CLAIM_CLOSED": "04-25-2019 ",
		 "ACTUAL_RTW_DATE": "04-16-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cayas",
		 "CLAIMANT_FIRST_NAME": "Joel",
		 "CLAIM_NO": "2021-11-09-0767-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "11-08-2021 ",
		 "DATE_CLAIM_CLOSED": "12-14-2021 ",
		 "ACTUAL_RTW_DATE": "12-13-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ceccato",
		 "CLAIMANT_FIRST_NAME": "Karen",
		 "CLAIM_NO": "2014-11-11-0551-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "12-01-2014 ",
		 "DATE_CLAIM_CLOSED": "01-20-2015 ",
		 "ACTUAL_RTW_DATE": "01-13-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ceja",
		 "CLAIMANT_FIRST_NAME": "Anthony",
		 "CLAIM_NO": "2021-06-29-0581-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "06-29-2021 ",
		 "DATE_CLAIM_CLOSED": "07-29-2021 ",
		 "ACTUAL_RTW_DATE": "07-26-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ceja",
		 "CLAIMANT_FIRST_NAME": "Iracema",
		 "CLAIM_NO": "2013-01-05-0175-VP-01",
		 "DURATION": 428,
		 "DISABILITY_START_DATE": "12-06-2012 ",
		 "DATE_CLAIM_CLOSED": "03-26-2014 ",
		 "ACTUAL_RTW_DATE": "02-07-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Celani",
		 "CLAIMANT_FIRST_NAME": "Michael",
		 "CLAIM_NO": "2020-07-31-0528-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "08-24-2020 ",
		 "DATE_CLAIM_CLOSED": "10-05-2020 ",
		 "ACTUAL_RTW_DATE": "09-21-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Celaya",
		 "CLAIMANT_FIRST_NAME": "Celestina",
		 "CLAIM_NO": "2013-08-24-0002-VP-01",
		 "DURATION": 109,
		 "DISABILITY_START_DATE": "08-23-2013 ",
		 "DATE_CLAIM_CLOSED": "12-16-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Celaya",
		 "CLAIMANT_FIRST_NAME": "Celestina",
		 "CLAIM_NO": "2013-02-21-0185-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "02-22-2013 ",
		 "DATE_CLAIM_CLOSED": "08-13-2013 ",
		 "ACTUAL_RTW_DATE": "03-05-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Celaya Orozco",
		 "CLAIMANT_FIRST_NAME": "Carolina",
		 "CLAIM_NO": "2013-12-30-0420-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "12-30-2013 ",
		 "DATE_CLAIM_CLOSED": "03-19-2014 ",
		 "ACTUAL_RTW_DATE": "01-13-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cen",
		 "CLAIMANT_FIRST_NAME": "Qi",
		 "CLAIM_NO": "2017-01-09-0574-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "12-28-2016 ",
		 "DATE_CLAIM_CLOSED": "02-01-2017 ",
		 "ACTUAL_RTW_DATE": "01-30-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cerina",
		 "CLAIMANT_FIRST_NAME": "Rowel",
		 "CLAIM_NO": "2019-01-18-0671-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "01-15-2019 ",
		 "DATE_CLAIM_CLOSED": "03-08-2019 ",
		 "ACTUAL_RTW_DATE": "02-25-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cerina",
		 "CLAIMANT_FIRST_NAME": "Michael Tan Cardoso",
		 "CLAIM_NO": "2022-05-11-0921-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "05-16-2022 ",
		 "DATE_CLAIM_CLOSED": "07-20-2022 ",
		 "ACTUAL_RTW_DATE": "07-11-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cerina",
		 "CLAIMANT_FIRST_NAME": "Michael Tan Cardoso",
		 "CLAIM_NO": "2022-01-12-1059-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "01-06-2022 ",
		 "DATE_CLAIM_CLOSED": "01-26-2022 ",
		 "ACTUAL_RTW_DATE": "01-24-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cervantes",
		 "CLAIMANT_FIRST_NAME": "Liz",
		 "CLAIM_NO": "2013-01-09-0461-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "01-07-2013 ",
		 "DATE_CLAIM_CLOSED": "06-24-2013 ",
		 "ACTUAL_RTW_DATE": "01-14-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cervantes",
		 "CLAIMANT_FIRST_NAME": "Liz",
		 "CLAIM_NO": "2015-07-21-0228-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "07-13-2015 ",
		 "DATE_CLAIM_CLOSED": "10-02-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cervantes",
		 "CLAIMANT_FIRST_NAME": "Claudia",
		 "CLAIM_NO": "2022-03-08-0551-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "03-18-2022 ",
		 "DATE_CLAIM_CLOSED": "05-03-2022 ",
		 "ACTUAL_RTW_DATE": "05-02-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cervantes",
		 "CLAIMANT_FIRST_NAME": "Liz",
		 "CLAIM_NO": "2013-06-17-0280-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "06-12-2013 ",
		 "DATE_CLAIM_CLOSED": "09-13-2013 ",
		 "ACTUAL_RTW_DATE": "07-22-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cervantes",
		 "CLAIMANT_FIRST_NAME": "Kenneth",
		 "CLAIM_NO": "2013-01-08-0145-VP-01",
		 "DURATION": 30,
		 "DISABILITY_START_DATE": "01-02-2013 ",
		 "DATE_CLAIM_CLOSED": "05-15-2013 ",
		 "ACTUAL_RTW_DATE": "02-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cervantes",
		 "CLAIMANT_FIRST_NAME": "Kenneth",
		 "CLAIM_NO": "2013-05-24-0123-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "05-01-2013 ",
		 "DATE_CLAIM_CLOSED": "08-23-2013 ",
		 "ACTUAL_RTW_DATE": "05-29-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cevallos",
		 "CLAIMANT_FIRST_NAME": "Jacqueline",
		 "CLAIM_NO": "2013-01-05-0742-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "11-30-2012 ",
		 "DATE_CLAIM_CLOSED": "10-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chadha",
		 "CLAIMANT_FIRST_NAME": "Ajitinder",
		 "CLAIM_NO": "2013-01-05-6118-VP-01",
		 "DURATION": 1104,
		 "DISABILITY_START_DATE": "02-21-2011 ",
		 "DATE_CLAIM_CLOSED": "03-11-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chaidez",
		 "CLAIMANT_FIRST_NAME": "Celeste",
		 "CLAIM_NO": "2021-11-19-0711-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "11-17-2021 ",
		 "DATE_CLAIM_CLOSED": "01-04-2022 ",
		 "ACTUAL_RTW_DATE": "12-06-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chairez",
		 "CLAIMANT_FIRST_NAME": "Alma",
		 "CLAIM_NO": "2013-02-05-0021-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "02-26-2013 ",
		 "DATE_CLAIM_CLOSED": "05-16-2013 ",
		 "ACTUAL_RTW_DATE": "03-12-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chaitin",
		 "CLAIMANT_FIRST_NAME": "Sara Ashley",
		 "CLAIM_NO": "2018-04-03-0036-VP-01",
		 "DURATION": 113,
		 "DISABILITY_START_DATE": "04-02-2018 ",
		 "DATE_CLAIM_CLOSED": "07-26-2018 ",
		 "ACTUAL_RTW_DATE": "07-24-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chaitin",
		 "CLAIMANT_FIRST_NAME": "Sara Ashley",
		 "CLAIM_NO": "2018-10-24-0288-VP-01",
		 "DURATION": 128,
		 "DISABILITY_START_DATE": "10-24-2018 ",
		 "DATE_CLAIM_CLOSED": "02-01-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chak",
		 "CLAIMANT_FIRST_NAME": "Sei-Yuen",
		 "CLAIM_NO": "2014-03-21-0110-VP-01",
		 "DURATION": 108,
		 "DISABILITY_START_DATE": "03-21-2014 ",
		 "DATE_CLAIM_CLOSED": "07-21-2014 ",
		 "ACTUAL_RTW_DATE": "07-07-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chally",
		 "CLAIMANT_FIRST_NAME": "Cynthia",
		 "CLAIM_NO": "2013-01-05-0517-VP-01",
		 "DURATION": 223,
		 "DISABILITY_START_DATE": "10-23-2012 ",
		 "DATE_CLAIM_CLOSED": "06-23-2013 ",
		 "ACTUAL_RTW_DATE": "06-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chally",
		 "CLAIMANT_FIRST_NAME": "Cynthia",
		 "CLAIM_NO": "2014-05-14-0339-VP-01",
		 "DURATION": 207,
		 "DISABILITY_START_DATE": "05-12-2014 ",
		 "DATE_CLAIM_CLOSED": "12-15-2014 ",
		 "ACTUAL_RTW_DATE": "12-05-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chambers",
		 "CLAIMANT_FIRST_NAME": "Dean",
		 "CLAIM_NO": "2013-04-05-0139-VP-01",
		 "DURATION": 0,
		 "DISABILITY_START_DATE": "03-23-2013 ",
		 "DATE_CLAIM_CLOSED": "06-07-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Champagne",
		 "CLAIMANT_FIRST_NAME": "Megan",
		 "CLAIM_NO": "2013-01-05-0583-VP-01",
		 "DURATION": 52,
		 "DISABILITY_START_DATE": "11-16-2012 ",
		 "DATE_CLAIM_CLOSED": "02-09-2013 ",
		 "ACTUAL_RTW_DATE": "01-07-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Champagne",
		 "CLAIMANT_FIRST_NAME": "Megan",
		 "CLAIM_NO": "2014-03-20-0482-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "04-11-2014 ",
		 "DATE_CLAIM_CLOSED": "08-08-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chan",
		 "CLAIMANT_FIRST_NAME": "Eva",
		 "CLAIM_NO": "2013-12-21-0035-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "12-17-2013 ",
		 "DATE_CLAIM_CLOSED": "03-18-2014 ",
		 "ACTUAL_RTW_DATE": "01-06-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chan",
		 "CLAIMANT_FIRST_NAME": "Celine",
		 "CLAIM_NO": "2020-09-24-0386-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "09-25-2020 ",
		 "DATE_CLAIM_CLOSED": "10-19-2020 ",
		 "ACTUAL_RTW_DATE": "10-08-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chan",
		 "CLAIMANT_FIRST_NAME": "Vince",
		 "CLAIM_NO": "2020-07-15-0518-VP-01",
		 "DURATION": 66,
		 "DISABILITY_START_DATE": "07-10-2020 ",
		 "DATE_CLAIM_CLOSED": "09-17-2020 ",
		 "ACTUAL_RTW_DATE": "09-14-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chan",
		 "CLAIMANT_FIRST_NAME": "Lily",
		 "CLAIM_NO": "2013-04-24-0058-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "04-25-2013 ",
		 "DATE_CLAIM_CLOSED": "06-26-2013 ",
		 "ACTUAL_RTW_DATE": "06-10-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chandler",
		 "CLAIMANT_FIRST_NAME": "Christopher",
		 "CLAIM_NO": "2018-08-23-0608-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "09-20-2018 ",
		 "DATE_CLAIM_CLOSED": "10-09-2018 ",
		 "ACTUAL_RTW_DATE": "10-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chandrasekhar",
		 "CLAIMANT_FIRST_NAME": "Shreya",
		 "CLAIM_NO": "2021-03-05-0622-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "05-05-2021 ",
		 "DATE_CLAIM_CLOSED": "06-17-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chang",
		 "CLAIMANT_FIRST_NAME": "Jeong Sun",
		 "CLAIM_NO": "2022-10-21-0379-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "09-26-2022 ",
		 "DATE_CLAIM_CLOSED": "12-06-2022 ",
		 "ACTUAL_RTW_DATE": "11-28-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chapman",
		 "CLAIMANT_FIRST_NAME": "Steven",
		 "CLAIM_NO": "2016-11-02-0553-VP-01",
		 "DURATION": 126,
		 "DISABILITY_START_DATE": "10-31-2016 ",
		 "DATE_CLAIM_CLOSED": "03-07-2017 ",
		 "ACTUAL_RTW_DATE": "03-06-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chapmen",
		 "CLAIMANT_FIRST_NAME": "Chhaorath",
		 "CLAIM_NO": "2022-06-07-0886-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "05-31-2022 ",
		 "DATE_CLAIM_CLOSED": "06-29-2022 ",
		 "ACTUAL_RTW_DATE": "06-24-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chartrand",
		 "CLAIMANT_FIRST_NAME": "Mark",
		 "CLAIM_NO": "2016-01-25-0167-VP-01",
		 "DURATION": 85,
		 "DISABILITY_START_DATE": "01-21-2016 ",
		 "DATE_CLAIM_CLOSED": "04-21-2016 ",
		 "ACTUAL_RTW_DATE": "04-15-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chase-Jones",
		 "CLAIMANT_FIRST_NAME": "Sharon",
		 "CLAIM_NO": "2014-04-18-0125-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "04-14-2014 ",
		 "DATE_CLAIM_CLOSED": "08-26-2014 ",
		 "ACTUAL_RTW_DATE": "07-14-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chattopadhyay",
		 "CLAIMANT_FIRST_NAME": "Mohar",
		 "CLAIM_NO": "2019-12-19-0009-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "12-30-2019 ",
		 "DATE_CLAIM_CLOSED": "02-24-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chaudhry",
		 "CLAIMANT_FIRST_NAME": "Fiza",
		 "CLAIM_NO": "2016-12-02-0089-VP-01",
		 "DURATION": 67,
		 "DISABILITY_START_DATE": "01-03-2017 ",
		 "DATE_CLAIM_CLOSED": "03-16-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chavarria",
		 "CLAIMANT_FIRST_NAME": "Chris",
		 "CLAIM_NO": "2013-01-05-0506-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "11-12-2012 ",
		 "DATE_CLAIM_CLOSED": "02-26-2013 ",
		 "ACTUAL_RTW_DATE": "12-10-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chaverry",
		 "CLAIMANT_FIRST_NAME": "Ines",
		 "CLAIM_NO": "2021-02-18-0867-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "03-26-2021 ",
		 "DATE_CLAIM_CLOSED": "08-02-2021 ",
		 "ACTUAL_RTW_DATE": "05-13-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chavez",
		 "CLAIMANT_FIRST_NAME": "Monica",
		 "CLAIM_NO": "2019-03-08-0463-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "03-05-2019 ",
		 "DATE_CLAIM_CLOSED": "04-17-2019 ",
		 "ACTUAL_RTW_DATE": "03-25-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chavez",
		 "CLAIMANT_FIRST_NAME": "Monica",
		 "CLAIM_NO": "2017-09-18-0541-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "09-13-2017 ",
		 "DATE_CLAIM_CLOSED": "06-12-2018 ",
		 "ACTUAL_RTW_DATE": "10-04-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chavez",
		 "CLAIMANT_FIRST_NAME": "Felisa",
		 "CLAIM_NO": "2021-01-05-1032-VP-01",
		 "DURATION": 268,
		 "DISABILITY_START_DATE": "01-04-2021 ",
		 "DATE_CLAIM_CLOSED": "10-19-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chavez",
		 "CLAIMANT_FIRST_NAME": "Princesa",
		 "CLAIM_NO": "2020-10-26-0941-VP-01",
		 "DURATION": 76,
		 "DISABILITY_START_DATE": "11-30-2020 ",
		 "DATE_CLAIM_CLOSED": "02-13-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chavez",
		 "CLAIMANT_FIRST_NAME": "Sylvia",
		 "CLAIM_NO": "2021-07-09-0762-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "07-29-2021 ",
		 "DATE_CLAIM_CLOSED": "09-02-2021 ",
		 "ACTUAL_RTW_DATE": "08-30-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chavez",
		 "CLAIMANT_FIRST_NAME": "Monica",
		 "CLAIM_NO": "2017-08-24-0331-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "08-21-2017 ",
		 "DATE_CLAIM_CLOSED": "04-04-2018 ",
		 "ACTUAL_RTW_DATE": "09-06-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chavez",
		 "CLAIMANT_FIRST_NAME": "Sylvia",
		 "CLAIM_NO": "2018-10-01-0024-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "10-01-2018 ",
		 "DATE_CLAIM_CLOSED": "10-29-2018 ",
		 "ACTUAL_RTW_DATE": "10-18-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chavez",
		 "CLAIMANT_FIRST_NAME": "Monica",
		 "CLAIM_NO": "2021-01-25-0682-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "01-25-2021 ",
		 "DATE_CLAIM_CLOSED": "02-09-2021 ",
		 "ACTUAL_RTW_DATE": "02-03-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chavez",
		 "CLAIMANT_FIRST_NAME": "Carmen",
		 "CLAIM_NO": "2019-03-30-0495-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "04-25-2019 ",
		 "DATE_CLAIM_CLOSED": "06-04-2019 ",
		 "ACTUAL_RTW_DATE": "05-23-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chavez",
		 "CLAIMANT_FIRST_NAME": "Princesa",
		 "CLAIM_NO": "2022-11-04-0949-VP-01",
		 "DURATION": 139,
		 "DISABILITY_START_DATE": "12-30-2022 ",
		 "DATE_CLAIM_CLOSED": "04-05-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chavez",
		 "CLAIMANT_FIRST_NAME": "Ramona",
		 "CLAIM_NO": "2019-03-07-0003-VP-01",
		 "DURATION": 87,
		 "DISABILITY_START_DATE": "03-22-2019 ",
		 "DATE_CLAIM_CLOSED": "06-24-2019 ",
		 "ACTUAL_RTW_DATE": "06-17-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chavez Pelaez",
		 "CLAIMANT_FIRST_NAME": "Hilda",
		 "CLAIM_NO": "2019-01-15-0780-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "01-03-2019 ",
		 "DATE_CLAIM_CLOSED": "01-28-2019 ",
		 "ACTUAL_RTW_DATE": "01-22-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chavez Pelaez",
		 "CLAIMANT_FIRST_NAME": "Hilda",
		 "CLAIM_NO": "2018-05-01-0390-VP-01",
		 "DURATION": 5,
		 "DISABILITY_START_DATE": "05-02-2018 ",
		 "DATE_CLAIM_CLOSED": "05-14-2018 ",
		 "ACTUAL_RTW_DATE": "05-07-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chavez Pelaez",
		 "CLAIMANT_FIRST_NAME": "Hilda",
		 "CLAIM_NO": "2018-08-17-0206-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "08-14-2018 ",
		 "DATE_CLAIM_CLOSED": "09-09-2018 ",
		 "ACTUAL_RTW_DATE": "09-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cheek",
		 "CLAIMANT_FIRST_NAME": "Mickey",
		 "CLAIM_NO": "2020-09-23-0789-VP-01",
		 "DURATION": 308,
		 "DISABILITY_START_DATE": "09-21-2020 ",
		 "DATE_CLAIM_CLOSED": "07-27-2021 ",
		 "ACTUAL_RTW_DATE": "07-26-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cheek",
		 "CLAIMANT_FIRST_NAME": "Mickey",
		 "CLAIM_NO": "2019-08-13-0296-VP-01",
		 "DURATION": 208,
		 "DISABILITY_START_DATE": "08-02-2019 ",
		 "DATE_CLAIM_CLOSED": "05-28-2020 ",
		 "ACTUAL_RTW_DATE": "02-26-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chelsvig",
		 "CLAIMANT_FIRST_NAME": "Kurtis",
		 "CLAIM_NO": "2013-01-05-0316-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "11-19-2012 ",
		 "DATE_CLAIM_CLOSED": "02-11-2013 ",
		 "ACTUAL_RTW_DATE": "01-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chen",
		 "CLAIMANT_FIRST_NAME": "Charlene",
		 "CLAIM_NO": "2013-03-04-0143-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "04-01-2013 ",
		 "DATE_CLAIM_CLOSED": "07-24-2013 ",
		 "ACTUAL_RTW_DATE": "05-20-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chen",
		 "CLAIMANT_FIRST_NAME": "Yongyi",
		 "CLAIM_NO": "2022-06-15-0553-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "05-17-2022 ",
		 "DATE_CLAIM_CLOSED": "08-19-2022 ",
		 "ACTUAL_RTW_DATE": "05-31-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chen",
		 "CLAIMANT_FIRST_NAME": "Valerie",
		 "CLAIM_NO": "2018-04-12-0495-VP-01",
		 "DURATION": 168,
		 "DISABILITY_START_DATE": "04-16-2018 ",
		 "DATE_CLAIM_CLOSED": "10-01-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chen",
		 "CLAIMANT_FIRST_NAME": "Vinca",
		 "CLAIM_NO": "2016-05-02-0547-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "05-23-2016 ",
		 "DATE_CLAIM_CLOSED": "07-25-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chen",
		 "CLAIMANT_FIRST_NAME": "Lisa",
		 "CLAIM_NO": "2022-01-31-0785-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "03-01-2022 ",
		 "DATE_CLAIM_CLOSED": "05-03-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chen",
		 "CLAIMANT_FIRST_NAME": "Vinca",
		 "CLAIM_NO": "2013-01-05-0299-VP-01",
		 "DURATION": 44,
		 "DISABILITY_START_DATE": "12-14-2012 ",
		 "DATE_CLAIM_CLOSED": "02-26-2013 ",
		 "ACTUAL_RTW_DATE": "01-27-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chen",
		 "CLAIMANT_FIRST_NAME": "Yeou-Mei",
		 "CLAIM_NO": "2013-03-17-0006-VP-01",
		 "DURATION": 111,
		 "DISABILITY_START_DATE": "03-19-2013 ",
		 "DATE_CLAIM_CLOSED": "05-07-2013 ",
		 "ACTUAL_RTW_DATE": "07-08-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chen",
		 "CLAIMANT_FIRST_NAME": "Jiehua",
		 "CLAIM_NO": "2013-01-05-0678-VP-01",
		 "DURATION": 176,
		 "DISABILITY_START_DATE": "10-05-2012 ",
		 "DATE_CLAIM_CLOSED": "07-12-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chen",
		 "CLAIMANT_FIRST_NAME": "Jessica",
		 "CLAIM_NO": "2022-06-02-0939-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "07-06-2022 ",
		 "DATE_CLAIM_CLOSED": "09-15-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chen",
		 "CLAIMANT_FIRST_NAME": "Clifford",
		 "CLAIM_NO": "2013-05-04-0006-VP-01",
		 "DURATION": 92,
		 "DISABILITY_START_DATE": "05-01-2013 ",
		 "DATE_CLAIM_CLOSED": "10-15-2013 ",
		 "ACTUAL_RTW_DATE": "08-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cheney",
		 "CLAIMANT_FIRST_NAME": "Marcia",
		 "CLAIM_NO": "2014-09-24-0213-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "09-22-2014 ",
		 "DATE_CLAIM_CLOSED": "03-10-2015 ",
		 "ACTUAL_RTW_DATE": "10-06-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cheney",
		 "CLAIMANT_FIRST_NAME": "Marcia",
		 "CLAIM_NO": "2015-10-29-0244-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "11-06-2015 ",
		 "DATE_CLAIM_CLOSED": "01-11-2016 ",
		 "ACTUAL_RTW_DATE": "01-04-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cheng",
		 "CLAIMANT_FIRST_NAME": "Peiwen",
		 "CLAIM_NO": "2014-06-20-0446-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "06-23-2014 ",
		 "DATE_CLAIM_CLOSED": "11-12-2014 ",
		 "ACTUAL_RTW_DATE": "07-21-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cheng Tsui",
		 "CLAIMANT_FIRST_NAME": "Waimeng",
		 "CLAIM_NO": "2022-03-05-0049-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "03-11-2022 ",
		 "DATE_CLAIM_CLOSED": "04-26-2022 ",
		 "ACTUAL_RTW_DATE": "04-25-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cherra",
		 "CLAIMANT_FIRST_NAME": "Narinder",
		 "CLAIM_NO": "2016-01-04-0202-VP-01",
		 "DURATION": 362,
		 "DISABILITY_START_DATE": "01-05-2016 ",
		 "DATE_CLAIM_CLOSED": "07-15-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cheung",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2018-12-20-0608-VP-01",
		 "DURATION": 161,
		 "DISABILITY_START_DATE": "01-07-2019 ",
		 "DATE_CLAIM_CLOSED": "03-14-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cheung",
		 "CLAIMANT_FIRST_NAME": "Amy",
		 "CLAIM_NO": "2013-01-10-0186-VP-01",
		 "DURATION": 50,
		 "DISABILITY_START_DATE": "02-04-2013 ",
		 "DATE_CLAIM_CLOSED": "07-16-2013 ",
		 "ACTUAL_RTW_DATE": "03-26-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cheung",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2017-12-13-0291-VP-01",
		 "DURATION": 154,
		 "DISABILITY_START_DATE": "01-09-2018 ",
		 "DATE_CLAIM_CLOSED": "03-19-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cheung",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2017-01-03-0592-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "01-23-2017 ",
		 "DATE_CLAIM_CLOSED": "03-28-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cheung",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2021-09-09-0876-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "09-13-2021 ",
		 "DATE_CLAIM_CLOSED": "11-24-2021 ",
		 "ACTUAL_RTW_DATE": "11-22-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cheung",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2017-10-04-0593-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "09-26-2017 ",
		 "DATE_CLAIM_CLOSED": "10-24-2017 ",
		 "ACTUAL_RTW_DATE": "10-04-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chin",
		 "CLAIMANT_FIRST_NAME": "Donald",
		 "CLAIM_NO": "2022-04-11-0891-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "02-28-2022 ",
		 "DATE_CLAIM_CLOSED": "05-05-2022 ",
		 "ACTUAL_RTW_DATE": "03-28-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chin",
		 "CLAIMANT_FIRST_NAME": "Calvin",
		 "CLAIM_NO": "2016-02-19-0192-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "02-23-2016 ",
		 "DATE_CLAIM_CLOSED": "03-08-2016 ",
		 "ACTUAL_RTW_DATE": "03-02-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ching Canal",
		 "CLAIMANT_FIRST_NAME": "Manuela",
		 "CLAIM_NO": "2013-01-18-0385-VP-01",
		 "DURATION": 75,
		 "DISABILITY_START_DATE": "01-16-2013 ",
		 "DATE_CLAIM_CLOSED": "08-27-2013 ",
		 "ACTUAL_RTW_DATE": "04-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ching Canal",
		 "CLAIMANT_FIRST_NAME": "Manuela",
		 "CLAIM_NO": "2021-08-20-0873-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "08-24-2021 ",
		 "DATE_CLAIM_CLOSED": "10-29-2021 ",
		 "ACTUAL_RTW_DATE": "10-25-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Choi",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2021-04-21-0666-VP-01",
		 "DURATION": 140,
		 "DISABILITY_START_DATE": "04-26-2021 ",
		 "DATE_CLAIM_CLOSED": "09-13-2021 ",
		 "ACTUAL_RTW_DATE": "09-13-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cholico Mondragon",
		 "CLAIMANT_FIRST_NAME": "Dulce",
		 "CLAIM_NO": "2021-06-14-0630-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "05-31-2021 ",
		 "DATE_CLAIM_CLOSED": "07-13-2021 ",
		 "ACTUAL_RTW_DATE": "06-21-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chou",
		 "CLAIMANT_FIRST_NAME": "Vivian",
		 "CLAIM_NO": "2023-04-21-0588-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "04-24-2023 ",
		 "DATE_CLAIM_CLOSED": "04-26-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chou",
		 "CLAIMANT_FIRST_NAME": "Karen",
		 "CLAIM_NO": "2020-06-02-0264-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "07-09-2020 ",
		 "DATE_CLAIM_CLOSED": "09-24-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chow",
		 "CLAIMANT_FIRST_NAME": "Melissa",
		 "CLAIM_NO": "2013-04-05-0040-VP-01",
		 "DURATION": 834,
		 "DISABILITY_START_DATE": "10-25-2012 ",
		 "DATE_CLAIM_CLOSED": "03-20-2015 ",
		 "ACTUAL_RTW_DATE": "02-06-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Christian",
		 "CLAIMANT_FIRST_NAME": "Sprinkle",
		 "CLAIM_NO": "2020-05-11-0351-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "06-23-2020 ",
		 "DATE_CLAIM_CLOSED": "08-27-2020 ",
		 "ACTUAL_RTW_DATE": "08-26-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Christian",
		 "CLAIMANT_FIRST_NAME": "Armeika",
		 "CLAIM_NO": "2021-09-17-0888-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "09-20-2021 ",
		 "DATE_CLAIM_CLOSED": "10-25-2021 ",
		 "ACTUAL_RTW_DATE": "10-11-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Christian",
		 "CLAIMANT_FIRST_NAME": "Sprinkle",
		 "CLAIM_NO": "2018-06-04-0614-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "07-23-2018 ",
		 "DATE_CLAIM_CLOSED": "10-01-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chu",
		 "CLAIMANT_FIRST_NAME": "Ofilia",
		 "CLAIM_NO": "2015-09-23-0583-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "09-30-2015 ",
		 "DATE_CLAIM_CLOSED": "12-02-2015 ",
		 "ACTUAL_RTW_DATE": "11-04-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chung",
		 "CLAIMANT_FIRST_NAME": "Julie Xiet",
		 "CLAIM_NO": "2020-09-08-0274-VP-01",
		 "DURATION": 242,
		 "DISABILITY_START_DATE": "09-01-2020 ",
		 "DATE_CLAIM_CLOSED": "10-21-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chung",
		 "CLAIMANT_FIRST_NAME": "Seung Hae",
		 "CLAIM_NO": "2021-10-06-0934-VP-01",
		 "DURATION": 102,
		 "DISABILITY_START_DATE": "10-18-2021 ",
		 "DATE_CLAIM_CLOSED": "02-01-2022 ",
		 "ACTUAL_RTW_DATE": "01-28-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Chung",
		 "CLAIMANT_FIRST_NAME": "Julie Xiet",
		 "CLAIM_NO": "2019-08-26-0362-VP-01",
		 "DURATION": 279,
		 "DISABILITY_START_DATE": "08-27-2019 ",
		 "DATE_CLAIM_CLOSED": "08-30-2021 ",
		 "ACTUAL_RTW_DATE": "06-01-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Church",
		 "CLAIMANT_FIRST_NAME": "Cindy",
		 "CLAIM_NO": "2013-01-05-1059-VP-01",
		 "DURATION": 480,
		 "DISABILITY_START_DATE": "08-24-2012 ",
		 "DATE_CLAIM_CLOSED": "01-08-2014 ",
		 "ACTUAL_RTW_DATE": "12-17-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ciudad Real",
		 "CLAIMANT_FIRST_NAME": "Juana",
		 "CLAIM_NO": "2017-05-08-0723-VP-01",
		 "DURATION": 94,
		 "DISABILITY_START_DATE": "05-19-2017 ",
		 "DATE_CLAIM_CLOSED": "08-23-2017 ",
		 "ACTUAL_RTW_DATE": "08-21-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ciudad Real",
		 "CLAIMANT_FIRST_NAME": "Juana",
		 "CLAIM_NO": "2014-10-27-0403-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "10-24-2014 ",
		 "DATE_CLAIM_CLOSED": "12-16-2014 ",
		 "ACTUAL_RTW_DATE": "11-03-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Clabaugh",
		 "CLAIMANT_FIRST_NAME": "Brant",
		 "CLAIM_NO": "2014-06-30-0400-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "07-10-2014 ",
		 "DATE_CLAIM_CLOSED": "12-05-2014 ",
		 "ACTUAL_RTW_DATE": "07-28-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Clark",
		 "CLAIMANT_FIRST_NAME": "Wendy",
		 "CLAIM_NO": "2013-05-14-0379-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "06-03-2013 ",
		 "DATE_CLAIM_CLOSED": "07-15-2013 ",
		 "ACTUAL_RTW_DATE": "07-13-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Clark",
		 "CLAIMANT_FIRST_NAME": "Susan",
		 "CLAIM_NO": "2016-06-17-0011-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "06-17-2016 ",
		 "DATE_CLAIM_CLOSED": "07-11-2016 ",
		 "ACTUAL_RTW_DATE": "07-05-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cleland",
		 "CLAIMANT_FIRST_NAME": "Kevin",
		 "CLAIM_NO": "2014-09-05-0295-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "09-08-2014 ",
		 "DATE_CLAIM_CLOSED": "12-05-2014 ",
		 "ACTUAL_RTW_DATE": "10-20-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Clevenger",
		 "CLAIMANT_FIRST_NAME": "Francine",
		 "CLAIM_NO": "2015-10-16-0304-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "10-26-2015 ",
		 "DATE_CLAIM_CLOSED": "01-12-2016 ",
		 "ACTUAL_RTW_DATE": "01-11-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Clossick",
		 "CLAIMANT_FIRST_NAME": "Lisa",
		 "CLAIM_NO": "2013-09-18-0380-VP-01",
		 "DURATION": 3,
		 "DISABILITY_START_DATE": "09-16-2013 ",
		 "DATE_CLAIM_CLOSED": "02-06-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Coatsworth",
		 "CLAIMANT_FIRST_NAME": "Lauren",
		 "CLAIM_NO": "2020-08-25-0988-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "08-31-2020 ",
		 "DATE_CLAIM_CLOSED": "09-17-2020 ",
		 "ACTUAL_RTW_DATE": "09-17-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cobar",
		 "CLAIMANT_FIRST_NAME": "Hugo",
		 "CLAIM_NO": "2016-04-26-0279-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "05-19-2016 ",
		 "DATE_CLAIM_CLOSED": "06-03-2016 ",
		 "ACTUAL_RTW_DATE": "06-02-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cobb",
		 "CLAIMANT_FIRST_NAME": "Melody",
		 "CLAIM_NO": "2016-02-05-0388-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "02-05-2016 ",
		 "DATE_CLAIM_CLOSED": "04-01-2016 ",
		 "ACTUAL_RTW_DATE": "03-07-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cobb",
		 "CLAIMANT_FIRST_NAME": "Melody",
		 "CLAIM_NO": "2014-08-28-0143-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "08-28-2014 ",
		 "DATE_CLAIM_CLOSED": "11-12-2014 ",
		 "ACTUAL_RTW_DATE": "10-16-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cobb",
		 "CLAIMANT_FIRST_NAME": "Melody",
		 "CLAIM_NO": "2014-10-24-0301-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "11-17-2014 ",
		 "DATE_CLAIM_CLOSED": "01-02-2015 ",
		 "ACTUAL_RTW_DATE": "01-02-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cobb",
		 "CLAIMANT_FIRST_NAME": "Melody",
		 "CLAIM_NO": "2014-04-28-0321-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "04-23-2014 ",
		 "DATE_CLAIM_CLOSED": "08-26-2014 ",
		 "ACTUAL_RTW_DATE": "05-05-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cobb",
		 "CLAIMANT_FIRST_NAME": "Melody",
		 "CLAIM_NO": "2013-07-10-0516-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "07-05-2013 ",
		 "DATE_CLAIM_CLOSED": "09-11-2013 ",
		 "ACTUAL_RTW_DATE": "08-09-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cobb",
		 "CLAIMANT_FIRST_NAME": "Melody",
		 "CLAIM_NO": "2019-10-14-0627-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "10-08-2019 ",
		 "DATE_CLAIM_CLOSED": "12-17-2019 ",
		 "ACTUAL_RTW_DATE": "12-11-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Coffman",
		 "CLAIMANT_FIRST_NAME": "Steven",
		 "CLAIM_NO": "2017-03-14-0149-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "03-06-2017 ",
		 "DATE_CLAIM_CLOSED": "03-29-2017 ",
		 "ACTUAL_RTW_DATE": "03-20-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Coleman",
		 "CLAIMANT_FIRST_NAME": "Daniel",
		 "CLAIM_NO": "2022-02-15-0593-VP-01",
		 "DURATION": 36,
		 "DISABILITY_START_DATE": "02-14-2022 ",
		 "DATE_CLAIM_CLOSED": "04-07-2022 ",
		 "ACTUAL_RTW_DATE": "03-22-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Coleman",
		 "CLAIMANT_FIRST_NAME": "Charity",
		 "CLAIM_NO": "2019-11-25-0732-VP-01",
		 "DURATION": 44,
		 "DISABILITY_START_DATE": "11-19-2019 ",
		 "DATE_CLAIM_CLOSED": "03-27-2020 ",
		 "ACTUAL_RTW_DATE": "01-02-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Coleman",
		 "CLAIMANT_FIRST_NAME": "Daniel",
		 "CLAIM_NO": "2022-11-23-0475-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "12-07-2022 ",
		 "DATE_CLAIM_CLOSED": "02-13-2023 ",
		 "ACTUAL_RTW_DATE": "12-20-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Coleman-Minor",
		 "CLAIMANT_FIRST_NAME": "Danielle",
		 "CLAIM_NO": "2021-02-22-0378-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "03-10-2021 ",
		 "DATE_CLAIM_CLOSED": "04-26-2021 ",
		 "ACTUAL_RTW_DATE": "04-22-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Colglazier",
		 "CLAIMANT_FIRST_NAME": "Joy",
		 "CLAIM_NO": "2021-10-01-0398-VP-01",
		 "DURATION": 194,
		 "DISABILITY_START_DATE": "10-18-2021 ",
		 "DATE_CLAIM_CLOSED": "05-02-2022 ",
		 "ACTUAL_RTW_DATE": "04-30-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Collazo",
		 "CLAIMANT_FIRST_NAME": "Leticia",
		 "CLAIM_NO": "2022-05-02-0199-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "04-25-2022 ",
		 "DATE_CLAIM_CLOSED": "06-09-2022 ",
		 "ACTUAL_RTW_DATE": "06-06-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Collazo",
		 "CLAIMANT_FIRST_NAME": "Leticia",
		 "CLAIM_NO": "2021-01-11-0794-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "01-11-2021 ",
		 "DATE_CLAIM_CLOSED": "03-05-2021 ",
		 "ACTUAL_RTW_DATE": "03-01-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Collins",
		 "CLAIMANT_FIRST_NAME": "Maraika",
		 "CLAIM_NO": "2020-05-08-0289-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "07-06-2020 ",
		 "DATE_CLAIM_CLOSED": "09-02-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Collins",
		 "CLAIMANT_FIRST_NAME": "Deborah",
		 "CLAIM_NO": "2020-12-18-1139-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "01-04-2021 ",
		 "DATE_CLAIM_CLOSED": "03-09-2021 ",
		 "ACTUAL_RTW_DATE": "03-08-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Comianos",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2017-05-09-0354-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "06-06-2017 ",
		 "DATE_CLAIM_CLOSED": "08-07-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Comianos",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2019-11-06-0750-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "12-05-2019 ",
		 "DATE_CLAIM_CLOSED": "02-05-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Compton",
		 "CLAIMANT_FIRST_NAME": "Lennix",
		 "CLAIM_NO": "2020-11-05-0061-VP-01",
		 "DURATION": 721,
		 "DISABILITY_START_DATE": "10-19-2020 ",
		 "DATE_CLAIM_CLOSED": "01-13-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Conde",
		 "CLAIMANT_FIRST_NAME": "Adriana",
		 "CLAIM_NO": "2015-05-04-0540-VP-01",
		 "DURATION": 76,
		 "DISABILITY_START_DATE": "05-26-2015 ",
		 "DATE_CLAIM_CLOSED": "08-17-2015 ",
		 "ACTUAL_RTW_DATE": "08-10-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Conde",
		 "CLAIMANT_FIRST_NAME": "Elvira",
		 "CLAIM_NO": "2021-01-25-1074-VP-01",
		 "DURATION": 175,
		 "DISABILITY_START_DATE": "02-15-2021 ",
		 "DATE_CLAIM_CLOSED": "08-16-2021 ",
		 "ACTUAL_RTW_DATE": "08-09-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Conemac",
		 "CLAIMANT_FIRST_NAME": "Lesa",
		 "CLAIM_NO": "2020-10-21-0391-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "12-07-2020 ",
		 "DATE_CLAIM_CLOSED": "02-03-2021 ",
		 "ACTUAL_RTW_DATE": "02-01-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Connaughton",
		 "CLAIMANT_FIRST_NAME": "Nicole",
		 "CLAIM_NO": "2021-07-19-0624-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "08-06-2021 ",
		 "DATE_CLAIM_CLOSED": "10-06-2021 ",
		 "ACTUAL_RTW_DATE": "10-01-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Connolly",
		 "CLAIMANT_FIRST_NAME": "Leanne",
		 "CLAIM_NO": "2015-08-28-0404-VP-01",
		 "DURATION": 115,
		 "DISABILITY_START_DATE": "09-11-2015 ",
		 "DATE_CLAIM_CLOSED": "11-06-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Connolly",
		 "CLAIMANT_FIRST_NAME": "Allison",
		 "CLAIM_NO": "2022-07-22-0803-VP-01",
		 "DURATION": 119,
		 "DISABILITY_START_DATE": "08-22-2022 ",
		 "DATE_CLAIM_CLOSED": "10-27-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Connolly",
		 "CLAIMANT_FIRST_NAME": "Leanne",
		 "CLAIM_NO": "2013-10-10-0382-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "11-04-2013 ",
		 "DATE_CLAIM_CLOSED": "12-30-2013 ",
		 "ACTUAL_RTW_DATE": "12-15-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Connolly",
		 "CLAIMANT_FIRST_NAME": "Allison",
		 "CLAIM_NO": "2020-04-14-0710-VP-01",
		 "DURATION": 87,
		 "DISABILITY_START_DATE": "04-20-2020 ",
		 "DATE_CLAIM_CLOSED": "07-16-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Considine",
		 "CLAIMANT_FIRST_NAME": "Kathleen",
		 "CLAIM_NO": "2015-08-24-0419-VP-01",
		 "DURATION": 92,
		 "DISABILITY_START_DATE": "08-20-2015 ",
		 "DATE_CLAIM_CLOSED": "11-23-2015 ",
		 "ACTUAL_RTW_DATE": "11-20-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Considine",
		 "CLAIMANT_FIRST_NAME": "Kathleen",
		 "CLAIM_NO": "2018-09-18-0657-VP-01",
		 "DURATION": 329,
		 "DISABILITY_START_DATE": "09-03-2018 ",
		 "DATE_CLAIM_CLOSED": "08-07-2019 ",
		 "ACTUAL_RTW_DATE": "07-29-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Contreras",
		 "CLAIMANT_FIRST_NAME": "Norma",
		 "CLAIM_NO": "2013-05-31-0409-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "06-12-2013 ",
		 "DATE_CLAIM_CLOSED": "09-23-2013 ",
		 "ACTUAL_RTW_DATE": "06-24-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cook",
		 "CLAIMANT_FIRST_NAME": "Gina",
		 "CLAIM_NO": "2017-03-03-0562-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "04-05-2017 ",
		 "DATE_CLAIM_CLOSED": "05-04-2017 ",
		 "ACTUAL_RTW_DATE": "05-03-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cooke",
		 "CLAIMANT_FIRST_NAME": "Gregory",
		 "CLAIM_NO": "2014-08-27-0259-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "08-26-2014 ",
		 "DATE_CLAIM_CLOSED": "11-12-2014 ",
		 "ACTUAL_RTW_DATE": "09-16-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cooke",
		 "CLAIMANT_FIRST_NAME": "Gregory",
		 "CLAIM_NO": "2015-08-27-0257-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "08-26-2015 ",
		 "DATE_CLAIM_CLOSED": "11-06-2015 ",
		 "ACTUAL_RTW_DATE": "11-02-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cooke",
		 "CLAIMANT_FIRST_NAME": "Gregory",
		 "CLAIM_NO": "2014-06-10-0566-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "06-04-2014 ",
		 "DATE_CLAIM_CLOSED": "11-12-2014 ",
		 "ACTUAL_RTW_DATE": "06-23-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cooper",
		 "CLAIMANT_FIRST_NAME": "Helen",
		 "CLAIM_NO": "2013-01-05-2799-VP-01",
		 "DURATION": 365,
		 "DISABILITY_START_DATE": "02-15-2012 ",
		 "DATE_CLAIM_CLOSED": "11-12-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cooper",
		 "CLAIMANT_FIRST_NAME": "Stephanie",
		 "CLAIM_NO": "2014-08-04-0517-VP-01",
		 "DURATION": 185,
		 "DISABILITY_START_DATE": "08-15-2014 ",
		 "DATE_CLAIM_CLOSED": "02-17-2015 ",
		 "ACTUAL_RTW_DATE": "02-16-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cooper",
		 "CLAIMANT_FIRST_NAME": "Aaron",
		 "CLAIM_NO": "2013-04-08-0213-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "04-04-2013 ",
		 "DATE_CLAIM_CLOSED": "07-24-2013 ",
		 "ACTUAL_RTW_DATE": "04-19-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cordero",
		 "CLAIMANT_FIRST_NAME": "Carolina",
		 "CLAIM_NO": "2021-11-18-1017-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "11-17-2021 ",
		 "DATE_CLAIM_CLOSED": "12-16-2021 ",
		 "ACTUAL_RTW_DATE": "12-16-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cordero",
		 "CLAIMANT_FIRST_NAME": "Carolina",
		 "CLAIM_NO": "2013-11-06-0369-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "11-06-2013 ",
		 "DATE_CLAIM_CLOSED": "09-08-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cordero",
		 "CLAIMANT_FIRST_NAME": "Carolina",
		 "CLAIM_NO": "2022-09-28-0782-VP-01",
		 "DURATION": 132,
		 "DISABILITY_START_DATE": "10-19-2022 ",
		 "DATE_CLAIM_CLOSED": "12-22-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cormane",
		 "CLAIMANT_FIRST_NAME": "Brooke",
		 "CLAIM_NO": "2022-06-27-1035-VP-01",
		 "DURATION": 105,
		 "DISABILITY_START_DATE": "08-08-2022 ",
		 "DATE_CLAIM_CLOSED": "10-03-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cormane",
		 "CLAIMANT_FIRST_NAME": "Brooke",
		 "CLAIM_NO": "2020-02-03-0808-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "03-02-2020 ",
		 "DATE_CLAIM_CLOSED": "04-28-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cornbleet",
		 "CLAIMANT_FIRST_NAME": "Molly",
		 "CLAIM_NO": "2020-08-15-0058-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "08-19-2020 ",
		 "DATE_CLAIM_CLOSED": "10-13-2020 ",
		 "ACTUAL_RTW_DATE": "10-12-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Corona",
		 "CLAIMANT_FIRST_NAME": "Herminio",
		 "CLAIM_NO": "2018-03-07-0311-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "03-07-2018 ",
		 "DATE_CLAIM_CLOSED": "04-09-2018 ",
		 "ACTUAL_RTW_DATE": "03-16-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Corona",
		 "CLAIMANT_FIRST_NAME": "Herminio",
		 "CLAIM_NO": "2014-05-19-0353-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "05-27-2014 ",
		 "DATE_CLAIM_CLOSED": "09-02-2014 ",
		 "ACTUAL_RTW_DATE": "06-23-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Coronel",
		 "CLAIMANT_FIRST_NAME": "Amadeo",
		 "CLAIM_NO": "2013-11-05-0332-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "11-01-2013 ",
		 "DATE_CLAIM_CLOSED": "03-25-2014 ",
		 "ACTUAL_RTW_DATE": "11-18-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Corpus",
		 "CLAIMANT_FIRST_NAME": "Benedict",
		 "CLAIM_NO": "2019-09-11-0181-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "09-10-2019 ",
		 "DATE_CLAIM_CLOSED": "10-16-2019 ",
		 "ACTUAL_RTW_DATE": "10-14-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Corrales",
		 "CLAIMANT_FIRST_NAME": "Rubi",
		 "CLAIM_NO": "2022-01-28-0579-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "03-08-2022 ",
		 "DATE_CLAIM_CLOSED": "04-21-2022 ",
		 "ACTUAL_RTW_DATE": "04-19-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Corrales",
		 "CLAIMANT_FIRST_NAME": "Rubi",
		 "CLAIM_NO": "2013-05-24-0348-VP-01",
		 "DURATION": 30,
		 "DISABILITY_START_DATE": "06-13-2013 ",
		 "DATE_CLAIM_CLOSED": "08-21-2013 ",
		 "ACTUAL_RTW_DATE": "07-13-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Corrales",
		 "CLAIMANT_FIRST_NAME": "Rubi",
		 "CLAIM_NO": "2019-03-22-0139-VP-01",
		 "DURATION": 102,
		 "DISABILITY_START_DATE": "03-18-2019 ",
		 "DATE_CLAIM_CLOSED": "07-01-2019 ",
		 "ACTUAL_RTW_DATE": "06-28-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Corrales",
		 "CLAIMANT_FIRST_NAME": "Elizabeth",
		 "CLAIM_NO": "2014-04-04-0498-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "05-01-2014 ",
		 "DATE_CLAIM_CLOSED": "07-10-2014 ",
		 "ACTUAL_RTW_DATE": "05-12-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Corrales",
		 "CLAIMANT_FIRST_NAME": "Rubi",
		 "CLAIM_NO": "2020-01-06-0901-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "02-13-2020 ",
		 "DATE_CLAIM_CLOSED": "02-20-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Corrales",
		 "CLAIMANT_FIRST_NAME": "Rubi",
		 "CLAIM_NO": "2020-05-26-0722-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "06-11-2020 ",
		 "DATE_CLAIM_CLOSED": "07-14-2020 ",
		 "ACTUAL_RTW_DATE": "07-10-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cortes",
		 "CLAIMANT_FIRST_NAME": "Matthew",
		 "CLAIM_NO": "2020-03-18-0760-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "03-09-2020 ",
		 "DATE_CLAIM_CLOSED": "04-02-2020 ",
		 "ACTUAL_RTW_DATE": "03-30-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cortes Becerra",
		 "CLAIMANT_FIRST_NAME": "Andres",
		 "CLAIM_NO": "2018-03-08-0144-VP-01",
		 "DURATION": 158,
		 "DISABILITY_START_DATE": "02-22-2018 ",
		 "DATE_CLAIM_CLOSED": "08-16-2018 ",
		 "ACTUAL_RTW_DATE": "07-30-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cortez",
		 "CLAIMANT_FIRST_NAME": "Crystal",
		 "CLAIM_NO": "2021-10-29-0769-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "10-27-2021 ",
		 "DATE_CLAIM_CLOSED": "11-26-2021 ",
		 "ACTUAL_RTW_DATE": "11-24-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cosmano Ii",
		 "CLAIMANT_FIRST_NAME": "Joseph",
		 "CLAIM_NO": "2021-09-30-0751-VP-01",
		 "DURATION": 39,
		 "DISABILITY_START_DATE": "09-30-2021 ",
		 "DATE_CLAIM_CLOSED": "11-09-2021 ",
		 "ACTUAL_RTW_DATE": "11-08-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cosmano Ii",
		 "CLAIMANT_FIRST_NAME": "Joseph",
		 "CLAIM_NO": "2015-09-17-0222-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "09-14-2015 ",
		 "DATE_CLAIM_CLOSED": "10-16-2015 ",
		 "ACTUAL_RTW_DATE": "10-06-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cosmano Ii",
		 "CLAIMANT_FIRST_NAME": "Joseph",
		 "CLAIM_NO": "2021-06-02-0920-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "05-27-2021 ",
		 "DATE_CLAIM_CLOSED": "06-17-2021 ",
		 "ACTUAL_RTW_DATE": "06-07-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cosmano Ii",
		 "CLAIMANT_FIRST_NAME": "Joseph",
		 "CLAIM_NO": "2021-08-19-0041-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "08-17-2021 ",
		 "DATE_CLAIM_CLOSED": "08-27-2021 ",
		 "ACTUAL_RTW_DATE": "08-26-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Costin",
		 "CLAIMANT_FIRST_NAME": "Georgiana",
		 "CLAIM_NO": "2022-05-12-0785-VP-01",
		 "DURATION": 67,
		 "DISABILITY_START_DATE": "05-25-2022 ",
		 "DATE_CLAIM_CLOSED": "08-10-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cota",
		 "CLAIMANT_FIRST_NAME": "Diego",
		 "CLAIM_NO": "2018-06-05-0615-VP-01",
		 "DURATION": 2,
		 "DISABILITY_START_DATE": "06-05-2018 ",
		 "DATE_CLAIM_CLOSED": "06-12-2018 ",
		 "ACTUAL_RTW_DATE": "06-07-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Couch",
		 "CLAIMANT_FIRST_NAME": "Nancy",
		 "CLAIM_NO": "2014-08-26-0505-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "08-12-2014 ",
		 "DATE_CLAIM_CLOSED": "11-12-2014 ",
		 "ACTUAL_RTW_DATE": "08-23-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Couch",
		 "CLAIMANT_FIRST_NAME": "Nancy",
		 "CLAIM_NO": "2014-09-24-0398-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "09-16-2014 ",
		 "DATE_CLAIM_CLOSED": "10-29-2014 ",
		 "ACTUAL_RTW_DATE": "10-06-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Covarrubias",
		 "CLAIMANT_FIRST_NAME": "Teresa",
		 "CLAIM_NO": "2013-01-05-4936-VP-01",
		 "DURATION": 110,
		 "DISABILITY_START_DATE": "06-07-2011 ",
		 "DATE_CLAIM_CLOSED": "01-15-2014 ",
		 "ACTUAL_RTW_DATE": "09-25-2011 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cowan",
		 "CLAIMANT_FIRST_NAME": "James",
		 "CLAIM_NO": "2013-07-23-0374-VP-01",
		 "DURATION": 117,
		 "DISABILITY_START_DATE": "07-19-2013 ",
		 "DATE_CLAIM_CLOSED": "11-18-2013 ",
		 "ACTUAL_RTW_DATE": "11-13-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cowan",
		 "CLAIMANT_FIRST_NAME": "James",
		 "CLAIM_NO": "2013-02-18-0122-VP-01",
		 "DURATION": 118,
		 "DISABILITY_START_DATE": "02-19-2013 ",
		 "DATE_CLAIM_CLOSED": "09-16-2013 ",
		 "ACTUAL_RTW_DATE": "06-17-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cox",
		 "CLAIMANT_FIRST_NAME": "Christopher",
		 "CLAIM_NO": "2022-01-07-1342-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "01-04-2022 ",
		 "DATE_CLAIM_CLOSED": "01-24-2022 ",
		 "ACTUAL_RTW_DATE": "01-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Crawford",
		 "CLAIMANT_FIRST_NAME": "Lauren",
		 "CLAIM_NO": "2017-01-10-0627-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "01-16-2017 ",
		 "DATE_CLAIM_CLOSED": "01-12-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Creager",
		 "CLAIMANT_FIRST_NAME": "Bradley",
		 "CLAIM_NO": "2015-03-04-0457-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "03-03-2015 ",
		 "DATE_CLAIM_CLOSED": "03-23-2015 ",
		 "ACTUAL_RTW_DATE": "03-20-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cretesio",
		 "CLAIMANT_FIRST_NAME": "Rochen",
		 "CLAIM_NO": "2019-07-30-0253-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "08-30-2019 ",
		 "DATE_CLAIM_CLOSED": "11-07-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cretin",
		 "CLAIMANT_FIRST_NAME": "Raquel",
		 "CLAIM_NO": "2019-09-24-0732-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "09-24-2019 ",
		 "DATE_CLAIM_CLOSED": "10-29-2019 ",
		 "ACTUAL_RTW_DATE": "10-14-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Crisologo",
		 "CLAIMANT_FIRST_NAME": "Carmelo",
		 "CLAIM_NO": "2019-08-01-0392-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "08-08-2019 ",
		 "DATE_CLAIM_CLOSED": "09-18-2019 ",
		 "ACTUAL_RTW_DATE": "08-26-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Crocker",
		 "CLAIMANT_FIRST_NAME": "Mandy",
		 "CLAIM_NO": "2022-12-16-0394-VP-01",
		 "DURATION": 125,
		 "DISABILITY_START_DATE": "01-18-2023 ",
		 "DATE_CLAIM_CLOSED": "03-24-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Crosby",
		 "CLAIMANT_FIRST_NAME": "Kathryn",
		 "CLAIM_NO": "2022-05-19-0678-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "05-27-2022 ",
		 "DATE_CLAIM_CLOSED": "06-23-2022 ",
		 "ACTUAL_RTW_DATE": "06-13-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Crosby",
		 "CLAIMANT_FIRST_NAME": "Kathryn",
		 "CLAIM_NO": "2022-08-16-0331-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "09-09-2022 ",
		 "DATE_CLAIM_CLOSED": "10-18-2022 ",
		 "ACTUAL_RTW_DATE": "09-29-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Crosby",
		 "CLAIMANT_FIRST_NAME": "Sally",
		 "CLAIM_NO": "2022-01-27-0403-VP-01",
		 "DURATION": 0,
		 "DISABILITY_START_DATE": "01-31-2022 ",
		 "DATE_CLAIM_CLOSED": "02-02-2022 ",
		 "ACTUAL_RTW_DATE": "01-31-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Crosson",
		 "CLAIMANT_FIRST_NAME": "Lori",
		 "CLAIM_NO": "2013-11-01-0212-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "12-04-2013 ",
		 "DATE_CLAIM_CLOSED": "02-11-2014 ",
		 "ACTUAL_RTW_DATE": "01-29-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cruz",
		 "CLAIMANT_FIRST_NAME": "Teresa",
		 "CLAIM_NO": "2013-06-24-0251-VP-01",
		 "DURATION": 133,
		 "DISABILITY_START_DATE": "07-22-2013 ",
		 "DATE_CLAIM_CLOSED": "12-03-2013 ",
		 "ACTUAL_RTW_DATE": "12-02-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cruz",
		 "CLAIMANT_FIRST_NAME": "Karina",
		 "CLAIM_NO": "2021-06-28-0948-VP-01",
		 "DURATION": 93,
		 "DISABILITY_START_DATE": "06-21-2021 ",
		 "DATE_CLAIM_CLOSED": "10-01-2021 ",
		 "ACTUAL_RTW_DATE": "09-22-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cruz",
		 "CLAIMANT_FIRST_NAME": "Tessie",
		 "CLAIM_NO": "2013-01-21-0081-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "01-15-2013 ",
		 "DATE_CLAIM_CLOSED": "05-06-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cruz",
		 "CLAIMANT_FIRST_NAME": "Abraham",
		 "CLAIM_NO": "2013-04-30-0359-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "05-01-2013 ",
		 "DATE_CLAIM_CLOSED": "08-03-2013 ",
		 "ACTUAL_RTW_DATE": "06-05-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cruz",
		 "CLAIMANT_FIRST_NAME": "Teresa",
		 "CLAIM_NO": "2014-07-29-0261-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "07-14-2014 ",
		 "DATE_CLAIM_CLOSED": "07-16-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cruz",
		 "CLAIMANT_FIRST_NAME": "Zoila",
		 "CLAIM_NO": "2013-01-05-0297-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "11-08-2012 ",
		 "DATE_CLAIM_CLOSED": "01-20-2013 ",
		 "ACTUAL_RTW_DATE": "11-28-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cruz",
		 "CLAIMANT_FIRST_NAME": "Abraham",
		 "CLAIM_NO": "2013-03-19-0203-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "03-19-2013 ",
		 "DATE_CLAIM_CLOSED": "05-03-2013 ",
		 "ACTUAL_RTW_DATE": "03-26-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cuadra",
		 "CLAIMANT_FIRST_NAME": "Moises",
		 "CLAIM_NO": "2021-03-12-0381-VP-01",
		 "DURATION": 183,
		 "DISABILITY_START_DATE": "03-01-2021 ",
		 "DATE_CLAIM_CLOSED": "08-16-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cuevas",
		 "CLAIMANT_FIRST_NAME": "Suzanne",
		 "CLAIM_NO": "2021-07-30-0285-VP-01",
		 "DURATION": 4,
		 "DISABILITY_START_DATE": "08-06-2021 ",
		 "DATE_CLAIM_CLOSED": "08-31-2021 ",
		 "ACTUAL_RTW_DATE": "08-10-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cuevas",
		 "CLAIMANT_FIRST_NAME": "Jorge",
		 "CLAIM_NO": "2015-09-08-0515-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "09-29-2015 ",
		 "DATE_CLAIM_CLOSED": "12-02-2015 ",
		 "ACTUAL_RTW_DATE": "10-27-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cuevas",
		 "CLAIMANT_FIRST_NAME": "Leonel",
		 "CLAIM_NO": "2013-11-18-0143-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "11-18-2013 ",
		 "DATE_CLAIM_CLOSED": "03-11-2014 ",
		 "ACTUAL_RTW_DATE": "01-13-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cuevas Aguilar",
		 "CLAIMANT_FIRST_NAME": "Karla",
		 "CLAIM_NO": "2023-03-17-0842-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "03-17-2023 ",
		 "DATE_CLAIM_CLOSED": "03-29-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cuevas Ponce",
		 "CLAIMANT_FIRST_NAME": "Rosalinda",
		 "CLAIM_NO": "2013-05-20-0062-VP-01",
		 "DURATION": 1010,
		 "DISABILITY_START_DATE": "05-15-2013 ",
		 "DATE_CLAIM_CLOSED": "02-22-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Culkin",
		 "CLAIMANT_FIRST_NAME": "Brigid",
		 "CLAIM_NO": "2020-07-22-0423-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "08-04-2020 ",
		 "DATE_CLAIM_CLOSED": "09-30-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Cummings",
		 "CLAIMANT_FIRST_NAME": "Lonny",
		 "CLAIM_NO": "2015-12-11-0412-VP-01",
		 "DURATION": 82,
		 "DISABILITY_START_DATE": "11-18-2015 ",
		 "DATE_CLAIM_CLOSED": "02-09-2016 ",
		 "ACTUAL_RTW_DATE": "02-08-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Curiel",
		 "CLAIMANT_FIRST_NAME": "Jorge",
		 "CLAIM_NO": "2014-05-12-0503-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "05-07-2014 ",
		 "DATE_CLAIM_CLOSED": "10-01-2014 ",
		 "ACTUAL_RTW_DATE": "05-15-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Curiel",
		 "CLAIMANT_FIRST_NAME": "Jorge",
		 "CLAIM_NO": "2015-03-27-0390-VP-01",
		 "DURATION": 126,
		 "DISABILITY_START_DATE": "03-23-2015 ",
		 "DATE_CLAIM_CLOSED": "08-03-2015 ",
		 "ACTUAL_RTW_DATE": "07-27-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Curiel",
		 "CLAIMANT_FIRST_NAME": "Jorge",
		 "CLAIM_NO": "2015-09-08-0240-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "09-01-2015 ",
		 "DATE_CLAIM_CLOSED": "09-30-2015 ",
		 "ACTUAL_RTW_DATE": "09-14-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Curiel",
		 "CLAIMANT_FIRST_NAME": "Jorge",
		 "CLAIM_NO": "2015-05-29-0115-VP-01",
		 "DISABILITY_START_DATE": "04-22-2015 ",
		 "DATE_CLAIM_CLOSED": "06-20-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Curley",
		 "CLAIMANT_FIRST_NAME": "Christina",
		 "CLAIM_NO": "2018-09-20-0556-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "10-15-2018 ",
		 "DATE_CLAIM_CLOSED": "11-27-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Curran",
		 "CLAIMANT_FIRST_NAME": "Jill",
		 "CLAIM_NO": "2019-03-18-0292-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "04-18-2019 ",
		 "DATE_CLAIM_CLOSED": "05-30-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Curran",
		 "CLAIMANT_FIRST_NAME": "Jill",
		 "CLAIM_NO": "2015-07-22-0536-VP-01",
		 "DURATION": 0,
		 "DISABILITY_START_DATE": "09-18-2015 ",
		 "DATE_CLAIM_CLOSED": "10-23-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Daga",
		 "CLAIMANT_FIRST_NAME": "Nidhi",
		 "CLAIM_NO": "2014-05-12-0488-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "05-19-2014 ",
		 "DATE_CLAIM_CLOSED": "12-05-2014 ",
		 "ACTUAL_RTW_DATE": "07-18-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Daga",
		 "CLAIMANT_FIRST_NAME": "Shweta",
		 "CLAIM_NO": "2013-11-21-0422-VP-01",
		 "DURATION": 69,
		 "DISABILITY_START_DATE": "12-02-2013 ",
		 "DATE_CLAIM_CLOSED": "05-20-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dagdagan",
		 "CLAIMANT_FIRST_NAME": "April",
		 "CLAIM_NO": "2019-09-05-0720-VP-01",
		 "DURATION": 161,
		 "DISABILITY_START_DATE": "09-06-2019 ",
		 "DATE_CLAIM_CLOSED": "03-09-2020 ",
		 "ACTUAL_RTW_DATE": "02-14-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dahlberg",
		 "CLAIMANT_FIRST_NAME": "Nathan",
		 "CLAIM_NO": "2014-05-06-0176-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "05-12-2014 ",
		 "DATE_CLAIM_CLOSED": "10-01-2014 ",
		 "ACTUAL_RTW_DATE": "05-27-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dairiki",
		 "CLAIMANT_FIRST_NAME": "Joan",
		 "CLAIM_NO": "2014-10-10-0307-VP-01",
		 "DURATION": 164,
		 "DISABILITY_START_DATE": "10-31-2014 ",
		 "DATE_CLAIM_CLOSED": "05-20-2015 ",
		 "ACTUAL_RTW_DATE": "04-13-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Daley",
		 "CLAIMANT_FIRST_NAME": "Debra",
		 "CLAIM_NO": "2013-02-26-0456-VP-01",
		 "DURATION": 287,
		 "DISABILITY_START_DATE": "03-21-2013 ",
		 "DATE_CLAIM_CLOSED": "03-18-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Damian",
		 "CLAIMANT_FIRST_NAME": "Manuela",
		 "CLAIM_NO": "2022-11-28-0788-VP-01",
		 "DURATION": 148,
		 "DISABILITY_START_DATE": "11-20-2022 ",
		 "DATE_CLAIM_CLOSED": "04-19-2023 ",
		 "ACTUAL_RTW_DATE": "04-17-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dang",
		 "CLAIMANT_FIRST_NAME": "Mai",
		 "CLAIM_NO": "2018-08-09-0609-VP-01",
		 "DURATION": 73,
		 "DISABILITY_START_DATE": "06-01-2018 ",
		 "DATE_CLAIM_CLOSED": "09-04-2018 ",
		 "ACTUAL_RTW_DATE": "08-13-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dang",
		 "CLAIMANT_FIRST_NAME": "Mai",
		 "CLAIM_NO": "2021-03-10-0018-VP-01",
		 "DURATION": 271,
		 "DISABILITY_START_DATE": "03-10-2021 ",
		 "DATE_CLAIM_CLOSED": "12-06-2021 ",
		 "ACTUAL_RTW_DATE": "12-06-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dang",
		 "CLAIMANT_FIRST_NAME": "Mai",
		 "CLAIM_NO": "2019-07-08-0670-VP-01",
		 "DURATION": -44,
		 "DISABILITY_START_DATE": "06-24-2019 ",
		 "DATE_CLAIM_CLOSED": "05-11-2020 ",
		 "ACTUAL_RTW_DATE": "05-11-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dang",
		 "CLAIMANT_FIRST_NAME": "Loan",
		 "CLAIM_NO": "2021-06-21-0548-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "07-06-2021 ",
		 "DATE_CLAIM_CLOSED": "09-09-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dang",
		 "CLAIMANT_FIRST_NAME": "Anne-Mai",
		 "CLAIM_NO": "2014-08-06-0307-VP-01",
		 "DURATION": 520,
		 "DISABILITY_START_DATE": "07-30-2014 ",
		 "DATE_CLAIM_CLOSED": "01-22-2016 ",
		 "ACTUAL_RTW_DATE": "01-01-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Danh",
		 "CLAIMANT_FIRST_NAME": "Trieng",
		 "CLAIM_NO": "2019-06-10-0002-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "07-08-2019 ",
		 "DATE_CLAIM_CLOSED": "08-27-2019 ",
		 "ACTUAL_RTW_DATE": "08-26-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Daniel",
		 "CLAIMANT_FIRST_NAME": "Misrak",
		 "CLAIM_NO": "2014-07-17-0347-VP-01",
		 "DURATION": 23,
		 "DISABILITY_START_DATE": "07-16-2014 ",
		 "DATE_CLAIM_CLOSED": "12-05-2014 ",
		 "ACTUAL_RTW_DATE": "08-08-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dao",
		 "CLAIMANT_FIRST_NAME": "Patrick",
		 "CLAIM_NO": "2022-10-12-0574-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "10-11-2022 ",
		 "DATE_CLAIM_CLOSED": "10-31-2022 ",
		 "ACTUAL_RTW_DATE": "10-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Darling",
		 "CLAIMANT_FIRST_NAME": "Kristina",
		 "CLAIM_NO": "2013-01-05-0300-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "12-03-2012 ",
		 "DATE_CLAIM_CLOSED": "02-09-2013 ",
		 "ACTUAL_RTW_DATE": "01-07-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "David",
		 "CLAIMANT_FIRST_NAME": "Melanie",
		 "CLAIM_NO": "2020-04-14-0505-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "05-12-2020 ",
		 "DATE_CLAIM_CLOSED": "05-12-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "David",
		 "CLAIMANT_FIRST_NAME": "Melanie",
		 "CLAIM_NO": "2020-07-06-0116-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "07-31-2020 ",
		 "DATE_CLAIM_CLOSED": "09-28-2020 ",
		 "ACTUAL_RTW_DATE": "09-25-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "David",
		 "CLAIMANT_FIRST_NAME": "Rafael",
		 "CLAIM_NO": "2021-09-03-0766-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "09-02-2021 ",
		 "DATE_CLAIM_CLOSED": "10-25-2021 ",
		 "ACTUAL_RTW_DATE": "09-20-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "David",
		 "CLAIMANT_FIRST_NAME": "Ashley",
		 "CLAIM_NO": "2014-01-13-0348-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "02-10-2014 ",
		 "DATE_CLAIM_CLOSED": "05-21-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Davidson",
		 "CLAIMANT_FIRST_NAME": "Karen",
		 "CLAIM_NO": "2017-12-20-0388-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "12-11-2017 ",
		 "DATE_CLAIM_CLOSED": "03-02-2018 ",
		 "ACTUAL_RTW_DATE": "03-02-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Davies",
		 "CLAIMANT_FIRST_NAME": "Ronald",
		 "CLAIM_NO": "2013-01-07-0379-VP-01",
		 "DURATION": 99,
		 "DISABILITY_START_DATE": "01-22-2013 ",
		 "DATE_CLAIM_CLOSED": "06-10-2013 ",
		 "ACTUAL_RTW_DATE": "05-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Davis",
		 "CLAIMANT_FIRST_NAME": "Belinda",
		 "CLAIM_NO": "2021-09-28-0427-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "11-05-2021 ",
		 "DATE_CLAIM_CLOSED": "12-20-2021 ",
		 "ACTUAL_RTW_DATE": "12-17-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Davis",
		 "CLAIMANT_FIRST_NAME": "Malany",
		 "CLAIM_NO": "2022-04-06-0455-VP-01",
		 "DURATION": 38,
		 "DISABILITY_START_DATE": "04-01-2022 ",
		 "DATE_CLAIM_CLOSED": "05-16-2022 ",
		 "ACTUAL_RTW_DATE": "05-09-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Davis",
		 "CLAIMANT_FIRST_NAME": "Michael",
		 "CLAIM_NO": "2013-05-08-0409-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "05-02-2013 ",
		 "DATE_CLAIM_CLOSED": "08-03-2013 ",
		 "ACTUAL_RTW_DATE": "06-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Davis",
		 "CLAIMANT_FIRST_NAME": "Rebecca",
		 "CLAIM_NO": "2019-11-25-0361-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "12-26-2019 ",
		 "DATE_CLAIM_CLOSED": "02-07-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Davis",
		 "CLAIMANT_FIRST_NAME": "Belinda",
		 "CLAIM_NO": "2022-10-01-0002-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "10-28-2022 ",
		 "DATE_CLAIM_CLOSED": "12-27-2022 ",
		 "ACTUAL_RTW_DATE": "12-24-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Davis",
		 "CLAIMANT_FIRST_NAME": "Glenn",
		 "CLAIM_NO": "2017-07-14-0235-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "06-12-2017 ",
		 "DATE_CLAIM_CLOSED": "07-24-2017 ",
		 "ACTUAL_RTW_DATE": "07-10-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Davis",
		 "CLAIMANT_FIRST_NAME": "Glenn",
		 "CLAIM_NO": "2017-05-15-0556-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "04-13-2017 ",
		 "DATE_CLAIM_CLOSED": "08-08-2017 ",
		 "ACTUAL_RTW_DATE": "05-12-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Davis",
		 "CLAIMANT_FIRST_NAME": "Belinda",
		 "CLAIM_NO": "2018-02-05-0297-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "02-13-2018 ",
		 "DATE_CLAIM_CLOSED": "03-05-2018 ",
		 "ACTUAL_RTW_DATE": "02-28-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Davis",
		 "CLAIMANT_FIRST_NAME": "Julie",
		 "CLAIM_NO": "2015-01-28-0294-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "01-23-2015 ",
		 "DATE_CLAIM_CLOSED": "08-04-2015 ",
		 "ACTUAL_RTW_DATE": "02-02-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Davydova",
		 "CLAIMANT_FIRST_NAME": "Oxana",
		 "CLAIM_NO": "2020-11-24-1056-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "11-09-2020 ",
		 "DATE_CLAIM_CLOSED": "01-12-2021 ",
		 "ACTUAL_RTW_DATE": "01-11-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Day",
		 "CLAIMANT_FIRST_NAME": "Casey",
		 "CLAIM_NO": "2022-12-22-0843-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "12-26-2022 ",
		 "DATE_CLAIM_CLOSED": "03-18-2023 ",
		 "ACTUAL_RTW_DATE": "02-28-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Day",
		 "CLAIMANT_FIRST_NAME": "Casey",
		 "CLAIM_NO": "2020-05-26-0702-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "05-21-2020 ",
		 "DATE_CLAIM_CLOSED": "07-21-2020 ",
		 "ACTUAL_RTW_DATE": "07-20-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dayani",
		 "CLAIMANT_FIRST_NAME": "Honey",
		 "CLAIM_NO": "2017-04-17-0643-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "04-24-2017 ",
		 "DATE_CLAIM_CLOSED": "07-10-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dayani",
		 "CLAIMANT_FIRST_NAME": "Honey",
		 "CLAIM_NO": "2020-04-06-0685-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "04-16-2020 ",
		 "DATE_CLAIM_CLOSED": "06-17-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Daza",
		 "CLAIMANT_FIRST_NAME": "Saribel",
		 "CLAIM_NO": "2016-09-26-0509-VP-01",
		 "DURATION": 52,
		 "DISABILITY_START_DATE": "10-10-2016 ",
		 "DATE_CLAIM_CLOSED": "12-12-2016 ",
		 "ACTUAL_RTW_DATE": "12-01-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "De Groot",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2020-07-20-0604-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "07-23-2020 ",
		 "DATE_CLAIM_CLOSED": "09-09-2020 ",
		 "ACTUAL_RTW_DATE": "09-07-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "De Guzman",
		 "CLAIMANT_FIRST_NAME": "Rechilda",
		 "CLAIM_NO": "2019-10-04-0381-VP-01",
		 "DURATION": 83,
		 "DISABILITY_START_DATE": "10-22-2019 ",
		 "DATE_CLAIM_CLOSED": "01-31-2020 ",
		 "ACTUAL_RTW_DATE": "01-13-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "De Guzman",
		 "CLAIMANT_FIRST_NAME": "Lilibeth",
		 "CLAIM_NO": "2013-01-14-0504-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "01-14-2013 ",
		 "DATE_CLAIM_CLOSED": "05-16-2013 ",
		 "ACTUAL_RTW_DATE": "03-09-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "De La Fe",
		 "CLAIMANT_FIRST_NAME": "Joseph",
		 "CLAIM_NO": "2013-01-05-0226-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "12-03-2012 ",
		 "DATE_CLAIM_CLOSED": "01-20-2013 ",
		 "ACTUAL_RTW_DATE": "12-17-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "De La Mora",
		 "CLAIMANT_FIRST_NAME": "Ramona",
		 "CLAIM_NO": "2022-02-01-1040-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "01-31-2022 ",
		 "DATE_CLAIM_CLOSED": "02-09-2022 ",
		 "ACTUAL_RTW_DATE": "02-07-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "De La O",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2022-11-08-0919-VP-01",
		 "DURATION": 181,
		 "DISABILITY_START_DATE": "09-13-2022 ",
		 "DATE_CLAIM_CLOSED": "03-28-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "De La Rosa Fuentes",
		 "CLAIMANT_FIRST_NAME": "Artemio",
		 "CLAIM_NO": "2019-10-29-0427-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "10-29-2019 ",
		 "DATE_CLAIM_CLOSED": "11-13-2019 ",
		 "ACTUAL_RTW_DATE": "11-11-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "De La Torre",
		 "CLAIMANT_FIRST_NAME": "Ruben",
		 "CLAIM_NO": "2022-05-24-0951-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "07-25-2022 ",
		 "DATE_CLAIM_CLOSED": "09-12-2022 ",
		 "ACTUAL_RTW_DATE": "09-06-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "De Leon",
		 "CLAIMANT_FIRST_NAME": "Otto",
		 "CLAIM_NO": "2013-07-22-0258-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "07-15-2013 ",
		 "DATE_CLAIM_CLOSED": "09-23-2013 ",
		 "ACTUAL_RTW_DATE": "07-24-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "De Leon",
		 "CLAIMANT_FIRST_NAME": "Sheila",
		 "CLAIM_NO": "2013-01-05-1008-VP-01",
		 "DURATION": 83,
		 "DISABILITY_START_DATE": "09-04-2012 ",
		 "DATE_CLAIM_CLOSED": "01-15-2014 ",
		 "ACTUAL_RTW_DATE": "11-26-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "De Leon",
		 "CLAIMANT_FIRST_NAME": "Sheila",
		 "CLAIM_NO": "2018-12-04-0524-VP-01",
		 "DURATION": 52,
		 "DISABILITY_START_DATE": "12-03-2018 ",
		 "DATE_CLAIM_CLOSED": "02-01-2019 ",
		 "ACTUAL_RTW_DATE": "01-24-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "De Luca",
		 "CLAIMANT_FIRST_NAME": "Amalia",
		 "CLAIM_NO": "2020-10-28-0753-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "11-30-2020 ",
		 "DATE_CLAIM_CLOSED": "01-24-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "De Luna",
		 "CLAIMANT_FIRST_NAME": "Elaina",
		 "CLAIM_NO": "2014-05-06-0808-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "05-23-2014 ",
		 "DATE_CLAIM_CLOSED": "06-03-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "De Luna",
		 "CLAIMANT_FIRST_NAME": "Elaina",
		 "CLAIM_NO": "2018-10-12-0010-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "10-10-2018 ",
		 "DATE_CLAIM_CLOSED": "10-24-2018 ",
		 "ACTUAL_RTW_DATE": "10-22-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "De Luna",
		 "CLAIMANT_FIRST_NAME": "Elaina",
		 "CLAIM_NO": "2014-09-26-0235-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "09-18-2014 ",
		 "DATE_CLAIM_CLOSED": "10-23-2014 ",
		 "ACTUAL_RTW_DATE": "10-06-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "De Paz",
		 "CLAIMANT_FIRST_NAME": "Jorge",
		 "CLAIM_NO": "2019-07-18-0124-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "08-05-2019 ",
		 "DATE_CLAIM_CLOSED": "10-29-2019 ",
		 "ACTUAL_RTW_DATE": "10-21-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "De Paz",
		 "CLAIMANT_FIRST_NAME": "Jorge",
		 "CLAIM_NO": "2021-06-10-0766-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "06-14-2021 ",
		 "DATE_CLAIM_CLOSED": "07-30-2021 ",
		 "ACTUAL_RTW_DATE": "07-26-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "De Paz",
		 "CLAIMANT_FIRST_NAME": "Jorge",
		 "CLAIM_NO": "2021-12-01-0360-VP-01",
		 "DURATION": 52,
		 "DISABILITY_START_DATE": "12-17-2021 ",
		 "DATE_CLAIM_CLOSED": "02-08-2022 ",
		 "ACTUAL_RTW_DATE": "02-07-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dean",
		 "CLAIMANT_FIRST_NAME": "Hanna",
		 "CLAIM_NO": "2022-10-10-0918-VP-01",
		 "DURATION": 150,
		 "DISABILITY_START_DATE": "12-02-2022 ",
		 "DATE_CLAIM_CLOSED": "02-18-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dean",
		 "CLAIMANT_FIRST_NAME": "Hanna",
		 "CLAIM_NO": "2020-07-13-0640-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "08-10-2020 ",
		 "DATE_CLAIM_CLOSED": "10-09-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Debaise",
		 "CLAIMANT_FIRST_NAME": "Kimberly",
		 "CLAIM_NO": "2019-06-19-0651-VP-01",
		 "DURATION": 88,
		 "DISABILITY_START_DATE": "06-28-2019 ",
		 "DATE_CLAIM_CLOSED": "09-30-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Decena",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2013-02-07-0394-VP-01",
		 "DURATION": 152,
		 "DISABILITY_START_DATE": "11-07-2012 ",
		 "DATE_CLAIM_CLOSED": "08-03-2013 ",
		 "ACTUAL_RTW_DATE": "04-08-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Deckert",
		 "CLAIMANT_FIRST_NAME": "Emily",
		 "CLAIM_NO": "2021-12-17-0606-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "01-04-2022 ",
		 "DATE_CLAIM_CLOSED": "03-02-2022 ",
		 "ACTUAL_RTW_DATE": "03-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Decoria",
		 "CLAIMANT_FIRST_NAME": "Kelly",
		 "CLAIM_NO": "2017-01-16-0247-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "01-09-2017 ",
		 "DATE_CLAIM_CLOSED": "01-27-2017 ",
		 "ACTUAL_RTW_DATE": "01-16-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Decoria",
		 "CLAIMANT_FIRST_NAME": "Kelly",
		 "CLAIM_NO": "2016-09-26-0293-VP-01",
		 "DURATION": 30,
		 "DISABILITY_START_DATE": "09-20-2016 ",
		 "DATE_CLAIM_CLOSED": "11-08-2016 ",
		 "ACTUAL_RTW_DATE": "10-20-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Deep",
		 "CLAIMANT_FIRST_NAME": "Raman",
		 "CLAIM_NO": "2018-10-16-0705-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "11-08-2018 ",
		 "DATE_CLAIM_CLOSED": "01-25-2019 ",
		 "ACTUAL_RTW_DATE": "01-07-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Defries",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2017-11-10-0492-VP-01",
		 "DURATION": 98,
		 "DISABILITY_START_DATE": "11-13-2017 ",
		 "DATE_CLAIM_CLOSED": "02-20-2018 ",
		 "ACTUAL_RTW_DATE": "02-19-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Defries",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2015-05-26-0281-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "06-15-2015 ",
		 "DATE_CLAIM_CLOSED": "08-25-2015 ",
		 "ACTUAL_RTW_DATE": "08-24-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Degraffenreid",
		 "CLAIMANT_FIRST_NAME": "Krista",
		 "CLAIM_NO": "2021-03-17-0672-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "04-19-2021 ",
		 "DATE_CLAIM_CLOSED": "08-06-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Degraffenreid",
		 "CLAIMANT_FIRST_NAME": "Krista",
		 "CLAIM_NO": "2020-09-29-0216-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "09-28-2020 ",
		 "DATE_CLAIM_CLOSED": "10-14-2020 ",
		 "ACTUAL_RTW_DATE": "10-13-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Deguzman",
		 "CLAIMANT_FIRST_NAME": "Graciano",
		 "CLAIM_NO": "2018-11-07-0395-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "11-01-2018 ",
		 "DATE_CLAIM_CLOSED": "11-27-2018 ",
		 "ACTUAL_RTW_DATE": "11-19-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Deifel",
		 "CLAIMANT_FIRST_NAME": "Erin",
		 "CLAIM_NO": "2022-03-14-0855-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "04-11-2022 ",
		 "DATE_CLAIM_CLOSED": "05-24-2022 ",
		 "ACTUAL_RTW_DATE": "05-24-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Deifel",
		 "CLAIMANT_FIRST_NAME": "Erin",
		 "CLAIM_NO": "2018-08-23-0273-VP-01",
		 "DURATION": 90,
		 "DISABILITY_START_DATE": "09-07-2018 ",
		 "DATE_CLAIM_CLOSED": "10-18-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Deitz",
		 "CLAIMANT_FIRST_NAME": "Andrea",
		 "CLAIM_NO": "2015-03-11-0523-VP-01",
		 "DURATION": 88,
		 "DISABILITY_START_DATE": "03-05-2015 ",
		 "DATE_CLAIM_CLOSED": "08-04-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Del Rosario",
		 "CLAIMANT_FIRST_NAME": "Janet",
		 "CLAIM_NO": "2013-09-03-0187-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "08-28-2013 ",
		 "DATE_CLAIM_CLOSED": "10-30-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Del Rosario",
		 "CLAIMANT_FIRST_NAME": "Janet",
		 "CLAIM_NO": "2013-11-11-0332-VP-01",
		 "DURATION": 36,
		 "DISABILITY_START_DATE": "11-11-2013 ",
		 "DATE_CLAIM_CLOSED": "03-19-2014 ",
		 "ACTUAL_RTW_DATE": "12-17-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Del Rosario",
		 "CLAIMANT_FIRST_NAME": "Jovan",
		 "CLAIM_NO": "2021-04-17-0010-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "05-26-2021 ",
		 "DATE_CLAIM_CLOSED": "10-15-2021 ",
		 "ACTUAL_RTW_DATE": "07-06-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Del Rosario",
		 "CLAIMANT_FIRST_NAME": "Janet",
		 "CLAIM_NO": "2018-01-25-0157-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "01-10-2018 ",
		 "DATE_CLAIM_CLOSED": "03-19-2018 ",
		 "ACTUAL_RTW_DATE": "01-19-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Del Rosario",
		 "CLAIMANT_FIRST_NAME": "Jovan",
		 "CLAIM_NO": "2022-08-01-0337-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "08-09-2022 ",
		 "DATE_CLAIM_CLOSED": "09-28-2022 ",
		 "ACTUAL_RTW_DATE": "09-20-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Del Rosario",
		 "CLAIMANT_FIRST_NAME": "Catalina",
		 "CLAIM_NO": "2018-07-27-0054-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "07-18-2018 ",
		 "DATE_CLAIM_CLOSED": "10-02-2018 ",
		 "ACTUAL_RTW_DATE": "09-24-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dela Cruz",
		 "CLAIMANT_FIRST_NAME": "Rossana",
		 "CLAIM_NO": "2014-10-24-0384-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "10-20-2014 ",
		 "DATE_CLAIM_CLOSED": "11-12-2014 ",
		 "ACTUAL_RTW_DATE": "10-27-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dela Cruz",
		 "CLAIMANT_FIRST_NAME": "Rosemary",
		 "CLAIM_NO": "2021-07-12-0715-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "07-06-2021 ",
		 "DATE_CLAIM_CLOSED": "11-02-2021 ",
		 "ACTUAL_RTW_DATE": "09-07-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Delahunty",
		 "CLAIMANT_FIRST_NAME": "Michael",
		 "CLAIM_NO": "2018-01-17-0572-VP-01",
		 "DURATION": 36,
		 "DISABILITY_START_DATE": "01-15-2018 ",
		 "DATE_CLAIM_CLOSED": "02-21-2018 ",
		 "ACTUAL_RTW_DATE": "02-20-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Delaney",
		 "CLAIMANT_FIRST_NAME": "Francesca",
		 "CLAIM_NO": "2021-03-04-0827-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "03-25-2021 ",
		 "DATE_CLAIM_CLOSED": "05-06-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Delgado",
		 "CLAIMANT_FIRST_NAME": "Carlo",
		 "CLAIM_NO": "2017-03-15-0246-VP-01",
		 "DURATION": 134,
		 "DISABILITY_START_DATE": "03-13-2017 ",
		 "DATE_CLAIM_CLOSED": "07-31-2017 ",
		 "ACTUAL_RTW_DATE": "07-25-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Delgado",
		 "CLAIMANT_FIRST_NAME": "Stephanie",
		 "CLAIM_NO": "2021-02-19-0821-VP-01",
		 "DURATION": 50,
		 "DISABILITY_START_DATE": "03-08-2021 ",
		 "DATE_CLAIM_CLOSED": "04-28-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Delgado",
		 "CLAIMANT_FIRST_NAME": "Juvy Lee Diaz",
		 "CLAIM_NO": "2022-09-20-0972-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "09-12-2022 ",
		 "DATE_CLAIM_CLOSED": "10-12-2022 ",
		 "ACTUAL_RTW_DATE": "09-20-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Delgado",
		 "CLAIMANT_FIRST_NAME": "Juvy Lee Diaz",
		 "CLAIM_NO": "2021-05-06-0381-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "05-24-2021 ",
		 "DATE_CLAIM_CLOSED": "07-26-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Deluna",
		 "CLAIMANT_FIRST_NAME": "Juan",
		 "CLAIM_NO": "2020-11-21-0010-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "11-20-2020 ",
		 "DATE_CLAIM_CLOSED": "01-05-2021 ",
		 "ACTUAL_RTW_DATE": "01-04-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Deluna",
		 "CLAIMANT_FIRST_NAME": "Juan",
		 "CLAIM_NO": "2016-01-04-0280-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "01-14-2016 ",
		 "DATE_CLAIM_CLOSED": "02-17-2016 ",
		 "ACTUAL_RTW_DATE": "02-15-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Demos",
		 "CLAIMANT_FIRST_NAME": "Jill",
		 "CLAIM_NO": "2018-06-08-0547-VP-01",
		 "DURATION": 25,
		 "DISABILITY_START_DATE": "06-14-2018 ",
		 "DATE_CLAIM_CLOSED": "07-10-2018 ",
		 "ACTUAL_RTW_DATE": "07-09-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Deng",
		 "CLAIMANT_FIRST_NAME": "Suihan",
		 "CLAIM_NO": "2022-05-04-0941-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "04-27-2022 ",
		 "DATE_CLAIM_CLOSED": "06-06-2022 ",
		 "ACTUAL_RTW_DATE": "05-25-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Denis",
		 "CLAIMANT_FIRST_NAME": "Ana",
		 "CLAIM_NO": "2013-06-19-0301-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "06-17-2013 ",
		 "DATE_CLAIM_CLOSED": "10-08-2013 ",
		 "ACTUAL_RTW_DATE": "07-15-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Denis",
		 "CLAIMANT_FIRST_NAME": "Ana",
		 "CLAIM_NO": "2013-10-22-0327-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "10-21-2013 ",
		 "DATE_CLAIM_CLOSED": "02-18-2014 ",
		 "ACTUAL_RTW_DATE": "11-12-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Denis",
		 "CLAIMANT_FIRST_NAME": "Ana",
		 "CLAIM_NO": "2013-08-27-0177-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "08-23-2013 ",
		 "DATE_CLAIM_CLOSED": "01-07-2014 ",
		 "ACTUAL_RTW_DATE": "09-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Denver",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2019-07-15-0594-VP-01",
		 "DURATION": 371,
		 "DISABILITY_START_DATE": "07-22-2019 ",
		 "DATE_CLAIM_CLOSED": "07-27-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Denver",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2018-11-05-0426-VP-01",
		 "DURATION": 158,
		 "DISABILITY_START_DATE": "10-25-2018 ",
		 "DATE_CLAIM_CLOSED": "04-02-2019 ",
		 "ACTUAL_RTW_DATE": "04-01-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Derain",
		 "CLAIMANT_FIRST_NAME": "Dana Lynn",
		 "CLAIM_NO": "2021-08-18-1045-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "08-17-2021 ",
		 "DATE_CLAIM_CLOSED": "10-15-2021 ",
		 "ACTUAL_RTW_DATE": "10-14-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Derain",
		 "CLAIMANT_FIRST_NAME": "Dana Lynn",
		 "CLAIM_NO": "2016-10-14-0168-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "10-17-2016 ",
		 "DATE_CLAIM_CLOSED": "11-14-2016 ",
		 "ACTUAL_RTW_DATE": "11-07-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Derain",
		 "CLAIMANT_FIRST_NAME": "Joshua",
		 "CLAIM_NO": "2018-03-20-0317-VP-01",
		 "DURATION": 116,
		 "DISABILITY_START_DATE": "03-21-2018 ",
		 "DATE_CLAIM_CLOSED": "07-19-2018 ",
		 "ACTUAL_RTW_DATE": "07-15-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Derain",
		 "CLAIMANT_FIRST_NAME": "Dana Lynn",
		 "CLAIM_NO": "2013-09-19-0318-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "09-14-2013 ",
		 "DATE_CLAIM_CLOSED": "02-06-2014 ",
		 "ACTUAL_RTW_DATE": "09-23-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Derain",
		 "CLAIMANT_FIRST_NAME": "Dana Lynn",
		 "CLAIM_NO": "2013-01-25-0304-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "01-22-2013 ",
		 "DATE_CLAIM_CLOSED": "05-16-2013 ",
		 "ACTUAL_RTW_DATE": "02-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Derain",
		 "CLAIMANT_FIRST_NAME": "Dana Lynn",
		 "CLAIM_NO": "2013-05-14-0361-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "05-09-2013 ",
		 "DATE_CLAIM_CLOSED": "08-14-2013 ",
		 "ACTUAL_RTW_DATE": "05-31-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dereniak",
		 "CLAIMANT_FIRST_NAME": "Andreana",
		 "CLAIM_NO": "2014-03-31-0384-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "04-28-2014 ",
		 "DATE_CLAIM_CLOSED": "10-16-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dereniak",
		 "CLAIMANT_FIRST_NAME": "Andreana",
		 "CLAIM_NO": "2018-04-16-0464-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "04-20-2018 ",
		 "DATE_CLAIM_CLOSED": "06-20-2018 ",
		 "ACTUAL_RTW_DATE": "06-18-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dereniak",
		 "CLAIMANT_FIRST_NAME": "Andreana",
		 "CLAIM_NO": "2017-09-19-0343-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "09-27-2017 ",
		 "DATE_CLAIM_CLOSED": "10-17-2017 ",
		 "ACTUAL_RTW_DATE": "10-12-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dereniak",
		 "CLAIMANT_FIRST_NAME": "Andreana",
		 "CLAIM_NO": "2017-05-26-0258-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "06-19-2017 ",
		 "DATE_CLAIM_CLOSED": "06-30-2017 ",
		 "ACTUAL_RTW_DATE": "06-27-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Deshpande",
		 "CLAIMANT_FIRST_NAME": "Payal",
		 "CLAIM_NO": "2019-03-11-0523-VP-01",
		 "DURATION": 66,
		 "DISABILITY_START_DATE": "03-11-2019 ",
		 "DATE_CLAIM_CLOSED": "05-28-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Desousa",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2017-06-15-0009-VP-01",
		 "DURATION": 365,
		 "DISABILITY_START_DATE": "06-15-2017 ",
		 "DATE_CLAIM_CLOSED": "06-18-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Desousa",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2017-01-30-0628-VP-01",
		 "DURATION": 53,
		 "DISABILITY_START_DATE": "01-26-2017 ",
		 "DATE_CLAIM_CLOSED": "04-03-2017 ",
		 "ACTUAL_RTW_DATE": "03-20-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Desousa",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2017-04-03-0613-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "03-30-2017 ",
		 "DATE_CLAIM_CLOSED": "06-12-2017 ",
		 "ACTUAL_RTW_DATE": "04-11-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Despi",
		 "CLAIMANT_FIRST_NAME": "Danny",
		 "CLAIM_NO": "2021-03-03-0299-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "02-22-2021 ",
		 "DATE_CLAIM_CLOSED": "02-22-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Deuber",
		 "CLAIMANT_FIRST_NAME": "Nicole",
		 "CLAIM_NO": "2015-04-08-0215-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "04-13-2015 ",
		 "DATE_CLAIM_CLOSED": "06-09-2015 ",
		 "ACTUAL_RTW_DATE": "05-18-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Devera",
		 "CLAIMANT_FIRST_NAME": "Bienvenido",
		 "CLAIM_NO": "2021-08-23-1109-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "09-17-2021 ",
		 "DATE_CLAIM_CLOSED": "10-01-2021 ",
		 "ACTUAL_RTW_DATE": "09-24-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Devine",
		 "CLAIMANT_FIRST_NAME": "Heidi",
		 "CLAIM_NO": "2014-12-12-0190-VP-01",
		 "DURATION": 144,
		 "DISABILITY_START_DATE": "12-11-2014 ",
		 "DATE_CLAIM_CLOSED": "05-20-2015 ",
		 "ACTUAL_RTW_DATE": "05-04-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Devine",
		 "CLAIMANT_FIRST_NAME": "Heidi",
		 "CLAIM_NO": "2021-08-10-1188-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "09-09-2021 ",
		 "DATE_CLAIM_CLOSED": "10-01-2021 ",
		 "ACTUAL_RTW_DATE": "09-27-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Devine",
		 "CLAIMANT_FIRST_NAME": "Heidi",
		 "CLAIM_NO": "2013-03-18-0393-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "03-18-2013 ",
		 "DATE_CLAIM_CLOSED": "05-07-2013 ",
		 "ACTUAL_RTW_DATE": "04-29-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Devine",
		 "CLAIMANT_FIRST_NAME": "Heidi",
		 "CLAIM_NO": "2016-10-18-0485-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "10-31-2016 ",
		 "DATE_CLAIM_CLOSED": "12-12-2016 ",
		 "ACTUAL_RTW_DATE": "11-28-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Devine",
		 "CLAIMANT_FIRST_NAME": "Heidi",
		 "CLAIM_NO": "2013-10-04-0181-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "10-15-2013 ",
		 "DATE_CLAIM_CLOSED": "01-08-2014 ",
		 "ACTUAL_RTW_DATE": "12-02-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Devine",
		 "CLAIMANT_FIRST_NAME": "Heidi",
		 "CLAIM_NO": "2019-05-07-0195-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "06-07-2019 ",
		 "DATE_CLAIM_CLOSED": "07-25-2019 ",
		 "ACTUAL_RTW_DATE": "07-22-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dhanik",
		 "CLAIMANT_FIRST_NAME": "Bhartiben",
		 "CLAIM_NO": "2019-01-04-0458-VP-01",
		 "DURATION": 144,
		 "DISABILITY_START_DATE": "01-07-2019 ",
		 "DATE_CLAIM_CLOSED": "06-10-2019 ",
		 "ACTUAL_RTW_DATE": "05-31-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dhanoa",
		 "CLAIMANT_FIRST_NAME": "Nirmal",
		 "CLAIM_NO": "2013-01-05-4592-VP-01",
		 "DURATION": 365,
		 "DISABILITY_START_DATE": "07-25-2011 ",
		 "DATE_CLAIM_CLOSED": "01-15-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dhillon",
		 "CLAIMANT_FIRST_NAME": "Harmanpreet",
		 "CLAIM_NO": "2022-10-16-0047-VP-01",
		 "DURATION": 109,
		 "DISABILITY_START_DATE": "10-13-2022 ",
		 "DATE_CLAIM_CLOSED": "05-03-2023 ",
		 "ACTUAL_RTW_DATE": "01-30-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dhillon",
		 "CLAIMANT_FIRST_NAME": "Sandeep",
		 "CLAIM_NO": "2021-11-22-1149-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "01-04-2022 ",
		 "DATE_CLAIM_CLOSED": "03-17-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dhiman",
		 "CLAIMANT_FIRST_NAME": "Shivani",
		 "CLAIM_NO": "2015-09-04-0003-VP-01",
		 "DURATION": 135,
		 "DISABILITY_START_DATE": "09-04-2015 ",
		 "DATE_CLAIM_CLOSED": "12-14-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dhiman",
		 "CLAIMANT_FIRST_NAME": "Shivani",
		 "CLAIM_NO": "2021-08-15-0055-VP-01",
		 "DURATION": 75,
		 "DISABILITY_START_DATE": "08-16-2021 ",
		 "DATE_CLAIM_CLOSED": "11-02-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dhiman",
		 "CLAIMANT_FIRST_NAME": "Shivani",
		 "CLAIM_NO": "2014-01-02-0498-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "01-08-2014 ",
		 "DATE_CLAIM_CLOSED": "02-24-2014 ",
		 "ACTUAL_RTW_DATE": "01-22-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Di Corpo",
		 "CLAIMANT_FIRST_NAME": "Gina",
		 "CLAIM_NO": "2015-02-17-0303-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "02-18-2015 ",
		 "DATE_CLAIM_CLOSED": "05-14-2015 ",
		 "ACTUAL_RTW_DATE": "04-27-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Di Corpo",
		 "CLAIMANT_FIRST_NAME": "Gina",
		 "CLAIM_NO": "2013-01-05-0489-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "11-27-2012 ",
		 "DATE_CLAIM_CLOSED": "02-09-2013 ",
		 "ACTUAL_RTW_DATE": "01-20-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Diaz",
		 "CLAIMANT_FIRST_NAME": "Leticia",
		 "CLAIM_NO": "2015-04-06-0242-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "04-10-2015 ",
		 "DATE_CLAIM_CLOSED": "06-25-2015 ",
		 "ACTUAL_RTW_DATE": "06-08-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Diaz",
		 "CLAIMANT_FIRST_NAME": "Leticia",
		 "CLAIM_NO": "2017-02-13-0295-VP-01",
		 "DURATION": 326,
		 "DISABILITY_START_DATE": "02-11-2017 ",
		 "DATE_CLAIM_CLOSED": "03-01-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Diaz",
		 "CLAIMANT_FIRST_NAME": "Mercedes",
		 "CLAIM_NO": "2016-01-21-0055-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "01-18-2016 ",
		 "DATE_CLAIM_CLOSED": "02-01-2016 ",
		 "ACTUAL_RTW_DATE": "01-25-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Diaz",
		 "CLAIMANT_FIRST_NAME": "Yolanda",
		 "CLAIM_NO": "2015-01-05-0426-VP-01",
		 "DURATION": 115,
		 "DISABILITY_START_DATE": "12-19-2014 ",
		 "DATE_CLAIM_CLOSED": "08-18-2015 ",
		 "ACTUAL_RTW_DATE": "04-13-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Diaz",
		 "CLAIMANT_FIRST_NAME": "Dava",
		 "CLAIM_NO": "2013-09-25-0202-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "09-18-2013 ",
		 "DATE_CLAIM_CLOSED": "11-12-2014 ",
		 "ACTUAL_RTW_DATE": "10-10-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Diaz De Perez",
		 "CLAIMANT_FIRST_NAME": "Doris",
		 "CLAIM_NO": "2017-02-24-0457-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "02-24-2017 ",
		 "DATE_CLAIM_CLOSED": "04-17-2017 ",
		 "ACTUAL_RTW_DATE": "03-06-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Diaz-Castillo",
		 "CLAIMANT_FIRST_NAME": "Omero",
		 "CLAIM_NO": "2013-01-23-0356-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "01-21-2013 ",
		 "DATE_CLAIM_CLOSED": "03-25-2013 ",
		 "ACTUAL_RTW_DATE": "03-18-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dicaprio",
		 "CLAIMANT_FIRST_NAME": "Alexandra",
		 "CLAIM_NO": "2021-01-12-0521-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "01-20-2021 ",
		 "DATE_CLAIM_CLOSED": "03-10-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dien",
		 "CLAIMANT_FIRST_NAME": "Thien Nga",
		 "CLAIM_NO": "2019-12-09-0336-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "12-16-2019 ",
		 "DATE_CLAIM_CLOSED": "02-14-2020 ",
		 "ACTUAL_RTW_DATE": "02-13-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dien",
		 "CLAIMANT_FIRST_NAME": "Cuong",
		 "CLAIM_NO": "2017-01-04-0536-VP-01",
		 "DURATION": 105,
		 "DISABILITY_START_DATE": "01-05-2017 ",
		 "DATE_CLAIM_CLOSED": "06-25-2018 ",
		 "ACTUAL_RTW_DATE": "04-20-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dieu",
		 "CLAIMANT_FIRST_NAME": "Tien",
		 "CLAIM_NO": "2020-09-08-0160-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "08-28-2020 ",
		 "DATE_CLAIM_CLOSED": "09-18-2020 ",
		 "ACTUAL_RTW_DATE": "09-16-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ding",
		 "CLAIMANT_FIRST_NAME": "Ni",
		 "CLAIM_NO": "2015-09-01-0293-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "09-28-2015 ",
		 "DATE_CLAIM_CLOSED": "11-16-2015 ",
		 "ACTUAL_RTW_DATE": "11-09-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dingle",
		 "CLAIMANT_FIRST_NAME": "Carol",
		 "CLAIM_NO": "2022-11-15-0505-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "11-15-2022 ",
		 "DATE_CLAIM_CLOSED": "12-05-2022 ",
		 "ACTUAL_RTW_DATE": "11-28-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dinh",
		 "CLAIMANT_FIRST_NAME": "Bich",
		 "CLAIM_NO": "2018-08-21-0611-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "08-20-2018 ",
		 "DATE_CLAIM_CLOSED": "09-06-2018 ",
		 "ACTUAL_RTW_DATE": "09-03-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dishaw",
		 "CLAIMANT_FIRST_NAME": "Ryan",
		 "CLAIM_NO": "2020-01-15-0783-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "02-05-2020 ",
		 "DATE_CLAIM_CLOSED": "02-24-2020 ",
		 "ACTUAL_RTW_DATE": "02-19-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Distenfield",
		 "CLAIMANT_FIRST_NAME": "Lisa",
		 "CLAIM_NO": "2019-03-04-0839-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "02-26-2019 ",
		 "DATE_CLAIM_CLOSED": "04-15-2019 ",
		 "ACTUAL_RTW_DATE": "04-09-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dixon",
		 "CLAIMANT_FIRST_NAME": "Lynn",
		 "CLAIM_NO": "2013-01-23-0410-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "02-01-2013 ",
		 "DATE_CLAIM_CLOSED": "05-09-2013 ",
		 "ACTUAL_RTW_DATE": "03-28-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dixon",
		 "CLAIMANT_FIRST_NAME": "Annelies",
		 "CLAIM_NO": "2020-01-08-0675-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "01-14-2020 ",
		 "DATE_CLAIM_CLOSED": "01-12-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dizon",
		 "CLAIMANT_FIRST_NAME": "Daniel",
		 "CLAIM_NO": "2017-04-24-0635-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "04-19-2017 ",
		 "DATE_CLAIM_CLOSED": "06-15-2017 ",
		 "ACTUAL_RTW_DATE": "05-15-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dizon",
		 "CLAIMANT_FIRST_NAME": "Priscila",
		 "CLAIM_NO": "2013-01-05-2122-VP-01",
		 "DURATION": 334,
		 "DISABILITY_START_DATE": "05-02-2012 ",
		 "DATE_CLAIM_CLOSED": "04-01-2013 ",
		 "ACTUAL_RTW_DATE": "04-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dizon",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2015-02-13-0109-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "02-02-2015 ",
		 "DATE_CLAIM_CLOSED": "02-17-2015 ",
		 "ACTUAL_RTW_DATE": "02-11-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dizon",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2015-03-06-0137-VP-01",
		 "DURATION": 128,
		 "DISABILITY_START_DATE": "03-10-2015 ",
		 "DATE_CLAIM_CLOSED": "07-27-2015 ",
		 "ACTUAL_RTW_DATE": "07-16-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dizon",
		 "CLAIMANT_FIRST_NAME": "Daniel",
		 "CLAIM_NO": "2016-03-18-0037-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "03-08-2016 ",
		 "DATE_CLAIM_CLOSED": "06-03-2016 ",
		 "ACTUAL_RTW_DATE": "03-21-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dizon",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2019-10-10-0315-VP-01",
		 "DURATION": 98,
		 "DISABILITY_START_DATE": "10-28-2019 ",
		 "DATE_CLAIM_CLOSED": "01-21-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Do",
		 "CLAIMANT_FIRST_NAME": "Dinh",
		 "CLAIM_NO": "2019-05-23-0486-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "05-22-2019 ",
		 "DATE_CLAIM_CLOSED": "06-26-2019 ",
		 "ACTUAL_RTW_DATE": "06-24-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Do",
		 "CLAIMANT_FIRST_NAME": "Minh",
		 "CLAIM_NO": "2013-09-10-0040-VP-01",
		 "DURATION": 705,
		 "DISABILITY_START_DATE": "09-04-2013 ",
		 "DATE_CLAIM_CLOSED": "11-13-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Do",
		 "CLAIMANT_FIRST_NAME": "Dinh",
		 "CLAIM_NO": "2020-06-11-0639-VP-01",
		 "DURATION": 146,
		 "DISABILITY_START_DATE": "06-18-2020 ",
		 "DATE_CLAIM_CLOSED": "11-17-2020 ",
		 "ACTUAL_RTW_DATE": "11-11-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Do",
		 "CLAIMANT_FIRST_NAME": "Peter",
		 "CLAIM_NO": "2017-02-10-0267-VP-01",
		 "DURATION": 381,
		 "DISABILITY_START_DATE": "02-07-2017 ",
		 "DATE_CLAIM_CLOSED": "05-07-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Do",
		 "CLAIMANT_FIRST_NAME": "Hung",
		 "CLAIM_NO": "2021-08-05-0619-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "07-19-2021 ",
		 "DATE_CLAIM_CLOSED": "07-22-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Do",
		 "CLAIMANT_FIRST_NAME": "Khoa",
		 "CLAIM_NO": "2019-05-01-0421-VP-01",
		 "DURATION": 38,
		 "DISABILITY_START_DATE": "04-26-2019 ",
		 "DATE_CLAIM_CLOSED": "06-11-2019 ",
		 "ACTUAL_RTW_DATE": "06-03-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Do",
		 "CLAIMANT_FIRST_NAME": "Thuy Oanh",
		 "CLAIM_NO": "2018-10-08-0623-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "10-01-2018 ",
		 "DATE_CLAIM_CLOSED": "11-29-2018 ",
		 "ACTUAL_RTW_DATE": "11-26-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Do",
		 "CLAIMANT_FIRST_NAME": "Thuy Oanh",
		 "CLAIM_NO": "2021-09-29-0525-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "09-28-2021 ",
		 "DATE_CLAIM_CLOSED": "12-01-2021 ",
		 "ACTUAL_RTW_DATE": "11-22-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Do",
		 "CLAIMANT_FIRST_NAME": "Thuan",
		 "CLAIM_NO": "2013-01-18-0219-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "01-23-2013 ",
		 "DATE_CLAIM_CLOSED": "05-07-2013 ",
		 "ACTUAL_RTW_DATE": "02-05-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Doan",
		 "CLAIMANT_FIRST_NAME": "John",
		 "CLAIM_NO": "2021-02-27-0189-VP-01",
		 "DURATION": 86,
		 "DISABILITY_START_DATE": "02-22-2021 ",
		 "DATE_CLAIM_CLOSED": "05-20-2021 ",
		 "ACTUAL_RTW_DATE": "05-19-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Doan",
		 "CLAIMANT_FIRST_NAME": "Cuong",
		 "CLAIM_NO": "2018-10-24-0641-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "10-24-2018 ",
		 "DATE_CLAIM_CLOSED": "01-24-2019 ",
		 "ACTUAL_RTW_DATE": "01-23-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Doan",
		 "CLAIMANT_FIRST_NAME": "Ai Thi",
		 "CLAIM_NO": "2018-10-31-0599-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "10-31-2018 ",
		 "DATE_CLAIM_CLOSED": "01-08-2019 ",
		 "ACTUAL_RTW_DATE": "11-16-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Doan",
		 "CLAIMANT_FIRST_NAME": "Ai Thi",
		 "CLAIM_NO": "2019-04-08-0858-VP-01",
		 "DURATION": 294,
		 "DISABILITY_START_DATE": "04-01-2019 ",
		 "DATE_CLAIM_CLOSED": "01-23-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dodge",
		 "CLAIMANT_FIRST_NAME": "Noemi",
		 "CLAIM_NO": "2013-01-05-0087-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "12-10-2012 ",
		 "DATE_CLAIM_CLOSED": "02-11-2013 ",
		 "ACTUAL_RTW_DATE": "12-27-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dodson",
		 "CLAIMANT_FIRST_NAME": "Juan",
		 "CLAIM_NO": "2021-10-21-0992-VP-01",
		 "DURATION": 180,
		 "DISABILITY_START_DATE": "10-20-2021 ",
		 "DATE_CLAIM_CLOSED": "04-19-2022 ",
		 "ACTUAL_RTW_DATE": "04-18-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Doggett",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2023-04-17-0492-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "04-17-2023 ",
		 "DATE_CLAIM_CLOSED": "05-03-2023 ",
		 "ACTUAL_RTW_DATE": "05-01-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dolin-Arroyo",
		 "CLAIMANT_FIRST_NAME": "Lovelle",
		 "CLAIM_NO": "2018-12-26-0059-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "12-14-2018 ",
		 "DATE_CLAIM_CLOSED": "01-22-2019 ",
		 "ACTUAL_RTW_DATE": "01-14-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dolly",
		 "CLAIMANT_FIRST_NAME": "Tom",
		 "CLAIM_NO": "2017-11-09-0498-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "11-07-2017 ",
		 "DATE_CLAIM_CLOSED": "02-16-2018 ",
		 "ACTUAL_RTW_DATE": "01-08-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dominguez",
		 "CLAIMANT_FIRST_NAME": "Malina",
		 "CLAIM_NO": "2021-07-09-0796-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "06-24-2021 ",
		 "DATE_CLAIM_CLOSED": "08-31-2021 ",
		 "ACTUAL_RTW_DATE": "07-12-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Donaldson",
		 "CLAIMANT_FIRST_NAME": "Tama",
		 "CLAIM_NO": "2014-12-01-0377-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "12-02-2014 ",
		 "DATE_CLAIM_CLOSED": "02-03-2015 ",
		 "ACTUAL_RTW_DATE": "01-30-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dooher",
		 "CLAIMANT_FIRST_NAME": "Paul",
		 "CLAIM_NO": "2020-03-17-0559-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "02-28-2020 ",
		 "DATE_CLAIM_CLOSED": "03-02-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dosen",
		 "CLAIMANT_FIRST_NAME": "Enida",
		 "CLAIM_NO": "2014-09-03-0256-VP-01",
		 "DURATION": 399,
		 "DISABILITY_START_DATE": "08-28-2014 ",
		 "DATE_CLAIM_CLOSED": "08-28-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Doshi",
		 "CLAIMANT_FIRST_NAME": "Pankti",
		 "CLAIM_NO": "2017-11-01-0295-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "11-02-2017 ",
		 "DATE_CLAIM_CLOSED": "12-05-2017 ",
		 "ACTUAL_RTW_DATE": "11-20-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Doshi",
		 "CLAIMANT_FIRST_NAME": "Meena",
		 "CLAIM_NO": "2022-04-18-1085-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "04-18-2022 ",
		 "DATE_CLAIM_CLOSED": "06-09-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dowdell",
		 "CLAIMANT_FIRST_NAME": "Loreta",
		 "CLAIM_NO": "2013-01-05-3824-VP-01",
		 "DURATION": 899,
		 "DISABILITY_START_DATE": "10-07-2011 ",
		 "DATE_CLAIM_CLOSED": "03-31-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dowling",
		 "CLAIMANT_FIRST_NAME": "Roland",
		 "CLAIM_NO": "2013-06-09-0009-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "06-04-2013 ",
		 "DATE_CLAIM_CLOSED": "08-03-2013 ",
		 "ACTUAL_RTW_DATE": "06-17-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Down",
		 "CLAIMANT_FIRST_NAME": "Charlene",
		 "CLAIM_NO": "2022-10-19-0893-VP-01",
		 "DURATION": 177,
		 "DISABILITY_START_DATE": "10-17-2022 ",
		 "DATE_CLAIM_CLOSED": "04-13-2023 ",
		 "ACTUAL_RTW_DATE": "04-12-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Downey",
		 "CLAIMANT_FIRST_NAME": "Gail",
		 "CLAIM_NO": "2016-01-29-0139-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "01-27-2016 ",
		 "DATE_CLAIM_CLOSED": "02-29-2016 ",
		 "ACTUAL_RTW_DATE": "02-29-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Drakousis",
		 "CLAIMANT_FIRST_NAME": "Stacy",
		 "CLAIM_NO": "2019-07-26-0645-VP-01",
		 "DURATION": 74,
		 "DISABILITY_START_DATE": "08-12-2019 ",
		 "DATE_CLAIM_CLOSED": "10-28-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Druzhinina",
		 "CLAIMANT_FIRST_NAME": "Victoria",
		 "CLAIM_NO": "2018-12-29-0088-VP-01",
		 "DURATION": -7,
		 "DISABILITY_START_DATE": "11-26-2018 ",
		 "DATE_CLAIM_CLOSED": "04-15-2020 ",
		 "ACTUAL_RTW_DATE": "11-19-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Druzhinina",
		 "CLAIMANT_FIRST_NAME": "Victoria",
		 "CLAIM_NO": "2018-10-24-0583-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "10-26-2018 ",
		 "DATE_CLAIM_CLOSED": "12-06-2018 ",
		 "ACTUAL_RTW_DATE": "11-19-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dualan",
		 "CLAIMANT_FIRST_NAME": "Zenaida",
		 "CLAIM_NO": "2023-03-21-0284-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "03-20-2023 ",
		 "DATE_CLAIM_CLOSED": "04-06-2023 ",
		 "ACTUAL_RTW_DATE": "04-03-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dualan",
		 "CLAIMANT_FIRST_NAME": "Zenaida",
		 "CLAIM_NO": "2019-08-11-0013-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "08-12-2019 ",
		 "DATE_CLAIM_CLOSED": "09-05-2019 ",
		 "ACTUAL_RTW_DATE": "08-26-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Duarte",
		 "CLAIMANT_FIRST_NAME": "Anthony",
		 "CLAIM_NO": "2022-10-17-0931-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "10-18-2022 ",
		 "DATE_CLAIM_CLOSED": "11-04-2022 ",
		 "ACTUAL_RTW_DATE": "10-31-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dudley",
		 "CLAIMANT_FIRST_NAME": "Bernhard",
		 "CLAIM_NO": "2016-08-10-0282-VP-01",
		 "DURATION": 671,
		 "DISABILITY_START_DATE": "06-15-2016 ",
		 "DATE_CLAIM_CLOSED": "08-21-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Duenas",
		 "CLAIMANT_FIRST_NAME": "Juan Carlos",
		 "CLAIM_NO": "2023-03-24-0774-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "03-27-2023 ",
		 "DATE_CLAIM_CLOSED": "04-25-2023 ",
		 "ACTUAL_RTW_DATE": "04-24-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dugard",
		 "CLAIMANT_FIRST_NAME": "Phillip",
		 "CLAIM_NO": "2021-09-28-0999-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "09-21-2021 ",
		 "DATE_CLAIM_CLOSED": "11-01-2021 ",
		 "ACTUAL_RTW_DATE": "10-11-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dugard",
		 "CLAIMANT_FIRST_NAME": "Phillip",
		 "CLAIM_NO": "2013-05-21-0259-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "05-16-2013 ",
		 "DATE_CLAIM_CLOSED": "07-09-2013 ",
		 "ACTUAL_RTW_DATE": "06-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Duhart",
		 "CLAIMANT_FIRST_NAME": "Anne",
		 "CLAIM_NO": "2013-01-05-0130-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "12-10-2012 ",
		 "DATE_CLAIM_CLOSED": "02-11-2013 ",
		 "ACTUAL_RTW_DATE": "12-24-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dumaual",
		 "CLAIMANT_FIRST_NAME": "Rosalie",
		 "CLAIM_NO": "2022-10-10-0382-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "10-24-2022 ",
		 "DATE_CLAIM_CLOSED": "11-30-2022 ",
		 "ACTUAL_RTW_DATE": "11-24-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dunkin",
		 "CLAIMANT_FIRST_NAME": "Kenneth",
		 "CLAIM_NO": "2019-04-15-0064-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "04-17-2019 ",
		 "DATE_CLAIM_CLOSED": "04-16-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dunkin",
		 "CLAIMANT_FIRST_NAME": "Kenneth",
		 "CLAIM_NO": "2021-06-28-0976-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "06-28-2021 ",
		 "DATE_CLAIM_CLOSED": "08-13-2021 ",
		 "ACTUAL_RTW_DATE": "07-29-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dunkin",
		 "CLAIMANT_FIRST_NAME": "Kenneth",
		 "CLAIM_NO": "2020-10-05-0344-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "09-30-2020 ",
		 "DATE_CLAIM_CLOSED": "11-03-2020 ",
		 "ACTUAL_RTW_DATE": "11-02-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dunn",
		 "CLAIMANT_FIRST_NAME": "Sherry",
		 "CLAIM_NO": "2014-09-22-0229-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "09-22-2014 ",
		 "DATE_CLAIM_CLOSED": "12-09-2014 ",
		 "ACTUAL_RTW_DATE": "11-24-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Duong",
		 "CLAIMANT_FIRST_NAME": "Annie",
		 "CLAIM_NO": "2016-12-19-0287-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "12-15-2016 ",
		 "DATE_CLAIM_CLOSED": "01-13-2017 ",
		 "ACTUAL_RTW_DATE": "01-03-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Duong",
		 "CLAIMANT_FIRST_NAME": "Lai",
		 "CLAIM_NO": "2019-05-10-0108-VP-01",
		 "DURATION": 124,
		 "DISABILITY_START_DATE": "05-02-2019 ",
		 "DATE_CLAIM_CLOSED": "09-09-2019 ",
		 "ACTUAL_RTW_DATE": "09-03-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Duong",
		 "CLAIMANT_FIRST_NAME": "Lai",
		 "CLAIM_NO": "2019-05-14-0687-VP-01",
		 "DURATION": 61,
		 "DISABILITY_START_DATE": "05-02-2019 ",
		 "DATE_CLAIM_CLOSED": "05-16-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Duran",
		 "CLAIMANT_FIRST_NAME": "Evelyn",
		 "CLAIM_NO": "2016-09-07-0561-VP-01",
		 "DURATION": 74,
		 "DISABILITY_START_DATE": "09-12-2016 ",
		 "DATE_CLAIM_CLOSED": "11-30-2016 ",
		 "ACTUAL_RTW_DATE": "11-25-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Durrani",
		 "CLAIMANT_FIRST_NAME": "Shaji",
		 "CLAIM_NO": "2019-01-17-0087-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "01-17-2019 ",
		 "DATE_CLAIM_CLOSED": "03-20-2019 ",
		 "ACTUAL_RTW_DATE": "03-18-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dutta",
		 "CLAIMANT_FIRST_NAME": "Shreyosi",
		 "CLAIM_NO": "2022-06-30-0719-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "07-06-2022 ",
		 "DATE_CLAIM_CLOSED": "08-19-2022 ",
		 "ACTUAL_RTW_DATE": "08-04-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Dwelle",
		 "CLAIMANT_FIRST_NAME": "Karen",
		 "CLAIM_NO": "2022-02-23-0832-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "02-04-2022 ",
		 "DATE_CLAIM_CLOSED": "03-02-2022 ",
		 "ACTUAL_RTW_DATE": "02-28-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ealey-Ashley",
		 "CLAIMANT_FIRST_NAME": "Lakisha",
		 "CLAIM_NO": "2020-06-01-0933-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "05-26-2020 ",
		 "DATE_CLAIM_CLOSED": "07-16-2020 ",
		 "ACTUAL_RTW_DATE": "06-16-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ealey-Ashley",
		 "CLAIMANT_FIRST_NAME": "Lakisha",
		 "CLAIM_NO": "2021-08-12-0891-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "08-16-2021 ",
		 "DATE_CLAIM_CLOSED": "10-08-2021 ",
		 "ACTUAL_RTW_DATE": "09-27-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Earley",
		 "CLAIMANT_FIRST_NAME": "Misty",
		 "CLAIM_NO": "2018-05-24-0533-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "06-18-2018 ",
		 "DATE_CLAIM_CLOSED": "08-01-2018 ",
		 "ACTUAL_RTW_DATE": "07-29-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Eastman",
		 "CLAIMANT_FIRST_NAME": "Brian",
		 "CLAIM_NO": "2013-04-05-0178-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "04-01-2013 ",
		 "DATE_CLAIM_CLOSED": "06-10-2013 ",
		 "ACTUAL_RTW_DATE": "04-25-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ebert",
		 "CLAIMANT_FIRST_NAME": "Edward",
		 "CLAIM_NO": "2022-08-12-0457-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "08-23-2022 ",
		 "DATE_CLAIM_CLOSED": "11-08-2022 ",
		 "ACTUAL_RTW_DATE": "10-05-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ebert",
		 "CLAIMANT_FIRST_NAME": "Edward",
		 "CLAIM_NO": "2022-07-22-0554-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "07-28-2022 ",
		 "DATE_CLAIM_CLOSED": "09-30-2022 ",
		 "ACTUAL_RTW_DATE": "08-11-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ebo",
		 "CLAIMANT_FIRST_NAME": "Gavin",
		 "CLAIM_NO": "2021-04-12-0378-VP-01",
		 "DURATION": 500,
		 "DISABILITY_START_DATE": "03-24-2021 ",
		 "DATE_CLAIM_CLOSED": "10-20-2022 ",
		 "ACTUAL_RTW_DATE": "08-06-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Echevaria",
		 "CLAIMANT_FIRST_NAME": "Mariemil",
		 "CLAIM_NO": "2015-03-09-0474-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "04-06-2015 ",
		 "DATE_CLAIM_CLOSED": "06-15-2015 ",
		 "ACTUAL_RTW_DATE": "05-19-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Edwards",
		 "CLAIMANT_FIRST_NAME": "Michael",
		 "CLAIM_NO": "2022-03-11-0463-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "03-09-2022 ",
		 "DATE_CLAIM_CLOSED": "06-16-2022 ",
		 "ACTUAL_RTW_DATE": "03-25-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Edwards",
		 "CLAIMANT_FIRST_NAME": "Michael",
		 "CLAIM_NO": "2021-12-08-0702-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "12-07-2021 ",
		 "DATE_CLAIM_CLOSED": "01-27-2022 ",
		 "ACTUAL_RTW_DATE": "01-03-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Edwards",
		 "CLAIMANT_FIRST_NAME": "William",
		 "CLAIM_NO": "2013-05-28-0233-VP-01",
		 "DURATION": 278,
		 "DISABILITY_START_DATE": "05-30-2013 ",
		 "DATE_CLAIM_CLOSED": "03-11-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Eglinton",
		 "CLAIMANT_FIRST_NAME": "George",
		 "CLAIM_NO": "2013-12-09-0289-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "12-03-2013 ",
		 "DATE_CLAIM_CLOSED": "05-07-2014 ",
		 "ACTUAL_RTW_DATE": "01-07-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Eglinton",
		 "CLAIMANT_FIRST_NAME": "George",
		 "CLAIM_NO": "2014-03-14-0147-VP-01",
		 "DURATION": 95,
		 "DISABILITY_START_DATE": "03-13-2014 ",
		 "DATE_CLAIM_CLOSED": "06-16-2014 ",
		 "ACTUAL_RTW_DATE": "06-16-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ehsani",
		 "CLAIMANT_FIRST_NAME": "Janik",
		 "CLAIM_NO": "2021-01-12-0901-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "01-12-2021 ",
		 "DATE_CLAIM_CLOSED": "03-16-2021 ",
		 "ACTUAL_RTW_DATE": "03-15-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Eidson",
		 "CLAIMANT_FIRST_NAME": "Ashley",
		 "CLAIM_NO": "2019-05-13-0552-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "05-27-2019 ",
		 "DATE_CLAIM_CLOSED": "07-10-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ejaus",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2021-06-16-0741-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "07-01-2021 ",
		 "DATE_CLAIM_CLOSED": "08-16-2021 ",
		 "ACTUAL_RTW_DATE": "08-13-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ejaus",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2013-03-13-0478-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "04-04-2013 ",
		 "DATE_CLAIM_CLOSED": "06-26-2013 ",
		 "ACTUAL_RTW_DATE": "04-26-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Elad",
		 "CLAIMANT_FIRST_NAME": "Jonathan",
		 "CLAIM_NO": "2013-11-20-0224-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "11-14-2013 ",
		 "DATE_CLAIM_CLOSED": "05-01-2014 ",
		 "ACTUAL_RTW_DATE": "11-25-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Elauria",
		 "CLAIMANT_FIRST_NAME": "Evelyn",
		 "CLAIM_NO": "2013-04-19-0375-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "04-12-2013 ",
		 "DATE_CLAIM_CLOSED": "07-02-2013 ",
		 "ACTUAL_RTW_DATE": "05-06-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Elezaby",
		 "CLAIMANT_FIRST_NAME": "Shereen",
		 "CLAIM_NO": "2015-06-26-0153-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "07-27-2015 ",
		 "DATE_CLAIM_CLOSED": "10-27-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Eliason-Carey",
		 "CLAIMANT_FIRST_NAME": "Zahra",
		 "CLAIM_NO": "2021-11-29-1056-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "11-02-2021 ",
		 "DATE_CLAIM_CLOSED": "12-13-2021 ",
		 "ACTUAL_RTW_DATE": "11-15-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Elkins",
		 "CLAIMANT_FIRST_NAME": "Claire",
		 "CLAIM_NO": "2020-07-08-0288-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "08-03-2020 ",
		 "DATE_CLAIM_CLOSED": "10-05-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Elkins",
		 "CLAIMANT_FIRST_NAME": "Claire",
		 "CLAIM_NO": "2018-07-17-0645-VP-01",
		 "DURATION": 72,
		 "DISABILITY_START_DATE": "08-16-2018 ",
		 "DATE_CLAIM_CLOSED": "10-30-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Elliott",
		 "CLAIMANT_FIRST_NAME": "Mark",
		 "CLAIM_NO": "2016-02-29-0371-VP-02",
		 "DURATION": 86,
		 "DISABILITY_START_DATE": "02-29-2016 ",
		 "DATE_CLAIM_CLOSED": "06-24-2016 ",
		 "ACTUAL_RTW_DATE": "05-25-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Elliott-Maitoza",
		 "CLAIMANT_FIRST_NAME": "Bella",
		 "CLAIM_NO": "2020-03-18-0748-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "03-18-2020 ",
		 "DATE_CLAIM_CLOSED": "04-02-2020 ",
		 "ACTUAL_RTW_DATE": "03-25-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Elliott-Maitoza",
		 "CLAIMANT_FIRST_NAME": "Bella",
		 "CLAIM_NO": "2014-08-13-0553-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "09-30-2014 ",
		 "DATE_CLAIM_CLOSED": "11-26-2014 ",
		 "ACTUAL_RTW_DATE": "11-24-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ellis",
		 "CLAIMANT_FIRST_NAME": "Lavita",
		 "CLAIM_NO": "2021-06-21-0419-VP-01",
		 "DURATION": 365,
		 "DISABILITY_START_DATE": "06-17-2021 ",
		 "DATE_CLAIM_CLOSED": "06-17-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ellis",
		 "CLAIMANT_FIRST_NAME": "Lavita",
		 "CLAIM_NO": "2017-07-27-0376-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "07-26-2017 ",
		 "DATE_CLAIM_CLOSED": "08-28-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ellis",
		 "CLAIMANT_FIRST_NAME": "John",
		 "CLAIM_NO": "2015-12-14-0249-VP-01",
		 "DURATION": 110,
		 "DISABILITY_START_DATE": "11-11-2015 ",
		 "DATE_CLAIM_CLOSED": "12-29-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ellis",
		 "CLAIMANT_FIRST_NAME": "John",
		 "CLAIM_NO": "2014-03-21-0109-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "03-17-2014 ",
		 "DATE_CLAIM_CLOSED": "05-20-2014 ",
		 "ACTUAL_RTW_DATE": "04-07-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ellis",
		 "CLAIMANT_FIRST_NAME": "Lavita",
		 "CLAIM_NO": "2016-10-19-0149-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "10-13-2016 ",
		 "DATE_CLAIM_CLOSED": "12-09-2016 ",
		 "ACTUAL_RTW_DATE": "11-22-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ellis",
		 "CLAIMANT_FIRST_NAME": "Lavita",
		 "CLAIM_NO": "2018-05-30-0336-VP-01",
		 "DURATION": 184,
		 "DISABILITY_START_DATE": "05-18-2018 ",
		 "DATE_CLAIM_CLOSED": "01-15-2019 ",
		 "ACTUAL_RTW_DATE": "11-18-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ellis",
		 "CLAIMANT_FIRST_NAME": "Lavita",
		 "CLAIM_NO": "2020-04-02-0869-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "03-25-2020 ",
		 "DATE_CLAIM_CLOSED": "05-05-2020 ",
		 "ACTUAL_RTW_DATE": "05-04-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ellyson Iii",
		 "CLAIMANT_FIRST_NAME": "Philip",
		 "CLAIM_NO": "2018-10-02-0671-VP-01",
		 "DURATION": 179,
		 "DISABILITY_START_DATE": "10-01-2018 ",
		 "DATE_CLAIM_CLOSED": "04-15-2019 ",
		 "ACTUAL_RTW_DATE": "03-29-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Elpel",
		 "CLAIMANT_FIRST_NAME": "Marc",
		 "CLAIM_NO": "2016-04-22-0356-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "04-18-2016 ",
		 "DATE_CLAIM_CLOSED": "05-04-2016 ",
		 "ACTUAL_RTW_DATE": "05-02-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Elsakka",
		 "CLAIMANT_FIRST_NAME": "Mamdouh",
		 "CLAIM_NO": "2013-11-01-0315-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "10-28-2013 ",
		 "DATE_CLAIM_CLOSED": "03-04-2014 ",
		 "ACTUAL_RTW_DATE": "11-06-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ennis",
		 "CLAIMANT_FIRST_NAME": "Brandon",
		 "CLAIM_NO": "2022-12-13-0630-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "12-13-2022 ",
		 "DATE_CLAIM_CLOSED": "01-13-2023 ",
		 "ACTUAL_RTW_DATE": "12-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Eno",
		 "CLAIMANT_FIRST_NAME": "Ella",
		 "CLAIM_NO": "2014-08-07-0248-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "08-08-2014 ",
		 "DATE_CLAIM_CLOSED": "10-01-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Eno",
		 "CLAIMANT_FIRST_NAME": "Ella",
		 "CLAIM_NO": "2017-06-02-0534-VP-01",
		 "DURATION": 67,
		 "DISABILITY_START_DATE": "06-05-2017 ",
		 "DATE_CLAIM_CLOSED": "08-14-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Enriquez",
		 "CLAIMANT_FIRST_NAME": "Gamaliel",
		 "CLAIM_NO": "2013-04-26-0012-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "04-17-2013 ",
		 "DATE_CLAIM_CLOSED": "06-29-2013 ",
		 "ACTUAL_RTW_DATE": "06-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Enriquez",
		 "CLAIMANT_FIRST_NAME": "Gamaliel",
		 "CLAIM_NO": "2017-09-25-0637-VP-01",
		 "DURATION": 67,
		 "DISABILITY_START_DATE": "09-21-2017 ",
		 "DATE_CLAIM_CLOSED": "11-28-2017 ",
		 "ACTUAL_RTW_DATE": "11-27-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ensastegui",
		 "CLAIMANT_FIRST_NAME": "Areli",
		 "CLAIM_NO": "2021-06-16-0743-VP-01",
		 "DURATION": 69,
		 "DISABILITY_START_DATE": "07-06-2021 ",
		 "DATE_CLAIM_CLOSED": "09-14-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ensey",
		 "CLAIMANT_FIRST_NAME": "Deborah",
		 "CLAIM_NO": "2015-12-11-0380-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "01-11-2016 ",
		 "DATE_CLAIM_CLOSED": "02-23-2016 ",
		 "ACTUAL_RTW_DATE": "02-22-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ensey",
		 "CLAIMANT_FIRST_NAME": "Deborah",
		 "CLAIM_NO": "2016-10-07-0164-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "10-10-2016 ",
		 "DATE_CLAIM_CLOSED": "10-28-2016 ",
		 "ACTUAL_RTW_DATE": "10-24-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ephraim",
		 "CLAIMANT_FIRST_NAME": "Daniel",
		 "CLAIM_NO": "2022-10-12-0997-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "10-11-2022 ",
		 "DATE_CLAIM_CLOSED": "11-23-2022 ",
		 "ACTUAL_RTW_DATE": "10-27-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ephraim",
		 "CLAIMANT_FIRST_NAME": "Daniel",
		 "CLAIM_NO": "2019-10-10-0453-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "10-07-2019 ",
		 "DATE_CLAIM_CLOSED": "11-11-2019 ",
		 "ACTUAL_RTW_DATE": "11-05-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Erazo",
		 "CLAIMANT_FIRST_NAME": "Marina",
		 "CLAIM_NO": "2013-10-09-0484-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "10-07-2013 ",
		 "DATE_CLAIM_CLOSED": "01-13-2014 ",
		 "ACTUAL_RTW_DATE": "10-21-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Erisik",
		 "CLAIMANT_FIRST_NAME": "Melahat",
		 "CLAIM_NO": "2013-08-02-0148-VP-01",
		 "DURATION": 51,
		 "DISABILITY_START_DATE": "07-29-2013 ",
		 "DATE_CLAIM_CLOSED": "10-07-2013 ",
		 "ACTUAL_RTW_DATE": "09-18-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ernest",
		 "CLAIMANT_FIRST_NAME": "Daniel",
		 "CLAIM_NO": "2015-04-07-0268-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "04-17-2015 ",
		 "DATE_CLAIM_CLOSED": "06-16-2015 ",
		 "ACTUAL_RTW_DATE": "06-15-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Eroles",
		 "CLAIMANT_FIRST_NAME": "Emelita",
		 "CLAIM_NO": "2013-01-05-0791-VP-01",
		 "DURATION": 183,
		 "DISABILITY_START_DATE": "10-12-2012 ",
		 "DATE_CLAIM_CLOSED": "07-11-2013 ",
		 "ACTUAL_RTW_DATE": "04-13-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ervin",
		 "CLAIMANT_FIRST_NAME": "Imelda",
		 "CLAIM_NO": "2018-03-19-0006-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "03-19-2018 ",
		 "DATE_CLAIM_CLOSED": "04-09-2018 ",
		 "ACTUAL_RTW_DATE": "04-02-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ervin",
		 "CLAIMANT_FIRST_NAME": "Imelda",
		 "CLAIM_NO": "2017-08-30-0596-VP-01",
		 "DURATION": 5,
		 "DISABILITY_START_DATE": "08-28-2017 ",
		 "DATE_CLAIM_CLOSED": "09-18-2017 ",
		 "ACTUAL_RTW_DATE": "09-02-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Escalera",
		 "CLAIMANT_FIRST_NAME": "Alexandra",
		 "CLAIM_NO": "2021-06-02-0584-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "06-07-2021 ",
		 "DATE_CLAIM_CLOSED": "08-06-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Escorpiso",
		 "CLAIMANT_FIRST_NAME": "Sheryl",
		 "CLAIM_NO": "2022-01-18-0560-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "02-02-2022 ",
		 "DATE_CLAIM_CLOSED": "02-17-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Esparza",
		 "CLAIMANT_FIRST_NAME": "Patricia",
		 "CLAIM_NO": "2013-03-05-0106-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "03-26-2013 ",
		 "DATE_CLAIM_CLOSED": "05-17-2013 ",
		 "ACTUAL_RTW_DATE": "04-16-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Esparza",
		 "CLAIMANT_FIRST_NAME": "Patricia",
		 "CLAIM_NO": "2014-01-15-0130-VP-01",
		 "DURATION": 52,
		 "DISABILITY_START_DATE": "01-10-2014 ",
		 "DATE_CLAIM_CLOSED": "05-01-2014 ",
		 "ACTUAL_RTW_DATE": "03-03-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Espinoza",
		 "CLAIMANT_FIRST_NAME": "Jessica",
		 "CLAIM_NO": "2018-08-22-0566-VP-01",
		 "DURATION": 39,
		 "DISABILITY_START_DATE": "08-21-2018 ",
		 "DATE_CLAIM_CLOSED": "10-03-2018 ",
		 "ACTUAL_RTW_DATE": "09-29-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Espinoza",
		 "CLAIMANT_FIRST_NAME": "Amalia",
		 "CLAIM_NO": "2021-07-15-0899-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "07-27-2021 ",
		 "DATE_CLAIM_CLOSED": "08-13-2021 ",
		 "ACTUAL_RTW_DATE": "08-10-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Espinoza",
		 "CLAIMANT_FIRST_NAME": "Rhondi",
		 "CLAIM_NO": "2013-01-05-1342-VP-01",
		 "DURATION": 299,
		 "DISABILITY_START_DATE": "07-25-2012 ",
		 "DATE_CLAIM_CLOSED": "06-04-2013 ",
		 "ACTUAL_RTW_DATE": "05-20-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Esqueda",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2021-04-29-0006-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "04-26-2021 ",
		 "DATE_CLAIM_CLOSED": "05-12-2021 ",
		 "ACTUAL_RTW_DATE": "05-10-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Esqueda",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2021-09-01-0920-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "09-03-2021 ",
		 "DATE_CLAIM_CLOSED": "09-28-2021 ",
		 "ACTUAL_RTW_DATE": "09-20-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Esqueda",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2021-07-15-0009-VP-01",
		 "DURATION": 39,
		 "DISABILITY_START_DATE": "07-16-2021 ",
		 "DATE_CLAIM_CLOSED": "08-26-2021 ",
		 "ACTUAL_RTW_DATE": "08-24-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Estrada",
		 "CLAIMANT_FIRST_NAME": "Ivan",
		 "CLAIM_NO": "2021-02-05-0721-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "02-05-2021 ",
		 "DATE_CLAIM_CLOSED": "04-29-2021 ",
		 "ACTUAL_RTW_DATE": "02-15-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Estrada",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2017-03-15-0206-VP-01",
		 "DURATION": 203,
		 "DISABILITY_START_DATE": "02-27-2017 ",
		 "DATE_CLAIM_CLOSED": "03-29-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Estrada",
		 "CLAIMANT_FIRST_NAME": "Renae",
		 "CLAIM_NO": "2021-01-04-0017-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "02-22-2021 ",
		 "DATE_CLAIM_CLOSED": "04-04-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Estrada",
		 "CLAIMANT_FIRST_NAME": "Carlota",
		 "CLAIM_NO": "2013-07-09-0177-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "07-09-2013 ",
		 "DATE_CLAIM_CLOSED": "09-10-2013 ",
		 "ACTUAL_RTW_DATE": "08-06-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Estrada",
		 "CLAIMANT_FIRST_NAME": "Remegio",
		 "CLAIM_NO": "2021-12-13-1188-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "12-09-2021 ",
		 "DATE_CLAIM_CLOSED": "01-03-2022 ",
		 "ACTUAL_RTW_DATE": "12-20-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Eswaran",
		 "CLAIMANT_FIRST_NAME": "Senthil",
		 "CLAIM_NO": "2021-08-19-0361-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "08-09-2021 ",
		 "DATE_CLAIM_CLOSED": "09-07-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Etezadi",
		 "CLAIMANT_FIRST_NAME": "Pouran",
		 "CLAIM_NO": "2013-10-04-0321-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "10-07-2013 ",
		 "DATE_CLAIM_CLOSED": "04-09-2014 ",
		 "ACTUAL_RTW_DATE": "12-09-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Etezadi",
		 "CLAIMANT_FIRST_NAME": "Pouran",
		 "CLAIM_NO": "2014-02-26-0149-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "02-26-2014 ",
		 "DATE_CLAIM_CLOSED": "08-06-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ettling",
		 "CLAIMANT_FIRST_NAME": "Chris",
		 "CLAIM_NO": "2019-07-16-0155-VP-01",
		 "DURATION": 74,
		 "DISABILITY_START_DATE": "08-01-2019 ",
		 "DATE_CLAIM_CLOSED": "10-16-2019 ",
		 "ACTUAL_RTW_DATE": "10-14-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Evangelista",
		 "CLAIMANT_FIRST_NAME": "Carlo",
		 "CLAIM_NO": "2022-12-02-1051-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "12-01-2022 ",
		 "DATE_CLAIM_CLOSED": "12-13-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Evangelista",
		 "CLAIMANT_FIRST_NAME": "Carlo",
		 "CLAIM_NO": "2022-10-20-0526-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "10-20-2022 ",
		 "DATE_CLAIM_CLOSED": "12-27-2022 ",
		 "ACTUAL_RTW_DATE": "12-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Evangelista",
		 "CLAIMANT_FIRST_NAME": "Carlo",
		 "CLAIM_NO": "2022-02-09-1011-VP-01",
		 "DURATION": 97,
		 "DISABILITY_START_DATE": "02-03-2022 ",
		 "DATE_CLAIM_CLOSED": "05-17-2022 ",
		 "ACTUAL_RTW_DATE": "05-11-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Evans",
		 "CLAIMANT_FIRST_NAME": "Kate",
		 "CLAIM_NO": "2021-11-08-1157-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "12-13-2021 ",
		 "DATE_CLAIM_CLOSED": "02-01-2022 ",
		 "ACTUAL_RTW_DATE": "01-31-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Evans",
		 "CLAIMANT_FIRST_NAME": "Kate",
		 "CLAIM_NO": "2018-04-23-0304-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "05-21-2018 ",
		 "DATE_CLAIM_CLOSED": "05-08-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Evans",
		 "CLAIMANT_FIRST_NAME": "Kate",
		 "CLAIM_NO": "2019-11-13-0439-VP-01",
		 "DURATION": 50,
		 "DISABILITY_START_DATE": "12-03-2019 ",
		 "DATE_CLAIM_CLOSED": "01-24-2020 ",
		 "ACTUAL_RTW_DATE": "01-22-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Faheem",
		 "CLAIMANT_FIRST_NAME": "Ashley",
		 "CLAIM_NO": "2015-08-27-0220-VP-01",
		 "DURATION": 39,
		 "DISABILITY_START_DATE": "08-27-2015 ",
		 "DATE_CLAIM_CLOSED": "10-27-2015 ",
		 "ACTUAL_RTW_DATE": "10-05-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Faheem",
		 "CLAIMANT_FIRST_NAME": "Ashley",
		 "CLAIM_NO": "2017-05-25-0308-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "05-22-2017 ",
		 "DATE_CLAIM_CLOSED": "07-06-2017 ",
		 "ACTUAL_RTW_DATE": "06-12-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Faheem",
		 "CLAIMANT_FIRST_NAME": "Ashley",
		 "CLAIM_NO": "2014-08-08-0216-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "09-02-2014 ",
		 "DATE_CLAIM_CLOSED": "11-03-2014 ",
		 "ACTUAL_RTW_DATE": "11-03-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fajardo",
		 "CLAIMANT_FIRST_NAME": "Raquel",
		 "CLAIM_NO": "2022-02-25-0838-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "03-15-2022 ",
		 "DATE_CLAIM_CLOSED": "05-03-2022 ",
		 "ACTUAL_RTW_DATE": "05-02-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fajardo",
		 "CLAIMANT_FIRST_NAME": "Raquel",
		 "CLAIM_NO": "2018-02-09-0666-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "03-05-2018 ",
		 "DATE_CLAIM_CLOSED": "04-24-2018 ",
		 "ACTUAL_RTW_DATE": "04-23-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fajardo",
		 "CLAIMANT_FIRST_NAME": "Raquel",
		 "CLAIM_NO": "2019-08-30-0221-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "09-25-2019 ",
		 "DATE_CLAIM_CLOSED": "10-29-2019 ",
		 "ACTUAL_RTW_DATE": "10-21-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fajardo",
		 "CLAIMANT_FIRST_NAME": "Alicia",
		 "CLAIM_NO": "2016-05-10-0214-VP-01",
		 "DURATION": 1039,
		 "DISABILITY_START_DATE": "02-25-2016 ",
		 "DATE_CLAIM_CLOSED": "04-17-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Farez",
		 "CLAIMANT_FIRST_NAME": "Nuria",
		 "CLAIM_NO": "2022-10-06-0450-VP-01",
		 "DURATION": 147,
		 "DISABILITY_START_DATE": "11-07-2022 ",
		 "DATE_CLAIM_CLOSED": "01-13-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Farnsworth",
		 "CLAIMANT_FIRST_NAME": "Judy",
		 "CLAIM_NO": "2014-01-03-0283-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "12-23-2013 ",
		 "DATE_CLAIM_CLOSED": "03-17-2014 ",
		 "ACTUAL_RTW_DATE": "01-20-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Farr",
		 "CLAIMANT_FIRST_NAME": "Ronda",
		 "CLAIM_NO": "2020-08-24-0480-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "09-02-2020 ",
		 "DATE_CLAIM_CLOSED": "10-20-2020 ",
		 "ACTUAL_RTW_DATE": "10-19-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Farr",
		 "CLAIMANT_FIRST_NAME": "Alexander",
		 "CLAIM_NO": "2018-07-20-0626-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "07-23-2018 ",
		 "DATE_CLAIM_CLOSED": "08-16-2018 ",
		 "ACTUAL_RTW_DATE": "08-13-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Farr",
		 "CLAIMANT_FIRST_NAME": "Alexander",
		 "CLAIM_NO": "2019-03-05-0230-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "03-04-2019 ",
		 "DATE_CLAIM_CLOSED": "04-09-2019 ",
		 "ACTUAL_RTW_DATE": "04-08-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Farr",
		 "CLAIMANT_FIRST_NAME": "Ronda",
		 "CLAIM_NO": "2021-07-08-0752-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "07-15-2021 ",
		 "DATE_CLAIM_CLOSED": "08-11-2021 ",
		 "ACTUAL_RTW_DATE": "07-26-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Farr",
		 "CLAIMANT_FIRST_NAME": "Ronda",
		 "CLAIM_NO": "2019-12-03-0507-VP-01",
		 "DURATION": 141,
		 "DISABILITY_START_DATE": "12-05-2019 ",
		 "DATE_CLAIM_CLOSED": "04-27-2020 ",
		 "ACTUAL_RTW_DATE": "04-24-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Farr",
		 "CLAIMANT_FIRST_NAME": "Ronda",
		 "CLAIM_NO": "2022-09-20-0804-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "10-07-2022 ",
		 "DATE_CLAIM_CLOSED": "11-30-2022 ",
		 "ACTUAL_RTW_DATE": "11-22-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Farr",
		 "CLAIMANT_FIRST_NAME": "Alexander",
		 "CLAIM_NO": "2022-09-21-0199-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "09-19-2022 ",
		 "DATE_CLAIM_CLOSED": "10-14-2022 ",
		 "ACTUAL_RTW_DATE": "10-03-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fasano",
		 "CLAIMANT_FIRST_NAME": "Clare",
		 "CLAIM_NO": "2014-01-31-0270-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "02-17-2014 ",
		 "DATE_CLAIM_CLOSED": "06-25-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Faussone",
		 "CLAIMANT_FIRST_NAME": "Teresa",
		 "CLAIM_NO": "2017-09-28-0518-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "09-28-2017 ",
		 "DATE_CLAIM_CLOSED": "11-13-2017 ",
		 "ACTUAL_RTW_DATE": "11-07-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Feeney",
		 "CLAIMANT_FIRST_NAME": "Elaine",
		 "CLAIM_NO": "2020-07-26-0053-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "08-17-2020 ",
		 "DATE_CLAIM_CLOSED": "09-21-2020 ",
		 "ACTUAL_RTW_DATE": "09-18-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Felton",
		 "CLAIMANT_FIRST_NAME": "Raymond",
		 "CLAIM_NO": "2015-09-25-0008-VP-01",
		 "DURATION": 123,
		 "DISABILITY_START_DATE": "09-24-2015 ",
		 "DATE_CLAIM_CLOSED": "01-26-2016 ",
		 "ACTUAL_RTW_DATE": "01-25-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Feng",
		 "CLAIMANT_FIRST_NAME": "Erjia",
		 "CLAIM_NO": "2017-08-11-0457-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "09-07-2017 ",
		 "DATE_CLAIM_CLOSED": "10-19-2017 ",
		 "ACTUAL_RTW_DATE": "10-19-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ferguson",
		 "CLAIMANT_FIRST_NAME": "Daniel",
		 "CLAIM_NO": "2022-09-15-0019-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "09-02-2022 ",
		 "DATE_CLAIM_CLOSED": "10-28-2022 ",
		 "ACTUAL_RTW_DATE": "09-19-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fern",
		 "CLAIMANT_FIRST_NAME": "Judith",
		 "CLAIM_NO": "2015-02-13-0443-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "02-19-2015 ",
		 "DATE_CLAIM_CLOSED": "03-09-2015 ",
		 "ACTUAL_RTW_DATE": "03-02-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fernandez",
		 "CLAIMANT_FIRST_NAME": "Javier",
		 "CLAIM_NO": "2013-01-05-2264-VP-01",
		 "DURATION": 363,
		 "DISABILITY_START_DATE": "04-10-2012 ",
		 "DATE_CLAIM_CLOSED": "04-08-2013 ",
		 "ACTUAL_RTW_DATE": "04-08-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ferrise",
		 "CLAIMANT_FIRST_NAME": "Gianni",
		 "CLAIM_NO": "2020-09-23-0687-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "09-16-2020 ",
		 "DATE_CLAIM_CLOSED": "10-28-2020 ",
		 "ACTUAL_RTW_DATE": "10-27-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ferros",
		 "CLAIMANT_FIRST_NAME": "Micheal",
		 "CLAIM_NO": "2016-08-18-0524-VP-01",
		 "DURATION": 140,
		 "DISABILITY_START_DATE": "08-23-2016 ",
		 "DATE_CLAIM_CLOSED": "05-04-2017 ",
		 "ACTUAL_RTW_DATE": "01-10-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Feseha",
		 "CLAIMANT_FIRST_NAME": "Ejigayehu",
		 "CLAIM_NO": "2013-02-11-0131-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "01-24-2013 ",
		 "DATE_CLAIM_CLOSED": "05-16-2013 ",
		 "ACTUAL_RTW_DATE": "02-25-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fields",
		 "CLAIMANT_FIRST_NAME": "Rafat",
		 "CLAIM_NO": "2014-10-06-0584-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "10-24-2014 ",
		 "DATE_CLAIM_CLOSED": "12-29-2014 ",
		 "ACTUAL_RTW_DATE": "12-19-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Figlio",
		 "CLAIMANT_FIRST_NAME": "Carol",
		 "CLAIM_NO": "2015-10-28-0105-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "12-02-2015 ",
		 "DATE_CLAIM_CLOSED": "01-05-2016 ",
		 "ACTUAL_RTW_DATE": "01-04-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Figlio",
		 "CLAIMANT_FIRST_NAME": "Carol",
		 "CLAIM_NO": "2015-04-23-0450-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "04-27-2015 ",
		 "DATE_CLAIM_CLOSED": "06-10-2015 ",
		 "ACTUAL_RTW_DATE": "05-11-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Figueroa",
		 "CLAIMANT_FIRST_NAME": "Bethzaida",
		 "CLAIM_NO": "2014-03-28-0392-VP-01",
		 "DURATION": 50,
		 "DISABILITY_START_DATE": "03-24-2014 ",
		 "DATE_CLAIM_CLOSED": "12-19-2014 ",
		 "ACTUAL_RTW_DATE": "05-13-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Figueroa",
		 "CLAIMANT_FIRST_NAME": "Mona",
		 "CLAIM_NO": "2013-11-27-0938-VP-01",
		 "DURATION": 603,
		 "DISABILITY_START_DATE": "11-26-2013 ",
		 "DATE_CLAIM_CLOSED": "07-24-2015 ",
		 "ACTUAL_RTW_DATE": "07-22-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fink",
		 "CLAIMANT_FIRST_NAME": "Stanley",
		 "CLAIM_NO": "2018-04-09-0528-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "04-02-2018 ",
		 "DATE_CLAIM_CLOSED": "05-03-2018 ",
		 "ACTUAL_RTW_DATE": "05-01-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Finstad",
		 "CLAIMANT_FIRST_NAME": "Tina",
		 "CLAIM_NO": "2013-01-05-1734-VP-01",
		 "DURATION": 258,
		 "DISABILITY_START_DATE": "06-15-2012 ",
		 "DATE_CLAIM_CLOSED": "03-12-2013 ",
		 "ACTUAL_RTW_DATE": "02-28-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fish",
		 "CLAIMANT_FIRST_NAME": "Malaya",
		 "CLAIM_NO": "2021-01-16-0022-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "01-18-2021 ",
		 "DATE_CLAIM_CLOSED": "02-01-2021 ",
		 "ACTUAL_RTW_DATE": "01-28-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fish",
		 "CLAIMANT_FIRST_NAME": "Malaya",
		 "CLAIM_NO": "2022-01-10-1605-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "01-04-2022 ",
		 "DATE_CLAIM_CLOSED": "01-21-2022 ",
		 "ACTUAL_RTW_DATE": "01-17-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fish",
		 "CLAIMANT_FIRST_NAME": "Malaya",
		 "CLAIM_NO": "2018-03-08-0149-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "02-27-2018 ",
		 "DATE_CLAIM_CLOSED": "05-01-2018 ",
		 "ACTUAL_RTW_DATE": "03-12-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fish",
		 "CLAIMANT_FIRST_NAME": "Malaya",
		 "CLAIM_NO": "2015-08-31-0398-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "08-25-2015 ",
		 "DATE_CLAIM_CLOSED": "10-27-2015 ",
		 "ACTUAL_RTW_DATE": "09-14-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fisher",
		 "CLAIMANT_FIRST_NAME": "Amanda",
		 "CLAIM_NO": "2014-12-09-0365-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "12-29-2014 ",
		 "DATE_CLAIM_CLOSED": "02-24-2015 ",
		 "ACTUAL_RTW_DATE": "02-23-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fisher",
		 "CLAIMANT_FIRST_NAME": "Tamara",
		 "CLAIM_NO": "2020-11-20-0314-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "01-13-2021 ",
		 "DATE_CLAIM_CLOSED": "03-18-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fite",
		 "CLAIMANT_FIRST_NAME": "Charlette",
		 "CLAIM_NO": "2013-12-19-0124-VP-01",
		 "DURATION": 93,
		 "DISABILITY_START_DATE": "01-06-2014 ",
		 "DATE_CLAIM_CLOSED": "03-28-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fite",
		 "CLAIMANT_FIRST_NAME": "Charlette",
		 "CLAIM_NO": "2015-03-03-0455-VP-01",
		 "DURATION": 110,
		 "DISABILITY_START_DATE": "03-04-2015 ",
		 "DATE_CLAIM_CLOSED": "06-26-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fitzpatrick",
		 "CLAIMANT_FIRST_NAME": "Valerie",
		 "CLAIM_NO": "2014-06-18-0378-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "07-14-2014 ",
		 "DATE_CLAIM_CLOSED": "11-24-2014 ",
		 "ACTUAL_RTW_DATE": "08-25-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fleming",
		 "CLAIMANT_FIRST_NAME": "Tanya",
		 "CLAIM_NO": "2014-06-04-3185-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "06-02-2014 ",
		 "DATE_CLAIM_CLOSED": "08-08-2014 ",
		 "ACTUAL_RTW_DATE": "06-19-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fleming",
		 "CLAIMANT_FIRST_NAME": "Tanya",
		 "CLAIM_NO": "2020-12-30-1119-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "01-11-2021 ",
		 "DATE_CLAIM_CLOSED": "01-19-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fleming",
		 "CLAIMANT_FIRST_NAME": "Tanya",
		 "CLAIM_NO": "2021-02-10-0212-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "02-19-2021 ",
		 "DATE_CLAIM_CLOSED": "03-24-2021 ",
		 "ACTUAL_RTW_DATE": "03-22-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fleming",
		 "CLAIMANT_FIRST_NAME": "Tanya",
		 "CLAIM_NO": "2017-03-03-0544-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "03-24-2017 ",
		 "DATE_CLAIM_CLOSED": "04-27-2017 ",
		 "ACTUAL_RTW_DATE": "04-17-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fletcher",
		 "CLAIMANT_FIRST_NAME": "William",
		 "CLAIM_NO": "2021-09-15-0752-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "09-20-2021 ",
		 "DATE_CLAIM_CLOSED": "11-15-2021 ",
		 "ACTUAL_RTW_DATE": "11-15-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Flint",
		 "CLAIMANT_FIRST_NAME": "Toriana",
		 "CLAIM_NO": "2019-04-05-0074-VP-01",
		 "DURATION": 76,
		 "DISABILITY_START_DATE": "04-02-2019 ",
		 "DATE_CLAIM_CLOSED": "07-11-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Flores",
		 "CLAIMANT_FIRST_NAME": "Lydia",
		 "CLAIM_NO": "2015-12-23-0439-VP-01",
		 "DURATION": 75,
		 "DISABILITY_START_DATE": "11-18-2015 ",
		 "DATE_CLAIM_CLOSED": "12-29-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Flores",
		 "CLAIMANT_FIRST_NAME": "Enrique",
		 "CLAIM_NO": "2018-10-24-0614-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "10-22-2018 ",
		 "DATE_CLAIM_CLOSED": "12-12-2018 ",
		 "ACTUAL_RTW_DATE": "12-10-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Flores",
		 "CLAIMANT_FIRST_NAME": "Stephen",
		 "CLAIM_NO": "2022-05-05-0584-VP-01",
		 "DURATION": 30,
		 "DISABILITY_START_DATE": "05-03-2022 ",
		 "DATE_CLAIM_CLOSED": "09-20-2022 ",
		 "ACTUAL_RTW_DATE": "06-02-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Flores",
		 "CLAIMANT_FIRST_NAME": "Ymelynn Mae",
		 "CLAIM_NO": "2021-12-29-0126-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "12-20-2021 ",
		 "DATE_CLAIM_CLOSED": "02-14-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Flores",
		 "CLAIMANT_FIRST_NAME": "Helen",
		 "CLAIM_NO": "2013-04-17-0193-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "04-15-2013 ",
		 "DATE_CLAIM_CLOSED": "06-27-2013 ",
		 "ACTUAL_RTW_DATE": "04-23-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Flores",
		 "CLAIMANT_FIRST_NAME": "Salvador",
		 "CLAIM_NO": "2014-01-13-0251-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "01-13-2014 ",
		 "DATE_CLAIM_CLOSED": "03-17-2014 ",
		 "ACTUAL_RTW_DATE": "03-17-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Flores",
		 "CLAIMANT_FIRST_NAME": "Beatriz",
		 "CLAIM_NO": "2018-07-23-0675-VP-01",
		 "DURATION": 169,
		 "DISABILITY_START_DATE": "07-16-2018 ",
		 "DATE_CLAIM_CLOSED": "08-20-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Flores Mendoza",
		 "CLAIMANT_FIRST_NAME": "Gabriel",
		 "CLAIM_NO": "2022-12-22-0878-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "12-09-2022 ",
		 "DATE_CLAIM_CLOSED": "01-19-2023 ",
		 "ACTUAL_RTW_DATE": "12-19-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Flores-Fonseca",
		 "CLAIMANT_FIRST_NAME": "Nancy",
		 "CLAIM_NO": "2019-06-14-0379-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "06-17-2019 ",
		 "DATE_CLAIM_CLOSED": "09-09-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Flores-Fonseca",
		 "CLAIMANT_FIRST_NAME": "Nancy",
		 "CLAIM_NO": "2019-02-11-0381-VP-01",
		 "DURATION": 142,
		 "DISABILITY_START_DATE": "02-07-2019 ",
		 "DATE_CLAIM_CLOSED": "06-18-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Flores-Roberts",
		 "CLAIMANT_FIRST_NAME": "Julia",
		 "CLAIM_NO": "2019-09-10-0766-VP-01",
		 "DURATION": 25,
		 "DISABILITY_START_DATE": "09-05-2019 ",
		 "DATE_CLAIM_CLOSED": "10-01-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Foggetti",
		 "CLAIMANT_FIRST_NAME": "Siena",
		 "CLAIM_NO": "2013-09-10-0318-VP-01",
		 "DURATION": 173,
		 "DISABILITY_START_DATE": "09-23-2013 ",
		 "DATE_CLAIM_CLOSED": "08-08-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Foggetti",
		 "CLAIMANT_FIRST_NAME": "Siena",
		 "CLAIM_NO": "2014-10-07-0184-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "09-22-2014 ",
		 "DATE_CLAIM_CLOSED": "12-09-2014 ",
		 "ACTUAL_RTW_DATE": "11-17-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Foggetti",
		 "CLAIMANT_FIRST_NAME": "Siena",
		 "CLAIM_NO": "2013-03-06-0011-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "02-27-2013 ",
		 "DATE_CLAIM_CLOSED": "06-12-2013 ",
		 "ACTUAL_RTW_DATE": "03-18-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fontamillas",
		 "CLAIMANT_FIRST_NAME": "Noel",
		 "CLAIM_NO": "2021-03-03-0015-VP-01",
		 "DURATION": 430,
		 "DISABILITY_START_DATE": "03-02-2021 ",
		 "DATE_CLAIM_CLOSED": "05-09-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fontamillas",
		 "CLAIMANT_FIRST_NAME": "Noel",
		 "CLAIM_NO": "2020-02-18-0390-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "02-14-2020 ",
		 "DATE_CLAIM_CLOSED": "03-10-2020 ",
		 "ACTUAL_RTW_DATE": "02-24-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fontamillas",
		 "CLAIMANT_FIRST_NAME": "Noel",
		 "CLAIM_NO": "2016-06-08-0204-VP-01",
		 "DURATION": 281,
		 "DISABILITY_START_DATE": "05-30-2016 ",
		 "DATE_CLAIM_CLOSED": "07-11-2017 ",
		 "ACTUAL_RTW_DATE": "03-07-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Forehand",
		 "CLAIMANT_FIRST_NAME": "Pixie",
		 "CLAIM_NO": "2022-09-28-0346-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "09-29-2022 ",
		 "DATE_CLAIM_CLOSED": "11-08-2022 ",
		 "ACTUAL_RTW_DATE": "10-17-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fortenberry",
		 "CLAIMANT_FIRST_NAME": "Steven",
		 "CLAIM_NO": "2017-10-30-0619-VP-01",
		 "DURATION": 95,
		 "DISABILITY_START_DATE": "09-12-2017 ",
		 "DATE_CLAIM_CLOSED": "12-21-2017 ",
		 "ACTUAL_RTW_DATE": "12-16-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fortune",
		 "CLAIMANT_FIRST_NAME": "Tina",
		 "CLAIM_NO": "2018-04-27-0526-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "06-07-2018 ",
		 "DATE_CLAIM_CLOSED": "08-09-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Foster",
		 "CLAIMANT_FIRST_NAME": "Nakia",
		 "CLAIM_NO": "2013-01-05-0548-VP-01",
		 "DURATION": 108,
		 "DISABILITY_START_DATE": "10-16-2012 ",
		 "DATE_CLAIM_CLOSED": "02-11-2013 ",
		 "ACTUAL_RTW_DATE": "02-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Foster",
		 "CLAIMANT_FIRST_NAME": "Nakia",
		 "CLAIM_NO": "2014-08-05-0196-VP-01",
		 "DURATION": 75,
		 "DISABILITY_START_DATE": "08-06-2014 ",
		 "DATE_CLAIM_CLOSED": "10-20-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fowlie",
		 "CLAIMANT_FIRST_NAME": "Crystal",
		 "CLAIM_NO": "2021-09-28-0869-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "10-25-2021 ",
		 "DATE_CLAIM_CLOSED": "01-14-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fowlie",
		 "CLAIMANT_FIRST_NAME": "Crystal",
		 "CLAIM_NO": "2017-03-31-0350-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "03-27-2017 ",
		 "DATE_CLAIM_CLOSED": "05-02-2017 ",
		 "ACTUAL_RTW_DATE": "04-25-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fowlie",
		 "CLAIMANT_FIRST_NAME": "Crystal",
		 "CLAIM_NO": "2019-10-07-0514-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "10-21-2019 ",
		 "DATE_CLAIM_CLOSED": "12-31-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Francis",
		 "CLAIMANT_FIRST_NAME": "Meliza",
		 "CLAIM_NO": "2015-03-02-0549-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "02-27-2015 ",
		 "DATE_CLAIM_CLOSED": "08-04-2015 ",
		 "ACTUAL_RTW_DATE": "03-09-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Francis",
		 "CLAIMANT_FIRST_NAME": "Anika",
		 "CLAIM_NO": "2015-03-25-0497-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "04-20-2015 ",
		 "DATE_CLAIM_CLOSED": "06-24-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Franco",
		 "CLAIMANT_FIRST_NAME": "Luz",
		 "CLAIM_NO": "2018-10-31-0565-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "10-26-2018 ",
		 "DATE_CLAIM_CLOSED": "11-27-2018 ",
		 "ACTUAL_RTW_DATE": "11-12-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Franco",
		 "CLAIMANT_FIRST_NAME": "Alex",
		 "CLAIM_NO": "2013-01-05-0841-VP-01",
		 "DURATION": 116,
		 "DISABILITY_START_DATE": "09-13-2012 ",
		 "DATE_CLAIM_CLOSED": "01-07-2013 ",
		 "ACTUAL_RTW_DATE": "01-07-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Franco",
		 "CLAIMANT_FIRST_NAME": "Mona Lisa",
		 "CLAIM_NO": "2022-11-08-1097-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "12-01-2022 ",
		 "DATE_CLAIM_CLOSED": "01-16-2023 ",
		 "ACTUAL_RTW_DATE": "12-16-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Franke",
		 "CLAIMANT_FIRST_NAME": "Abirami",
		 "CLAIM_NO": "2020-01-07-0762-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "01-20-2020 ",
		 "DATE_CLAIM_CLOSED": "03-17-2020 ",
		 "ACTUAL_RTW_DATE": "03-16-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Frankel",
		 "CLAIMANT_FIRST_NAME": "Ding Ying",
		 "CLAIM_NO": "2022-08-18-0391-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "08-24-2022 ",
		 "DATE_CLAIM_CLOSED": "10-04-2022 ",
		 "ACTUAL_RTW_DATE": "09-08-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Frankel",
		 "CLAIMANT_FIRST_NAME": "Ding Ying",
		 "CLAIM_NO": "2019-01-10-0593-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "12-13-2018 ",
		 "DATE_CLAIM_CLOSED": "04-05-2019 ",
		 "ACTUAL_RTW_DATE": "12-21-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Franklin",
		 "CLAIMANT_FIRST_NAME": "Matilde",
		 "CLAIM_NO": "2014-04-23-0478-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "04-21-2014 ",
		 "DATE_CLAIM_CLOSED": "05-01-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Frater",
		 "CLAIMANT_FIRST_NAME": "Natalie",
		 "CLAIM_NO": "2021-03-03-0468-VP-01",
		 "DURATION": 80,
		 "DISABILITY_START_DATE": "04-19-2021 ",
		 "DATE_CLAIM_CLOSED": "07-09-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Frater",
		 "CLAIMANT_FIRST_NAME": "Natalie",
		 "CLAIM_NO": "2019-03-14-0663-VP-01",
		 "DURATION": 85,
		 "DISABILITY_START_DATE": "03-15-2019 ",
		 "DATE_CLAIM_CLOSED": "06-12-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Frausto",
		 "CLAIMANT_FIRST_NAME": "Alicia",
		 "CLAIM_NO": "2014-04-07-0380-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "05-05-2014 ",
		 "DATE_CLAIM_CLOSED": "07-18-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Freitas",
		 "CLAIMANT_FIRST_NAME": "Valerie",
		 "CLAIM_NO": "2014-04-14-0262-VP-01",
		 "DURATION": 36,
		 "DISABILITY_START_DATE": "05-14-2014 ",
		 "DATE_CLAIM_CLOSED": "07-28-2014 ",
		 "ACTUAL_RTW_DATE": "06-19-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Freitas",
		 "CLAIMANT_FIRST_NAME": "Valerie",
		 "CLAIM_NO": "2015-02-11-0211-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "02-12-2015 ",
		 "DATE_CLAIM_CLOSED": "06-24-2015 ",
		 "ACTUAL_RTW_DATE": "02-26-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Freitas",
		 "CLAIMANT_FIRST_NAME": "Valerie",
		 "CLAIM_NO": "2017-07-11-0004-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "08-02-2017 ",
		 "DATE_CLAIM_CLOSED": "09-18-2017 ",
		 "ACTUAL_RTW_DATE": "09-13-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Freitas",
		 "CLAIMANT_FIRST_NAME": "Valerie",
		 "CLAIM_NO": "2021-10-19-0419-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "10-19-2021 ",
		 "DATE_CLAIM_CLOSED": "11-22-2021 ",
		 "ACTUAL_RTW_DATE": "11-08-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Freitas",
		 "CLAIMANT_FIRST_NAME": "Valerie",
		 "CLAIM_NO": "2016-05-26-0468-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "05-27-2016 ",
		 "DATE_CLAIM_CLOSED": "06-28-2016 ",
		 "ACTUAL_RTW_DATE": "06-27-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Freitas",
		 "CLAIMANT_FIRST_NAME": "James",
		 "CLAIM_NO": "2020-01-08-0896-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "01-24-2020 ",
		 "DATE_CLAIM_CLOSED": "02-26-2020 ",
		 "ACTUAL_RTW_DATE": "02-24-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "French",
		 "CLAIMANT_FIRST_NAME": "Rick",
		 "CLAIM_NO": "2020-03-04-0817-VP-01",
		 "DURATION": 140,
		 "DISABILITY_START_DATE": "03-02-2020 ",
		 "DATE_CLAIM_CLOSED": "07-21-2020 ",
		 "ACTUAL_RTW_DATE": "07-20-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "French",
		 "CLAIMANT_FIRST_NAME": "Rick",
		 "CLAIM_NO": "2021-08-27-0843-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "08-03-2021 ",
		 "DATE_CLAIM_CLOSED": "09-14-2021 ",
		 "ACTUAL_RTW_DATE": "08-19-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "French",
		 "CLAIMANT_FIRST_NAME": "Rick",
		 "CLAIM_NO": "2021-12-09-1295-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "12-06-2021 ",
		 "DATE_CLAIM_CLOSED": "01-07-2022 ",
		 "ACTUAL_RTW_DATE": "12-24-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Frenkel",
		 "CLAIMANT_FIRST_NAME": "Shani",
		 "CLAIM_NO": "2013-02-21-0059-VP-01",
		 "DURATION": 152,
		 "DISABILITY_START_DATE": "02-14-2013 ",
		 "DATE_CLAIM_CLOSED": "08-27-2013 ",
		 "ACTUAL_RTW_DATE": "07-16-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Frias",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2023-01-17-0820-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "01-10-2023 ",
		 "DATE_CLAIM_CLOSED": "03-13-2023 ",
		 "ACTUAL_RTW_DATE": "01-23-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Friedman",
		 "CLAIMANT_FIRST_NAME": "Erica",
		 "CLAIM_NO": "2014-03-19-0481-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "03-24-2014 ",
		 "DATE_CLAIM_CLOSED": "12-08-2014 ",
		 "ACTUAL_RTW_DATE": "06-09-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Friedman",
		 "CLAIMANT_FIRST_NAME": "Allison",
		 "CLAIM_NO": "2022-07-08-0869-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "07-27-2022 ",
		 "DATE_CLAIM_CLOSED": "10-20-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Friedman",
		 "CLAIMANT_FIRST_NAME": "Allison",
		 "CLAIM_NO": "2022-09-01-0693-VP-01",
		 "DISABILITY_START_DATE": "09-07-2022 ",
		 "DATE_CLAIM_CLOSED": "09-09-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Friend",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2015-06-12-0664-VP-01",
		 "DURATION": 113,
		 "DISABILITY_START_DATE": "06-11-2015 ",
		 "DATE_CLAIM_CLOSED": "10-26-2015 ",
		 "ACTUAL_RTW_DATE": "10-02-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fung",
		 "CLAIMANT_FIRST_NAME": "Morgan",
		 "CLAIM_NO": "2018-09-05-0695-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "10-29-2018 ",
		 "DATE_CLAIM_CLOSED": "12-10-2018 ",
		 "ACTUAL_RTW_DATE": "12-03-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Fung",
		 "CLAIMANT_FIRST_NAME": "Florence",
		 "CLAIM_NO": "2022-06-10-0571-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "06-10-2022 ",
		 "DATE_CLAIM_CLOSED": "07-15-2022 ",
		 "ACTUAL_RTW_DATE": "07-13-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gaborro",
		 "CLAIMANT_FIRST_NAME": "Anna",
		 "CLAIM_NO": "2022-04-01-0024-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "03-31-2022 ",
		 "DATE_CLAIM_CLOSED": "05-23-2022 ",
		 "ACTUAL_RTW_DATE": "05-16-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gada",
		 "CLAIMANT_FIRST_NAME": "Manish",
		 "CLAIM_NO": "2018-10-25-0241-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "10-22-2018 ",
		 "DATE_CLAIM_CLOSED": "11-06-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gadison",
		 "CLAIMANT_FIRST_NAME": "Patricia",
		 "CLAIM_NO": "2013-01-05-0542-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "10-23-2012 ",
		 "DATE_CLAIM_CLOSED": "09-04-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gaerlan",
		 "CLAIMANT_FIRST_NAME": "Arnel",
		 "CLAIM_NO": "2019-04-25-0155-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "04-22-2019 ",
		 "DATE_CLAIM_CLOSED": "06-04-2019 ",
		 "ACTUAL_RTW_DATE": "05-27-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gafford",
		 "CLAIMANT_FIRST_NAME": "Suzan",
		 "CLAIM_NO": "2015-02-23-0267-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "02-13-2015 ",
		 "DATE_CLAIM_CLOSED": "04-21-2015 ",
		 "ACTUAL_RTW_DATE": "02-23-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gagarin",
		 "CLAIMANT_FIRST_NAME": "Sheila",
		 "CLAIM_NO": "2013-10-14-0211-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "10-28-2013 ",
		 "DATE_CLAIM_CLOSED": "10-16-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gagarin",
		 "CLAIMANT_FIRST_NAME": "Ruth",
		 "CLAIM_NO": "2019-02-21-0620-VP-01",
		 "DURATION": 226,
		 "DISABILITY_START_DATE": "03-11-2019 ",
		 "DATE_CLAIM_CLOSED": "11-11-2019 ",
		 "ACTUAL_RTW_DATE": "10-23-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gagarin",
		 "CLAIMANT_FIRST_NAME": "Sheila",
		 "CLAIM_NO": "2013-08-01-0008-VP-01",
		 "DURATION": 144,
		 "DISABILITY_START_DATE": "07-31-2013 ",
		 "DATE_CLAIM_CLOSED": "12-30-2013 ",
		 "ACTUAL_RTW_DATE": "12-22-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gagarin",
		 "CLAIMANT_FIRST_NAME": "Sheila",
		 "CLAIM_NO": "2013-01-05-0095-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "12-17-2012 ",
		 "DATE_CLAIM_CLOSED": "03-01-2013 ",
		 "ACTUAL_RTW_DATE": "01-08-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gaines",
		 "CLAIMANT_FIRST_NAME": "Jacqueline",
		 "CLAIM_NO": "2013-01-05-0492-VP-01",
		 "DURATION": 133,
		 "DISABILITY_START_DATE": "08-07-2012 ",
		 "DATE_CLAIM_CLOSED": "03-14-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gaitley",
		 "CLAIMANT_FIRST_NAME": "Jane",
		 "CLAIM_NO": "2014-11-19-0200-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "12-04-2014 ",
		 "DATE_CLAIM_CLOSED": "02-27-2015 ",
		 "ACTUAL_RTW_DATE": "02-26-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gaitley",
		 "CLAIMANT_FIRST_NAME": "Jane",
		 "CLAIM_NO": "2021-05-04-0758-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "05-05-2021 ",
		 "DATE_CLAIM_CLOSED": "07-07-2021 ",
		 "ACTUAL_RTW_DATE": "06-29-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Galano",
		 "CLAIMANT_FIRST_NAME": "Frank",
		 "CLAIM_NO": "2013-01-18-0377-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "01-14-2013 ",
		 "DATE_CLAIM_CLOSED": "08-05-2013 ",
		 "ACTUAL_RTW_DATE": "02-18-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Galaviz",
		 "CLAIMANT_FIRST_NAME": "Ezequiel",
		 "CLAIM_NO": "2017-12-14-0532-VP-01",
		 "DURATION": 367,
		 "DISABILITY_START_DATE": "12-12-2017 ",
		 "DATE_CLAIM_CLOSED": "12-17-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Galiano",
		 "CLAIMANT_FIRST_NAME": "Donna",
		 "CLAIM_NO": "2016-02-09-0105-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "03-10-2016 ",
		 "DATE_CLAIM_CLOSED": "04-26-2016 ",
		 "ACTUAL_RTW_DATE": "04-25-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Galicia",
		 "CLAIMANT_FIRST_NAME": "Melissa",
		 "CLAIM_NO": "2021-10-12-0002-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "10-26-2021 ",
		 "DATE_CLAIM_CLOSED": "04-07-2022 ",
		 "ACTUAL_RTW_DATE": "11-22-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Galindo",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2018-10-09-0211-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "10-09-2018 ",
		 "DATE_CLAIM_CLOSED": "11-28-2018 ",
		 "ACTUAL_RTW_DATE": "10-22-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Galindo",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2017-12-12-0408-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "12-05-2017 ",
		 "DATE_CLAIM_CLOSED": "01-10-2018 ",
		 "ACTUAL_RTW_DATE": "12-22-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gallagher",
		 "CLAIMANT_FIRST_NAME": "Michael",
		 "CLAIM_NO": "2017-05-12-0542-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "05-08-2017 ",
		 "DATE_CLAIM_CLOSED": "06-12-2017 ",
		 "ACTUAL_RTW_DATE": "05-29-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gallagher",
		 "CLAIMANT_FIRST_NAME": "Kirsten",
		 "CLAIM_NO": "2013-10-11-0136-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "10-14-2013 ",
		 "DATE_CLAIM_CLOSED": "03-20-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gallagher",
		 "CLAIMANT_FIRST_NAME": "Kirsten",
		 "CLAIM_NO": "2018-01-09-0522-VP-01",
		 "DURATION": 126,
		 "DISABILITY_START_DATE": "02-19-2018 ",
		 "DATE_CLAIM_CLOSED": "04-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gallardo",
		 "CLAIMANT_FIRST_NAME": "Alicia",
		 "CLAIM_NO": "2013-10-03-0147-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "09-18-2013 ",
		 "DATE_CLAIM_CLOSED": "09-29-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gallardo",
		 "CLAIMANT_FIRST_NAME": "Leticia",
		 "CLAIM_NO": "2017-03-23-0246-VP-01",
		 "DURATION": 88,
		 "DISABILITY_START_DATE": "03-23-2017 ",
		 "DATE_CLAIM_CLOSED": "06-20-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gallardo",
		 "CLAIMANT_FIRST_NAME": "Leticia",
		 "CLAIM_NO": "2015-03-26-0253-VP-01",
		 "DURATION": 4,
		 "DISABILITY_START_DATE": "03-26-2015 ",
		 "DATE_CLAIM_CLOSED": "04-09-2015 ",
		 "ACTUAL_RTW_DATE": "03-30-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gallardo",
		 "CLAIMANT_FIRST_NAME": "Leticia",
		 "CLAIM_NO": "2016-11-14-0687-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "11-15-2016 ",
		 "DATE_CLAIM_CLOSED": "12-27-2016 ",
		 "ACTUAL_RTW_DATE": "12-02-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gallegos",
		 "CLAIMANT_FIRST_NAME": "Abigail",
		 "CLAIM_NO": "2013-10-14-0505-VP-01",
		 "DURATION": 52,
		 "DISABILITY_START_DATE": "10-21-2013 ",
		 "DATE_CLAIM_CLOSED": "03-20-2014 ",
		 "ACTUAL_RTW_DATE": "12-12-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gallegos",
		 "CLAIMANT_FIRST_NAME": "Abigail",
		 "CLAIM_NO": "2018-06-21-0653-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "06-22-2018 ",
		 "DATE_CLAIM_CLOSED": "07-09-2018 ",
		 "ACTUAL_RTW_DATE": "07-02-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gallegos Nevarez",
		 "CLAIMANT_FIRST_NAME": "Audrey",
		 "CLAIM_NO": "2018-10-16-0690-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "10-18-2018 ",
		 "DATE_CLAIM_CLOSED": "11-27-2018 ",
		 "ACTUAL_RTW_DATE": "11-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Galvan",
		 "CLAIMANT_FIRST_NAME": "Leslie",
		 "CLAIM_NO": "2020-07-10-0858-VP-01",
		 "DURATION": 51,
		 "DISABILITY_START_DATE": "07-13-2020 ",
		 "DATE_CLAIM_CLOSED": "09-02-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Galvan",
		 "CLAIMANT_FIRST_NAME": "Leslie",
		 "CLAIM_NO": "2022-06-02-1035-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "05-16-2022 ",
		 "DATE_CLAIM_CLOSED": "06-17-2022 ",
		 "ACTUAL_RTW_DATE": "06-14-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Galvan",
		 "CLAIMANT_FIRST_NAME": "Leslie",
		 "CLAIM_NO": "2019-06-10-0553-VP-01",
		 "DURATION": 110,
		 "DISABILITY_START_DATE": "06-10-2019 ",
		 "DATE_CLAIM_CLOSED": "10-04-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Galvan",
		 "CLAIMANT_FIRST_NAME": "Leslie",
		 "CLAIM_NO": "2022-10-12-0594-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "10-12-2022 ",
		 "DATE_CLAIM_CLOSED": "12-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Galvez",
		 "CLAIMANT_FIRST_NAME": "Adriana",
		 "CLAIM_NO": "2020-08-11-0881-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "08-10-2020 ",
		 "DATE_CLAIM_CLOSED": "09-09-2020 ",
		 "ACTUAL_RTW_DATE": "09-05-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Galvez",
		 "CLAIMANT_FIRST_NAME": "Adriana",
		 "CLAIM_NO": "2020-04-01-0217-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "03-25-2020 ",
		 "DATE_CLAIM_CLOSED": "04-17-2020 ",
		 "ACTUAL_RTW_DATE": "04-15-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Galvin",
		 "CLAIMANT_FIRST_NAME": "Velinda",
		 "CLAIM_NO": "2022-06-03-0221-VP-01",
		 "DURATION": 167,
		 "DISABILITY_START_DATE": "06-17-2022 ",
		 "DATE_CLAIM_CLOSED": "12-07-2022 ",
		 "ACTUAL_RTW_DATE": "12-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Galvin",
		 "CLAIMANT_FIRST_NAME": "Velinda",
		 "CLAIM_NO": "2020-10-02-0643-VP-01",
		 "DURATION": 190,
		 "DISABILITY_START_DATE": "10-30-2020 ",
		 "DATE_CLAIM_CLOSED": "05-14-2021 ",
		 "ACTUAL_RTW_DATE": "05-08-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gamaza",
		 "CLAIMANT_FIRST_NAME": "Julia",
		 "CLAIM_NO": "2022-09-22-0273-VP-01",
		 "DURATION": 154,
		 "DISABILITY_START_DATE": "10-26-2022 ",
		 "DATE_CLAIM_CLOSED": "01-16-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gamble",
		 "CLAIMANT_FIRST_NAME": "Karen",
		 "CLAIM_NO": "2015-05-28-0185-VP-01",
		 "DURATION": 125,
		 "DISABILITY_START_DATE": "05-19-2015 ",
		 "DATE_CLAIM_CLOSED": "10-02-2015 ",
		 "ACTUAL_RTW_DATE": "09-21-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gamble",
		 "CLAIMANT_FIRST_NAME": "Karen",
		 "CLAIM_NO": "2014-03-05-0264-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "03-03-2014 ",
		 "DATE_CLAIM_CLOSED": "03-17-2014 ",
		 "ACTUAL_RTW_DATE": "03-10-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gamble",
		 "CLAIMANT_FIRST_NAME": "Anna",
		 "CLAIM_NO": "2015-03-12-0214-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "04-13-2015 ",
		 "DATE_CLAIM_CLOSED": "06-11-2015 ",
		 "ACTUAL_RTW_DATE": "06-08-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gamble",
		 "CLAIMANT_FIRST_NAME": "Anna",
		 "CLAIM_NO": "2016-03-07-0609-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "05-02-2016 ",
		 "DATE_CLAIM_CLOSED": "06-28-2016 ",
		 "ACTUAL_RTW_DATE": "06-27-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gamboa",
		 "CLAIMANT_FIRST_NAME": "Meghan",
		 "CLAIM_NO": "2018-01-30-0446-VP-01",
		 "DURATION": 98,
		 "DISABILITY_START_DATE": "01-30-2018 ",
		 "DATE_CLAIM_CLOSED": "03-28-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gamboa",
		 "CLAIMANT_FIRST_NAME": "Meghan",
		 "CLAIM_NO": "2014-10-01-0442-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "11-03-2014 ",
		 "DATE_CLAIM_CLOSED": "01-05-2015 ",
		 "ACTUAL_RTW_DATE": "01-01-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gamboa",
		 "CLAIMANT_FIRST_NAME": "Delia",
		 "CLAIM_NO": "2022-04-11-0660-VP-01",
		 "DURATION": 87,
		 "DISABILITY_START_DATE": "04-25-2022 ",
		 "DATE_CLAIM_CLOSED": "07-26-2022 ",
		 "ACTUAL_RTW_DATE": "07-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gameng",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2018-10-30-0609-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "10-29-2018 ",
		 "DATE_CLAIM_CLOSED": "01-02-2019 ",
		 "ACTUAL_RTW_DATE": "12-17-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gandhi",
		 "CLAIMANT_FIRST_NAME": "Tanvi",
		 "CLAIM_NO": "2021-10-25-0817-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "10-25-2021 ",
		 "DATE_CLAIM_CLOSED": "11-19-2021 ",
		 "ACTUAL_RTW_DATE": "11-08-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gandhi",
		 "CLAIMANT_FIRST_NAME": "Riju",
		 "CLAIM_NO": "2018-02-19-0172-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "03-19-2018 ",
		 "DATE_CLAIM_CLOSED": "05-18-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gandhi",
		 "CLAIMANT_FIRST_NAME": "Riju",
		 "CLAIM_NO": "2020-12-01-0696-VP-01",
		 "DURATION": 65,
		 "DISABILITY_START_DATE": "01-04-2021 ",
		 "DATE_CLAIM_CLOSED": "03-10-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gandhi",
		 "CLAIMANT_FIRST_NAME": "Preeti",
		 "CLAIM_NO": "2013-01-05-1331-VP-01",
		 "DURATION": 163,
		 "DISABILITY_START_DATE": "08-15-2012 ",
		 "DATE_CLAIM_CLOSED": "02-09-2013 ",
		 "ACTUAL_RTW_DATE": "01-25-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ganesan",
		 "CLAIMANT_FIRST_NAME": "Subhashree",
		 "CLAIM_NO": "2022-02-03-0816-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "02-10-2022 ",
		 "DATE_CLAIM_CLOSED": "04-06-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ganesh Prabhakar",
		 "CLAIMANT_FIRST_NAME": "Preethi",
		 "CLAIM_NO": "2019-12-10-0411-VP-01",
		 "DURATION": 66,
		 "DISABILITY_START_DATE": "12-17-2019 ",
		 "DATE_CLAIM_CLOSED": "02-24-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gano",
		 "CLAIMANT_FIRST_NAME": "Rebecca",
		 "CLAIM_NO": "2013-01-05-0330-VP-01",
		 "DURATION": 1036,
		 "DISABILITY_START_DATE": "11-16-2012 ",
		 "DATE_CLAIM_CLOSED": "10-30-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gapuz",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2013-11-11-0458-VP-01",
		 "DURATION": 76,
		 "DISABILITY_START_DATE": "12-04-2013 ",
		 "DATE_CLAIM_CLOSED": "03-19-2014 ",
		 "ACTUAL_RTW_DATE": "02-18-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Kelly",
		 "CLAIM_NO": "2016-12-20-0317-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "12-19-2016 ",
		 "DATE_CLAIM_CLOSED": "01-05-2017 ",
		 "ACTUAL_RTW_DATE": "12-26-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Maricela",
		 "CLAIM_NO": "2017-10-25-0191-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "10-20-2017 ",
		 "DATE_CLAIM_CLOSED": "11-02-2017 ",
		 "ACTUAL_RTW_DATE": "10-30-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Judy",
		 "CLAIM_NO": "2021-08-16-0474-VP-01",
		 "DURATION": 109,
		 "DISABILITY_START_DATE": "09-16-2021 ",
		 "DATE_CLAIM_CLOSED": "01-10-2022 ",
		 "ACTUAL_RTW_DATE": "01-03-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Mark",
		 "CLAIM_NO": "2021-09-01-0984-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "08-24-2021 ",
		 "DATE_CLAIM_CLOSED": "09-29-2021 ",
		 "ACTUAL_RTW_DATE": "09-07-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Maricela",
		 "CLAIM_NO": "2013-01-23-0196-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "02-21-2013 ",
		 "DATE_CLAIM_CLOSED": "05-17-2013 ",
		 "ACTUAL_RTW_DATE": "03-14-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2020-05-01-0256-VP-01",
		 "DURATION": 126,
		 "DISABILITY_START_DATE": "04-28-2020 ",
		 "DATE_CLAIM_CLOSED": "09-01-2020 ",
		 "ACTUAL_RTW_DATE": "09-01-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Carmen",
		 "CLAIM_NO": "2018-07-24-0533-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "07-17-2018 ",
		 "DATE_CLAIM_CLOSED": "08-14-2018 ",
		 "ACTUAL_RTW_DATE": "08-13-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Sonia",
		 "CLAIM_NO": "2020-09-01-0805-VP-01",
		 "DURATION": 6,
		 "DISABILITY_START_DATE": "08-31-2020 ",
		 "DATE_CLAIM_CLOSED": "09-10-2020 ",
		 "ACTUAL_RTW_DATE": "09-06-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Kelly",
		 "CLAIM_NO": "2016-05-23-0401-VP-01",
		 "DURATION": 53,
		 "DISABILITY_START_DATE": "05-19-2016 ",
		 "DATE_CLAIM_CLOSED": "08-11-2016 ",
		 "ACTUAL_RTW_DATE": "07-11-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Venalie",
		 "CLAIM_NO": "2014-04-15-0531-VP-01",
		 "DURATION": 53,
		 "DISABILITY_START_DATE": "04-28-2014 ",
		 "DATE_CLAIM_CLOSED": "11-03-2014 ",
		 "ACTUAL_RTW_DATE": "06-20-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Escolastica",
		 "CLAIM_NO": "2016-08-26-0372-VP-01",
		 "DURATION": 178,
		 "DISABILITY_START_DATE": "08-26-2016 ",
		 "DATE_CLAIM_CLOSED": "02-22-2017 ",
		 "ACTUAL_RTW_DATE": "02-20-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2021-02-09-0978-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "02-05-2021 ",
		 "DATE_CLAIM_CLOSED": "02-15-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Sonia",
		 "CLAIM_NO": "2016-08-05-0197-VP-01",
		 "DURATION": 82,
		 "DISABILITY_START_DATE": "09-01-2016 ",
		 "DATE_CLAIM_CLOSED": "11-29-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Cricelda",
		 "CLAIM_NO": "2013-01-05-0811-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "09-19-2012 ",
		 "DATE_CLAIM_CLOSED": "02-26-2013 ",
		 "ACTUAL_RTW_DATE": "10-13-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Jessie",
		 "CLAIM_NO": "2022-01-05-0832-VP-01",
		 "DURATION": 74,
		 "DISABILITY_START_DATE": "01-04-2022 ",
		 "DATE_CLAIM_CLOSED": "03-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Cricelda",
		 "CLAIM_NO": "2013-04-17-0395-VP-01",
		 "DURATION": 260,
		 "DISABILITY_START_DATE": "04-16-2013 ",
		 "DATE_CLAIM_CLOSED": "02-11-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Elida",
		 "CLAIM_NO": "2021-04-23-0564-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "04-21-2021 ",
		 "DATE_CLAIM_CLOSED": "05-24-2021 ",
		 "ACTUAL_RTW_DATE": "05-20-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Cricelda",
		 "CLAIM_NO": "2013-01-05-0049-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "12-27-2012 ",
		 "DATE_CLAIM_CLOSED": "02-26-2013 ",
		 "ACTUAL_RTW_DATE": "01-08-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Judy",
		 "CLAIM_NO": "2021-05-28-0226-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "07-08-2021 ",
		 "DATE_CLAIM_CLOSED": "07-07-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Paul",
		 "CLAIM_NO": "2019-02-04-0770-VP-01",
		 "DURATION": 6,
		 "DISABILITY_START_DATE": "02-05-2019 ",
		 "DATE_CLAIM_CLOSED": "02-13-2019 ",
		 "ACTUAL_RTW_DATE": "02-11-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2019-09-04-0409-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "08-27-2019 ",
		 "DATE_CLAIM_CLOSED": "11-13-2019 ",
		 "ACTUAL_RTW_DATE": "09-09-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2022-09-06-1129-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "09-06-2022 ",
		 "DATE_CLAIM_CLOSED": "12-07-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Graciela",
		 "CLAIM_NO": "2014-10-08-0511-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "10-02-2014 ",
		 "DATE_CLAIM_CLOSED": "01-12-2015 ",
		 "ACTUAL_RTW_DATE": "10-13-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Alma",
		 "CLAIM_NO": "2018-01-15-0080-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "01-15-2018 ",
		 "DATE_CLAIM_CLOSED": "02-05-2018 ",
		 "ACTUAL_RTW_DATE": "01-31-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Carolina",
		 "CLAIM_NO": "2015-05-04-0226-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "05-04-2015 ",
		 "DATE_CLAIM_CLOSED": "07-07-2015 ",
		 "ACTUAL_RTW_DATE": "07-01-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Maricela",
		 "CLAIM_NO": "2014-07-18-0224-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "07-18-2014 ",
		 "DATE_CLAIM_CLOSED": "11-24-2014 ",
		 "ACTUAL_RTW_DATE": "08-04-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Vanessa",
		 "CLAIM_NO": "2022-08-09-0658-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "08-15-2022 ",
		 "DATE_CLAIM_CLOSED": "10-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Kelly",
		 "CLAIM_NO": "2013-01-05-0020-VP-01",
		 "DURATION": 25,
		 "DISABILITY_START_DATE": "12-24-2012 ",
		 "DATE_CLAIM_CLOSED": "05-15-2013 ",
		 "ACTUAL_RTW_DATE": "01-18-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia",
		 "CLAIMANT_FIRST_NAME": "Kelly",
		 "CLAIM_NO": "2017-01-09-0689-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "01-23-2017 ",
		 "DATE_CLAIM_CLOSED": "03-13-2017 ",
		 "ACTUAL_RTW_DATE": "03-07-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia Cabrera",
		 "CLAIMANT_FIRST_NAME": "Hector",
		 "CLAIM_NO": "2021-09-24-1009-VP-01",
		 "DURATION": 315,
		 "DISABILITY_START_DATE": "09-27-2021 ",
		 "DATE_CLAIM_CLOSED": "09-30-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia-Bello",
		 "CLAIMANT_FIRST_NAME": "Jorge",
		 "CLAIM_NO": "2019-03-06-0441-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "03-22-2019 ",
		 "DATE_CLAIM_CLOSED": "04-15-2019 ",
		 "ACTUAL_RTW_DATE": "04-08-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garcia-Contreras",
		 "CLAIMANT_FIRST_NAME": "Jessica",
		 "CLAIM_NO": "2013-01-05-0066-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "11-19-2012 ",
		 "DATE_CLAIM_CLOSED": "07-29-2013 ",
		 "ACTUAL_RTW_DATE": "01-28-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gardea",
		 "CLAIMANT_FIRST_NAME": "Gloria",
		 "CLAIM_NO": "2013-01-16-0348-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "01-23-2013 ",
		 "DATE_CLAIM_CLOSED": "05-20-2013 ",
		 "ACTUAL_RTW_DATE": "02-06-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gardner",
		 "CLAIMANT_FIRST_NAME": "Linda",
		 "CLAIM_NO": "2018-07-12-0448-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "08-02-2018 ",
		 "DATE_CLAIM_CLOSED": "09-06-2018 ",
		 "ACTUAL_RTW_DATE": "09-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garibay Lopez",
		 "CLAIMANT_FIRST_NAME": "Evelyn",
		 "CLAIM_NO": "2016-08-08-0367-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "08-02-2016 ",
		 "DATE_CLAIM_CLOSED": "08-30-2016 ",
		 "ACTUAL_RTW_DATE": "08-15-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garlejo",
		 "CLAIMANT_FIRST_NAME": "Margarita",
		 "CLAIM_NO": "2016-04-18-0130-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "04-21-2016 ",
		 "DATE_CLAIM_CLOSED": "06-08-2016 ",
		 "ACTUAL_RTW_DATE": "05-02-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garvin",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2013-02-01-0282-VP-01",
		 "DURATION": 83,
		 "DISABILITY_START_DATE": "02-08-2013 ",
		 "DATE_CLAIM_CLOSED": "07-11-2013 ",
		 "ACTUAL_RTW_DATE": "05-02-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garvin",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2017-10-23-0382-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "11-21-2017 ",
		 "DATE_CLAIM_CLOSED": "12-11-2017 ",
		 "ACTUAL_RTW_DATE": "12-05-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garza",
		 "CLAIMANT_FIRST_NAME": "Jesus",
		 "CLAIM_NO": "2013-01-05-7612-VP-01",
		 "DURATION": 1046,
		 "DISABILITY_START_DATE": "01-28-2010 ",
		 "DATE_CLAIM_CLOSED": "03-14-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garza",
		 "CLAIMANT_FIRST_NAME": "Hailee",
		 "CLAIM_NO": "2019-10-17-0355-VP-01",
		 "DURATION": 105,
		 "DISABILITY_START_DATE": "10-15-2019 ",
		 "DATE_CLAIM_CLOSED": "02-04-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garza",
		 "CLAIMANT_FIRST_NAME": "Sandra",
		 "CLAIM_NO": "2014-07-10-0318-VP-01",
		 "DURATION": 83,
		 "DISABILITY_START_DATE": "07-08-2014 ",
		 "DATE_CLAIM_CLOSED": "11-24-2014 ",
		 "ACTUAL_RTW_DATE": "09-29-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Garza",
		 "CLAIMANT_FIRST_NAME": "Sandra",
		 "CLAIM_NO": "2015-04-07-0461-VP-01",
		 "DURATION": 233,
		 "DISABILITY_START_DATE": "04-07-2015 ",
		 "DATE_CLAIM_CLOSED": "12-23-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gatchalian",
		 "CLAIMANT_FIRST_NAME": "Stephanie",
		 "CLAIM_NO": "2018-05-15-0618-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "06-11-2018 ",
		 "DATE_CLAIM_CLOSED": "08-20-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gatewood",
		 "CLAIMANT_FIRST_NAME": "Christopher",
		 "CLAIM_NO": "2014-05-22-0248-VP-01",
		 "DURATION": 132,
		 "DISABILITY_START_DATE": "05-22-2014 ",
		 "DATE_CLAIM_CLOSED": "10-06-2014 ",
		 "ACTUAL_RTW_DATE": "10-01-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gathairu",
		 "CLAIMANT_FIRST_NAME": "Faith",
		 "CLAIM_NO": "2022-12-30-0587-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "12-28-2022 ",
		 "DATE_CLAIM_CLOSED": "02-09-2023 ",
		 "ACTUAL_RTW_DATE": "01-25-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gauthier",
		 "CLAIMANT_FIRST_NAME": "Kelly",
		 "CLAIM_NO": "2019-12-12-0393-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "12-12-2019 ",
		 "DATE_CLAIM_CLOSED": "01-22-2020 ",
		 "ACTUAL_RTW_DATE": "01-22-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gavaller",
		 "CLAIMANT_FIRST_NAME": "Francis",
		 "CLAIM_NO": "2022-12-13-1009-VP-01",
		 "DURATION": 23,
		 "DISABILITY_START_DATE": "12-08-2022 ",
		 "DATE_CLAIM_CLOSED": "01-30-2023 ",
		 "ACTUAL_RTW_DATE": "12-31-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gavin",
		 "CLAIMANT_FIRST_NAME": "Melisande",
		 "CLAIM_NO": "2021-12-03-1001-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "11-22-2021 ",
		 "DATE_CLAIM_CLOSED": "01-18-2022 ",
		 "ACTUAL_RTW_DATE": "01-06-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gaytan",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2021-09-01-0407-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "08-30-2021 ",
		 "DATE_CLAIM_CLOSED": "10-05-2021 ",
		 "ACTUAL_RTW_DATE": "10-04-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Geiger",
		 "CLAIMANT_FIRST_NAME": "Stuart",
		 "CLAIM_NO": "2015-10-29-0267-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "10-27-2015 ",
		 "DATE_CLAIM_CLOSED": "12-28-2015 ",
		 "ACTUAL_RTW_DATE": "12-28-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Geiger",
		 "CLAIMANT_FIRST_NAME": "Stuart",
		 "CLAIM_NO": "2016-03-23-0279-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "03-17-2016 ",
		 "DATE_CLAIM_CLOSED": "05-24-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Geiger",
		 "CLAIMANT_FIRST_NAME": "Rachael",
		 "CLAIM_NO": "2021-08-30-1134-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "10-19-2021 ",
		 "DATE_CLAIM_CLOSED": "12-17-2021 ",
		 "ACTUAL_RTW_DATE": "12-14-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Genereux",
		 "CLAIMANT_FIRST_NAME": "Mark",
		 "CLAIM_NO": "2013-01-05-0774-VP-01",
		 "DURATION": 94,
		 "DISABILITY_START_DATE": "10-23-2012 ",
		 "DATE_CLAIM_CLOSED": "02-08-2013 ",
		 "ACTUAL_RTW_DATE": "01-25-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Genetti",
		 "CLAIMANT_FIRST_NAME": "Mary",
		 "CLAIM_NO": "2013-06-04-0257-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "05-28-2013 ",
		 "DATE_CLAIM_CLOSED": "08-01-2013 ",
		 "ACTUAL_RTW_DATE": "07-23-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "George",
		 "CLAIMANT_FIRST_NAME": "Nicholas",
		 "CLAIM_NO": "2016-04-05-0543-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "04-05-2016 ",
		 "DATE_CLAIM_CLOSED": "04-04-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gervacio",
		 "CLAIMANT_FIRST_NAME": "Antonio",
		 "CLAIM_NO": "2020-12-16-0670-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "12-01-2020 ",
		 "DATE_CLAIM_CLOSED": "02-24-2021 ",
		 "ACTUAL_RTW_DATE": "02-23-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gette",
		 "CLAIMANT_FIRST_NAME": "Ryan",
		 "CLAIM_NO": "2022-01-28-0482-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "01-26-2022 ",
		 "DATE_CLAIM_CLOSED": "04-04-2022 ",
		 "ACTUAL_RTW_DATE": "04-04-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Geverola",
		 "CLAIMANT_FIRST_NAME": "Vicente",
		 "CLAIM_NO": "2015-05-18-0391-VP-02",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "05-08-2015 ",
		 "DATE_CLAIM_CLOSED": "07-02-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Geverola",
		 "CLAIMANT_FIRST_NAME": "Vicente",
		 "CLAIM_NO": "2013-01-23-0128-VP-01",
		 "DURATION": -20,
		 "DISABILITY_START_DATE": "01-22-2013 ",
		 "DATE_CLAIM_CLOSED": "05-08-2013 ",
		 "ACTUAL_RTW_DATE": "01-02-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ghaffari",
		 "CLAIMANT_FIRST_NAME": "Haleh",
		 "CLAIM_NO": "2020-11-16-0198-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "11-11-2020 ",
		 "DATE_CLAIM_CLOSED": "12-02-2020 ",
		 "ACTUAL_RTW_DATE": "11-23-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ghaffari",
		 "CLAIMANT_FIRST_NAME": "Haleh",
		 "CLAIM_NO": "2019-03-11-0670-VP-01",
		 "DURATION": 83,
		 "DISABILITY_START_DATE": "04-04-2019 ",
		 "DATE_CLAIM_CLOSED": "06-26-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ghaffari",
		 "CLAIMANT_FIRST_NAME": "Haleh",
		 "CLAIM_NO": "2016-06-07-0231-VP-01",
		 "DURATION": 38,
		 "DISABILITY_START_DATE": "06-03-2016 ",
		 "DATE_CLAIM_CLOSED": "07-14-2016 ",
		 "ACTUAL_RTW_DATE": "07-11-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ghani",
		 "CLAIMANT_FIRST_NAME": "Ayesha",
		 "CLAIM_NO": "2013-02-07-0405-VP-01",
		 "DURATION": 224,
		 "DISABILITY_START_DATE": "02-18-2013 ",
		 "DATE_CLAIM_CLOSED": "02-18-2014 ",
		 "ACTUAL_RTW_DATE": "09-30-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ghani",
		 "CLAIMANT_FIRST_NAME": "Ayesha",
		 "CLAIM_NO": "2016-03-10-0132-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "03-10-2016 ",
		 "DATE_CLAIM_CLOSED": "04-26-2016 ",
		 "ACTUAL_RTW_DATE": "04-25-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ghani",
		 "CLAIMANT_FIRST_NAME": "Ayesha",
		 "CLAIM_NO": "2021-08-24-0788-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "09-01-2021 ",
		 "DATE_CLAIM_CLOSED": "10-04-2021 ",
		 "ACTUAL_RTW_DATE": "09-27-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ghani",
		 "CLAIMANT_FIRST_NAME": "Ayesha",
		 "CLAIM_NO": "2019-02-01-0004-VP-01",
		 "DURATION": 165,
		 "DISABILITY_START_DATE": "02-18-2019 ",
		 "DATE_CLAIM_CLOSED": "05-03-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ghani",
		 "CLAIMANT_FIRST_NAME": "Ayesha",
		 "CLAIM_NO": "2017-12-26-0161-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "12-22-2017 ",
		 "DATE_CLAIM_CLOSED": "03-06-2018 ",
		 "ACTUAL_RTW_DATE": "03-02-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ghanpur Balakrishna",
		 "CLAIMANT_FIRST_NAME": "Greeshmi",
		 "CLAIM_NO": "2019-07-29-0590-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "09-23-2019 ",
		 "DATE_CLAIM_CLOSED": "11-13-2019 ",
		 "ACTUAL_RTW_DATE": "11-02-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ghasemi",
		 "CLAIMANT_FIRST_NAME": "Zahra",
		 "CLAIM_NO": "2020-09-28-0579-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "10-19-2020 ",
		 "DATE_CLAIM_CLOSED": "12-02-2020 ",
		 "ACTUAL_RTW_DATE": "11-29-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ghazarian",
		 "CLAIMANT_FIRST_NAME": "Amy-Lynne",
		 "CLAIM_NO": "2014-11-26-0523-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "12-15-2014 ",
		 "DATE_CLAIM_CLOSED": "03-02-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Giancola-Coleman",
		 "CLAIMANT_FIRST_NAME": "Leslie",
		 "CLAIM_NO": "2013-11-06-0429-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "11-18-2013 ",
		 "DATE_CLAIM_CLOSED": "08-11-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gil",
		 "CLAIMANT_FIRST_NAME": "Sylvia",
		 "CLAIM_NO": "2013-01-05-0129-VP-01",
		 "DURATION": 25,
		 "DISABILITY_START_DATE": "12-13-2012 ",
		 "DATE_CLAIM_CLOSED": "01-31-2013 ",
		 "ACTUAL_RTW_DATE": "01-07-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gil",
		 "CLAIMANT_FIRST_NAME": "Sylvia",
		 "CLAIM_NO": "2014-05-08-0489-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "05-05-2014 ",
		 "DATE_CLAIM_CLOSED": "08-04-2014 ",
		 "ACTUAL_RTW_DATE": "05-19-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gil",
		 "CLAIMANT_FIRST_NAME": "Miguel",
		 "CLAIM_NO": "2019-03-08-0582-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "03-11-2019 ",
		 "DATE_CLAIM_CLOSED": "05-13-2019 ",
		 "ACTUAL_RTW_DATE": "05-09-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gil",
		 "CLAIMANT_FIRST_NAME": "Sylvia",
		 "CLAIM_NO": "2014-09-30-0344-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "09-29-2014 ",
		 "DATE_CLAIM_CLOSED": "10-13-2014 ",
		 "ACTUAL_RTW_DATE": "10-06-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gil",
		 "CLAIMANT_FIRST_NAME": "Miguel",
		 "CLAIM_NO": "2020-08-03-0278-VP-01",
		 "DURATION": 126,
		 "DISABILITY_START_DATE": "08-10-2020 ",
		 "DATE_CLAIM_CLOSED": "12-15-2020 ",
		 "ACTUAL_RTW_DATE": "12-14-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gil",
		 "CLAIMANT_FIRST_NAME": "Sylvia",
		 "CLAIM_NO": "2013-02-11-0110-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "02-08-2013 ",
		 "DATE_CLAIM_CLOSED": "02-11-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gil",
		 "CLAIMANT_FIRST_NAME": "Miguel",
		 "CLAIM_NO": "2016-08-12-0146-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "08-08-2016 ",
		 "DATE_CLAIM_CLOSED": "08-17-2016 ",
		 "ACTUAL_RTW_DATE": "08-15-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gil",
		 "CLAIMANT_FIRST_NAME": "Hector",
		 "CLAIM_NO": "2019-07-09-0384-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "07-08-2019 ",
		 "DATE_CLAIM_CLOSED": "09-17-2019 ",
		 "ACTUAL_RTW_DATE": "08-24-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gil",
		 "CLAIMANT_FIRST_NAME": "Miguel",
		 "CLAIM_NO": "2017-06-07-0539-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "06-26-2017 ",
		 "DATE_CLAIM_CLOSED": "09-25-2017 ",
		 "ACTUAL_RTW_DATE": "09-25-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gil Soto",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2016-09-20-0459-VP-01",
		 "DURATION": 131,
		 "DISABILITY_START_DATE": "09-14-2016 ",
		 "DATE_CLAIM_CLOSED": "07-17-2017 ",
		 "ACTUAL_RTW_DATE": "01-23-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gil Soto",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2018-05-23-0726-VP-01",
		 "DURATION": 103,
		 "DISABILITY_START_DATE": "05-23-2018 ",
		 "DATE_CLAIM_CLOSED": "10-08-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gil Soto",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2013-08-21-0070-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "08-19-2013 ",
		 "DATE_CLAIM_CLOSED": "09-09-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gil Soto",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2015-03-27-0135-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "03-23-2015 ",
		 "DATE_CLAIM_CLOSED": "06-16-2015 ",
		 "ACTUAL_RTW_DATE": "04-06-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gildea",
		 "CLAIMANT_FIRST_NAME": "Richard",
		 "CLAIM_NO": "2016-04-19-0574-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "04-25-2016 ",
		 "DATE_CLAIM_CLOSED": "07-07-2016 ",
		 "ACTUAL_RTW_DATE": "07-05-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gill",
		 "CLAIMANT_FIRST_NAME": "Russell",
		 "CLAIM_NO": "2022-03-03-0633-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "03-16-2022 ",
		 "DATE_CLAIM_CLOSED": "06-06-2022 ",
		 "ACTUAL_RTW_DATE": "05-03-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gill",
		 "CLAIMANT_FIRST_NAME": "Navneet",
		 "CLAIM_NO": "2013-06-14-0364-VP-01",
		 "DURATION": 115,
		 "DISABILITY_START_DATE": "07-12-2013 ",
		 "DATE_CLAIM_CLOSED": "09-25-2013 ",
		 "ACTUAL_RTW_DATE": "11-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gill",
		 "CLAIMANT_FIRST_NAME": "Paul",
		 "CLAIM_NO": "2019-09-23-0282-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "09-23-2019 ",
		 "DATE_CLAIM_CLOSED": "02-03-2020 ",
		 "ACTUAL_RTW_DATE": "10-07-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gimeno",
		 "CLAIMANT_FIRST_NAME": "Gemma",
		 "CLAIM_NO": "2013-01-05-0324-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "11-12-2012 ",
		 "DATE_CLAIM_CLOSED": "02-21-2013 ",
		 "ACTUAL_RTW_DATE": "12-11-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gines",
		 "CLAIMANT_FIRST_NAME": "Nikki",
		 "CLAIM_NO": "2020-04-13-0382-VP-01",
		 "DURATION": 67,
		 "DISABILITY_START_DATE": "05-15-2020 ",
		 "DATE_CLAIM_CLOSED": "07-21-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Giries",
		 "CLAIMANT_FIRST_NAME": "Marlene",
		 "CLAIM_NO": "2017-01-04-0197-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "01-26-2017 ",
		 "DATE_CLAIM_CLOSED": "02-22-2017 ",
		 "ACTUAL_RTW_DATE": "02-14-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Giron Yanes",
		 "CLAIMANT_FIRST_NAME": "Arnoldo",
		 "CLAIM_NO": "2013-06-13-0100-VP-01",
		 "DURATION": 210,
		 "DISABILITY_START_DATE": "07-02-2013 ",
		 "DATE_CLAIM_CLOSED": "02-07-2014 ",
		 "ACTUAL_RTW_DATE": "01-28-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Go-Oco",
		 "CLAIMANT_FIRST_NAME": "Ann",
		 "CLAIM_NO": "2013-01-05-1181-VP-01",
		 "DURATION": 249,
		 "DISABILITY_START_DATE": "08-30-2012 ",
		 "DATE_CLAIM_CLOSED": "07-11-2013 ",
		 "ACTUAL_RTW_DATE": "05-06-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Godinez",
		 "CLAIMANT_FIRST_NAME": "Monica",
		 "CLAIM_NO": "2013-01-07-0360-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "01-18-2013 ",
		 "DATE_CLAIM_CLOSED": "05-13-2013 ",
		 "ACTUAL_RTW_DATE": "03-30-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Goff",
		 "CLAIMANT_FIRST_NAME": "Naomi",
		 "CLAIM_NO": "2017-08-15-0509-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "09-18-2017 ",
		 "DATE_CLAIM_CLOSED": "11-27-2017 ",
		 "ACTUAL_RTW_DATE": "11-20-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Golzar",
		 "CLAIMANT_FIRST_NAME": "Aida",
		 "CLAIM_NO": "2021-10-04-0683-VP-01",
		 "DURATION": 408,
		 "DISABILITY_START_DATE": "10-12-2021 ",
		 "DATE_CLAIM_CLOSED": "11-24-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gomes",
		 "CLAIMANT_FIRST_NAME": "Bridget",
		 "CLAIM_NO": "2021-01-18-0283-VP-01",
		 "DURATION": 154,
		 "DISABILITY_START_DATE": "01-28-2021 ",
		 "DATE_CLAIM_CLOSED": "07-22-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gomez",
		 "CLAIMANT_FIRST_NAME": "Emerita",
		 "CLAIM_NO": "2013-01-05-2824-VP-01",
		 "DURATION": 331,
		 "DISABILITY_START_DATE": "02-06-2012 ",
		 "DATE_CLAIM_CLOSED": "01-07-2013 ",
		 "ACTUAL_RTW_DATE": "01-02-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gomez",
		 "CLAIMANT_FIRST_NAME": "Jasmine",
		 "CLAIM_NO": "2019-04-09-0568-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "04-09-2019 ",
		 "DATE_CLAIM_CLOSED": "05-23-2019 ",
		 "ACTUAL_RTW_DATE": "05-22-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gomez",
		 "CLAIMANT_FIRST_NAME": "Jasmine",
		 "CLAIM_NO": "2014-07-29-0221-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "07-29-2014 ",
		 "DATE_CLAIM_CLOSED": "12-05-2014 ",
		 "ACTUAL_RTW_DATE": "08-08-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gomez",
		 "CLAIMANT_FIRST_NAME": "Brandon",
		 "CLAIM_NO": "2018-11-15-0476-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "11-09-2018 ",
		 "DATE_CLAIM_CLOSED": "01-23-2019 ",
		 "ACTUAL_RTW_DATE": "12-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gomez",
		 "CLAIMANT_FIRST_NAME": "San Juana",
		 "CLAIM_NO": "2022-10-07-0274-VP-01",
		 "DURATION": 52,
		 "DISABILITY_START_DATE": "10-14-2022 ",
		 "DATE_CLAIM_CLOSED": "12-27-2022 ",
		 "ACTUAL_RTW_DATE": "12-05-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gomez",
		 "CLAIMANT_FIRST_NAME": "Margarita",
		 "CLAIM_NO": "2021-07-16-0776-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "07-07-2021 ",
		 "DATE_CLAIM_CLOSED": "08-04-2021 ",
		 "ACTUAL_RTW_DATE": "08-02-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gomez",
		 "CLAIMANT_FIRST_NAME": "Dominic",
		 "CLAIM_NO": "2021-12-10-1001-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "12-20-2021 ",
		 "DATE_CLAIM_CLOSED": "01-19-2022 ",
		 "ACTUAL_RTW_DATE": "01-17-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gomez",
		 "CLAIMANT_FIRST_NAME": "Jasmine",
		 "CLAIM_NO": "2020-08-13-0774-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "08-17-2020 ",
		 "DATE_CLAIM_CLOSED": "09-03-2020 ",
		 "ACTUAL_RTW_DATE": "09-03-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzales",
		 "CLAIMANT_FIRST_NAME": "Carolyn",
		 "CLAIM_NO": "2013-01-05-0586-VP-01",
		 "DURATION": 162,
		 "DISABILITY_START_DATE": "10-22-2012 ",
		 "DATE_CLAIM_CLOSED": "05-07-2013 ",
		 "ACTUAL_RTW_DATE": "04-02-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzales",
		 "CLAIMANT_FIRST_NAME": "Juanita",
		 "CLAIM_NO": "2021-10-11-0970-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "10-14-2021 ",
		 "DATE_CLAIM_CLOSED": "12-02-2021 ",
		 "ACTUAL_RTW_DATE": "12-01-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzales",
		 "CLAIMANT_FIRST_NAME": "Jesus",
		 "CLAIM_NO": "2013-01-05-4181-VP-01",
		 "DURATION": 949,
		 "DISABILITY_START_DATE": "08-30-2011 ",
		 "DATE_CLAIM_CLOSED": "04-07-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzales",
		 "CLAIMANT_FIRST_NAME": "Gina",
		 "CLAIM_NO": "2021-03-04-0844-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "02-22-2021 ",
		 "DATE_CLAIM_CLOSED": "03-24-2021 ",
		 "ACTUAL_RTW_DATE": "03-22-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzales",
		 "CLAIMANT_FIRST_NAME": "Brienne",
		 "CLAIM_NO": "2015-09-09-0075-VP-01",
		 "DURATION": 119,
		 "DISABILITY_START_DATE": "10-05-2015 ",
		 "DATE_CLAIM_CLOSED": "12-07-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2021-07-07-0501-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "07-06-2021 ",
		 "DATE_CLAIM_CLOSED": "07-22-2021 ",
		 "ACTUAL_RTW_DATE": "07-21-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Alma",
		 "CLAIM_NO": "2014-04-15-0369-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "04-15-2014 ",
		 "DATE_CLAIM_CLOSED": "07-28-2014 ",
		 "ACTUAL_RTW_DATE": "05-07-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Reyna",
		 "CLAIM_NO": "2014-06-30-0245-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "07-14-2014 ",
		 "DATE_CLAIM_CLOSED": "11-05-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Leticia",
		 "CLAIM_NO": "2014-04-29-0533-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "04-28-2014 ",
		 "DATE_CLAIM_CLOSED": "09-26-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2019-09-23-0853-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "09-17-2019 ",
		 "DATE_CLAIM_CLOSED": "10-07-2019 ",
		 "ACTUAL_RTW_DATE": "09-30-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2013-10-21-0058-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "09-24-2013 ",
		 "DATE_CLAIM_CLOSED": "12-15-2014 ",
		 "ACTUAL_RTW_DATE": "12-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2022-05-26-0746-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "05-24-2022 ",
		 "DATE_CLAIM_CLOSED": "08-17-2022 ",
		 "ACTUAL_RTW_DATE": "06-20-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Olga",
		 "CLAIM_NO": "2020-09-01-0155-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "10-15-2020 ",
		 "DATE_CLAIM_CLOSED": "12-17-2020 ",
		 "ACTUAL_RTW_DATE": "11-12-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Yvette",
		 "CLAIM_NO": "2022-01-20-0582-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "02-02-2022 ",
		 "DATE_CLAIM_CLOSED": "03-02-2022 ",
		 "ACTUAL_RTW_DATE": "02-28-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Olga",
		 "CLAIM_NO": "2019-05-17-0142-VP-01",
		 "DURATION": 93,
		 "DISABILITY_START_DATE": "05-16-2019 ",
		 "DATE_CLAIM_CLOSED": "10-10-2019 ",
		 "ACTUAL_RTW_DATE": "08-17-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2020-01-21-0404-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "01-13-2020 ",
		 "DATE_CLAIM_CLOSED": "01-30-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Sandra",
		 "CLAIM_NO": "2013-01-05-2886-VP-01",
		 "DURATION": 1263,
		 "DISABILITY_START_DATE": "01-26-2012 ",
		 "DATE_CLAIM_CLOSED": "09-12-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Mercedes",
		 "CLAIM_NO": "2014-09-17-0285-VP-01",
		 "DURATION": 30,
		 "DISABILITY_START_DATE": "09-12-2014 ",
		 "DATE_CLAIM_CLOSED": "01-20-2015 ",
		 "ACTUAL_RTW_DATE": "10-12-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Josephine",
		 "CLAIM_NO": "2013-09-07-0046-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "09-24-2013 ",
		 "DATE_CLAIM_CLOSED": "06-02-2014 ",
		 "ACTUAL_RTW_DATE": "11-05-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2017-08-10-0345-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "08-10-2017 ",
		 "DATE_CLAIM_CLOSED": "08-29-2017 ",
		 "ACTUAL_RTW_DATE": "08-24-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Avelina",
		 "CLAIM_NO": "2013-11-04-0271-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "11-15-2013 ",
		 "DATE_CLAIM_CLOSED": "02-18-2014 ",
		 "ACTUAL_RTW_DATE": "01-02-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Maritza",
		 "CLAIM_NO": "2021-08-06-0590-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "08-03-2021 ",
		 "DATE_CLAIM_CLOSED": "08-25-2021 ",
		 "ACTUAL_RTW_DATE": "08-23-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Olga",
		 "CLAIM_NO": "2021-12-13-1032-VP-01",
		 "DURATION": 292,
		 "DISABILITY_START_DATE": "12-13-2021 ",
		 "DATE_CLAIM_CLOSED": "12-22-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Kenia",
		 "CLAIM_NO": "2020-09-24-0307-VP-01",
		 "DURATION": 73,
		 "DISABILITY_START_DATE": "07-20-2020 ",
		 "DATE_CLAIM_CLOSED": "10-13-2020 ",
		 "ACTUAL_RTW_DATE": "10-01-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2017-04-25-0369-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "03-20-2017 ",
		 "DATE_CLAIM_CLOSED": "06-14-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2017-03-17-0169-VP-01",
		 "DURATION": 69,
		 "DISABILITY_START_DATE": "03-20-2017 ",
		 "DATE_CLAIM_CLOSED": "04-26-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Oscar",
		 "CLAIM_NO": "2018-06-22-0651-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "06-18-2018 ",
		 "DATE_CLAIM_CLOSED": "06-17-2019 ",
		 "ACTUAL_RTW_DATE": "06-17-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Leticia",
		 "CLAIM_NO": "2014-07-29-0507-VP-01",
		 "DURATION": 363,
		 "DISABILITY_START_DATE": "07-28-2014 ",
		 "DATE_CLAIM_CLOSED": "08-03-2015 ",
		 "ACTUAL_RTW_DATE": "07-26-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Rodolfo",
		 "CLAIM_NO": "2018-07-12-0672-VP-01",
		 "DURATION": 5,
		 "DISABILITY_START_DATE": "07-11-2018 ",
		 "DATE_CLAIM_CLOSED": "07-23-2018 ",
		 "ACTUAL_RTW_DATE": "07-16-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Kenia",
		 "CLAIM_NO": "2021-02-17-0011-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "02-17-2021 ",
		 "DATE_CLAIM_CLOSED": "04-15-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez",
		 "CLAIMANT_FIRST_NAME": "Olga",
		 "CLAIM_NO": "2018-03-14-0274-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "03-14-2018 ",
		 "DATE_CLAIM_CLOSED": "06-11-2018 ",
		 "ACTUAL_RTW_DATE": "06-06-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gonzalez Castillo",
		 "CLAIMANT_FIRST_NAME": "Adelina",
		 "CLAIM_NO": "2015-05-04-0253-VP-01",
		 "DURATION": 6,
		 "DISABILITY_START_DATE": "04-30-2015 ",
		 "DATE_CLAIM_CLOSED": "05-14-2015 ",
		 "ACTUAL_RTW_DATE": "05-06-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gordon",
		 "CLAIMANT_FIRST_NAME": "Tai",
		 "CLAIM_NO": "2018-10-04-0150-VP-01",
		 "DURATION": 92,
		 "DISABILITY_START_DATE": "11-19-2018 ",
		 "DATE_CLAIM_CLOSED": "02-22-2019 ",
		 "ACTUAL_RTW_DATE": "02-19-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gordon",
		 "CLAIMANT_FIRST_NAME": "Collin",
		 "CLAIM_NO": "2018-06-07-0443-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "06-06-2018 ",
		 "DATE_CLAIM_CLOSED": "07-25-2018 ",
		 "ACTUAL_RTW_DATE": "06-18-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gorina",
		 "CLAIMANT_FIRST_NAME": "Svetlana",
		 "CLAIM_NO": "2017-03-30-0520-VP-01",
		 "DURATION": 53,
		 "DISABILITY_START_DATE": "03-30-2017 ",
		 "DATE_CLAIM_CLOSED": "05-23-2017 ",
		 "ACTUAL_RTW_DATE": "05-22-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gorina",
		 "CLAIMANT_FIRST_NAME": "Svetlana",
		 "CLAIM_NO": "2015-05-08-0042-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "05-12-2015 ",
		 "DATE_CLAIM_CLOSED": "07-07-2015 ",
		 "ACTUAL_RTW_DATE": "05-27-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gorman",
		 "CLAIMANT_FIRST_NAME": "Michael",
		 "CLAIM_NO": "2018-01-19-0508-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "01-09-2018 ",
		 "DATE_CLAIM_CLOSED": "03-09-2018 ",
		 "ACTUAL_RTW_DATE": "01-19-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gorman",
		 "CLAIMANT_FIRST_NAME": "Natalie",
		 "CLAIM_NO": "2019-09-16-0616-VP-01",
		 "DURATION": 101,
		 "DISABILITY_START_DATE": "09-17-2019 ",
		 "DATE_CLAIM_CLOSED": "03-11-2020 ",
		 "ACTUAL_RTW_DATE": "12-27-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gorman",
		 "CLAIMANT_FIRST_NAME": "Natalie",
		 "CLAIM_NO": "2016-06-02-0267-VP-01",
		 "DURATION": 69,
		 "DISABILITY_START_DATE": "05-24-2016 ",
		 "DATE_CLAIM_CLOSED": "08-11-2016 ",
		 "ACTUAL_RTW_DATE": "08-01-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gorman",
		 "CLAIMANT_FIRST_NAME": "Natalie",
		 "CLAIM_NO": "2013-12-21-0130-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "12-30-2013 ",
		 "DATE_CLAIM_CLOSED": "03-17-2014 ",
		 "ACTUAL_RTW_DATE": "01-14-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gorman Iii",
		 "CLAIMANT_FIRST_NAME": "Clifford",
		 "CLAIM_NO": "2014-04-29-0164-VP-01",
		 "DURATION": 53,
		 "DISABILITY_START_DATE": "04-24-2014 ",
		 "DATE_CLAIM_CLOSED": "11-24-2014 ",
		 "ACTUAL_RTW_DATE": "06-16-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gosal",
		 "CLAIMANT_FIRST_NAME": "Megan",
		 "CLAIM_NO": "2020-07-09-0529-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "08-10-2020 ",
		 "DATE_CLAIM_CLOSED": "10-05-2020 ",
		 "ACTUAL_RTW_DATE": "10-05-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gosal",
		 "CLAIMANT_FIRST_NAME": "Megan",
		 "CLAIM_NO": "2022-02-25-0849-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "03-14-2022 ",
		 "DATE_CLAIM_CLOSED": "05-02-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Goudreau",
		 "CLAIMANT_FIRST_NAME": "James",
		 "CLAIM_NO": "2021-07-22-0862-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "08-26-2021 ",
		 "DATE_CLAIM_CLOSED": "10-26-2021 ",
		 "ACTUAL_RTW_DATE": "10-25-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Goyal",
		 "CLAIMANT_FIRST_NAME": "Surbhi",
		 "CLAIM_NO": "2022-01-21-1275-VP-01",
		 "DURATION": 134,
		 "DISABILITY_START_DATE": "02-21-2022 ",
		 "DATE_CLAIM_CLOSED": "07-12-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Goyal",
		 "CLAIMANT_FIRST_NAME": "Surbhi",
		 "CLAIM_NO": "2018-10-19-0018-VP-01",
		 "DURATION": 88,
		 "DISABILITY_START_DATE": "11-05-2018 ",
		 "DATE_CLAIM_CLOSED": "02-04-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Graef",
		 "CLAIMANT_FIRST_NAME": "Roxy",
		 "CLAIM_NO": "2019-02-25-0396-VP-01",
		 "DURATION": 78,
		 "DISABILITY_START_DATE": "03-18-2019 ",
		 "DATE_CLAIM_CLOSED": "06-10-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Granado",
		 "CLAIMANT_FIRST_NAME": "Vanessa",
		 "CLAIM_NO": "2022-11-09-0899-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "12-14-2022 ",
		 "DATE_CLAIM_CLOSED": "03-15-2023 ",
		 "ACTUAL_RTW_DATE": "03-08-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Granado",
		 "CLAIMANT_FIRST_NAME": "Vanessa",
		 "CLAIM_NO": "2021-12-06-0838-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "01-03-2022 ",
		 "DATE_CLAIM_CLOSED": "02-15-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gray",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2014-07-14-0619-VP-01",
		 "DURATION": 69,
		 "DISABILITY_START_DATE": "07-15-2014 ",
		 "DATE_CLAIM_CLOSED": "02-09-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Greathouse",
		 "CLAIMANT_FIRST_NAME": "Daniel",
		 "CLAIM_NO": "2015-09-09-0193-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "10-12-2015 ",
		 "DATE_CLAIM_CLOSED": "11-02-2015 ",
		 "ACTUAL_RTW_DATE": "10-26-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Greenberg",
		 "CLAIMANT_FIRST_NAME": "Jeremy",
		 "CLAIM_NO": "2021-09-07-0498-VP-01",
		 "DURATION": 82,
		 "DISABILITY_START_DATE": "09-08-2021 ",
		 "DATE_CLAIM_CLOSED": "12-01-2021 ",
		 "ACTUAL_RTW_DATE": "11-29-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Greene",
		 "CLAIMANT_FIRST_NAME": "Carla",
		 "CLAIM_NO": "2013-10-14-0493-VP-01",
		 "DURATION": 98,
		 "DISABILITY_START_DATE": "10-14-2013 ",
		 "DATE_CLAIM_CLOSED": "05-07-2014 ",
		 "ACTUAL_RTW_DATE": "01-20-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Greer",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2020-05-22-0571-VP-01",
		 "DURATION": 53,
		 "DISABILITY_START_DATE": "04-20-2020 ",
		 "DATE_CLAIM_CLOSED": "06-12-2020 ",
		 "ACTUAL_RTW_DATE": "06-12-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Greer",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2022-02-22-0926-VP-01",
		 "DURATION": 183,
		 "DISABILITY_START_DATE": "10-22-2021 ",
		 "DATE_CLAIM_CLOSED": "03-08-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Greiner",
		 "CLAIMANT_FIRST_NAME": "Rachael",
		 "CLAIM_NO": "2016-01-13-0443-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "02-15-2016 ",
		 "DATE_CLAIM_CLOSED": "04-19-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gremminger",
		 "CLAIMANT_FIRST_NAME": "Joyce",
		 "CLAIM_NO": "2014-07-25-0336-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "07-28-2014 ",
		 "DATE_CLAIM_CLOSED": "11-03-2014 ",
		 "ACTUAL_RTW_DATE": "08-25-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gremyachinskiy",
		 "CLAIMANT_FIRST_NAME": "Tatsiana",
		 "CLAIM_NO": "2018-01-02-0749-VP-01",
		 "DURATION": 144,
		 "DISABILITY_START_DATE": "02-01-2018 ",
		 "DATE_CLAIM_CLOSED": "04-10-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Grenis",
		 "CLAIMANT_FIRST_NAME": "Lyndee",
		 "CLAIM_NO": "2022-08-04-0927-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "09-20-2022 ",
		 "DATE_CLAIM_CLOSED": "11-07-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Grier",
		 "CLAIMANT_FIRST_NAME": "Tammy",
		 "CLAIM_NO": "2014-11-19-0159-VP-01",
		 "DURATION": 61,
		 "DISABILITY_START_DATE": "11-19-2014 ",
		 "DATE_CLAIM_CLOSED": "08-04-2015 ",
		 "ACTUAL_RTW_DATE": "01-19-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Griffin",
		 "CLAIMANT_FIRST_NAME": "Julie",
		 "CLAIM_NO": "2021-02-02-0353-VP-01",
		 "DURATION": 89,
		 "DISABILITY_START_DATE": "03-08-2021 ",
		 "DATE_CLAIM_CLOSED": "06-10-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Griffith",
		 "CLAIMANT_FIRST_NAME": "Raymond",
		 "CLAIM_NO": "2017-08-23-0367-VP-01",
		 "DURATION": 182,
		 "DISABILITY_START_DATE": "10-06-2017 ",
		 "DATE_CLAIM_CLOSED": "05-11-2018 ",
		 "ACTUAL_RTW_DATE": "04-06-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Grigoryan",
		 "CLAIMANT_FIRST_NAME": "Meri",
		 "CLAIM_NO": "2018-05-22-0724-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "06-06-2018 ",
		 "DATE_CLAIM_CLOSED": "07-10-2018 ",
		 "ACTUAL_RTW_DATE": "07-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Grimares",
		 "CLAIMANT_FIRST_NAME": "Armie",
		 "CLAIM_NO": "2020-07-17-0404-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "07-20-2020 ",
		 "DATE_CLAIM_CLOSED": "08-25-2020 ",
		 "ACTUAL_RTW_DATE": "08-17-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Grimes",
		 "CLAIMANT_FIRST_NAME": "Kathy",
		 "CLAIM_NO": "2013-01-09-0178-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "01-08-2013 ",
		 "DATE_CLAIM_CLOSED": "01-28-2013 ",
		 "ACTUAL_RTW_DATE": "01-28-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Grimes",
		 "CLAIMANT_FIRST_NAME": "Kathy",
		 "CLAIM_NO": "2014-08-12-0232-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "08-12-2014 ",
		 "DATE_CLAIM_CLOSED": "01-13-2015 ",
		 "ACTUAL_RTW_DATE": "10-13-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Grohmann",
		 "CLAIMANT_FIRST_NAME": "Ismene",
		 "CLAIM_NO": "2022-03-08-0657-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "05-02-2022 ",
		 "DATE_CLAIM_CLOSED": "05-26-2022 ",
		 "ACTUAL_RTW_DATE": "05-23-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Grover",
		 "CLAIMANT_FIRST_NAME": "Binita",
		 "CLAIM_NO": "2022-07-22-0909-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "09-05-2022 ",
		 "DATE_CLAIM_CLOSED": "12-27-2022 ",
		 "ACTUAL_RTW_DATE": "11-12-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Guardiano",
		 "CLAIMANT_FIRST_NAME": "John",
		 "CLAIM_NO": "2017-05-18-0523-VP-01",
		 "DURATION": 38,
		 "DISABILITY_START_DATE": "05-26-2017 ",
		 "DATE_CLAIM_CLOSED": "07-05-2017 ",
		 "ACTUAL_RTW_DATE": "07-03-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gudiel",
		 "CLAIMANT_FIRST_NAME": "Anthony",
		 "CLAIM_NO": "2022-04-28-0903-VP-01",
		 "DURATION": 74,
		 "DISABILITY_START_DATE": "05-05-2022 ",
		 "DATE_CLAIM_CLOSED": "05-09-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gueco",
		 "CLAIMANT_FIRST_NAME": "Myra",
		 "CLAIM_NO": "2020-02-28-0793-VP-01",
		 "DURATION": 123,
		 "DISABILITY_START_DATE": "03-02-2020 ",
		 "DATE_CLAIM_CLOSED": "07-06-2020 ",
		 "ACTUAL_RTW_DATE": "07-03-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Guerrero",
		 "CLAIMANT_FIRST_NAME": "Graciela",
		 "CLAIM_NO": "2021-05-28-0380-VP-01",
		 "DURATION": 461,
		 "DISABILITY_START_DATE": "05-28-2021 ",
		 "DATE_CLAIM_CLOSED": "09-30-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Guerrero",
		 "CLAIMANT_FIRST_NAME": "Dolores",
		 "CLAIM_NO": "2022-11-09-1043-VP-01",
		 "DURATION": 61,
		 "DISABILITY_START_DATE": "11-30-2022 ",
		 "DATE_CLAIM_CLOSED": "03-06-2023 ",
		 "ACTUAL_RTW_DATE": "01-30-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Guerrero",
		 "CLAIMANT_FIRST_NAME": "Erica",
		 "CLAIM_NO": "2019-08-05-0628-VP-01",
		 "DURATION": 131,
		 "DISABILITY_START_DATE": "08-28-2019 ",
		 "DATE_CLAIM_CLOSED": "01-15-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Guerrero",
		 "CLAIMANT_FIRST_NAME": "Janelle",
		 "CLAIM_NO": "2018-11-14-0312-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "11-06-2018 ",
		 "DATE_CLAIM_CLOSED": "12-11-2018 ",
		 "ACTUAL_RTW_DATE": "11-30-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gugert",
		 "CLAIMANT_FIRST_NAME": "Kimberly",
		 "CLAIM_NO": "2014-10-06-0580-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "09-30-2014 ",
		 "DATE_CLAIM_CLOSED": "12-09-2014 ",
		 "ACTUAL_RTW_DATE": "10-27-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Guillen",
		 "CLAIMANT_FIRST_NAME": "Linda",
		 "CLAIM_NO": "2020-04-27-0340-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "06-05-2020 ",
		 "DATE_CLAIM_CLOSED": "07-21-2020 ",
		 "ACTUAL_RTW_DATE": "07-20-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gulley",
		 "CLAIMANT_FIRST_NAME": "Karlee",
		 "CLAIM_NO": "2016-06-01-0543-VP-01",
		 "DURATION": 98,
		 "DISABILITY_START_DATE": "06-06-2016 ",
		 "DATE_CLAIM_CLOSED": "09-30-2016 ",
		 "ACTUAL_RTW_DATE": "09-12-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gulley",
		 "CLAIMANT_FIRST_NAME": "Karlee",
		 "CLAIM_NO": "2019-10-18-0616-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "11-05-2019 ",
		 "DATE_CLAIM_CLOSED": "12-09-2019 ",
		 "ACTUAL_RTW_DATE": "11-18-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gulley",
		 "CLAIMANT_FIRST_NAME": "Karlee",
		 "CLAIM_NO": "2014-12-15-0266-VP-01",
		 "DURATION": 87,
		 "DISABILITY_START_DATE": "01-07-2015 ",
		 "DATE_CLAIM_CLOSED": "04-13-2015 ",
		 "ACTUAL_RTW_DATE": "04-04-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gully",
		 "CLAIMANT_FIRST_NAME": "Samantha",
		 "CLAIM_NO": "2022-05-16-1028-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "06-28-2022 ",
		 "DATE_CLAIM_CLOSED": "09-12-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gully",
		 "CLAIMANT_FIRST_NAME": "Samantha",
		 "CLAIM_NO": "2022-11-21-1302-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "11-28-2022 ",
		 "DATE_CLAIM_CLOSED": "02-13-2023 ",
		 "ACTUAL_RTW_DATE": "12-27-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gunasekaran",
		 "CLAIMANT_FIRST_NAME": "Krithika",
		 "CLAIM_NO": "2015-05-29-0409-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "06-29-2015 ",
		 "DATE_CLAIM_CLOSED": "08-24-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gunasekaran",
		 "CLAIMANT_FIRST_NAME": "Krithika",
		 "CLAIM_NO": "2013-06-24-0386-VP-01",
		 "DURATION": 61,
		 "DISABILITY_START_DATE": "07-29-2013 ",
		 "DATE_CLAIM_CLOSED": "09-30-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gunn-Wilkinson",
		 "CLAIMANT_FIRST_NAME": "Maren",
		 "CLAIM_NO": "2016-11-16-0376-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "12-19-2016 ",
		 "DATE_CLAIM_CLOSED": "03-03-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gunn-Wilkinson",
		 "CLAIMANT_FIRST_NAME": "Maren",
		 "CLAIM_NO": "2014-06-09-0417-VP-01",
		 "DURATION": 69,
		 "DISABILITY_START_DATE": "07-07-2014 ",
		 "DATE_CLAIM_CLOSED": "01-15-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gupta",
		 "CLAIMANT_FIRST_NAME": "Devyani",
		 "CLAIM_NO": "2013-12-02-0343-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "01-01-2014 ",
		 "DATE_CLAIM_CLOSED": "05-30-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gurat",
		 "CLAIMANT_FIRST_NAME": "Elvira",
		 "CLAIM_NO": "2013-01-05-5185-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "05-26-2011 ",
		 "DATE_CLAIM_CLOSED": "03-13-2013 ",
		 "ACTUAL_RTW_DATE": "06-06-2011 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gurat",
		 "CLAIMANT_FIRST_NAME": "Elvira",
		 "CLAIM_NO": "2013-01-05-2106-VP-01",
		 "DURATION": 298,
		 "DISABILITY_START_DATE": "05-03-2012 ",
		 "DATE_CLAIM_CLOSED": "03-21-2013 ",
		 "ACTUAL_RTW_DATE": "02-25-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gusseck",
		 "CLAIMANT_FIRST_NAME": "Evelyn",
		 "CLAIM_NO": "2015-03-31-0432-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "03-31-2015 ",
		 "DATE_CLAIM_CLOSED": "06-30-2015 ",
		 "ACTUAL_RTW_DATE": "06-23-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gustafson-Davis",
		 "CLAIMANT_FIRST_NAME": "Kayla",
		 "CLAIM_NO": "2022-09-20-0515-VP-01",
		 "DURATION": 169,
		 "DISABILITY_START_DATE": "10-17-2022 ",
		 "DATE_CLAIM_CLOSED": "01-26-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gutierrez",
		 "CLAIMANT_FIRST_NAME": "Jeric",
		 "CLAIM_NO": "2020-04-01-0408-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "03-23-2020 ",
		 "DATE_CLAIM_CLOSED": "06-16-2020 ",
		 "ACTUAL_RTW_DATE": "06-15-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gutierrez",
		 "CLAIMANT_FIRST_NAME": "Pablo",
		 "CLAIM_NO": "2021-02-19-0468-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "03-01-2021 ",
		 "DATE_CLAIM_CLOSED": "05-04-2021 ",
		 "ACTUAL_RTW_DATE": "05-03-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gutierrez",
		 "CLAIMANT_FIRST_NAME": "Brenda",
		 "CLAIM_NO": "2022-07-06-1013-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "07-06-2022 ",
		 "DATE_CLAIM_CLOSED": "09-14-2022 ",
		 "ACTUAL_RTW_DATE": "08-16-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gutierrez",
		 "CLAIMANT_FIRST_NAME": "Irma",
		 "CLAIM_NO": "2020-01-06-0652-VP-01",
		 "DURATION": 65,
		 "DISABILITY_START_DATE": "02-27-2020 ",
		 "DATE_CLAIM_CLOSED": "05-01-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gutierrez",
		 "CLAIMANT_FIRST_NAME": "Aurora",
		 "CLAIM_NO": "2016-03-17-0322-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "03-11-2016 ",
		 "DATE_CLAIM_CLOSED": "05-03-2016 ",
		 "ACTUAL_RTW_DATE": "03-28-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gutierrez",
		 "CLAIMANT_FIRST_NAME": "Aurora",
		 "CLAIM_NO": "2020-03-25-0672-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "03-25-2020 ",
		 "DATE_CLAIM_CLOSED": "06-04-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gutierrez",
		 "CLAIMANT_FIRST_NAME": "Jeric",
		 "CLAIM_NO": "2020-09-14-0677-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "08-25-2020 ",
		 "DATE_CLAIM_CLOSED": "09-30-2020 ",
		 "ACTUAL_RTW_DATE": "09-28-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gutierrez",
		 "CLAIMANT_FIRST_NAME": "Emanaite",
		 "CLAIM_NO": "2020-01-13-0986-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "01-16-2020 ",
		 "DATE_CLAIM_CLOSED": "01-31-2020 ",
		 "ACTUAL_RTW_DATE": "01-27-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gutierrez Melgoza",
		 "CLAIMANT_FIRST_NAME": "Martha",
		 "CLAIM_NO": "2021-06-16-0380-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "06-07-2021 ",
		 "DATE_CLAIM_CLOSED": "07-22-2021 ",
		 "ACTUAL_RTW_DATE": "06-21-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gutierrez-Cortes",
		 "CLAIMANT_FIRST_NAME": "Margarita",
		 "CLAIM_NO": "2020-02-26-0235-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "02-25-2020 ",
		 "DATE_CLAIM_CLOSED": "03-10-2020 ",
		 "ACTUAL_RTW_DATE": "03-04-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Gutting",
		 "CLAIMANT_FIRST_NAME": "Christine",
		 "CLAIM_NO": "2021-08-20-0793-VP-01",
		 "DURATION": 96,
		 "DISABILITY_START_DATE": "09-08-2021 ",
		 "DATE_CLAIM_CLOSED": "12-14-2021 ",
		 "ACTUAL_RTW_DATE": "12-13-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Guzlas",
		 "CLAIMANT_FIRST_NAME": "Marki",
		 "CLAIM_NO": "2013-04-29-0457-VP-01",
		 "DURATION": 105,
		 "DISABILITY_START_DATE": "04-29-2013 ",
		 "DATE_CLAIM_CLOSED": "08-12-2013 ",
		 "ACTUAL_RTW_DATE": "08-12-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Guzlas",
		 "CLAIMANT_FIRST_NAME": "Marki",
		 "CLAIM_NO": "2013-01-30-0142-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "01-22-2013 ",
		 "DATE_CLAIM_CLOSED": "07-23-2013 ",
		 "ACTUAL_RTW_DATE": "02-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Guzman",
		 "CLAIMANT_FIRST_NAME": "Gloria",
		 "CLAIM_NO": "2015-11-02-0226-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "10-26-2015 ",
		 "DATE_CLAIM_CLOSED": "12-29-2015 ",
		 "ACTUAL_RTW_DATE": "11-06-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Guzman",
		 "CLAIMANT_FIRST_NAME": "Gloria",
		 "CLAIM_NO": "2020-06-19-0671-VP-01",
		 "DURATION": 550,
		 "DISABILITY_START_DATE": "06-22-2020 ",
		 "DATE_CLAIM_CLOSED": "06-10-2022 ",
		 "ACTUAL_RTW_DATE": "12-24-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Guzman",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2013-07-24-0248-VP-01",
		 "DURATION": 61,
		 "DISABILITY_START_DATE": "07-24-2013 ",
		 "DATE_CLAIM_CLOSED": "10-07-2013 ",
		 "ACTUAL_RTW_DATE": "09-23-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Guzman Gutierrez",
		 "CLAIMANT_FIRST_NAME": "Miguel",
		 "CLAIM_NO": "2013-01-05-2030-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "05-07-2012 ",
		 "DATE_CLAIM_CLOSED": "06-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ha",
		 "CLAIMANT_FIRST_NAME": "Diem",
		 "CLAIM_NO": "2022-04-25-0237-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "04-18-2022 ",
		 "DATE_CLAIM_CLOSED": "05-23-2022 ",
		 "ACTUAL_RTW_DATE": "05-09-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ha",
		 "CLAIMANT_FIRST_NAME": "Catherine",
		 "CLAIM_NO": "2014-11-11-0448-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "12-11-2014 ",
		 "DATE_CLAIM_CLOSED": "02-13-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hackett",
		 "CLAIMANT_FIRST_NAME": "Edward",
		 "CLAIM_NO": "2017-02-03-0468-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "02-08-2017 ",
		 "DATE_CLAIM_CLOSED": "04-18-2017 ",
		 "ACTUAL_RTW_DATE": "02-27-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hafner",
		 "CLAIMANT_FIRST_NAME": "Margie",
		 "CLAIM_NO": "2014-12-01-0620-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "11-26-2014 ",
		 "DATE_CLAIM_CLOSED": "01-27-2015 ",
		 "ACTUAL_RTW_DATE": "12-22-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hafner",
		 "CLAIMANT_FIRST_NAME": "Margie",
		 "CLAIM_NO": "2015-04-14-0527-VP-01",
		 "DURATION": 408,
		 "DISABILITY_START_DATE": "04-13-2015 ",
		 "DATE_CLAIM_CLOSED": "04-18-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hafner",
		 "CLAIMANT_FIRST_NAME": "Margie",
		 "CLAIM_NO": "2014-03-24-0271-VP-01",
		 "DURATION": 126,
		 "DISABILITY_START_DATE": "03-24-2014 ",
		 "DATE_CLAIM_CLOSED": "08-08-2014 ",
		 "ACTUAL_RTW_DATE": "07-28-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hahn",
		 "CLAIMANT_FIRST_NAME": "Aiphi",
		 "CLAIM_NO": "2022-02-01-0677-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "02-02-2022 ",
		 "DATE_CLAIM_CLOSED": "03-17-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Halilovic",
		 "CLAIMANT_FIRST_NAME": "Jasmina",
		 "CLAIM_NO": "2021-03-29-1265-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "03-29-2021 ",
		 "DATE_CLAIM_CLOSED": "05-24-2021 ",
		 "ACTUAL_RTW_DATE": "04-12-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hall",
		 "CLAIMANT_FIRST_NAME": "Louise",
		 "CLAIM_NO": "2015-01-29-0459-VP-01",
		 "DURATION": 120,
		 "DISABILITY_START_DATE": "02-02-2015 ",
		 "DATE_CLAIM_CLOSED": "06-03-2015 ",
		 "ACTUAL_RTW_DATE": "06-02-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hall",
		 "CLAIMANT_FIRST_NAME": "Sarah",
		 "CLAIM_NO": "2016-12-07-0625-VP-01",
		 "DURATION": 65,
		 "DISABILITY_START_DATE": "01-11-2017 ",
		 "DATE_CLAIM_CLOSED": "04-17-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hall",
		 "CLAIMANT_FIRST_NAME": "Sarah",
		 "CLAIM_NO": "2015-01-13-0413-VP-01",
		 "DURATION": 65,
		 "DISABILITY_START_DATE": "01-29-2015 ",
		 "DATE_CLAIM_CLOSED": "07-06-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hall",
		 "CLAIMANT_FIRST_NAME": "Troy",
		 "CLAIM_NO": "2014-01-27-0179-VP-01",
		 "DURATION": 0,
		 "DISABILITY_START_DATE": "01-21-2014 ",
		 "DATE_CLAIM_CLOSED": "02-07-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ham",
		 "CLAIMANT_FIRST_NAME": "Judy",
		 "CLAIM_NO": "2013-06-11-0184-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "07-12-2013 ",
		 "DATE_CLAIM_CLOSED": "08-28-2013 ",
		 "ACTUAL_RTW_DATE": "08-26-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hamada",
		 "CLAIMANT_FIRST_NAME": "Mark",
		 "CLAIM_NO": "2018-02-09-0634-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "02-09-2018 ",
		 "DATE_CLAIM_CLOSED": "03-29-2018 ",
		 "ACTUAL_RTW_DATE": "03-12-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hammond",
		 "CLAIMANT_FIRST_NAME": "Bruce",
		 "CLAIM_NO": "2018-03-12-0396-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "03-20-2018 ",
		 "DATE_CLAIM_CLOSED": "05-22-2018 ",
		 "ACTUAL_RTW_DATE": "05-18-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hampton",
		 "CLAIMANT_FIRST_NAME": "Amy",
		 "CLAIM_NO": "2013-10-23-0300-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "11-22-2013 ",
		 "DATE_CLAIM_CLOSED": "03-21-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Han",
		 "CLAIMANT_FIRST_NAME": "Lixian",
		 "CLAIM_NO": "2018-08-21-0216-VP-01",
		 "DURATION": 383,
		 "DISABILITY_START_DATE": "08-15-2018 ",
		 "DATE_CLAIM_CLOSED": "09-17-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hanna",
		 "CLAIMANT_FIRST_NAME": "Sylvia",
		 "CLAIM_NO": "2021-03-19-0668-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "04-26-2021 ",
		 "DATE_CLAIM_CLOSED": "07-09-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hansen",
		 "CLAIMANT_FIRST_NAME": "Michael",
		 "CLAIM_NO": "2013-07-10-0509-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "08-06-2013 ",
		 "DATE_CLAIM_CLOSED": "08-07-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hansen",
		 "CLAIMANT_FIRST_NAME": "Michael",
		 "CLAIM_NO": "2013-08-22-0059-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "09-17-2013 ",
		 "DATE_CLAIM_CLOSED": "10-07-2013 ",
		 "ACTUAL_RTW_DATE": "10-02-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Happ",
		 "CLAIMANT_FIRST_NAME": "Alan",
		 "CLAIM_NO": "2013-08-21-0137-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "08-22-2013 ",
		 "DATE_CLAIM_CLOSED": "11-18-2013 ",
		 "ACTUAL_RTW_DATE": "10-21-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Happ",
		 "CLAIMANT_FIRST_NAME": "Alan",
		 "CLAIM_NO": "2014-01-08-0219-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "01-08-2014 ",
		 "DATE_CLAIM_CLOSED": "01-12-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Harding",
		 "CLAIMANT_FIRST_NAME": "Kenneth",
		 "CLAIM_NO": "2019-06-13-0350-VP-01",
		 "DURATION": 232,
		 "DISABILITY_START_DATE": "06-12-2019 ",
		 "DATE_CLAIM_CLOSED": "12-23-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Harding",
		 "CLAIMANT_FIRST_NAME": "Kenneth",
		 "CLAIM_NO": "2013-05-03-0203-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "04-29-2013 ",
		 "DATE_CLAIM_CLOSED": "07-03-2013 ",
		 "ACTUAL_RTW_DATE": "05-16-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Harding",
		 "CLAIMANT_FIRST_NAME": "Kenneth",
		 "CLAIM_NO": "2013-08-19-0269-VP-01",
		 "DURATION": 119,
		 "DISABILITY_START_DATE": "08-22-2013 ",
		 "DATE_CLAIM_CLOSED": "01-07-2014 ",
		 "ACTUAL_RTW_DATE": "12-19-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Harding",
		 "CLAIMANT_FIRST_NAME": "Eva",
		 "CLAIM_NO": "2013-01-05-0074-VP-01",
		 "DURATION": 94,
		 "DISABILITY_START_DATE": "01-04-2013 ",
		 "DATE_CLAIM_CLOSED": "04-08-2013 ",
		 "ACTUAL_RTW_DATE": "04-08-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hardwick",
		 "CLAIMANT_FIRST_NAME": "Rebecca",
		 "CLAIM_NO": "2019-05-14-0530-VP-01",
		 "DURATION": 87,
		 "DISABILITY_START_DATE": "05-30-2019 ",
		 "DATE_CLAIM_CLOSED": "09-06-2019 ",
		 "ACTUAL_RTW_DATE": "08-25-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Harmon",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2019-03-27-0289-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "03-13-2019 ",
		 "DATE_CLAIM_CLOSED": "05-02-2019 ",
		 "ACTUAL_RTW_DATE": "05-01-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Harper",
		 "CLAIMANT_FIRST_NAME": "Anise",
		 "CLAIM_NO": "2020-01-07-1313-VP-01",
		 "DURATION": 118,
		 "DISABILITY_START_DATE": "01-21-2020 ",
		 "DATE_CLAIM_CLOSED": "06-12-2020 ",
		 "ACTUAL_RTW_DATE": "05-18-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Harper",
		 "CLAIMANT_FIRST_NAME": "Anise",
		 "CLAIM_NO": "2014-09-19-0494-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "09-12-2014 ",
		 "DATE_CLAIM_CLOSED": "10-29-2014 ",
		 "ACTUAL_RTW_DATE": "10-06-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Harper",
		 "CLAIMANT_FIRST_NAME": "Anise",
		 "CLAIM_NO": "2021-08-24-0643-VP-01",
		 "DURATION": 161,
		 "DISABILITY_START_DATE": "08-24-2021 ",
		 "DATE_CLAIM_CLOSED": "02-09-2022 ",
		 "ACTUAL_RTW_DATE": "02-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Harper",
		 "CLAIMANT_FIRST_NAME": "Anise",
		 "CLAIM_NO": "2022-12-19-1107-VP-01",
		 "DURATION": 89,
		 "DISABILITY_START_DATE": "01-04-2023 ",
		 "DATE_CLAIM_CLOSED": "04-10-2023 ",
		 "ACTUAL_RTW_DATE": "04-03-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Harrington",
		 "CLAIMANT_FIRST_NAME": "Joel",
		 "CLAIM_NO": "2013-04-16-0106-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "03-27-2013 ",
		 "DATE_CLAIM_CLOSED": "05-20-2013 ",
		 "ACTUAL_RTW_DATE": "04-22-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Harrington",
		 "CLAIMANT_FIRST_NAME": "Melinda",
		 "CLAIM_NO": "2013-05-06-0155-VP-01",
		 "DURATION": 83,
		 "DISABILITY_START_DATE": "06-07-2013 ",
		 "DATE_CLAIM_CLOSED": "08-29-2013 ",
		 "ACTUAL_RTW_DATE": "08-29-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Harris",
		 "CLAIMANT_FIRST_NAME": "Karl",
		 "CLAIM_NO": "2019-09-18-0316-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "09-09-2019 ",
		 "DATE_CLAIM_CLOSED": "09-26-2019 ",
		 "ACTUAL_RTW_DATE": "09-23-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Harris",
		 "CLAIMANT_FIRST_NAME": "Ramonda",
		 "CLAIM_NO": "2020-08-24-0478-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "08-24-2020 ",
		 "DATE_CLAIM_CLOSED": "10-20-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Harrison",
		 "CLAIMANT_FIRST_NAME": "Anna",
		 "CLAIM_NO": "2020-06-19-0510-VP-01",
		 "DURATION": 97,
		 "DISABILITY_START_DATE": "06-11-2020 ",
		 "DATE_CLAIM_CLOSED": "09-22-2020 ",
		 "ACTUAL_RTW_DATE": "09-16-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Harrison",
		 "CLAIMANT_FIRST_NAME": "Anna",
		 "CLAIM_NO": "2020-04-03-0629-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "03-30-2020 ",
		 "DATE_CLAIM_CLOSED": "04-06-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Harrison",
		 "CLAIMANT_FIRST_NAME": "Anna",
		 "CLAIM_NO": "2020-04-23-0374-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "04-13-2020 ",
		 "DATE_CLAIM_CLOSED": "06-08-2020 ",
		 "ACTUAL_RTW_DATE": "05-26-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Harrison",
		 "CLAIMANT_FIRST_NAME": "Melissa",
		 "CLAIM_NO": "2022-06-18-0022-VP-01",
		 "DURATION": 171,
		 "DISABILITY_START_DATE": "08-05-2022 ",
		 "DATE_CLAIM_CLOSED": "02-28-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Harry",
		 "CLAIMANT_FIRST_NAME": "Michael",
		 "CLAIM_NO": "2021-01-04-0369-VP-01",
		 "DURATION": 177,
		 "DISABILITY_START_DATE": "01-06-2021 ",
		 "DATE_CLAIM_CLOSED": "07-06-2021 ",
		 "ACTUAL_RTW_DATE": "07-02-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hasan",
		 "CLAIMANT_FIRST_NAME": "Myra",
		 "CLAIM_NO": "2022-09-16-0747-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "10-05-2022 ",
		 "DATE_CLAIM_CLOSED": "12-30-2022 ",
		 "ACTUAL_RTW_DATE": "12-14-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Haskins",
		 "CLAIMANT_FIRST_NAME": "Charles",
		 "CLAIM_NO": "2018-01-11-0776-VP-01",
		 "DURATION": 44,
		 "DISABILITY_START_DATE": "02-07-2018 ",
		 "DATE_CLAIM_CLOSED": "04-30-2018 ",
		 "ACTUAL_RTW_DATE": "03-23-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hassan",
		 "CLAIMANT_FIRST_NAME": "Said",
		 "CLAIM_NO": "2023-01-05-0873-VP-01",
		 "DURATION": 74,
		 "DISABILITY_START_DATE": "12-22-2022 ",
		 "DATE_CLAIM_CLOSED": "04-03-2023 ",
		 "ACTUAL_RTW_DATE": "03-06-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hasson",
		 "CLAIMANT_FIRST_NAME": "Hadeel",
		 "CLAIM_NO": "2014-08-11-0522-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "08-26-2014 ",
		 "DATE_CLAIM_CLOSED": "11-13-2014 ",
		 "ACTUAL_RTW_DATE": "10-27-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hastings",
		 "CLAIMANT_FIRST_NAME": "Kim",
		 "CLAIM_NO": "2014-01-06-0256-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "12-24-2013 ",
		 "DATE_CLAIM_CLOSED": "05-06-2014 ",
		 "ACTUAL_RTW_DATE": "01-27-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hata",
		 "CLAIMANT_FIRST_NAME": "Lisa",
		 "CLAIM_NO": "2016-07-25-0008-VP-01",
		 "DURATION": 85,
		 "DISABILITY_START_DATE": "08-15-2016 ",
		 "DATE_CLAIM_CLOSED": "11-14-2016 ",
		 "ACTUAL_RTW_DATE": "11-08-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hatcher",
		 "CLAIMANT_FIRST_NAME": "Jasa",
		 "CLAIM_NO": "2014-08-04-0560-VP-01",
		 "DURATION": 118,
		 "DISABILITY_START_DATE": "08-05-2014 ",
		 "DATE_CLAIM_CLOSED": "12-03-2014 ",
		 "ACTUAL_RTW_DATE": "12-01-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hatcher",
		 "CLAIMANT_FIRST_NAME": "Jasa",
		 "CLAIM_NO": "2013-02-11-0505-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "02-07-2013 ",
		 "DATE_CLAIM_CLOSED": "07-23-2013 ",
		 "ACTUAL_RTW_DATE": "03-25-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hauck",
		 "CLAIMANT_FIRST_NAME": "Gregory",
		 "CLAIM_NO": "2019-03-20-0694-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "03-25-2019 ",
		 "DATE_CLAIM_CLOSED": "04-23-2019 ",
		 "ACTUAL_RTW_DATE": "04-22-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Haugo",
		 "CLAIMANT_FIRST_NAME": "Matthew",
		 "CLAIM_NO": "2016-08-19-0478-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "08-23-2016 ",
		 "DATE_CLAIM_CLOSED": "09-13-2016 ",
		 "ACTUAL_RTW_DATE": "09-06-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hayenga",
		 "CLAIMANT_FIRST_NAME": "Kim",
		 "CLAIM_NO": "2019-12-19-0317-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "02-03-2020 ",
		 "DATE_CLAIM_CLOSED": "04-08-2020 ",
		 "ACTUAL_RTW_DATE": "03-31-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hayenga",
		 "CLAIMANT_FIRST_NAME": "Kim",
		 "CLAIM_NO": "2022-01-06-0790-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "02-21-2022 ",
		 "DATE_CLAIM_CLOSED": "04-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "He",
		 "CLAIMANT_FIRST_NAME": "Aihua",
		 "CLAIM_NO": "2014-02-03-0525-VP-01",
		 "DURATION": 86,
		 "DISABILITY_START_DATE": "02-06-2014 ",
		 "DATE_CLAIM_CLOSED": "05-16-2014 ",
		 "ACTUAL_RTW_DATE": "05-03-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Headen",
		 "CLAIMANT_FIRST_NAME": "Brian-Kalani",
		 "CLAIM_NO": "2022-12-13-0894-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "12-13-2022 ",
		 "DATE_CLAIM_CLOSED": "01-04-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Headley",
		 "CLAIMANT_FIRST_NAME": "Jenifer",
		 "CLAIM_NO": "2015-07-20-0262-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "07-20-2015 ",
		 "DATE_CLAIM_CLOSED": "09-23-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hedgeman",
		 "CLAIMANT_FIRST_NAME": "Patrice",
		 "CLAIM_NO": "2013-11-20-0075-VP-01",
		 "DURATION": 107,
		 "DISABILITY_START_DATE": "11-19-2013 ",
		 "DATE_CLAIM_CLOSED": "08-11-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hedman",
		 "CLAIMANT_FIRST_NAME": "Phyllis",
		 "CLAIM_NO": "2018-12-18-0364-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "01-18-2019 ",
		 "DATE_CLAIM_CLOSED": "03-05-2019 ",
		 "ACTUAL_RTW_DATE": "03-04-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Heer",
		 "CLAIMANT_FIRST_NAME": "Raveena",
		 "CLAIM_NO": "2022-12-19-0999-VP-01",
		 "DURATION": 146,
		 "DISABILITY_START_DATE": "01-17-2023 ",
		 "DATE_CLAIM_CLOSED": "03-30-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Heflin",
		 "CLAIMANT_FIRST_NAME": "Herminia",
		 "CLAIM_NO": "2017-08-29-0578-VP-01",
		 "DURATION": 139,
		 "DISABILITY_START_DATE": "09-12-2017 ",
		 "DATE_CLAIM_CLOSED": "02-12-2018 ",
		 "ACTUAL_RTW_DATE": "01-29-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Heinrich",
		 "CLAIMANT_FIRST_NAME": "Kevin",
		 "CLAIM_NO": "2014-06-23-0400-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "06-23-2014 ",
		 "DATE_CLAIM_CLOSED": "12-05-2014 ",
		 "ACTUAL_RTW_DATE": "07-14-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Henderson",
		 "CLAIMANT_FIRST_NAME": "Rachel",
		 "CLAIM_NO": "2013-01-05-2709-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "03-26-2012 ",
		 "DATE_CLAIM_CLOSED": "01-15-2014 ",
		 "ACTUAL_RTW_DATE": "06-04-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Henderson",
		 "CLAIMANT_FIRST_NAME": "John",
		 "CLAIM_NO": "2016-01-19-0221-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "01-09-2016 ",
		 "DATE_CLAIM_CLOSED": "04-07-2016 ",
		 "ACTUAL_RTW_DATE": "01-25-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hendricks",
		 "CLAIMANT_FIRST_NAME": "James",
		 "CLAIM_NO": "2014-03-31-0591-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "04-14-2014 ",
		 "DATE_CLAIM_CLOSED": "09-23-2014 ",
		 "ACTUAL_RTW_DATE": "05-05-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Henke",
		 "CLAIMANT_FIRST_NAME": "George",
		 "CLAIM_NO": "2016-11-07-0084-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "12-09-2016 ",
		 "DATE_CLAIM_CLOSED": "12-28-2016 ",
		 "ACTUAL_RTW_DATE": "12-26-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Henke",
		 "CLAIMANT_FIRST_NAME": "George",
		 "CLAIM_NO": "2018-03-09-0517-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "03-05-2018 ",
		 "DATE_CLAIM_CLOSED": "01-10-2019 ",
		 "ACTUAL_RTW_DATE": "03-20-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Henke",
		 "CLAIMANT_FIRST_NAME": "George",
		 "CLAIM_NO": "2020-01-08-0009-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "01-06-2020 ",
		 "DATE_CLAIM_CLOSED": "03-06-2020 ",
		 "ACTUAL_RTW_DATE": "03-05-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Henley",
		 "CLAIMANT_FIRST_NAME": "Carolyn",
		 "CLAIM_NO": "2017-07-17-0081-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "07-14-2017 ",
		 "DATE_CLAIM_CLOSED": "08-07-2017 ",
		 "ACTUAL_RTW_DATE": "08-02-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Henley",
		 "CLAIMANT_FIRST_NAME": "Carolyn",
		 "CLAIM_NO": "2020-06-09-0569-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "06-08-2020 ",
		 "DATE_CLAIM_CLOSED": "06-11-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Henley",
		 "CLAIMANT_FIRST_NAME": "Carolyn",
		 "CLAIM_NO": "2022-05-31-0393-VP-01",
		 "DURATION": 30,
		 "DISABILITY_START_DATE": "07-13-2022 ",
		 "DATE_CLAIM_CLOSED": "08-31-2022 ",
		 "ACTUAL_RTW_DATE": "08-12-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Henley",
		 "CLAIMANT_FIRST_NAME": "Carolyn",
		 "CLAIM_NO": "2019-09-26-0001-VP-01",
		 "DURATION": 168,
		 "DISABILITY_START_DATE": "11-15-2019 ",
		 "DATE_CLAIM_CLOSED": "05-07-2020 ",
		 "ACTUAL_RTW_DATE": "05-01-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Henson",
		 "CLAIMANT_FIRST_NAME": "Christopher",
		 "CLAIM_NO": "2014-09-19-0448-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "09-16-2014 ",
		 "DATE_CLAIM_CLOSED": "12-08-2014 ",
		 "ACTUAL_RTW_DATE": "09-29-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Henson",
		 "CLAIMANT_FIRST_NAME": "Christopher",
		 "CLAIM_NO": "2014-02-10-0104-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "02-03-2014 ",
		 "DATE_CLAIM_CLOSED": "04-09-2014 ",
		 "ACTUAL_RTW_DATE": "03-24-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Leticia",
		 "CLAIM_NO": "2016-11-08-0616-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "11-28-2016 ",
		 "DATE_CLAIM_CLOSED": "01-10-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2021-06-21-0733-VP-01",
		 "DURATION": 72,
		 "DISABILITY_START_DATE": "07-12-2021 ",
		 "DATE_CLAIM_CLOSED": "09-29-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2013-09-20-0036-VP-01",
		 "DURATION": 116,
		 "DISABILITY_START_DATE": "09-27-2013 ",
		 "DATE_CLAIM_CLOSED": "03-05-2014 ",
		 "ACTUAL_RTW_DATE": "01-21-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Edith",
		 "CLAIM_NO": "2015-02-27-0600-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "03-27-2015 ",
		 "DATE_CLAIM_CLOSED": "06-10-2015 ",
		 "ACTUAL_RTW_DATE": "05-26-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Felicia",
		 "CLAIM_NO": "2022-01-10-0168-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "02-15-2022 ",
		 "DATE_CLAIM_CLOSED": "04-06-2022 ",
		 "ACTUAL_RTW_DATE": "04-04-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Diosenia",
		 "CLAIM_NO": "2021-06-30-0779-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "06-28-2021 ",
		 "DATE_CLAIM_CLOSED": "07-30-2021 ",
		 "ACTUAL_RTW_DATE": "07-15-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2019-06-17-0815-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "07-15-2019 ",
		 "DATE_CLAIM_CLOSED": "09-23-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Diosenia",
		 "CLAIM_NO": "2022-01-31-1267-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "01-28-2022 ",
		 "DATE_CLAIM_CLOSED": "02-14-2022 ",
		 "ACTUAL_RTW_DATE": "02-14-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Samantha",
		 "CLAIM_NO": "2018-08-24-0203-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "08-21-2018 ",
		 "DATE_CLAIM_CLOSED": "11-27-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Alejandrina",
		 "CLAIM_NO": "2020-06-24-0764-VP-01",
		 "DURATION": 93,
		 "DISABILITY_START_DATE": "07-07-2020 ",
		 "DATE_CLAIM_CLOSED": "10-12-2020 ",
		 "ACTUAL_RTW_DATE": "10-08-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Guadalupe",
		 "CLAIM_NO": "2021-01-05-0683-VP-01",
		 "DURATION": 38,
		 "DISABILITY_START_DATE": "02-05-2021 ",
		 "DATE_CLAIM_CLOSED": "03-15-2021 ",
		 "ACTUAL_RTW_DATE": "03-15-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Joeanna",
		 "CLAIM_NO": "2014-07-07-0351-VP-01",
		 "DURATION": 121,
		 "DISABILITY_START_DATE": "07-03-2014 ",
		 "DATE_CLAIM_CLOSED": "02-05-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Diosenia",
		 "CLAIM_NO": "2022-12-22-0931-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "12-20-2022 ",
		 "DATE_CLAIM_CLOSED": "01-26-2023 ",
		 "ACTUAL_RTW_DATE": "12-29-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2016-09-06-0510-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "09-16-2016 ",
		 "DATE_CLAIM_CLOSED": "11-30-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Soraya",
		 "CLAIM_NO": "2022-04-18-0986-VP-01",
		 "DURATION": 87,
		 "DISABILITY_START_DATE": "04-18-2022 ",
		 "DATE_CLAIM_CLOSED": "07-15-2022 ",
		 "ACTUAL_RTW_DATE": "07-14-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Silvia",
		 "CLAIM_NO": "2014-02-19-0282-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "02-28-2014 ",
		 "DATE_CLAIM_CLOSED": "04-30-2014 ",
		 "ACTUAL_RTW_DATE": "03-24-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Esther",
		 "CLAIM_NO": "2022-07-01-0443-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "07-14-2022 ",
		 "DATE_CLAIM_CLOSED": "08-18-2022 ",
		 "ACTUAL_RTW_DATE": "08-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Samantha",
		 "CLAIM_NO": "2015-08-21-0340-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "08-26-2015 ",
		 "DATE_CLAIM_CLOSED": "11-02-2015 ",
		 "ACTUAL_RTW_DATE": "10-29-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Diosenia",
		 "CLAIM_NO": "2020-08-04-0531-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "08-04-2020 ",
		 "DATE_CLAIM_CLOSED": "08-17-2020 ",
		 "ACTUAL_RTW_DATE": "08-12-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Joeanna",
		 "CLAIM_NO": "2013-01-05-0311-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "11-16-2012 ",
		 "DATE_CLAIM_CLOSED": "02-21-2013 ",
		 "ACTUAL_RTW_DATE": "11-26-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Diosenia",
		 "CLAIM_NO": "2023-01-05-0917-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "01-03-2023 ",
		 "DATE_CLAIM_CLOSED": "01-23-2023 ",
		 "ACTUAL_RTW_DATE": "01-11-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Gladys",
		 "CLAIM_NO": "2019-01-29-0319-VP-01",
		 "DURATION": 326,
		 "DISABILITY_START_DATE": "01-28-2019 ",
		 "DATE_CLAIM_CLOSED": "02-03-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2015-09-18-0167-VP-01",
		 "DURATION": 1363,
		 "DISABILITY_START_DATE": "09-17-2015 ",
		 "DATE_CLAIM_CLOSED": "07-29-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Ana",
		 "CLAIM_NO": "2013-01-05-0401-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "11-06-2012 ",
		 "DATE_CLAIM_CLOSED": "02-11-2013 ",
		 "ACTUAL_RTW_DATE": "11-14-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2015-05-01-0431-VP-01",
		 "DURATION": 23,
		 "DISABILITY_START_DATE": "04-20-2015 ",
		 "DATE_CLAIM_CLOSED": "06-26-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hernandez",
		 "CLAIMANT_FIRST_NAME": "Irma",
		 "CLAIM_NO": "2021-02-09-0681-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "02-08-2021 ",
		 "DATE_CLAIM_CLOSED": "05-12-2021 ",
		 "ACTUAL_RTW_DATE": "05-10-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Herrera",
		 "CLAIMANT_FIRST_NAME": "Letisia",
		 "CLAIM_NO": "2013-07-31-0180-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "08-08-2013 ",
		 "DATE_CLAIM_CLOSED": "10-07-2013 ",
		 "ACTUAL_RTW_DATE": "08-15-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hess",
		 "CLAIMANT_FIRST_NAME": "Kim",
		 "CLAIM_NO": "2020-03-04-0422-VP-01",
		 "DURATION": 95,
		 "DISABILITY_START_DATE": "04-02-2020 ",
		 "DATE_CLAIM_CLOSED": "03-25-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hess",
		 "CLAIMANT_FIRST_NAME": "Kim",
		 "CLAIM_NO": "2020-05-27-0152-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "06-25-2020 ",
		 "DATE_CLAIM_CLOSED": "09-28-2020 ",
		 "ACTUAL_RTW_DATE": "09-17-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Heth",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2019-02-12-0447-VP-01",
		 "DURATION": 105,
		 "DISABILITY_START_DATE": "03-04-2019 ",
		 "DATE_CLAIM_CLOSED": "04-15-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Heth",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2019-04-04-0043-STD-01",
		 "DISABILITY_START_DATE": "03-10-2019 ",
		 "DATE_CLAIM_CLOSED": "04-04-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hiatt-Lough",
		 "CLAIMANT_FIRST_NAME": "J.T.",
		 "CLAIM_NO": "2013-01-16-0142-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "12-26-2012 ",
		 "DATE_CLAIM_CLOSED": "03-11-2013 ",
		 "ACTUAL_RTW_DATE": "03-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hiatt-Lough",
		 "CLAIMANT_FIRST_NAME": "J.T.",
		 "CLAIM_NO": "2014-03-05-0552-VP-01",
		 "DURATION": 94,
		 "DISABILITY_START_DATE": "03-17-2014 ",
		 "DATE_CLAIM_CLOSED": "07-07-2014 ",
		 "ACTUAL_RTW_DATE": "06-19-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hiatt-Lough",
		 "CLAIMANT_FIRST_NAME": "J.T.",
		 "CLAIM_NO": "2014-11-07-0127-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "10-13-2014 ",
		 "DATE_CLAIM_CLOSED": "01-27-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hill",
		 "CLAIMANT_FIRST_NAME": "Victoria",
		 "CLAIM_NO": "2022-06-06-1196-VP-01",
		 "DURATION": 163,
		 "DISABILITY_START_DATE": "06-21-2022 ",
		 "DATE_CLAIM_CLOSED": "08-02-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hill",
		 "CLAIMANT_FIRST_NAME": "Damian",
		 "CLAIM_NO": "2015-08-11-0336-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "09-01-2015 ",
		 "DATE_CLAIM_CLOSED": "10-27-2015 ",
		 "ACTUAL_RTW_DATE": "10-14-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hill",
		 "CLAIMANT_FIRST_NAME": "Victoria",
		 "CLAIM_NO": "2019-02-11-0757-VP-01",
		 "DURATION": 159,
		 "DISABILITY_START_DATE": "02-27-2019 ",
		 "DATE_CLAIM_CLOSED": "08-07-2019 ",
		 "ACTUAL_RTW_DATE": "08-05-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hilzman",
		 "CLAIMANT_FIRST_NAME": "Irma",
		 "CLAIM_NO": "2017-02-28-0124-VP-01",
		 "DURATION": 166,
		 "DISABILITY_START_DATE": "02-22-2017 ",
		 "DATE_CLAIM_CLOSED": "08-08-2017 ",
		 "ACTUAL_RTW_DATE": "08-07-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hima",
		 "CLAIMANT_FIRST_NAME": "Satya",
		 "CLAIM_NO": "2020-08-11-0273-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "08-11-2020 ",
		 "DATE_CLAIM_CLOSED": "11-09-2020 ",
		 "ACTUAL_RTW_DATE": "09-08-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hima",
		 "CLAIMANT_FIRST_NAME": "Satya",
		 "CLAIM_NO": "2021-01-25-0948-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "01-21-2021 ",
		 "DATE_CLAIM_CLOSED": "03-03-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hindmarch",
		 "CLAIMANT_FIRST_NAME": "Samantha",
		 "CLAIM_NO": "2020-02-27-0007-VP-01",
		 "DURATION": 25,
		 "DISABILITY_START_DATE": "02-27-2020 ",
		 "DATE_CLAIM_CLOSED": "05-28-2020 ",
		 "ACTUAL_RTW_DATE": "03-23-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hindmarch",
		 "CLAIMANT_FIRST_NAME": "Samantha",
		 "CLAIM_NO": "2014-07-15-0485-VP-01",
		 "DURATION": 178,
		 "DISABILITY_START_DATE": "07-21-2014 ",
		 "DATE_CLAIM_CLOSED": "06-18-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hines",
		 "CLAIMANT_FIRST_NAME": "Lionel",
		 "CLAIM_NO": "2017-05-08-0705-VP-01",
		 "DURATION": 69,
		 "DISABILITY_START_DATE": "05-11-2017 ",
		 "DATE_CLAIM_CLOSED": "07-31-2017 ",
		 "ACTUAL_RTW_DATE": "07-19-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hintz",
		 "CLAIMANT_FIRST_NAME": "Suzanne",
		 "CLAIM_NO": "2019-04-10-0333-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "04-15-2019 ",
		 "DATE_CLAIM_CLOSED": "06-10-2019 ",
		 "ACTUAL_RTW_DATE": "05-28-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hintz",
		 "CLAIMANT_FIRST_NAME": "Suzanne",
		 "CLAIM_NO": "2020-11-18-0744-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "12-02-2020 ",
		 "DATE_CLAIM_CLOSED": "01-06-2021 ",
		 "ACTUAL_RTW_DATE": "01-04-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ho",
		 "CLAIMANT_FIRST_NAME": "Juyao",
		 "CLAIM_NO": "2018-07-09-0733-VP-01",
		 "DURATION": 93,
		 "DISABILITY_START_DATE": "07-09-2018 ",
		 "DATE_CLAIM_CLOSED": "10-15-2018 ",
		 "ACTUAL_RTW_DATE": "10-10-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hoang",
		 "CLAIMANT_FIRST_NAME": "Thuan",
		 "CLAIM_NO": "2021-06-17-0877-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "06-16-2021 ",
		 "DATE_CLAIM_CLOSED": "07-07-2021 ",
		 "ACTUAL_RTW_DATE": "07-06-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hoang",
		 "CLAIMANT_FIRST_NAME": "Thuan",
		 "CLAIM_NO": "2022-06-23-0729-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "06-23-2022 ",
		 "DATE_CLAIM_CLOSED": "09-15-2022 ",
		 "ACTUAL_RTW_DATE": "08-09-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hoang",
		 "CLAIMANT_FIRST_NAME": "Duc",
		 "CLAIM_NO": "2020-01-23-0578-VP-01",
		 "DURATION": 152,
		 "DISABILITY_START_DATE": "02-06-2020 ",
		 "DATE_CLAIM_CLOSED": "07-08-2020 ",
		 "ACTUAL_RTW_DATE": "07-07-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hoang",
		 "CLAIMANT_FIRST_NAME": "Thuan",
		 "CLAIM_NO": "2013-03-28-0348-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "04-08-2013 ",
		 "DATE_CLAIM_CLOSED": "06-10-2013 ",
		 "ACTUAL_RTW_DATE": "04-29-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hoang",
		 "CLAIMANT_FIRST_NAME": "Thuan",
		 "CLAIM_NO": "2020-02-10-0659-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "02-03-2020 ",
		 "DATE_CLAIM_CLOSED": "04-28-2020 ",
		 "ACTUAL_RTW_DATE": "02-19-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hoang",
		 "CLAIMANT_FIRST_NAME": "Thuan",
		 "CLAIM_NO": "2022-02-09-1001-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "02-07-2022 ",
		 "DATE_CLAIM_CLOSED": "03-02-2022 ",
		 "ACTUAL_RTW_DATE": "02-18-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hoang",
		 "CLAIMANT_FIRST_NAME": "Thuan",
		 "CLAIM_NO": "2021-01-05-0650-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "01-05-2021 ",
		 "DATE_CLAIM_CLOSED": "01-27-2021 ",
		 "ACTUAL_RTW_DATE": "01-22-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hofer",
		 "CLAIMANT_FIRST_NAME": "Steven",
		 "CLAIM_NO": "2013-10-16-0391-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "10-08-2013 ",
		 "DATE_CLAIM_CLOSED": "02-04-2014 ",
		 "ACTUAL_RTW_DATE": "10-28-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hofer",
		 "CLAIMANT_FIRST_NAME": "Steven",
		 "CLAIM_NO": "2014-02-05-0179-VP-01",
		 "DURATION": 61,
		 "DISABILITY_START_DATE": "02-19-2014 ",
		 "DATE_CLAIM_CLOSED": "04-30-2014 ",
		 "ACTUAL_RTW_DATE": "04-21-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hofstadter",
		 "CLAIMANT_FIRST_NAME": "Stephen",
		 "CLAIM_NO": "2022-05-03-0899-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "05-03-2022 ",
		 "DATE_CLAIM_CLOSED": "05-04-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Holdsworth",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2022-12-20-1239-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "12-29-2022 ",
		 "DATE_CLAIM_CLOSED": "02-13-2023 ",
		 "ACTUAL_RTW_DATE": "02-10-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hollister",
		 "CLAIMANT_FIRST_NAME": "Anita",
		 "CLAIM_NO": "2013-03-01-0202-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "02-20-2013 ",
		 "DATE_CLAIM_CLOSED": "07-23-2013 ",
		 "ACTUAL_RTW_DATE": "03-06-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Holloway",
		 "CLAIMANT_FIRST_NAME": "Patricia",
		 "CLAIM_NO": "2015-01-21-0554-VP-01",
		 "DURATION": 75,
		 "DISABILITY_START_DATE": "02-16-2015 ",
		 "DATE_CLAIM_CLOSED": "05-08-2015 ",
		 "ACTUAL_RTW_DATE": "05-02-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hom",
		 "CLAIMANT_FIRST_NAME": "Kyle",
		 "CLAIM_NO": "2022-09-07-0398-VP-01",
		 "DURATION": 97,
		 "DISABILITY_START_DATE": "10-28-2022 ",
		 "DATE_CLAIM_CLOSED": "02-09-2023 ",
		 "ACTUAL_RTW_DATE": "02-02-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Homfeld",
		 "CLAIMANT_FIRST_NAME": "Christina",
		 "CLAIM_NO": "2021-11-18-0738-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "11-11-2021 ",
		 "DATE_CLAIM_CLOSED": "01-19-2022 ",
		 "ACTUAL_RTW_DATE": "11-22-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Honey",
		 "CLAIMANT_FIRST_NAME": "Consuelo",
		 "CLAIM_NO": "2013-08-22-0058-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "08-22-2013 ",
		 "DATE_CLAIM_CLOSED": "10-07-2013 ",
		 "ACTUAL_RTW_DATE": "09-06-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hong",
		 "CLAIMANT_FIRST_NAME": "Pei-Chi",
		 "CLAIM_NO": "2015-04-09-0410-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "05-05-2015 ",
		 "DATE_CLAIM_CLOSED": "06-23-2015 ",
		 "ACTUAL_RTW_DATE": "06-17-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hong",
		 "CLAIMANT_FIRST_NAME": "Pei-Chi",
		 "CLAIM_NO": "2018-07-27-0057-VP-01",
		 "DURATION": 97,
		 "DISABILITY_START_DATE": "08-16-2018 ",
		 "DATE_CLAIM_CLOSED": "11-29-2018 ",
		 "ACTUAL_RTW_DATE": "11-21-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hong",
		 "CLAIMANT_FIRST_NAME": "Gordon",
		 "CLAIM_NO": "2022-10-17-0809-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "10-26-2022 ",
		 "DATE_CLAIM_CLOSED": "11-11-2022 ",
		 "ACTUAL_RTW_DATE": "11-07-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hong",
		 "CLAIMANT_FIRST_NAME": "Donald",
		 "CLAIM_NO": "2013-10-25-0167-VP-01",
		 "DURATION": 124,
		 "DISABILITY_START_DATE": "10-30-2013 ",
		 "DATE_CLAIM_CLOSED": "03-17-2014 ",
		 "ACTUAL_RTW_DATE": "03-03-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hosseini",
		 "CLAIMANT_FIRST_NAME": "Manijeh",
		 "CLAIM_NO": "2015-12-17-0443-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "01-04-2016 ",
		 "DATE_CLAIM_CLOSED": "03-29-2016 ",
		 "ACTUAL_RTW_DATE": "03-28-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hosseini",
		 "CLAIMANT_FIRST_NAME": "Manijeh",
		 "CLAIM_NO": "2015-01-08-0463-VP-01",
		 "DURATION": 139,
		 "DISABILITY_START_DATE": "01-13-2015 ",
		 "DATE_CLAIM_CLOSED": "06-04-2015 ",
		 "ACTUAL_RTW_DATE": "06-01-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hou",
		 "CLAIMANT_FIRST_NAME": "Aaron",
		 "CLAIM_NO": "2015-04-03-0169-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "03-25-2015 ",
		 "DATE_CLAIM_CLOSED": "06-16-2015 ",
		 "ACTUAL_RTW_DATE": "05-05-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hourani",
		 "CLAIMANT_FIRST_NAME": "Nina",
		 "CLAIM_NO": "2019-07-01-0423-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "07-19-2019 ",
		 "DATE_CLAIM_CLOSED": "09-06-2019 ",
		 "ACTUAL_RTW_DATE": "09-05-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Houston",
		 "CLAIMANT_FIRST_NAME": "Lakisha",
		 "CLAIM_NO": "2017-02-21-0068-VP-01",
		 "DURATION": 140,
		 "DISABILITY_START_DATE": "04-24-2017 ",
		 "DATE_CLAIM_CLOSED": "10-11-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Houts",
		 "CLAIMANT_FIRST_NAME": "Michael",
		 "CLAIM_NO": "2021-02-05-0469-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "02-02-2021 ",
		 "DATE_CLAIM_CLOSED": "02-12-2021 ",
		 "ACTUAL_RTW_DATE": "02-10-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Houts",
		 "CLAIMANT_FIRST_NAME": "Michael",
		 "CLAIM_NO": "2022-03-29-0364-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "03-24-2022 ",
		 "DATE_CLAIM_CLOSED": "06-14-2022 ",
		 "ACTUAL_RTW_DATE": "06-13-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Howard",
		 "CLAIMANT_FIRST_NAME": "Catherine",
		 "CLAIM_NO": "2013-01-23-0217-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "02-15-2013 ",
		 "DATE_CLAIM_CLOSED": "05-20-2013 ",
		 "ACTUAL_RTW_DATE": "03-29-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Howland",
		 "CLAIMANT_FIRST_NAME": "Marilea",
		 "CLAIM_NO": "2013-04-25-0315-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "05-09-2013 ",
		 "DATE_CLAIM_CLOSED": "07-15-2013 ",
		 "ACTUAL_RTW_DATE": "05-23-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hsieh",
		 "CLAIMANT_FIRST_NAME": "Pei-Shan",
		 "CLAIM_NO": "2022-07-11-1134-VP-01",
		 "DURATION": 154,
		 "DISABILITY_START_DATE": "08-15-2022 ",
		 "DATE_CLAIM_CLOSED": "10-27-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hsieh",
		 "CLAIMANT_FIRST_NAME": "Pei-Shan",
		 "CLAIM_NO": "2019-12-18-0714-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "01-06-2020 ",
		 "DATE_CLAIM_CLOSED": "03-13-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hsin",
		 "CLAIMANT_FIRST_NAME": "Tina",
		 "CLAIM_NO": "2020-02-28-0187-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "02-28-2020 ",
		 "DATE_CLAIM_CLOSED": "04-01-2020 ",
		 "ACTUAL_RTW_DATE": "03-30-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hsu",
		 "CLAIMANT_FIRST_NAME": "Emily",
		 "CLAIM_NO": "2013-01-18-0369-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "01-02-2013 ",
		 "DATE_CLAIM_CLOSED": "05-06-2013 ",
		 "ACTUAL_RTW_DATE": "01-15-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hsu",
		 "CLAIMANT_FIRST_NAME": "Byron",
		 "CLAIM_NO": "2017-04-22-0022-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "04-20-2017 ",
		 "DATE_CLAIM_CLOSED": "06-19-2017 ",
		 "ACTUAL_RTW_DATE": "05-05-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hsueh",
		 "CLAIMANT_FIRST_NAME": "Taishun",
		 "CLAIM_NO": "2015-07-06-0603-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "08-10-2015 ",
		 "DATE_CLAIM_CLOSED": "10-05-2015 ",
		 "ACTUAL_RTW_DATE": "10-03-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hu",
		 "CLAIMANT_FIRST_NAME": "Yinyu",
		 "CLAIM_NO": "2020-03-24-0543-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "04-16-2020 ",
		 "DATE_CLAIM_CLOSED": "05-28-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hu",
		 "CLAIMANT_FIRST_NAME": "Frances",
		 "CLAIM_NO": "2015-11-11-0395-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "12-07-2015 ",
		 "DATE_CLAIM_CLOSED": "01-19-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hu",
		 "CLAIMANT_FIRST_NAME": "Frances",
		 "CLAIM_NO": "2015-11-11-0395-VP-02",
		 "DURATION": 82,
		 "DISABILITY_START_DATE": "12-07-2015 ",
		 "DATE_CLAIM_CLOSED": "04-22-2016 ",
		 "ACTUAL_RTW_DATE": "02-27-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hu",
		 "CLAIMANT_FIRST_NAME": "Frances",
		 "CLAIM_NO": "2020-08-07-0540-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "08-24-2020 ",
		 "DATE_CLAIM_CLOSED": "10-12-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Huang",
		 "CLAIMANT_FIRST_NAME": "Xin",
		 "CLAIM_NO": "2021-11-29-1055-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "11-17-2021 ",
		 "DATE_CLAIM_CLOSED": "12-27-2021 ",
		 "ACTUAL_RTW_DATE": "12-13-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Huang",
		 "CLAIMANT_FIRST_NAME": "Jean",
		 "CLAIM_NO": "2014-02-19-0479-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "03-05-2014 ",
		 "DATE_CLAIM_CLOSED": "06-02-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Huang",
		 "CLAIMANT_FIRST_NAME": "Ling",
		 "CLAIM_NO": "2014-08-20-0321-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "09-10-2014 ",
		 "DATE_CLAIM_CLOSED": "11-04-2014 ",
		 "ACTUAL_RTW_DATE": "10-22-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Huang",
		 "CLAIMANT_FIRST_NAME": "Hsuan",
		 "CLAIM_NO": "2020-03-09-0674-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "04-06-2020 ",
		 "DATE_CLAIM_CLOSED": "06-01-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Huber",
		 "CLAIMANT_FIRST_NAME": "Emilee",
		 "CLAIM_NO": "2018-06-28-0512-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "07-09-2018 ",
		 "DATE_CLAIM_CLOSED": "08-20-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Huber",
		 "CLAIMANT_FIRST_NAME": "Emilee",
		 "CLAIM_NO": "2018-01-11-0573-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "01-10-2018 ",
		 "DATE_CLAIM_CLOSED": "02-20-2018 ",
		 "ACTUAL_RTW_DATE": "01-22-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hudson",
		 "CLAIMANT_FIRST_NAME": "Dana",
		 "CLAIM_NO": "2021-09-30-0755-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "10-01-2021 ",
		 "DATE_CLAIM_CLOSED": "10-28-2021 ",
		 "ACTUAL_RTW_DATE": "10-18-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Huen",
		 "CLAIMANT_FIRST_NAME": "Katherine",
		 "CLAIM_NO": "2022-01-05-1368-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "01-05-2022 ",
		 "DATE_CLAIM_CLOSED": "02-02-2022 ",
		 "ACTUAL_RTW_DATE": "01-31-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Huerta",
		 "CLAIMANT_FIRST_NAME": "Sandra",
		 "CLAIM_NO": "2017-04-13-0468-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "04-24-2017 ",
		 "DATE_CLAIM_CLOSED": "06-26-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Huff",
		 "CLAIMANT_FIRST_NAME": "Anthony",
		 "CLAIM_NO": "2016-11-04-0157-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "11-01-2016 ",
		 "DATE_CLAIM_CLOSED": "11-18-2016 ",
		 "ACTUAL_RTW_DATE": "11-08-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hulshof",
		 "CLAIMANT_FIRST_NAME": "Colleen",
		 "CLAIM_NO": "2015-02-09-0378-VP-01",
		 "DURATION": 98,
		 "DISABILITY_START_DATE": "02-09-2015 ",
		 "DATE_CLAIM_CLOSED": "05-20-2015 ",
		 "ACTUAL_RTW_DATE": "05-18-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hunter",
		 "CLAIMANT_FIRST_NAME": "Lowell",
		 "CLAIM_NO": "2013-10-18-0091-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "10-21-2013 ",
		 "DATE_CLAIM_CLOSED": "10-27-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Hunter",
		 "CLAIMANT_FIRST_NAME": "Kelly",
		 "CLAIM_NO": "2013-01-05-5993-VP-01",
		 "DURATION": 1137,
		 "DISABILITY_START_DATE": "03-04-2011 ",
		 "DATE_CLAIM_CLOSED": "04-14-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Huntley",
		 "CLAIMANT_FIRST_NAME": "Valerey",
		 "CLAIM_NO": "2017-12-06-0600-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "12-07-2017 ",
		 "DATE_CLAIM_CLOSED": "12-26-2017 ",
		 "ACTUAL_RTW_DATE": "12-22-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Huter",
		 "CLAIMANT_FIRST_NAME": "Benjamin",
		 "CLAIM_NO": "2020-08-05-0479-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "08-11-2020 ",
		 "DATE_CLAIM_CLOSED": "09-08-2020 ",
		 "ACTUAL_RTW_DATE": "09-02-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Huth",
		 "CLAIMANT_FIRST_NAME": "Mimi",
		 "CLAIM_NO": "2015-01-15-0468-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "12-29-2014 ",
		 "DATE_CLAIM_CLOSED": "03-09-2015 ",
		 "ACTUAL_RTW_DATE": "01-12-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Huynh",
		 "CLAIMANT_FIRST_NAME": "Cecilia",
		 "CLAIM_NO": "2020-01-03-0725-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "01-02-2020 ",
		 "DATE_CLAIM_CLOSED": "02-04-2020 ",
		 "ACTUAL_RTW_DATE": "02-03-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Huynh",
		 "CLAIMANT_FIRST_NAME": "Cecilia",
		 "CLAIM_NO": "2018-01-16-0027-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "01-26-2018 ",
		 "DATE_CLAIM_CLOSED": "02-12-2018 ",
		 "ACTUAL_RTW_DATE": "02-12-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Huynh",
		 "CLAIMANT_FIRST_NAME": "Hung",
		 "CLAIM_NO": "2019-05-23-0453-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "05-28-2019 ",
		 "DATE_CLAIM_CLOSED": "06-24-2019 ",
		 "ACTUAL_RTW_DATE": "06-05-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Huynh",
		 "CLAIMANT_FIRST_NAME": "Phuong",
		 "CLAIM_NO": "2016-05-12-0280-VP-01",
		 "DURATION": 294,
		 "DISABILITY_START_DATE": "05-09-2016 ",
		 "DATE_CLAIM_CLOSED": "02-27-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ibrahimi",
		 "CLAIMANT_FIRST_NAME": "Erika",
		 "CLAIM_NO": "2021-01-04-0535-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "01-07-2021 ",
		 "DATE_CLAIM_CLOSED": "02-19-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ibrahimi",
		 "CLAIMANT_FIRST_NAME": "Erika",
		 "CLAIM_NO": "2022-10-31-0780-VP-01",
		 "DURATION": 98,
		 "DISABILITY_START_DATE": "12-05-2022 ",
		 "DATE_CLAIM_CLOSED": "01-24-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Iglesias",
		 "CLAIMANT_FIRST_NAME": "Wilma",
		 "CLAIM_NO": "2019-08-29-0222-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "08-26-2019 ",
		 "DATE_CLAIM_CLOSED": "11-11-2019 ",
		 "ACTUAL_RTW_DATE": "11-04-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Iglesias",
		 "CLAIMANT_FIRST_NAME": "Wilma",
		 "CLAIM_NO": "2020-04-08-0248-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "04-06-2020 ",
		 "DATE_CLAIM_CLOSED": "05-07-2020 ",
		 "ACTUAL_RTW_DATE": "04-14-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Intrasuwan",
		 "CLAIMANT_FIRST_NAME": "Kamolporn",
		 "CLAIM_NO": "2014-04-07-0299-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "04-21-2014 ",
		 "DATE_CLAIM_CLOSED": "06-02-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Isaia",
		 "CLAIMANT_FIRST_NAME": "Pearl",
		 "CLAIM_NO": "2018-08-03-0548-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "07-19-2018 ",
		 "DATE_CLAIM_CLOSED": "10-16-2018 ",
		 "ACTUAL_RTW_DATE": "08-14-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ismail",
		 "CLAIMANT_FIRST_NAME": "Mehvish",
		 "CLAIM_NO": "2022-07-12-0911-VP-01",
		 "DURATION": 147,
		 "DISABILITY_START_DATE": "08-15-2022 ",
		 "DATE_CLAIM_CLOSED": "11-22-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ivey",
		 "CLAIMANT_FIRST_NAME": "Jessica",
		 "CLAIM_NO": "2017-12-12-0238-VP-01",
		 "DURATION": 126,
		 "DISABILITY_START_DATE": "10-30-2017 ",
		 "DATE_CLAIM_CLOSED": "01-23-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Iwanaga",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2019-05-23-0342-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "05-03-2019 ",
		 "DATE_CLAIM_CLOSED": "06-10-2019 ",
		 "ACTUAL_RTW_DATE": "05-20-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Iwanaga",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2021-01-25-0431-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "01-25-2021 ",
		 "DATE_CLAIM_CLOSED": "02-25-2021 ",
		 "ACTUAL_RTW_DATE": "02-15-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Izadi",
		 "CLAIMANT_FIRST_NAME": "Homa",
		 "CLAIM_NO": "2021-05-13-0677-VP-01",
		 "DURATION": 50,
		 "DISABILITY_START_DATE": "06-03-2021 ",
		 "DATE_CLAIM_CLOSED": "07-30-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jaca",
		 "CLAIMANT_FIRST_NAME": "Michael",
		 "CLAIM_NO": "2013-03-19-0147-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "03-26-2013 ",
		 "DATE_CLAIM_CLOSED": "05-13-2013 ",
		 "ACTUAL_RTW_DATE": "04-10-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jackson",
		 "CLAIMANT_FIRST_NAME": "Amy",
		 "CLAIM_NO": "2017-02-10-0180-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "03-10-2017 ",
		 "DATE_CLAIM_CLOSED": "05-09-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jackson",
		 "CLAIMANT_FIRST_NAME": "Shavonya",
		 "CLAIM_NO": "2023-01-20-0875-VP-01",
		 "DURATION": 61,
		 "DISABILITY_START_DATE": "01-19-2023 ",
		 "DATE_CLAIM_CLOSED": "03-24-2023 ",
		 "ACTUAL_RTW_DATE": "03-21-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jackson",
		 "CLAIMANT_FIRST_NAME": "Amy",
		 "CLAIM_NO": "2022-09-02-0678-VP-01",
		 "DURATION": 98,
		 "DISABILITY_START_DATE": "09-01-2022 ",
		 "DATE_CLAIM_CLOSED": "10-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jacobo",
		 "CLAIMANT_FIRST_NAME": "Lydia",
		 "CLAIM_NO": "2016-12-13-0648-VP-01",
		 "DURATION": 65,
		 "DISABILITY_START_DATE": "12-10-2016 ",
		 "DATE_CLAIM_CLOSED": "02-27-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jacobo",
		 "CLAIMANT_FIRST_NAME": "Lydia",
		 "CLAIM_NO": "2014-06-17-0193-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "07-07-2014 ",
		 "DATE_CLAIM_CLOSED": "08-19-2014 ",
		 "ACTUAL_RTW_DATE": "07-14-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jacomb",
		 "CLAIMANT_FIRST_NAME": "William",
		 "CLAIM_NO": "2018-09-19-0617-VP-01",
		 "DURATION": 95,
		 "DISABILITY_START_DATE": "10-11-2018 ",
		 "DATE_CLAIM_CLOSED": "01-22-2019 ",
		 "ACTUAL_RTW_DATE": "01-14-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jaimes",
		 "CLAIMANT_FIRST_NAME": "Janet",
		 "CLAIM_NO": "2021-10-06-0788-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "11-22-2021 ",
		 "DATE_CLAIM_CLOSED": "01-25-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jamali",
		 "CLAIMANT_FIRST_NAME": "Farideh",
		 "CLAIM_NO": "2020-11-24-0685-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "12-02-2020 ",
		 "DATE_CLAIM_CLOSED": "01-14-2021 ",
		 "ACTUAL_RTW_DATE": "01-11-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "James",
		 "CLAIMANT_FIRST_NAME": "Renee",
		 "CLAIM_NO": "2019-01-17-0594-VP-01",
		 "DURATION": 105,
		 "DISABILITY_START_DATE": "02-11-2019 ",
		 "DATE_CLAIM_CLOSED": "03-25-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Janardhanaiah",
		 "CLAIMANT_FIRST_NAME": "Nethravathi",
		 "CLAIM_NO": "2022-03-30-0905-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "05-02-2022 ",
		 "DATE_CLAIM_CLOSED": "07-20-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Janik",
		 "CLAIMANT_FIRST_NAME": "James",
		 "CLAIM_NO": "2014-03-02-0014-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "03-03-2014 ",
		 "DATE_CLAIM_CLOSED": "03-09-2015 ",
		 "ACTUAL_RTW_DATE": "04-28-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jantz",
		 "CLAIMANT_FIRST_NAME": "Brandon",
		 "CLAIM_NO": "2014-06-13-0020-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "06-13-2014 ",
		 "DATE_CLAIM_CLOSED": "09-23-2014 ",
		 "ACTUAL_RTW_DATE": "07-23-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jaqua",
		 "CLAIMANT_FIRST_NAME": "Marcy",
		 "CLAIM_NO": "2021-02-26-0766-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "02-22-2021 ",
		 "DATE_CLAIM_CLOSED": "04-28-2021 ",
		 "ACTUAL_RTW_DATE": "04-26-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jarrett",
		 "CLAIMANT_FIRST_NAME": "Felicia",
		 "CLAIM_NO": "2016-06-01-0005-VP-01",
		 "DURATION": 99,
		 "DISABILITY_START_DATE": "06-20-2016 ",
		 "DATE_CLAIM_CLOSED": "08-15-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jasareno",
		 "CLAIMANT_FIRST_NAME": "Ronald",
		 "CLAIM_NO": "2020-09-25-0746-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "11-11-2020 ",
		 "DATE_CLAIM_CLOSED": "01-06-2021 ",
		 "ACTUAL_RTW_DATE": "01-04-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jasem",
		 "CLAIMANT_FIRST_NAME": "Renee",
		 "CLAIM_NO": "2013-08-16-0393-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "08-16-2013 ",
		 "DATE_CLAIM_CLOSED": "09-26-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jasem",
		 "CLAIMANT_FIRST_NAME": "Renee",
		 "CLAIM_NO": "2015-08-27-0184-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "08-26-2015 ",
		 "DATE_CLAIM_CLOSED": "09-24-2015 ",
		 "ACTUAL_RTW_DATE": "09-09-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jasniewicz",
		 "CLAIMANT_FIRST_NAME": "Britney",
		 "CLAIM_NO": "2014-03-24-0251-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "04-18-2014 ",
		 "DATE_CLAIM_CLOSED": "07-02-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jasso",
		 "CLAIMANT_FIRST_NAME": "Josh",
		 "CLAIM_NO": "2020-07-06-0028-VP-01",
		 "DURATION": 190,
		 "DISABILITY_START_DATE": "06-25-2020 ",
		 "DATE_CLAIM_CLOSED": "01-07-2021 ",
		 "ACTUAL_RTW_DATE": "01-01-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jayasree Krishnan",
		 "CLAIMANT_FIRST_NAME": "Veena",
		 "CLAIM_NO": "2022-02-01-0643-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "03-30-2022 ",
		 "DATE_CLAIM_CLOSED": "05-25-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jenkins",
		 "CLAIMANT_FIRST_NAME": "Thomas",
		 "CLAIM_NO": "2021-11-15-0874-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "11-15-2021 ",
		 "DATE_CLAIM_CLOSED": "02-15-2022 ",
		 "ACTUAL_RTW_DATE": "01-24-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jensen",
		 "CLAIMANT_FIRST_NAME": "Crystal",
		 "CLAIM_NO": "2015-05-11-0108-VP-01",
		 "DURATION": 51,
		 "DISABILITY_START_DATE": "05-11-2015 ",
		 "DATE_CLAIM_CLOSED": "07-13-2015 ",
		 "ACTUAL_RTW_DATE": "07-01-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jensen",
		 "CLAIMANT_FIRST_NAME": "Sheri",
		 "CLAIM_NO": "2019-03-04-0850-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "03-04-2019 ",
		 "DATE_CLAIM_CLOSED": "04-17-2019 ",
		 "ACTUAL_RTW_DATE": "04-15-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jensen",
		 "CLAIMANT_FIRST_NAME": "Crystal",
		 "CLAIM_NO": "2014-07-15-0272-VP-01",
		 "DURATION": 175,
		 "DISABILITY_START_DATE": "07-14-2014 ",
		 "DATE_CLAIM_CLOSED": "01-05-2015 ",
		 "ACTUAL_RTW_DATE": "01-05-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jensen",
		 "CLAIMANT_FIRST_NAME": "Crystal",
		 "CLAIM_NO": "2013-01-05-0080-VP-01",
		 "DURATION": 66,
		 "DISABILITY_START_DATE": "12-14-2012 ",
		 "DATE_CLAIM_CLOSED": "06-14-2013 ",
		 "ACTUAL_RTW_DATE": "02-18-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jensen-Gonzales",
		 "CLAIMANT_FIRST_NAME": "Elkana",
		 "CLAIM_NO": "2018-05-07-0339-VP-01",
		 "DURATION": 126,
		 "DISABILITY_START_DATE": "05-01-2018 ",
		 "DATE_CLAIM_CLOSED": "06-28-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jensen-Gonzales",
		 "CLAIMANT_FIRST_NAME": "Elkana",
		 "CLAIM_NO": "2019-12-03-0504-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "12-13-2019 ",
		 "DATE_CLAIM_CLOSED": "02-07-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jiang",
		 "CLAIMANT_FIRST_NAME": "Chunlan",
		 "CLAIM_NO": "2021-11-03-0244-VP-01",
		 "DURATION": -297,
		 "DISABILITY_START_DATE": "11-22-2021 ",
		 "DATE_CLAIM_CLOSED": "01-31-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jiang",
		 "CLAIMANT_FIRST_NAME": "Jiayuan",
		 "CLAIM_NO": "2016-01-08-0232-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "01-07-2016 ",
		 "DATE_CLAIM_CLOSED": "09-14-2016 ",
		 "ACTUAL_RTW_DATE": "03-28-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jimenez",
		 "CLAIMANT_FIRST_NAME": "Karina",
		 "CLAIM_NO": "2013-01-05-1836-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "06-11-2012 ",
		 "DATE_CLAIM_CLOSED": "04-08-2013 ",
		 "ACTUAL_RTW_DATE": "08-31-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jinadu",
		 "CLAIMANT_FIRST_NAME": "Olukayode",
		 "CLAIM_NO": "2021-02-15-0290-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "02-08-2021 ",
		 "DATE_CLAIM_CLOSED": "03-02-2021 ",
		 "ACTUAL_RTW_DATE": "02-25-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Joachin",
		 "CLAIMANT_FIRST_NAME": "Juan",
		 "CLAIM_NO": "2015-09-28-0340-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "09-25-2015 ",
		 "DATE_CLAIM_CLOSED": "10-22-2015 ",
		 "ACTUAL_RTW_DATE": "10-12-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jocson",
		 "CLAIMANT_FIRST_NAME": "Lucida",
		 "CLAIM_NO": "2017-10-06-0013-VP-01",
		 "DURATION": 23,
		 "DISABILITY_START_DATE": "11-09-2017 ",
		 "DATE_CLAIM_CLOSED": "12-11-2017 ",
		 "ACTUAL_RTW_DATE": "12-02-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "John",
		 "CLAIMANT_FIRST_NAME": "Asha",
		 "CLAIM_NO": "2014-09-09-0273-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "09-10-2014 ",
		 "DATE_CLAIM_CLOSED": "12-11-2014 ",
		 "ACTUAL_RTW_DATE": "11-13-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Johnson",
		 "CLAIMANT_FIRST_NAME": "Nancy",
		 "CLAIM_NO": "2013-12-18-0477-VP-02",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "01-08-2014 ",
		 "DATE_CLAIM_CLOSED": "05-19-2014 ",
		 "ACTUAL_RTW_DATE": "03-17-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Johnson",
		 "CLAIMANT_FIRST_NAME": "Antoinette",
		 "CLAIM_NO": "2018-06-20-0676-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "07-06-2018 ",
		 "DATE_CLAIM_CLOSED": "08-06-2018 ",
		 "ACTUAL_RTW_DATE": "07-24-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Johnson",
		 "CLAIMANT_FIRST_NAME": "Quincy",
		 "CLAIM_NO": "2021-11-18-0549-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "11-11-2021 ",
		 "DATE_CLAIM_CLOSED": "01-05-2022 ",
		 "ACTUAL_RTW_DATE": "12-27-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Johnson",
		 "CLAIMANT_FIRST_NAME": "Ebony",
		 "CLAIM_NO": "2019-08-06-0322-VP-01",
		 "DURATION": 108,
		 "DISABILITY_START_DATE": "08-07-2019 ",
		 "DATE_CLAIM_CLOSED": "12-09-2019 ",
		 "ACTUAL_RTW_DATE": "11-23-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Johnson",
		 "CLAIMANT_FIRST_NAME": "Angel",
		 "CLAIM_NO": "2022-10-17-1099-VP-01",
		 "DURATION": 66,
		 "DISABILITY_START_DATE": "10-17-2022 ",
		 "DATE_CLAIM_CLOSED": "01-04-2023 ",
		 "ACTUAL_RTW_DATE": "12-22-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Johnson",
		 "CLAIMANT_FIRST_NAME": "Matthew",
		 "CLAIM_NO": "2023-01-12-0792-VP-01",
		 "DURATION": 93,
		 "DISABILITY_START_DATE": "01-10-2023 ",
		 "DATE_CLAIM_CLOSED": "04-18-2023 ",
		 "ACTUAL_RTW_DATE": "04-13-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Johnson",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2014-10-31-0420-VP-01",
		 "DURATION": 104,
		 "DISABILITY_START_DATE": "11-18-2014 ",
		 "DATE_CLAIM_CLOSED": "03-02-2015 ",
		 "ACTUAL_RTW_DATE": "03-02-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Johnson",
		 "CLAIMANT_FIRST_NAME": "Beatrice",
		 "CLAIM_NO": "2013-01-05-0016-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "01-10-2013 ",
		 "DATE_CLAIM_CLOSED": "04-30-2013 ",
		 "ACTUAL_RTW_DATE": "02-21-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Johnson",
		 "CLAIMANT_FIRST_NAME": "Matthew",
		 "CLAIM_NO": "2021-04-13-0942-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "04-14-2021 ",
		 "DATE_CLAIM_CLOSED": "05-05-2021 ",
		 "ACTUAL_RTW_DATE": "05-03-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Johnson",
		 "CLAIMANT_FIRST_NAME": "Ronnie",
		 "CLAIM_NO": "2022-07-15-0783-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "08-11-2022 ",
		 "DATE_CLAIM_CLOSED": "09-08-2022 ",
		 "ACTUAL_RTW_DATE": "08-25-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Johnston",
		 "CLAIMANT_FIRST_NAME": "Stephanie",
		 "CLAIM_NO": "2022-07-11-0700-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "07-18-2022 ",
		 "DATE_CLAIM_CLOSED": "09-19-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Johnston",
		 "CLAIMANT_FIRST_NAME": "Stephanie",
		 "CLAIM_NO": "2020-03-10-0634-VP-01",
		 "DURATION": 53,
		 "DISABILITY_START_DATE": "03-26-2020 ",
		 "DATE_CLAIM_CLOSED": "05-21-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jonda",
		 "CLAIMANT_FIRST_NAME": "Danielle",
		 "CLAIM_NO": "2019-01-17-0641-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "02-25-2019 ",
		 "DATE_CLAIM_CLOSED": "05-06-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jones",
		 "CLAIMANT_FIRST_NAME": "Donovan",
		 "CLAIM_NO": "2021-12-15-1431-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "12-10-2021 ",
		 "DATE_CLAIM_CLOSED": "12-27-2021 ",
		 "ACTUAL_RTW_DATE": "12-21-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jones",
		 "CLAIMANT_FIRST_NAME": "Tammy",
		 "CLAIM_NO": "2019-08-26-0605-VP-01",
		 "DURATION": 30,
		 "DISABILITY_START_DATE": "09-25-2019 ",
		 "DATE_CLAIM_CLOSED": "10-28-2019 ",
		 "ACTUAL_RTW_DATE": "10-25-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jones",
		 "CLAIMANT_FIRST_NAME": "James",
		 "CLAIM_NO": "2019-06-04-0244-VP-01",
		 "DURATION": 75,
		 "DISABILITY_START_DATE": "06-03-2019 ",
		 "DATE_CLAIM_CLOSED": "10-07-2019 ",
		 "ACTUAL_RTW_DATE": "08-17-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jones",
		 "CLAIMANT_FIRST_NAME": "Marc",
		 "CLAIM_NO": "2021-09-22-1015-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "10-13-2021 ",
		 "DATE_CLAIM_CLOSED": "01-12-2022 ",
		 "ACTUAL_RTW_DATE": "11-01-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jones",
		 "CLAIMANT_FIRST_NAME": "James",
		 "CLAIM_NO": "2013-05-24-0324-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "05-20-2013 ",
		 "DATE_CLAIM_CLOSED": "07-23-2013 ",
		 "ACTUAL_RTW_DATE": "07-08-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jones",
		 "CLAIMANT_FIRST_NAME": "Danny",
		 "CLAIM_NO": "2022-02-09-0715-VP-01",
		 "DURATION": 268,
		 "DISABILITY_START_DATE": "02-07-2022 ",
		 "DATE_CLAIM_CLOSED": "02-08-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jones",
		 "CLAIMANT_FIRST_NAME": "Danny",
		 "CLAIM_NO": "2021-02-23-0522-VP-01",
		 "DURATION": 69,
		 "DISABILITY_START_DATE": "02-23-2021 ",
		 "DATE_CLAIM_CLOSED": "05-05-2021 ",
		 "ACTUAL_RTW_DATE": "05-03-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jones",
		 "CLAIMANT_FIRST_NAME": "James",
		 "CLAIM_NO": "2021-07-01-0825-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "07-01-2021 ",
		 "DATE_CLAIM_CLOSED": "08-16-2021 ",
		 "ACTUAL_RTW_DATE": "07-12-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jones",
		 "CLAIMANT_FIRST_NAME": "Nina",
		 "CLAIM_NO": "2013-11-28-0011-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "12-09-2013 ",
		 "DATE_CLAIM_CLOSED": "03-12-2014 ",
		 "ACTUAL_RTW_DATE": "01-06-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jones",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2013-11-05-0536-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "11-22-2013 ",
		 "DATE_CLAIM_CLOSED": "02-18-2014 ",
		 "ACTUAL_RTW_DATE": "12-13-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jones-Mcmeans",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2014-04-07-0167-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "05-01-2014 ",
		 "DATE_CLAIM_CLOSED": "11-03-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jonnavithula",
		 "CLAIMANT_FIRST_NAME": "Lalitha",
		 "CLAIM_NO": "2017-11-16-0578-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "11-16-2017 ",
		 "DATE_CLAIM_CLOSED": "12-21-2017 ",
		 "ACTUAL_RTW_DATE": "12-18-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jordan",
		 "CLAIMANT_FIRST_NAME": "Erica",
		 "CLAIM_NO": "2013-01-05-0221-VP-01",
		 "DURATION": 25,
		 "DISABILITY_START_DATE": "01-03-2013 ",
		 "DATE_CLAIM_CLOSED": "02-26-2013 ",
		 "ACTUAL_RTW_DATE": "01-28-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jordan",
		 "CLAIMANT_FIRST_NAME": "Erica",
		 "CLAIM_NO": "2016-03-29-0537-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "04-29-2016 ",
		 "DATE_CLAIM_CLOSED": "05-26-2016 ",
		 "ACTUAL_RTW_DATE": "05-16-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jordan",
		 "CLAIMANT_FIRST_NAME": "Shaleigha",
		 "CLAIM_NO": "2021-07-27-0015-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "07-14-2021 ",
		 "DATE_CLAIM_CLOSED": "09-14-2021 ",
		 "ACTUAL_RTW_DATE": "07-22-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Joseph",
		 "CLAIMANT_FIRST_NAME": "Cheri",
		 "CLAIM_NO": "2018-09-20-0500-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "10-01-2018 ",
		 "DATE_CLAIM_CLOSED": "12-11-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jovel Campos",
		 "CLAIMANT_FIRST_NAME": "Jose",
		 "CLAIM_NO": "2016-06-24-0237-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "06-28-2016 ",
		 "DATE_CLAIM_CLOSED": "07-05-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Juarez",
		 "CLAIMANT_FIRST_NAME": "Marco",
		 "CLAIM_NO": "2013-01-05-1415-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "07-18-2012 ",
		 "DATE_CLAIM_CLOSED": "07-22-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Juarez",
		 "CLAIMANT_FIRST_NAME": "Marco",
		 "CLAIM_NO": "2013-01-05-5900-VP-01",
		 "DURATION": 99,
		 "DISABILITY_START_DATE": "03-14-2011 ",
		 "DATE_CLAIM_CLOSED": "01-24-2013 ",
		 "ACTUAL_RTW_DATE": "06-21-2011 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Juenger",
		 "CLAIMANT_FIRST_NAME": "Aimee",
		 "CLAIM_NO": "2017-01-12-0569-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "02-07-2017 ",
		 "DATE_CLAIM_CLOSED": "02-27-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jujana",
		 "CLAIMANT_FIRST_NAME": "Dan",
		 "CLAIM_NO": "2013-03-15-0228-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "04-02-2013 ",
		 "DATE_CLAIM_CLOSED": "07-08-2013 ",
		 "ACTUAL_RTW_DATE": "07-02-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jujana",
		 "CLAIMANT_FIRST_NAME": "Angelica",
		 "CLAIM_NO": "2020-12-18-0717-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "03-02-2021 ",
		 "DATE_CLAIM_CLOSED": "04-16-2021 ",
		 "ACTUAL_RTW_DATE": "04-14-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jules-Fleury",
		 "CLAIMANT_FIRST_NAME": "Sherly",
		 "CLAIM_NO": "2016-08-09-0555-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "09-14-2016 ",
		 "DATE_CLAIM_CLOSED": "10-17-2016 ",
		 "ACTUAL_RTW_DATE": "10-13-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Justman",
		 "CLAIMANT_FIRST_NAME": "Leslie",
		 "CLAIM_NO": "2021-09-29-0367-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "09-29-2021 ",
		 "DATE_CLAIM_CLOSED": "12-13-2021 ",
		 "ACTUAL_RTW_DATE": "10-15-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Justo",
		 "CLAIMANT_FIRST_NAME": "Joel",
		 "CLAIM_NO": "2022-09-05-0040-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "09-26-2022 ",
		 "DATE_CLAIM_CLOSED": "11-29-2022 ",
		 "ACTUAL_RTW_DATE": "10-24-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Justo",
		 "CLAIMANT_FIRST_NAME": "Joel",
		 "CLAIM_NO": "2019-03-20-0289-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "03-13-2019 ",
		 "DATE_CLAIM_CLOSED": "05-06-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Justo",
		 "CLAIMANT_FIRST_NAME": "Benjamin",
		 "CLAIM_NO": "2019-09-06-0293-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "09-17-2019 ",
		 "DATE_CLAIM_CLOSED": "10-06-2019 ",
		 "ACTUAL_RTW_DATE": "10-02-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jwanouskos",
		 "CLAIMANT_FIRST_NAME": "Steven",
		 "CLAIM_NO": "2015-06-19-0277-VP-01",
		 "DURATION": 65,
		 "DISABILITY_START_DATE": "06-17-2015 ",
		 "DATE_CLAIM_CLOSED": "08-25-2015 ",
		 "ACTUAL_RTW_DATE": "08-21-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Jwanouskos",
		 "CLAIMANT_FIRST_NAME": "Steven",
		 "CLAIM_NO": "2016-10-04-0090-VP-01",
		 "DURATION": 90,
		 "DISABILITY_START_DATE": "10-03-2016 ",
		 "DATE_CLAIM_CLOSED": "01-09-2017 ",
		 "ACTUAL_RTW_DATE": "01-01-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kabe",
		 "CLAIMANT_FIRST_NAME": "Arundhati",
		 "CLAIM_NO": "2013-01-05-0765-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "10-19-2012 ",
		 "DATE_CLAIM_CLOSED": "08-11-2014 ",
		 "ACTUAL_RTW_DATE": "01-11-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kaiser",
		 "CLAIMANT_FIRST_NAME": "Jacquiline",
		 "CLAIM_NO": "2013-08-21-0373-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "08-19-2013 ",
		 "DATE_CLAIM_CLOSED": "10-07-2013 ",
		 "ACTUAL_RTW_DATE": "09-02-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kaiser",
		 "CLAIMANT_FIRST_NAME": "Jacquiline",
		 "CLAIM_NO": "2015-02-26-0154-VP-01",
		 "DURATION": 44,
		 "DISABILITY_START_DATE": "02-24-2015 ",
		 "DATE_CLAIM_CLOSED": "07-13-2015 ",
		 "ACTUAL_RTW_DATE": "04-09-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kaiser",
		 "CLAIMANT_FIRST_NAME": "Jacquiline",
		 "CLAIM_NO": "2015-08-13-0427-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "08-13-2015 ",
		 "DATE_CLAIM_CLOSED": "09-29-2015 ",
		 "ACTUAL_RTW_DATE": "08-24-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kalvass",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2017-11-20-0276-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "11-14-2017 ",
		 "DATE_CLAIM_CLOSED": "12-26-2017 ",
		 "ACTUAL_RTW_DATE": "11-27-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kalvass",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2014-08-13-0556-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "09-15-2014 ",
		 "DATE_CLAIM_CLOSED": "01-13-2015 ",
		 "ACTUAL_RTW_DATE": "11-22-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kalvass",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2017-06-19-0356-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "07-14-2017 ",
		 "DATE_CLAIM_CLOSED": "09-05-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kao",
		 "CLAIMANT_FIRST_NAME": "Weiting",
		 "CLAIM_NO": "2021-04-22-0834-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "04-29-2021 ",
		 "DATE_CLAIM_CLOSED": "06-14-2021 ",
		 "ACTUAL_RTW_DATE": "06-14-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kao",
		 "CLAIMANT_FIRST_NAME": "Weiting",
		 "CLAIM_NO": "2016-07-22-0224-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "08-09-2016 ",
		 "DATE_CLAIM_CLOSED": "10-11-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Karamil Franzonia",
		 "CLAIMANT_FIRST_NAME": "Seda",
		 "CLAIM_NO": "2021-10-26-0573-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "12-22-2021 ",
		 "DATE_CLAIM_CLOSED": "02-03-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Karan",
		 "CLAIMANT_FIRST_NAME": "Ajay",
		 "CLAIM_NO": "2013-02-15-0016-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "02-12-2013 ",
		 "DATE_CLAIM_CLOSED": "05-15-2013 ",
		 "ACTUAL_RTW_DATE": "03-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Karan",
		 "CLAIMANT_FIRST_NAME": "Ajay",
		 "CLAIM_NO": "2014-04-21-0160-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "04-18-2014 ",
		 "DATE_CLAIM_CLOSED": "07-29-2014 ",
		 "ACTUAL_RTW_DATE": "05-12-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Karshenas",
		 "CLAIMANT_FIRST_NAME": "Allie",
		 "CLAIM_NO": "2013-02-01-0381-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "02-08-2013 ",
		 "DATE_CLAIM_CLOSED": "07-23-2013 ",
		 "ACTUAL_RTW_DATE": "02-25-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kashyap",
		 "CLAIMANT_FIRST_NAME": "Jhuri",
		 "CLAIM_NO": "2021-08-04-0470-VP-01",
		 "DURATION": 105,
		 "DISABILITY_START_DATE": "09-27-2021 ",
		 "DATE_CLAIM_CLOSED": "01-31-2022 ",
		 "ACTUAL_RTW_DATE": "01-10-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kasminskiy",
		 "CLAIMANT_FIRST_NAME": "Yuliya",
		 "CLAIM_NO": "2015-07-22-0451-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "08-17-2015 ",
		 "DATE_CLAIM_CLOSED": "09-22-2015 ",
		 "ACTUAL_RTW_DATE": "08-31-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kasner",
		 "CLAIMANT_FIRST_NAME": "Sharan",
		 "CLAIM_NO": "2014-04-16-0479-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "04-07-2014 ",
		 "DATE_CLAIM_CLOSED": "07-30-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kast",
		 "CLAIMANT_FIRST_NAME": "John",
		 "CLAIM_NO": "2017-01-04-0631-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "12-27-2016 ",
		 "DATE_CLAIM_CLOSED": "02-27-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kastanos",
		 "CLAIMANT_FIRST_NAME": "Kevin",
		 "CLAIM_NO": "2018-05-09-0547-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "05-03-2018 ",
		 "DATE_CLAIM_CLOSED": "06-13-2018 ",
		 "ACTUAL_RTW_DATE": "06-01-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Katiwa",
		 "CLAIMANT_FIRST_NAME": "Dolphine",
		 "CLAIM_NO": "2020-04-06-0355-VP-01",
		 "DURATION": 73,
		 "DISABILITY_START_DATE": "05-01-2020 ",
		 "DATE_CLAIM_CLOSED": "07-14-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kato",
		 "CLAIMANT_FIRST_NAME": "Vince",
		 "CLAIM_NO": "2020-04-01-0685-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "04-06-2020 ",
		 "DATE_CLAIM_CLOSED": "05-11-2020 ",
		 "ACTUAL_RTW_DATE": "05-05-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kaufman",
		 "CLAIMANT_FIRST_NAME": "Shirin",
		 "CLAIM_NO": "2017-11-28-0006-VP-01",
		 "DURATION": 167,
		 "DISABILITY_START_DATE": "12-05-2017 ",
		 "DATE_CLAIM_CLOSED": "06-12-2018 ",
		 "ACTUAL_RTW_DATE": "05-21-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kaur",
		 "CLAIMANT_FIRST_NAME": "Sarabjit",
		 "CLAIM_NO": "2019-02-20-0517-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "02-18-2019 ",
		 "DATE_CLAIM_CLOSED": "03-14-2019 ",
		 "ACTUAL_RTW_DATE": "03-09-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kaur",
		 "CLAIMANT_FIRST_NAME": "Surjit",
		 "CLAIM_NO": "2021-02-04-0811-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "02-04-2021 ",
		 "DATE_CLAIM_CLOSED": "03-02-2021 ",
		 "ACTUAL_RTW_DATE": "02-26-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kaur",
		 "CLAIMANT_FIRST_NAME": "Surjit",
		 "CLAIM_NO": "2022-03-05-0045-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "03-15-2022 ",
		 "DATE_CLAIM_CLOSED": "03-28-2022 ",
		 "ACTUAL_RTW_DATE": "03-23-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kaur",
		 "CLAIMANT_FIRST_NAME": "Harpreet",
		 "CLAIM_NO": "2019-10-10-0017-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "11-25-2019 ",
		 "DATE_CLAIM_CLOSED": "01-23-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kaur",
		 "CLAIMANT_FIRST_NAME": "Amandeep",
		 "CLAIM_NO": "2020-09-29-0781-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "09-24-2020 ",
		 "DATE_CLAIM_CLOSED": "10-26-2020 ",
		 "ACTUAL_RTW_DATE": "10-15-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kaur",
		 "CLAIMANT_FIRST_NAME": "Surjit",
		 "CLAIM_NO": "2020-09-01-0933-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "09-09-2020 ",
		 "DATE_CLAIM_CLOSED": "11-09-2020 ",
		 "ACTUAL_RTW_DATE": "11-02-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kaur",
		 "CLAIMANT_FIRST_NAME": "Shaminder",
		 "CLAIM_NO": "2022-09-16-0683-VP-01",
		 "DURATION": 39,
		 "DISABILITY_START_DATE": "08-18-2022 ",
		 "DATE_CLAIM_CLOSED": "10-21-2022 ",
		 "ACTUAL_RTW_DATE": "09-26-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kaur",
		 "CLAIMANT_FIRST_NAME": "Shaminder",
		 "CLAIM_NO": "2021-08-20-0802-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "08-03-2021 ",
		 "DATE_CLAIM_CLOSED": "09-29-2021 ",
		 "ACTUAL_RTW_DATE": "08-16-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kaur",
		 "CLAIMANT_FIRST_NAME": "Shikha",
		 "CLAIM_NO": "2021-08-24-0957-VP-01",
		 "DURATION": 69,
		 "DISABILITY_START_DATE": "09-01-2021 ",
		 "DATE_CLAIM_CLOSED": "11-10-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kaushal",
		 "CLAIMANT_FIRST_NAME": "Shelley",
		 "CLAIM_NO": "2016-10-13-0264-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "10-24-2016 ",
		 "DATE_CLAIM_CLOSED": "12-23-2016 ",
		 "ACTUAL_RTW_DATE": "12-19-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kaushal",
		 "CLAIMANT_FIRST_NAME": "Shelley",
		 "CLAIM_NO": "2018-08-03-0577-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "08-31-2018 ",
		 "DATE_CLAIM_CLOSED": "11-15-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kay",
		 "CLAIMANT_FIRST_NAME": "Rosalynn",
		 "CLAIM_NO": "2016-07-15-0411-VP-01",
		 "DURATION": 69,
		 "DISABILITY_START_DATE": "08-15-2016 ",
		 "DATE_CLAIM_CLOSED": "10-26-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kayvongsone",
		 "CLAIMANT_FIRST_NAME": "Malaykham",
		 "CLAIM_NO": "2013-05-14-0157-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "05-14-2013 ",
		 "DATE_CLAIM_CLOSED": "07-02-2013 ",
		 "ACTUAL_RTW_DATE": "05-25-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kealy",
		 "CLAIMANT_FIRST_NAME": "Aine",
		 "CLAIM_NO": "2020-12-08-0754-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "12-01-2020 ",
		 "DATE_CLAIM_CLOSED": "12-23-2020 ",
		 "ACTUAL_RTW_DATE": "12-14-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Keaveny",
		 "CLAIMANT_FIRST_NAME": "Aishling",
		 "CLAIM_NO": "2020-07-31-0514-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "08-27-2020 ",
		 "DATE_CLAIM_CLOSED": "10-14-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Keaveny",
		 "CLAIMANT_FIRST_NAME": "Aishling",
		 "CLAIM_NO": "2022-06-20-0483-VP-01",
		 "DURATION": 53,
		 "DISABILITY_START_DATE": "07-25-2022 ",
		 "DATE_CLAIM_CLOSED": "10-10-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kehl",
		 "CLAIMANT_FIRST_NAME": "Li",
		 "CLAIM_NO": "2020-07-31-0315-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "08-18-2020 ",
		 "DATE_CLAIM_CLOSED": "09-30-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kell",
		 "CLAIMANT_FIRST_NAME": "Brenna",
		 "CLAIM_NO": "2015-12-23-0370-VP-01",
		 "DURATION": 94,
		 "DISABILITY_START_DATE": "01-12-2016 ",
		 "DATE_CLAIM_CLOSED": "02-24-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kelley",
		 "CLAIMANT_FIRST_NAME": "Jamila",
		 "CLAIM_NO": "2014-12-08-0335-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "01-06-2015 ",
		 "DATE_CLAIM_CLOSED": "03-23-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kelley",
		 "CLAIMANT_FIRST_NAME": "Jamila",
		 "CLAIM_NO": "2020-02-03-0822-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "01-22-2020 ",
		 "DATE_CLAIM_CLOSED": "02-18-2020 ",
		 "ACTUAL_RTW_DATE": "02-03-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kelley",
		 "CLAIMANT_FIRST_NAME": "Irene",
		 "CLAIM_NO": "2021-04-27-0974-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "05-10-2021 ",
		 "DATE_CLAIM_CLOSED": "05-26-2021 ",
		 "ACTUAL_RTW_DATE": "05-24-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kelley",
		 "CLAIMANT_FIRST_NAME": "Irene",
		 "CLAIM_NO": "2022-01-14-1130-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "03-07-2022 ",
		 "DATE_CLAIM_CLOSED": "04-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kelley",
		 "CLAIMANT_FIRST_NAME": "Patricia",
		 "CLAIM_NO": "2015-09-02-0470-VP-01",
		 "DURATION": 138,
		 "DISABILITY_START_DATE": "08-20-2015 ",
		 "DATE_CLAIM_CLOSED": "01-12-2016 ",
		 "ACTUAL_RTW_DATE": "01-05-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kelly",
		 "CLAIMANT_FIRST_NAME": "Donnell",
		 "CLAIM_NO": "2021-07-29-0582-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "08-17-2021 ",
		 "DATE_CLAIM_CLOSED": "09-29-2021 ",
		 "ACTUAL_RTW_DATE": "09-06-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kelly",
		 "CLAIMANT_FIRST_NAME": "Jimmi",
		 "CLAIM_NO": "2021-09-01-0675-VP-01",
		 "DURATION": 125,
		 "DISABILITY_START_DATE": "08-29-2021 ",
		 "DATE_CLAIM_CLOSED": "01-19-2022 ",
		 "ACTUAL_RTW_DATE": "01-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kemp",
		 "CLAIMANT_FIRST_NAME": "Tammy",
		 "CLAIM_NO": "2018-07-02-0574-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "07-05-2018 ",
		 "DATE_CLAIM_CLOSED": "08-06-2018 ",
		 "ACTUAL_RTW_DATE": "07-20-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kempaiah",
		 "CLAIMANT_FIRST_NAME": "Divya",
		 "CLAIM_NO": "2022-10-20-0529-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "10-14-2022 ",
		 "DATE_CLAIM_CLOSED": "11-08-2022 ",
		 "ACTUAL_RTW_DATE": "10-31-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kendrat",
		 "CLAIMANT_FIRST_NAME": "Kelly",
		 "CLAIM_NO": "2022-01-27-0965-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "03-03-2022 ",
		 "DATE_CLAIM_CLOSED": "03-23-2022 ",
		 "ACTUAL_RTW_DATE": "03-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kennedy",
		 "CLAIMANT_FIRST_NAME": "Howard",
		 "CLAIM_NO": "2021-02-16-0009-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "02-25-2021 ",
		 "DATE_CLAIM_CLOSED": "04-12-2021 ",
		 "ACTUAL_RTW_DATE": "04-08-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kennedy",
		 "CLAIMANT_FIRST_NAME": "Howard",
		 "CLAIM_NO": "2021-05-18-0938-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "05-28-2021 ",
		 "DATE_CLAIM_CLOSED": "10-04-2021 ",
		 "ACTUAL_RTW_DATE": "07-12-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kenny",
		 "CLAIMANT_FIRST_NAME": "Elizabeth",
		 "CLAIM_NO": "2021-09-20-0654-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "11-04-2021 ",
		 "DATE_CLAIM_CLOSED": "12-20-2021 ",
		 "ACTUAL_RTW_DATE": "12-16-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kent",
		 "CLAIMANT_FIRST_NAME": "Vanna",
		 "CLAIM_NO": "2020-06-04-0748-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "06-22-2020 ",
		 "DATE_CLAIM_CLOSED": "08-17-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kerensky",
		 "CLAIMANT_FIRST_NAME": "Lourdes",
		 "CLAIM_NO": "2016-06-06-0369-VP-01",
		 "DURATION": 181,
		 "DISABILITY_START_DATE": "05-25-2016 ",
		 "DATE_CLAIM_CLOSED": "12-02-2016 ",
		 "ACTUAL_RTW_DATE": "11-22-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kerensky",
		 "CLAIMANT_FIRST_NAME": "Lourdes",
		 "CLAIM_NO": "2021-08-24-0374-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "08-25-2021 ",
		 "DATE_CLAIM_CLOSED": "10-25-2021 ",
		 "ACTUAL_RTW_DATE": "09-13-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kerensky",
		 "CLAIMANT_FIRST_NAME": "Lourdes",
		 "CLAIM_NO": "2014-09-17-0155-VP-01",
		 "DURATION": 5,
		 "DISABILITY_START_DATE": "09-17-2014 ",
		 "DATE_CLAIM_CLOSED": "11-20-2014 ",
		 "ACTUAL_RTW_DATE": "09-22-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kern",
		 "CLAIMANT_FIRST_NAME": "Ami",
		 "CLAIM_NO": "2013-09-18-0438-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "10-08-2013 ",
		 "DATE_CLAIM_CLOSED": "01-27-2014 ",
		 "ACTUAL_RTW_DATE": "12-06-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kern",
		 "CLAIMANT_FIRST_NAME": "Ami",
		 "CLAIM_NO": "2017-04-27-0030-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "08-12-2017 ",
		 "DATE_CLAIM_CLOSED": "10-10-2017 ",
		 "ACTUAL_RTW_DATE": "10-09-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Keshishyan",
		 "CLAIMANT_FIRST_NAME": "Gary",
		 "CLAIM_NO": "2021-07-27-1154-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "07-26-2021 ",
		 "DATE_CLAIM_CLOSED": "10-18-2021 ",
		 "ACTUAL_RTW_DATE": "09-27-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Khalifeh",
		 "CLAIMANT_FIRST_NAME": "Tony",
		 "CLAIM_NO": "2013-01-05-4126-VP-01",
		 "DURATION": 482,
		 "DISABILITY_START_DATE": "09-12-2011 ",
		 "DATE_CLAIM_CLOSED": "01-21-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Khalil",
		 "CLAIMANT_FIRST_NAME": "Usama",
		 "CLAIM_NO": "2021-04-21-0789-VP-01",
		 "DURATION": 145,
		 "DISABILITY_START_DATE": "04-26-2021 ",
		 "DATE_CLAIM_CLOSED": "09-24-2021 ",
		 "ACTUAL_RTW_DATE": "09-18-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Khamphilavong",
		 "CLAIMANT_FIRST_NAME": "Manivanh",
		 "CLAIM_NO": "2013-01-18-0213-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "01-11-2013 ",
		 "DATE_CLAIM_CLOSED": "02-25-2013 ",
		 "ACTUAL_RTW_DATE": "01-21-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Khan",
		 "CLAIMANT_FIRST_NAME": "Nishatur",
		 "CLAIM_NO": "2013-01-05-2610-VP-01",
		 "DURATION": 268,
		 "DISABILITY_START_DATE": "03-06-2012 ",
		 "DATE_CLAIM_CLOSED": "03-27-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Khan",
		 "CLAIMANT_FIRST_NAME": "Shaista",
		 "CLAIM_NO": "2014-06-19-0548-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "06-20-2014 ",
		 "DATE_CLAIM_CLOSED": "12-29-2014 ",
		 "ACTUAL_RTW_DATE": "06-30-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Khan",
		 "CLAIMANT_FIRST_NAME": "Shaista",
		 "CLAIM_NO": "2014-12-04-0320-VP-01",
		 "DURATION": 127,
		 "DISABILITY_START_DATE": "12-05-2014 ",
		 "DATE_CLAIM_CLOSED": "08-04-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Khare",
		 "CLAIMANT_FIRST_NAME": "Manasi",
		 "CLAIM_NO": "2019-07-22-0751-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "08-26-2019 ",
		 "DATE_CLAIM_CLOSED": "11-22-2019 ",
		 "ACTUAL_RTW_DATE": "10-24-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Khosravan Ahoura",
		 "CLAIMANT_FIRST_NAME": "Anita",
		 "CLAIM_NO": "2021-07-29-0286-VP-01",
		 "DURATION": 89,
		 "DISABILITY_START_DATE": "09-24-2021 ",
		 "DATE_CLAIM_CLOSED": "12-27-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Khosrowpanah",
		 "CLAIMANT_FIRST_NAME": "Neda",
		 "CLAIM_NO": "2019-08-26-0773-VP-01",
		 "DURATION": 78,
		 "DISABILITY_START_DATE": "10-04-2019 ",
		 "DATE_CLAIM_CLOSED": "12-23-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Khuu",
		 "CLAIMANT_FIRST_NAME": "Thuy",
		 "CLAIM_NO": "2021-09-14-0565-VP-01",
		 "DURATION": 111,
		 "DISABILITY_START_DATE": "09-15-2021 ",
		 "DATE_CLAIM_CLOSED": "01-20-2022 ",
		 "ACTUAL_RTW_DATE": "01-04-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Khzanyan",
		 "CLAIMANT_FIRST_NAME": "Alina",
		 "CLAIM_NO": "2021-08-02-0415-VP-01",
		 "DURATION": 87,
		 "DISABILITY_START_DATE": "08-16-2021 ",
		 "DATE_CLAIM_CLOSED": "11-12-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kiely",
		 "CLAIMANT_FIRST_NAME": "Michael",
		 "CLAIM_NO": "2016-01-28-0265-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "02-09-2016 ",
		 "DATE_CLAIM_CLOSED": "05-06-2016 ",
		 "ACTUAL_RTW_DATE": "05-03-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kikuta",
		 "CLAIMANT_FIRST_NAME": "Joy",
		 "CLAIM_NO": "2014-01-17-0501-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "02-05-2014 ",
		 "DATE_CLAIM_CLOSED": "03-24-2014 ",
		 "ACTUAL_RTW_DATE": "02-19-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kiley",
		 "CLAIMANT_FIRST_NAME": "Kristen",
		 "CLAIM_NO": "2015-01-26-0307-VP-01",
		 "DURATION": 89,
		 "DISABILITY_START_DATE": "02-16-2015 ",
		 "DATE_CLAIM_CLOSED": "05-20-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kilinski",
		 "CLAIMANT_FIRST_NAME": "Lydia",
		 "CLAIM_NO": "2013-08-06-0428-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "09-03-2013 ",
		 "DATE_CLAIM_CLOSED": "09-15-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kim",
		 "CLAIMANT_FIRST_NAME": "Jane",
		 "CLAIM_NO": "2016-02-24-0456-VP-01",
		 "DURATION": 123,
		 "DISABILITY_START_DATE": "03-04-2016 ",
		 "DATE_CLAIM_CLOSED": "04-19-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kindberg",
		 "CLAIMANT_FIRST_NAME": "Vera",
		 "CLAIM_NO": "2015-03-10-0004-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "03-10-2015 ",
		 "DATE_CLAIM_CLOSED": "07-01-2015 ",
		 "ACTUAL_RTW_DATE": "04-28-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kindberg",
		 "CLAIMANT_FIRST_NAME": "Vera",
		 "CLAIM_NO": "2013-01-05-0510-VP-01",
		 "DURATION": 222,
		 "DISABILITY_START_DATE": "10-24-2012 ",
		 "DATE_CLAIM_CLOSED": "07-11-2013 ",
		 "ACTUAL_RTW_DATE": "06-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kindelberger",
		 "CLAIMANT_FIRST_NAME": "Daniel",
		 "CLAIM_NO": "2015-10-01-0133-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "09-29-2015 ",
		 "DATE_CLAIM_CLOSED": "11-30-2015 ",
		 "ACTUAL_RTW_DATE": "11-30-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "King",
		 "CLAIMANT_FIRST_NAME": "Shannon",
		 "CLAIM_NO": "2016-04-26-0528-VP-01",
		 "DURATION": 66,
		 "DISABILITY_START_DATE": "05-02-2016 ",
		 "DATE_CLAIM_CLOSED": "07-10-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "King",
		 "CLAIMANT_FIRST_NAME": "Jay",
		 "CLAIM_NO": "2015-01-05-0562-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "02-02-2015 ",
		 "DATE_CLAIM_CLOSED": "02-23-2015 ",
		 "ACTUAL_RTW_DATE": "02-23-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "King",
		 "CLAIMANT_FIRST_NAME": "Cecilia",
		 "CLAIM_NO": "2013-01-30-0133-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "01-17-2013 ",
		 "DATE_CLAIM_CLOSED": "07-15-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "King",
		 "CLAIMANT_FIRST_NAME": "Jay",
		 "CLAIM_NO": "2021-06-21-0790-VP-01",
		 "DURATION": 301,
		 "DISABILITY_START_DATE": "06-07-2021 ",
		 "DATE_CLAIM_CLOSED": "05-16-2022 ",
		 "ACTUAL_RTW_DATE": "04-04-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kirkpatrick",
		 "CLAIMANT_FIRST_NAME": "Bruce",
		 "CLAIM_NO": "2019-02-13-0387-VP-01",
		 "DURATION": 184,
		 "DISABILITY_START_DATE": "03-15-2019 ",
		 "DATE_CLAIM_CLOSED": "03-13-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kisina",
		 "CLAIMANT_FIRST_NAME": "Irina",
		 "CLAIM_NO": "2019-01-28-0327-VP-01",
		 "DURATION": 86,
		 "DISABILITY_START_DATE": "01-28-2019 ",
		 "DATE_CLAIM_CLOSED": "05-03-2019 ",
		 "ACTUAL_RTW_DATE": "04-24-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kittisathanon",
		 "CLAIMANT_FIRST_NAME": "Chanin",
		 "CLAIM_NO": "2013-01-05-5823-VP-01",
		 "DURATION": 923,
		 "DISABILITY_START_DATE": "04-26-2011 ",
		 "DATE_CLAIM_CLOSED": "11-07-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Klarich",
		 "CLAIMANT_FIRST_NAME": "Sara",
		 "CLAIM_NO": "2015-01-23-0613-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "02-17-2015 ",
		 "DATE_CLAIM_CLOSED": "04-21-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Klein",
		 "CLAIMANT_FIRST_NAME": "Christie",
		 "CLAIM_NO": "2014-04-15-0246-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "05-12-2014 ",
		 "DATE_CLAIM_CLOSED": "08-21-2014 ",
		 "ACTUAL_RTW_DATE": "07-08-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kleppe",
		 "CLAIMANT_FIRST_NAME": "Owen",
		 "CLAIM_NO": "2022-06-20-0008-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "06-20-2022 ",
		 "DATE_CLAIM_CLOSED": "10-18-2022 ",
		 "ACTUAL_RTW_DATE": "09-12-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Klimek",
		 "CLAIMANT_FIRST_NAME": "Dorothy",
		 "CLAIM_NO": "2018-12-10-0619-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "12-03-2018 ",
		 "DATE_CLAIM_CLOSED": "03-01-2019 ",
		 "ACTUAL_RTW_DATE": "12-25-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Klink",
		 "CLAIMANT_FIRST_NAME": "Gary",
		 "CLAIM_NO": "2021-10-27-1055-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "10-19-2021 ",
		 "DATE_CLAIM_CLOSED": "12-07-2021 ",
		 "ACTUAL_RTW_DATE": "12-06-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Knockel",
		 "CLAIMANT_FIRST_NAME": "Simon",
		 "CLAIM_NO": "2018-01-17-0862-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "01-15-2018 ",
		 "DATE_CLAIM_CLOSED": "02-22-2018 ",
		 "ACTUAL_RTW_DATE": "02-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Knott",
		 "CLAIMANT_FIRST_NAME": "Lisa",
		 "CLAIM_NO": "2017-01-25-0544-VP-01",
		 "DURATION": 39,
		 "DISABILITY_START_DATE": "02-23-2017 ",
		 "DATE_CLAIM_CLOSED": "04-04-2017 ",
		 "ACTUAL_RTW_DATE": "04-03-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Koehn",
		 "CLAIMANT_FIRST_NAME": "Chad",
		 "CLAIM_NO": "2017-10-09-0424-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "10-03-2017 ",
		 "DATE_CLAIM_CLOSED": "11-30-2017 ",
		 "ACTUAL_RTW_DATE": "11-28-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Koenig",
		 "CLAIMANT_FIRST_NAME": "Lisa",
		 "CLAIM_NO": "2015-02-16-0242-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "02-09-2015 ",
		 "DATE_CLAIM_CLOSED": "04-10-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Koenig",
		 "CLAIMANT_FIRST_NAME": "Lisa",
		 "CLAIM_NO": "2013-01-05-0333-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "11-13-2012 ",
		 "DATE_CLAIM_CLOSED": "04-19-2013 ",
		 "ACTUAL_RTW_DATE": "01-08-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kokkinen",
		 "CLAIMANT_FIRST_NAME": "Mary",
		 "CLAIM_NO": "2019-05-31-0594-VP-01",
		 "DURATION": 66,
		 "DISABILITY_START_DATE": "06-24-2019 ",
		 "DATE_CLAIM_CLOSED": "09-06-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kono",
		 "CLAIMANT_FIRST_NAME": "Melissa",
		 "CLAIM_NO": "2021-11-24-0610-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "11-29-2021 ",
		 "DATE_CLAIM_CLOSED": "01-14-2022 ",
		 "ACTUAL_RTW_DATE": "01-02-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kono",
		 "CLAIMANT_FIRST_NAME": "Melissa",
		 "CLAIM_NO": "2021-07-30-0597-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "07-29-2021 ",
		 "DATE_CLAIM_CLOSED": "08-16-2021 ",
		 "ACTUAL_RTW_DATE": "08-09-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kor",
		 "CLAIMANT_FIRST_NAME": "Tieng",
		 "CLAIM_NO": "2021-03-31-0524-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "04-01-2021 ",
		 "DATE_CLAIM_CLOSED": "05-11-2021 ",
		 "ACTUAL_RTW_DATE": "05-06-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kossuth",
		 "CLAIMANT_FIRST_NAME": "Mary Beth",
		 "CLAIM_NO": "2015-07-16-0157-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "07-20-2015 ",
		 "DATE_CLAIM_CLOSED": "08-31-2015 ",
		 "ACTUAL_RTW_DATE": "08-10-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kottar",
		 "CLAIMANT_FIRST_NAME": "Meera",
		 "CLAIM_NO": "2013-03-14-0059-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "03-15-2013 ",
		 "DATE_CLAIM_CLOSED": "07-01-2013 ",
		 "ACTUAL_RTW_DATE": "05-13-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kovacich",
		 "CLAIMANT_FIRST_NAME": "James",
		 "CLAIM_NO": "2016-01-18-0102-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "02-09-2016 ",
		 "DATE_CLAIM_CLOSED": "02-24-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kozuma",
		 "CLAIMANT_FIRST_NAME": "Stephanie",
		 "CLAIM_NO": "2015-07-23-0144-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "08-17-2015 ",
		 "DATE_CLAIM_CLOSED": "11-05-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kozuma",
		 "CLAIMANT_FIRST_NAME": "Stephanie",
		 "CLAIM_NO": "2013-06-17-0191-VP-01",
		 "DURATION": 140,
		 "DISABILITY_START_DATE": "07-01-2013 ",
		 "DATE_CLAIM_CLOSED": "09-27-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kpatakpa",
		 "CLAIMANT_FIRST_NAME": "Karl",
		 "CLAIM_NO": "2017-01-05-0287-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "01-03-2017 ",
		 "DATE_CLAIM_CLOSED": "03-22-2017 ",
		 "ACTUAL_RTW_DATE": "01-12-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Krakosky",
		 "CLAIMANT_FIRST_NAME": "Diane",
		 "CLAIM_NO": "2015-04-01-0339-VP-01",
		 "DURATION": 182,
		 "DISABILITY_START_DATE": "03-26-2015 ",
		 "DATE_CLAIM_CLOSED": "10-30-2015 ",
		 "ACTUAL_RTW_DATE": "09-24-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kramer-Brown",
		 "CLAIMANT_FIRST_NAME": "Pamela",
		 "CLAIM_NO": "2013-04-30-0369-VP-01",
		 "DURATION": 329,
		 "DISABILITY_START_DATE": "04-18-2013 ",
		 "DATE_CLAIM_CLOSED": "03-31-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Krans",
		 "CLAIMANT_FIRST_NAME": "Richard",
		 "CLAIM_NO": "2019-12-12-0754-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "12-17-2019 ",
		 "DATE_CLAIM_CLOSED": "02-13-2020 ",
		 "ACTUAL_RTW_DATE": "02-10-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Krans",
		 "CLAIMANT_FIRST_NAME": "Richard",
		 "CLAIM_NO": "2022-04-04-0863-VP-01",
		 "DURATION": 96,
		 "DISABILITY_START_DATE": "04-06-2022 ",
		 "DATE_CLAIM_CLOSED": "07-14-2022 ",
		 "ACTUAL_RTW_DATE": "07-11-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kray",
		 "CLAIMANT_FIRST_NAME": "Mykela",
		 "CLAIM_NO": "2022-12-17-0008-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "12-06-2022 ",
		 "DATE_CLAIM_CLOSED": "01-31-2023 ",
		 "ACTUAL_RTW_DATE": "12-23-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kray",
		 "CLAIMANT_FIRST_NAME": "Mykela",
		 "CLAIM_NO": "2021-09-15-0008-VP-01",
		 "DURATION": 51,
		 "DISABILITY_START_DATE": "10-01-2021 ",
		 "DATE_CLAIM_CLOSED": "11-29-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Krenz",
		 "CLAIMANT_FIRST_NAME": "Deanne",
		 "CLAIM_NO": "2018-08-02-0272-VP-01",
		 "DURATION": 90,
		 "DISABILITY_START_DATE": "08-28-2018 ",
		 "DATE_CLAIM_CLOSED": "01-25-2019 ",
		 "ACTUAL_RTW_DATE": "11-26-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kreutzer",
		 "CLAIMANT_FIRST_NAME": "Norman",
		 "CLAIM_NO": "2014-09-19-0485-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "09-22-2014 ",
		 "DATE_CLAIM_CLOSED": "11-24-2014 ",
		 "ACTUAL_RTW_DATE": "10-20-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Krieger",
		 "CLAIMANT_FIRST_NAME": "Lance",
		 "CLAIM_NO": "2013-04-11-0224-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "04-08-2013 ",
		 "DATE_CLAIM_CLOSED": "04-22-2013 ",
		 "ACTUAL_RTW_DATE": "04-15-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Krouse",
		 "CLAIMANT_FIRST_NAME": "Karen",
		 "CLAIM_NO": "2021-08-05-0935-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "08-09-2021 ",
		 "DATE_CLAIM_CLOSED": "09-29-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Krouse",
		 "CLAIMANT_FIRST_NAME": "Karen",
		 "CLAIM_NO": "2019-04-01-0295-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "04-12-2019 ",
		 "DATE_CLAIM_CLOSED": "05-28-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Krouse",
		 "CLAIMANT_FIRST_NAME": "Karen",
		 "CLAIM_NO": "2015-11-20-0211-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "12-14-2015 ",
		 "DATE_CLAIM_CLOSED": "02-05-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kuborssy",
		 "CLAIMANT_FIRST_NAME": "Robin",
		 "CLAIM_NO": "2020-02-28-0608-VP-01",
		 "DURATION": 67,
		 "DISABILITY_START_DATE": "03-13-2020 ",
		 "DATE_CLAIM_CLOSED": "05-26-2020 ",
		 "ACTUAL_RTW_DATE": "05-19-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kuborssy",
		 "CLAIMANT_FIRST_NAME": "Robin",
		 "CLAIM_NO": "2019-07-25-0604-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "07-24-2019 ",
		 "DATE_CLAIM_CLOSED": "12-16-2019 ",
		 "ACTUAL_RTW_DATE": "10-23-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kulvicki",
		 "CLAIMANT_FIRST_NAME": "Adam",
		 "CLAIM_NO": "2021-09-24-0683-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "09-23-2021 ",
		 "DATE_CLAIM_CLOSED": "10-11-2021 ",
		 "ACTUAL_RTW_DATE": "10-04-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kumar",
		 "CLAIMANT_FIRST_NAME": "Arvind",
		 "CLAIM_NO": "2022-02-09-1054-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "02-01-2022 ",
		 "DATE_CLAIM_CLOSED": "03-28-2022 ",
		 "ACTUAL_RTW_DATE": "02-10-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kuo",
		 "CLAIMANT_FIRST_NAME": "Hai-Chien",
		 "CLAIM_NO": "2013-06-19-0443-VP-01",
		 "DURATION": 99,
		 "DISABILITY_START_DATE": "06-24-2013 ",
		 "DATE_CLAIM_CLOSED": "10-07-2013 ",
		 "ACTUAL_RTW_DATE": "10-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kuo",
		 "CLAIMANT_FIRST_NAME": "Hai-Chien",
		 "CLAIM_NO": "2013-01-05-7375-VP-01",
		 "DURATION": 133,
		 "DISABILITY_START_DATE": "08-31-2010 ",
		 "DATE_CLAIM_CLOSED": "02-09-2013 ",
		 "ACTUAL_RTW_DATE": "01-11-2011 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kuo",
		 "CLAIMANT_FIRST_NAME": "Hai-Chien",
		 "CLAIM_NO": "2016-08-18-0362-VP-01",
		 "DURATION": 51,
		 "DISABILITY_START_DATE": "08-16-2016 ",
		 "DATE_CLAIM_CLOSED": "01-11-2019 ",
		 "ACTUAL_RTW_DATE": "10-06-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kurtz",
		 "CLAIMANT_FIRST_NAME": "Maribel",
		 "CLAIM_NO": "2022-03-02-0289-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "02-22-2022 ",
		 "DATE_CLAIM_CLOSED": "05-24-2022 ",
		 "ACTUAL_RTW_DATE": "03-14-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kutlu",
		 "CLAIMANT_FIRST_NAME": "Ipek Meric",
		 "CLAIM_NO": "2021-01-11-0980-VP-01",
		 "DURATION": 95,
		 "DISABILITY_START_DATE": "01-07-2021 ",
		 "DATE_CLAIM_CLOSED": "04-15-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kwok",
		 "CLAIMANT_FIRST_NAME": "Karrie",
		 "CLAIM_NO": "2022-04-05-0995-VP-01",
		 "DURATION": 67,
		 "DISABILITY_START_DATE": "03-24-2022 ",
		 "DATE_CLAIM_CLOSED": "05-31-2022 ",
		 "ACTUAL_RTW_DATE": "05-30-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Kwong",
		 "CLAIMANT_FIRST_NAME": "Andrea",
		 "CLAIM_NO": "2018-12-04-0704-VP-01",
		 "DURATION": 75,
		 "DISABILITY_START_DATE": "12-26-2018 ",
		 "DATE_CLAIM_CLOSED": "03-13-2019 ",
		 "ACTUAL_RTW_DATE": "03-11-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "La Day",
		 "CLAIMANT_FIRST_NAME": "Frances",
		 "CLAIM_NO": "2013-01-05-1729-VP-01",
		 "DURATION": 1519,
		 "DISABILITY_START_DATE": "06-04-2012 ",
		 "DATE_CLAIM_CLOSED": "09-28-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lacasse",
		 "CLAIMANT_FIRST_NAME": "Richard",
		 "CLAIM_NO": "2013-09-23-0457-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "10-16-2013 ",
		 "DATE_CLAIM_CLOSED": "12-24-2013 ",
		 "ACTUAL_RTW_DATE": "11-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lacasse",
		 "CLAIMANT_FIRST_NAME": "Richard",
		 "CLAIM_NO": "2013-05-03-0207-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "05-30-2013 ",
		 "DATE_CLAIM_CLOSED": "07-06-2013 ",
		 "ACTUAL_RTW_DATE": "06-17-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lacea",
		 "CLAIMANT_FIRST_NAME": "Jazmine",
		 "CLAIM_NO": "2022-04-06-0959-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "04-25-2022 ",
		 "DATE_CLAIM_CLOSED": "07-11-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lachica",
		 "CLAIMANT_FIRST_NAME": "Sarah",
		 "CLAIM_NO": "2022-05-22-0054-VP-01",
		 "DURATION": 75,
		 "DISABILITY_START_DATE": "05-26-2022 ",
		 "DATE_CLAIM_CLOSED": "08-26-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lacsamana",
		 "CLAIMANT_FIRST_NAME": "Leticia",
		 "CLAIM_NO": "2019-02-19-0557-VP-01",
		 "DURATION": 25,
		 "DISABILITY_START_DATE": "02-14-2019 ",
		 "DATE_CLAIM_CLOSED": "04-01-2019 ",
		 "ACTUAL_RTW_DATE": "03-11-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lad",
		 "CLAIMANT_FIRST_NAME": "Ekta",
		 "CLAIM_NO": "2018-07-18-0129-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "08-10-2018 ",
		 "DATE_CLAIM_CLOSED": "10-08-2018 ",
		 "ACTUAL_RTW_DATE": "09-10-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lai",
		 "CLAIMANT_FIRST_NAME": "Eric",
		 "CLAIM_NO": "2014-03-12-0484-VP-01",
		 "DURATION": 98,
		 "DISABILITY_START_DATE": "03-17-2014 ",
		 "DATE_CLAIM_CLOSED": "06-25-2014 ",
		 "ACTUAL_RTW_DATE": "06-23-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lam",
		 "CLAIMANT_FIRST_NAME": "Helen",
		 "CLAIM_NO": "2022-10-06-0715-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "10-04-2022 ",
		 "DATE_CLAIM_CLOSED": "10-27-2022 ",
		 "ACTUAL_RTW_DATE": "10-24-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lam",
		 "CLAIMANT_FIRST_NAME": "Elsa",
		 "CLAIM_NO": "2019-04-16-0420-VP-01",
		 "DURATION": 182,
		 "DISABILITY_START_DATE": "04-10-2019 ",
		 "DATE_CLAIM_CLOSED": "10-12-2019 ",
		 "ACTUAL_RTW_DATE": "10-09-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lam",
		 "CLAIMANT_FIRST_NAME": "Nhit",
		 "CLAIM_NO": "2022-05-22-0068-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "05-19-2022 ",
		 "DATE_CLAIM_CLOSED": "08-01-2022 ",
		 "ACTUAL_RTW_DATE": "06-06-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lama",
		 "CLAIMANT_FIRST_NAME": "Leagatonu",
		 "CLAIM_NO": "2019-06-12-0672-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "06-11-2019 ",
		 "DATE_CLAIM_CLOSED": "09-06-2019 ",
		 "ACTUAL_RTW_DATE": "08-05-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lama",
		 "CLAIMANT_FIRST_NAME": "Leagatonu",
		 "CLAIM_NO": "2020-06-19-0669-VP-01",
		 "DURATION": 82,
		 "DISABILITY_START_DATE": "07-10-2020 ",
		 "DATE_CLAIM_CLOSED": "10-05-2020 ",
		 "ACTUAL_RTW_DATE": "09-30-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lambert",
		 "CLAIMANT_FIRST_NAME": "Norma",
		 "CLAIM_NO": "2020-05-12-0398-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "05-20-2020 ",
		 "DATE_CLAIM_CLOSED": "07-20-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lambert",
		 "CLAIMANT_FIRST_NAME": "Norma",
		 "CLAIM_NO": "2013-08-09-0305-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "08-12-2013 ",
		 "DATE_CLAIM_CLOSED": "11-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lancina",
		 "CLAIMANT_FIRST_NAME": "Robert",
		 "CLAIM_NO": "2022-04-12-0718-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "04-11-2022 ",
		 "DATE_CLAIM_CLOSED": "07-11-2022 ",
		 "ACTUAL_RTW_DATE": "07-11-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Landaeta",
		 "CLAIMANT_FIRST_NAME": "Antonio",
		 "CLAIM_NO": "2015-04-10-0321-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "05-04-2015 ",
		 "DATE_CLAIM_CLOSED": "08-04-2015 ",
		 "ACTUAL_RTW_DATE": "07-06-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Landaeta",
		 "CLAIMANT_FIRST_NAME": "Antonio",
		 "CLAIM_NO": "2013-01-05-0870-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "09-10-2012 ",
		 "DATE_CLAIM_CLOSED": "01-09-2013 ",
		 "ACTUAL_RTW_DATE": "09-24-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Landaverde",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2020-03-09-0003-VP-01",
		 "DURATION": 154,
		 "DISABILITY_START_DATE": "03-24-2020 ",
		 "DATE_CLAIM_CLOSED": "09-21-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Landeros",
		 "CLAIMANT_FIRST_NAME": "Abigail",
		 "CLAIM_NO": "2022-12-15-0931-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "11-23-2022 ",
		 "DATE_CLAIM_CLOSED": "01-18-2023 ",
		 "ACTUAL_RTW_DATE": "01-17-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Landeros",
		 "CLAIMANT_FIRST_NAME": "Abigail",
		 "CLAIM_NO": "2019-02-26-0646-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "03-12-2019 ",
		 "DATE_CLAIM_CLOSED": "06-10-2019 ",
		 "ACTUAL_RTW_DATE": "06-01-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Landis",
		 "CLAIMANT_FIRST_NAME": "Mary",
		 "CLAIM_NO": "2019-08-19-0794-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "10-04-2019 ",
		 "DATE_CLAIM_CLOSED": "11-19-2019 ",
		 "ACTUAL_RTW_DATE": "11-07-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Landrum",
		 "CLAIMANT_FIRST_NAME": "Nina",
		 "CLAIM_NO": "2018-07-12-0374-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "08-01-2018 ",
		 "DATE_CLAIM_CLOSED": "09-09-2018 ",
		 "ACTUAL_RTW_DATE": "09-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Landrum",
		 "CLAIMANT_FIRST_NAME": "Nina",
		 "CLAIM_NO": "2017-11-01-0389-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "11-10-2017 ",
		 "DATE_CLAIM_CLOSED": "12-27-2017 ",
		 "ACTUAL_RTW_DATE": "11-28-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lane",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2013-07-02-0244-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "07-17-2013 ",
		 "DATE_CLAIM_CLOSED": "09-25-2013 ",
		 "ACTUAL_RTW_DATE": "09-17-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lane",
		 "CLAIMANT_FIRST_NAME": "Tessa",
		 "CLAIM_NO": "2018-01-05-0774-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "01-08-2018 ",
		 "DATE_CLAIM_CLOSED": "01-29-2018 ",
		 "ACTUAL_RTW_DATE": "01-29-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Langarica",
		 "CLAIMANT_FIRST_NAME": "Angelica",
		 "CLAIM_NO": "2020-09-17-0569-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "09-08-2020 ",
		 "DATE_CLAIM_CLOSED": "12-08-2020 ",
		 "ACTUAL_RTW_DATE": "12-01-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lao",
		 "CLAIMANT_FIRST_NAME": "Erica",
		 "CLAIM_NO": "2021-08-19-0929-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "09-07-2021 ",
		 "DATE_CLAIM_CLOSED": "11-09-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lao",
		 "CLAIMANT_FIRST_NAME": "Erica",
		 "CLAIM_NO": "2019-10-21-0743-VP-01",
		 "DURATION": 66,
		 "DISABILITY_START_DATE": "11-18-2019 ",
		 "DATE_CLAIM_CLOSED": "01-23-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lapidus",
		 "CLAIMANT_FIRST_NAME": "Benjamin",
		 "CLAIM_NO": "2022-11-17-0016-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "11-02-2022 ",
		 "DATE_CLAIM_CLOSED": "01-10-2023 ",
		 "ACTUAL_RTW_DATE": "11-15-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lapidus",
		 "CLAIMANT_FIRST_NAME": "Benjamin",
		 "CLAIM_NO": "2022-09-11-0002-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "08-17-2022 ",
		 "DATE_CLAIM_CLOSED": "12-16-2022 ",
		 "ACTUAL_RTW_DATE": "08-30-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lara",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2018-07-25-0191-VP-01",
		 "DURATION": 90,
		 "DISABILITY_START_DATE": "07-25-2018 ",
		 "DATE_CLAIM_CLOSED": "10-29-2018 ",
		 "ACTUAL_RTW_DATE": "10-23-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lara Esquivel Jr",
		 "CLAIMANT_FIRST_NAME": "Saul",
		 "CLAIM_NO": "2017-03-23-0503-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "04-24-2017 ",
		 "DATE_CLAIM_CLOSED": "06-26-2017 ",
		 "ACTUAL_RTW_DATE": "06-23-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lathen",
		 "CLAIMANT_FIRST_NAME": "Nicholas",
		 "CLAIM_NO": "2015-07-17-0444-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "08-13-2015 ",
		 "DATE_CLAIM_CLOSED": "09-15-2015 ",
		 "ACTUAL_RTW_DATE": "09-11-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lau",
		 "CLAIMANT_FIRST_NAME": "Gabrielle",
		 "CLAIM_NO": "2019-03-29-0342-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "04-01-2019 ",
		 "DATE_CLAIM_CLOSED": "05-30-2019 ",
		 "ACTUAL_RTW_DATE": "05-27-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lau",
		 "CLAIMANT_FIRST_NAME": "Gabrielle",
		 "CLAIM_NO": "2021-05-04-0617-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "05-05-2021 ",
		 "DATE_CLAIM_CLOSED": "06-23-2021 ",
		 "ACTUAL_RTW_DATE": "06-23-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lau",
		 "CLAIMANT_FIRST_NAME": "Gabrielle",
		 "CLAIM_NO": "2021-07-14-0727-VP-01",
		 "DURATION": 92,
		 "DISABILITY_START_DATE": "07-14-2021 ",
		 "DATE_CLAIM_CLOSED": "04-08-2022 ",
		 "ACTUAL_RTW_DATE": "10-14-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lau",
		 "CLAIMANT_FIRST_NAME": "Gabrielle",
		 "CLAIM_NO": "2018-02-05-0720-VP-01",
		 "DURATION": 138,
		 "DISABILITY_START_DATE": "01-31-2018 ",
		 "DATE_CLAIM_CLOSED": "06-20-2018 ",
		 "ACTUAL_RTW_DATE": "06-18-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lau",
		 "CLAIMANT_FIRST_NAME": "Gabrielle",
		 "CLAIM_NO": "2019-08-28-0344-VP-01",
		 "DURATION": 36,
		 "DISABILITY_START_DATE": "08-28-2019 ",
		 "DATE_CLAIM_CLOSED": "10-07-2019 ",
		 "ACTUAL_RTW_DATE": "10-03-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lawal",
		 "CLAIMANT_FIRST_NAME": "Adeleke",
		 "CLAIM_NO": "2015-09-02-0185-VP-01",
		 "DURATION": 1098,
		 "DISABILITY_START_DATE": "08-20-2015 ",
		 "DATE_CLAIM_CLOSED": "08-30-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lawrence",
		 "CLAIMANT_FIRST_NAME": "Cathy",
		 "CLAIM_NO": "2020-04-09-0640-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "03-31-2020 ",
		 "DATE_CLAIM_CLOSED": "04-20-2020 ",
		 "ACTUAL_RTW_DATE": "04-13-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lawrence",
		 "CLAIMANT_FIRST_NAME": "Cathy",
		 "CLAIM_NO": "2021-01-09-0021-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "01-11-2021 ",
		 "DATE_CLAIM_CLOSED": "02-01-2021 ",
		 "ACTUAL_RTW_DATE": "01-27-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lawrence",
		 "CLAIMANT_FIRST_NAME": "Cathy",
		 "CLAIM_NO": "2017-05-26-0370-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "05-18-2017 ",
		 "DATE_CLAIM_CLOSED": "06-19-2017 ",
		 "ACTUAL_RTW_DATE": "06-05-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Layoso",
		 "CLAIMANT_FIRST_NAME": "Chester",
		 "CLAIM_NO": "2021-07-30-0017-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "08-02-2021 ",
		 "DATE_CLAIM_CLOSED": "08-23-2021 ",
		 "ACTUAL_RTW_DATE": "08-20-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Layoso",
		 "CLAIMANT_FIRST_NAME": "Chester",
		 "CLAIM_NO": "2017-11-27-0643-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "11-27-2017 ",
		 "DATE_CLAIM_CLOSED": "01-18-2018 ",
		 "ACTUAL_RTW_DATE": "12-07-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Le",
		 "CLAIMANT_FIRST_NAME": "Nga",
		 "CLAIM_NO": "2022-04-07-0469-VP-01",
		 "DURATION": 103,
		 "DISABILITY_START_DATE": "04-06-2022 ",
		 "DATE_CLAIM_CLOSED": "08-31-2022 ",
		 "ACTUAL_RTW_DATE": "07-18-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Le",
		 "CLAIMANT_FIRST_NAME": "Van",
		 "CLAIM_NO": "2021-02-09-0173-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "02-08-2021 ",
		 "DATE_CLAIM_CLOSED": "02-14-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Le",
		 "CLAIMANT_FIRST_NAME": "Daniel",
		 "CLAIM_NO": "2022-05-11-0890-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "05-25-2022 ",
		 "DATE_CLAIM_CLOSED": "06-30-2022 ",
		 "ACTUAL_RTW_DATE": "06-25-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Le",
		 "CLAIMANT_FIRST_NAME": "Nga",
		 "CLAIM_NO": "2015-07-23-0181-VP-01",
		 "DURATION": 1,
		 "DISABILITY_START_DATE": "07-28-2015 ",
		 "DATE_CLAIM_CLOSED": "08-10-2015 ",
		 "ACTUAL_RTW_DATE": "07-29-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Le",
		 "CLAIMANT_FIRST_NAME": "Van",
		 "CLAIM_NO": "2020-07-24-0121-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "07-23-2020 ",
		 "DATE_CLAIM_CLOSED": "07-24-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Le",
		 "CLAIMANT_FIRST_NAME": "Van",
		 "CLAIM_NO": "2020-05-28-0059-VP-01",
		 "DURATION": 118,
		 "DISABILITY_START_DATE": "05-26-2020 ",
		 "DATE_CLAIM_CLOSED": "09-28-2020 ",
		 "ACTUAL_RTW_DATE": "09-21-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Le",
		 "CLAIMANT_FIRST_NAME": "Nga",
		 "CLAIM_NO": "2019-09-05-0233-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "06-12-2019 ",
		 "DATE_CLAIM_CLOSED": "12-03-2019 ",
		 "ACTUAL_RTW_DATE": "08-19-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Le",
		 "CLAIMANT_FIRST_NAME": "Vu",
		 "CLAIM_NO": "2016-02-26-0451-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "03-10-2016 ",
		 "DATE_CLAIM_CLOSED": "04-07-2016 ",
		 "ACTUAL_RTW_DATE": "03-28-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Le Blanc",
		 "CLAIMANT_FIRST_NAME": "Robin",
		 "CLAIM_NO": "2019-12-09-0633-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "12-19-2019 ",
		 "DATE_CLAIM_CLOSED": "12-11-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lea",
		 "CLAIMANT_FIRST_NAME": "Lorna",
		 "CLAIM_NO": "2014-11-05-0264-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "10-28-2014 ",
		 "DATE_CLAIM_CLOSED": "01-08-2015 ",
		 "ACTUAL_RTW_DATE": "11-10-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lea",
		 "CLAIMANT_FIRST_NAME": "Lorna",
		 "CLAIM_NO": "2021-05-12-0919-VP-01",
		 "DURATION": 104,
		 "DISABILITY_START_DATE": "06-15-2021 ",
		 "DATE_CLAIM_CLOSED": "09-29-2021 ",
		 "ACTUAL_RTW_DATE": "09-27-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Leach",
		 "CLAIMANT_FIRST_NAME": "Eva",
		 "CLAIM_NO": "2016-10-24-0088-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "10-19-2016 ",
		 "DATE_CLAIM_CLOSED": "03-29-2017 ",
		 "ACTUAL_RTW_DATE": "12-01-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Leao Bielecki",
		 "CLAIMANT_FIRST_NAME": "Tais",
		 "CLAIM_NO": "2022-04-29-0439-VP-01",
		 "DURATION": 72,
		 "DISABILITY_START_DATE": "05-31-2022 ",
		 "DATE_CLAIM_CLOSED": "08-15-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lebedyanskiy",
		 "CLAIMANT_FIRST_NAME": "Vladimir",
		 "CLAIM_NO": "2022-03-31-0802-VP-01",
		 "DURATION": 83,
		 "DISABILITY_START_DATE": "02-10-2022 ",
		 "DATE_CLAIM_CLOSED": "04-19-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lebrun",
		 "CLAIMANT_FIRST_NAME": "Kurt",
		 "CLAIM_NO": "2014-04-02-6269-VP-01",
		 "DURATION": 94,
		 "DISABILITY_START_DATE": "04-18-2014 ",
		 "DATE_CLAIM_CLOSED": "08-18-2014 ",
		 "ACTUAL_RTW_DATE": "07-21-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lecroy",
		 "CLAIMANT_FIRST_NAME": "Dan",
		 "CLAIM_NO": "2022-05-20-0846-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "07-04-2022 ",
		 "DATE_CLAIM_CLOSED": "08-16-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lecroy",
		 "CLAIMANT_FIRST_NAME": "Dan",
		 "CLAIM_NO": "2020-06-02-0006-VP-01",
		 "DURATION": 61,
		 "DISABILITY_START_DATE": "07-06-2020 ",
		 "DATE_CLAIM_CLOSED": "09-09-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ledesma",
		 "CLAIMANT_FIRST_NAME": "Rosa",
		 "CLAIM_NO": "2021-03-16-0891-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "03-15-2021 ",
		 "DATE_CLAIM_CLOSED": "06-15-2021 ",
		 "ACTUAL_RTW_DATE": "06-14-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ledinh",
		 "CLAIMANT_FIRST_NAME": "Helen",
		 "CLAIM_NO": "2015-01-30-0331-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "02-13-2015 ",
		 "DATE_CLAIM_CLOSED": "04-20-2015 ",
		 "ACTUAL_RTW_DATE": "03-02-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lee",
		 "CLAIMANT_FIRST_NAME": "Dana",
		 "CLAIM_NO": "2014-07-21-0293-VP-01",
		 "DURATION": 53,
		 "DISABILITY_START_DATE": "08-04-2014 ",
		 "DATE_CLAIM_CLOSED": "11-05-2014 ",
		 "ACTUAL_RTW_DATE": "09-26-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lee",
		 "CLAIMANT_FIRST_NAME": "Robert",
		 "CLAIM_NO": "2015-08-03-0188-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "09-01-2015 ",
		 "DATE_CLAIM_CLOSED": "11-11-2015 ",
		 "ACTUAL_RTW_DATE": "10-14-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lee",
		 "CLAIMANT_FIRST_NAME": "Robert",
		 "CLAIM_NO": "2016-02-23-0192-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "02-19-2016 ",
		 "DATE_CLAIM_CLOSED": "12-14-2016 ",
		 "ACTUAL_RTW_DATE": "03-07-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lee",
		 "CLAIMANT_FIRST_NAME": "Ellen",
		 "CLAIM_NO": "2016-01-12-0458-VP-01",
		 "DURATION": 226,
		 "DISABILITY_START_DATE": "01-18-2016 ",
		 "DATE_CLAIM_CLOSED": "10-03-2016 ",
		 "ACTUAL_RTW_DATE": "08-31-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lee",
		 "CLAIMANT_FIRST_NAME": "Tracey",
		 "CLAIM_NO": "2021-02-01-0594-VP-01",
		 "DURATION": 67,
		 "DISABILITY_START_DATE": "02-22-2021 ",
		 "DATE_CLAIM_CLOSED": "04-30-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lee",
		 "CLAIMANT_FIRST_NAME": "William",
		 "CLAIM_NO": "2021-07-30-0746-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "08-23-2021 ",
		 "DATE_CLAIM_CLOSED": "09-15-2021 ",
		 "ACTUAL_RTW_DATE": "09-13-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lee",
		 "CLAIMANT_FIRST_NAME": "Nina",
		 "CLAIM_NO": "2018-07-19-0304-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "07-31-2018 ",
		 "DATE_CLAIM_CLOSED": "08-24-2018 ",
		 "ACTUAL_RTW_DATE": "08-21-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lee",
		 "CLAIMANT_FIRST_NAME": "William",
		 "CLAIM_NO": "2018-08-31-0456-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "09-19-2018 ",
		 "DATE_CLAIM_CLOSED": "10-15-2018 ",
		 "ACTUAL_RTW_DATE": "10-09-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lee",
		 "CLAIMANT_FIRST_NAME": "Phat",
		 "CLAIM_NO": "2014-05-06-0305-VP-01",
		 "DURATION": 23,
		 "DISABILITY_START_DATE": "04-30-2014 ",
		 "DATE_CLAIM_CLOSED": "10-06-2014 ",
		 "ACTUAL_RTW_DATE": "05-23-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lee",
		 "CLAIMANT_FIRST_NAME": "Jordy",
		 "CLAIM_NO": "2021-03-16-0382-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "03-08-2021 ",
		 "DATE_CLAIM_CLOSED": "04-06-2021 ",
		 "ACTUAL_RTW_DATE": "04-05-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lee",
		 "CLAIMANT_FIRST_NAME": "Soraya",
		 "CLAIM_NO": "2021-11-16-1052-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "12-01-2021 ",
		 "DATE_CLAIM_CLOSED": "02-14-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Leelin",
		 "CLAIMANT_FIRST_NAME": "Neva",
		 "CLAIM_NO": "2014-04-08-0124-VP-01",
		 "DURATION": 50,
		 "DISABILITY_START_DATE": "03-31-2014 ",
		 "DATE_CLAIM_CLOSED": "06-03-2014 ",
		 "ACTUAL_RTW_DATE": "05-20-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lefebvre",
		 "CLAIMANT_FIRST_NAME": "Gail",
		 "CLAIM_NO": "2017-02-09-0330-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "01-22-2017 ",
		 "DATE_CLAIM_CLOSED": "03-02-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Legan",
		 "CLAIMANT_FIRST_NAME": "Heather",
		 "CLAIM_NO": "2021-04-29-0453-VP-01",
		 "DURATION": 148,
		 "DISABILITY_START_DATE": "04-28-2021 ",
		 "DATE_CLAIM_CLOSED": "09-30-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Legaspi",
		 "CLAIMANT_FIRST_NAME": "Josephine",
		 "CLAIM_NO": "2013-01-29-0011-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "02-05-2013 ",
		 "DATE_CLAIM_CLOSED": "06-10-2013 ",
		 "ACTUAL_RTW_DATE": "04-23-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lehman",
		 "CLAIMANT_FIRST_NAME": "Diane",
		 "CLAIM_NO": "2015-02-11-0476-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "03-10-2015 ",
		 "DATE_CLAIM_CLOSED": "03-25-2015 ",
		 "ACTUAL_RTW_DATE": "03-23-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lei",
		 "CLAIMANT_FIRST_NAME": "Sheryl",
		 "CLAIM_NO": "2014-08-19-0286-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "09-15-2014 ",
		 "DATE_CLAIM_CLOSED": "12-09-2014 ",
		 "ACTUAL_RTW_DATE": "11-02-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Leidig",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2015-06-24-0205-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "06-30-2015 ",
		 "DATE_CLAIM_CLOSED": "09-01-2015 ",
		 "ACTUAL_RTW_DATE": "07-21-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Leinbach",
		 "CLAIMANT_FIRST_NAME": "Judith",
		 "CLAIM_NO": "2021-04-22-0731-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "04-16-2021 ",
		 "DATE_CLAIM_CLOSED": "05-10-2021 ",
		 "ACTUAL_RTW_DATE": "05-04-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lelenta-Brooks",
		 "CLAIMANT_FIRST_NAME": "Kadiatou",
		 "CLAIM_NO": "2015-02-17-0179-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "02-13-2015 ",
		 "DATE_CLAIM_CLOSED": "03-24-2015 ",
		 "ACTUAL_RTW_DATE": "03-18-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lemos",
		 "CLAIMANT_FIRST_NAME": "Leeann",
		 "CLAIM_NO": "2021-06-11-0780-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "06-21-2021 ",
		 "DATE_CLAIM_CLOSED": "08-12-2021 ",
		 "ACTUAL_RTW_DATE": "08-05-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lennen",
		 "CLAIMANT_FIRST_NAME": "Lindsay",
		 "CLAIM_NO": "2022-10-03-0934-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "09-26-2022 ",
		 "DATE_CLAIM_CLOSED": "12-20-2022 ",
		 "ACTUAL_RTW_DATE": "11-07-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Leon",
		 "CLAIMANT_FIRST_NAME": "Cesar",
		 "CLAIM_NO": "2022-12-19-0768-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "12-13-2022 ",
		 "DATE_CLAIM_CLOSED": "02-03-2023 ",
		 "ACTUAL_RTW_DATE": "01-30-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lepulu",
		 "CLAIMANT_FIRST_NAME": "Filipo",
		 "CLAIM_NO": "2014-01-27-0312-VP-01",
		 "DURATION": 120,
		 "DISABILITY_START_DATE": "01-17-2014 ",
		 "DATE_CLAIM_CLOSED": "05-19-2014 ",
		 "ACTUAL_RTW_DATE": "05-17-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Leschuck",
		 "CLAIMANT_FIRST_NAME": "Mary",
		 "CLAIM_NO": "2016-03-30-0436-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "03-29-2016 ",
		 "DATE_CLAIM_CLOSED": "06-01-2016 ",
		 "ACTUAL_RTW_DATE": "06-01-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Leschuck",
		 "CLAIMANT_FIRST_NAME": "Mary",
		 "CLAIM_NO": "2015-08-18-0128-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "08-17-2015 ",
		 "DATE_CLAIM_CLOSED": "10-08-2015 ",
		 "ACTUAL_RTW_DATE": "10-05-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lester",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2021-05-26-0533-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "05-12-2021 ",
		 "DATE_CLAIM_CLOSED": "08-06-2021 ",
		 "ACTUAL_RTW_DATE": "06-01-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lester",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2021-10-12-0712-VP-01",
		 "DURATION": 50,
		 "DISABILITY_START_DATE": "10-04-2021 ",
		 "DATE_CLAIM_CLOSED": "05-17-2022 ",
		 "ACTUAL_RTW_DATE": "11-23-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lesu'I",
		 "CLAIMANT_FIRST_NAME": "Emily",
		 "CLAIM_NO": "2023-01-26-0912-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "01-12-2023 ",
		 "DATE_CLAIM_CLOSED": "03-03-2023 ",
		 "ACTUAL_RTW_DATE": "03-01-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Leugoud",
		 "CLAIMANT_FIRST_NAME": "Ron",
		 "CLAIM_NO": "2021-04-12-1084-VP-01",
		 "DURATION": 105,
		 "DISABILITY_START_DATE": "04-12-2021 ",
		 "DATE_CLAIM_CLOSED": "01-14-2022 ",
		 "ACTUAL_RTW_DATE": "07-26-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Leung",
		 "CLAIMANT_FIRST_NAME": "Frederick",
		 "CLAIM_NO": "2014-06-17-0537-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "06-19-2014 ",
		 "DATE_CLAIM_CLOSED": "08-28-2014 ",
		 "ACTUAL_RTW_DATE": "08-01-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Levanduski",
		 "CLAIMANT_FIRST_NAME": "Connie",
		 "CLAIM_NO": "2014-04-28-0548-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "05-27-2014 ",
		 "DATE_CLAIM_CLOSED": "10-06-2014 ",
		 "ACTUAL_RTW_DATE": "06-30-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lew",
		 "CLAIMANT_FIRST_NAME": "Tim",
		 "CLAIM_NO": "2022-10-06-0711-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "10-06-2022 ",
		 "DATE_CLAIM_CLOSED": "10-27-2022 ",
		 "ACTUAL_RTW_DATE": "10-24-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lewis",
		 "CLAIMANT_FIRST_NAME": "Evan",
		 "CLAIM_NO": "2022-11-17-0663-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "11-08-2022 ",
		 "DATE_CLAIM_CLOSED": "12-28-2022 ",
		 "ACTUAL_RTW_DATE": "11-17-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lewis",
		 "CLAIMANT_FIRST_NAME": "Christine",
		 "CLAIM_NO": "2019-12-18-0426-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "12-09-2019 ",
		 "DATE_CLAIM_CLOSED": "02-10-2020 ",
		 "ACTUAL_RTW_DATE": "02-03-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lewis",
		 "CLAIMANT_FIRST_NAME": "Daniel",
		 "CLAIM_NO": "2018-11-03-0009-VP-01",
		 "DURATION": 53,
		 "DISABILITY_START_DATE": "11-29-2018 ",
		 "DATE_CLAIM_CLOSED": "01-24-2019 ",
		 "ACTUAL_RTW_DATE": "01-21-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lewis",
		 "CLAIMANT_FIRST_NAME": "Christine",
		 "CLAIM_NO": "2019-08-16-0224-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "08-15-2019 ",
		 "DATE_CLAIM_CLOSED": "10-25-2019 ",
		 "ACTUAL_RTW_DATE": "10-08-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lewis",
		 "CLAIMANT_FIRST_NAME": "Daniel",
		 "CLAIM_NO": "2019-11-14-0732-VP-01",
		 "DURATION": 106,
		 "DISABILITY_START_DATE": "11-19-2019 ",
		 "DATE_CLAIM_CLOSED": "11-20-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lewis",
		 "CLAIMANT_FIRST_NAME": "Rosa",
		 "CLAIM_NO": "2020-07-07-0867-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "07-06-2020 ",
		 "DATE_CLAIM_CLOSED": "07-27-2020 ",
		 "ACTUAL_RTW_DATE": "07-14-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lewis",
		 "CLAIMANT_FIRST_NAME": "Evan",
		 "CLAIM_NO": "2023-01-22-0047-VP-01",
		 "DURATION": 39,
		 "DISABILITY_START_DATE": "01-23-2023 ",
		 "DATE_CLAIM_CLOSED": "03-13-2023 ",
		 "ACTUAL_RTW_DATE": "03-03-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lewis",
		 "CLAIMANT_FIRST_NAME": "Daniel",
		 "CLAIM_NO": "2020-01-07-1122-VP-01",
		 "DURATION": 346,
		 "DISABILITY_START_DATE": "01-21-2020 ",
		 "DATE_CLAIM_CLOSED": "02-03-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lewis",
		 "CLAIMANT_FIRST_NAME": "Daniel",
		 "CLAIM_NO": "2017-09-06-0523-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "08-30-2017 ",
		 "DATE_CLAIM_CLOSED": "09-26-2017 ",
		 "ACTUAL_RTW_DATE": "09-25-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Li",
		 "CLAIMANT_FIRST_NAME": "Xinni",
		 "CLAIM_NO": "2020-10-13-0416-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "10-05-2020 ",
		 "DATE_CLAIM_CLOSED": "11-03-2020 ",
		 "ACTUAL_RTW_DATE": "11-02-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Li",
		 "CLAIMANT_FIRST_NAME": "Wenwen",
		 "CLAIM_NO": "2020-01-06-0440-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "02-03-2020 ",
		 "DATE_CLAIM_CLOSED": "03-30-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Li",
		 "CLAIMANT_FIRST_NAME": "Zhongqiu",
		 "CLAIM_NO": "2023-01-05-1177-VP-01",
		 "DURATION": 104,
		 "DISABILITY_START_DATE": "01-06-2023 ",
		 "DATE_CLAIM_CLOSED": "04-21-2023 ",
		 "ACTUAL_RTW_DATE": "04-20-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Li",
		 "CLAIMANT_FIRST_NAME": "Chieh Mei",
		 "CLAIM_NO": "2018-07-10-0696-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "07-19-2018 ",
		 "DATE_CLAIM_CLOSED": "09-20-2018 ",
		 "ACTUAL_RTW_DATE": "08-31-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Li",
		 "CLAIMANT_FIRST_NAME": "Wenwen",
		 "CLAIM_NO": "2021-08-03-0026-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "08-16-2021 ",
		 "DATE_CLAIM_CLOSED": "10-06-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Liborio-Navarro",
		 "CLAIMANT_FIRST_NAME": "Cassandra",
		 "CLAIM_NO": "2015-12-29-0270-VP-01",
		 "DURATION": 110,
		 "DISABILITY_START_DATE": "01-11-2016 ",
		 "DATE_CLAIM_CLOSED": "04-07-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Libuna",
		 "CLAIMANT_FIRST_NAME": "Riechter",
		 "CLAIM_NO": "2022-03-31-0674-VP-01",
		 "DURATION": 38,
		 "DISABILITY_START_DATE": "03-28-2022 ",
		 "DATE_CLAIM_CLOSED": "05-09-2022 ",
		 "ACTUAL_RTW_DATE": "05-05-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lie-Perez",
		 "CLAIMANT_FIRST_NAME": "Greissy",
		 "CLAIM_NO": "2018-03-16-0579-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "03-19-2018 ",
		 "DATE_CLAIM_CLOSED": "05-14-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Liles Tellez",
		 "CLAIMANT_FIRST_NAME": "Robin",
		 "CLAIM_NO": "2014-10-28-0252-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "10-27-2014 ",
		 "DATE_CLAIM_CLOSED": "02-03-2015 ",
		 "ACTUAL_RTW_DATE": "01-05-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Limones",
		 "CLAIMANT_FIRST_NAME": "Isaac",
		 "CLAIM_NO": "2020-08-31-0589-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "09-08-2020 ",
		 "DATE_CLAIM_CLOSED": "09-21-2020 ",
		 "ACTUAL_RTW_DATE": "09-15-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Limpert",
		 "CLAIMANT_FIRST_NAME": "Dana",
		 "CLAIM_NO": "2015-10-22-0229-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "11-09-2015 ",
		 "DATE_CLAIM_CLOSED": "01-05-2016 ",
		 "ACTUAL_RTW_DATE": "01-04-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Limpert",
		 "CLAIMANT_FIRST_NAME": "Dana",
		 "CLAIM_NO": "2015-02-09-0634-VP-01",
		 "DURATION": 44,
		 "DISABILITY_START_DATE": "02-23-2015 ",
		 "DATE_CLAIM_CLOSED": "04-20-2015 ",
		 "ACTUAL_RTW_DATE": "04-08-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Limprecht",
		 "CLAIMANT_FIRST_NAME": "Sandra",
		 "CLAIM_NO": "2022-10-12-0642-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "10-12-2022 ",
		 "DATE_CLAIM_CLOSED": "12-13-2022 ",
		 "ACTUAL_RTW_DATE": "12-08-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Limprecht",
		 "CLAIMANT_FIRST_NAME": "Sandra",
		 "CLAIM_NO": "2021-06-16-0615-VP-01",
		 "DURATION": 61,
		 "DISABILITY_START_DATE": "06-16-2021 ",
		 "DATE_CLAIM_CLOSED": "08-17-2021 ",
		 "ACTUAL_RTW_DATE": "08-16-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lindemann",
		 "CLAIMANT_FIRST_NAME": "Carol",
		 "CLAIM_NO": "2018-09-09-0026-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "08-30-2018 ",
		 "DATE_CLAIM_CLOSED": "09-19-2018 ",
		 "ACTUAL_RTW_DATE": "09-13-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Liner",
		 "CLAIMANT_FIRST_NAME": "Elizabeth",
		 "CLAIM_NO": "2016-08-09-0271-VP-01",
		 "DURATION": 72,
		 "DISABILITY_START_DATE": "09-06-2016 ",
		 "DATE_CLAIM_CLOSED": "12-06-2016 ",
		 "ACTUAL_RTW_DATE": "11-17-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Liner",
		 "CLAIMANT_FIRST_NAME": "Elizabeth",
		 "CLAIM_NO": "2015-05-29-0422-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "05-26-2015 ",
		 "DATE_CLAIM_CLOSED": "10-22-2015 ",
		 "ACTUAL_RTW_DATE": "06-24-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Linsday-Ambroziak",
		 "CLAIMANT_FIRST_NAME": "Linda",
		 "CLAIM_NO": "2015-01-28-0058-VP-01",
		 "DURATION": 36,
		 "DISABILITY_START_DATE": "01-26-2015 ",
		 "DATE_CLAIM_CLOSED": "03-09-2015 ",
		 "ACTUAL_RTW_DATE": "03-03-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lipata",
		 "CLAIMANT_FIRST_NAME": "Arlene",
		 "CLAIM_NO": "2013-04-16-0184-VP-01",
		 "DURATION": 3,
		 "DISABILITY_START_DATE": "04-23-2013 ",
		 "DATE_CLAIM_CLOSED": "07-06-2013 ",
		 "ACTUAL_RTW_DATE": "04-26-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lipata",
		 "CLAIMANT_FIRST_NAME": "Arlene",
		 "CLAIM_NO": "2019-04-11-0329-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "04-15-2019 ",
		 "DATE_CLAIM_CLOSED": "05-03-2019 ",
		 "ACTUAL_RTW_DATE": "04-29-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lipata",
		 "CLAIMANT_FIRST_NAME": "Arlene",
		 "CLAIM_NO": "2014-02-13-0232-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "02-12-2014 ",
		 "DATE_CLAIM_CLOSED": "06-02-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lipata",
		 "CLAIMANT_FIRST_NAME": "Arlene",
		 "CLAIM_NO": "2016-05-13-0276-VP-01",
		 "DURATION": 176,
		 "DISABILITY_START_DATE": "05-11-2016 ",
		 "DATE_CLAIM_CLOSED": "11-09-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lippincott",
		 "CLAIMANT_FIRST_NAME": "Bonnie",
		 "CLAIM_NO": "2019-12-04-0661-VP-01",
		 "DURATION": 197,
		 "DISABILITY_START_DATE": "07-07-2019 ",
		 "DATE_CLAIM_CLOSED": "12-06-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lippincott",
		 "CLAIMANT_FIRST_NAME": "Bonnie",
		 "CLAIM_NO": "2019-06-27-0659-VP-01",
		 "DURATION": 196,
		 "DISABILITY_START_DATE": "07-08-2019 ",
		 "DATE_CLAIM_CLOSED": "01-28-2020 ",
		 "ACTUAL_RTW_DATE": "01-20-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lipumano",
		 "CLAIMANT_FIRST_NAME": "Lani",
		 "CLAIM_NO": "2013-03-05-0297-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "02-28-2013 ",
		 "DATE_CLAIM_CLOSED": "08-10-2013 ",
		 "ACTUAL_RTW_DATE": "03-12-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lipumano",
		 "CLAIMANT_FIRST_NAME": "Lani",
		 "CLAIM_NO": "2016-05-11-0330-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "05-08-2016 ",
		 "DATE_CLAIM_CLOSED": "05-18-2016 ",
		 "ACTUAL_RTW_DATE": "05-16-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Liu",
		 "CLAIMANT_FIRST_NAME": "Zhiling",
		 "CLAIM_NO": "2021-10-08-0875-VP-01",
		 "DURATION": 178,
		 "DISABILITY_START_DATE": "10-27-2021 ",
		 "DATE_CLAIM_CLOSED": "05-05-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Liu",
		 "CLAIMANT_FIRST_NAME": "Shuwen",
		 "CLAIM_NO": "2013-01-05-1492-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "07-05-2012 ",
		 "DATE_CLAIM_CLOSED": "08-24-2013 ",
		 "ACTUAL_RTW_DATE": "07-18-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Liu",
		 "CLAIMANT_FIRST_NAME": "Shuwen",
		 "CLAIM_NO": "2015-07-14-0492-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "07-28-2015 ",
		 "DATE_CLAIM_CLOSED": "09-25-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Liu",
		 "CLAIMANT_FIRST_NAME": "Huibin",
		 "CLAIM_NO": "2017-01-19-0522-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "02-14-2017 ",
		 "DATE_CLAIM_CLOSED": "04-17-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Liu",
		 "CLAIMANT_FIRST_NAME": "Janet",
		 "CLAIM_NO": "2022-11-08-0636-VP-01",
		 "DURATION": 118,
		 "DISABILITY_START_DATE": "01-03-2023 ",
		 "DATE_CLAIM_CLOSED": "03-15-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lizarraga",
		 "CLAIMANT_FIRST_NAME": "Lorena",
		 "CLAIM_NO": "2020-09-24-0277-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "10-12-2020 ",
		 "DATE_CLAIM_CLOSED": "12-02-2020 ",
		 "ACTUAL_RTW_DATE": "11-23-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lloyd",
		 "CLAIMANT_FIRST_NAME": "Sheila",
		 "CLAIM_NO": "2018-09-26-0458-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "09-18-2018 ",
		 "DATE_CLAIM_CLOSED": "10-03-2018 ",
		 "ACTUAL_RTW_DATE": "10-01-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Loch",
		 "CLAIMANT_FIRST_NAME": "Marcie",
		 "CLAIM_NO": "2013-10-30-0316-VP-01",
		 "DURATION": 448,
		 "DISABILITY_START_DATE": "10-01-2013 ",
		 "DATE_CLAIM_CLOSED": "03-24-2014 ",
		 "ACTUAL_RTW_DATE": "12-23-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Loeffler",
		 "CLAIMANT_FIRST_NAME": "Shirley",
		 "CLAIM_NO": "2016-07-13-0496-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "07-18-2016 ",
		 "DATE_CLAIM_CLOSED": "08-16-2016 ",
		 "ACTUAL_RTW_DATE": "08-15-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Loens",
		 "CLAIMANT_FIRST_NAME": "Edmund",
		 "CLAIM_NO": "2017-04-14-0069-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "04-03-2017 ",
		 "DATE_CLAIM_CLOSED": "05-04-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Loghmanpour",
		 "CLAIMANT_FIRST_NAME": "Natasha",
		 "CLAIM_NO": "2020-08-14-0714-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "09-28-2020 ",
		 "DATE_CLAIM_CLOSED": "11-09-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Long",
		 "CLAIMANT_FIRST_NAME": "Andralynn",
		 "CLAIM_NO": "2022-05-03-0791-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "05-02-2022 ",
		 "DATE_CLAIM_CLOSED": "05-23-2022 ",
		 "ACTUAL_RTW_DATE": "05-17-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Long",
		 "CLAIMANT_FIRST_NAME": "Andralynn",
		 "CLAIM_NO": "2022-06-27-0812-VP-01",
		 "DURATION": 65,
		 "DISABILITY_START_DATE": "06-29-2022 ",
		 "DATE_CLAIM_CLOSED": "09-14-2022 ",
		 "ACTUAL_RTW_DATE": "09-02-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Long",
		 "CLAIMANT_FIRST_NAME": "Casey",
		 "CLAIM_NO": "2022-04-12-0426-VP-01",
		 "DURATION": 85,
		 "DISABILITY_START_DATE": "05-09-2022 ",
		 "DATE_CLAIM_CLOSED": "08-02-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Long",
		 "CLAIMANT_FIRST_NAME": "Carie",
		 "CLAIM_NO": "2014-08-12-0242-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "09-04-2014 ",
		 "DATE_CLAIM_CLOSED": "11-17-2014 ",
		 "ACTUAL_RTW_DATE": "10-20-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Longson",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2022-06-06-0807-VP-01",
		 "DURATION": 112,
		 "DISABILITY_START_DATE": "06-13-2022 ",
		 "DATE_CLAIM_CLOSED": "10-06-2022 ",
		 "ACTUAL_RTW_DATE": "10-03-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Longson",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2013-01-07-0439-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "02-05-2013 ",
		 "DATE_CLAIM_CLOSED": "05-17-2013 ",
		 "ACTUAL_RTW_DATE": "04-08-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lopez",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2020-07-30-0816-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "07-20-2020 ",
		 "DATE_CLAIM_CLOSED": "09-03-2020 ",
		 "ACTUAL_RTW_DATE": "08-31-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lopez",
		 "CLAIMANT_FIRST_NAME": "Hilda",
		 "CLAIM_NO": "2018-12-07-0263-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "12-06-2018 ",
		 "DATE_CLAIM_CLOSED": "01-14-2019 ",
		 "ACTUAL_RTW_DATE": "01-07-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lopez",
		 "CLAIMANT_FIRST_NAME": "Debra",
		 "CLAIM_NO": "2014-07-03-0235-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "07-18-2014 ",
		 "DATE_CLAIM_CLOSED": "12-11-2014 ",
		 "ACTUAL_RTW_DATE": "08-04-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lopez",
		 "CLAIMANT_FIRST_NAME": "Violet",
		 "CLAIM_NO": "2015-01-07-0290-VP-01",
		 "DURATION": 99,
		 "DISABILITY_START_DATE": "01-12-2015 ",
		 "DATE_CLAIM_CLOSED": "04-27-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lopez",
		 "CLAIMANT_FIRST_NAME": "Cesar",
		 "CLAIM_NO": "2022-06-22-0622-VP-01",
		 "DURATION": 30,
		 "DISABILITY_START_DATE": "06-20-2022 ",
		 "DATE_CLAIM_CLOSED": "08-02-2022 ",
		 "ACTUAL_RTW_DATE": "07-20-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lopez",
		 "CLAIMANT_FIRST_NAME": "Rochelle",
		 "CLAIM_NO": "2014-10-01-0332-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "10-13-2014 ",
		 "DATE_CLAIM_CLOSED": "12-15-2014 ",
		 "ACTUAL_RTW_DATE": "12-11-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lopez",
		 "CLAIMANT_FIRST_NAME": "Racel",
		 "CLAIM_NO": "2017-01-26-0005-VP-01",
		 "DURATION": 53,
		 "DISABILITY_START_DATE": "11-14-2016 ",
		 "DATE_CLAIM_CLOSED": "05-09-2017 ",
		 "ACTUAL_RTW_DATE": "01-06-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lopez",
		 "CLAIMANT_FIRST_NAME": "Bianca",
		 "CLAIM_NO": "2021-03-12-0479-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "04-06-2021 ",
		 "DATE_CLAIM_CLOSED": "06-23-2021 ",
		 "ACTUAL_RTW_DATE": "06-15-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lopez",
		 "CLAIMANT_FIRST_NAME": "Ilda",
		 "CLAIM_NO": "2019-01-18-0206-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "01-24-2019 ",
		 "DATE_CLAIM_CLOSED": "02-27-2019 ",
		 "ACTUAL_RTW_DATE": "02-24-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lopez",
		 "CLAIMANT_FIRST_NAME": "Gemma",
		 "CLAIM_NO": "2018-02-26-0831-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "02-27-2018 ",
		 "DATE_CLAIM_CLOSED": "05-30-2018 ",
		 "ACTUAL_RTW_DATE": "03-09-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lopez",
		 "CLAIMANT_FIRST_NAME": "Hilda",
		 "CLAIM_NO": "2019-08-29-0325-VP-01",
		 "DURATION": 140,
		 "DISABILITY_START_DATE": "08-26-2019 ",
		 "DATE_CLAIM_CLOSED": "01-17-2020 ",
		 "ACTUAL_RTW_DATE": "01-13-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lopez",
		 "CLAIMANT_FIRST_NAME": "Debra",
		 "CLAIM_NO": "2014-08-08-0451-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "08-22-2014 ",
		 "DATE_CLAIM_CLOSED": "11-03-2014 ",
		 "ACTUAL_RTW_DATE": "09-08-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lopez De Martinez",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2015-05-06-0309-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "05-05-2015 ",
		 "DATE_CLAIM_CLOSED": "07-13-2015 ",
		 "ACTUAL_RTW_DATE": "06-15-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lopez Garcia",
		 "CLAIMANT_FIRST_NAME": "Beatriz",
		 "CLAIM_NO": "2015-04-09-0260-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "04-09-2015 ",
		 "DATE_CLAIM_CLOSED": "07-06-2015 ",
		 "ACTUAL_RTW_DATE": "04-23-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lopez Garcia",
		 "CLAIMANT_FIRST_NAME": "Beatriz",
		 "CLAIM_NO": "2014-04-24-0423-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "04-21-2014 ",
		 "DATE_CLAIM_CLOSED": "08-14-2014 ",
		 "ACTUAL_RTW_DATE": "05-05-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lopez Garcia",
		 "CLAIMANT_FIRST_NAME": "Beatriz",
		 "CLAIM_NO": "2013-11-18-0212-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "11-11-2013 ",
		 "DATE_CLAIM_CLOSED": "02-03-2014 ",
		 "ACTUAL_RTW_DATE": "12-16-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lopez Garcia",
		 "CLAIMANT_FIRST_NAME": "Beatriz",
		 "CLAIM_NO": "2014-08-15-0391-VP-01",
		 "DURATION": 78,
		 "DISABILITY_START_DATE": "08-15-2014 ",
		 "DATE_CLAIM_CLOSED": "11-05-2014 ",
		 "ACTUAL_RTW_DATE": "11-01-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lopez Garcia",
		 "CLAIMANT_FIRST_NAME": "Beatriz",
		 "CLAIM_NO": "2013-06-28-0013-VP-01",
		 "DURATION": 38,
		 "DISABILITY_START_DATE": "06-24-2013 ",
		 "DATE_CLAIM_CLOSED": "08-24-2013 ",
		 "ACTUAL_RTW_DATE": "08-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lopez Garcia",
		 "CLAIMANT_FIRST_NAME": "Beatriz",
		 "CLAIM_NO": "2013-03-21-0348-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "03-19-2013 ",
		 "DATE_CLAIM_CLOSED": "06-27-2013 ",
		 "ACTUAL_RTW_DATE": "04-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lopez Jr",
		 "CLAIMANT_FIRST_NAME": "Louie",
		 "CLAIM_NO": "2021-05-12-0319-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "05-05-2021 ",
		 "DATE_CLAIM_CLOSED": "07-14-2021 ",
		 "ACTUAL_RTW_DATE": "05-17-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lorenzo-Van Der Meer",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2020-08-17-0534-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "08-03-2020 ",
		 "DATE_CLAIM_CLOSED": "09-08-2020 ",
		 "ACTUAL_RTW_DATE": "08-10-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Losendo",
		 "CLAIMANT_FIRST_NAME": "Recto",
		 "CLAIM_NO": "2016-05-24-0287-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "04-01-2016 ",
		 "DATE_CLAIM_CLOSED": "06-03-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Loucks",
		 "CLAIMANT_FIRST_NAME": "Marie",
		 "CLAIM_NO": "2013-09-10-0389-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "09-19-2013 ",
		 "DATE_CLAIM_CLOSED": "11-06-2013 ",
		 "ACTUAL_RTW_DATE": "10-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lowe",
		 "CLAIMANT_FIRST_NAME": "Kevin",
		 "CLAIM_NO": "2019-06-28-0401-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "07-26-2019 ",
		 "DATE_CLAIM_CLOSED": "08-12-2019 ",
		 "ACTUAL_RTW_DATE": "08-06-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Loza",
		 "CLAIMANT_FIRST_NAME": "Richard",
		 "CLAIM_NO": "2017-01-27-0529-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "01-23-2017 ",
		 "DATE_CLAIM_CLOSED": "04-07-2017 ",
		 "ACTUAL_RTW_DATE": "02-06-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Loza",
		 "CLAIMANT_FIRST_NAME": "Richard",
		 "CLAIM_NO": "2018-02-05-0734-VP-01",
		 "DURATION": 6,
		 "DISABILITY_START_DATE": "02-06-2018 ",
		 "DATE_CLAIM_CLOSED": "03-08-2018 ",
		 "ACTUAL_RTW_DATE": "02-12-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Loza",
		 "CLAIMANT_FIRST_NAME": "Richard",
		 "CLAIM_NO": "2018-05-10-0265-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "05-09-2018 ",
		 "DATE_CLAIM_CLOSED": "06-20-2018 ",
		 "ACTUAL_RTW_DATE": "05-17-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lozande",
		 "CLAIMANT_FIRST_NAME": "Jose Benito",
		 "CLAIM_NO": "2023-01-25-0522-VP-01",
		 "DURATION": 83,
		 "DISABILITY_START_DATE": "01-18-2023 ",
		 "DATE_CLAIM_CLOSED": "04-12-2023 ",
		 "ACTUAL_RTW_DATE": "04-11-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lozoya",
		 "CLAIMANT_FIRST_NAME": "Beatriz",
		 "CLAIM_NO": "2014-01-31-0179-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "01-31-2014 ",
		 "DATE_CLAIM_CLOSED": "04-30-2014 ",
		 "ACTUAL_RTW_DATE": "02-10-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lu",
		 "CLAIMANT_FIRST_NAME": "Bella",
		 "CLAIM_NO": "2018-09-28-0377-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "09-07-2018 ",
		 "DATE_CLAIM_CLOSED": "10-04-2018 ",
		 "ACTUAL_RTW_DATE": "09-14-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lu-Hunter",
		 "CLAIMANT_FIRST_NAME": "Karen",
		 "CLAIM_NO": "2022-12-21-0994-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "01-23-2023 ",
		 "DATE_CLAIM_CLOSED": "04-06-2023 ",
		 "ACTUAL_RTW_DATE": "04-04-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lucas",
		 "CLAIMANT_FIRST_NAME": "Recto",
		 "CLAIM_NO": "2013-01-05-4180-VP-01",
		 "DURATION": 796,
		 "DISABILITY_START_DATE": "09-07-2011 ",
		 "DATE_CLAIM_CLOSED": "11-11-2013 ",
		 "ACTUAL_RTW_DATE": "11-11-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lucas",
		 "CLAIMANT_FIRST_NAME": "Desire",
		 "CLAIM_NO": "2013-01-05-1089-VP-01",
		 "DURATION": 366,
		 "DISABILITY_START_DATE": "08-17-2012 ",
		 "DATE_CLAIM_CLOSED": "12-11-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lucero",
		 "CLAIMANT_FIRST_NAME": "Geanine",
		 "CLAIM_NO": "2014-11-18-0546-VP-01",
		 "DURATION": 80,
		 "DISABILITY_START_DATE": "12-19-2014 ",
		 "DATE_CLAIM_CLOSED": "04-24-2015 ",
		 "ACTUAL_RTW_DATE": "03-09-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Luera",
		 "CLAIMANT_FIRST_NAME": "Rachel",
		 "CLAIM_NO": "2018-09-21-0555-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "11-16-2018 ",
		 "DATE_CLAIM_CLOSED": "12-06-2018 ",
		 "ACTUAL_RTW_DATE": "12-03-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Luevano",
		 "CLAIMANT_FIRST_NAME": "Mandi",
		 "CLAIM_NO": "2014-10-27-0444-VP-01",
		 "DURATION": 385,
		 "DISABILITY_START_DATE": "10-27-2014 ",
		 "DATE_CLAIM_CLOSED": "11-02-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Luna",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2021-04-01-0254-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "04-19-2021 ",
		 "DATE_CLAIM_CLOSED": "06-17-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Luna",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2019-10-15-0732-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "10-15-2019 ",
		 "DATE_CLAIM_CLOSED": "11-22-2019 ",
		 "ACTUAL_RTW_DATE": "10-31-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Luna",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2022-10-07-0925-VP-01",
		 "DURATION": 119,
		 "DISABILITY_START_DATE": "11-28-2022 ",
		 "DATE_CLAIM_CLOSED": "02-04-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lundh",
		 "CLAIMANT_FIRST_NAME": "Tina",
		 "CLAIM_NO": "2014-08-06-0403-VP-01",
		 "DURATION": 44,
		 "DISABILITY_START_DATE": "08-01-2014 ",
		 "DATE_CLAIM_CLOSED": "09-30-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Luo",
		 "CLAIMANT_FIRST_NAME": "Qiong",
		 "CLAIM_NO": "2013-03-27-0430-VP-01",
		 "DURATION": 105,
		 "DISABILITY_START_DATE": "04-22-2013 ",
		 "DATE_CLAIM_CLOSED": "10-01-2013 ",
		 "ACTUAL_RTW_DATE": "08-05-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Lusignan",
		 "CLAIMANT_FIRST_NAME": "Betty",
		 "CLAIM_NO": "2013-01-09-0231-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "01-03-2013 ",
		 "DATE_CLAIM_CLOSED": "05-10-2013 ",
		 "ACTUAL_RTW_DATE": "01-14-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Luxner",
		 "CLAIMANT_FIRST_NAME": "Amy",
		 "CLAIM_NO": "2018-09-14-0550-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "09-17-2018 ",
		 "DATE_CLAIM_CLOSED": "10-01-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Luzzi",
		 "CLAIMANT_FIRST_NAME": "Angela",
		 "CLAIM_NO": "2016-09-29-0473-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "09-27-2016 ",
		 "DATE_CLAIM_CLOSED": "10-02-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Luzzi",
		 "CLAIMANT_FIRST_NAME": "Angela",
		 "CLAIM_NO": "2013-01-05-0606-VP-01",
		 "DURATION": 96,
		 "DISABILITY_START_DATE": "10-03-2012 ",
		 "DATE_CLAIM_CLOSED": "04-06-2013 ",
		 "ACTUAL_RTW_DATE": "01-07-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ly",
		 "CLAIMANT_FIRST_NAME": "Nancy",
		 "CLAIM_NO": "2022-05-10-0930-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "06-01-2022 ",
		 "DATE_CLAIM_CLOSED": "07-20-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ly",
		 "CLAIMANT_FIRST_NAME": "Anh",
		 "CLAIM_NO": "2023-02-03-0872-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "02-15-2023 ",
		 "DATE_CLAIM_CLOSED": "03-21-2023 ",
		 "ACTUAL_RTW_DATE": "03-02-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ma",
		 "CLAIMANT_FIRST_NAME": "Xiao",
		 "CLAIM_NO": "2013-01-05-0046-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "01-21-2013 ",
		 "DATE_CLAIM_CLOSED": "05-08-2013 ",
		 "ACTUAL_RTW_DATE": "03-20-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ma",
		 "CLAIMANT_FIRST_NAME": "Dung",
		 "CLAIM_NO": "2016-01-11-0330-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "02-08-2016 ",
		 "DATE_CLAIM_CLOSED": "02-23-2016 ",
		 "ACTUAL_RTW_DATE": "02-22-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ma",
		 "CLAIMANT_FIRST_NAME": "Samphon",
		 "CLAIM_NO": "2016-01-12-0398-VP-01",
		 "DURATION": 92,
		 "DISABILITY_START_DATE": "01-12-2016 ",
		 "DATE_CLAIM_CLOSED": "04-21-2016 ",
		 "ACTUAL_RTW_DATE": "04-13-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Maccombs",
		 "CLAIMANT_FIRST_NAME": "Jami",
		 "CLAIM_NO": "2020-03-04-0671-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "05-04-2020 ",
		 "DATE_CLAIM_CLOSED": "06-29-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Maccombs",
		 "CLAIMANT_FIRST_NAME": "Jami",
		 "CLAIM_NO": "2017-03-14-0222-VP-01",
		 "DURATION": 65,
		 "DISABILITY_START_DATE": "04-03-2017 ",
		 "DATE_CLAIM_CLOSED": "06-12-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Machen",
		 "CLAIMANT_FIRST_NAME": "Holly",
		 "CLAIM_NO": "2018-06-14-0263-VP-01",
		 "DURATION": 115,
		 "DISABILITY_START_DATE": "07-09-2018 ",
		 "DATE_CLAIM_CLOSED": "11-02-2018 ",
		 "ACTUAL_RTW_DATE": "11-01-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Machen",
		 "CLAIMANT_FIRST_NAME": "Holly",
		 "CLAIM_NO": "2020-11-24-0861-VP-01",
		 "DURATION": 120,
		 "DISABILITY_START_DATE": "11-30-2020 ",
		 "DATE_CLAIM_CLOSED": "04-01-2021 ",
		 "ACTUAL_RTW_DATE": "03-30-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Maciel",
		 "CLAIMANT_FIRST_NAME": "Phillip",
		 "CLAIM_NO": "2016-11-22-0088-VP-02",
		 "DURATION": 89,
		 "DISABILITY_START_DATE": "11-18-2016 ",
		 "DATE_CLAIM_CLOSED": "03-23-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mackechnie",
		 "CLAIMANT_FIRST_NAME": "Kathleen",
		 "CLAIM_NO": "2013-10-25-0175-VP-01",
		 "DURATION": 159,
		 "DISABILITY_START_DATE": "10-25-2013 ",
		 "DATE_CLAIM_CLOSED": "04-07-2014 ",
		 "ACTUAL_RTW_DATE": "04-02-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mackechnie",
		 "CLAIMANT_FIRST_NAME": "Kathleen",
		 "CLAIM_NO": "2021-06-10-0385-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "06-10-2021 ",
		 "DATE_CLAIM_CLOSED": "06-24-2021 ",
		 "ACTUAL_RTW_DATE": "06-23-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mackechnie",
		 "CLAIMANT_FIRST_NAME": "Kathleen",
		 "CLAIM_NO": "2013-03-15-0075-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "03-14-2013 ",
		 "DATE_CLAIM_CLOSED": "06-13-2013 ",
		 "ACTUAL_RTW_DATE": "05-02-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mackechnie",
		 "CLAIMANT_FIRST_NAME": "Kathleen",
		 "CLAIM_NO": "2019-02-26-0393-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "02-25-2019 ",
		 "DATE_CLAIM_CLOSED": "03-27-2019 ",
		 "ACTUAL_RTW_DATE": "03-25-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Madden",
		 "CLAIMANT_FIRST_NAME": "Deborah",
		 "CLAIM_NO": "2022-03-11-0808-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "04-15-2022 ",
		 "DATE_CLAIM_CLOSED": "05-12-2022 ",
		 "ACTUAL_RTW_DATE": "05-09-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Madden",
		 "CLAIMANT_FIRST_NAME": "Deborah",
		 "CLAIM_NO": "2015-05-19-0078-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "05-18-2015 ",
		 "DATE_CLAIM_CLOSED": "08-04-2015 ",
		 "ACTUAL_RTW_DATE": "06-01-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Magana",
		 "CLAIMANT_FIRST_NAME": "Lucy",
		 "CLAIM_NO": "2021-05-10-0750-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "05-10-2021 ",
		 "DATE_CLAIM_CLOSED": "07-12-2021 ",
		 "ACTUAL_RTW_DATE": "07-12-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Magana",
		 "CLAIMANT_FIRST_NAME": "Lucy",
		 "CLAIM_NO": "2019-09-27-0558-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "09-27-2019 ",
		 "DATE_CLAIM_CLOSED": "11-26-2019 ",
		 "ACTUAL_RTW_DATE": "10-14-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Magana",
		 "CLAIMANT_FIRST_NAME": "Lucy",
		 "CLAIM_NO": "2020-03-12-0270-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "03-11-2020 ",
		 "DATE_CLAIM_CLOSED": "04-27-2020 ",
		 "ACTUAL_RTW_DATE": "04-23-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Magana",
		 "CLAIMANT_FIRST_NAME": "Lucy",
		 "CLAIM_NO": "2020-08-14-0497-VP-01",
		 "DURATION": 52,
		 "DISABILITY_START_DATE": "08-14-2020 ",
		 "DATE_CLAIM_CLOSED": "10-06-2020 ",
		 "ACTUAL_RTW_DATE": "10-05-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Maglalang",
		 "CLAIMANT_FIRST_NAME": "Lovelyann",
		 "CLAIM_NO": "2023-04-13-0023-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "04-10-2023 ",
		 "DATE_CLAIM_CLOSED": "04-26-2023 ",
		 "ACTUAL_RTW_DATE": "04-24-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Maglaras",
		 "CLAIMANT_FIRST_NAME": "Angelina",
		 "CLAIM_NO": "2022-08-22-0851-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "09-19-2022 ",
		 "DATE_CLAIM_CLOSED": "11-10-2022 ",
		 "ACTUAL_RTW_DATE": "11-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Magnano",
		 "CLAIMANT_FIRST_NAME": "Michael",
		 "CLAIM_NO": "2021-03-31-0922-VP-01",
		 "DURATION": 61,
		 "DISABILITY_START_DATE": "03-24-2021 ",
		 "DATE_CLAIM_CLOSED": "05-25-2021 ",
		 "ACTUAL_RTW_DATE": "05-24-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Magsino",
		 "CLAIMANT_FIRST_NAME": "Wilfredo",
		 "CLAIM_NO": "2013-01-05-0360-VP-01",
		 "DURATION": 158,
		 "DISABILITY_START_DATE": "11-08-2012 ",
		 "DATE_CLAIM_CLOSED": "04-22-2013 ",
		 "ACTUAL_RTW_DATE": "04-15-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mahabadi",
		 "CLAIMANT_FIRST_NAME": "Sherri",
		 "CLAIM_NO": "2021-07-16-0324-VP-01",
		 "DURATION": 82,
		 "DISABILITY_START_DATE": "07-14-2021 ",
		 "DATE_CLAIM_CLOSED": "10-07-2021 ",
		 "ACTUAL_RTW_DATE": "10-04-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mahboub",
		 "CLAIMANT_FIRST_NAME": "Mahsa",
		 "CLAIM_NO": "2022-04-29-0001-VP-01",
		 "DURATION": 76,
		 "DISABILITY_START_DATE": "04-26-2022 ",
		 "DATE_CLAIM_CLOSED": "08-02-2022 ",
		 "ACTUAL_RTW_DATE": "07-11-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mahmi",
		 "CLAIMANT_FIRST_NAME": "Promila",
		 "CLAIM_NO": "2013-01-18-0335-VP-01",
		 "DURATION": 97,
		 "DISABILITY_START_DATE": "12-18-2012 ",
		 "DATE_CLAIM_CLOSED": "07-10-2013 ",
		 "ACTUAL_RTW_DATE": "03-25-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mahmi",
		 "CLAIMANT_FIRST_NAME": "Promila",
		 "CLAIM_NO": "2013-04-17-0126-VP-01",
		 "DURATION": 392,
		 "DISABILITY_START_DATE": "04-17-2013 ",
		 "DATE_CLAIM_CLOSED": "05-19-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mahmood",
		 "CLAIMANT_FIRST_NAME": "Nuria",
		 "CLAIM_NO": "2021-11-16-0849-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "12-03-2021 ",
		 "DATE_CLAIM_CLOSED": "01-28-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mahmood",
		 "CLAIMANT_FIRST_NAME": "Nuria",
		 "CLAIM_NO": "2022-09-22-0817-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "09-23-2022 ",
		 "DATE_CLAIM_CLOSED": "11-22-2022 ",
		 "ACTUAL_RTW_DATE": "11-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mahoney",
		 "CLAIMANT_FIRST_NAME": "Timothy",
		 "CLAIM_NO": "2021-01-11-0386-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "01-04-2021 ",
		 "DATE_CLAIM_CLOSED": "03-02-2021 ",
		 "ACTUAL_RTW_DATE": "02-08-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mai",
		 "CLAIMANT_FIRST_NAME": "Thu  Hoang",
		 "CLAIM_NO": "2014-07-30-0508-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "08-11-2014 ",
		 "DATE_CLAIM_CLOSED": "12-16-2014 ",
		 "ACTUAL_RTW_DATE": "09-20-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mai",
		 "CLAIMANT_FIRST_NAME": "Thu  Hoang",
		 "CLAIM_NO": "2019-01-25-0652-VP-01",
		 "DURATION": 52,
		 "DISABILITY_START_DATE": "01-25-2019 ",
		 "DATE_CLAIM_CLOSED": "03-20-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Maitoza",
		 "CLAIMANT_FIRST_NAME": "Joel",
		 "CLAIM_NO": "2016-06-15-0458-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "06-15-2016 ",
		 "DATE_CLAIM_CLOSED": "06-30-2016 ",
		 "ACTUAL_RTW_DATE": "06-25-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Major-Bliss",
		 "CLAIMANT_FIRST_NAME": "Patricia",
		 "CLAIM_NO": "2020-01-17-1181-VP-01",
		 "DURATION": 175,
		 "DISABILITY_START_DATE": "02-10-2020 ",
		 "DATE_CLAIM_CLOSED": "01-14-2021 ",
		 "ACTUAL_RTW_DATE": "08-03-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mak",
		 "CLAIMANT_FIRST_NAME": "Ching Man Hazel",
		 "CLAIM_NO": "2020-02-27-0011-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "03-16-2020 ",
		 "DATE_CLAIM_CLOSED": "05-28-2020 ",
		 "ACTUAL_RTW_DATE": "05-25-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mak",
		 "CLAIMANT_FIRST_NAME": "Ching Man Hazel",
		 "CLAIM_NO": "2021-12-08-1242-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "01-04-2022 ",
		 "DATE_CLAIM_CLOSED": "01-19-2023 ",
		 "ACTUAL_RTW_DATE": "01-03-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Makshanoff",
		 "CLAIMANT_FIRST_NAME": "Susan",
		 "CLAIM_NO": "2014-11-14-0368-VP-01",
		 "DURATION": 0,
		 "DISABILITY_START_DATE": "07-24-2013 ",
		 "DATE_CLAIM_CLOSED": "12-16-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Maldonado",
		 "CLAIMANT_FIRST_NAME": "Esteban",
		 "CLAIM_NO": "2013-04-22-0449-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "04-15-2013 ",
		 "DATE_CLAIM_CLOSED": "06-10-2013 ",
		 "ACTUAL_RTW_DATE": "05-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Maldonado",
		 "CLAIMANT_FIRST_NAME": "Stephanie",
		 "CLAIM_NO": "2013-01-05-0164-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "12-20-2012 ",
		 "DATE_CLAIM_CLOSED": "08-06-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Maldonado",
		 "CLAIMANT_FIRST_NAME": "Esteban",
		 "CLAIM_NO": "2021-01-20-0487-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "01-22-2021 ",
		 "DATE_CLAIM_CLOSED": "04-05-2021 ",
		 "ACTUAL_RTW_DATE": "02-09-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Maldonado",
		 "CLAIMANT_FIRST_NAME": "Eva",
		 "CLAIM_NO": "2021-06-17-0586-VP-01",
		 "DURATION": 67,
		 "DISABILITY_START_DATE": "06-28-2021 ",
		 "DATE_CLAIM_CLOSED": "09-07-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Maldonado",
		 "CLAIMANT_FIRST_NAME": "Stephanie",
		 "CLAIM_NO": "2015-08-25-0518-VP-01",
		 "DURATION": 90,
		 "DISABILITY_START_DATE": "09-18-2015 ",
		 "DATE_CLAIM_CLOSED": "11-19-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Malhotra",
		 "CLAIMANT_FIRST_NAME": "Neha",
		 "CLAIM_NO": "2019-01-11-0664-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "01-28-2019 ",
		 "DATE_CLAIM_CLOSED": "04-02-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Malili",
		 "CLAIMANT_FIRST_NAME": "Salamo",
		 "CLAIM_NO": "2020-08-10-0914-VP-01",
		 "DURATION": 117,
		 "DISABILITY_START_DATE": "08-05-2020 ",
		 "DATE_CLAIM_CLOSED": "12-21-2020 ",
		 "ACTUAL_RTW_DATE": "11-30-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Malili",
		 "CLAIMANT_FIRST_NAME": "Salamo",
		 "CLAIM_NO": "2020-11-16-1000-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "11-11-2020 ",
		 "DATE_CLAIM_CLOSED": "11-17-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mamuyac",
		 "CLAIMANT_FIRST_NAME": "Bernard Mel",
		 "CLAIM_NO": "2021-08-05-0728-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "08-02-2021 ",
		 "DATE_CLAIM_CLOSED": "01-07-2022 ",
		 "ACTUAL_RTW_DATE": "09-27-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Manalo",
		 "CLAIMANT_FIRST_NAME": "Liane",
		 "CLAIM_NO": "2020-06-11-0015-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "06-02-2020 ",
		 "DATE_CLAIM_CLOSED": "07-20-2020 ",
		 "ACTUAL_RTW_DATE": "06-22-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mani",
		 "CLAIMANT_FIRST_NAME": "Appay",
		 "CLAIM_NO": "2013-09-23-0553-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "10-03-2013 ",
		 "DATE_CLAIM_CLOSED": "12-24-2013 ",
		 "ACTUAL_RTW_DATE": "10-31-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mani",
		 "CLAIMANT_FIRST_NAME": "Appay",
		 "CLAIM_NO": "2015-09-15-0445-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "09-16-2015 ",
		 "DATE_CLAIM_CLOSED": "10-07-2015 ",
		 "ACTUAL_RTW_DATE": "10-01-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mann",
		 "CLAIMANT_FIRST_NAME": "Surindra",
		 "CLAIM_NO": "2013-01-05-0057-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "12-17-2012 ",
		 "DATE_CLAIM_CLOSED": "05-08-2013 ",
		 "ACTUAL_RTW_DATE": "01-21-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mann",
		 "CLAIMANT_FIRST_NAME": "Timothy",
		 "CLAIM_NO": "2022-09-16-0501-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "09-19-2022 ",
		 "DATE_CLAIM_CLOSED": "11-21-2022 ",
		 "ACTUAL_RTW_DATE": "11-15-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mann",
		 "CLAIMANT_FIRST_NAME": "Surindra",
		 "CLAIM_NO": "2014-08-19-0284-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "08-20-2014 ",
		 "DATE_CLAIM_CLOSED": "12-16-2014 ",
		 "ACTUAL_RTW_DATE": "09-04-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Manokham",
		 "CLAIMANT_FIRST_NAME": "Sounthay",
		 "CLAIM_NO": "2023-01-03-0988-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "12-23-2022 ",
		 "DATE_CLAIM_CLOSED": "02-15-2023 ",
		 "ACTUAL_RTW_DATE": "01-04-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Manthani",
		 "CLAIMANT_FIRST_NAME": "Namratha",
		 "CLAIM_NO": "2015-07-09-0494-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "08-03-2015 ",
		 "DATE_CLAIM_CLOSED": "08-19-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Manuel",
		 "CLAIMANT_FIRST_NAME": "Joanna Marie",
		 "CLAIM_NO": "2021-01-30-0007-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "01-25-2021 ",
		 "DATE_CLAIM_CLOSED": "02-22-2021 ",
		 "ACTUAL_RTW_DATE": "02-03-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Manzo",
		 "CLAIMANT_FIRST_NAME": "Sandra",
		 "CLAIM_NO": "2015-05-27-0300-VP-01",
		 "DURATION": 136,
		 "DISABILITY_START_DATE": "05-27-2015 ",
		 "DATE_CLAIM_CLOSED": "11-11-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Manzo",
		 "CLAIMANT_FIRST_NAME": "Daniel",
		 "CLAIM_NO": "2015-10-21-0400-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "10-15-2015 ",
		 "DATE_CLAIM_CLOSED": "11-24-2015 ",
		 "ACTUAL_RTW_DATE": "11-16-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mao",
		 "CLAIMANT_FIRST_NAME": "Wen",
		 "CLAIM_NO": "2014-10-27-0243-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "12-01-2014 ",
		 "DATE_CLAIM_CLOSED": "05-14-2015 ",
		 "ACTUAL_RTW_DATE": "02-20-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mapes",
		 "CLAIMANT_FIRST_NAME": "Betty",
		 "CLAIM_NO": "2013-01-05-0701-VP-01",
		 "DURATION": 85,
		 "DISABILITY_START_DATE": "10-08-2012 ",
		 "DATE_CLAIM_CLOSED": "01-28-2013 ",
		 "ACTUAL_RTW_DATE": "01-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Marabante",
		 "CLAIMANT_FIRST_NAME": "Teresa",
		 "CLAIM_NO": "2018-07-17-0275-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "07-17-2018 ",
		 "DATE_CLAIM_CLOSED": "08-27-2018 ",
		 "ACTUAL_RTW_DATE": "08-18-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Marcos",
		 "CLAIMANT_FIRST_NAME": "Alfredo",
		 "CLAIM_NO": "2022-10-26-0109-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "11-07-2022 ",
		 "DATE_CLAIM_CLOSED": "01-13-2023 ",
		 "ACTUAL_RTW_DATE": "01-09-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Marcyes",
		 "CLAIMANT_FIRST_NAME": "Sandy",
		 "CLAIM_NO": "2015-09-30-0273-VP-01",
		 "DURATION": 154,
		 "DISABILITY_START_DATE": "11-02-2015 ",
		 "DATE_CLAIM_CLOSED": "02-08-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mares",
		 "CLAIMANT_FIRST_NAME": "Salvador",
		 "CLAIM_NO": "2013-04-19-0226-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "04-15-2013 ",
		 "DATE_CLAIM_CLOSED": "07-01-2013 ",
		 "ACTUAL_RTW_DATE": "05-13-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mares-Jorge",
		 "CLAIMANT_FIRST_NAME": "Ismaela",
		 "CLAIM_NO": "2021-12-31-0219-VP-01",
		 "DURATION": 92,
		 "DISABILITY_START_DATE": "01-06-2022 ",
		 "DATE_CLAIM_CLOSED": "04-13-2022 ",
		 "ACTUAL_RTW_DATE": "04-08-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Marin",
		 "CLAIMANT_FIRST_NAME": "Christopher",
		 "CLAIM_NO": "2021-01-28-0502-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "02-09-2021 ",
		 "DATE_CLAIM_CLOSED": "04-09-2021 ",
		 "ACTUAL_RTW_DATE": "04-06-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Marinas",
		 "CLAIMANT_FIRST_NAME": "Alejo",
		 "CLAIM_NO": "2013-03-22-0009-VP-01",
		 "DURATION": 25,
		 "DISABILITY_START_DATE": "03-22-2013 ",
		 "DATE_CLAIM_CLOSED": "05-07-2013 ",
		 "ACTUAL_RTW_DATE": "04-16-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Marinescu",
		 "CLAIMANT_FIRST_NAME": "Roxana",
		 "CLAIM_NO": "2013-03-29-0156-VP-01",
		 "DURATION": 50,
		 "DISABILITY_START_DATE": "05-01-2013 ",
		 "DATE_CLAIM_CLOSED": "01-15-2014 ",
		 "ACTUAL_RTW_DATE": "06-20-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Marinescu",
		 "CLAIMANT_FIRST_NAME": "Roxana",
		 "CLAIM_NO": "2016-04-21-0172-VP-01",
		 "DURATION": 72,
		 "DISABILITY_START_DATE": "05-25-2016 ",
		 "DATE_CLAIM_CLOSED": "08-09-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Marmolejo",
		 "CLAIMANT_FIRST_NAME": "Julio",
		 "CLAIM_NO": "2015-01-08-0231-VP-01",
		 "DURATION": 30,
		 "DISABILITY_START_DATE": "01-04-2015 ",
		 "DATE_CLAIM_CLOSED": "02-12-2015 ",
		 "ACTUAL_RTW_DATE": "02-03-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Marmolejo",
		 "CLAIMANT_FIRST_NAME": "Juan",
		 "CLAIM_NO": "2021-09-03-0796-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "09-02-2021 ",
		 "DATE_CLAIM_CLOSED": "09-30-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Marmolejo",
		 "CLAIMANT_FIRST_NAME": "Julio",
		 "CLAIM_NO": "2013-06-25-0120-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "06-28-2013 ",
		 "DATE_CLAIM_CLOSED": "09-04-2013 ",
		 "ACTUAL_RTW_DATE": "08-08-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Marostica",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2015-03-06-0144-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "02-18-2015 ",
		 "DATE_CLAIM_CLOSED": "06-09-2015 ",
		 "ACTUAL_RTW_DATE": "03-17-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Marostica",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2016-04-20-0007-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "05-02-2016 ",
		 "DATE_CLAIM_CLOSED": "06-01-2016 ",
		 "ACTUAL_RTW_DATE": "05-30-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Marostica",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2018-01-17-0711-VP-01",
		 "DURATION": 127,
		 "DISABILITY_START_DATE": "01-25-2018 ",
		 "DATE_CLAIM_CLOSED": "06-05-2018 ",
		 "ACTUAL_RTW_DATE": "06-01-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Marquez",
		 "CLAIMANT_FIRST_NAME": "Maribel",
		 "CLAIM_NO": "2019-04-12-0031-VP-01",
		 "DURATION": 169,
		 "DISABILITY_START_DATE": "05-17-2019 ",
		 "DATE_CLAIM_CLOSED": "08-19-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Marroquin",
		 "CLAIMANT_FIRST_NAME": "Kati",
		 "CLAIM_NO": "2019-07-22-0710-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "07-22-2019 ",
		 "DATE_CLAIM_CLOSED": "09-30-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Marroquin",
		 "CLAIMANT_FIRST_NAME": "Kati",
		 "CLAIM_NO": "2017-10-04-0576-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "09-29-2017 ",
		 "DATE_CLAIM_CLOSED": "11-13-2017 ",
		 "ACTUAL_RTW_DATE": "11-11-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Marroquin",
		 "CLAIMANT_FIRST_NAME": "Kati",
		 "CLAIM_NO": "2015-01-19-0234-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "01-27-2015 ",
		 "DATE_CLAIM_CLOSED": "02-24-2015 ",
		 "ACTUAL_RTW_DATE": "02-23-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Marsh",
		 "CLAIMANT_FIRST_NAME": "Tracy",
		 "CLAIM_NO": "2022-05-23-0142-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "06-01-2022 ",
		 "DATE_CLAIM_CLOSED": "07-07-2022 ",
		 "ACTUAL_RTW_DATE": "07-06-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Marshall",
		 "CLAIMANT_FIRST_NAME": "April",
		 "CLAIM_NO": "2013-01-05-0035-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "01-02-2013 ",
		 "DATE_CLAIM_CLOSED": "08-29-2013 ",
		 "ACTUAL_RTW_DATE": "03-20-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Marshall",
		 "CLAIMANT_FIRST_NAME": "April",
		 "CLAIM_NO": "2012-12-14-4522-VP-01",
		 "DURATION": 0,
		 "DISABILITY_START_DATE": "06-29-2007 ",
		 "DATE_CLAIM_CLOSED": "05-16-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martin",
		 "CLAIMANT_FIRST_NAME": "Sally",
		 "CLAIM_NO": "2016-08-22-0193-VP-01",
		 "DURATION": 95,
		 "DISABILITY_START_DATE": "09-01-2016 ",
		 "DATE_CLAIM_CLOSED": "12-08-2016 ",
		 "ACTUAL_RTW_DATE": "12-05-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martin",
		 "CLAIMANT_FIRST_NAME": "Nancy",
		 "CLAIM_NO": "2016-09-23-0392-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "09-16-2016 ",
		 "DATE_CLAIM_CLOSED": "11-21-2016 ",
		 "ACTUAL_RTW_DATE": "11-13-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martin",
		 "CLAIMANT_FIRST_NAME": "Chante",
		 "CLAIM_NO": "2013-09-24-0332-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "10-04-2013 ",
		 "DATE_CLAIM_CLOSED": "02-07-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martineau",
		 "CLAIMANT_FIRST_NAME": "Nicole",
		 "CLAIM_NO": "2022-09-30-0275-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "10-24-2022 ",
		 "DATE_CLAIM_CLOSED": "10-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Steven",
		 "CLAIM_NO": "2020-09-15-0023-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "09-01-2020 ",
		 "DATE_CLAIM_CLOSED": "09-07-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Eva",
		 "CLAIM_NO": "2022-01-09-0011-VP-01",
		 "DURATION": 96,
		 "DISABILITY_START_DATE": "02-03-2022 ",
		 "DATE_CLAIM_CLOSED": "05-11-2022 ",
		 "ACTUAL_RTW_DATE": "05-10-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Johanna",
		 "CLAIM_NO": "2019-07-22-0670-VP-01",
		 "DURATION": 115,
		 "DISABILITY_START_DATE": "07-19-2019 ",
		 "DATE_CLAIM_CLOSED": "04-02-2020 ",
		 "ACTUAL_RTW_DATE": "11-11-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Steven",
		 "CLAIM_NO": "2020-04-10-0302-VP-01",
		 "DURATION": 144,
		 "DISABILITY_START_DATE": "04-10-2020 ",
		 "DATE_CLAIM_CLOSED": "10-12-2020 ",
		 "ACTUAL_RTW_DATE": "09-01-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Angela",
		 "CLAIM_NO": "2021-02-26-0871-VP-01",
		 "DURATION": 129,
		 "DISABILITY_START_DATE": "03-15-2021 ",
		 "DATE_CLAIM_CLOSED": "07-27-2021 ",
		 "ACTUAL_RTW_DATE": "07-22-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Angela",
		 "CLAIM_NO": "2020-01-02-0414-VP-01",
		 "DURATION": 37,
		 "DISABILITY_START_DATE": "12-30-2019 ",
		 "DATE_CLAIM_CLOSED": "02-10-2020 ",
		 "ACTUAL_RTW_DATE": "02-05-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Catherine",
		 "CLAIM_NO": "2019-01-22-0735-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "01-18-2019 ",
		 "DATE_CLAIM_CLOSED": "03-26-2019 ",
		 "ACTUAL_RTW_DATE": "01-28-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Johanna",
		 "CLAIM_NO": "2021-02-04-0789-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "01-27-2021 ",
		 "DATE_CLAIM_CLOSED": "03-12-2021 ",
		 "ACTUAL_RTW_DATE": "02-22-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Karina",
		 "CLAIM_NO": "2021-05-25-0906-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "06-01-2021 ",
		 "DATE_CLAIM_CLOSED": "07-19-2021 ",
		 "ACTUAL_RTW_DATE": "07-13-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Angela",
		 "CLAIM_NO": "2019-03-20-0018-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "03-21-2019 ",
		 "DATE_CLAIM_CLOSED": "06-24-2019 ",
		 "ACTUAL_RTW_DATE": "05-08-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2015-02-27-0128-VP-01",
		 "DURATION": 174,
		 "DISABILITY_START_DATE": "02-24-2015 ",
		 "DATE_CLAIM_CLOSED": "08-18-2015 ",
		 "ACTUAL_RTW_DATE": "08-17-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2021-03-24-0137-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "04-13-2021 ",
		 "DATE_CLAIM_CLOSED": "05-10-2021 ",
		 "ACTUAL_RTW_DATE": "04-28-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Olivia",
		 "CLAIM_NO": "2020-03-30-0311-VP-01",
		 "DURATION": 23,
		 "DISABILITY_START_DATE": "03-17-2020 ",
		 "DATE_CLAIM_CLOSED": "04-09-2020 ",
		 "ACTUAL_RTW_DATE": "04-09-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Dora",
		 "CLAIM_NO": "2013-02-01-0056-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "01-30-2013 ",
		 "DATE_CLAIM_CLOSED": "06-17-2013 ",
		 "ACTUAL_RTW_DATE": "02-11-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Ana",
		 "CLAIM_NO": "2018-10-22-0449-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "11-01-2018 ",
		 "DATE_CLAIM_CLOSED": "01-22-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Juanita",
		 "CLAIM_NO": "2013-01-05-0353-VP-01",
		 "DURATION": 75,
		 "DISABILITY_START_DATE": "11-30-2012 ",
		 "DATE_CLAIM_CLOSED": "03-07-2013 ",
		 "ACTUAL_RTW_DATE": "02-13-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Nicole",
		 "CLAIM_NO": "2020-05-11-0500-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "04-24-2020 ",
		 "DATE_CLAIM_CLOSED": "05-26-2020 ",
		 "ACTUAL_RTW_DATE": "05-04-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Jessi",
		 "CLAIM_NO": "2013-01-05-1351-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "08-27-2012 ",
		 "DATE_CLAIM_CLOSED": "01-15-2014 ",
		 "ACTUAL_RTW_DATE": "10-07-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Ernesto",
		 "CLAIM_NO": "2017-01-23-0154-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "02-08-2017 ",
		 "DATE_CLAIM_CLOSED": "03-28-2017 ",
		 "ACTUAL_RTW_DATE": "03-27-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2022-02-17-0229-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "02-18-2022 ",
		 "DATE_CLAIM_CLOSED": "03-31-2022 ",
		 "ACTUAL_RTW_DATE": "03-22-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Blanca",
		 "CLAIM_NO": "2013-01-05-1978-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "05-01-2012 ",
		 "DATE_CLAIM_CLOSED": "05-07-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Angela",
		 "CLAIM_NO": "2023-01-04-1211-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "02-06-2023 ",
		 "DATE_CLAIM_CLOSED": "03-30-2023 ",
		 "ACTUAL_RTW_DATE": "03-13-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Merlyn",
		 "CLAIM_NO": "2021-12-10-0951-VP-01",
		 "DURATION": 189,
		 "DISABILITY_START_DATE": "12-02-2021 ",
		 "DATE_CLAIM_CLOSED": "06-13-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Francisco",
		 "CLAIM_NO": "2016-03-29-0400-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "03-29-2016 ",
		 "DATE_CLAIM_CLOSED": "05-18-2016 ",
		 "ACTUAL_RTW_DATE": "04-13-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Juanita",
		 "CLAIM_NO": "2016-04-20-0236-VP-01",
		 "DURATION": 153,
		 "DISABILITY_START_DATE": "04-18-2016 ",
		 "DATE_CLAIM_CLOSED": "09-20-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2022-05-17-0549-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "04-29-2022 ",
		 "DATE_CLAIM_CLOSED": "06-03-2022 ",
		 "ACTUAL_RTW_DATE": "05-26-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Martinez",
		 "CLAIMANT_FIRST_NAME": "Olivia",
		 "CLAIM_NO": "2022-01-09-0116-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "01-05-2022 ",
		 "DATE_CLAIM_CLOSED": "01-20-2022 ",
		 "ACTUAL_RTW_DATE": "01-15-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Marzano",
		 "CLAIMANT_FIRST_NAME": "Kelley",
		 "CLAIM_NO": "2022-05-26-0029-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "06-28-2022 ",
		 "DATE_CLAIM_CLOSED": "06-28-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Masterson",
		 "CLAIMANT_FIRST_NAME": "Kathryn",
		 "CLAIM_NO": "2019-12-30-0927-VP-01",
		 "DURATION": 65,
		 "DISABILITY_START_DATE": "01-06-2020 ",
		 "DATE_CLAIM_CLOSED": "03-11-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Masterson",
		 "CLAIMANT_FIRST_NAME": "Kathryn",
		 "CLAIM_NO": "2022-04-07-0274-VP-01",
		 "DURATION": 65,
		 "DISABILITY_START_DATE": "04-25-2022 ",
		 "DATE_CLAIM_CLOSED": "07-05-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Masui",
		 "CLAIMANT_FIRST_NAME": "Steven",
		 "CLAIM_NO": "2013-03-08-0155-VP-01",
		 "DURATION": 101,
		 "DISABILITY_START_DATE": "02-22-2013 ",
		 "DATE_CLAIM_CLOSED": "07-22-2013 ",
		 "ACTUAL_RTW_DATE": "06-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mateo",
		 "CLAIMANT_FIRST_NAME": "Antonio",
		 "CLAIM_NO": "2013-03-06-0376-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "03-04-2013 ",
		 "DATE_CLAIM_CLOSED": "05-07-2013 ",
		 "ACTUAL_RTW_DATE": "03-11-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mateo",
		 "CLAIMANT_FIRST_NAME": "Antonio",
		 "CLAIM_NO": "2013-01-24-0170-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "01-02-2013 ",
		 "DATE_CLAIM_CLOSED": "06-03-2014 ",
		 "ACTUAL_RTW_DATE": "01-15-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mathew",
		 "CLAIMANT_FIRST_NAME": "Sharon",
		 "CLAIM_NO": "2022-04-07-0763-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "05-04-2022 ",
		 "DATE_CLAIM_CLOSED": "07-20-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mathew",
		 "CLAIMANT_FIRST_NAME": "Sharon",
		 "CLAIM_NO": "2018-05-22-0147-VP-01",
		 "DURATION": 116,
		 "DISABILITY_START_DATE": "06-07-2018 ",
		 "DATE_CLAIM_CLOSED": "08-10-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mathie",
		 "CLAIMANT_FIRST_NAME": "Natalie",
		 "CLAIM_NO": "2021-11-03-1033-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "12-20-2021 ",
		 "DATE_CLAIM_CLOSED": "01-20-2022 ",
		 "ACTUAL_RTW_DATE": "01-03-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mathur",
		 "CLAIMANT_FIRST_NAME": "Manashi",
		 "CLAIM_NO": "2019-08-01-0747-VP-01",
		 "DURATION": 365,
		 "DISABILITY_START_DATE": "08-01-2019 ",
		 "DATE_CLAIM_CLOSED": "08-03-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Matlock",
		 "CLAIMANT_FIRST_NAME": "Jill",
		 "CLAIM_NO": "2013-06-24-0399-VP-01",
		 "DURATION": 111,
		 "DISABILITY_START_DATE": "06-25-2013 ",
		 "DATE_CLAIM_CLOSED": "11-04-2013 ",
		 "ACTUAL_RTW_DATE": "10-14-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Matlock",
		 "CLAIMANT_FIRST_NAME": "Jill",
		 "CLAIM_NO": "2014-01-27-0588-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "01-27-2014 ",
		 "DATE_CLAIM_CLOSED": "05-28-2014 ",
		 "ACTUAL_RTW_DATE": "03-10-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Matos",
		 "CLAIMANT_FIRST_NAME": "Gilda",
		 "CLAIM_NO": "2019-09-23-0894-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "11-12-2019 ",
		 "DATE_CLAIM_CLOSED": "01-21-2020 ",
		 "ACTUAL_RTW_DATE": "01-08-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Matsumoto",
		 "CLAIMANT_FIRST_NAME": "Ophelia",
		 "CLAIM_NO": "2020-10-15-1034-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "11-09-2020 ",
		 "DATE_CLAIM_CLOSED": "12-28-2020 ",
		 "ACTUAL_RTW_DATE": "12-25-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Matteson",
		 "CLAIMANT_FIRST_NAME": "Melissa",
		 "CLAIM_NO": "2015-02-18-0430-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "02-09-2015 ",
		 "DATE_CLAIM_CLOSED": "05-05-2015 ",
		 "ACTUAL_RTW_DATE": "02-23-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Matteson",
		 "CLAIMANT_FIRST_NAME": "Melissa",
		 "CLAIM_NO": "2013-09-16-0043-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "09-16-2013 ",
		 "DATE_CLAIM_CLOSED": "01-24-2014 ",
		 "ACTUAL_RTW_DATE": "11-02-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Matthews",
		 "CLAIMANT_FIRST_NAME": "Dorothy",
		 "CLAIM_NO": "2013-08-19-0359-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "09-16-2013 ",
		 "DATE_CLAIM_CLOSED": "11-22-2013 ",
		 "ACTUAL_RTW_DATE": "11-14-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mauger",
		 "CLAIMANT_FIRST_NAME": "Catherine",
		 "CLAIM_NO": "2015-05-05-0264-VP-01",
		 "DURATION": 147,
		 "DISABILITY_START_DATE": "05-04-2015 ",
		 "DATE_CLAIM_CLOSED": "10-01-2015 ",
		 "ACTUAL_RTW_DATE": "09-28-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Maung",
		 "CLAIMANT_FIRST_NAME": "Thantzinaung",
		 "CLAIM_NO": "2017-07-13-0237-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "07-11-2017 ",
		 "DATE_CLAIM_CLOSED": "09-06-2017 ",
		 "ACTUAL_RTW_DATE": "07-24-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Maupin",
		 "CLAIMANT_FIRST_NAME": "Vivian",
		 "CLAIM_NO": "2020-09-14-0377-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "09-14-2020 ",
		 "DATE_CLAIM_CLOSED": "11-04-2020 ",
		 "ACTUAL_RTW_DATE": "11-02-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Maupin",
		 "CLAIMANT_FIRST_NAME": "Vivian",
		 "CLAIM_NO": "2020-11-12-1019-VP-01",
		 "DURATION": 354,
		 "DISABILITY_START_DATE": "11-12-2020 ",
		 "DATE_CLAIM_CLOSED": "12-16-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Maupin",
		 "CLAIMANT_FIRST_NAME": "Vivian",
		 "CLAIM_NO": "2015-05-04-0260-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "05-01-2015 ",
		 "DATE_CLAIM_CLOSED": "08-04-2015 ",
		 "ACTUAL_RTW_DATE": "05-08-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mauricio",
		 "CLAIMANT_FIRST_NAME": "Socorro",
		 "CLAIM_NO": "2022-04-19-0881-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "04-26-2022 ",
		 "DATE_CLAIM_CLOSED": "04-27-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mauricio",
		 "CLAIMANT_FIRST_NAME": "Socorro",
		 "CLAIM_NO": "2018-05-02-0257-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "04-30-2018 ",
		 "DATE_CLAIM_CLOSED": "07-27-2018 ",
		 "ACTUAL_RTW_DATE": "07-02-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mavis",
		 "CLAIMANT_FIRST_NAME": "Richard",
		 "CLAIM_NO": "2013-01-18-0109-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "02-14-2013 ",
		 "DATE_CLAIM_CLOSED": "07-15-2013 ",
		 "ACTUAL_RTW_DATE": "03-05-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mawhorter",
		 "CLAIMANT_FIRST_NAME": "Julie",
		 "CLAIM_NO": "2019-02-18-0079-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "02-18-2019 ",
		 "DATE_CLAIM_CLOSED": "04-17-2019 ",
		 "ACTUAL_RTW_DATE": "03-04-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mawhorter",
		 "CLAIMANT_FIRST_NAME": "Julie",
		 "CLAIM_NO": "2017-12-01-0571-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "11-29-2017 ",
		 "DATE_CLAIM_CLOSED": "01-08-2018 ",
		 "ACTUAL_RTW_DATE": "01-03-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Maxfield",
		 "CLAIMANT_FIRST_NAME": "Mark",
		 "CLAIM_NO": "2022-02-17-0716-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "02-10-2022 ",
		 "DATE_CLAIM_CLOSED": "03-08-2022 ",
		 "ACTUAL_RTW_DATE": "02-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "May",
		 "CLAIMANT_FIRST_NAME": "Stephen",
		 "CLAIM_NO": "2018-06-18-0632-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "06-19-2018 ",
		 "DATE_CLAIM_CLOSED": "07-30-2018 ",
		 "ACTUAL_RTW_DATE": "07-24-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "May",
		 "CLAIMANT_FIRST_NAME": "Stephen",
		 "CLAIM_NO": "2019-05-16-0632-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "05-06-2019 ",
		 "DATE_CLAIM_CLOSED": "07-15-2019 ",
		 "ACTUAL_RTW_DATE": "05-28-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "May",
		 "CLAIMANT_FIRST_NAME": "Amelia",
		 "CLAIM_NO": "2013-08-19-0301-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "09-06-2013 ",
		 "DATE_CLAIM_CLOSED": "11-04-2013 ",
		 "ACTUAL_RTW_DATE": "11-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "May",
		 "CLAIMANT_FIRST_NAME": "Amelia",
		 "CLAIM_NO": "2013-01-05-1132-VP-01",
		 "DURATION": 105,
		 "DISABILITY_START_DATE": "08-06-2012 ",
		 "DATE_CLAIM_CLOSED": "02-17-2013 ",
		 "ACTUAL_RTW_DATE": "11-19-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mayfield",
		 "CLAIMANT_FIRST_NAME": "Camellia",
		 "CLAIM_NO": "2022-10-27-0395-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "10-31-2022 ",
		 "DATE_CLAIM_CLOSED": "12-06-2022 ",
		 "ACTUAL_RTW_DATE": "12-05-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mayrhofer",
		 "CLAIMANT_FIRST_NAME": "Sally",
		 "CLAIM_NO": "2015-01-06-0349-VP-01",
		 "DURATION": 92,
		 "DISABILITY_START_DATE": "12-31-2014 ",
		 "DATE_CLAIM_CLOSED": "04-10-2015 ",
		 "ACTUAL_RTW_DATE": "04-02-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mayrhofer",
		 "CLAIMANT_FIRST_NAME": "Sally",
		 "CLAIM_NO": "2014-05-16-0436-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "05-28-2014 ",
		 "DATE_CLAIM_CLOSED": "07-28-2014 ",
		 "ACTUAL_RTW_DATE": "06-18-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mayrhofer",
		 "CLAIMANT_FIRST_NAME": "Sally",
		 "CLAIM_NO": "2015-01-23-0382-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "02-23-2015 ",
		 "DATE_CLAIM_CLOSED": "06-09-2015 ",
		 "ACTUAL_RTW_DATE": "03-30-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mayrhofer",
		 "CLAIMANT_FIRST_NAME": "Sally",
		 "CLAIM_NO": "2013-07-24-0276-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "07-22-2013 ",
		 "DATE_CLAIM_CLOSED": "09-16-2013 ",
		 "ACTUAL_RTW_DATE": "08-05-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcafee-Collins",
		 "CLAIMANT_FIRST_NAME": "Kelly",
		 "CLAIM_NO": "2019-07-08-0432-VP-01",
		 "DURATION": 135,
		 "DISABILITY_START_DATE": "07-09-2019 ",
		 "DATE_CLAIM_CLOSED": "11-23-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcaloney",
		 "CLAIMANT_FIRST_NAME": "Darla",
		 "CLAIM_NO": "2013-01-05-4879-VP-01",
		 "DURATION": 76,
		 "DISABILITY_START_DATE": "06-23-2011 ",
		 "DATE_CLAIM_CLOSED": "04-03-2013 ",
		 "ACTUAL_RTW_DATE": "09-07-2011 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcbean",
		 "CLAIMANT_FIRST_NAME": "Lynell",
		 "CLAIM_NO": "2014-12-30-0370-VP-01",
		 "DURATION": 80,
		 "DISABILITY_START_DATE": "01-19-2015 ",
		 "DATE_CLAIM_CLOSED": "06-05-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcbean",
		 "CLAIMANT_FIRST_NAME": "Nicole",
		 "CLAIM_NO": "2016-09-29-0235-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "10-25-2016 ",
		 "DATE_CLAIM_CLOSED": "12-27-2016 ",
		 "ACTUAL_RTW_DATE": "12-20-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mccarter",
		 "CLAIMANT_FIRST_NAME": "Kevin",
		 "CLAIM_NO": "2018-05-07-0403-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "05-07-2018 ",
		 "DATE_CLAIM_CLOSED": "07-18-2018 ",
		 "ACTUAL_RTW_DATE": "07-16-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mccarter",
		 "CLAIMANT_FIRST_NAME": "Kevin",
		 "CLAIM_NO": "2018-03-13-0679-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "03-06-2018 ",
		 "DATE_CLAIM_CLOSED": "03-27-2018 ",
		 "ACTUAL_RTW_DATE": "03-21-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mccarthy",
		 "CLAIMANT_FIRST_NAME": "Rossana",
		 "CLAIM_NO": "2020-01-09-0668-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "02-04-2020 ",
		 "DATE_CLAIM_CLOSED": "02-28-2020 ",
		 "ACTUAL_RTW_DATE": "02-26-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcclain",
		 "CLAIMANT_FIRST_NAME": "Nicole",
		 "CLAIM_NO": "2022-08-15-0032-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "08-15-2022 ",
		 "DATE_CLAIM_CLOSED": "09-20-2022 ",
		 "ACTUAL_RTW_DATE": "09-19-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mccoy",
		 "CLAIMANT_FIRST_NAME": "Deonte",
		 "CLAIM_NO": "2018-01-30-0255-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "01-24-2018 ",
		 "DATE_CLAIM_CLOSED": "03-12-2018 ",
		 "ACTUAL_RTW_DATE": "02-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mccoy",
		 "CLAIMANT_FIRST_NAME": "Deonte",
		 "CLAIM_NO": "2021-09-14-0484-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "09-13-2021 ",
		 "DATE_CLAIM_CLOSED": "11-15-2021 ",
		 "ACTUAL_RTW_DATE": "09-27-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mccoy",
		 "CLAIMANT_FIRST_NAME": "Jill",
		 "CLAIM_NO": "2014-07-21-0253-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "08-11-2014 ",
		 "DATE_CLAIM_CLOSED": "10-10-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcculloch",
		 "CLAIMANT_FIRST_NAME": "Cale",
		 "CLAIM_NO": "2021-01-12-0712-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "01-12-2021 ",
		 "DATE_CLAIM_CLOSED": "02-24-2021 ",
		 "ACTUAL_RTW_DATE": "02-08-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mccumsey",
		 "CLAIMANT_FIRST_NAME": "Trixia",
		 "CLAIM_NO": "2016-09-26-0357-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "09-29-2016 ",
		 "DATE_CLAIM_CLOSED": "12-05-2016 ",
		 "ACTUAL_RTW_DATE": "10-17-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcdaniel",
		 "CLAIMANT_FIRST_NAME": "Tesha",
		 "CLAIM_NO": "2016-02-01-0287-VP-01",
		 "DURATION": 67,
		 "DISABILITY_START_DATE": "01-28-2016 ",
		 "DATE_CLAIM_CLOSED": "04-11-2016 ",
		 "ACTUAL_RTW_DATE": "04-04-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcdaniel",
		 "CLAIMANT_FIRST_NAME": "Tesha",
		 "CLAIM_NO": "2015-04-01-0284-VP-01",
		 "DURATION": 127,
		 "DISABILITY_START_DATE": "03-31-2015 ",
		 "DATE_CLAIM_CLOSED": "08-13-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcdaniel",
		 "CLAIMANT_FIRST_NAME": "Tesha",
		 "CLAIM_NO": "2013-06-26-0018-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "06-24-2013 ",
		 "DATE_CLAIM_CLOSED": "09-30-2013 ",
		 "ACTUAL_RTW_DATE": "07-29-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcdaniel",
		 "CLAIMANT_FIRST_NAME": "Scott",
		 "CLAIM_NO": "2014-07-11-0462-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "07-08-2014 ",
		 "DATE_CLAIM_CLOSED": "07-15-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcdaniel",
		 "CLAIMANT_FIRST_NAME": "Tesha",
		 "CLAIM_NO": "2013-08-21-0062-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "08-30-2013 ",
		 "DATE_CLAIM_CLOSED": "10-21-2013 ",
		 "ACTUAL_RTW_DATE": "09-16-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcdonald",
		 "CLAIMANT_FIRST_NAME": "Suzanne",
		 "CLAIM_NO": "2020-06-10-0744-VP-01",
		 "DURATION": 25,
		 "DISABILITY_START_DATE": "08-13-2020 ",
		 "DATE_CLAIM_CLOSED": "09-16-2020 ",
		 "ACTUAL_RTW_DATE": "09-07-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcdonald",
		 "CLAIMANT_FIRST_NAME": "Suzanne",
		 "CLAIM_NO": "2021-10-07-0813-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "10-21-2021 ",
		 "DATE_CLAIM_CLOSED": "11-29-2021 ",
		 "ACTUAL_RTW_DATE": "11-08-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcelroy",
		 "CLAIMANT_FIRST_NAME": "Pamela",
		 "CLAIM_NO": "2018-05-15-0539-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "05-02-2018 ",
		 "DATE_CLAIM_CLOSED": "05-30-2018 ",
		 "ACTUAL_RTW_DATE": "05-10-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcelroy",
		 "CLAIMANT_FIRST_NAME": "Pamela",
		 "CLAIM_NO": "2014-06-04-4490-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "05-23-2014 ",
		 "DATE_CLAIM_CLOSED": "08-28-2014 ",
		 "ACTUAL_RTW_DATE": "06-16-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcelroy",
		 "CLAIMANT_FIRST_NAME": "Pamela",
		 "CLAIM_NO": "2021-08-24-0737-VP-01",
		 "DURATION": 220,
		 "DISABILITY_START_DATE": "08-24-2021 ",
		 "DATE_CLAIM_CLOSED": "04-26-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcelroy",
		 "CLAIMANT_FIRST_NAME": "Pamela",
		 "CLAIM_NO": "2014-10-03-0158-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "09-29-2014 ",
		 "DATE_CLAIM_CLOSED": "12-16-2014 ",
		 "ACTUAL_RTW_DATE": "10-07-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcentush",
		 "CLAIMANT_FIRST_NAME": "Kenneth",
		 "CLAIM_NO": "2017-03-10-0233-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "03-03-2017 ",
		 "DATE_CLAIM_CLOSED": "04-20-2017 ",
		 "ACTUAL_RTW_DATE": "04-19-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcgee",
		 "CLAIMANT_FIRST_NAME": "Deborah",
		 "CLAIM_NO": "2014-02-18-0391-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "03-11-2014 ",
		 "DATE_CLAIM_CLOSED": "05-28-2014 ",
		 "ACTUAL_RTW_DATE": "03-24-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcgee",
		 "CLAIMANT_FIRST_NAME": "Deborah",
		 "CLAIM_NO": "2019-01-15-0516-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "01-22-2019 ",
		 "DATE_CLAIM_CLOSED": "03-06-2019 ",
		 "ACTUAL_RTW_DATE": "03-04-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcgill",
		 "CLAIMANT_FIRST_NAME": "Kiana",
		 "CLAIM_NO": "2013-01-05-2717-VP-01",
		 "DURATION": 632,
		 "DISABILITY_START_DATE": "02-22-2012 ",
		 "DATE_CLAIM_CLOSED": "11-20-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcglone",
		 "CLAIMANT_FIRST_NAME": "Christopher",
		 "CLAIM_NO": "2014-08-29-0406-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "08-27-2014 ",
		 "DATE_CLAIM_CLOSED": "11-04-2014 ",
		 "ACTUAL_RTW_DATE": "10-24-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcintosh",
		 "CLAIMANT_FIRST_NAME": "Nan",
		 "CLAIM_NO": "2021-07-20-0988-VP-01",
		 "DISABILITY_START_DATE": "07-06-2021 ",
		 "DATE_CLAIM_CLOSED": "07-27-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mckeown",
		 "CLAIMANT_FIRST_NAME": "Sheryl",
		 "CLAIM_NO": "2014-11-11-0157-VP-01",
		 "DURATION": 119,
		 "DISABILITY_START_DATE": "11-24-2014 ",
		 "DATE_CLAIM_CLOSED": "04-15-2015 ",
		 "ACTUAL_RTW_DATE": "03-23-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mckeown",
		 "CLAIMANT_FIRST_NAME": "Sheryl",
		 "CLAIM_NO": "2015-07-28-0312-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "08-07-2015 ",
		 "DATE_CLAIM_CLOSED": "10-27-2015 ",
		 "ACTUAL_RTW_DATE": "08-18-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mclachlan",
		 "CLAIMANT_FIRST_NAME": "Elizabeth",
		 "CLAIM_NO": "2015-03-24-0223-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "03-20-2015 ",
		 "DATE_CLAIM_CLOSED": "04-22-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mclachlan",
		 "CLAIMANT_FIRST_NAME": "Elizabeth",
		 "CLAIM_NO": "2015-01-19-0224-VP-01",
		 "DURATION": 103,
		 "DISABILITY_START_DATE": "01-28-2015 ",
		 "DATE_CLAIM_CLOSED": "05-20-2015 ",
		 "ACTUAL_RTW_DATE": "05-11-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mclemore",
		 "CLAIMANT_FIRST_NAME": "Lori",
		 "CLAIM_NO": "2021-09-07-1138-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "08-30-2021 ",
		 "DATE_CLAIM_CLOSED": "10-22-2021 ",
		 "ACTUAL_RTW_DATE": "09-13-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mclemore",
		 "CLAIMANT_FIRST_NAME": "Lori",
		 "CLAIM_NO": "2020-12-10-1181-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "12-03-2020 ",
		 "DATE_CLAIM_CLOSED": "03-03-2021 ",
		 "ACTUAL_RTW_DATE": "12-14-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcmichael",
		 "CLAIMANT_FIRST_NAME": "Joe",
		 "CLAIM_NO": "2017-10-30-0660-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "10-30-2017 ",
		 "DATE_CLAIM_CLOSED": "12-05-2017 ",
		 "ACTUAL_RTW_DATE": "12-01-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcmichael",
		 "CLAIMANT_FIRST_NAME": "Joe",
		 "CLAIM_NO": "2022-10-06-0716-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "09-19-2022 ",
		 "DATE_CLAIM_CLOSED": "10-21-2022 ",
		 "ACTUAL_RTW_DATE": "10-17-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcniven",
		 "CLAIMANT_FIRST_NAME": "Ann",
		 "CLAIM_NO": "2016-11-29-0284-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "11-29-2016 ",
		 "DATE_CLAIM_CLOSED": "01-30-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcniven",
		 "CLAIMANT_FIRST_NAME": "Anne",
		 "CLAIM_NO": "2013-09-16-0310-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "09-30-2013 ",
		 "DATE_CLAIM_CLOSED": "01-23-2014 ",
		 "ACTUAL_RTW_DATE": "11-11-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcpherson Reyes",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2013-01-21-0089-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "02-18-2013 ",
		 "DATE_CLAIM_CLOSED": "06-04-2013 ",
		 "ACTUAL_RTW_DATE": "04-23-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mcwilliams",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2014-03-05-0561-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "03-25-2014 ",
		 "DATE_CLAIM_CLOSED": "12-17-2014 ",
		 "ACTUAL_RTW_DATE": "05-12-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mecano",
		 "CLAIMANT_FIRST_NAME": "Joseph",
		 "CLAIM_NO": "2021-02-22-0703-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "04-07-2021 ",
		 "DATE_CLAIM_CLOSED": "05-25-2021 ",
		 "ACTUAL_RTW_DATE": "05-24-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mechem",
		 "CLAIMANT_FIRST_NAME": "Kristine",
		 "CLAIM_NO": "2014-06-19-0354-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "06-19-2014 ",
		 "DATE_CLAIM_CLOSED": "12-02-2014 ",
		 "ACTUAL_RTW_DATE": "07-07-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Medina",
		 "CLAIMANT_FIRST_NAME": "Sandra",
		 "CLAIM_NO": "2013-08-01-0371-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "07-22-2013 ",
		 "DATE_CLAIM_CLOSED": "09-23-2013 ",
		 "ACTUAL_RTW_DATE": "09-05-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Medina",
		 "CLAIMANT_FIRST_NAME": "Sandra",
		 "CLAIM_NO": "2020-08-05-0478-VP-01",
		 "DURATION": 137,
		 "DISABILITY_START_DATE": "08-17-2020 ",
		 "DATE_CLAIM_CLOSED": "02-01-2022 ",
		 "ACTUAL_RTW_DATE": "01-01-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Medrano",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2013-01-05-1225-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "08-03-2012 ",
		 "DATE_CLAIM_CLOSED": "08-05-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Meehan",
		 "CLAIMANT_FIRST_NAME": "Simeon",
		 "CLAIM_NO": "2017-08-30-0009-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "08-23-2017 ",
		 "DATE_CLAIM_CLOSED": "09-14-2017 ",
		 "ACTUAL_RTW_DATE": "09-11-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Meehan",
		 "CLAIMANT_FIRST_NAME": "Simeon",
		 "CLAIM_NO": "2020-04-22-0282-VP-01",
		 "DURATION": 352,
		 "DISABILITY_START_DATE": "04-20-2020 ",
		 "DATE_CLAIM_CLOSED": "04-12-2021 ",
		 "ACTUAL_RTW_DATE": "04-07-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Meeker",
		 "CLAIMANT_FIRST_NAME": "Dan",
		 "CLAIM_NO": "2019-09-11-0238-VP-01",
		 "DURATION": 186,
		 "DISABILITY_START_DATE": "09-16-2019 ",
		 "DATE_CLAIM_CLOSED": "04-16-2020 ",
		 "ACTUAL_RTW_DATE": "03-20-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Meesai",
		 "CLAIMANT_FIRST_NAME": "Vanessa",
		 "CLAIM_NO": "2022-01-07-1440-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "02-01-2022 ",
		 "DATE_CLAIM_CLOSED": "03-28-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mehta",
		 "CLAIMANT_FIRST_NAME": "Seema",
		 "CLAIM_NO": "2014-03-17-0196-VP-01",
		 "DURATION": 110,
		 "DISABILITY_START_DATE": "04-15-2014 ",
		 "DATE_CLAIM_CLOSED": "10-07-2014 ",
		 "ACTUAL_RTW_DATE": "08-03-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mehta",
		 "CLAIMANT_FIRST_NAME": "Seema",
		 "CLAIM_NO": "2021-10-27-0816-VP-01",
		 "DURATION": 137,
		 "DISABILITY_START_DATE": "11-15-2021 ",
		 "DATE_CLAIM_CLOSED": "11-15-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mehta",
		 "CLAIMANT_FIRST_NAME": "Seema",
		 "CLAIM_NO": "2020-07-30-0484-VP-01",
		 "DURATION": 118,
		 "DISABILITY_START_DATE": "08-04-2020 ",
		 "DATE_CLAIM_CLOSED": "12-01-2020 ",
		 "ACTUAL_RTW_DATE": "11-30-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mehta",
		 "CLAIMANT_FIRST_NAME": "Seema",
		 "CLAIM_NO": "2018-11-26-0375-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "11-26-2018 ",
		 "DATE_CLAIM_CLOSED": "01-09-2019 ",
		 "ACTUAL_RTW_DATE": "12-10-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mehta",
		 "CLAIMANT_FIRST_NAME": "Seema",
		 "CLAIM_NO": "2019-02-13-0318-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "03-08-2019 ",
		 "DATE_CLAIM_CLOSED": "05-13-2019 ",
		 "ACTUAL_RTW_DATE": "05-06-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mehta",
		 "CLAIMANT_FIRST_NAME": "Seema",
		 "CLAIM_NO": "2022-01-04-1276-VP-01",
		 "DURATION": 98,
		 "DISABILITY_START_DATE": "01-10-2022 ",
		 "DATE_CLAIM_CLOSED": "04-22-2022 ",
		 "ACTUAL_RTW_DATE": "04-18-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mejia",
		 "CLAIMANT_FIRST_NAME": "Hugo",
		 "CLAIM_NO": "2019-08-05-0263-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "08-28-2019 ",
		 "DATE_CLAIM_CLOSED": "09-17-2019 ",
		 "ACTUAL_RTW_DATE": "09-12-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mejorada",
		 "CLAIMANT_FIRST_NAME": "Mary",
		 "CLAIM_NO": "2016-01-29-0444-VP-01",
		 "DURATION": 75,
		 "DISABILITY_START_DATE": "02-29-2016 ",
		 "DATE_CLAIM_CLOSED": "05-16-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Meksavanh",
		 "CLAIMANT_FIRST_NAME": "Meck",
		 "CLAIM_NO": "2018-04-02-0540-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "04-02-2018 ",
		 "DATE_CLAIM_CLOSED": "05-03-2018 ",
		 "ACTUAL_RTW_DATE": "04-26-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Meksavanh",
		 "CLAIMANT_FIRST_NAME": "Meck",
		 "CLAIM_NO": "2019-04-05-0063-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "04-01-2019 ",
		 "DATE_CLAIM_CLOSED": "04-17-2019 ",
		 "ACTUAL_RTW_DATE": "04-15-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Meksavanh",
		 "CLAIMANT_FIRST_NAME": "Meck",
		 "CLAIM_NO": "2018-07-30-0671-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "08-01-2018 ",
		 "DATE_CLAIM_CLOSED": "09-04-2018 ",
		 "ACTUAL_RTW_DATE": "09-03-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Melchor",
		 "CLAIMANT_FIRST_NAME": "Beida",
		 "CLAIM_NO": "2019-03-07-0649-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "02-19-2019 ",
		 "DATE_CLAIM_CLOSED": "05-23-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Melendrez",
		 "CLAIMANT_FIRST_NAME": "Juanita",
		 "CLAIM_NO": "2013-02-05-0486-VP-01",
		 "DURATION": 105,
		 "DISABILITY_START_DATE": "02-04-2013 ",
		 "DATE_CLAIM_CLOSED": "05-22-2013 ",
		 "ACTUAL_RTW_DATE": "05-20-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Melendrez",
		 "CLAIMANT_FIRST_NAME": "Juanita",
		 "CLAIM_NO": "2013-01-05-0539-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "10-23-2012 ",
		 "DATE_CLAIM_CLOSED": "02-17-2013 ",
		 "ACTUAL_RTW_DATE": "11-19-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Melikian",
		 "CLAIMANT_FIRST_NAME": "Rosanne",
		 "CLAIM_NO": "2016-01-25-0291-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "02-08-2016 ",
		 "DATE_CLAIM_CLOSED": "04-04-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Melikyan",
		 "CLAIMANT_FIRST_NAME": "Davit",
		 "CLAIM_NO": "2022-07-19-0943-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "07-22-2022 ",
		 "DATE_CLAIM_CLOSED": "09-30-2022 ",
		 "ACTUAL_RTW_DATE": "09-23-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Membreno",
		 "CLAIMANT_FIRST_NAME": "Ana",
		 "CLAIM_NO": "2019-06-27-0611-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "07-02-2019 ",
		 "DATE_CLAIM_CLOSED": "07-09-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Membreno",
		 "CLAIMANT_FIRST_NAME": "Ana",
		 "CLAIM_NO": "2020-09-21-0763-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "09-09-2020 ",
		 "DATE_CLAIM_CLOSED": "09-30-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mena",
		 "CLAIMANT_FIRST_NAME": "Erin",
		 "CLAIM_NO": "2022-02-01-0561-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "02-23-2022 ",
		 "DATE_CLAIM_CLOSED": "05-23-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mendez",
		 "CLAIMANT_FIRST_NAME": "Felisa",
		 "CLAIM_NO": "2014-05-20-0331-VP-01",
		 "DURATION": 124,
		 "DISABILITY_START_DATE": "05-16-2014 ",
		 "DATE_CLAIM_CLOSED": "09-22-2014 ",
		 "ACTUAL_RTW_DATE": "09-17-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mendez",
		 "CLAIMANT_FIRST_NAME": "Edward",
		 "CLAIM_NO": "2014-01-10-0457-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "01-10-2014 ",
		 "DATE_CLAIM_CLOSED": "03-17-2014 ",
		 "ACTUAL_RTW_DATE": "02-24-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mendez",
		 "CLAIMANT_FIRST_NAME": "Edward",
		 "CLAIM_NO": "2015-03-05-0253-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "02-24-2015 ",
		 "DATE_CLAIM_CLOSED": "05-13-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mendez",
		 "CLAIMANT_FIRST_NAME": "Monica",
		 "CLAIM_NO": "2018-06-14-0378-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "07-09-2018 ",
		 "DATE_CLAIM_CLOSED": "09-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mendez",
		 "CLAIMANT_FIRST_NAME": "George",
		 "CLAIM_NO": "2016-10-26-0500-VP-01",
		 "DURATION": 6,
		 "DISABILITY_START_DATE": "10-26-2016 ",
		 "DATE_CLAIM_CLOSED": "11-11-2016 ",
		 "ACTUAL_RTW_DATE": "11-01-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mendez",
		 "CLAIMANT_FIRST_NAME": "Felisa",
		 "CLAIM_NO": "2015-05-14-0159-VP-01",
		 "DURATION": 181,
		 "DISABILITY_START_DATE": "05-14-2015 ",
		 "DATE_CLAIM_CLOSED": "11-16-2015 ",
		 "ACTUAL_RTW_DATE": "11-11-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mendibles",
		 "CLAIMANT_FIRST_NAME": "Joseph",
		 "CLAIM_NO": "2018-11-19-0308-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "11-26-2018 ",
		 "DATE_CLAIM_CLOSED": "01-15-2019 ",
		 "ACTUAL_RTW_DATE": "01-07-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mendoza",
		 "CLAIMANT_FIRST_NAME": "Andrea",
		 "CLAIM_NO": "2021-03-02-0809-VP-01",
		 "DURATION": 74,
		 "DISABILITY_START_DATE": "03-22-2021 ",
		 "DATE_CLAIM_CLOSED": "06-04-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mendoza",
		 "CLAIMANT_FIRST_NAME": "Sandra",
		 "CLAIM_NO": "2019-10-09-0762-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "10-14-2019 ",
		 "DATE_CLAIM_CLOSED": "11-12-2019 ",
		 "ACTUAL_RTW_DATE": "10-28-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mendoza",
		 "CLAIMANT_FIRST_NAME": "Sandra",
		 "CLAIM_NO": "2022-08-24-0402-VP-01",
		 "DURATION": 66,
		 "DISABILITY_START_DATE": "10-10-2022 ",
		 "DATE_CLAIM_CLOSED": "03-22-2023 ",
		 "ACTUAL_RTW_DATE": "12-15-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mendoza",
		 "CLAIMANT_FIRST_NAME": "Anna",
		 "CLAIM_NO": "2020-10-06-0036-VP-01",
		 "DURATION": 0,
		 "DISABILITY_START_DATE": "10-05-2020 ",
		 "DATE_CLAIM_CLOSED": "12-08-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mendoza",
		 "CLAIMANT_FIRST_NAME": "Andrea",
		 "CLAIM_NO": "2022-06-29-0745-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "08-12-2022 ",
		 "DATE_CLAIM_CLOSED": "09-16-2022 ",
		 "ACTUAL_RTW_DATE": "08-29-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mendoza",
		 "CLAIMANT_FIRST_NAME": "Erica",
		 "CLAIM_NO": "2021-04-22-0913-VP-01",
		 "DURATION": -1,
		 "DISABILITY_START_DATE": "04-16-2021 ",
		 "DATE_CLAIM_CLOSED": "04-20-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mendoza",
		 "CLAIMANT_FIRST_NAME": "Sandra",
		 "CLAIM_NO": "2020-07-30-0731-VP-01",
		 "DURATION": 101,
		 "DISABILITY_START_DATE": "08-24-2020 ",
		 "DATE_CLAIM_CLOSED": "12-08-2020 ",
		 "ACTUAL_RTW_DATE": "12-03-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mennig",
		 "CLAIMANT_FIRST_NAME": "Robert",
		 "CLAIM_NO": "2014-07-21-0554-VP-01",
		 "DURATION": 105,
		 "DISABILITY_START_DATE": "07-14-2014 ",
		 "DATE_CLAIM_CLOSED": "10-28-2014 ",
		 "ACTUAL_RTW_DATE": "10-27-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mercado",
		 "CLAIMANT_FIRST_NAME": "Ida",
		 "CLAIM_NO": "2013-03-22-0157-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "04-05-2013 ",
		 "DATE_CLAIM_CLOSED": "06-26-2013 ",
		 "ACTUAL_RTW_DATE": "05-20-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mercado",
		 "CLAIMANT_FIRST_NAME": "Richard",
		 "CLAIM_NO": "2019-09-17-0627-VP-01",
		 "DURATION": 99,
		 "DISABILITY_START_DATE": "09-17-2019 ",
		 "DATE_CLAIM_CLOSED": "12-30-2019 ",
		 "ACTUAL_RTW_DATE": "12-25-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mercer",
		 "CLAIMANT_FIRST_NAME": "Shaynnah",
		 "CLAIM_NO": "2018-01-23-0713-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "02-13-2018 ",
		 "DATE_CLAIM_CLOSED": "03-05-2018 ",
		 "ACTUAL_RTW_DATE": "02-22-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mesa",
		 "CLAIMANT_FIRST_NAME": "Kelly",
		 "CLAIM_NO": "2019-05-29-0274-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "07-02-2019 ",
		 "DATE_CLAIM_CLOSED": "08-19-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Messina",
		 "CLAIMANT_FIRST_NAME": "Terri",
		 "CLAIM_NO": "2018-03-02-0548-VP-01",
		 "DURATION": 183,
		 "DISABILITY_START_DATE": "03-28-2018 ",
		 "DATE_CLAIM_CLOSED": "10-02-2018 ",
		 "ACTUAL_RTW_DATE": "09-27-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Meulmester",
		 "CLAIMANT_FIRST_NAME": "Jamie",
		 "CLAIM_NO": "2018-05-11-0236-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "05-29-2018 ",
		 "DATE_CLAIM_CLOSED": "09-28-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Meulmester",
		 "CLAIMANT_FIRST_NAME": "Jamie",
		 "CLAIM_NO": "2020-09-08-0813-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "09-15-2020 ",
		 "DATE_CLAIM_CLOSED": "11-17-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mewborn",
		 "CLAIMANT_FIRST_NAME": "Karen",
		 "CLAIM_NO": "2013-01-05-0400-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "11-12-2012 ",
		 "DATE_CLAIM_CLOSED": "06-03-2014 ",
		 "ACTUAL_RTW_DATE": "01-07-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Meyer",
		 "CLAIMANT_FIRST_NAME": "Cynthia",
		 "CLAIM_NO": "2016-03-21-0526-VP-01",
		 "DURATION": 94,
		 "DISABILITY_START_DATE": "04-19-2016 ",
		 "DATE_CLAIM_CLOSED": "07-22-2016 ",
		 "ACTUAL_RTW_DATE": "07-22-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Meyyappan",
		 "CLAIMANT_FIRST_NAME": "Meyyammai",
		 "CLAIM_NO": "2022-07-12-0675-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "08-12-2022 ",
		 "DATE_CLAIM_CLOSED": "11-15-2022 ",
		 "ACTUAL_RTW_DATE": "10-22-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Meza",
		 "CLAIMANT_FIRST_NAME": "Michell",
		 "CLAIM_NO": "2021-04-22-0346-VP-01",
		 "DURATION": 85,
		 "DISABILITY_START_DATE": "04-21-2021 ",
		 "DATE_CLAIM_CLOSED": "07-19-2021 ",
		 "ACTUAL_RTW_DATE": "07-15-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Meza",
		 "CLAIMANT_FIRST_NAME": "Consuelo",
		 "CLAIM_NO": "2013-04-09-0413-VP-01",
		 "DURATION": 952,
		 "DISABILITY_START_DATE": "04-09-2013 ",
		 "DATE_CLAIM_CLOSED": "11-24-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Meza",
		 "CLAIMANT_FIRST_NAME": "Consuelo",
		 "CLAIM_NO": "2013-01-16-0437-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "01-31-2013 ",
		 "DATE_CLAIM_CLOSED": "06-24-2013 ",
		 "ACTUAL_RTW_DATE": "03-18-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Meza",
		 "CLAIMANT_FIRST_NAME": "Bryan",
		 "CLAIM_NO": "2013-06-04-0477-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "06-03-2013 ",
		 "DATE_CLAIM_CLOSED": "08-01-2013 ",
		 "ACTUAL_RTW_DATE": "06-17-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Meza",
		 "CLAIMANT_FIRST_NAME": "Bryan",
		 "CLAIM_NO": "2018-05-23-0646-VP-01",
		 "DURATION": 44,
		 "DISABILITY_START_DATE": "05-21-2018 ",
		 "DATE_CLAIM_CLOSED": "07-31-2018 ",
		 "ACTUAL_RTW_DATE": "07-04-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Michael",
		 "CLAIMANT_FIRST_NAME": "Magdy",
		 "CLAIM_NO": "2021-12-23-0268-VP-01",
		 "DURATION": 99,
		 "DISABILITY_START_DATE": "12-23-2021 ",
		 "DATE_CLAIM_CLOSED": "04-14-2022 ",
		 "ACTUAL_RTW_DATE": "04-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Michel",
		 "CLAIMANT_FIRST_NAME": "Vanessa",
		 "CLAIM_NO": "2018-11-07-0586-VP-01",
		 "DURATION": 74,
		 "DISABILITY_START_DATE": "11-26-2018 ",
		 "DATE_CLAIM_CLOSED": "02-08-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Migdol",
		 "CLAIMANT_FIRST_NAME": "Hollie",
		 "CLAIM_NO": "2022-08-23-1082-VP-01",
		 "DURATION": 119,
		 "DISABILITY_START_DATE": "10-17-2022 ",
		 "DATE_CLAIM_CLOSED": "12-16-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Miguel",
		 "CLAIMANT_FIRST_NAME": "Lorenia",
		 "CLAIM_NO": "2021-06-22-0646-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "06-22-2021 ",
		 "DATE_CLAIM_CLOSED": "07-22-2021 ",
		 "ACTUAL_RTW_DATE": "07-19-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mijangos",
		 "CLAIMANT_FIRST_NAME": "Eusebia",
		 "CLAIM_NO": "2013-01-09-0419-VP-01",
		 "DURATION": 0,
		 "DISABILITY_START_DATE": "01-07-2013 ",
		 "DATE_CLAIM_CLOSED": "05-06-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mijangos",
		 "CLAIMANT_FIRST_NAME": "Eusebia",
		 "CLAIM_NO": "2013-01-05-0307-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "12-03-2012 ",
		 "DATE_CLAIM_CLOSED": "02-25-2013 ",
		 "ACTUAL_RTW_DATE": "01-21-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mikami",
		 "CLAIMANT_FIRST_NAME": "Chantha",
		 "CLAIM_NO": "2021-11-02-0618-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "11-01-2021 ",
		 "DATE_CLAIM_CLOSED": "11-17-2021 ",
		 "ACTUAL_RTW_DATE": "11-15-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mikolaski",
		 "CLAIMANT_FIRST_NAME": "Angela",
		 "CLAIM_NO": "2022-05-03-0718-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "05-24-2022 ",
		 "DATE_CLAIM_CLOSED": "07-11-2022 ",
		 "ACTUAL_RTW_DATE": "07-06-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mikolaski",
		 "CLAIMANT_FIRST_NAME": "Angela",
		 "CLAIM_NO": "2022-09-13-0542-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "09-12-2022 ",
		 "DATE_CLAIM_CLOSED": "11-01-2022 ",
		 "ACTUAL_RTW_DATE": "10-03-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Milan",
		 "CLAIMANT_FIRST_NAME": "Joy Erika",
		 "CLAIM_NO": "2021-02-18-0485-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "03-12-2021 ",
		 "DATE_CLAIM_CLOSED": "06-10-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Milan",
		 "CLAIMANT_FIRST_NAME": "Joy Erika",
		 "CLAIM_NO": "2019-08-07-0640-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "09-12-2019 ",
		 "DATE_CLAIM_CLOSED": "10-31-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Miller",
		 "CLAIMANT_FIRST_NAME": "Melissa",
		 "CLAIM_NO": "2022-08-11-0620-VP-01",
		 "DURATION": 103,
		 "DISABILITY_START_DATE": "08-12-2022 ",
		 "DATE_CLAIM_CLOSED": "10-19-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Miller",
		 "CLAIMANT_FIRST_NAME": "Cambria",
		 "CLAIM_NO": "2018-09-26-0538-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "09-25-2018 ",
		 "DATE_CLAIM_CLOSED": "11-28-2018 ",
		 "ACTUAL_RTW_DATE": "10-29-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Miller",
		 "CLAIMANT_FIRST_NAME": "William",
		 "CLAIM_NO": "2019-06-28-0479-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "07-12-2019 ",
		 "DATE_CLAIM_CLOSED": "09-11-2019 ",
		 "ACTUAL_RTW_DATE": "08-26-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Miller",
		 "CLAIMANT_FIRST_NAME": "Joe",
		 "CLAIM_NO": "2014-08-04-0170-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "07-28-2014 ",
		 "DATE_CLAIM_CLOSED": "02-06-2015 ",
		 "ACTUAL_RTW_DATE": "09-29-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mills",
		 "CLAIMANT_FIRST_NAME": "Melissa",
		 "CLAIM_NO": "2021-08-11-0338-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "08-09-2021 ",
		 "DATE_CLAIM_CLOSED": "10-25-2021 ",
		 "ACTUAL_RTW_DATE": "10-18-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mindlin",
		 "CLAIMANT_FIRST_NAME": "Elina",
		 "CLAIM_NO": "2019-05-03-0426-VP-01",
		 "DURATION": 365,
		 "DISABILITY_START_DATE": "05-06-2019 ",
		 "DATE_CLAIM_CLOSED": "05-11-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Miner",
		 "CLAIMANT_FIRST_NAME": "Susan",
		 "CLAIM_NO": "2022-03-29-0824-VP-01",
		 "DURATION": 93,
		 "DISABILITY_START_DATE": "03-30-2022 ",
		 "DATE_CLAIM_CLOSED": "07-13-2022 ",
		 "ACTUAL_RTW_DATE": "07-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Miner",
		 "CLAIMANT_FIRST_NAME": "Susan",
		 "CLAIM_NO": "2018-07-16-0286-VP-01",
		 "DURATION": 25,
		 "DISABILITY_START_DATE": "07-19-2018 ",
		 "DATE_CLAIM_CLOSED": "09-20-2018 ",
		 "ACTUAL_RTW_DATE": "08-13-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mirador",
		 "CLAIMANT_FIRST_NAME": "Emma",
		 "CLAIM_NO": "2016-11-07-0314-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "11-10-2016 ",
		 "DATE_CLAIM_CLOSED": "12-13-2016 ",
		 "ACTUAL_RTW_DATE": "11-28-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mirador",
		 "CLAIMANT_FIRST_NAME": "Emma",
		 "CLAIM_NO": "2018-03-20-0304-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "02-28-2018 ",
		 "DATE_CLAIM_CLOSED": "06-20-2018 ",
		 "ACTUAL_RTW_DATE": "03-27-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mirador",
		 "CLAIMANT_FIRST_NAME": "Emma",
		 "CLAIM_NO": "2016-10-26-0113-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "10-19-2016 ",
		 "DATE_CLAIM_CLOSED": "08-21-2017 ",
		 "ACTUAL_RTW_DATE": "11-28-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Miranda",
		 "CLAIMANT_FIRST_NAME": "Belinda",
		 "CLAIM_NO": "2014-09-08-0223-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "09-09-2014 ",
		 "DATE_CLAIM_CLOSED": "11-24-2014 ",
		 "ACTUAL_RTW_DATE": "09-29-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Miranda",
		 "CLAIMANT_FIRST_NAME": "Karen",
		 "CLAIM_NO": "2014-05-01-5833-VP-01",
		 "DURATION": 431,
		 "DISABILITY_START_DATE": "05-23-2014 ",
		 "DATE_CLAIM_CLOSED": "02-13-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Miranda",
		 "CLAIMANT_FIRST_NAME": "Karen",
		 "CLAIM_NO": "2020-03-30-0356-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "03-26-2020 ",
		 "DATE_CLAIM_CLOSED": "04-07-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Miranda",
		 "CLAIMANT_FIRST_NAME": "Eliza",
		 "CLAIM_NO": "2021-10-25-1100-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "11-01-2021 ",
		 "DATE_CLAIM_CLOSED": "01-19-2022 ",
		 "ACTUAL_RTW_DATE": "11-16-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mirza",
		 "CLAIMANT_FIRST_NAME": "Ronnie",
		 "CLAIM_NO": "2019-11-08-0722-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "11-04-2019 ",
		 "DATE_CLAIM_CLOSED": "01-02-2020 ",
		 "ACTUAL_RTW_DATE": "12-02-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mistry",
		 "CLAIMANT_FIRST_NAME": "Khushboo",
		 "CLAIM_NO": "2019-06-18-0351-VP-01",
		 "DURATION": 105,
		 "DISABILITY_START_DATE": "06-11-2019 ",
		 "DATE_CLAIM_CLOSED": "07-29-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mittelstaedt",
		 "CLAIMANT_FIRST_NAME": "Ashlea",
		 "CLAIM_NO": "2021-07-29-0504-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "08-10-2021 ",
		 "DATE_CLAIM_CLOSED": "10-11-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mixon",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2020-09-21-0351-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "10-28-2020 ",
		 "DATE_CLAIM_CLOSED": "12-04-2020 ",
		 "ACTUAL_RTW_DATE": "11-30-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Moala",
		 "CLAIMANT_FIRST_NAME": "Michele",
		 "CLAIM_NO": "2022-10-28-0399-VP-01",
		 "DURATION": 98,
		 "DISABILITY_START_DATE": "12-12-2022 ",
		 "DATE_CLAIM_CLOSED": "02-13-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mobilia",
		 "CLAIMANT_FIRST_NAME": "Marissa",
		 "CLAIM_NO": "2022-09-19-1025-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "10-10-2022 ",
		 "DATE_CLAIM_CLOSED": "11-30-2022 ",
		 "ACTUAL_RTW_DATE": "11-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Moein",
		 "CLAIMANT_FIRST_NAME": "Mohammed",
		 "CLAIM_NO": "2017-06-16-0311-VP-01",
		 "DURATION": 37,
		 "DISABILITY_START_DATE": "06-05-2017 ",
		 "DATE_CLAIM_CLOSED": "07-24-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Moeller",
		 "CLAIMANT_FIRST_NAME": "Brooke",
		 "CLAIM_NO": "2015-06-05-0275-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "06-08-2015 ",
		 "DATE_CLAIM_CLOSED": "07-22-2015 ",
		 "ACTUAL_RTW_DATE": "07-06-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mohammad",
		 "CLAIMANT_FIRST_NAME": "Mina",
		 "CLAIM_NO": "2022-05-05-0648-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "06-14-2022 ",
		 "DATE_CLAIM_CLOSED": "08-18-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mohammed",
		 "CLAIMANT_FIRST_NAME": "Taihra",
		 "CLAIM_NO": "2021-04-05-0453-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "04-12-2021 ",
		 "DATE_CLAIM_CLOSED": "06-10-2021 ",
		 "ACTUAL_RTW_DATE": "06-07-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mohammed",
		 "CLAIMANT_FIRST_NAME": "Taihra",
		 "CLAIM_NO": "2022-05-13-0601-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "05-16-2022 ",
		 "DATE_CLAIM_CLOSED": "08-05-2022 ",
		 "ACTUAL_RTW_DATE": "08-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mohandas",
		 "CLAIMANT_FIRST_NAME": "Sruti",
		 "CLAIM_NO": "2018-10-22-0260-VP-01",
		 "DURATION": -274,
		 "DISABILITY_START_DATE": "11-08-2018 ",
		 "DATE_CLAIM_CLOSED": "12-28-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mokhtari",
		 "CLAIMANT_FIRST_NAME": "Masoumeh",
		 "CLAIM_NO": "2021-08-06-0220-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "08-06-2021 ",
		 "DATE_CLAIM_CLOSED": "10-19-2021 ",
		 "ACTUAL_RTW_DATE": "09-24-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mokhtari",
		 "CLAIMANT_FIRST_NAME": "Masoumeh",
		 "CLAIM_NO": "2016-06-02-0500-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "06-01-2016 ",
		 "DATE_CLAIM_CLOSED": "08-09-2016 ",
		 "ACTUAL_RTW_DATE": "08-02-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mokres",
		 "CLAIMANT_FIRST_NAME": "Lucia",
		 "CLAIM_NO": "2014-02-11-0010-VP-01",
		 "DURATION": 99,
		 "DISABILITY_START_DATE": "02-11-2014 ",
		 "DATE_CLAIM_CLOSED": "06-10-2014 ",
		 "ACTUAL_RTW_DATE": "05-21-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Molano",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2016-12-06-0013-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "12-07-2016 ",
		 "DATE_CLAIM_CLOSED": "01-18-2017 ",
		 "ACTUAL_RTW_DATE": "01-16-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Molano",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2013-01-05-0494-VP-01",
		 "DURATION": 72,
		 "DISABILITY_START_DATE": "10-29-2012 ",
		 "DATE_CLAIM_CLOSED": "08-06-2013 ",
		 "ACTUAL_RTW_DATE": "01-09-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Molano",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2015-05-27-0529-VP-01",
		 "DURATION": 132,
		 "DISABILITY_START_DATE": "05-26-2015 ",
		 "DATE_CLAIM_CLOSED": "05-18-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Molaug",
		 "CLAIMANT_FIRST_NAME": "Adrianna",
		 "CLAIM_NO": "2016-02-01-0527-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "02-10-2016 ",
		 "DATE_CLAIM_CLOSED": "03-30-2016 ",
		 "ACTUAL_RTW_DATE": "03-10-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Molina",
		 "CLAIMANT_FIRST_NAME": "Emelyn",
		 "CLAIM_NO": "2013-01-09-0317-VP-01",
		 "DURATION": 61,
		 "DISABILITY_START_DATE": "01-09-2013 ",
		 "DATE_CLAIM_CLOSED": "06-26-2013 ",
		 "ACTUAL_RTW_DATE": "03-11-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Molina Vigil",
		 "CLAIMANT_FIRST_NAME": "Gabriel",
		 "CLAIM_NO": "2014-09-18-0460-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "09-25-2014 ",
		 "DATE_CLAIM_CLOSED": "12-16-2014 ",
		 "ACTUAL_RTW_DATE": "11-11-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Molnar",
		 "CLAIMANT_FIRST_NAME": "Shanna",
		 "CLAIM_NO": "2022-08-29-0670-VP-01",
		 "DURATION": 65,
		 "DISABILITY_START_DATE": "09-28-2022 ",
		 "DATE_CLAIM_CLOSED": "12-14-2022 ",
		 "ACTUAL_RTW_DATE": "12-02-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Monari",
		 "CLAIMANT_FIRST_NAME": "Ruth",
		 "CLAIM_NO": "2023-02-02-0941-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "02-02-2023 ",
		 "DATE_CLAIM_CLOSED": "03-31-2023 ",
		 "ACTUAL_RTW_DATE": "03-31-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Monari",
		 "CLAIMANT_FIRST_NAME": "Ruth",
		 "CLAIM_NO": "2022-08-08-0813-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "08-09-2022 ",
		 "DATE_CLAIM_CLOSED": "10-12-2022 ",
		 "ACTUAL_RTW_DATE": "09-12-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Moniz",
		 "CLAIMANT_FIRST_NAME": "Edmundo",
		 "CLAIM_NO": "2013-01-10-0031-VP-01",
		 "DURATION": 93,
		 "DISABILITY_START_DATE": "01-14-2013 ",
		 "DATE_CLAIM_CLOSED": "06-10-2013 ",
		 "ACTUAL_RTW_DATE": "04-17-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Montano-Morse",
		 "CLAIMANT_FIRST_NAME": "Ana",
		 "CLAIM_NO": "2013-12-12-0196-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "01-14-2014 ",
		 "DATE_CLAIM_CLOSED": "03-24-2014 ",
		 "ACTUAL_RTW_DATE": "03-04-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Montehermoso",
		 "CLAIMANT_FIRST_NAME": "Anne",
		 "CLAIM_NO": "2021-10-08-0021-VP-01",
		 "DURATION": 25,
		 "DISABILITY_START_DATE": "10-28-2021 ",
		 "DATE_CLAIM_CLOSED": "11-23-2021 ",
		 "ACTUAL_RTW_DATE": "11-22-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Montehermoso",
		 "CLAIMANT_FIRST_NAME": "Anne",
		 "CLAIM_NO": "2022-08-08-0978-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "08-12-2022 ",
		 "DATE_CLAIM_CLOSED": "09-14-2022 ",
		 "ACTUAL_RTW_DATE": "09-05-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Montehermoso",
		 "CLAIMANT_FIRST_NAME": "Anne",
		 "CLAIM_NO": "2023-01-23-0612-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "01-09-2023 ",
		 "DATE_CLAIM_CLOSED": "04-13-2023 ",
		 "ACTUAL_RTW_DATE": "03-27-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Monteil",
		 "CLAIMANT_FIRST_NAME": "Rosanna",
		 "CLAIM_NO": "2022-12-28-0549-VP-01",
		 "DURATION": 140,
		 "DISABILITY_START_DATE": "01-23-2023 ",
		 "DATE_CLAIM_CLOSED": "04-04-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Monteon",
		 "CLAIMANT_FIRST_NAME": "Gabriel",
		 "CLAIM_NO": "2022-05-05-0369-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "05-05-2022 ",
		 "DATE_CLAIM_CLOSED": "06-07-2022 ",
		 "ACTUAL_RTW_DATE": "06-06-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Montes",
		 "CLAIMANT_FIRST_NAME": "Victor",
		 "CLAIM_NO": "2013-02-12-0180-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "02-15-2013 ",
		 "DATE_CLAIM_CLOSED": "05-15-2013 ",
		 "ACTUAL_RTW_DATE": "03-18-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Montiel",
		 "CLAIMANT_FIRST_NAME": "Ernestina",
		 "CLAIM_NO": "2015-03-27-0271-VP-01",
		 "DURATION": 988,
		 "DISABILITY_START_DATE": "11-11-2013 ",
		 "DATE_CLAIM_CLOSED": "11-02-2016 ",
		 "ACTUAL_RTW_DATE": "07-26-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Montilla",
		 "CLAIMANT_FIRST_NAME": "Jeffrey",
		 "CLAIM_NO": "2014-05-22-0340-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "05-19-2014 ",
		 "DATE_CLAIM_CLOSED": "08-11-2014 ",
		 "ACTUAL_RTW_DATE": "06-02-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Montoya",
		 "CLAIMANT_FIRST_NAME": "Christopher",
		 "CLAIM_NO": "2021-09-09-0005-VP-01",
		 "DURATION": 4,
		 "DISABILITY_START_DATE": "09-23-2021 ",
		 "DATE_CLAIM_CLOSED": "11-15-2021 ",
		 "ACTUAL_RTW_DATE": "09-27-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Montoya",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2015-10-09-0175-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "10-06-2015 ",
		 "DATE_CLAIM_CLOSED": "11-03-2015 ",
		 "ACTUAL_RTW_DATE": "10-19-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Montoya",
		 "CLAIMANT_FIRST_NAME": "Cheryl",
		 "CLAIM_NO": "2013-08-21-0352-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "09-05-2013 ",
		 "DATE_CLAIM_CLOSED": "11-25-2013 ",
		 "ACTUAL_RTW_DATE": "09-16-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Montoya",
		 "CLAIMANT_FIRST_NAME": "Christopher",
		 "CLAIM_NO": "2023-01-19-0879-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "01-19-2023 ",
		 "DATE_CLAIM_CLOSED": "04-24-2023 ",
		 "ACTUAL_RTW_DATE": "03-14-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Montoya",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2016-06-03-0377-VP-01",
		 "DURATION": 99,
		 "DISABILITY_START_DATE": "05-18-2016 ",
		 "DATE_CLAIM_CLOSED": "03-31-2017 ",
		 "ACTUAL_RTW_DATE": "08-25-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Montoya",
		 "CLAIMANT_FIRST_NAME": "Cheryl",
		 "CLAIM_NO": "2014-09-02-0259-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "09-12-2014 ",
		 "DATE_CLAIM_CLOSED": "01-07-2015 ",
		 "ACTUAL_RTW_DATE": "10-13-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Montoya",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2017-07-14-0506-VP-01",
		 "DURATION": 381,
		 "DISABILITY_START_DATE": "07-17-2017 ",
		 "DATE_CLAIM_CLOSED": "06-15-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Montoya",
		 "CLAIMANT_FIRST_NAME": "Cheryl",
		 "CLAIM_NO": "2015-02-19-0523-VP-01",
		 "DURATION": 124,
		 "DISABILITY_START_DATE": "02-18-2015 ",
		 "DATE_CLAIM_CLOSED": "06-23-2015 ",
		 "ACTUAL_RTW_DATE": "06-22-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Moon",
		 "CLAIMANT_FIRST_NAME": "Juyeun",
		 "CLAIM_NO": "2021-08-03-0536-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "09-16-2021 ",
		 "DATE_CLAIM_CLOSED": "11-10-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Moore",
		 "CLAIMANT_FIRST_NAME": "Leslee",
		 "CLAIM_NO": "2013-06-05-0353-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "07-02-2013 ",
		 "DATE_CLAIM_CLOSED": "08-05-2013 ",
		 "ACTUAL_RTW_DATE": "07-16-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Moore",
		 "CLAIMANT_FIRST_NAME": "Leslee",
		 "CLAIM_NO": "2014-07-28-0485-VP-01",
		 "DURATION": 0,
		 "DISABILITY_START_DATE": "07-28-2014 ",
		 "DATE_CLAIM_CLOSED": "11-13-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mora",
		 "CLAIMANT_FIRST_NAME": "Julie",
		 "CLAIM_NO": "2016-05-13-0442-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "05-16-2016 ",
		 "DATE_CLAIM_CLOSED": "06-07-2016 ",
		 "ACTUAL_RTW_DATE": "05-31-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mora",
		 "CLAIMANT_FIRST_NAME": "Nancy",
		 "CLAIM_NO": "2023-01-24-0814-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "02-01-2023 ",
		 "DATE_CLAIM_CLOSED": "03-27-2023 ",
		 "ACTUAL_RTW_DATE": "03-16-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mora",
		 "CLAIMANT_FIRST_NAME": "Julie",
		 "CLAIM_NO": "2013-08-19-0223-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "08-14-2013 ",
		 "DATE_CLAIM_CLOSED": "10-04-2013 ",
		 "ACTUAL_RTW_DATE": "08-27-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mora",
		 "CLAIMANT_FIRST_NAME": "Raul",
		 "CLAIM_NO": "2021-11-30-0773-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "12-17-2021 ",
		 "DATE_CLAIM_CLOSED": "01-24-2022 ",
		 "ACTUAL_RTW_DATE": "01-10-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mora",
		 "CLAIMANT_FIRST_NAME": "Julie",
		 "CLAIM_NO": "2013-05-28-0449-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "05-28-2013 ",
		 "DATE_CLAIM_CLOSED": "10-16-2013 ",
		 "ACTUAL_RTW_DATE": "06-11-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Morales",
		 "CLAIMANT_FIRST_NAME": "Dolores",
		 "CLAIM_NO": "2020-08-03-0844-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "07-27-2020 ",
		 "DATE_CLAIM_CLOSED": "08-07-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Morales",
		 "CLAIMANT_FIRST_NAME": "Paulette",
		 "CLAIM_NO": "2021-01-12-0348-VP-01",
		 "DURATION": 179,
		 "DISABILITY_START_DATE": "02-01-2021 ",
		 "DATE_CLAIM_CLOSED": "08-02-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Morales",
		 "CLAIMANT_FIRST_NAME": "Soledad",
		 "CLAIM_NO": "2019-08-07-0534-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "09-03-2019 ",
		 "DATE_CLAIM_CLOSED": "11-01-2019 ",
		 "ACTUAL_RTW_DATE": "10-28-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Morales",
		 "CLAIMANT_FIRST_NAME": "Marisela",
		 "CLAIM_NO": "2019-08-09-0343-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "08-05-2019 ",
		 "DATE_CLAIM_CLOSED": "10-17-2019 ",
		 "ACTUAL_RTW_DATE": "10-07-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Morales",
		 "CLAIMANT_FIRST_NAME": "Dolores",
		 "CLAIM_NO": "2017-01-30-0206-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "01-23-2017 ",
		 "DATE_CLAIM_CLOSED": "02-28-2017 ",
		 "ACTUAL_RTW_DATE": "02-06-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Morales",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2023-01-03-0776-VP-01",
		 "DURATION": 30,
		 "DISABILITY_START_DATE": "12-17-2022 ",
		 "DATE_CLAIM_CLOSED": "02-14-2023 ",
		 "ACTUAL_RTW_DATE": "01-16-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Moran",
		 "CLAIMANT_FIRST_NAME": "Karen",
		 "CLAIM_NO": "2013-11-15-0291-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "12-02-2013 ",
		 "DATE_CLAIM_CLOSED": "02-13-2014 ",
		 "ACTUAL_RTW_DATE": "01-27-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Moran",
		 "CLAIMANT_FIRST_NAME": "Chelsea",
		 "CLAIM_NO": "2022-02-21-0362-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "04-04-2022 ",
		 "DATE_CLAIM_CLOSED": "06-06-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Moran",
		 "CLAIMANT_FIRST_NAME": "Karen",
		 "CLAIM_NO": "2013-10-22-0294-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "10-30-2013 ",
		 "DATE_CLAIM_CLOSED": "11-08-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Morden Ignacio",
		 "CLAIMANT_FIRST_NAME": "Rosalind",
		 "CLAIM_NO": "2016-06-01-0567-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "06-02-2016 ",
		 "DATE_CLAIM_CLOSED": "08-22-2016 ",
		 "ACTUAL_RTW_DATE": "08-18-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Moredia",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2013-01-05-5659-VP-01",
		 "DURATION": 654,
		 "DISABILITY_START_DATE": "04-07-2011 ",
		 "DATE_CLAIM_CLOSED": "04-24-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Morehead",
		 "CLAIMANT_FIRST_NAME": "Michael",
		 "CLAIM_NO": "2016-06-17-0444-VP-01",
		 "DURATION": 113,
		 "DISABILITY_START_DATE": "06-20-2016 ",
		 "DATE_CLAIM_CLOSED": "10-11-2016 ",
		 "ACTUAL_RTW_DATE": "10-11-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Morehead",
		 "CLAIMANT_FIRST_NAME": "Michael",
		 "CLAIM_NO": "2018-06-07-0216-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "06-29-2018 ",
		 "DATE_CLAIM_CLOSED": "07-17-2018 ",
		 "ACTUAL_RTW_DATE": "07-16-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Moreira",
		 "CLAIMANT_FIRST_NAME": "Courtney",
		 "CLAIM_NO": "2013-01-05-0067-VP-01",
		 "DURATION": 50,
		 "DISABILITY_START_DATE": "01-14-2013 ",
		 "DATE_CLAIM_CLOSED": "05-09-2013 ",
		 "ACTUAL_RTW_DATE": "03-05-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Moreira",
		 "CLAIMANT_FIRST_NAME": "Rebecca",
		 "CLAIM_NO": "2014-02-12-0268-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "03-05-2014 ",
		 "DATE_CLAIM_CLOSED": "06-03-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Moreira",
		 "CLAIMANT_FIRST_NAME": "Courtney",
		 "CLAIM_NO": "2015-01-29-0455-VP-01",
		 "DURATION": 52,
		 "DISABILITY_START_DATE": "02-25-2015 ",
		 "DATE_CLAIM_CLOSED": "04-28-2015 ",
		 "ACTUAL_RTW_DATE": "04-18-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Moreira",
		 "CLAIMANT_FIRST_NAME": "Rebecca",
		 "CLAIM_NO": "2015-09-29-0363-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "10-12-2015 ",
		 "DATE_CLAIM_CLOSED": "11-30-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Morella",
		 "CLAIMANT_FIRST_NAME": "Jesusa",
		 "CLAIM_NO": "2019-02-25-0565-VP-01",
		 "DURATION": 92,
		 "DISABILITY_START_DATE": "02-25-2019 ",
		 "DATE_CLAIM_CLOSED": "06-10-2019 ",
		 "ACTUAL_RTW_DATE": "05-28-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Moreno",
		 "CLAIMANT_FIRST_NAME": "Orlando",
		 "CLAIM_NO": "2017-06-02-0530-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "06-05-2017 ",
		 "DATE_CLAIM_CLOSED": "08-08-2017 ",
		 "ACTUAL_RTW_DATE": "08-07-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Morey",
		 "CLAIMANT_FIRST_NAME": "Vicky",
		 "CLAIM_NO": "2017-07-17-0503-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "08-14-2017 ",
		 "DATE_CLAIM_CLOSED": "10-30-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Morgan",
		 "CLAIMANT_FIRST_NAME": "Allison",
		 "CLAIM_NO": "2013-06-10-0413-VP-01",
		 "DURATION": 99,
		 "DISABILITY_START_DATE": "06-26-2013 ",
		 "DATE_CLAIM_CLOSED": "11-05-2013 ",
		 "ACTUAL_RTW_DATE": "10-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Morgan",
		 "CLAIMANT_FIRST_NAME": "Allison",
		 "CLAIM_NO": "2015-07-20-0163-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "08-27-2015 ",
		 "DATE_CLAIM_CLOSED": "10-27-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Morishetti",
		 "CLAIMANT_FIRST_NAME": "Deepika",
		 "CLAIM_NO": "2022-01-03-1164-VP-01",
		 "DURATION": 123,
		 "DISABILITY_START_DATE": "01-04-2022 ",
		 "DATE_CLAIM_CLOSED": "05-19-2022 ",
		 "ACTUAL_RTW_DATE": "05-07-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Morishetti",
		 "CLAIMANT_FIRST_NAME": "Deepika",
		 "CLAIM_NO": "2015-05-22-0477-VP-01",
		 "DURATION": 67,
		 "DISABILITY_START_DATE": "06-11-2015 ",
		 "DATE_CLAIM_CLOSED": "08-17-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Morog",
		 "CLAIMANT_FIRST_NAME": "Jessica",
		 "CLAIM_NO": "2023-01-18-0024-VP-01",
		 "DURATION": 67,
		 "DISABILITY_START_DATE": "02-02-2023 ",
		 "DATE_CLAIM_CLOSED": "05-04-2023 ",
		 "ACTUAL_RTW_DATE": "04-10-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Morrow",
		 "CLAIMANT_FIRST_NAME": "Bobbie",
		 "CLAIM_NO": "2018-10-29-0691-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "11-21-2018 ",
		 "DATE_CLAIM_CLOSED": "01-17-2019 ",
		 "ACTUAL_RTW_DATE": "01-14-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Moscon",
		 "CLAIMANT_FIRST_NAME": "Joseph",
		 "CLAIM_NO": "2018-04-30-0231-VP-01",
		 "DURATION": 75,
		 "DISABILITY_START_DATE": "04-26-2018 ",
		 "DATE_CLAIM_CLOSED": "08-01-2018 ",
		 "ACTUAL_RTW_DATE": "07-10-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Moscon",
		 "CLAIMANT_FIRST_NAME": "Joseph",
		 "CLAIM_NO": "2018-12-07-0412-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "12-03-2018 ",
		 "DATE_CLAIM_CLOSED": "01-08-2019 ",
		 "ACTUAL_RTW_DATE": "12-12-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Moselle",
		 "CLAIMANT_FIRST_NAME": "Anne",
		 "CLAIM_NO": "2019-02-19-0458-VP-01",
		 "DURATION": 185,
		 "DISABILITY_START_DATE": "02-15-2019 ",
		 "DATE_CLAIM_CLOSED": "08-22-2019 ",
		 "ACTUAL_RTW_DATE": "08-19-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mosher",
		 "CLAIMANT_FIRST_NAME": "Scott",
		 "CLAIM_NO": "2019-11-13-0441-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "11-25-2019 ",
		 "DATE_CLAIM_CLOSED": "12-12-2019 ",
		 "ACTUAL_RTW_DATE": "12-09-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mosher",
		 "CLAIMANT_FIRST_NAME": "Scott",
		 "CLAIM_NO": "2020-02-04-0378-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "03-04-2020 ",
		 "DATE_CLAIM_CLOSED": "03-25-2020 ",
		 "ACTUAL_RTW_DATE": "03-23-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mosley",
		 "CLAIMANT_FIRST_NAME": "Robert",
		 "CLAIM_NO": "2015-05-20-0365-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "06-15-2015 ",
		 "DATE_CLAIM_CLOSED": "09-16-2015 ",
		 "ACTUAL_RTW_DATE": "09-07-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mossberg",
		 "CLAIMANT_FIRST_NAME": "Kathryn",
		 "CLAIM_NO": "2015-01-05-0554-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "01-13-2015 ",
		 "DATE_CLAIM_CLOSED": "02-12-2015 ",
		 "ACTUAL_RTW_DATE": "02-09-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mossberg",
		 "CLAIMANT_FIRST_NAME": "Kathryn",
		 "CLAIM_NO": "2016-05-03-0266-VP-01",
		 "DURATION": 5,
		 "DISABILITY_START_DATE": "05-18-2016 ",
		 "DATE_CLAIM_CLOSED": "06-03-2016 ",
		 "ACTUAL_RTW_DATE": "05-23-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mossberg",
		 "CLAIMANT_FIRST_NAME": "Kathryn",
		 "CLAIM_NO": "2016-06-07-0098-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "06-01-2016 ",
		 "DATE_CLAIM_CLOSED": "07-21-2016 ",
		 "ACTUAL_RTW_DATE": "07-18-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Moubarek",
		 "CLAIMANT_FIRST_NAME": "Saleha",
		 "CLAIM_NO": "2022-09-13-0976-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "10-10-2022 ",
		 "DATE_CLAIM_CLOSED": "11-25-2022 ",
		 "ACTUAL_RTW_DATE": "11-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Moulton",
		 "CLAIMANT_FIRST_NAME": "Christine",
		 "CLAIM_NO": "2020-12-16-0929-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "01-13-2021 ",
		 "DATE_CLAIM_CLOSED": "03-18-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Muchunku",
		 "CLAIMANT_FIRST_NAME": "Makena",
		 "CLAIM_NO": "2018-07-31-0441-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "08-22-2018 ",
		 "DATE_CLAIM_CLOSED": "10-22-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Muehlbach",
		 "CLAIMANT_FIRST_NAME": "Corazon",
		 "CLAIM_NO": "2019-03-18-0316-VP-01",
		 "DURATION": 108,
		 "DISABILITY_START_DATE": "03-18-2019 ",
		 "DATE_CLAIM_CLOSED": "07-12-2019 ",
		 "ACTUAL_RTW_DATE": "07-04-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Muehlbach",
		 "CLAIMANT_FIRST_NAME": "Corazon",
		 "CLAIM_NO": "2020-03-24-0250-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "03-20-2020 ",
		 "DATE_CLAIM_CLOSED": "04-03-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Muehlbach",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2013-08-26-0165-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "08-26-2013 ",
		 "DATE_CLAIM_CLOSED": "02-03-2014 ",
		 "ACTUAL_RTW_DATE": "09-17-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Muehlbach",
		 "CLAIMANT_FIRST_NAME": "Corazon",
		 "CLAIM_NO": "2021-06-08-0342-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "06-04-2021 ",
		 "DATE_CLAIM_CLOSED": "08-24-2021 ",
		 "ACTUAL_RTW_DATE": "08-20-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Muehlbach",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2013-12-13-0234-VP-01",
		 "DURATION": 52,
		 "DISABILITY_START_DATE": "12-13-2013 ",
		 "DATE_CLAIM_CLOSED": "03-17-2014 ",
		 "ACTUAL_RTW_DATE": "02-03-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Muehlbach",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2014-11-03-0189-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "11-03-2014 ",
		 "DATE_CLAIM_CLOSED": "12-09-2014 ",
		 "ACTUAL_RTW_DATE": "12-04-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Muehlbach",
		 "CLAIMANT_FIRST_NAME": "Corazon",
		 "CLAIM_NO": "2016-05-10-0224-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "05-03-2016 ",
		 "DATE_CLAIM_CLOSED": "06-20-2016 ",
		 "ACTUAL_RTW_DATE": "06-20-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Muehlbach",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2013-01-28-0135-VP-01",
		 "DURATION": 141,
		 "DISABILITY_START_DATE": "01-28-2013 ",
		 "DATE_CLAIM_CLOSED": "07-22-2013 ",
		 "ACTUAL_RTW_DATE": "06-18-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mukherjee",
		 "CLAIMANT_FIRST_NAME": "Avi",
		 "CLAIM_NO": "2021-05-24-0879-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "05-06-2021 ",
		 "DATE_CLAIM_CLOSED": "08-03-2021 ",
		 "ACTUAL_RTW_DATE": "05-26-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mukherjee",
		 "CLAIMANT_FIRST_NAME": "Avi",
		 "CLAIM_NO": "2022-10-07-0196-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "09-28-2022 ",
		 "DATE_CLAIM_CLOSED": "12-05-2022 ",
		 "ACTUAL_RTW_DATE": "10-18-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mukhopadhyay",
		 "CLAIMANT_FIRST_NAME": "Debnath",
		 "CLAIM_NO": "2023-02-07-0009-VP-01",
		 "DURATION": 6,
		 "DISABILITY_START_DATE": "01-26-2023 ",
		 "DATE_CLAIM_CLOSED": "02-16-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Mullu",
		 "CLAIMANT_FIRST_NAME": "Valentine",
		 "CLAIM_NO": "2015-03-20-0229-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "03-02-2015 ",
		 "DATE_CLAIM_CLOSED": "06-04-2015 ",
		 "ACTUAL_RTW_DATE": "03-11-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Munoz",
		 "CLAIMANT_FIRST_NAME": "Andy",
		 "CLAIM_NO": "2015-05-20-0008-VP-01",
		 "DURATION": 119,
		 "DISABILITY_START_DATE": "06-22-2015 ",
		 "DATE_CLAIM_CLOSED": "10-27-2015 ",
		 "ACTUAL_RTW_DATE": "10-19-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Munoz",
		 "CLAIMANT_FIRST_NAME": "Carolyn",
		 "CLAIM_NO": "2021-05-06-0895-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "06-28-2021 ",
		 "DATE_CLAIM_CLOSED": "07-19-2021 ",
		 "ACTUAL_RTW_DATE": "07-17-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Munoz",
		 "CLAIMANT_FIRST_NAME": "Carolyn",
		 "CLAIM_NO": "2022-01-19-1401-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "01-17-2022 ",
		 "DATE_CLAIM_CLOSED": "01-31-2022 ",
		 "ACTUAL_RTW_DATE": "01-26-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Munsayac",
		 "CLAIMANT_FIRST_NAME": "Paulita",
		 "CLAIM_NO": "2013-01-05-0026-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "01-09-2013 ",
		 "DATE_CLAIM_CLOSED": "01-22-2013 ",
		 "ACTUAL_RTW_DATE": "01-21-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Muralidharan",
		 "CLAIMANT_FIRST_NAME": "Krithika",
		 "CLAIM_NO": "2013-01-14-0190-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "02-15-2013 ",
		 "DATE_CLAIM_CLOSED": "06-03-2013 ",
		 "ACTUAL_RTW_DATE": "04-14-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Murillo",
		 "CLAIMANT_FIRST_NAME": "Janet",
		 "CLAIM_NO": "2021-11-02-0672-VP-01",
		 "DURATION": 401,
		 "DISABILITY_START_DATE": "10-04-2021 ",
		 "DATE_CLAIM_CLOSED": "12-06-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Murphy",
		 "CLAIMANT_FIRST_NAME": "Ann",
		 "CLAIM_NO": "2016-09-22-0410-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "09-30-2016 ",
		 "DATE_CLAIM_CLOSED": "11-04-2016 ",
		 "ACTUAL_RTW_DATE": "10-31-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Murphy",
		 "CLAIMANT_FIRST_NAME": "Michele",
		 "CLAIM_NO": "2019-08-13-0243-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "09-23-2019 ",
		 "DATE_CLAIM_CLOSED": "12-06-2019 ",
		 "ACTUAL_RTW_DATE": "12-02-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Murray",
		 "CLAIMANT_FIRST_NAME": "Robert",
		 "CLAIM_NO": "2021-01-18-0296-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "01-11-2021 ",
		 "DATE_CLAIM_CLOSED": "03-31-2021 ",
		 "ACTUAL_RTW_DATE": "03-29-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Murray",
		 "CLAIMANT_FIRST_NAME": "Robert",
		 "CLAIM_NO": "2018-10-22-0312-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "10-22-2018 ",
		 "DATE_CLAIM_CLOSED": "01-08-2019 ",
		 "ACTUAL_RTW_DATE": "01-07-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Murtishaw",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2019-05-24-0538-VP-01",
		 "DURATION": 112,
		 "DISABILITY_START_DATE": "05-20-2019 ",
		 "DATE_CLAIM_CLOSED": "10-15-2019 ",
		 "ACTUAL_RTW_DATE": "09-09-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Muthu Sekaran",
		 "CLAIMANT_FIRST_NAME": "Gayatri",
		 "CLAIM_NO": "2022-03-14-0817-VP-01",
		 "DURATION": 61,
		 "DISABILITY_START_DATE": "04-11-2022 ",
		 "DATE_CLAIM_CLOSED": "06-13-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Myo-Min",
		 "CLAIMANT_FIRST_NAME": "Kathy",
		 "CLAIM_NO": "2014-03-18-0250-VP-01",
		 "DURATION": 289,
		 "DISABILITY_START_DATE": "03-19-2014 ",
		 "DATE_CLAIM_CLOSED": "01-21-2015 ",
		 "ACTUAL_RTW_DATE": "01-02-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Naaman",
		 "CLAIMANT_FIRST_NAME": "Lauren",
		 "CLAIM_NO": "2022-05-09-0759-VP-01",
		 "DURATION": 133,
		 "DISABILITY_START_DATE": "07-11-2022 ",
		 "DATE_CLAIM_CLOSED": "09-12-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Naaman",
		 "CLAIMANT_FIRST_NAME": "Lauren",
		 "CLAIM_NO": "2020-01-06-0922-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "02-03-2020 ",
		 "DATE_CLAIM_CLOSED": "03-19-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nada",
		 "CLAIMANT_FIRST_NAME": "Riad",
		 "CLAIM_NO": "2022-10-03-0902-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "09-07-2022 ",
		 "DATE_CLAIM_CLOSED": "01-25-2023 ",
		 "ACTUAL_RTW_DATE": "10-17-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Naegeli",
		 "CLAIMANT_FIRST_NAME": "Andrew",
		 "CLAIM_NO": "2016-05-09-0518-VP-01",
		 "DURATION": 104,
		 "DISABILITY_START_DATE": "04-12-2016 ",
		 "DATE_CLAIM_CLOSED": "07-26-2016 ",
		 "ACTUAL_RTW_DATE": "07-25-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nagra",
		 "CLAIMANT_FIRST_NAME": "Kulwinder",
		 "CLAIM_NO": "2016-05-17-0156-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "05-09-2016 ",
		 "DATE_CLAIM_CLOSED": "07-05-2016 ",
		 "ACTUAL_RTW_DATE": "05-31-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Naidu",
		 "CLAIMANT_FIRST_NAME": "Shobna",
		 "CLAIM_NO": "2013-02-04-0193-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "01-31-2013 ",
		 "DATE_CLAIM_CLOSED": "05-15-2013 ",
		 "ACTUAL_RTW_DATE": "02-10-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Najarro",
		 "CLAIMANT_FIRST_NAME": "Araceli",
		 "CLAIM_NO": "2021-05-24-0484-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "05-24-2021 ",
		 "DATE_CLAIM_CLOSED": "07-12-2021 ",
		 "ACTUAL_RTW_DATE": "07-08-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nakagawa",
		 "CLAIMANT_FIRST_NAME": "Jane",
		 "CLAIM_NO": "2015-07-31-0171-VP-01",
		 "DURATION": 5,
		 "DISABILITY_START_DATE": "08-05-2015 ",
		 "DATE_CLAIM_CLOSED": "09-14-2015 ",
		 "ACTUAL_RTW_DATE": "08-10-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nakamura",
		 "CLAIMANT_FIRST_NAME": "Liberty",
		 "CLAIM_NO": "2015-07-09-0178-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "07-17-2015 ",
		 "DATE_CLAIM_CLOSED": "09-29-2015 ",
		 "ACTUAL_RTW_DATE": "08-03-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Napiewocki",
		 "CLAIMANT_FIRST_NAME": "Shane",
		 "CLAIM_NO": "2016-03-23-0495-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "03-13-2016 ",
		 "DATE_CLAIM_CLOSED": "05-06-2016 ",
		 "ACTUAL_RTW_DATE": "03-21-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Narina",
		 "CLAIMANT_FIRST_NAME": "Chaithanya",
		 "CLAIM_NO": "2022-05-27-0345-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "06-27-2022 ",
		 "DATE_CLAIM_CLOSED": "07-26-2022 ",
		 "ACTUAL_RTW_DATE": "07-25-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Narina",
		 "CLAIMANT_FIRST_NAME": "Chaithanya",
		 "CLAIM_NO": "2015-04-07-0245-VP-01",
		 "DURATION": 78,
		 "DISABILITY_START_DATE": "04-20-2015 ",
		 "DATE_CLAIM_CLOSED": "09-10-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nava",
		 "CLAIMANT_FIRST_NAME": "Fidelina",
		 "CLAIM_NO": "2013-01-05-7547-VP-01",
		 "DURATION": 970,
		 "DISABILITY_START_DATE": "05-12-2010 ",
		 "DATE_CLAIM_CLOSED": "04-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nava",
		 "CLAIMANT_FIRST_NAME": "Lorena",
		 "CLAIM_NO": "2013-12-09-0367-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "12-02-2013 ",
		 "DATE_CLAIM_CLOSED": "12-19-2013 ",
		 "ACTUAL_RTW_DATE": "12-09-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nava",
		 "CLAIMANT_FIRST_NAME": "Lorena",
		 "CLAIM_NO": "2014-03-10-0263-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "02-07-2014 ",
		 "DATE_CLAIM_CLOSED": "02-09-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nava",
		 "CLAIMANT_FIRST_NAME": "Lorena",
		 "CLAIM_NO": "2013-10-11-0169-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "11-01-2013 ",
		 "DATE_CLAIM_CLOSED": "01-14-2014 ",
		 "ACTUAL_RTW_DATE": "11-14-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nava",
		 "CLAIMANT_FIRST_NAME": "Lorena",
		 "CLAIM_NO": "2013-06-20-0374-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "06-13-2013 ",
		 "DATE_CLAIM_CLOSED": "09-30-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nava",
		 "CLAIMANT_FIRST_NAME": "Lorena",
		 "CLAIM_NO": "2013-09-30-0409-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "09-17-2013 ",
		 "DATE_CLAIM_CLOSED": "02-03-2014 ",
		 "ACTUAL_RTW_DATE": "09-30-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nava",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2013-06-05-0039-VP-01",
		 "DURATION": 89,
		 "DISABILITY_START_DATE": "06-05-2013 ",
		 "DATE_CLAIM_CLOSED": "10-03-2013 ",
		 "ACTUAL_RTW_DATE": "09-02-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nava",
		 "CLAIMANT_FIRST_NAME": "Lorena",
		 "CLAIM_NO": "2013-08-15-0489-VP-01",
		 "DISABILITY_START_DATE": "08-13-2013 ",
		 "DATE_CLAIM_CLOSED": "08-21-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nava",
		 "CLAIMANT_FIRST_NAME": "Lorena",
		 "CLAIM_NO": "2013-01-15-0143-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "01-07-2013 ",
		 "DATE_CLAIM_CLOSED": "05-06-2013 ",
		 "ACTUAL_RTW_DATE": "04-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nava",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2021-01-19-1035-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "01-19-2021 ",
		 "DATE_CLAIM_CLOSED": "03-05-2021 ",
		 "ACTUAL_RTW_DATE": "03-01-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nava",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2022-12-16-0676-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "12-22-2022 ",
		 "DATE_CLAIM_CLOSED": "02-07-2023 ",
		 "ACTUAL_RTW_DATE": "02-02-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nava",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2022-01-17-0476-VP-01",
		 "DURATION": 53,
		 "DISABILITY_START_DATE": "03-03-2022 ",
		 "DATE_CLAIM_CLOSED": "04-29-2022 ",
		 "ACTUAL_RTW_DATE": "04-25-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Navalta",
		 "CLAIMANT_FIRST_NAME": "Beverly",
		 "CLAIM_NO": "2013-01-05-0450-VP-01",
		 "DURATION": 119,
		 "DISABILITY_START_DATE": "11-28-2012 ",
		 "DATE_CLAIM_CLOSED": "07-10-2013 ",
		 "ACTUAL_RTW_DATE": "03-27-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Navarrete",
		 "CLAIMANT_FIRST_NAME": "Sergio",
		 "CLAIM_NO": "2023-01-17-1221-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "01-09-2023 ",
		 "DATE_CLAIM_CLOSED": "02-10-2023 ",
		 "ACTUAL_RTW_DATE": "01-23-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Navarro",
		 "CLAIMANT_FIRST_NAME": "Carlos",
		 "CLAIM_NO": "2021-01-07-0983-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "01-04-2021 ",
		 "DATE_CLAIM_CLOSED": "01-11-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Navarro",
		 "CLAIMANT_FIRST_NAME": "Esther",
		 "CLAIM_NO": "2014-07-24-0248-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "07-24-2014 ",
		 "DATE_CLAIM_CLOSED": "01-12-2015 ",
		 "ACTUAL_RTW_DATE": "08-25-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Navarro",
		 "CLAIMANT_FIRST_NAME": "Yadira",
		 "CLAIM_NO": "2017-11-20-0336-VP-01",
		 "DURATION": 206,
		 "DISABILITY_START_DATE": "11-17-2017 ",
		 "DATE_CLAIM_CLOSED": "07-09-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Navarro",
		 "CLAIMANT_FIRST_NAME": "Israel",
		 "CLAIM_NO": "2019-06-17-0353-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "06-17-2019 ",
		 "DATE_CLAIM_CLOSED": "08-08-2019 ",
		 "ACTUAL_RTW_DATE": "08-05-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nayak",
		 "CLAIMANT_FIRST_NAME": "Sushma",
		 "CLAIM_NO": "2013-04-01-0386-VP-01",
		 "DURATION": 105,
		 "DISABILITY_START_DATE": "04-25-2013 ",
		 "DATE_CLAIM_CLOSED": "07-02-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Negrete",
		 "CLAIMANT_FIRST_NAME": "Sandilynn",
		 "CLAIM_NO": "2015-07-10-0265-VP-01",
		 "DURATION": 6,
		 "DISABILITY_START_DATE": "07-30-2015 ",
		 "DATE_CLAIM_CLOSED": "09-22-2015 ",
		 "ACTUAL_RTW_DATE": "08-05-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Negrete",
		 "CLAIMANT_FIRST_NAME": "Sandilynn",
		 "CLAIM_NO": "2013-01-05-0064-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "12-13-2012 ",
		 "DATE_CLAIM_CLOSED": "05-07-2013 ",
		 "ACTUAL_RTW_DATE": "01-08-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Negrete",
		 "CLAIMANT_FIRST_NAME": "Sandilynn",
		 "CLAIM_NO": "2013-05-20-0067-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "06-04-2013 ",
		 "DATE_CLAIM_CLOSED": "07-15-2013 ",
		 "ACTUAL_RTW_DATE": "06-24-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Negrete",
		 "CLAIMANT_FIRST_NAME": "Sandilynn",
		 "CLAIM_NO": "2014-04-08-0249-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "04-03-2014 ",
		 "DATE_CLAIM_CLOSED": "02-09-2016 ",
		 "ACTUAL_RTW_DATE": "04-21-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Negrete",
		 "CLAIMANT_FIRST_NAME": "Jezzica",
		 "CLAIM_NO": "2021-05-18-0923-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "06-04-2021 ",
		 "DATE_CLAIM_CLOSED": "07-29-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Negrete",
		 "CLAIMANT_FIRST_NAME": "Sandilynn",
		 "CLAIM_NO": "2015-10-22-0394-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "10-19-2015 ",
		 "DATE_CLAIM_CLOSED": "11-24-2015 ",
		 "ACTUAL_RTW_DATE": "11-23-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Neilsen",
		 "CLAIMANT_FIRST_NAME": "Jeffrey",
		 "CLAIM_NO": "2019-09-04-0356-VP-01",
		 "DURATION": 105,
		 "DISABILITY_START_DATE": "09-18-2019 ",
		 "DATE_CLAIM_CLOSED": "01-14-2020 ",
		 "ACTUAL_RTW_DATE": "01-01-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Neilsen",
		 "CLAIMANT_FIRST_NAME": "Jeffrey",
		 "CLAIM_NO": "2021-03-31-0514-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "05-04-2021 ",
		 "DATE_CLAIM_CLOSED": "07-09-2021 ",
		 "ACTUAL_RTW_DATE": "07-05-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nejad",
		 "CLAIMANT_FIRST_NAME": "Borzoo",
		 "CLAIM_NO": "2022-07-28-0348-VP-01",
		 "DURATION": 216,
		 "DISABILITY_START_DATE": "06-01-2022 ",
		 "DATE_CLAIM_CLOSED": "01-06-2023 ",
		 "ACTUAL_RTW_DATE": "01-03-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nelson",
		 "CLAIMANT_FIRST_NAME": "Eric",
		 "CLAIM_NO": "2022-08-11-0649-VP-01",
		 "DURATION": 23,
		 "DISABILITY_START_DATE": "08-09-2022 ",
		 "DATE_CLAIM_CLOSED": "08-24-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nelson",
		 "CLAIMANT_FIRST_NAME": "Craig",
		 "CLAIM_NO": "2017-10-26-0396-VP-01",
		 "DURATION": 118,
		 "DISABILITY_START_DATE": "11-21-2017 ",
		 "DATE_CLAIM_CLOSED": "03-20-2018 ",
		 "ACTUAL_RTW_DATE": "03-19-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nelson",
		 "CLAIMANT_FIRST_NAME": "Craig",
		 "CLAIM_NO": "2014-08-01-0312-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "08-12-2014 ",
		 "DATE_CLAIM_CLOSED": "09-29-2014 ",
		 "ACTUAL_RTW_DATE": "09-22-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ner",
		 "CLAIMANT_FIRST_NAME": "Nelson",
		 "CLAIM_NO": "2017-12-13-0195-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "12-07-2017 ",
		 "DATE_CLAIM_CLOSED": "01-11-2018 ",
		 "ACTUAL_RTW_DATE": "12-18-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Netravali",
		 "CLAIMANT_FIRST_NAME": "Ashley",
		 "CLAIM_NO": "2015-07-21-0196-VP-01",
		 "DURATION": 65,
		 "DISABILITY_START_DATE": "08-24-2015 ",
		 "DATE_CLAIM_CLOSED": "11-23-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Netravali",
		 "CLAIMANT_FIRST_NAME": "Ashley",
		 "CLAIM_NO": "2013-07-12-0126-VP-01",
		 "DURATION": 67,
		 "DISABILITY_START_DATE": "08-14-2013 ",
		 "DATE_CLAIM_CLOSED": "11-18-2013 ",
		 "ACTUAL_RTW_DATE": "10-20-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Newell",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2018-07-11-0562-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "07-09-2018 ",
		 "DATE_CLAIM_CLOSED": "09-04-2018 ",
		 "ACTUAL_RTW_DATE": "08-13-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Newton",
		 "CLAIMANT_FIRST_NAME": "Giulia",
		 "CLAIM_NO": "2016-04-11-0120-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "03-31-2016 ",
		 "DATE_CLAIM_CLOSED": "05-02-2016 ",
		 "ACTUAL_RTW_DATE": "04-28-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ngatino",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2020-06-04-0731-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "07-20-2020 ",
		 "DATE_CLAIM_CLOSED": "09-21-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ngo",
		 "CLAIMANT_FIRST_NAME": "Vu",
		 "CLAIM_NO": "2019-11-05-0827-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "10-28-2019 ",
		 "DATE_CLAIM_CLOSED": "11-23-2019 ",
		 "ACTUAL_RTW_DATE": "11-11-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ngo",
		 "CLAIMANT_FIRST_NAME": "Helen",
		 "CLAIM_NO": "2023-04-08-0056-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "05-29-2023 ",
		 "DATE_CLAIM_CLOSED": "04-28-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ngo",
		 "CLAIMANT_FIRST_NAME": "Geraldine",
		 "CLAIM_NO": "2021-11-15-0865-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "11-11-2021 ",
		 "DATE_CLAIM_CLOSED": "11-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ngo",
		 "CLAIMANT_FIRST_NAME": "Uyendai",
		 "CLAIM_NO": "2022-02-24-0652-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "02-21-2022 ",
		 "DATE_CLAIM_CLOSED": "03-24-2022 ",
		 "ACTUAL_RTW_DATE": "03-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Duc",
		 "CLAIM_NO": "2022-04-08-0451-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "04-06-2022 ",
		 "DATE_CLAIM_CLOSED": "05-25-2022 ",
		 "ACTUAL_RTW_DATE": "05-23-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Thi",
		 "CLAIM_NO": "2022-12-13-0909-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "01-05-2023 ",
		 "DATE_CLAIM_CLOSED": "02-28-2023 ",
		 "ACTUAL_RTW_DATE": "02-17-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Tiffany",
		 "CLAIM_NO": "2022-07-20-1103-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "07-25-2022 ",
		 "DATE_CLAIM_CLOSED": "08-25-2022 ",
		 "ACTUAL_RTW_DATE": "08-22-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Helen",
		 "CLAIM_NO": "2018-07-18-0298-VP-01",
		 "DURATION": 371,
		 "DISABILITY_START_DATE": "07-18-2018 ",
		 "DATE_CLAIM_CLOSED": "08-09-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Tuong",
		 "CLAIM_NO": "2014-11-18-0004-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "11-14-2014 ",
		 "DATE_CLAIM_CLOSED": "12-12-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Hongdiep",
		 "CLAIM_NO": "2016-01-14-0004-VP-01",
		 "DURATION": 92,
		 "DISABILITY_START_DATE": "01-14-2016 ",
		 "DATE_CLAIM_CLOSED": "06-07-2016 ",
		 "ACTUAL_RTW_DATE": "04-15-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Bich",
		 "CLAIM_NO": "2021-09-01-0989-VP-01",
		 "DURATION": 25,
		 "DISABILITY_START_DATE": "09-02-2021 ",
		 "DATE_CLAIM_CLOSED": "10-06-2021 ",
		 "ACTUAL_RTW_DATE": "09-27-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Andy",
		 "CLAIM_NO": "2013-10-10-0181-VP-01",
		 "DURATION": 61,
		 "DISABILITY_START_DATE": "10-11-2013 ",
		 "DATE_CLAIM_CLOSED": "12-24-2013 ",
		 "ACTUAL_RTW_DATE": "12-11-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Cynthia",
		 "CLAIM_NO": "2013-02-01-0215-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "01-28-2013 ",
		 "DATE_CLAIM_CLOSED": "07-10-2013 ",
		 "ACTUAL_RTW_DATE": "03-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Cindy",
		 "CLAIM_NO": "2017-10-03-0226-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "09-11-2017 ",
		 "DATE_CLAIM_CLOSED": "11-30-2017 ",
		 "ACTUAL_RTW_DATE": "11-20-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Bich",
		 "CLAIM_NO": "2019-08-22-0646-VP-01",
		 "DURATION": 111,
		 "DISABILITY_START_DATE": "08-23-2019 ",
		 "DATE_CLAIM_CLOSED": "01-14-2020 ",
		 "ACTUAL_RTW_DATE": "12-12-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Hai Au",
		 "CLAIM_NO": "2020-08-14-0552-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "08-14-2020 ",
		 "DATE_CLAIM_CLOSED": "09-09-2020 ",
		 "ACTUAL_RTW_DATE": "08-26-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Hai Au",
		 "CLAIM_NO": "2020-11-13-0665-VP-01",
		 "DURATION": 207,
		 "DISABILITY_START_DATE": "11-02-2020 ",
		 "DATE_CLAIM_CLOSED": "05-28-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Tan Huu",
		 "CLAIM_NO": "2018-09-21-0230-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "09-20-2018 ",
		 "DATE_CLAIM_CLOSED": "01-25-2019 ",
		 "ACTUAL_RTW_DATE": "10-01-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Mai Ngoc",
		 "CLAIM_NO": "2019-01-07-0747-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "01-08-2019 ",
		 "DATE_CLAIM_CLOSED": "03-26-2019 ",
		 "ACTUAL_RTW_DATE": "02-11-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Van",
		 "CLAIM_NO": "2016-04-05-0081-VP-01",
		 "DURATION": 328,
		 "DISABILITY_START_DATE": "04-05-2016 ",
		 "DATE_CLAIM_CLOSED": "02-27-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Truong-Thuy",
		 "CLAIM_NO": "2014-06-02-0451-VP-01",
		 "DURATION": 23,
		 "DISABILITY_START_DATE": "05-22-2014 ",
		 "DATE_CLAIM_CLOSED": "01-21-2015 ",
		 "ACTUAL_RTW_DATE": "06-14-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Phuong Ha Thi",
		 "CLAIM_NO": "2019-06-19-0685-VP-01",
		 "DURATION": 209,
		 "DISABILITY_START_DATE": "06-07-2019 ",
		 "DATE_CLAIM_CLOSED": "02-13-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Francois",
		 "CLAIM_NO": "2021-11-10-0916-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "11-22-2021 ",
		 "DATE_CLAIM_CLOSED": "12-09-2021 ",
		 "ACTUAL_RTW_DATE": "12-06-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Mai",
		 "CLAIM_NO": "2013-01-05-4307-VP-01",
		 "DURATION": 367,
		 "DISABILITY_START_DATE": "08-19-2011 ",
		 "DATE_CLAIM_CLOSED": "04-06-2013 ",
		 "ACTUAL_RTW_DATE": "08-20-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Hongdiep",
		 "CLAIM_NO": "2013-01-18-0140-VP-01",
		 "DURATION": 182,
		 "DISABILITY_START_DATE": "01-14-2013 ",
		 "DATE_CLAIM_CLOSED": "01-02-2014 ",
		 "ACTUAL_RTW_DATE": "07-15-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Thanh Thi",
		 "CLAIM_NO": "2018-08-27-0548-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "08-24-2018 ",
		 "DATE_CLAIM_CLOSED": "10-01-2018 ",
		 "ACTUAL_RTW_DATE": "09-24-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Xuan Thieu",
		 "CLAIM_NO": "2019-10-02-0387-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "09-30-2019 ",
		 "DATE_CLAIM_CLOSED": "11-12-2019 ",
		 "ACTUAL_RTW_DATE": "11-04-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Loan Anh",
		 "CLAIM_NO": "2022-10-04-0915-VP-01",
		 "DURATION": 139,
		 "DISABILITY_START_DATE": "10-12-2022 ",
		 "DATE_CLAIM_CLOSED": "12-27-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Hang Vu",
		 "CLAIM_NO": "2022-04-11-0567-VP-01",
		 "DURATION": 74,
		 "DISABILITY_START_DATE": "04-18-2022 ",
		 "DATE_CLAIM_CLOSED": "07-05-2022 ",
		 "ACTUAL_RTW_DATE": "07-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "The Tuan",
		 "CLAIM_NO": "2022-08-22-0879-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "08-18-2022 ",
		 "DATE_CLAIM_CLOSED": "12-14-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Hongdiep",
		 "CLAIM_NO": "2015-10-08-0215-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "10-08-2015 ",
		 "DATE_CLAIM_CLOSED": "12-01-2015 ",
		 "ACTUAL_RTW_DATE": "11-25-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Hai Au",
		 "CLAIM_NO": "2022-04-26-1017-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "04-25-2022 ",
		 "DATE_CLAIM_CLOSED": "08-01-2022 ",
		 "ACTUAL_RTW_DATE": "07-25-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Liem",
		 "CLAIM_NO": "2019-11-18-0347-VP-01",
		 "DURATION": 181,
		 "DISABILITY_START_DATE": "11-18-2019 ",
		 "DATE_CLAIM_CLOSED": "05-22-2020 ",
		 "ACTUAL_RTW_DATE": "05-17-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Kim Nhung",
		 "CLAIM_NO": "2021-10-26-0410-VP-01",
		 "DURATION": 86,
		 "DISABILITY_START_DATE": "10-10-2021 ",
		 "DATE_CLAIM_CLOSED": "01-25-2022 ",
		 "ACTUAL_RTW_DATE": "01-04-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Tiffany",
		 "CLAIM_NO": "2021-02-05-0010-VP-01",
		 "DURATION": 168,
		 "DISABILITY_START_DATE": "02-08-2021 ",
		 "DATE_CLAIM_CLOSED": "07-27-2021 ",
		 "ACTUAL_RTW_DATE": "07-26-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Phu",
		 "CLAIM_NO": "2013-03-12-0009-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "03-04-2013 ",
		 "DATE_CLAIM_CLOSED": "05-06-2013 ",
		 "ACTUAL_RTW_DATE": "03-13-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Amy",
		 "CLAIM_NO": "2021-09-08-1162-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "09-17-2021 ",
		 "DATE_CLAIM_CLOSED": "12-21-2021 ",
		 "ACTUAL_RTW_DATE": "12-17-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Loan Anh",
		 "CLAIM_NO": "2020-01-07-1112-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "02-10-2020 ",
		 "DATE_CLAIM_CLOSED": "04-22-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Duc",
		 "CLAIM_NO": "2021-04-21-0495-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "04-19-2021 ",
		 "DATE_CLAIM_CLOSED": "05-19-2021 ",
		 "ACTUAL_RTW_DATE": "05-17-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Tram",
		 "CLAIM_NO": "2018-11-01-0144-VP-01",
		 "DURATION": 50,
		 "DISABILITY_START_DATE": "11-12-2018 ",
		 "DATE_CLAIM_CLOSED": "01-17-2019 ",
		 "ACTUAL_RTW_DATE": "01-01-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Bich",
		 "CLAIM_NO": "2022-03-13-0003-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "03-14-2022 ",
		 "DATE_CLAIM_CLOSED": "05-24-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Duc",
		 "CLAIM_NO": "2019-06-01-0018-VP-01",
		 "DURATION": 78,
		 "DISABILITY_START_DATE": "06-12-2019 ",
		 "DATE_CLAIM_CLOSED": "09-06-2019 ",
		 "ACTUAL_RTW_DATE": "08-29-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "Tram",
		 "CLAIM_NO": "2021-11-24-0736-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "12-20-2021 ",
		 "DATE_CLAIM_CLOSED": "02-08-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen",
		 "CLAIMANT_FIRST_NAME": "My Dzuyen",
		 "CLAIM_NO": "2019-04-17-0006-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "04-19-2019 ",
		 "DATE_CLAIM_CLOSED": "05-21-2019 ",
		 "ACTUAL_RTW_DATE": "05-17-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen Do",
		 "CLAIMANT_FIRST_NAME": "Van",
		 "CLAIM_NO": "2020-08-24-0768-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "08-31-2020 ",
		 "DATE_CLAIM_CLOSED": "10-12-2020 ",
		 "ACTUAL_RTW_DATE": "10-05-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nguyen-Brown",
		 "CLAIMANT_FIRST_NAME": "Minh-Trang",
		 "CLAIM_NO": "2022-07-01-0335-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "07-04-2022 ",
		 "DATE_CLAIM_CLOSED": "08-19-2022 ",
		 "ACTUAL_RTW_DATE": "08-15-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nhonthachith",
		 "CLAIMANT_FIRST_NAME": "Phonesavanh",
		 "CLAIM_NO": "2015-02-06-0276-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "01-28-2015 ",
		 "DATE_CLAIM_CLOSED": "04-13-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nichols",
		 "CLAIMANT_FIRST_NAME": "Nancy",
		 "CLAIM_NO": "2019-05-19-0011-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "05-10-2019 ",
		 "DATE_CLAIM_CLOSED": "06-13-2019 ",
		 "ACTUAL_RTW_DATE": "06-10-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nichols",
		 "CLAIMANT_FIRST_NAME": "Peter",
		 "CLAIM_NO": "2019-09-24-0592-VP-01",
		 "DURATION": 154,
		 "DISABILITY_START_DATE": "07-29-2019 ",
		 "DATE_CLAIM_CLOSED": "03-12-2020 ",
		 "ACTUAL_RTW_DATE": "12-30-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nicholson",
		 "CLAIMANT_FIRST_NAME": "Victoria",
		 "CLAIM_NO": "2013-09-11-0376-VP-01",
		 "DURATION": 85,
		 "DISABILITY_START_DATE": "09-24-2013 ",
		 "DATE_CLAIM_CLOSED": "12-20-2013 ",
		 "ACTUAL_RTW_DATE": "12-18-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nickel",
		 "CLAIMANT_FIRST_NAME": "Douglas",
		 "CLAIM_NO": "2014-03-21-0206-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "03-14-2014 ",
		 "DATE_CLAIM_CLOSED": "01-20-2015 ",
		 "ACTUAL_RTW_DATE": "03-31-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nierlich",
		 "CLAIMANT_FIRST_NAME": "Steve",
		 "CLAIM_NO": "2021-04-15-0479-VP-01",
		 "DURATION": 66,
		 "DISABILITY_START_DATE": "04-26-2021 ",
		 "DATE_CLAIM_CLOSED": "07-06-2021 ",
		 "ACTUAL_RTW_DATE": "07-01-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nikola",
		 "CLAIMANT_FIRST_NAME": "Marla",
		 "CLAIM_NO": "2019-09-18-0343-VP-01",
		 "DURATION": 66,
		 "DISABILITY_START_DATE": "09-17-2019 ",
		 "DATE_CLAIM_CLOSED": "12-23-2019 ",
		 "ACTUAL_RTW_DATE": "11-22-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nila",
		 "CLAIMANT_FIRST_NAME": "Gerardo",
		 "CLAIM_NO": "2018-07-31-0574-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "07-30-2018 ",
		 "DATE_CLAIM_CLOSED": "08-22-2018 ",
		 "ACTUAL_RTW_DATE": "08-08-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nila",
		 "CLAIMANT_FIRST_NAME": "Maribel",
		 "CLAIM_NO": "2022-12-15-0698-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "01-03-2023 ",
		 "DATE_CLAIM_CLOSED": "03-07-2023 ",
		 "ACTUAL_RTW_DATE": "02-28-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nolasco",
		 "CLAIMANT_FIRST_NAME": "Deja",
		 "CLAIM_NO": "2020-09-03-0453-VP-01",
		 "DURATION": 147,
		 "DISABILITY_START_DATE": "09-03-2020 ",
		 "DATE_CLAIM_CLOSED": "02-05-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Noriega",
		 "CLAIMANT_FIRST_NAME": "Sylvia",
		 "CLAIM_NO": "2019-07-01-0787-VP-01",
		 "DURATION": 90,
		 "DISABILITY_START_DATE": "07-17-2019 ",
		 "DATE_CLAIM_CLOSED": "10-20-2019 ",
		 "ACTUAL_RTW_DATE": "10-15-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Noriega",
		 "CLAIMANT_FIRST_NAME": "Gloria",
		 "CLAIM_NO": "2021-09-01-0995-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "09-15-2021 ",
		 "DATE_CLAIM_CLOSED": "10-26-2021 ",
		 "ACTUAL_RTW_DATE": "10-18-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Noriega",
		 "CLAIMANT_FIRST_NAME": "Sylvia",
		 "CLAIM_NO": "2013-11-26-0105-VP-01",
		 "DURATION": 104,
		 "DISABILITY_START_DATE": "12-10-2013 ",
		 "DATE_CLAIM_CLOSED": "10-06-2014 ",
		 "ACTUAL_RTW_DATE": "03-24-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Norris",
		 "CLAIMANT_FIRST_NAME": "Danielle",
		 "CLAIM_NO": "2013-06-11-0350-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "07-01-2013 ",
		 "DATE_CLAIM_CLOSED": "06-24-2013 ",
		 "ACTUAL_RTW_DATE": "08-26-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nostadt",
		 "CLAIMANT_FIRST_NAME": "Michael",
		 "CLAIM_NO": "2018-07-05-0599-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "07-02-2018 ",
		 "DATE_CLAIM_CLOSED": "11-28-2018 ",
		 "ACTUAL_RTW_DATE": "09-24-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nowlan",
		 "CLAIMANT_FIRST_NAME": "Sean",
		 "CLAIM_NO": "2018-09-26-0132-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "09-28-2018 ",
		 "DATE_CLAIM_CLOSED": "11-02-2018 ",
		 "ACTUAL_RTW_DATE": "10-29-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nunes",
		 "CLAIMANT_FIRST_NAME": "Randy",
		 "CLAIM_NO": "2016-09-06-0105-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "08-29-2016 ",
		 "DATE_CLAIM_CLOSED": "09-27-2016 ",
		 "ACTUAL_RTW_DATE": "09-06-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nunez",
		 "CLAIMANT_FIRST_NAME": "Luz",
		 "CLAIM_NO": "2022-10-11-0854-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "10-10-2022 ",
		 "DATE_CLAIM_CLOSED": "11-10-2022 ",
		 "ACTUAL_RTW_DATE": "10-23-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nunez",
		 "CLAIMANT_FIRST_NAME": "Luz",
		 "CLAIM_NO": "2019-09-24-0546-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "09-23-2019 ",
		 "DATE_CLAIM_CLOSED": "10-14-2019 ",
		 "ACTUAL_RTW_DATE": "10-07-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Nystrom",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2018-12-22-0028-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "12-26-2018 ",
		 "DATE_CLAIM_CLOSED": "02-06-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "O'Banion",
		 "CLAIMANT_FIRST_NAME": "Mary",
		 "CLAIM_NO": "2014-11-03-0200-VP-01",
		 "DURATION": 230,
		 "DISABILITY_START_DATE": "09-22-2014 ",
		 "DATE_CLAIM_CLOSED": "07-28-2015 ",
		 "ACTUAL_RTW_DATE": "05-10-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "O'Campo",
		 "CLAIMANT_FIRST_NAME": "Emily",
		 "CLAIM_NO": "2013-02-21-0050-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "02-18-2013 ",
		 "DATE_CLAIM_CLOSED": "02-22-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "O'Donnell",
		 "CLAIMANT_FIRST_NAME": "Deborah",
		 "CLAIM_NO": "2018-07-09-0370-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "09-04-2018 ",
		 "DATE_CLAIM_CLOSED": "11-07-2018 ",
		 "ACTUAL_RTW_DATE": "11-01-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "O'Keefe",
		 "CLAIMANT_FIRST_NAME": "Kimberly",
		 "CLAIM_NO": "2013-05-14-0272-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "05-14-2013 ",
		 "DATE_CLAIM_CLOSED": "07-25-2013 ",
		 "ACTUAL_RTW_DATE": "06-11-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "O'Neil",
		 "CLAIMANT_FIRST_NAME": "Buttons",
		 "CLAIM_NO": "2021-12-09-1237-VP-01",
		 "DURATION": 164,
		 "DISABILITY_START_DATE": "01-07-2022 ",
		 "DATE_CLAIM_CLOSED": "06-21-2022 ",
		 "ACTUAL_RTW_DATE": "06-20-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "O'Neill",
		 "CLAIMANT_FIRST_NAME": "Catherine",
		 "CLAIM_NO": "2015-04-08-0250-VP-01",
		 "DURATION": 6,
		 "DISABILITY_START_DATE": "04-07-2015 ",
		 "DATE_CLAIM_CLOSED": "05-05-2015 ",
		 "ACTUAL_RTW_DATE": "04-13-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "O'Sullivan",
		 "CLAIMANT_FIRST_NAME": "Stephanie",
		 "CLAIM_NO": "2016-04-13-0231-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "05-09-2016 ",
		 "DATE_CLAIM_CLOSED": "07-11-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "O'Toole",
		 "CLAIMANT_FIRST_NAME": "Roisin",
		 "CLAIM_NO": "2018-03-07-0203-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "02-28-2018 ",
		 "DATE_CLAIM_CLOSED": "04-11-2018 ",
		 "ACTUAL_RTW_DATE": "04-09-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Oberst",
		 "CLAIMANT_FIRST_NAME": "James",
		 "CLAIM_NO": "2013-06-18-0137-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "06-18-2013 ",
		 "DATE_CLAIM_CLOSED": "10-29-2013 ",
		 "ACTUAL_RTW_DATE": "07-09-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ochoa",
		 "CLAIMANT_FIRST_NAME": "Nora",
		 "CLAIM_NO": "2016-12-05-0582-VP-01",
		 "DURATION": 74,
		 "DISABILITY_START_DATE": "12-01-2016 ",
		 "DATE_CLAIM_CLOSED": "02-15-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ochoa",
		 "CLAIMANT_FIRST_NAME": "Jacqueline",
		 "CLAIM_NO": "2017-07-13-0582-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "07-11-2017 ",
		 "DATE_CLAIM_CLOSED": "08-02-2017 ",
		 "ACTUAL_RTW_DATE": "07-31-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ochoa-Manriquez",
		 "CLAIMANT_FIRST_NAME": "Sylvia",
		 "CLAIM_NO": "2020-02-04-0377-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "02-10-2020 ",
		 "DATE_CLAIM_CLOSED": "04-14-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Odegaard",
		 "CLAIMANT_FIRST_NAME": "Linda",
		 "CLAIM_NO": "2014-07-30-0515-VP-01",
		 "DURATION": 53,
		 "DISABILITY_START_DATE": "08-21-2014 ",
		 "DATE_CLAIM_CLOSED": "10-20-2014 ",
		 "ACTUAL_RTW_DATE": "10-13-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ogunde",
		 "CLAIMANT_FIRST_NAME": "Regina",
		 "CLAIM_NO": "2013-01-05-3208-VP-01",
		 "DURATION": 652,
		 "DISABILITY_START_DATE": "01-04-2012 ",
		 "DATE_CLAIM_CLOSED": "03-17-2014 ",
		 "ACTUAL_RTW_DATE": "10-17-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ohene-Nyako",
		 "CLAIMANT_FIRST_NAME": "Afua",
		 "CLAIM_NO": "2014-09-02-0492-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "09-15-2014 ",
		 "DATE_CLAIM_CLOSED": "10-30-2014 ",
		 "ACTUAL_RTW_DATE": "10-25-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ohene-Nyako",
		 "CLAIMANT_FIRST_NAME": "Afua",
		 "CLAIM_NO": "2016-06-08-0478-VP-01",
		 "DURATION": 44,
		 "DISABILITY_START_DATE": "06-29-2016 ",
		 "DATE_CLAIM_CLOSED": "09-02-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Oiye",
		 "CLAIMANT_FIRST_NAME": "Nancy",
		 "CLAIM_NO": "2017-04-26-0220-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "04-26-2017 ",
		 "DATE_CLAIM_CLOSED": "07-10-2017 ",
		 "ACTUAL_RTW_DATE": "07-05-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ojeda",
		 "CLAIMANT_FIRST_NAME": "Juanita",
		 "CLAIM_NO": "2022-01-21-0433-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "01-10-2022 ",
		 "DATE_CLAIM_CLOSED": "03-01-2022 ",
		 "ACTUAL_RTW_DATE": "02-24-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ojeda",
		 "CLAIMANT_FIRST_NAME": "Juanita",
		 "CLAIM_NO": "2021-10-26-0982-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "10-25-2021 ",
		 "DATE_CLAIM_CLOSED": "01-18-2022 ",
		 "ACTUAL_RTW_DATE": "11-15-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Olalia",
		 "CLAIMANT_FIRST_NAME": "Yemmi",
		 "CLAIM_NO": "2022-11-12-0044-VP-01",
		 "DURATION": 85,
		 "DISABILITY_START_DATE": "12-12-2022 ",
		 "DATE_CLAIM_CLOSED": "02-04-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Olaniyan",
		 "CLAIMANT_FIRST_NAME": "Sarah",
		 "CLAIM_NO": "2022-06-24-0878-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "07-15-2022 ",
		 "DATE_CLAIM_CLOSED": "08-17-2022 ",
		 "ACTUAL_RTW_DATE": "08-15-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Olasov",
		 "CLAIMANT_FIRST_NAME": "Ingrid",
		 "CLAIM_NO": "2019-08-01-0603-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "09-26-2019 ",
		 "DATE_CLAIM_CLOSED": "11-12-2019 ",
		 "ACTUAL_RTW_DATE": "11-07-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Olasov",
		 "CLAIMANT_FIRST_NAME": "Ingrid",
		 "CLAIM_NO": "2022-08-30-0427-VP-01",
		 "DURATION": 50,
		 "DISABILITY_START_DATE": "10-10-2022 ",
		 "DATE_CLAIM_CLOSED": "12-27-2022 ",
		 "ACTUAL_RTW_DATE": "11-29-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Oliva",
		 "CLAIMANT_FIRST_NAME": "Cesar",
		 "CLAIM_NO": "2020-10-15-0376-VP-01",
		 "DURATION": 93,
		 "DISABILITY_START_DATE": "10-15-2020 ",
		 "DATE_CLAIM_CLOSED": "01-19-2021 ",
		 "ACTUAL_RTW_DATE": "01-16-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Oliva",
		 "CLAIMANT_FIRST_NAME": "Cesar",
		 "CLAIM_NO": "2022-01-18-0482-VP-01",
		 "DURATION": 96,
		 "DISABILITY_START_DATE": "01-14-2022 ",
		 "DATE_CLAIM_CLOSED": "04-25-2022 ",
		 "ACTUAL_RTW_DATE": "04-20-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Oliva",
		 "CLAIMANT_FIRST_NAME": "Cesar",
		 "CLAIM_NO": "2020-05-27-0738-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "05-29-2020 ",
		 "DATE_CLAIM_CLOSED": "07-06-2020 ",
		 "ACTUAL_RTW_DATE": "06-29-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Olivares",
		 "CLAIMANT_FIRST_NAME": "Lourdes",
		 "CLAIM_NO": "2013-01-05-0538-VP-01",
		 "DURATION": 126,
		 "DISABILITY_START_DATE": "10-22-2012 ",
		 "DATE_CLAIM_CLOSED": "04-07-2013 ",
		 "ACTUAL_RTW_DATE": "02-25-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Olivares",
		 "CLAIMANT_FIRST_NAME": "Elmer",
		 "CLAIM_NO": "2018-01-18-0671-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "01-02-2018 ",
		 "DATE_CLAIM_CLOSED": "02-26-2018 ",
		 "ACTUAL_RTW_DATE": "01-15-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Olivares-Cobian",
		 "CLAIMANT_FIRST_NAME": "Sonia",
		 "CLAIM_NO": "2020-06-08-0926-VP-01",
		 "DURATION": 65,
		 "DISABILITY_START_DATE": "06-23-2020 ",
		 "DATE_CLAIM_CLOSED": "09-01-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Olivares-Cobian",
		 "CLAIMANT_FIRST_NAME": "Sonia",
		 "CLAIM_NO": "2017-03-27-0380-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "04-17-2017 ",
		 "DATE_CLAIM_CLOSED": "07-03-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Oliver Bracamontes",
		 "CLAIMANT_FIRST_NAME": "Raquel",
		 "CLAIM_NO": "2016-12-22-0379-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "12-23-2016 ",
		 "DATE_CLAIM_CLOSED": "03-22-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Olivier",
		 "CLAIMANT_FIRST_NAME": "Kay",
		 "CLAIM_NO": "2013-01-05-1009-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "06-14-2012 ",
		 "DATE_CLAIM_CLOSED": "01-15-2014 ",
		 "ACTUAL_RTW_DATE": "08-23-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Olp",
		 "CLAIMANT_FIRST_NAME": "Amber",
		 "CLAIM_NO": "2013-12-13-0296-VP-01",
		 "DURATION": 363,
		 "DISABILITY_START_DATE": "12-13-2013 ",
		 "DATE_CLAIM_CLOSED": "12-16-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Olp",
		 "CLAIMANT_FIRST_NAME": "Amber",
		 "CLAIM_NO": "2013-04-30-0217-VP-01",
		 "DURATION": 184,
		 "DISABILITY_START_DATE": "05-14-2013 ",
		 "DATE_CLAIM_CLOSED": "11-18-2013 ",
		 "ACTUAL_RTW_DATE": "11-14-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Olsen",
		 "CLAIMANT_FIRST_NAME": "Kristine",
		 "CLAIM_NO": "2016-04-14-0295-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "04-18-2016 ",
		 "DATE_CLAIM_CLOSED": "05-16-2016 ",
		 "ACTUAL_RTW_DATE": "05-09-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Olsen",
		 "CLAIMANT_FIRST_NAME": "Kristine",
		 "CLAIM_NO": "2014-09-24-0557-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "11-03-2014 ",
		 "DATE_CLAIM_CLOSED": "01-06-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Olson",
		 "CLAIMANT_FIRST_NAME": "Mark",
		 "CLAIM_NO": "2018-03-02-0531-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "03-08-2018 ",
		 "DATE_CLAIM_CLOSED": "04-16-2018 ",
		 "ACTUAL_RTW_DATE": "04-09-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Olson",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2021-07-14-0279-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "08-05-2021 ",
		 "DATE_CLAIM_CLOSED": "10-12-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ong",
		 "CLAIMANT_FIRST_NAME": "Chia Hoay",
		 "CLAIM_NO": "2018-01-12-0664-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "01-15-2018 ",
		 "DATE_CLAIM_CLOSED": "03-28-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ong",
		 "CLAIMANT_FIRST_NAME": "Kim",
		 "CLAIM_NO": "2013-02-13-0240-VP-01",
		 "DURATION": 83,
		 "DISABILITY_START_DATE": "03-01-2013 ",
		 "DATE_CLAIM_CLOSED": "08-29-2013 ",
		 "ACTUAL_RTW_DATE": "05-23-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Onoza",
		 "CLAIMANT_FIRST_NAME": "Gemma",
		 "CLAIM_NO": "2014-11-21-0224-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "11-20-2014 ",
		 "DATE_CLAIM_CLOSED": "12-16-2014 ",
		 "ACTUAL_RTW_DATE": "12-04-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ontiveros",
		 "CLAIMANT_FIRST_NAME": "Carmen",
		 "CLAIM_NO": "2021-12-14-0974-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "01-05-2022 ",
		 "DATE_CLAIM_CLOSED": "01-31-2022 ",
		 "ACTUAL_RTW_DATE": "01-25-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ontiveros",
		 "CLAIMANT_FIRST_NAME": "Bal",
		 "CLAIM_NO": "2019-10-10-0433-VP-01",
		 "DURATION": 111,
		 "DISABILITY_START_DATE": "07-26-2019 ",
		 "DATE_CLAIM_CLOSED": "09-18-2020 ",
		 "ACTUAL_RTW_DATE": "11-14-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ontiveros",
		 "CLAIMANT_FIRST_NAME": "Lilibeth",
		 "CLAIM_NO": "2021-01-04-1272-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "01-14-2021 ",
		 "DATE_CLAIM_CLOSED": "03-01-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Opoku",
		 "CLAIMANT_FIRST_NAME": "George",
		 "CLAIM_NO": "2014-06-27-0300-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "06-04-2014 ",
		 "DATE_CLAIM_CLOSED": "01-21-2015 ",
		 "ACTUAL_RTW_DATE": "07-07-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ordanez",
		 "CLAIMANT_FIRST_NAME": "Reimann Louise",
		 "CLAIM_NO": "2022-04-29-0007-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "04-28-2022 ",
		 "DATE_CLAIM_CLOSED": "07-19-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ordonez",
		 "CLAIMANT_FIRST_NAME": "Vanessa",
		 "CLAIM_NO": "2022-06-29-0813-VP-01",
		 "DURATION": 132,
		 "DISABILITY_START_DATE": "08-22-2022 ",
		 "DATE_CLAIM_CLOSED": "11-15-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ordonez",
		 "CLAIMANT_FIRST_NAME": "Diana",
		 "CLAIM_NO": "2022-08-18-0831-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "08-22-2022 ",
		 "DATE_CLAIM_CLOSED": "10-03-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ordonez",
		 "CLAIMANT_FIRST_NAME": "Vanessa",
		 "CLAIM_NO": "2016-12-21-0327-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "03-31-2017 ",
		 "DATE_CLAIM_CLOSED": "06-12-2017 ",
		 "ACTUAL_RTW_DATE": "04-24-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ordonez",
		 "CLAIMANT_FIRST_NAME": "Vanessa",
		 "CLAIM_NO": "2020-01-09-0192-VP-01",
		 "DURATION": 61,
		 "DISABILITY_START_DATE": "02-10-2020 ",
		 "DATE_CLAIM_CLOSED": "04-14-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ordonez",
		 "CLAIMANT_FIRST_NAME": "Emiliana",
		 "CLAIM_NO": "2016-03-03-0310-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "03-01-2016 ",
		 "DATE_CLAIM_CLOSED": "04-18-2016 ",
		 "ACTUAL_RTW_DATE": "04-11-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Orenday",
		 "CLAIMANT_FIRST_NAME": "Yolanda",
		 "CLAIM_NO": "2013-05-02-0073-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "05-31-2013 ",
		 "DATE_CLAIM_CLOSED": "07-02-2013 ",
		 "ACTUAL_RTW_DATE": "07-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Orenday",
		 "CLAIMANT_FIRST_NAME": "Yolanda",
		 "CLAIM_NO": "2018-09-07-0303-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "10-01-2018 ",
		 "DATE_CLAIM_CLOSED": "10-16-2018 ",
		 "ACTUAL_RTW_DATE": "10-15-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Orenday",
		 "CLAIMANT_FIRST_NAME": "Yolanda",
		 "CLAIM_NO": "2021-10-01-0805-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "10-01-2021 ",
		 "DATE_CLAIM_CLOSED": "11-02-2021 ",
		 "ACTUAL_RTW_DATE": "10-25-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Orozco",
		 "CLAIMANT_FIRST_NAME": "Carmen",
		 "CLAIM_NO": "2014-08-19-0348-VP-01",
		 "DURATION": 75,
		 "DISABILITY_START_DATE": "09-17-2014 ",
		 "DATE_CLAIM_CLOSED": "03-26-2015 ",
		 "ACTUAL_RTW_DATE": "12-01-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Orozco",
		 "CLAIMANT_FIRST_NAME": "Carmen",
		 "CLAIM_NO": "2013-01-07-0371-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "01-04-2013 ",
		 "DATE_CLAIM_CLOSED": "04-26-2013 ",
		 "ACTUAL_RTW_DATE": "01-21-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Orozco",
		 "CLAIMANT_FIRST_NAME": "Amelia",
		 "CLAIM_NO": "2019-04-22-0546-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "04-22-2019 ",
		 "DATE_CLAIM_CLOSED": "05-16-2019 ",
		 "ACTUAL_RTW_DATE": "05-13-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Orozco",
		 "CLAIMANT_FIRST_NAME": "Yaneth",
		 "CLAIM_NO": "2013-03-19-0121-VP-01",
		 "DURATION": 121,
		 "DISABILITY_START_DATE": "03-19-2013 ",
		 "DATE_CLAIM_CLOSED": "08-23-2013 ",
		 "ACTUAL_RTW_DATE": "07-18-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Orozco",
		 "CLAIMANT_FIRST_NAME": "Yaneth",
		 "CLAIM_NO": "2022-01-20-0962-VP-01",
		 "DURATION": 97,
		 "DISABILITY_START_DATE": "01-21-2022 ",
		 "DATE_CLAIM_CLOSED": "05-03-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Orozco",
		 "CLAIMANT_FIRST_NAME": "Yaneth",
		 "CLAIM_NO": "2014-05-06-0380-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "05-02-2014 ",
		 "DATE_CLAIM_CLOSED": "12-12-2014 ",
		 "ACTUAL_RTW_DATE": "06-27-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Orozco",
		 "CLAIMANT_FIRST_NAME": "Yaneth",
		 "CLAIM_NO": "2021-02-23-0453-VP-01",
		 "DURATION": 87,
		 "DISABILITY_START_DATE": "02-22-2021 ",
		 "DATE_CLAIM_CLOSED": "05-24-2021 ",
		 "ACTUAL_RTW_DATE": "05-20-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Orta",
		 "CLAIMANT_FIRST_NAME": "Amarilis",
		 "CLAIM_NO": "2015-07-08-0094-VP-01",
		 "DURATION": 73,
		 "DISABILITY_START_DATE": "08-04-2015 ",
		 "DATE_CLAIM_CLOSED": "09-29-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Orta",
		 "CLAIMANT_FIRST_NAME": "Amarilis",
		 "CLAIM_NO": "2019-06-04-0707-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "06-11-2019 ",
		 "DATE_CLAIM_CLOSED": "06-06-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ortega",
		 "CLAIMANT_FIRST_NAME": "Yvette",
		 "CLAIM_NO": "2021-01-21-0293-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "02-05-2021 ",
		 "DATE_CLAIM_CLOSED": "02-26-2021 ",
		 "ACTUAL_RTW_DATE": "02-15-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ortega",
		 "CLAIMANT_FIRST_NAME": "Christopher",
		 "CLAIM_NO": "2021-09-01-0815-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "08-30-2021 ",
		 "DATE_CLAIM_CLOSED": "10-06-2021 ",
		 "ACTUAL_RTW_DATE": "09-13-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ortega",
		 "CLAIMANT_FIRST_NAME": "Christopher",
		 "CLAIM_NO": "2022-05-03-1061-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "05-04-2022 ",
		 "DATE_CLAIM_CLOSED": "06-21-2022 ",
		 "ACTUAL_RTW_DATE": "06-20-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ortega",
		 "CLAIMANT_FIRST_NAME": "Maribel",
		 "CLAIM_NO": "2014-11-24-0267-VP-01",
		 "DURATION": 170,
		 "DISABILITY_START_DATE": "12-05-2014 ",
		 "DATE_CLAIM_CLOSED": "04-16-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ortega",
		 "CLAIMANT_FIRST_NAME": "Leopoldo",
		 "CLAIM_NO": "2013-04-04-0310-VP-01",
		 "DURATION": 76,
		 "DISABILITY_START_DATE": "04-03-2013 ",
		 "DATE_CLAIM_CLOSED": "06-27-2013 ",
		 "ACTUAL_RTW_DATE": "06-18-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ortega Flores",
		 "CLAIMANT_FIRST_NAME": "Aida",
		 "CLAIM_NO": "2019-09-13-0605-VP-01",
		 "DURATION": 38,
		 "DISABILITY_START_DATE": "08-09-2019 ",
		 "DATE_CLAIM_CLOSED": "10-12-2019 ",
		 "ACTUAL_RTW_DATE": "09-16-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ortega Flores",
		 "CLAIMANT_FIRST_NAME": "Aida",
		 "CLAIM_NO": "2015-10-16-0456-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "10-30-2015 ",
		 "DATE_CLAIM_CLOSED": "12-04-2015 ",
		 "ACTUAL_RTW_DATE": "12-01-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ortega Flores",
		 "CLAIMANT_FIRST_NAME": "Aida",
		 "CLAIM_NO": "2022-04-07-0575-VP-01",
		 "DURATION": 182,
		 "DISABILITY_START_DATE": "04-05-2022 ",
		 "DATE_CLAIM_CLOSED": "10-25-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ortisi",
		 "CLAIMANT_FIRST_NAME": "Rosanna",
		 "CLAIM_NO": "2013-06-18-0020-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "06-10-2013 ",
		 "DATE_CLAIM_CLOSED": "08-15-2013 ",
		 "ACTUAL_RTW_DATE": "06-21-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Orts",
		 "CLAIMANT_FIRST_NAME": "Sondra",
		 "CLAIM_NO": "2019-08-23-0560-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "09-16-2019 ",
		 "DATE_CLAIM_CLOSED": "12-02-2019 ",
		 "ACTUAL_RTW_DATE": "11-17-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Osaki",
		 "CLAIMANT_FIRST_NAME": "Dale",
		 "CLAIM_NO": "2016-04-04-0552-VP-01",
		 "DURATION": 322,
		 "DISABILITY_START_DATE": "04-11-2016 ",
		 "DATE_CLAIM_CLOSED": "02-27-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Osayande",
		 "CLAIMANT_FIRST_NAME": "Theresa",
		 "CLAIM_NO": "2020-11-12-1026-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "12-03-2020 ",
		 "DATE_CLAIM_CLOSED": "01-21-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Oseguera",
		 "CLAIMANT_FIRST_NAME": "German",
		 "CLAIM_NO": "2022-10-28-0386-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "11-18-2022 ",
		 "DATE_CLAIM_CLOSED": "12-19-2022 ",
		 "ACTUAL_RTW_DATE": "12-12-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Oseguera",
		 "CLAIMANT_FIRST_NAME": "German",
		 "CLAIM_NO": "2021-08-18-0707-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "09-01-2021 ",
		 "DATE_CLAIM_CLOSED": "10-18-2021 ",
		 "ACTUAL_RTW_DATE": "10-13-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Oseguera",
		 "CLAIMANT_FIRST_NAME": "German",
		 "CLAIM_NO": "2021-05-21-0228-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "06-15-2021 ",
		 "DATE_CLAIM_CLOSED": "06-11-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Oseguera",
		 "CLAIMANT_FIRST_NAME": "German",
		 "CLAIM_NO": "2013-01-24-0166-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "01-15-2013 ",
		 "DATE_CLAIM_CLOSED": "08-24-2013 ",
		 "ACTUAL_RTW_DATE": "02-11-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Osnas",
		 "CLAIMANT_FIRST_NAME": "Svetlana",
		 "CLAIM_NO": "2018-05-04-0533-VP-01",
		 "DURATION": 175,
		 "DISABILITY_START_DATE": "04-30-2018 ",
		 "DATE_CLAIM_CLOSED": "11-28-2018 ",
		 "ACTUAL_RTW_DATE": "10-22-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Osoba",
		 "CLAIMANT_FIRST_NAME": "Adesimbo",
		 "CLAIM_NO": "2023-01-03-0700-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "02-09-2023 ",
		 "DATE_CLAIM_CLOSED": "03-28-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ott",
		 "CLAIMANT_FIRST_NAME": "Leo",
		 "CLAIM_NO": "2014-11-17-0614-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "12-16-2014 ",
		 "DATE_CLAIM_CLOSED": "01-29-2015 ",
		 "ACTUAL_RTW_DATE": "01-20-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Otton",
		 "CLAIMANT_FIRST_NAME": "Kimberley",
		 "CLAIM_NO": "2013-01-05-0523-VP-01",
		 "DURATION": 133,
		 "DISABILITY_START_DATE": "11-19-2012 ",
		 "DATE_CLAIM_CLOSED": "04-06-2013 ",
		 "ACTUAL_RTW_DATE": "04-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ou",
		 "CLAIMANT_FIRST_NAME": "Junli",
		 "CLAIM_NO": "2013-06-20-0340-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "07-24-2013 ",
		 "DATE_CLAIM_CLOSED": "09-09-2013 ",
		 "ACTUAL_RTW_DATE": "09-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Oun",
		 "CLAIMANT_FIRST_NAME": "An",
		 "CLAIM_NO": "2022-03-28-0615-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "01-24-2022 ",
		 "DATE_CLAIM_CLOSED": "05-03-2022 ",
		 "ACTUAL_RTW_DATE": "04-25-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ourk",
		 "CLAIMANT_FIRST_NAME": "Billie",
		 "CLAIM_NO": "2018-09-18-0316-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "09-06-2018 ",
		 "DATE_CLAIM_CLOSED": "10-08-2018 ",
		 "ACTUAL_RTW_DATE": "10-04-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ow",
		 "CLAIMANT_FIRST_NAME": "Rodney",
		 "CLAIM_NO": "2013-07-31-0257-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "07-26-2013 ",
		 "DATE_CLAIM_CLOSED": "09-17-2013 ",
		 "ACTUAL_RTW_DATE": "08-05-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Owens",
		 "CLAIMANT_FIRST_NAME": "Rayne",
		 "CLAIM_NO": "2016-10-03-0191-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "11-04-2016 ",
		 "DATE_CLAIM_CLOSED": "11-21-2016 ",
		 "ACTUAL_RTW_DATE": "11-15-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Owens",
		 "CLAIMANT_FIRST_NAME": "Emily",
		 "CLAIM_NO": "2016-04-06-0477-VP-01",
		 "DURATION": 36,
		 "DISABILITY_START_DATE": "04-05-2016 ",
		 "DATE_CLAIM_CLOSED": "06-29-2016 ",
		 "ACTUAL_RTW_DATE": "05-11-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Owens",
		 "CLAIMANT_FIRST_NAME": "Emily",
		 "CLAIM_NO": "2013-05-24-0002-VP-01",
		 "DURATION": 235,
		 "DISABILITY_START_DATE": "05-24-2013 ",
		 "DATE_CLAIM_CLOSED": "01-22-2014 ",
		 "ACTUAL_RTW_DATE": "01-14-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Owens",
		 "CLAIMANT_FIRST_NAME": "Emily",
		 "CLAIM_NO": "2016-07-15-0192-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "07-11-2016 ",
		 "DATE_CLAIM_CLOSED": "10-11-2016 ",
		 "ACTUAL_RTW_DATE": "09-30-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ozbay",
		 "CLAIMANT_FIRST_NAME": "Gizem",
		 "CLAIM_NO": "2019-12-19-0586-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "01-27-2020 ",
		 "DATE_CLAIM_CLOSED": "03-30-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pace",
		 "CLAIMANT_FIRST_NAME": "Elizabeth",
		 "CLAIM_NO": "2013-12-13-0210-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "12-09-2013 ",
		 "DATE_CLAIM_CLOSED": "12-19-2013 ",
		 "ACTUAL_RTW_DATE": "12-16-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pace",
		 "CLAIMANT_FIRST_NAME": "Teonta",
		 "CLAIM_NO": "2021-04-14-0198-VP-01",
		 "DURATION": 73,
		 "DISABILITY_START_DATE": "04-19-2021 ",
		 "DATE_CLAIM_CLOSED": "07-01-2021 ",
		 "ACTUAL_RTW_DATE": "07-01-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pacetti",
		 "CLAIMANT_FIRST_NAME": "Stephen",
		 "CLAIM_NO": "2021-04-09-0956-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "04-12-2021 ",
		 "DATE_CLAIM_CLOSED": "09-07-2021 ",
		 "ACTUAL_RTW_DATE": "06-14-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pacheco",
		 "CLAIMANT_FIRST_NAME": "Veronica",
		 "CLAIM_NO": "2021-09-22-1073-VP-01",
		 "DURATION": 37,
		 "DISABILITY_START_DATE": "09-20-2021 ",
		 "DATE_CLAIM_CLOSED": "10-06-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pacoma",
		 "CLAIMANT_FIRST_NAME": "E. Avenex",
		 "CLAIM_NO": "2015-02-19-0274-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "03-09-2015 ",
		 "DATE_CLAIM_CLOSED": "06-11-2015 ",
		 "ACTUAL_RTW_DATE": "06-08-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Padekar",
		 "CLAIMANT_FIRST_NAME": "Adita",
		 "CLAIM_NO": "2018-04-20-0428-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "04-18-2018 ",
		 "DATE_CLAIM_CLOSED": "05-14-2018 ",
		 "ACTUAL_RTW_DATE": "05-07-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Padilla",
		 "CLAIMANT_FIRST_NAME": "Eloy",
		 "CLAIM_NO": "2021-01-21-1138-VP-01",
		 "DURATION": 75,
		 "DISABILITY_START_DATE": "01-04-2021 ",
		 "DATE_CLAIM_CLOSED": "04-05-2021 ",
		 "ACTUAL_RTW_DATE": "03-20-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Padilla",
		 "CLAIMANT_FIRST_NAME": "Janelle",
		 "CLAIM_NO": "2021-02-26-0640-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "02-15-2021 ",
		 "DATE_CLAIM_CLOSED": "03-12-2021 ",
		 "ACTUAL_RTW_DATE": "03-08-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Padilla",
		 "CLAIMANT_FIRST_NAME": "Philda",
		 "CLAIM_NO": "2022-08-25-0689-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "08-25-2022 ",
		 "DATE_CLAIM_CLOSED": "08-30-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Padron Perez",
		 "CLAIMANT_FIRST_NAME": "Nora",
		 "CLAIM_NO": "2019-04-26-0554-VP-01",
		 "DURATION": 82,
		 "DISABILITY_START_DATE": "05-01-2019 ",
		 "DATE_CLAIM_CLOSED": "07-25-2019 ",
		 "ACTUAL_RTW_DATE": "07-22-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Padron Perez",
		 "CLAIMANT_FIRST_NAME": "Nora",
		 "CLAIM_NO": "2020-11-12-0863-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "11-09-2020 ",
		 "DATE_CLAIM_CLOSED": "01-05-2021 ",
		 "ACTUAL_RTW_DATE": "12-21-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Padron Perez",
		 "CLAIMANT_FIRST_NAME": "Nora",
		 "CLAIM_NO": "2022-08-16-0630-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "08-26-2022 ",
		 "DATE_CLAIM_CLOSED": "10-19-2022 ",
		 "ACTUAL_RTW_DATE": "10-10-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pagan",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2014-10-14-0405-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "11-20-2014 ",
		 "DATE_CLAIM_CLOSED": "01-21-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Page",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2015-04-20-0130-VP-01",
		 "DURATION": 179,
		 "DISABILITY_START_DATE": "04-20-2015 ",
		 "DATE_CLAIM_CLOSED": "10-27-2015 ",
		 "ACTUAL_RTW_DATE": "10-16-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Page",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2016-02-18-0127-VP-01",
		 "DURATION": 386,
		 "DISABILITY_START_DATE": "02-18-2016 ",
		 "DATE_CLAIM_CLOSED": "02-21-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Page",
		 "CLAIMANT_FIRST_NAME": "Pilar",
		 "CLAIM_NO": "2017-06-01-0447-VP-01",
		 "DURATION": 80,
		 "DISABILITY_START_DATE": "06-06-2017 ",
		 "DATE_CLAIM_CLOSED": "08-29-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pak",
		 "CLAIMANT_FIRST_NAME": "Suet",
		 "CLAIM_NO": "2022-04-08-0782-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "04-11-2022 ",
		 "DATE_CLAIM_CLOSED": "04-25-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pal",
		 "CLAIMANT_FIRST_NAME": "Savita",
		 "CLAIM_NO": "2013-04-18-0285-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "04-11-2013 ",
		 "DATE_CLAIM_CLOSED": "08-03-2013 ",
		 "ACTUAL_RTW_DATE": "05-13-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Palacios",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2013-01-05-0773-VP-01",
		 "DURATION": 96,
		 "DISABILITY_START_DATE": "10-01-2012 ",
		 "DATE_CLAIM_CLOSED": "02-09-2013 ",
		 "ACTUAL_RTW_DATE": "01-05-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Palacios",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2015-03-12-0215-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "03-16-2015 ",
		 "DATE_CLAIM_CLOSED": "06-09-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Palacios",
		 "CLAIMANT_FIRST_NAME": "Teodoro",
		 "CLAIM_NO": "2016-08-30-0367-VP-01",
		 "DURATION": 66,
		 "DISABILITY_START_DATE": "08-29-2016 ",
		 "DATE_CLAIM_CLOSED": "01-19-2017 ",
		 "ACTUAL_RTW_DATE": "11-03-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Palad",
		 "CLAIMANT_FIRST_NAME": "Armando",
		 "CLAIM_NO": "2021-07-30-0372-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "09-08-2021 ",
		 "DATE_CLAIM_CLOSED": "11-19-2021 ",
		 "ACTUAL_RTW_DATE": "11-18-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Palad",
		 "CLAIMANT_FIRST_NAME": "Armando",
		 "CLAIM_NO": "2021-02-10-0890-VP-01",
		 "DURATION": 93,
		 "DISABILITY_START_DATE": "03-09-2021 ",
		 "DATE_CLAIM_CLOSED": "06-14-2021 ",
		 "ACTUAL_RTW_DATE": "06-10-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Palafox",
		 "CLAIMANT_FIRST_NAME": "Ernestina",
		 "CLAIM_NO": "2013-01-05-1597-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "07-16-2012 ",
		 "DATE_CLAIM_CLOSED": "01-15-2014 ",
		 "ACTUAL_RTW_DATE": "07-31-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Paliwal",
		 "CLAIMANT_FIRST_NAME": "Shraddha",
		 "CLAIM_NO": "2020-06-05-0475-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "06-19-2020 ",
		 "DATE_CLAIM_CLOSED": "08-24-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Palmas",
		 "CLAIMANT_FIRST_NAME": "Catalina",
		 "CLAIM_NO": "2018-01-19-0551-VP-01",
		 "DURATION": 4,
		 "DISABILITY_START_DATE": "01-18-2018 ",
		 "DATE_CLAIM_CLOSED": "01-25-2018 ",
		 "ACTUAL_RTW_DATE": "01-22-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Palmas",
		 "CLAIMANT_FIRST_NAME": "Catalina",
		 "CLAIM_NO": "2018-03-14-0547-VP-01",
		 "DURATION": 317,
		 "DISABILITY_START_DATE": "03-13-2018 ",
		 "DATE_CLAIM_CLOSED": "02-13-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Paltayan",
		 "CLAIMANT_FIRST_NAME": "Michael",
		 "CLAIM_NO": "2021-01-05-1102-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "12-28-2020 ",
		 "DATE_CLAIM_CLOSED": "01-21-2021 ",
		 "ACTUAL_RTW_DATE": "01-19-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Palumbo",
		 "CLAIMANT_FIRST_NAME": "Antonia",
		 "CLAIM_NO": "2015-03-24-0089-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "03-30-2015 ",
		 "DATE_CLAIM_CLOSED": "05-05-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pamaran",
		 "CLAIMANT_FIRST_NAME": "Lafonda",
		 "CLAIM_NO": "2021-04-29-0013-VP-01",
		 "DURATION": 72,
		 "DISABILITY_START_DATE": "05-25-2021 ",
		 "DATE_CLAIM_CLOSED": "08-05-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pamatian",
		 "CLAIMANT_FIRST_NAME": "Anna",
		 "CLAIM_NO": "2013-08-27-0215-VP-01",
		 "DURATION": 80,
		 "DISABILITY_START_DATE": "09-23-2013 ",
		 "DATE_CLAIM_CLOSED": "12-16-2013 ",
		 "ACTUAL_RTW_DATE": "12-12-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Panek",
		 "CLAIMANT_FIRST_NAME": "Evie",
		 "CLAIM_NO": "2015-03-10-0232-VP-01",
		 "DURATION": 126,
		 "DISABILITY_START_DATE": "03-09-2015 ",
		 "DATE_CLAIM_CLOSED": "07-16-2015 ",
		 "ACTUAL_RTW_DATE": "07-13-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Paner",
		 "CLAIMANT_FIRST_NAME": "Wilma",
		 "CLAIM_NO": "2015-07-06-0438-VP-01",
		 "DURATION": 122,
		 "DISABILITY_START_DATE": "07-06-2015 ",
		 "DATE_CLAIM_CLOSED": "01-07-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Paner",
		 "CLAIMANT_FIRST_NAME": "Wilma",
		 "CLAIM_NO": "2020-08-21-0742-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "09-04-2020 ",
		 "DATE_CLAIM_CLOSED": "10-05-2020 ",
		 "ACTUAL_RTW_DATE": "09-28-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Paredes",
		 "CLAIMANT_FIRST_NAME": "Lorenia",
		 "CLAIM_NO": "2021-06-08-0275-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "06-07-2021 ",
		 "DATE_CLAIM_CLOSED": "06-22-2021 ",
		 "ACTUAL_RTW_DATE": "06-21-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Paredes",
		 "CLAIMANT_FIRST_NAME": "Paula",
		 "CLAIM_NO": "2020-09-15-0808-VP-01",
		 "DURATION": 67,
		 "DISABILITY_START_DATE": "10-01-2020 ",
		 "DATE_CLAIM_CLOSED": "12-07-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Parham",
		 "CLAIMANT_FIRST_NAME": "Carlos",
		 "CLAIM_NO": "2015-12-07-0600-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "12-07-2015 ",
		 "DATE_CLAIM_CLOSED": "12-14-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Parikh",
		 "CLAIMANT_FIRST_NAME": "Nayan",
		 "CLAIM_NO": "2013-09-11-0036-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "09-12-2013 ",
		 "DATE_CLAIM_CLOSED": "10-10-2013 ",
		 "ACTUAL_RTW_DATE": "09-23-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Park",
		 "CLAIMANT_FIRST_NAME": "Adriana",
		 "CLAIM_NO": "2015-09-02-0062-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "10-01-2015 ",
		 "DATE_CLAIM_CLOSED": "11-18-2015 ",
		 "ACTUAL_RTW_DATE": "10-22-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Park",
		 "CLAIMANT_FIRST_NAME": "Adriana",
		 "CLAIM_NO": "2014-11-03-0518-VP-01",
		 "DURATION": 141,
		 "DISABILITY_START_DATE": "11-11-2014 ",
		 "DATE_CLAIM_CLOSED": "08-04-2015 ",
		 "ACTUAL_RTW_DATE": "04-01-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Park",
		 "CLAIMANT_FIRST_NAME": "Adriana",
		 "CLAIM_NO": "2016-05-17-0470-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "06-16-2016 ",
		 "DATE_CLAIM_CLOSED": "07-08-2016 ",
		 "ACTUAL_RTW_DATE": "07-07-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Park",
		 "CLAIMANT_FIRST_NAME": "Jeongha",
		 "CLAIM_NO": "2019-06-10-0459-VP-01",
		 "DURATION": 75,
		 "DISABILITY_START_DATE": "07-01-2019 ",
		 "DATE_CLAIM_CLOSED": "09-17-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Parker",
		 "CLAIMANT_FIRST_NAME": "Carol",
		 "CLAIM_NO": "2013-01-05-0683-VP-01",
		 "DURATION": 108,
		 "DISABILITY_START_DATE": "09-24-2012 ",
		 "DATE_CLAIM_CLOSED": "04-03-2013 ",
		 "ACTUAL_RTW_DATE": "01-10-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pasion",
		 "CLAIMANT_FIRST_NAME": "Trina",
		 "CLAIM_NO": "2013-07-08-0400-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "07-01-2013 ",
		 "DATE_CLAIM_CLOSED": "07-29-2013 ",
		 "ACTUAL_RTW_DATE": "07-27-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pasion",
		 "CLAIMANT_FIRST_NAME": "Trina",
		 "CLAIM_NO": "2015-09-17-0484-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "09-16-2015 ",
		 "DATE_CLAIM_CLOSED": "10-27-2015 ",
		 "ACTUAL_RTW_DATE": "10-15-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pastor",
		 "CLAIMANT_FIRST_NAME": "Jan Pauline",
		 "CLAIM_NO": "2020-09-11-0616-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "09-08-2020 ",
		 "DATE_CLAIM_CLOSED": "09-22-2020 ",
		 "ACTUAL_RTW_DATE": "09-17-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Patel",
		 "CLAIMANT_FIRST_NAME": "Mona",
		 "CLAIM_NO": "2021-02-22-0387-VP-01",
		 "DURATION": 83,
		 "DISABILITY_START_DATE": "02-23-2021 ",
		 "DATE_CLAIM_CLOSED": "05-17-2021 ",
		 "ACTUAL_RTW_DATE": "05-17-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Patel",
		 "CLAIMANT_FIRST_NAME": "Karishma",
		 "CLAIM_NO": "2022-12-08-0963-VP-01",
		 "DURATION": 120,
		 "DISABILITY_START_DATE": "01-03-2023 ",
		 "DATE_CLAIM_CLOSED": "03-01-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Patel",
		 "CLAIMANT_FIRST_NAME": "Parul",
		 "CLAIM_NO": "2013-01-05-0120-VP-01",
		 "DURATION": 94,
		 "DISABILITY_START_DATE": "12-17-2012 ",
		 "DATE_CLAIM_CLOSED": "07-10-2013 ",
		 "ACTUAL_RTW_DATE": "03-21-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Patel",
		 "CLAIMANT_FIRST_NAME": "Hiralben",
		 "CLAIM_NO": "2015-02-18-0193-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "03-02-2015 ",
		 "DATE_CLAIM_CLOSED": "08-04-2015 ",
		 "ACTUAL_RTW_DATE": "03-16-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Patel",
		 "CLAIMANT_FIRST_NAME": "Narendra",
		 "CLAIM_NO": "2018-12-10-0622-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "12-04-2018 ",
		 "DATE_CLAIM_CLOSED": "12-11-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Patel",
		 "CLAIMANT_FIRST_NAME": "Shilpa",
		 "CLAIM_NO": "2013-04-30-0267-VP-01",
		 "DURATION": 120,
		 "DISABILITY_START_DATE": "04-29-2013 ",
		 "DATE_CLAIM_CLOSED": "09-27-2013 ",
		 "ACTUAL_RTW_DATE": "08-27-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Patel",
		 "CLAIMANT_FIRST_NAME": "Narendra",
		 "CLAIM_NO": "2018-09-20-0167-VP-01",
		 "DURATION": 321,
		 "DISABILITY_START_DATE": "09-10-2018 ",
		 "DATE_CLAIM_CLOSED": "10-17-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Patino",
		 "CLAIMANT_FIRST_NAME": "Norma",
		 "CLAIM_NO": "2020-08-06-0570-VP-01",
		 "DURATION": 112,
		 "DISABILITY_START_DATE": "07-27-2020 ",
		 "DATE_CLAIM_CLOSED": "11-17-2020 ",
		 "ACTUAL_RTW_DATE": "11-16-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Patino",
		 "CLAIMANT_FIRST_NAME": "Norma",
		 "CLAIM_NO": "2015-03-12-0284-VP-01",
		 "DURATION": 144,
		 "DISABILITY_START_DATE": "03-12-2015 ",
		 "DATE_CLAIM_CLOSED": "09-03-2015 ",
		 "ACTUAL_RTW_DATE": "08-03-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Patino",
		 "CLAIMANT_FIRST_NAME": "Norma",
		 "CLAIM_NO": "2021-09-15-0754-VP-01",
		 "DURATION": 30,
		 "DISABILITY_START_DATE": "09-15-2021 ",
		 "DATE_CLAIM_CLOSED": "09-23-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Paul",
		 "CLAIMANT_FIRST_NAME": "Rachna",
		 "CLAIM_NO": "2013-04-02-0317-VP-01",
		 "DURATION": 85,
		 "DISABILITY_START_DATE": "04-15-2013 ",
		 "DATE_CLAIM_CLOSED": "07-22-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Paulsen",
		 "CLAIMANT_FIRST_NAME": "Erika",
		 "CLAIM_NO": "2014-08-13-0550-VP-01",
		 "DURATION": 75,
		 "DISABILITY_START_DATE": "08-25-2014 ",
		 "DATE_CLAIM_CLOSED": "01-08-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Paulsen",
		 "CLAIMANT_FIRST_NAME": "Erika",
		 "CLAIM_NO": "2013-09-05-0119-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "09-11-2013 ",
		 "DATE_CLAIM_CLOSED": "12-09-2013 ",
		 "ACTUAL_RTW_DATE": "09-23-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Peacock",
		 "CLAIMANT_FIRST_NAME": "Glen",
		 "CLAIM_NO": "2020-02-25-0924-VP-01",
		 "DURATION": 154,
		 "DISABILITY_START_DATE": "03-09-2020 ",
		 "DATE_CLAIM_CLOSED": "08-10-2020 ",
		 "ACTUAL_RTW_DATE": "08-10-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pearce",
		 "CLAIMANT_FIRST_NAME": "Susan",
		 "CLAIM_NO": "2019-05-23-0580-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "05-28-2019 ",
		 "DATE_CLAIM_CLOSED": "06-24-2019 ",
		 "ACTUAL_RTW_DATE": "06-17-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pearce",
		 "CLAIMANT_FIRST_NAME": "Susan",
		 "CLAIM_NO": "2021-06-21-0142-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "06-23-2021 ",
		 "DATE_CLAIM_CLOSED": "07-14-2021 ",
		 "ACTUAL_RTW_DATE": "07-05-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pedersen",
		 "CLAIMANT_FIRST_NAME": "Kristin",
		 "CLAIM_NO": "2019-06-26-0484-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "07-11-2019 ",
		 "DATE_CLAIM_CLOSED": "09-30-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pederson",
		 "CLAIMANT_FIRST_NAME": "Corissa",
		 "CLAIM_NO": "2014-01-29-0095-VP-01",
		 "DURATION": 132,
		 "DISABILITY_START_DATE": "01-02-2014 ",
		 "DATE_CLAIM_CLOSED": "05-31-2014 ",
		 "ACTUAL_RTW_DATE": "05-14-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pederson",
		 "CLAIMANT_FIRST_NAME": "Corissa",
		 "CLAIM_NO": "2014-01-03-0333-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "12-23-2013 ",
		 "DATE_CLAIM_CLOSED": "02-22-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pedroza",
		 "CLAIMANT_FIRST_NAME": "Yolanda",
		 "CLAIM_NO": "2014-07-22-0225-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "07-21-2014 ",
		 "DATE_CLAIM_CLOSED": "01-21-2015 ",
		 "ACTUAL_RTW_DATE": "09-22-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Peek",
		 "CLAIMANT_FIRST_NAME": "Jason",
		 "CLAIM_NO": "2016-02-10-0535-VP-01",
		 "DURATION": 149,
		 "DISABILITY_START_DATE": "03-01-2016 ",
		 "DATE_CLAIM_CLOSED": "08-01-2016 ",
		 "ACTUAL_RTW_DATE": "07-28-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Peeters",
		 "CLAIMANT_FIRST_NAME": "Licet",
		 "CLAIM_NO": "2019-07-08-0742-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "07-10-2019 ",
		 "DATE_CLAIM_CLOSED": "10-28-2019 ",
		 "ACTUAL_RTW_DATE": "10-09-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pelster",
		 "CLAIMANT_FIRST_NAME": "Lindsey",
		 "CLAIM_NO": "2022-03-15-0898-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "03-16-2022 ",
		 "DATE_CLAIM_CLOSED": "05-04-2022 ",
		 "ACTUAL_RTW_DATE": "05-02-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pelster",
		 "CLAIMANT_FIRST_NAME": "Lindsey",
		 "CLAIM_NO": "2019-10-30-0743-VP-01",
		 "DURATION": 23,
		 "DISABILITY_START_DATE": "11-12-2019 ",
		 "DATE_CLAIM_CLOSED": "12-09-2019 ",
		 "ACTUAL_RTW_DATE": "12-05-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pena",
		 "CLAIMANT_FIRST_NAME": "Norma",
		 "CLAIM_NO": "2013-01-05-1905-VP-01",
		 "DURATION": 302,
		 "DISABILITY_START_DATE": "05-23-2012 ",
		 "DATE_CLAIM_CLOSED": "05-02-2014 ",
		 "ACTUAL_RTW_DATE": "03-21-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pena",
		 "CLAIMANT_FIRST_NAME": "Norma",
		 "CLAIM_NO": "2016-12-06-0073-VP-01",
		 "DURATION": 182,
		 "DISABILITY_START_DATE": "12-07-2016 ",
		 "DATE_CLAIM_CLOSED": "06-12-2017 ",
		 "ACTUAL_RTW_DATE": "06-07-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Peng",
		 "CLAIMANT_FIRST_NAME": "Jie",
		 "CLAIM_NO": "2018-01-23-0386-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "01-18-2018 ",
		 "DATE_CLAIM_CLOSED": "04-16-2018 ",
		 "ACTUAL_RTW_DATE": "04-09-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Penry",
		 "CLAIMANT_FIRST_NAME": "Patricia",
		 "CLAIM_NO": "2014-04-29-0483-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "04-29-2014 ",
		 "DATE_CLAIM_CLOSED": "01-20-2015 ",
		 "ACTUAL_RTW_DATE": "05-26-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Penry",
		 "CLAIMANT_FIRST_NAME": "Patricia",
		 "CLAIM_NO": "2015-07-29-0453-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "07-22-2015 ",
		 "DATE_CLAIM_CLOSED": "09-22-2015 ",
		 "ACTUAL_RTW_DATE": "08-10-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perdue",
		 "CLAIMANT_FIRST_NAME": "Jon",
		 "CLAIM_NO": "2019-04-17-0010-VP-01",
		 "DURATION": 365,
		 "DISABILITY_START_DATE": "03-15-2019 ",
		 "DATE_CLAIM_CLOSED": "03-13-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perdue",
		 "CLAIMANT_FIRST_NAME": "Jon",
		 "CLAIM_NO": "2018-06-12-0371-VP-01",
		 "DURATION": 155,
		 "DISABILITY_START_DATE": "05-30-2018 ",
		 "DATE_CLAIM_CLOSED": "11-06-2018 ",
		 "ACTUAL_RTW_DATE": "11-01-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perea",
		 "CLAIMANT_FIRST_NAME": "Dennis Lawrence",
		 "CLAIM_NO": "2019-01-10-0529-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "01-18-2019 ",
		 "DATE_CLAIM_CLOSED": "02-06-2019 ",
		 "ACTUAL_RTW_DATE": "02-04-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perea",
		 "CLAIMANT_FIRST_NAME": "Wyleen",
		 "CLAIM_NO": "2022-02-12-0008-VP-01",
		 "DURATION": 82,
		 "DISABILITY_START_DATE": "03-11-2022 ",
		 "DATE_CLAIM_CLOSED": "05-13-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pereida",
		 "CLAIMANT_FIRST_NAME": "Clemente",
		 "CLAIM_NO": "2022-11-02-0493-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "10-27-2022 ",
		 "DATE_CLAIM_CLOSED": "01-13-2023 ",
		 "ACTUAL_RTW_DATE": "01-03-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Peren",
		 "CLAIMANT_FIRST_NAME": "Christa",
		 "CLAIM_NO": "2014-03-19-0152-VP-01",
		 "DURATION": 98,
		 "DISABILITY_START_DATE": "03-31-2014 ",
		 "DATE_CLAIM_CLOSED": "01-07-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Peresutti",
		 "CLAIMANT_FIRST_NAME": "Lauren",
		 "CLAIM_NO": "2021-08-10-1101-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "10-06-2021 ",
		 "DATE_CLAIM_CLOSED": "11-09-2021 ",
		 "ACTUAL_RTW_DATE": "11-04-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perez",
		 "CLAIMANT_FIRST_NAME": "Martha",
		 "CLAIM_NO": "2017-03-10-0286-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "03-27-2017 ",
		 "DATE_CLAIM_CLOSED": "04-24-2017 ",
		 "ACTUAL_RTW_DATE": "04-18-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perez",
		 "CLAIMANT_FIRST_NAME": "Juan",
		 "CLAIM_NO": "2018-02-19-0072-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "02-09-2018 ",
		 "DATE_CLAIM_CLOSED": "07-03-2018 ",
		 "ACTUAL_RTW_DATE": "02-19-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perez",
		 "CLAIMANT_FIRST_NAME": "Gilberto",
		 "CLAIM_NO": "2022-05-18-0911-VP-01",
		 "DURATION": 85,
		 "DISABILITY_START_DATE": "06-27-2022 ",
		 "DATE_CLAIM_CLOSED": "09-30-2022 ",
		 "ACTUAL_RTW_DATE": "09-20-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perez",
		 "CLAIMANT_FIRST_NAME": "Juan",
		 "CLAIM_NO": "2021-02-02-0255-VP-01",
		 "DURATION": 52,
		 "DISABILITY_START_DATE": "01-22-2021 ",
		 "DATE_CLAIM_CLOSED": "04-12-2021 ",
		 "ACTUAL_RTW_DATE": "03-15-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perez",
		 "CLAIMANT_FIRST_NAME": "Gilberto",
		 "CLAIM_NO": "2021-07-12-0752-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "07-19-2021 ",
		 "DATE_CLAIM_CLOSED": "10-15-2021 ",
		 "ACTUAL_RTW_DATE": "10-11-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perez",
		 "CLAIMANT_FIRST_NAME": "Ester",
		 "CLAIM_NO": "2018-02-21-0455-VP-01",
		 "DURATION": 493,
		 "DISABILITY_START_DATE": "02-16-2018 ",
		 "DATE_CLAIM_CLOSED": "03-04-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perez",
		 "CLAIMANT_FIRST_NAME": "Roxanna",
		 "CLAIM_NO": "2021-07-26-0760-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "07-30-2021 ",
		 "DATE_CLAIM_CLOSED": "08-29-2021 ",
		 "ACTUAL_RTW_DATE": "08-17-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perez",
		 "CLAIMANT_FIRST_NAME": "Marcia",
		 "CLAIM_NO": "2022-10-24-0755-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "11-17-2022 ",
		 "DATE_CLAIM_CLOSED": "12-30-2022 ",
		 "ACTUAL_RTW_DATE": "12-19-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perez",
		 "CLAIMANT_FIRST_NAME": "Roxanna",
		 "CLAIM_NO": "2020-05-18-0749-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "05-29-2020 ",
		 "DATE_CLAIM_CLOSED": "05-29-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perez",
		 "CLAIMANT_FIRST_NAME": "Martha",
		 "CLAIM_NO": "2013-01-05-0564-VP-01",
		 "DURATION": 263,
		 "DISABILITY_START_DATE": "10-18-2012 ",
		 "DATE_CLAIM_CLOSED": "07-10-2013 ",
		 "ACTUAL_RTW_DATE": "07-08-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perez",
		 "CLAIMANT_FIRST_NAME": "Jose",
		 "CLAIM_NO": "2014-02-20-0440-VP-01",
		 "DURATION": 584,
		 "DISABILITY_START_DATE": "11-08-2013 ",
		 "DATE_CLAIM_CLOSED": "06-16-2015 ",
		 "ACTUAL_RTW_DATE": "06-15-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perez",
		 "CLAIMANT_FIRST_NAME": "Gilberto",
		 "CLAIM_NO": "2021-07-13-0156-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "07-19-2021 ",
		 "DATE_CLAIM_CLOSED": "07-14-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perez",
		 "CLAIMANT_FIRST_NAME": "Graciela",
		 "CLAIM_NO": "2014-04-08-0172-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "04-04-2014 ",
		 "DATE_CLAIM_CLOSED": "01-20-2015 ",
		 "ACTUAL_RTW_DATE": "04-21-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perez",
		 "CLAIMANT_FIRST_NAME": "Martha",
		 "CLAIM_NO": "2017-08-21-0530-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "08-17-2017 ",
		 "DATE_CLAIM_CLOSED": "09-19-2017 ",
		 "ACTUAL_RTW_DATE": "09-05-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perez",
		 "CLAIMANT_FIRST_NAME": "Marybel",
		 "CLAIM_NO": "2018-07-31-0167-VP-01",
		 "DURATION": 97,
		 "DISABILITY_START_DATE": "07-23-2018 ",
		 "DATE_CLAIM_CLOSED": "10-30-2018 ",
		 "ACTUAL_RTW_DATE": "10-28-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perez",
		 "CLAIMANT_FIRST_NAME": "Martha",
		 "CLAIM_NO": "2018-10-01-0399-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "09-24-2018 ",
		 "DATE_CLAIM_CLOSED": "10-10-2018 ",
		 "ACTUAL_RTW_DATE": "10-08-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perez",
		 "CLAIMANT_FIRST_NAME": "Ester",
		 "CLAIM_NO": "2018-01-08-0670-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "01-18-2018 ",
		 "DATE_CLAIM_CLOSED": "01-18-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perez",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2021-03-22-2709-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "03-23-2021 ",
		 "DATE_CLAIM_CLOSED": "05-19-2021 ",
		 "ACTUAL_RTW_DATE": "05-17-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perez - Verdier",
		 "CLAIMANT_FIRST_NAME": "Frances",
		 "CLAIM_NO": "2020-09-21-0758-VP-01",
		 "DURATION": 88,
		 "DISABILITY_START_DATE": "10-05-2020 ",
		 "DATE_CLAIM_CLOSED": "10-02-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perkins",
		 "CLAIMANT_FIRST_NAME": "Gail",
		 "CLAIM_NO": "2018-07-16-0518-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "07-26-2018 ",
		 "DATE_CLAIM_CLOSED": "09-17-2018 ",
		 "ACTUAL_RTW_DATE": "09-10-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perkins",
		 "CLAIMANT_FIRST_NAME": "Gail",
		 "CLAIM_NO": "2017-04-24-0129-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "04-25-2017 ",
		 "DATE_CLAIM_CLOSED": "07-31-2017 ",
		 "ACTUAL_RTW_DATE": "05-30-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perkins",
		 "CLAIMANT_FIRST_NAME": "Gail",
		 "CLAIM_NO": "2017-09-25-0588-VP-01",
		 "DURATION": 89,
		 "DISABILITY_START_DATE": "09-20-2017 ",
		 "DATE_CLAIM_CLOSED": "12-19-2017 ",
		 "ACTUAL_RTW_DATE": "12-18-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perrone",
		 "CLAIMANT_FIRST_NAME": "Olga",
		 "CLAIM_NO": "2022-02-18-0548-VP-01",
		 "DURATION": 189,
		 "DISABILITY_START_DATE": "03-08-2022 ",
		 "DATE_CLAIM_CLOSED": "09-15-2022 ",
		 "ACTUAL_RTW_DATE": "09-13-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perrone",
		 "CLAIMANT_FIRST_NAME": "Olga",
		 "CLAIM_NO": "2016-02-02-0544-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "01-24-2016 ",
		 "DATE_CLAIM_CLOSED": "02-23-2016 ",
		 "ACTUAL_RTW_DATE": "02-22-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perryman",
		 "CLAIMANT_FIRST_NAME": "Jerome",
		 "CLAIM_NO": "2021-11-05-0781-VP-01",
		 "DURATION": 113,
		 "DISABILITY_START_DATE": "11-08-2021 ",
		 "DATE_CLAIM_CLOSED": "05-11-2022 ",
		 "ACTUAL_RTW_DATE": "03-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Perzov",
		 "CLAIMANT_FIRST_NAME": "Nicole",
		 "CLAIM_NO": "2015-04-15-0490-VP-01",
		 "DURATION": 119,
		 "DISABILITY_START_DATE": "05-18-2015 ",
		 "DATE_CLAIM_CLOSED": "09-14-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Peters",
		 "CLAIMANT_FIRST_NAME": "Marinca",
		 "CLAIM_NO": "2016-08-15-0245-VP-01",
		 "DURATION": 90,
		 "DISABILITY_START_DATE": "08-16-2016 ",
		 "DATE_CLAIM_CLOSED": "11-21-2016 ",
		 "ACTUAL_RTW_DATE": "11-14-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Peters",
		 "CLAIMANT_FIRST_NAME": "Marinca",
		 "CLAIM_NO": "2013-06-27-0248-VP-01",
		 "DURATION": 96,
		 "DISABILITY_START_DATE": "06-27-2013 ",
		 "DATE_CLAIM_CLOSED": "12-27-2013 ",
		 "ACTUAL_RTW_DATE": "10-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Peters",
		 "CLAIMANT_FIRST_NAME": "Ragini",
		 "CLAIM_NO": "2021-09-02-0903-VP-01",
		 "DURATION": 179,
		 "DISABILITY_START_DATE": "09-03-2021 ",
		 "DATE_CLAIM_CLOSED": "03-02-2022 ",
		 "ACTUAL_RTW_DATE": "03-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Petrich",
		 "CLAIMANT_FIRST_NAME": "Gina",
		 "CLAIM_NO": "2022-10-05-0011-VP-01",
		 "DURATION": 92,
		 "DISABILITY_START_DATE": "10-10-2022 ",
		 "DATE_CLAIM_CLOSED": "04-13-2023 ",
		 "ACTUAL_RTW_DATE": "01-10-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Petrossian",
		 "CLAIMANT_FIRST_NAME": "Suzanne",
		 "CLAIM_NO": "2020-06-22-0460-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "07-29-2020 ",
		 "DATE_CLAIM_CLOSED": "09-16-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Petry Bastedo",
		 "CLAIMANT_FIRST_NAME": "Donna",
		 "CLAIM_NO": "2017-02-22-0641-VP-01",
		 "DURATION": 30,
		 "DISABILITY_START_DATE": "02-22-2017 ",
		 "DATE_CLAIM_CLOSED": "02-27-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Petty",
		 "CLAIMANT_FIRST_NAME": "Liberty",
		 "CLAIM_NO": "2016-05-31-0460-VP-01",
		 "DURATION": 93,
		 "DISABILITY_START_DATE": "05-31-2016 ",
		 "DATE_CLAIM_CLOSED": "09-19-2016 ",
		 "ACTUAL_RTW_DATE": "09-01-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Petty",
		 "CLAIMANT_FIRST_NAME": "Liberty",
		 "CLAIM_NO": "2017-08-09-0570-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "08-07-2017 ",
		 "DATE_CLAIM_CLOSED": "08-23-2017 ",
		 "ACTUAL_RTW_DATE": "08-21-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Petty",
		 "CLAIMANT_FIRST_NAME": "Liberty",
		 "CLAIM_NO": "2020-02-26-0344-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "02-24-2020 ",
		 "DATE_CLAIM_CLOSED": "05-26-2020 ",
		 "ACTUAL_RTW_DATE": "03-09-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Petty",
		 "CLAIMANT_FIRST_NAME": "Liberty",
		 "CLAIM_NO": "2022-01-26-1446-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "02-02-2022 ",
		 "DATE_CLAIM_CLOSED": "03-08-2022 ",
		 "ACTUAL_RTW_DATE": "02-15-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Petty",
		 "CLAIMANT_FIRST_NAME": "Liberty",
		 "CLAIM_NO": "2017-03-29-0231-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "03-29-2017 ",
		 "DATE_CLAIM_CLOSED": "04-05-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Petty",
		 "CLAIMANT_FIRST_NAME": "Liberty",
		 "CLAIM_NO": "2018-09-17-0527-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "09-17-2018 ",
		 "DATE_CLAIM_CLOSED": "12-18-2018 ",
		 "ACTUAL_RTW_DATE": "12-17-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Peyton",
		 "CLAIMANT_FIRST_NAME": "Reven Grace",
		 "CLAIM_NO": "2021-09-13-0666-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "10-18-2021 ",
		 "DATE_CLAIM_CLOSED": "12-21-2021 ",
		 "ACTUAL_RTW_DATE": "11-22-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pham",
		 "CLAIMANT_FIRST_NAME": "Steven",
		 "CLAIM_NO": "2020-09-11-0847-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "09-10-2020 ",
		 "DATE_CLAIM_CLOSED": "10-19-2020 ",
		 "ACTUAL_RTW_DATE": "10-13-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pham",
		 "CLAIMANT_FIRST_NAME": "Anh-Thu",
		 "CLAIM_NO": "2020-07-09-0508-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "07-07-2020 ",
		 "DATE_CLAIM_CLOSED": "07-23-2020 ",
		 "ACTUAL_RTW_DATE": "07-20-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pham",
		 "CLAIMANT_FIRST_NAME": "Tramanh",
		 "CLAIM_NO": "2016-04-08-0467-VP-01",
		 "DURATION": 51,
		 "DISABILITY_START_DATE": "04-18-2016 ",
		 "DATE_CLAIM_CLOSED": "06-14-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pham",
		 "CLAIMANT_FIRST_NAME": "Katherine",
		 "CLAIM_NO": "2018-07-20-0334-VP-01",
		 "DURATION": 51,
		 "DISABILITY_START_DATE": "06-04-2018 ",
		 "DATE_CLAIM_CLOSED": "09-06-2018 ",
		 "ACTUAL_RTW_DATE": "07-25-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pham",
		 "CLAIMANT_FIRST_NAME": "Thanh",
		 "CLAIM_NO": "2016-04-13-0462-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "04-13-2016 ",
		 "DATE_CLAIM_CLOSED": "06-01-2016 ",
		 "ACTUAL_RTW_DATE": "05-23-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pham",
		 "CLAIMANT_FIRST_NAME": "Tramanh",
		 "CLAIM_NO": "2021-02-10-0922-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "02-11-2021 ",
		 "DATE_CLAIM_CLOSED": "03-01-2021 ",
		 "ACTUAL_RTW_DATE": "02-22-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pham",
		 "CLAIMANT_FIRST_NAME": "Quang",
		 "CLAIM_NO": "2022-04-26-0918-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "05-20-2022 ",
		 "DATE_CLAIM_CLOSED": "07-07-2022 ",
		 "ACTUAL_RTW_DATE": "07-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pham",
		 "CLAIMANT_FIRST_NAME": "Le",
		 "CLAIM_NO": "2020-01-27-0829-VP-01",
		 "DURATION": 85,
		 "DISABILITY_START_DATE": "02-20-2020 ",
		 "DATE_CLAIM_CLOSED": "05-18-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pham",
		 "CLAIMANT_FIRST_NAME": "Katherine",
		 "CLAIM_NO": "2020-04-02-0710-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "03-19-2020 ",
		 "DATE_CLAIM_CLOSED": "04-03-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pham",
		 "CLAIMANT_FIRST_NAME": "Le",
		 "CLAIM_NO": "2018-07-24-0346-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "07-23-2018 ",
		 "DATE_CLAIM_CLOSED": "08-07-2018 ",
		 "ACTUAL_RTW_DATE": "08-06-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pham",
		 "CLAIMANT_FIRST_NAME": "Hong Yen",
		 "CLAIM_NO": "2018-01-22-0782-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "01-15-2018 ",
		 "DATE_CLAIM_CLOSED": "03-21-2018 ",
		 "ACTUAL_RTW_DATE": "02-19-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phan",
		 "CLAIMANT_FIRST_NAME": "Huyen",
		 "CLAIM_NO": "2014-07-18-0087-VP-01",
		 "DURATION": 0,
		 "DISABILITY_START_DATE": "04-18-2014 ",
		 "DATE_CLAIM_CLOSED": "10-06-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phan",
		 "CLAIMANT_FIRST_NAME": "Binh Tan",
		 "CLAIM_NO": "2020-10-08-0485-VP-01",
		 "DURATION": 363,
		 "DISABILITY_START_DATE": "09-18-2020 ",
		 "DATE_CLAIM_CLOSED": "01-13-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phan",
		 "CLAIMANT_FIRST_NAME": "Huyen",
		 "CLAIM_NO": "2022-01-19-0989-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "01-31-2022 ",
		 "DATE_CLAIM_CLOSED": "04-11-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phan",
		 "CLAIMANT_FIRST_NAME": "Helene",
		 "CLAIM_NO": "2022-01-07-0904-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "01-06-2022 ",
		 "DATE_CLAIM_CLOSED": "02-11-2022 ",
		 "ACTUAL_RTW_DATE": "02-03-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phan",
		 "CLAIMANT_FIRST_NAME": "Tam",
		 "CLAIM_NO": "2022-08-19-0323-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "08-15-2022 ",
		 "DATE_CLAIM_CLOSED": "09-07-2022 ",
		 "ACTUAL_RTW_DATE": "09-05-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phelps",
		 "CLAIMANT_FIRST_NAME": "Molly",
		 "CLAIM_NO": "2021-02-10-0293-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "02-03-2021 ",
		 "DATE_CLAIM_CLOSED": "02-23-2021 ",
		 "ACTUAL_RTW_DATE": "02-15-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phelps",
		 "CLAIMANT_FIRST_NAME": "Molly",
		 "CLAIM_NO": "2021-08-05-0414-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "08-03-2021 ",
		 "DATE_CLAIM_CLOSED": "08-27-2021 ",
		 "ACTUAL_RTW_DATE": "08-11-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phetsany",
		 "CLAIMANT_FIRST_NAME": "Lynda",
		 "CLAIM_NO": "2019-02-11-0732-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "03-05-2019 ",
		 "DATE_CLAIM_CLOSED": "04-23-2019 ",
		 "ACTUAL_RTW_DATE": "04-19-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phillips",
		 "CLAIMANT_FIRST_NAME": "Tierra",
		 "CLAIM_NO": "2022-01-03-0485-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "12-27-2021 ",
		 "DATE_CLAIM_CLOSED": "01-26-2022 ",
		 "ACTUAL_RTW_DATE": "01-24-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phillips",
		 "CLAIMANT_FIRST_NAME": "Jason",
		 "CLAIM_NO": "2013-01-05-0669-VP-01",
		 "DURATION": 73,
		 "DISABILITY_START_DATE": "10-05-2012 ",
		 "DATE_CLAIM_CLOSED": "04-07-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phipps",
		 "CLAIMANT_FIRST_NAME": "John",
		 "CLAIM_NO": "2016-09-22-0445-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "10-05-2016 ",
		 "DATE_CLAIM_CLOSED": "11-18-2016 ",
		 "ACTUAL_RTW_DATE": "11-17-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phoprom",
		 "CLAIMANT_FIRST_NAME": "Hilda",
		 "CLAIM_NO": "2014-06-03-0307-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "05-27-2014 ",
		 "DATE_CLAIM_CLOSED": "10-20-2014 ",
		 "ACTUAL_RTW_DATE": "06-09-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phoprom",
		 "CLAIMANT_FIRST_NAME": "Hilda",
		 "CLAIM_NO": "2014-01-10-0013-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "01-06-2014 ",
		 "DATE_CLAIM_CLOSED": "03-28-2014 ",
		 "ACTUAL_RTW_DATE": "01-18-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phoprom",
		 "CLAIMANT_FIRST_NAME": "Hilda",
		 "CLAIM_NO": "2014-05-01-0513-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "04-25-2014 ",
		 "DATE_CLAIM_CLOSED": "07-21-2014 ",
		 "ACTUAL_RTW_DATE": "05-02-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phoprom",
		 "CLAIMANT_FIRST_NAME": "Hilda",
		 "CLAIM_NO": "2020-02-06-0384-VP-01",
		 "DURATION": 209,
		 "DISABILITY_START_DATE": "02-06-2020 ",
		 "DATE_CLAIM_CLOSED": "12-29-2020 ",
		 "ACTUAL_RTW_DATE": "09-02-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phoprom",
		 "CLAIMANT_FIRST_NAME": "Hilda",
		 "CLAIM_NO": "2013-02-25-0304-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "02-17-2013 ",
		 "DATE_CLAIM_CLOSED": "06-25-2013 ",
		 "ACTUAL_RTW_DATE": "02-25-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phoprom",
		 "CLAIMANT_FIRST_NAME": "Hilda",
		 "CLAIM_NO": "2013-11-28-0024-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "11-17-2013 ",
		 "DATE_CLAIM_CLOSED": "01-29-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phoprom",
		 "CLAIMANT_FIRST_NAME": "Hilda",
		 "CLAIM_NO": "2014-07-21-0214-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "07-16-2014 ",
		 "DATE_CLAIM_CLOSED": "10-27-2014 ",
		 "ACTUAL_RTW_DATE": "09-18-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phoutthavong",
		 "CLAIMANT_FIRST_NAME": "Joann",
		 "CLAIM_NO": "2017-08-15-0135-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "08-28-2017 ",
		 "DATE_CLAIM_CLOSED": "10-30-2017 ",
		 "ACTUAL_RTW_DATE": "10-25-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phu",
		 "CLAIMANT_FIRST_NAME": "Deanna",
		 "CLAIM_NO": "2019-08-21-0143-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "08-20-2019 ",
		 "DATE_CLAIM_CLOSED": "09-23-2019 ",
		 "ACTUAL_RTW_DATE": "09-16-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phu",
		 "CLAIMANT_FIRST_NAME": "Deanna",
		 "CLAIM_NO": "2018-08-08-0481-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "07-31-2018 ",
		 "DATE_CLAIM_CLOSED": "09-17-2018 ",
		 "ACTUAL_RTW_DATE": "09-10-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phu",
		 "CLAIMANT_FIRST_NAME": "Deanna",
		 "CLAIM_NO": "2020-11-17-0261-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "11-04-2020 ",
		 "DATE_CLAIM_CLOSED": "01-05-2021 ",
		 "ACTUAL_RTW_DATE": "12-30-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phu",
		 "CLAIMANT_FIRST_NAME": "Deanna",
		 "CLAIM_NO": "2020-01-03-0421-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "01-03-2020 ",
		 "DATE_CLAIM_CLOSED": "09-25-2020 ",
		 "ACTUAL_RTW_DATE": "02-05-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phu",
		 "CLAIMANT_FIRST_NAME": "Deanna",
		 "CLAIM_NO": "2019-05-09-0123-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "05-09-2019 ",
		 "DATE_CLAIM_CLOSED": "06-25-2019 ",
		 "ACTUAL_RTW_DATE": "06-24-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phu",
		 "CLAIMANT_FIRST_NAME": "Deanna",
		 "CLAIM_NO": "2016-09-02-0219-VP-01",
		 "DURATION": 180,
		 "DISABILITY_START_DATE": "09-02-2016 ",
		 "DATE_CLAIM_CLOSED": "03-03-2017 ",
		 "ACTUAL_RTW_DATE": "03-01-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Phung Nguyen",
		 "CLAIMANT_FIRST_NAME": "Phuong Thanh",
		 "CLAIM_NO": "2019-03-13-0410-VP-01",
		 "DURATION": 265,
		 "DISABILITY_START_DATE": "03-08-2019 ",
		 "DATE_CLAIM_CLOSED": "12-03-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pichardo",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2018-10-25-0308-VP-01",
		 "DURATION": 153,
		 "DISABILITY_START_DATE": "10-19-2018 ",
		 "DATE_CLAIM_CLOSED": "04-01-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pichardo",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2020-09-17-1092-VP-01",
		 "DURATION": 259,
		 "DISABILITY_START_DATE": "09-21-2020 ",
		 "DATE_CLAIM_CLOSED": "06-07-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pieper",
		 "CLAIMANT_FIRST_NAME": "Whitney",
		 "CLAIM_NO": "2014-08-11-0513-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "08-25-2014 ",
		 "DATE_CLAIM_CLOSED": "11-17-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pierce",
		 "CLAIMANT_FIRST_NAME": "Mary",
		 "CLAIM_NO": "2013-01-28-0268-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "02-27-2013 ",
		 "DATE_CLAIM_CLOSED": "08-06-2013 ",
		 "ACTUAL_RTW_DATE": "03-11-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pierce",
		 "CLAIMANT_FIRST_NAME": "Elaine",
		 "CLAIM_NO": "2016-07-25-0068-VP-01",
		 "DURATION": 447,
		 "DISABILITY_START_DATE": "12-08-2015 ",
		 "DATE_CLAIM_CLOSED": "02-27-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pierce",
		 "CLAIMANT_FIRST_NAME": "Mary",
		 "CLAIM_NO": "2013-01-05-0229-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "12-19-2012 ",
		 "DATE_CLAIM_CLOSED": "03-10-2013 ",
		 "ACTUAL_RTW_DATE": "12-27-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pimentel",
		 "CLAIMANT_FIRST_NAME": "Rebecca",
		 "CLAIM_NO": "2013-05-14-0212-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "06-04-2013 ",
		 "DATE_CLAIM_CLOSED": "08-19-2013 ",
		 "ACTUAL_RTW_DATE": "07-16-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pimentel",
		 "CLAIMANT_FIRST_NAME": "Rebecca",
		 "CLAIM_NO": "2023-01-09-0585-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "01-04-2023 ",
		 "DATE_CLAIM_CLOSED": "01-23-2023 ",
		 "ACTUAL_RTW_DATE": "01-19-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pimentel",
		 "CLAIMANT_FIRST_NAME": "Marianne",
		 "CLAIM_NO": "2020-05-27-0230-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "05-25-2020 ",
		 "DATE_CLAIM_CLOSED": "07-08-2020 ",
		 "ACTUAL_RTW_DATE": "07-06-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pimentel",
		 "CLAIMANT_FIRST_NAME": "Marlene",
		 "CLAIM_NO": "2015-12-16-0477-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "12-14-2015 ",
		 "DATE_CLAIM_CLOSED": "01-13-2016 ",
		 "ACTUAL_RTW_DATE": "12-23-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pimentel",
		 "CLAIMANT_FIRST_NAME": "Rebecca",
		 "CLAIM_NO": "2016-08-31-0283-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "09-12-2016 ",
		 "DATE_CLAIM_CLOSED": "11-03-2016 ",
		 "ACTUAL_RTW_DATE": "09-27-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pimentel",
		 "CLAIMANT_FIRST_NAME": "Rebecca",
		 "CLAIM_NO": "2021-11-15-1257-VP-01",
		 "DURATION": 109,
		 "DISABILITY_START_DATE": "12-09-2021 ",
		 "DATE_CLAIM_CLOSED": "03-29-2022 ",
		 "ACTUAL_RTW_DATE": "03-28-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pineda",
		 "CLAIMANT_FIRST_NAME": "Gabriela",
		 "CLAIM_NO": "2022-06-14-1007-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "07-13-2022 ",
		 "DATE_CLAIM_CLOSED": "08-26-2022 ",
		 "ACTUAL_RTW_DATE": "08-25-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pineda",
		 "CLAIMANT_FIRST_NAME": "Gabriela",
		 "CLAIM_NO": "2013-12-13-0168-VP-01",
		 "DURATION": 25,
		 "DISABILITY_START_DATE": "01-06-2014 ",
		 "DATE_CLAIM_CLOSED": "06-02-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pineda",
		 "CLAIMANT_FIRST_NAME": "Guillermo",
		 "CLAIM_NO": "2018-04-12-0326-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "04-09-2018 ",
		 "DATE_CLAIM_CLOSED": "04-23-2018 ",
		 "ACTUAL_RTW_DATE": "04-23-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pineda",
		 "CLAIMANT_FIRST_NAME": "Humberto",
		 "CLAIM_NO": "2021-02-19-0899-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "02-22-2021 ",
		 "DATE_CLAIM_CLOSED": "03-11-2021 ",
		 "ACTUAL_RTW_DATE": "03-03-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pineda",
		 "CLAIMANT_FIRST_NAME": "Lester",
		 "CLAIM_NO": "2016-09-09-0473-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "09-08-2016 ",
		 "DATE_CLAIM_CLOSED": "07-28-2017 ",
		 "ACTUAL_RTW_DATE": "11-10-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pineda",
		 "CLAIMANT_FIRST_NAME": "Lester",
		 "CLAIM_NO": "2016-03-02-0310-VP-01",
		 "DURATION": 163,
		 "DISABILITY_START_DATE": "02-29-2016 ",
		 "DATE_CLAIM_CLOSED": "04-20-2017 ",
		 "ACTUAL_RTW_DATE": "08-10-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pinero",
		 "CLAIMANT_FIRST_NAME": "Kyrsten",
		 "CLAIM_NO": "2020-01-01-1516-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "12-26-2019 ",
		 "DATE_CLAIM_CLOSED": "01-21-2020 ",
		 "ACTUAL_RTW_DATE": "01-07-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Piparia",
		 "CLAIMANT_FIRST_NAME": "Ashmi",
		 "CLAIM_NO": "2022-01-14-1221-VP-01",
		 "DURATION": 119,
		 "DISABILITY_START_DATE": "02-14-2022 ",
		 "DATE_CLAIM_CLOSED": "04-11-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pitts",
		 "CLAIMANT_FIRST_NAME": "Bianca",
		 "CLAIM_NO": "2014-06-27-0057-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "07-11-2014 ",
		 "DATE_CLAIM_CLOSED": "12-12-2014 ",
		 "ACTUAL_RTW_DATE": "09-19-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Planting",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2019-06-19-0720-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "06-24-2019 ",
		 "DATE_CLAIM_CLOSED": "09-06-2019 ",
		 "ACTUAL_RTW_DATE": "09-03-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Planting",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2019-10-03-0741-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "10-07-2019 ",
		 "DATE_CLAIM_CLOSED": "12-02-2019 ",
		 "ACTUAL_RTW_DATE": "12-02-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Planting",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2020-06-11-0635-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "06-08-2020 ",
		 "DATE_CLAIM_CLOSED": "08-04-2020 ",
		 "ACTUAL_RTW_DATE": "08-03-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Plattner",
		 "CLAIMANT_FIRST_NAME": "Edward",
		 "CLAIM_NO": "2014-12-13-0019-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "01-12-2015 ",
		 "DATE_CLAIM_CLOSED": "02-04-2015 ",
		 "ACTUAL_RTW_DATE": "01-26-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pliego",
		 "CLAIMANT_FIRST_NAME": "Enid",
		 "CLAIM_NO": "2018-08-03-0131-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "08-10-2018 ",
		 "DATE_CLAIM_CLOSED": "09-04-2018 ",
		 "ACTUAL_RTW_DATE": "08-27-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Plummer",
		 "CLAIMANT_FIRST_NAME": "Annie",
		 "CLAIM_NO": "2013-03-13-0140-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "02-06-2013 ",
		 "DATE_CLAIM_CLOSED": "02-22-2014 ",
		 "ACTUAL_RTW_DATE": "02-19-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Plummer",
		 "CLAIMANT_FIRST_NAME": "Richard",
		 "CLAIM_NO": "2013-01-05-0419-VP-01",
		 "DURATION": 118,
		 "DISABILITY_START_DATE": "11-06-2012 ",
		 "DATE_CLAIM_CLOSED": "06-27-2013 ",
		 "ACTUAL_RTW_DATE": "03-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Poblete",
		 "CLAIMANT_FIRST_NAME": "Debra",
		 "CLAIM_NO": "2019-09-27-0137-VP-01",
		 "DURATION": 113,
		 "DISABILITY_START_DATE": "09-16-2019 ",
		 "DATE_CLAIM_CLOSED": "01-13-2020 ",
		 "ACTUAL_RTW_DATE": "01-07-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Polanco",
		 "CLAIMANT_FIRST_NAME": "Delmy",
		 "CLAIM_NO": "2016-11-16-0522-VP-01",
		 "DURATION": 72,
		 "DISABILITY_START_DATE": "10-21-2016 ",
		 "DATE_CLAIM_CLOSED": "01-06-2017 ",
		 "ACTUAL_RTW_DATE": "01-01-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Polanco",
		 "CLAIMANT_FIRST_NAME": "Delmy",
		 "CLAIM_NO": "2013-01-21-0082-VP-01",
		 "DURATION": 78,
		 "DISABILITY_START_DATE": "01-21-2013 ",
		 "DATE_CLAIM_CLOSED": "06-26-2013 ",
		 "ACTUAL_RTW_DATE": "04-09-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Poling",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2015-02-23-0404-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "02-16-2015 ",
		 "DATE_CLAIM_CLOSED": "08-04-2015 ",
		 "ACTUAL_RTW_DATE": "05-18-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Poling",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2019-01-07-0985-VP-01",
		 "DURATION": 175,
		 "DISABILITY_START_DATE": "02-05-2019 ",
		 "DATE_CLAIM_CLOSED": "08-06-2019 ",
		 "ACTUAL_RTW_DATE": "07-30-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pollock",
		 "CLAIMANT_FIRST_NAME": "Annette",
		 "CLAIM_NO": "2018-03-06-0439-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "02-27-2018 ",
		 "DATE_CLAIM_CLOSED": "03-21-2018 ",
		 "ACTUAL_RTW_DATE": "03-20-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ponce",
		 "CLAIMANT_FIRST_NAME": "Lorena",
		 "CLAIM_NO": "2015-08-11-0468-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "08-24-2015 ",
		 "DATE_CLAIM_CLOSED": "10-27-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ponce",
		 "CLAIMANT_FIRST_NAME": "Veronica",
		 "CLAIM_NO": "2022-06-09-0957-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "07-12-2022 ",
		 "DATE_CLAIM_CLOSED": "08-09-2022 ",
		 "ACTUAL_RTW_DATE": "08-08-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ponce",
		 "CLAIMANT_FIRST_NAME": "Monica",
		 "CLAIM_NO": "2021-04-05-0931-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "04-02-2021 ",
		 "DATE_CLAIM_CLOSED": "04-14-2021 ",
		 "ACTUAL_RTW_DATE": "04-12-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Poole",
		 "CLAIMANT_FIRST_NAME": "Robert",
		 "CLAIM_NO": "2020-03-18-0788-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "03-16-2020 ",
		 "DATE_CLAIM_CLOSED": "03-20-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Poole",
		 "CLAIMANT_FIRST_NAME": "Robert",
		 "CLAIM_NO": "2019-10-07-0488-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "10-01-2019 ",
		 "DATE_CLAIM_CLOSED": "11-21-2019 ",
		 "ACTUAL_RTW_DATE": "10-28-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Poon",
		 "CLAIMANT_FIRST_NAME": "Kwun Yee",
		 "CLAIM_NO": "2018-11-29-0008-VP-01",
		 "DURATION": 25,
		 "DISABILITY_START_DATE": "12-03-2018 ",
		 "DATE_CLAIM_CLOSED": "01-08-2019 ",
		 "ACTUAL_RTW_DATE": "12-28-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Powell",
		 "CLAIMANT_FIRST_NAME": "Susan",
		 "CLAIM_NO": "2022-04-27-0220-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "05-02-2022 ",
		 "DATE_CLAIM_CLOSED": "05-02-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Praino",
		 "CLAIMANT_FIRST_NAME": "Lauren",
		 "CLAIM_NO": "2022-07-21-0709-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "08-17-2022 ",
		 "DATE_CLAIM_CLOSED": "09-30-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Prakash",
		 "CLAIMANT_FIRST_NAME": "Arputha Anita",
		 "CLAIM_NO": "2020-03-12-0402-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "03-16-2020 ",
		 "DATE_CLAIM_CLOSED": "04-07-2020 ",
		 "ACTUAL_RTW_DATE": "04-06-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Prakash",
		 "CLAIMANT_FIRST_NAME": "Arputha Anita",
		 "CLAIM_NO": "2021-06-08-0967-VP-01",
		 "DURATION": 240,
		 "DISABILITY_START_DATE": "06-02-2021 ",
		 "DATE_CLAIM_CLOSED": "02-02-2022 ",
		 "ACTUAL_RTW_DATE": "01-28-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Prasad",
		 "CLAIMANT_FIRST_NAME": "Jyoti",
		 "CLAIM_NO": "2017-03-27-0232-VP-01",
		 "DURATION": 107,
		 "DISABILITY_START_DATE": "03-16-2017 ",
		 "DATE_CLAIM_CLOSED": "10-04-2017 ",
		 "ACTUAL_RTW_DATE": "07-01-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Premakumar",
		 "CLAIMANT_FIRST_NAME": "Indralakshmi",
		 "CLAIM_NO": "2020-10-27-0294-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "11-23-2020 ",
		 "DATE_CLAIM_CLOSED": "01-04-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Premakumar",
		 "CLAIMANT_FIRST_NAME": "Indralakshmi",
		 "CLAIM_NO": "2019-02-27-0742-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "03-20-2019 ",
		 "DATE_CLAIM_CLOSED": "05-01-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Prescott",
		 "CLAIMANT_FIRST_NAME": "Kathryn",
		 "CLAIM_NO": "2020-03-10-0168-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "04-13-2020 ",
		 "DATE_CLAIM_CLOSED": "06-09-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Prewitt",
		 "CLAIMANT_FIRST_NAME": "Cynthia",
		 "CLAIM_NO": "2013-12-09-0330-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "12-02-2013 ",
		 "DATE_CLAIM_CLOSED": "12-19-2013 ",
		 "ACTUAL_RTW_DATE": "12-09-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Price",
		 "CLAIMANT_FIRST_NAME": "Adriana",
		 "CLAIM_NO": "2013-04-25-0097-VP-01",
		 "DURATION": 817,
		 "DISABILITY_START_DATE": "04-11-2012 ",
		 "DATE_CLAIM_CLOSED": "10-27-2014 ",
		 "ACTUAL_RTW_DATE": "07-07-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Price",
		 "CLAIMANT_FIRST_NAME": "Regina",
		 "CLAIM_NO": "2019-07-08-0413-VP-01",
		 "DURATION": 140,
		 "DISABILITY_START_DATE": "07-15-2019 ",
		 "DATE_CLAIM_CLOSED": "12-06-2019 ",
		 "ACTUAL_RTW_DATE": "12-02-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Prichard",
		 "CLAIMANT_FIRST_NAME": "Darcie",
		 "CLAIM_NO": "2021-05-03-1011-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "06-03-2021 ",
		 "DATE_CLAIM_CLOSED": "07-16-2021 ",
		 "ACTUAL_RTW_DATE": "07-16-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Priest",
		 "CLAIMANT_FIRST_NAME": "Velma",
		 "CLAIM_NO": "2019-06-21-0408-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "06-27-2019 ",
		 "DATE_CLAIM_CLOSED": "10-29-2019 ",
		 "ACTUAL_RTW_DATE": "07-04-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Prieto",
		 "CLAIMANT_FIRST_NAME": "Sergio",
		 "CLAIM_NO": "2014-10-10-0245-VP-01",
		 "DURATION": 101,
		 "DISABILITY_START_DATE": "10-10-2014 ",
		 "DATE_CLAIM_CLOSED": "08-04-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Princeau",
		 "CLAIMANT_FIRST_NAME": "Richard",
		 "CLAIM_NO": "2014-03-24-1029-VP-01",
		 "DURATION": 307,
		 "DISABILITY_START_DATE": "03-14-2014 ",
		 "DATE_CLAIM_CLOSED": "04-13-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pruett",
		 "CLAIMANT_FIRST_NAME": "Guadalupe",
		 "CLAIM_NO": "2013-01-05-0004-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "01-02-2013 ",
		 "DATE_CLAIM_CLOSED": "06-02-2013 ",
		 "ACTUAL_RTW_DATE": "01-14-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pruitt",
		 "CLAIMANT_FIRST_NAME": "Lisa",
		 "CLAIM_NO": "2022-04-18-0389-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "04-11-2022 ",
		 "DATE_CLAIM_CLOSED": "05-09-2022 ",
		 "ACTUAL_RTW_DATE": "05-02-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Prum",
		 "CLAIMANT_FIRST_NAME": "Cun",
		 "CLAIM_NO": "2013-01-05-0447-VP-01",
		 "DURATION": 50,
		 "DISABILITY_START_DATE": "11-28-2012 ",
		 "DATE_CLAIM_CLOSED": "03-07-2013 ",
		 "ACTUAL_RTW_DATE": "01-17-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Psarakis",
		 "CLAIMANT_FIRST_NAME": "Jodaylin",
		 "CLAIM_NO": "2016-05-10-0480-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "05-31-2016 ",
		 "DATE_CLAIM_CLOSED": "08-22-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pu",
		 "CLAIMANT_FIRST_NAME": "Fiona (Qihui)",
		 "CLAIM_NO": "2021-04-05-0556-VP-01",
		 "DURATION": 65,
		 "DISABILITY_START_DATE": "06-11-2021 ",
		 "DATE_CLAIM_CLOSED": "08-17-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Pu",
		 "CLAIMANT_FIRST_NAME": "Fiona (Qihui)",
		 "CLAIM_NO": "2019-02-13-0718-VP-01",
		 "DURATION": 76,
		 "DISABILITY_START_DATE": "04-01-2019 ",
		 "DATE_CLAIM_CLOSED": "06-17-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Purtzer",
		 "CLAIMANT_FIRST_NAME": "Raleigh",
		 "CLAIM_NO": "2014-01-02-0404-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "12-23-2013 ",
		 "DATE_CLAIM_CLOSED": "11-17-2014 ",
		 "ACTUAL_RTW_DATE": "01-20-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Qin",
		 "CLAIMANT_FIRST_NAME": "Jia",
		 "CLAIM_NO": "2022-05-03-0030-VP-01",
		 "DURATION": 108,
		 "DISABILITY_START_DATE": "05-02-2022 ",
		 "DATE_CLAIM_CLOSED": "08-22-2022 ",
		 "ACTUAL_RTW_DATE": "08-18-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Quackenbush",
		 "CLAIMANT_FIRST_NAME": "Adam",
		 "CLAIM_NO": "2013-07-03-0321-VP-01",
		 "DURATION": 52,
		 "DISABILITY_START_DATE": "07-19-2013 ",
		 "DATE_CLAIM_CLOSED": "10-17-2013 ",
		 "ACTUAL_RTW_DATE": "09-09-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Quarello",
		 "CLAIMANT_FIRST_NAME": "Joseph",
		 "CLAIM_NO": "2020-08-26-0230-VP-01",
		 "DURATION": 131,
		 "DISABILITY_START_DATE": "09-09-2020 ",
		 "DATE_CLAIM_CLOSED": "01-21-2021 ",
		 "ACTUAL_RTW_DATE": "01-18-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Quatrell",
		 "CLAIMANT_FIRST_NAME": "Kimberly",
		 "CLAIM_NO": "2021-11-23-0063-VP-01",
		 "DURATION": 114,
		 "DISABILITY_START_DATE": "12-06-2021 ",
		 "DATE_CLAIM_CLOSED": "04-11-2022 ",
		 "ACTUAL_RTW_DATE": "03-30-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Queja",
		 "CLAIMANT_FIRST_NAME": "Bendionicio",
		 "CLAIM_NO": "2014-10-07-0289-VP-01",
		 "DURATION": 245,
		 "DISABILITY_START_DATE": "09-15-2014 ",
		 "DATE_CLAIM_CLOSED": "06-29-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Queja",
		 "CLAIMANT_FIRST_NAME": "Bendionicio",
		 "CLAIM_NO": "2013-01-05-0485-VP-01",
		 "DURATION": 337,
		 "DISABILITY_START_DATE": "10-29-2012 ",
		 "DATE_CLAIM_CLOSED": "10-08-2013 ",
		 "ACTUAL_RTW_DATE": "10-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Quevedo",
		 "CLAIMANT_FIRST_NAME": "Ruby",
		 "CLAIM_NO": "2023-03-13-1055-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "03-14-2023 ",
		 "DATE_CLAIM_CLOSED": "03-29-2023 ",
		 "ACTUAL_RTW_DATE": "03-27-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Quezada",
		 "CLAIMANT_FIRST_NAME": "Emanuel",
		 "CLAIM_NO": "2020-12-11-0807-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "12-01-2020 ",
		 "DATE_CLAIM_CLOSED": "12-22-2020 ",
		 "ACTUAL_RTW_DATE": "12-21-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Quezada",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2019-11-21-0648-VP-01",
		 "DURATION": 193,
		 "DISABILITY_START_DATE": "11-22-2019 ",
		 "DATE_CLAIM_CLOSED": "09-28-2020 ",
		 "ACTUAL_RTW_DATE": "06-02-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Quezada",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2019-07-22-0563-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "07-15-2019 ",
		 "DATE_CLAIM_CLOSED": "09-16-2019 ",
		 "ACTUAL_RTW_DATE": "07-24-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Quiaot",
		 "CLAIMANT_FIRST_NAME": "Jemma",
		 "CLAIM_NO": "2013-12-17-0510-VP-01",
		 "DURATION": 170,
		 "DISABILITY_START_DATE": "12-13-2013 ",
		 "DATE_CLAIM_CLOSED": "01-22-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Quiaot",
		 "CLAIMANT_FIRST_NAME": "Jemma",
		 "CLAIM_NO": "2013-01-05-3833-VP-01",
		 "DURATION": 763,
		 "DISABILITY_START_DATE": "10-12-2011 ",
		 "DATE_CLAIM_CLOSED": "01-29-2014 ",
		 "ACTUAL_RTW_DATE": "11-13-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Quijano",
		 "CLAIMANT_FIRST_NAME": "Soledad",
		 "CLAIM_NO": "2013-01-05-3107-VP-01",
		 "DURATION": 361,
		 "DISABILITY_START_DATE": "01-09-2012 ",
		 "DATE_CLAIM_CLOSED": "04-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Quijas",
		 "CLAIMANT_FIRST_NAME": "Hector",
		 "CLAIM_NO": "2022-12-09-1121-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "12-01-2022 ",
		 "DATE_CLAIM_CLOSED": "02-16-2023 ",
		 "ACTUAL_RTW_DATE": "12-19-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Quilausing",
		 "CLAIMANT_FIRST_NAME": "Sharon",
		 "CLAIM_NO": "2013-03-15-0118-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "03-11-2013 ",
		 "DATE_CLAIM_CLOSED": "05-23-2013 ",
		 "ACTUAL_RTW_DATE": "04-08-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Quillin",
		 "CLAIMANT_FIRST_NAME": "Kayla",
		 "CLAIM_NO": "2019-04-22-0217-VP-01",
		 "DURATION": 164,
		 "DISABILITY_START_DATE": "05-20-2019 ",
		 "DATE_CLAIM_CLOSED": "11-14-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Quinonez",
		 "CLAIMANT_FIRST_NAME": "Marisel",
		 "CLAIM_NO": "2016-03-26-0003-VP-01",
		 "DURATION": 97,
		 "DISABILITY_START_DATE": "03-28-2016 ",
		 "DATE_CLAIM_CLOSED": "07-06-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Quintanilla",
		 "CLAIMANT_FIRST_NAME": "Belinda",
		 "CLAIM_NO": "2014-08-05-0213-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "08-25-2014 ",
		 "DATE_CLAIM_CLOSED": "10-13-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Quintero",
		 "CLAIMANT_FIRST_NAME": "Oscar",
		 "CLAIM_NO": "2013-09-04-0096-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "09-16-2013 ",
		 "DATE_CLAIM_CLOSED": "01-29-2014 ",
		 "ACTUAL_RTW_DATE": "10-14-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Quintero",
		 "CLAIMANT_FIRST_NAME": "Blanca",
		 "CLAIM_NO": "2020-06-01-0978-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "06-08-2020 ",
		 "DATE_CLAIM_CLOSED": "07-14-2020 ",
		 "ACTUAL_RTW_DATE": "07-06-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Quintero",
		 "CLAIMANT_FIRST_NAME": "Roberto",
		 "CLAIM_NO": "2020-05-07-0543-VP-01",
		 "DURATION": 103,
		 "DISABILITY_START_DATE": "05-06-2020 ",
		 "DATE_CLAIM_CLOSED": "08-19-2020 ",
		 "ACTUAL_RTW_DATE": "08-17-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Quiroz",
		 "CLAIMANT_FIRST_NAME": "Marlene",
		 "CLAIM_NO": "2013-02-27-0364-VP-01",
		 "DURATION": 78,
		 "DISABILITY_START_DATE": "03-11-2013 ",
		 "DATE_CLAIM_CLOSED": "07-22-2013 ",
		 "ACTUAL_RTW_DATE": "05-28-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Quiroz",
		 "CLAIMANT_FIRST_NAME": "Jane",
		 "CLAIM_NO": "2015-07-06-0292-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "07-21-2015 ",
		 "DATE_CLAIM_CLOSED": "08-11-2015 ",
		 "ACTUAL_RTW_DATE": "08-10-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Quisquirin",
		 "CLAIMANT_FIRST_NAME": "Marian Abegail",
		 "CLAIM_NO": "2022-08-16-0631-VP-01",
		 "DURATION": 121,
		 "DISABILITY_START_DATE": "09-26-2022 ",
		 "DATE_CLAIM_CLOSED": "01-23-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Raabe",
		 "CLAIMANT_FIRST_NAME": "Rebecca",
		 "CLAIM_NO": "2014-12-11-0364-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "12-22-2014 ",
		 "DATE_CLAIM_CLOSED": "08-04-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rabinovich",
		 "CLAIMANT_FIRST_NAME": "Lydia",
		 "CLAIM_NO": "2014-03-12-0236-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "03-04-2014 ",
		 "DATE_CLAIM_CLOSED": "05-31-2014 ",
		 "ACTUAL_RTW_DATE": "03-25-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Radzins",
		 "CLAIMANT_FIRST_NAME": "Meredith",
		 "CLAIM_NO": "2014-03-11-0283-VP-01",
		 "DURATION": 147,
		 "DISABILITY_START_DATE": "03-10-2014 ",
		 "DATE_CLAIM_CLOSED": "09-08-2014 ",
		 "ACTUAL_RTW_DATE": "08-04-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Radzins",
		 "CLAIMANT_FIRST_NAME": "Meredith",
		 "CLAIM_NO": "2013-01-05-0344-VP-01",
		 "DURATION": 186,
		 "DISABILITY_START_DATE": "11-15-2012 ",
		 "DATE_CLAIM_CLOSED": "05-21-2013 ",
		 "ACTUAL_RTW_DATE": "05-20-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rafati",
		 "CLAIMANT_FIRST_NAME": "Mehrnaz",
		 "CLAIM_NO": "2022-11-08-0616-VP-01",
		 "DURATION": 75,
		 "DISABILITY_START_DATE": "12-12-2022 ",
		 "DATE_CLAIM_CLOSED": "02-27-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ragland",
		 "CLAIMANT_FIRST_NAME": "Guillermina",
		 "CLAIM_NO": "2013-03-19-0377-VP-01",
		 "DURATION": 111,
		 "DISABILITY_START_DATE": "03-19-2013 ",
		 "DATE_CLAIM_CLOSED": "08-23-2013 ",
		 "ACTUAL_RTW_DATE": "07-08-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ram",
		 "CLAIMANT_FIRST_NAME": "Yashesvini",
		 "CLAIM_NO": "2018-05-15-0261-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "06-06-2018 ",
		 "DATE_CLAIM_CLOSED": "08-06-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ram",
		 "CLAIMANT_FIRST_NAME": "Kamini",
		 "CLAIM_NO": "2017-06-30-0342-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "06-29-2017 ",
		 "DATE_CLAIM_CLOSED": "09-11-2017 ",
		 "ACTUAL_RTW_DATE": "08-28-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ram",
		 "CLAIMANT_FIRST_NAME": "Kamini",
		 "CLAIM_NO": "2017-01-23-0402-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "01-16-2017 ",
		 "DATE_CLAIM_CLOSED": "02-21-2017 ",
		 "ACTUAL_RTW_DATE": "02-06-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ramakrishna",
		 "CLAIMANT_FIRST_NAME": "Susmitha",
		 "CLAIM_NO": "2022-01-19-1088-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "01-10-2022 ",
		 "DATE_CLAIM_CLOSED": "02-08-2022 ",
		 "ACTUAL_RTW_DATE": "01-24-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ramesh",
		 "CLAIMANT_FIRST_NAME": "Varsha",
		 "CLAIM_NO": "2018-07-09-0378-VP-01",
		 "DURATION": 44,
		 "DISABILITY_START_DATE": "08-13-2018 ",
		 "DATE_CLAIM_CLOSED": "10-03-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ramirez",
		 "CLAIMANT_FIRST_NAME": "Patricia",
		 "CLAIM_NO": "2022-07-18-0934-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "07-22-2022 ",
		 "DATE_CLAIM_CLOSED": "07-25-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ramirez",
		 "CLAIMANT_FIRST_NAME": "Veronica",
		 "CLAIM_NO": "2016-11-29-0432-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "11-29-2016 ",
		 "DATE_CLAIM_CLOSED": "12-14-2016 ",
		 "ACTUAL_RTW_DATE": "12-08-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ramirez",
		 "CLAIMANT_FIRST_NAME": "Jackie",
		 "CLAIM_NO": "2013-01-05-0174-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "12-05-2012 ",
		 "DATE_CLAIM_CLOSED": "01-21-2013 ",
		 "ACTUAL_RTW_DATE": "01-02-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ramirez",
		 "CLAIMANT_FIRST_NAME": "Mary",
		 "CLAIM_NO": "2018-01-12-0358-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "01-15-2018 ",
		 "DATE_CLAIM_CLOSED": "03-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ramirez",
		 "CLAIMANT_FIRST_NAME": "Jackie",
		 "CLAIM_NO": "2013-10-28-0235-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "10-30-2013 ",
		 "DATE_CLAIM_CLOSED": "03-28-2014 ",
		 "ACTUAL_RTW_DATE": "11-11-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ramirez",
		 "CLAIMANT_FIRST_NAME": "Veronica",
		 "CLAIM_NO": "2017-06-07-0573-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "07-27-2017 ",
		 "DATE_CLAIM_CLOSED": "09-26-2017 ",
		 "ACTUAL_RTW_DATE": "09-22-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ramirez",
		 "CLAIMANT_FIRST_NAME": "Veronica",
		 "CLAIM_NO": "2017-10-24-0505-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "11-03-2017 ",
		 "DATE_CLAIM_CLOSED": "11-20-2017 ",
		 "ACTUAL_RTW_DATE": "11-20-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ramirez Vega",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2022-03-21-0947-VP-01",
		 "DURATION": 25,
		 "DISABILITY_START_DATE": "03-17-2022 ",
		 "DATE_CLAIM_CLOSED": "04-12-2022 ",
		 "ACTUAL_RTW_DATE": "04-11-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ramos",
		 "CLAIMANT_FIRST_NAME": "Rebecca",
		 "CLAIM_NO": "2013-04-17-0138-VP-01",
		 "DURATION": 608,
		 "DISABILITY_START_DATE": "03-25-2013 ",
		 "DATE_CLAIM_CLOSED": "11-25-2014 ",
		 "ACTUAL_RTW_DATE": "11-23-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ramos",
		 "CLAIMANT_FIRST_NAME": "Noel",
		 "CLAIM_NO": "2021-01-14-0452-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "01-11-2021 ",
		 "DATE_CLAIM_CLOSED": "03-12-2021 ",
		 "ACTUAL_RTW_DATE": "01-26-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ramos",
		 "CLAIMANT_FIRST_NAME": "Megan",
		 "CLAIM_NO": "2013-04-02-0423-VP-01",
		 "DURATION": 111,
		 "DISABILITY_START_DATE": "04-02-2013 ",
		 "DATE_CLAIM_CLOSED": "08-12-2013 ",
		 "ACTUAL_RTW_DATE": "07-22-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ramos",
		 "CLAIMANT_FIRST_NAME": "Queenie",
		 "CLAIM_NO": "2016-08-02-0581-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "07-29-2016 ",
		 "DATE_CLAIM_CLOSED": "10-10-2016 ",
		 "ACTUAL_RTW_DATE": "09-21-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ramos Buquid",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2021-12-02-1078-VP-01",
		 "DURATION": 88,
		 "DISABILITY_START_DATE": "01-06-2022 ",
		 "DATE_CLAIM_CLOSED": "01-12-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ramos-Resurreccion",
		 "CLAIMANT_FIRST_NAME": "Anne",
		 "CLAIM_NO": "2013-04-18-0371-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "04-16-2013 ",
		 "DATE_CLAIM_CLOSED": "07-21-2013 ",
		 "ACTUAL_RTW_DATE": "04-29-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ran",
		 "CLAIMANT_FIRST_NAME": "Xing",
		 "CLAIM_NO": "2017-04-26-0257-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "04-24-2017 ",
		 "DATE_CLAIM_CLOSED": "06-14-2017 ",
		 "ACTUAL_RTW_DATE": "06-12-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rana",
		 "CLAIMANT_FIRST_NAME": "Pooja",
		 "CLAIM_NO": "2021-10-13-0242-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "10-25-2021 ",
		 "DATE_CLAIM_CLOSED": "12-09-2021 ",
		 "ACTUAL_RTW_DATE": "12-06-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rancitelli-Mcmillan",
		 "CLAIMANT_FIRST_NAME": "Frederica",
		 "CLAIM_NO": "2016-04-27-0507-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "05-03-2016 ",
		 "DATE_CLAIM_CLOSED": "06-03-2016 ",
		 "ACTUAL_RTW_DATE": "05-10-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Randev",
		 "CLAIMANT_FIRST_NAME": "Vijay",
		 "CLAIM_NO": "2020-11-21-0094-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "12-22-2020 ",
		 "DATE_CLAIM_CLOSED": "02-10-2021 ",
		 "ACTUAL_RTW_DATE": "02-05-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rangel",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2023-02-28-0823-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "02-20-2023 ",
		 "DATE_CLAIM_CLOSED": "03-30-2023 ",
		 "ACTUAL_RTW_DATE": "03-13-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rangel",
		 "CLAIMANT_FIRST_NAME": "Wendy",
		 "CLAIM_NO": "2020-07-29-0660-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "07-31-2020 ",
		 "DATE_CLAIM_CLOSED": "08-19-2020 ",
		 "ACTUAL_RTW_DATE": "08-17-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rangel",
		 "CLAIMANT_FIRST_NAME": "Wendy",
		 "CLAIM_NO": "2021-10-11-0520-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "10-26-2021 ",
		 "DATE_CLAIM_CLOSED": "12-28-2021 ",
		 "ACTUAL_RTW_DATE": "12-07-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rankin",
		 "CLAIMANT_FIRST_NAME": "Esmeralda",
		 "CLAIM_NO": "2021-06-03-0591-VP-01",
		 "DURATION": 86,
		 "DISABILITY_START_DATE": "06-01-2021 ",
		 "DATE_CLAIM_CLOSED": "08-31-2021 ",
		 "ACTUAL_RTW_DATE": "08-26-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ranola",
		 "CLAIMANT_FIRST_NAME": "Leonida",
		 "CLAIM_NO": "2021-06-25-0394-VP-01",
		 "DURATION": 259,
		 "DISABILITY_START_DATE": "06-21-2021 ",
		 "DATE_CLAIM_CLOSED": "06-20-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ransom",
		 "CLAIMANT_FIRST_NAME": "Juanita",
		 "CLAIM_NO": "2016-03-04-0462-VP-01",
		 "DURATION": 151,
		 "DISABILITY_START_DATE": "03-03-2016 ",
		 "DATE_CLAIM_CLOSED": "08-01-2016 ",
		 "ACTUAL_RTW_DATE": "08-01-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ransom",
		 "CLAIMANT_FIRST_NAME": "Juanita",
		 "CLAIM_NO": "2017-05-04-0447-VP-01",
		 "DURATION": 38,
		 "DISABILITY_START_DATE": "05-08-2017 ",
		 "DATE_CLAIM_CLOSED": "06-19-2017 ",
		 "ACTUAL_RTW_DATE": "06-15-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ransom",
		 "CLAIMANT_FIRST_NAME": "Juanita",
		 "CLAIM_NO": "2013-01-25-0259-VP-01",
		 "DURATION": 0,
		 "DISABILITY_START_DATE": "01-23-2013 ",
		 "DATE_CLAIM_CLOSED": "05-07-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ransom",
		 "CLAIMANT_FIRST_NAME": "Juanita",
		 "CLAIM_NO": "2020-02-12-0486-VP-01",
		 "DURATION": 157,
		 "DISABILITY_START_DATE": "02-07-2020 ",
		 "DATE_CLAIM_CLOSED": "07-14-2020 ",
		 "ACTUAL_RTW_DATE": "07-13-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ransom",
		 "CLAIMANT_FIRST_NAME": "Juanita",
		 "CLAIM_NO": "2017-01-09-0371-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "01-04-2017 ",
		 "DATE_CLAIM_CLOSED": "01-30-2017 ",
		 "ACTUAL_RTW_DATE": "01-19-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Raper",
		 "CLAIMANT_FIRST_NAME": "Fern",
		 "CLAIM_NO": "2023-01-26-0462-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "01-17-2023 ",
		 "DATE_CLAIM_CLOSED": "03-16-2023 ",
		 "ACTUAL_RTW_DATE": "01-31-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Raper",
		 "CLAIMANT_FIRST_NAME": "Fern",
		 "CLAIM_NO": "2021-04-02-0101-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "04-01-2021 ",
		 "DATE_CLAIM_CLOSED": "04-22-2021 ",
		 "ACTUAL_RTW_DATE": "04-15-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Raper",
		 "CLAIMANT_FIRST_NAME": "Fern",
		 "CLAIM_NO": "2018-12-10-0631-VP-01",
		 "DURATION": 53,
		 "DISABILITY_START_DATE": "12-06-2018 ",
		 "DATE_CLAIM_CLOSED": "02-04-2019 ",
		 "ACTUAL_RTW_DATE": "01-28-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Raper",
		 "CLAIMANT_FIRST_NAME": "Fern",
		 "CLAIM_NO": "2020-01-23-0811-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "01-10-2020 ",
		 "DATE_CLAIM_CLOSED": "02-05-2020 ",
		 "ACTUAL_RTW_DATE": "02-03-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Raper",
		 "CLAIMANT_FIRST_NAME": "Fern",
		 "CLAIM_NO": "2022-05-10-1038-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "05-03-2022 ",
		 "DATE_CLAIM_CLOSED": "06-20-2022 ",
		 "ACTUAL_RTW_DATE": "05-31-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Raper",
		 "CLAIMANT_FIRST_NAME": "Fern",
		 "CLAIM_NO": "2020-02-18-0487-VP-01",
		 "DURATION": 38,
		 "DISABILITY_START_DATE": "02-07-2020 ",
		 "DATE_CLAIM_CLOSED": "03-18-2020 ",
		 "ACTUAL_RTW_DATE": "03-16-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Raper",
		 "CLAIMANT_FIRST_NAME": "Fern",
		 "CLAIM_NO": "2022-08-29-0391-VP-01",
		 "DURATION": 87,
		 "DISABILITY_START_DATE": "08-29-2022 ",
		 "DATE_CLAIM_CLOSED": "01-10-2023 ",
		 "ACTUAL_RTW_DATE": "11-24-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Raper",
		 "CLAIMANT_FIRST_NAME": "Fern",
		 "CLAIM_NO": "2018-11-01-0492-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "11-05-2018 ",
		 "DATE_CLAIM_CLOSED": "11-28-2018 ",
		 "ACTUAL_RTW_DATE": "11-19-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Raper",
		 "CLAIMANT_FIRST_NAME": "Fern",
		 "CLAIM_NO": "2019-02-14-0619-VP-01",
		 "DURATION": 25,
		 "DISABILITY_START_DATE": "02-07-2019 ",
		 "DATE_CLAIM_CLOSED": "03-06-2019 ",
		 "ACTUAL_RTW_DATE": "03-04-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rapinan",
		 "CLAIMANT_FIRST_NAME": "Penelope",
		 "CLAIM_NO": "2016-05-12-0125-VP-01",
		 "DURATION": 79,
		 "DISABILITY_START_DATE": "05-11-2016 ",
		 "DATE_CLAIM_CLOSED": "07-27-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rapinan",
		 "CLAIMANT_FIRST_NAME": "Penelope",
		 "CLAIM_NO": "2015-08-21-0197-VP-01",
		 "DURATION": 99,
		 "DISABILITY_START_DATE": "08-24-2015 ",
		 "DATE_CLAIM_CLOSED": "12-02-2015 ",
		 "ACTUAL_RTW_DATE": "12-01-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rapinan",
		 "CLAIMANT_FIRST_NAME": "Penelope",
		 "CLAIM_NO": "2013-08-06-0493-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "09-05-2013 ",
		 "DATE_CLAIM_CLOSED": "11-11-2013 ",
		 "ACTUAL_RTW_DATE": "11-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Raul",
		 "CLAIMANT_FIRST_NAME": "Marcella",
		 "CLAIM_NO": "2013-07-16-0045-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "07-09-2013 ",
		 "DATE_CLAIM_CLOSED": "09-16-2013 ",
		 "ACTUAL_RTW_DATE": "07-25-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Raul",
		 "CLAIMANT_FIRST_NAME": "Marcella",
		 "CLAIM_NO": "2017-02-13-0502-VP-01",
		 "DURATION": 119,
		 "DISABILITY_START_DATE": "03-08-2017 ",
		 "DATE_CLAIM_CLOSED": "07-10-2017 ",
		 "ACTUAL_RTW_DATE": "07-05-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Raul",
		 "CLAIMANT_FIRST_NAME": "Marcella",
		 "CLAIM_NO": "2017-09-01-0291-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "08-30-2017 ",
		 "DATE_CLAIM_CLOSED": "11-06-2017 ",
		 "ACTUAL_RTW_DATE": "11-02-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Raul",
		 "CLAIMANT_FIRST_NAME": "Marcella",
		 "CLAIM_NO": "2016-07-27-0320-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "07-21-2016 ",
		 "DATE_CLAIM_CLOSED": "11-02-2016 ",
		 "ACTUAL_RTW_DATE": "10-10-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Raul",
		 "CLAIMANT_FIRST_NAME": "Marcella",
		 "CLAIM_NO": "2015-06-22-0210-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "07-13-2015 ",
		 "DATE_CLAIM_CLOSED": "09-15-2015 ",
		 "ACTUAL_RTW_DATE": "09-14-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ravishankar",
		 "CLAIMANT_FIRST_NAME": "Arpitha",
		 "CLAIM_NO": "2018-12-27-0485-VP-01",
		 "DURATION": 196,
		 "DISABILITY_START_DATE": "01-21-2019 ",
		 "DATE_CLAIM_CLOSED": "08-07-2019 ",
		 "ACTUAL_RTW_DATE": "08-05-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Razavi",
		 "CLAIMANT_FIRST_NAME": "Neda",
		 "CLAIM_NO": "2017-08-18-0485-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "08-21-2017 ",
		 "DATE_CLAIM_CLOSED": "09-28-2017 ",
		 "ACTUAL_RTW_DATE": "09-11-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rebosa",
		 "CLAIMANT_FIRST_NAME": "Saturnino",
		 "CLAIM_NO": "2020-12-28-0493-VP-01",
		 "DURATION": 114,
		 "DISABILITY_START_DATE": "01-07-2021 ",
		 "DATE_CLAIM_CLOSED": "05-10-2021 ",
		 "ACTUAL_RTW_DATE": "05-01-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Recinos-Sandoval",
		 "CLAIMANT_FIRST_NAME": "Ana",
		 "CLAIM_NO": "2013-01-05-0054-VP-01",
		 "DURATION": 99,
		 "DISABILITY_START_DATE": "01-02-2013 ",
		 "DATE_CLAIM_CLOSED": "05-06-2013 ",
		 "ACTUAL_RTW_DATE": "04-11-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reck",
		 "CLAIMANT_FIRST_NAME": "Tiffany",
		 "CLAIM_NO": "2022-02-16-1045-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "03-22-2022 ",
		 "DATE_CLAIM_CLOSED": "05-04-2022 ",
		 "ACTUAL_RTW_DATE": "05-02-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rector",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2019-04-02-0716-VP-01",
		 "DURATION": 112,
		 "DISABILITY_START_DATE": "04-08-2019 ",
		 "DATE_CLAIM_CLOSED": "07-31-2019 ",
		 "ACTUAL_RTW_DATE": "07-29-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rector",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2018-06-29-0499-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "06-25-2018 ",
		 "DATE_CLAIM_CLOSED": "07-23-2018 ",
		 "ACTUAL_RTW_DATE": "07-10-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reddy",
		 "CLAIMANT_FIRST_NAME": "Gyaneshwar",
		 "CLAIM_NO": "2019-02-19-0844-VP-01",
		 "DURATION": -350,
		 "DISABILITY_START_DATE": "02-18-2019 ",
		 "DATE_CLAIM_CLOSED": "03-11-2019 ",
		 "ACTUAL_RTW_DATE": "03-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reddy",
		 "CLAIMANT_FIRST_NAME": "Gyaneshwar",
		 "CLAIM_NO": "2018-01-08-0756-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "01-04-2018 ",
		 "DATE_CLAIM_CLOSED": "01-18-2018 ",
		 "ACTUAL_RTW_DATE": "01-15-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reed",
		 "CLAIMANT_FIRST_NAME": "Thomas",
		 "CLAIM_NO": "2018-02-07-0176-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "02-06-2018 ",
		 "DATE_CLAIM_CLOSED": "03-14-2018 ",
		 "ACTUAL_RTW_DATE": "03-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reed",
		 "CLAIMANT_FIRST_NAME": "Thomas",
		 "CLAIM_NO": "2019-07-10-0190-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "06-26-2019 ",
		 "DATE_CLAIM_CLOSED": "09-24-2019 ",
		 "ACTUAL_RTW_DATE": "07-10-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reed",
		 "CLAIMANT_FIRST_NAME": "Thomas",
		 "CLAIM_NO": "2019-09-18-0166-VP-01",
		 "DURATION": 1254,
		 "DISABILITY_START_DATE": "09-05-2019 ",
		 "DATE_CLAIM_CLOSED": "02-17-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reed",
		 "CLAIMANT_FIRST_NAME": "Thomas",
		 "CLAIM_NO": "2018-10-15-0539-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "10-15-2018 ",
		 "DATE_CLAIM_CLOSED": "10-25-2018 ",
		 "ACTUAL_RTW_DATE": "10-22-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reed",
		 "CLAIMANT_FIRST_NAME": "Robert",
		 "CLAIM_NO": "2017-04-07-0475-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "04-03-2017 ",
		 "DATE_CLAIM_CLOSED": "05-30-2017 ",
		 "ACTUAL_RTW_DATE": "04-24-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Refuerzo",
		 "CLAIMANT_FIRST_NAME": "Baby Rose",
		 "CLAIM_NO": "2018-08-16-0545-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "08-13-2018 ",
		 "DATE_CLAIM_CLOSED": "09-26-2018 ",
		 "ACTUAL_RTW_DATE": "08-27-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Refuerzo",
		 "CLAIMANT_FIRST_NAME": "Baby Rose",
		 "CLAIM_NO": "2021-02-11-0459-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "02-08-2021 ",
		 "DATE_CLAIM_CLOSED": "03-05-2021 ",
		 "ACTUAL_RTW_DATE": "02-15-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Refuerzo",
		 "CLAIMANT_FIRST_NAME": "Baby Rose",
		 "CLAIM_NO": "2019-02-19-0783-VP-01",
		 "DURATION": 78,
		 "DISABILITY_START_DATE": "02-26-2019 ",
		 "DATE_CLAIM_CLOSED": "05-20-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Refuerzo",
		 "CLAIMANT_FIRST_NAME": "Baby Rose",
		 "CLAIM_NO": "2020-06-02-0266-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "05-28-2020 ",
		 "DATE_CLAIM_CLOSED": "06-02-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reich",
		 "CLAIMANT_FIRST_NAME": "John",
		 "CLAIM_NO": "2013-07-30-0205-VP-01",
		 "DURATION": 6,
		 "DISABILITY_START_DATE": "05-28-2013 ",
		 "DATE_CLAIM_CLOSED": "08-12-2013 ",
		 "ACTUAL_RTW_DATE": "06-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reich",
		 "CLAIMANT_FIRST_NAME": "John",
		 "CLAIM_NO": "2013-05-27-0012-VP-01",
		 "DURATION": 6,
		 "DISABILITY_START_DATE": "05-28-2013 ",
		 "DATE_CLAIM_CLOSED": "07-30-2013 ",
		 "ACTUAL_RTW_DATE": "06-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reigel",
		 "CLAIMANT_FIRST_NAME": "Jo Anne",
		 "CLAIM_NO": "2022-07-28-0334-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "07-20-2022 ",
		 "DATE_CLAIM_CLOSED": "09-12-2022 ",
		 "ACTUAL_RTW_DATE": "09-06-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reisinger",
		 "CLAIMANT_FIRST_NAME": "Cathy",
		 "CLAIM_NO": "2013-06-19-0247-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "06-17-2013 ",
		 "DATE_CLAIM_CLOSED": "09-16-2013 ",
		 "ACTUAL_RTW_DATE": "07-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Renderos",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2017-10-02-0616-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "10-05-2017 ",
		 "DATE_CLAIM_CLOSED": "11-20-2017 ",
		 "ACTUAL_RTW_DATE": "11-20-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Renfro",
		 "CLAIMANT_FIRST_NAME": "Jewel",
		 "CLAIM_NO": "2013-09-17-0008-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "09-12-2013 ",
		 "DATE_CLAIM_CLOSED": "05-12-2014 ",
		 "ACTUAL_RTW_DATE": "10-28-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Renfro",
		 "CLAIMANT_FIRST_NAME": "Jewel",
		 "CLAIM_NO": "2015-04-01-0492-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "04-28-2015 ",
		 "DATE_CLAIM_CLOSED": "06-02-2015 ",
		 "ACTUAL_RTW_DATE": "05-13-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Renfro",
		 "CLAIMANT_FIRST_NAME": "Alana",
		 "CLAIM_NO": "2021-09-17-0500-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "10-04-2021 ",
		 "DATE_CLAIM_CLOSED": "11-30-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Renollet",
		 "CLAIMANT_FIRST_NAME": "Annette",
		 "CLAIM_NO": "2013-04-29-0254-VP-01",
		 "DURATION": 255,
		 "DISABILITY_START_DATE": "04-26-2013 ",
		 "DATE_CLAIM_CLOSED": "01-08-2014 ",
		 "ACTUAL_RTW_DATE": "01-06-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Retonel",
		 "CLAIMANT_FIRST_NAME": "Lea",
		 "CLAIM_NO": "2014-01-31-0302-VP-01",
		 "DURATION": 168,
		 "DISABILITY_START_DATE": "01-29-2014 ",
		 "DATE_CLAIM_CLOSED": "06-23-2016 ",
		 "ACTUAL_RTW_DATE": "07-16-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Revilleza",
		 "CLAIMANT_FIRST_NAME": "Catrina",
		 "CLAIM_NO": "2020-07-14-0876-VP-01",
		 "DURATION": 51,
		 "DISABILITY_START_DATE": "07-21-2020 ",
		 "DATE_CLAIM_CLOSED": "09-08-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Revilleza",
		 "CLAIMANT_FIRST_NAME": "Catrina",
		 "CLAIM_NO": "2021-11-17-1136-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "11-11-2021 ",
		 "DATE_CLAIM_CLOSED": "01-20-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reyes",
		 "CLAIMANT_FIRST_NAME": "Evelyn",
		 "CLAIM_NO": "2016-08-08-0553-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "08-15-2016 ",
		 "DATE_CLAIM_CLOSED": "09-01-2016 ",
		 "ACTUAL_RTW_DATE": "08-26-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reyes",
		 "CLAIMANT_FIRST_NAME": "Erlindo",
		 "CLAIM_NO": "2013-05-07-0166-VP-01",
		 "DURATION": 36,
		 "DISABILITY_START_DATE": "05-03-2013 ",
		 "DATE_CLAIM_CLOSED": "09-28-2013 ",
		 "ACTUAL_RTW_DATE": "06-08-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reyes",
		 "CLAIMANT_FIRST_NAME": "Evelyn",
		 "CLAIM_NO": "2019-09-16-0686-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "09-16-2019 ",
		 "DATE_CLAIM_CLOSED": "10-16-2019 ",
		 "ACTUAL_RTW_DATE": "10-10-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reyes",
		 "CLAIMANT_FIRST_NAME": "Aida",
		 "CLAIM_NO": "2021-10-20-0018-VP-01",
		 "DURATION": 114,
		 "DISABILITY_START_DATE": "10-25-2021 ",
		 "DATE_CLAIM_CLOSED": "02-28-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reyes",
		 "CLAIMANT_FIRST_NAME": "Pascual",
		 "CLAIM_NO": "2021-12-20-0888-VP-01",
		 "DURATION": 143,
		 "DISABILITY_START_DATE": "01-11-2022 ",
		 "DATE_CLAIM_CLOSED": "06-06-2022 ",
		 "ACTUAL_RTW_DATE": "06-03-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reyez",
		 "CLAIMANT_FIRST_NAME": "Guillermo",
		 "CLAIM_NO": "2021-08-27-0778-VP-01",
		 "DURATION": 108,
		 "DISABILITY_START_DATE": "08-30-2021 ",
		 "DATE_CLAIM_CLOSED": "12-23-2021 ",
		 "ACTUAL_RTW_DATE": "12-16-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reyna",
		 "CLAIMANT_FIRST_NAME": "Xochitl",
		 "CLAIM_NO": "2022-05-09-0471-VP-01",
		 "DURATION": 37,
		 "DISABILITY_START_DATE": "04-25-2022 ",
		 "DATE_CLAIM_CLOSED": "06-20-2022 ",
		 "ACTUAL_RTW_DATE": "06-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reyna",
		 "CLAIMANT_FIRST_NAME": "Leticia",
		 "CLAIM_NO": "2021-09-27-0878-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "09-27-2021 ",
		 "DATE_CLAIM_CLOSED": "11-09-2021 ",
		 "ACTUAL_RTW_DATE": "11-08-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reyna",
		 "CLAIMANT_FIRST_NAME": "Xochitl",
		 "CLAIM_NO": "2019-08-27-0471-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "08-27-2019 ",
		 "DATE_CLAIM_CLOSED": "10-31-2019 ",
		 "ACTUAL_RTW_DATE": "10-21-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reynolds",
		 "CLAIMANT_FIRST_NAME": "Jason",
		 "CLAIM_NO": "2019-11-22-0567-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "11-04-2019 ",
		 "DATE_CLAIM_CLOSED": "12-11-2019 ",
		 "ACTUAL_RTW_DATE": "11-14-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reynolds",
		 "CLAIMANT_FIRST_NAME": "Jason",
		 "CLAIM_NO": "2014-04-29-0278-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "04-29-2014 ",
		 "DATE_CLAIM_CLOSED": "10-06-2014 ",
		 "ACTUAL_RTW_DATE": "05-07-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reynolds",
		 "CLAIMANT_FIRST_NAME": "Jason",
		 "CLAIM_NO": "2021-02-26-0724-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "02-18-2021 ",
		 "DATE_CLAIM_CLOSED": "03-17-2021 ",
		 "ACTUAL_RTW_DATE": "03-16-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reynoso",
		 "CLAIMANT_FIRST_NAME": "Desiree",
		 "CLAIM_NO": "2017-04-27-0482-VP-01",
		 "DURATION": 100,
		 "DISABILITY_START_DATE": "05-08-2017 ",
		 "DATE_CLAIM_CLOSED": "08-21-2017 ",
		 "ACTUAL_RTW_DATE": "08-16-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reynoso",
		 "CLAIMANT_FIRST_NAME": "Desiree",
		 "CLAIM_NO": "2013-02-12-0333-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "02-11-2013 ",
		 "DATE_CLAIM_CLOSED": "05-07-2013 ",
		 "ACTUAL_RTW_DATE": "02-18-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Reynoso Meza",
		 "CLAIMANT_FIRST_NAME": "Rosa",
		 "CLAIM_NO": "2021-12-01-1073-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "12-01-2021 ",
		 "DATE_CLAIM_CLOSED": "02-04-2022 ",
		 "ACTUAL_RTW_DATE": "02-02-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rheaume",
		 "CLAIMANT_FIRST_NAME": "Randall",
		 "CLAIM_NO": "2017-01-19-0344-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "01-13-2017 ",
		 "DATE_CLAIM_CLOSED": "03-10-2017 ",
		 "ACTUAL_RTW_DATE": "01-23-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ricafort",
		 "CLAIMANT_FIRST_NAME": "Laarni",
		 "CLAIM_NO": "2016-07-26-0568-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "07-18-2016 ",
		 "DATE_CLAIM_CLOSED": "10-31-2016 ",
		 "ACTUAL_RTW_DATE": "10-17-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ricafort",
		 "CLAIMANT_FIRST_NAME": "Laarni",
		 "CLAIM_NO": "2017-02-02-0421-VP-01",
		 "DURATION": 66,
		 "DISABILITY_START_DATE": "02-06-2017 ",
		 "DATE_CLAIM_CLOSED": "04-17-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ricafort",
		 "CLAIMANT_FIRST_NAME": "Laarni",
		 "CLAIM_NO": "2015-01-15-0471-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "02-09-2015 ",
		 "DATE_CLAIM_CLOSED": "06-16-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rice",
		 "CLAIMANT_FIRST_NAME": "Mark",
		 "CLAIM_NO": "2022-07-25-0493-VP-01",
		 "DURATION": 25,
		 "DISABILITY_START_DATE": "07-21-2022 ",
		 "DATE_CLAIM_CLOSED": "08-19-2022 ",
		 "ACTUAL_RTW_DATE": "08-15-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Richards",
		 "CLAIMANT_FIRST_NAME": "Deanna",
		 "CLAIM_NO": "2021-08-11-0405-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "08-11-2021 ",
		 "DATE_CLAIM_CLOSED": "09-22-2021 ",
		 "ACTUAL_RTW_DATE": "09-13-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Richards",
		 "CLAIMANT_FIRST_NAME": "Deanna",
		 "CLAIM_NO": "2014-01-15-0483-VP-01",
		 "DURATION": 90,
		 "DISABILITY_START_DATE": "02-13-2014 ",
		 "DATE_CLAIM_CLOSED": "05-31-2014 ",
		 "ACTUAL_RTW_DATE": "05-14-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Richardson",
		 "CLAIMANT_FIRST_NAME": "Evette",
		 "CLAIM_NO": "2017-04-25-0150-VP-01",
		 "DURATION": 98,
		 "DISABILITY_START_DATE": "04-17-2017 ",
		 "DATE_CLAIM_CLOSED": "08-01-2017 ",
		 "ACTUAL_RTW_DATE": "07-24-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Richardson",
		 "CLAIMANT_FIRST_NAME": "Evette",
		 "CLAIM_NO": "2013-12-04-0414-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "11-26-2013 ",
		 "DATE_CLAIM_CLOSED": "06-21-2014 ",
		 "ACTUAL_RTW_DATE": "12-09-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Richardson",
		 "CLAIMANT_FIRST_NAME": "Andrea",
		 "CLAIM_NO": "2019-10-24-0641-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "10-21-2019 ",
		 "DATE_CLAIM_CLOSED": "11-15-2019 ",
		 "ACTUAL_RTW_DATE": "11-04-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rider",
		 "CLAIMANT_FIRST_NAME": "Alma",
		 "CLAIM_NO": "2018-05-30-0210-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "05-28-2018 ",
		 "DATE_CLAIM_CLOSED": "08-06-2018 ",
		 "ACTUAL_RTW_DATE": "08-06-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rief",
		 "CLAIMANT_FIRST_NAME": "Brian",
		 "CLAIM_NO": "2021-10-21-0733-VP-01",
		 "DURATION": 39,
		 "DISABILITY_START_DATE": "10-21-2021 ",
		 "DATE_CLAIM_CLOSED": "12-02-2021 ",
		 "ACTUAL_RTW_DATE": "11-29-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rimer",
		 "CLAIMANT_FIRST_NAME": "Stephanie",
		 "CLAIM_NO": "2016-03-08-0586-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "03-14-2016 ",
		 "DATE_CLAIM_CLOSED": "04-15-2016 ",
		 "ACTUAL_RTW_DATE": "04-04-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rincon",
		 "CLAIMANT_FIRST_NAME": "Hilda",
		 "CLAIM_NO": "2021-01-19-1031-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "01-04-2021 ",
		 "DATE_CLAIM_CLOSED": "02-24-2021 ",
		 "ACTUAL_RTW_DATE": "02-19-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rini",
		 "CLAIMANT_FIRST_NAME": "Theresa",
		 "CLAIM_NO": "2020-01-02-0723-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "12-30-2019 ",
		 "DATE_CLAIM_CLOSED": "02-05-2020 ",
		 "ACTUAL_RTW_DATE": "02-03-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rios",
		 "CLAIMANT_FIRST_NAME": "Marian",
		 "CLAIM_NO": "2014-05-06-0213-VP-01",
		 "DURATION": 0,
		 "DISABILITY_START_DATE": "05-09-2014 ",
		 "DATE_CLAIM_CLOSED": "05-16-2014 ",
		 "ACTUAL_RTW_DATE": "05-09-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rishi",
		 "CLAIMANT_FIRST_NAME": "Sarthak",
		 "CLAIM_NO": "2019-03-10-0022-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "03-11-2019 ",
		 "DATE_CLAIM_CLOSED": "03-28-2019 ",
		 "ACTUAL_RTW_DATE": "03-25-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Risser",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2019-09-03-0804-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "09-02-2019 ",
		 "DATE_CLAIM_CLOSED": "10-14-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Risser",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2022-03-30-0790-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "05-23-2022 ",
		 "DATE_CLAIM_CLOSED": "07-05-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Risser",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2017-10-12-0044-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "11-09-2017 ",
		 "DATE_CLAIM_CLOSED": "01-10-2018 ",
		 "ACTUAL_RTW_DATE": "01-03-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ritchie",
		 "CLAIMANT_FIRST_NAME": "Patricia",
		 "CLAIM_NO": "2020-06-29-0253-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "07-15-2020 ",
		 "DATE_CLAIM_CLOSED": "08-17-2020 ",
		 "ACTUAL_RTW_DATE": "08-12-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rivadeneyra",
		 "CLAIMANT_FIRST_NAME": "Elia",
		 "CLAIM_NO": "2013-08-26-0495-VP-01",
		 "DURATION": 0,
		 "DISABILITY_START_DATE": "08-26-2013 ",
		 "DATE_CLAIM_CLOSED": "11-18-2013 ",
		 "ACTUAL_RTW_DATE": "08-26-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rivadeneyra",
		 "CLAIMANT_FIRST_NAME": "Elia",
		 "CLAIM_NO": "2014-04-16-0262-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "04-14-2014 ",
		 "DATE_CLAIM_CLOSED": "07-10-2014 ",
		 "ACTUAL_RTW_DATE": "04-28-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rivelli",
		 "CLAIMANT_FIRST_NAME": "Lauren",
		 "CLAIM_NO": "2020-01-13-0802-VP-01",
		 "DURATION": 73,
		 "DISABILITY_START_DATE": "02-17-2020 ",
		 "DATE_CLAIM_CLOSED": "04-30-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rivera",
		 "CLAIMANT_FIRST_NAME": "Claudia",
		 "CLAIM_NO": "2014-09-10-0584-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "08-29-2014 ",
		 "DATE_CLAIM_CLOSED": "01-21-2015 ",
		 "ACTUAL_RTW_DATE": "09-29-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rivera",
		 "CLAIMANT_FIRST_NAME": "Blanca",
		 "CLAIM_NO": "2016-12-28-0180-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "01-04-2017 ",
		 "DATE_CLAIM_CLOSED": "01-24-2017 ",
		 "ACTUAL_RTW_DATE": "01-18-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rivera",
		 "CLAIMANT_FIRST_NAME": "Blanca",
		 "CLAIM_NO": "2023-02-08-0356-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "02-02-2023 ",
		 "DATE_CLAIM_CLOSED": "03-06-2023 ",
		 "ACTUAL_RTW_DATE": "02-13-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rivera",
		 "CLAIMANT_FIRST_NAME": "Martha",
		 "CLAIM_NO": "2013-10-16-0419-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "10-18-2013 ",
		 "DATE_CLAIM_CLOSED": "01-23-2014 ",
		 "ACTUAL_RTW_DATE": "11-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rivera",
		 "CLAIMANT_FIRST_NAME": "Ignacio",
		 "CLAIM_NO": "2013-09-11-0294-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "10-15-2013 ",
		 "DATE_CLAIM_CLOSED": "08-04-2014 ",
		 "ACTUAL_RTW_DATE": "01-14-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rivera",
		 "CLAIMANT_FIRST_NAME": "Blanca",
		 "CLAIM_NO": "2022-10-24-0024-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "10-14-2022 ",
		 "DATE_CLAIM_CLOSED": "12-12-2022 ",
		 "ACTUAL_RTW_DATE": "10-24-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rivera",
		 "CLAIMANT_FIRST_NAME": "Martha",
		 "CLAIM_NO": "2013-12-02-0152-VP-01",
		 "DURATION": 175,
		 "DISABILITY_START_DATE": "12-02-2013 ",
		 "DATE_CLAIM_CLOSED": "05-20-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rivera",
		 "CLAIMANT_FIRST_NAME": "Blanca",
		 "CLAIM_NO": "2020-10-19-0724-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "10-13-2020 ",
		 "DATE_CLAIM_CLOSED": "11-02-2020 ",
		 "ACTUAL_RTW_DATE": "11-02-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rivera",
		 "CLAIMANT_FIRST_NAME": "Blanca",
		 "CLAIM_NO": "2019-10-10-0660-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "11-11-2019 ",
		 "DATE_CLAIM_CLOSED": "12-26-2019 ",
		 "ACTUAL_RTW_DATE": "12-23-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rivera Contreras",
		 "CLAIMANT_FIRST_NAME": "Arlene",
		 "CLAIM_NO": "2016-11-03-0382-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "11-14-2016 ",
		 "DATE_CLAIM_CLOSED": "12-29-2016 ",
		 "ACTUAL_RTW_DATE": "12-27-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rivera Hernandez",
		 "CLAIMANT_FIRST_NAME": "Mia",
		 "CLAIM_NO": "2015-03-19-0099-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "03-17-2015 ",
		 "DATE_CLAIM_CLOSED": "08-04-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rivera Jr",
		 "CLAIMANT_FIRST_NAME": "Jose",
		 "CLAIM_NO": "2013-02-18-0123-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "02-21-2013 ",
		 "DATE_CLAIM_CLOSED": "05-13-2013 ",
		 "ACTUAL_RTW_DATE": "03-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rivera-Castaneda",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2018-11-28-0496-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "11-27-2018 ",
		 "DATE_CLAIM_CLOSED": "12-10-2018 ",
		 "ACTUAL_RTW_DATE": "12-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rivers",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2013-01-07-0388-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "01-07-2013 ",
		 "DATE_CLAIM_CLOSED": "06-10-2013 ",
		 "ACTUAL_RTW_DATE": "02-18-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rivers",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2018-09-07-0413-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "09-04-2018 ",
		 "DATE_CLAIM_CLOSED": "10-17-2018 ",
		 "ACTUAL_RTW_DATE": "10-16-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Roa",
		 "CLAIMANT_FIRST_NAME": "Audrey",
		 "CLAIM_NO": "2019-05-29-0690-VP-01",
		 "DURATION": 23,
		 "DISABILITY_START_DATE": "06-20-2019 ",
		 "DATE_CLAIM_CLOSED": "07-23-2019 ",
		 "ACTUAL_RTW_DATE": "07-13-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Roa",
		 "CLAIMANT_FIRST_NAME": "Audrey",
		 "CLAIM_NO": "2017-12-05-0328-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "01-03-2018 ",
		 "DATE_CLAIM_CLOSED": "02-20-2018 ",
		 "ACTUAL_RTW_DATE": "02-12-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Roberson",
		 "CLAIMANT_FIRST_NAME": "Cynthia",
		 "CLAIM_NO": "2014-07-23-0285-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "07-22-2014 ",
		 "DATE_CLAIM_CLOSED": "12-16-2014 ",
		 "ACTUAL_RTW_DATE": "09-03-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Roberson",
		 "CLAIMANT_FIRST_NAME": "Roberta P",
		 "CLAIM_NO": "2022-04-15-0304-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "04-15-2022 ",
		 "DATE_CLAIM_CLOSED": "05-17-2022 ",
		 "ACTUAL_RTW_DATE": "05-02-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Roberts",
		 "CLAIMANT_FIRST_NAME": "Timothy",
		 "CLAIM_NO": "2023-03-09-0025-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "03-09-2023 ",
		 "DATE_CLAIM_CLOSED": "04-11-2023 ",
		 "ACTUAL_RTW_DATE": "03-27-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Robinson",
		 "CLAIMANT_FIRST_NAME": "Aaron",
		 "CLAIM_NO": "2014-09-30-0517-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "10-15-2014 ",
		 "DATE_CLAIM_CLOSED": "12-08-2014 ",
		 "ACTUAL_RTW_DATE": "10-27-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Robinson",
		 "CLAIMANT_FIRST_NAME": "Brandi",
		 "CLAIM_NO": "2018-06-01-0556-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "05-14-2018 ",
		 "DATE_CLAIM_CLOSED": "06-18-2018 ",
		 "ACTUAL_RTW_DATE": "05-31-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Robinson",
		 "CLAIMANT_FIRST_NAME": "Brandi",
		 "CLAIM_NO": "2018-06-15-0231-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "06-06-2018 ",
		 "DATE_CLAIM_CLOSED": "07-26-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Robledo",
		 "CLAIMANT_FIRST_NAME": "Jenna",
		 "CLAIM_NO": "2018-08-13-0549-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "09-04-2018 ",
		 "DATE_CLAIM_CLOSED": "11-12-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Robles",
		 "CLAIMANT_FIRST_NAME": "Elisa",
		 "CLAIM_NO": "2013-03-20-0124-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "03-18-2013 ",
		 "DATE_CLAIM_CLOSED": "05-13-2013 ",
		 "ACTUAL_RTW_DATE": "04-08-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Robles",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2019-06-04-0530-VP-01",
		 "DURATION": 52,
		 "DISABILITY_START_DATE": "06-24-2019 ",
		 "DATE_CLAIM_CLOSED": "08-20-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Roche",
		 "CLAIMANT_FIRST_NAME": "Qing",
		 "CLAIM_NO": "2020-09-25-0547-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "09-28-2020 ",
		 "DATE_CLAIM_CLOSED": "01-21-2021 ",
		 "ACTUAL_RTW_DATE": "11-23-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rochet",
		 "CLAIMANT_FIRST_NAME": "Ana",
		 "CLAIM_NO": "2016-09-02-0306-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "09-15-2016 ",
		 "DATE_CLAIM_CLOSED": "11-18-2016 ",
		 "ACTUAL_RTW_DATE": "11-17-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rodas",
		 "CLAIMANT_FIRST_NAME": "Cesar",
		 "CLAIM_NO": "2015-10-20-0301-VP-01",
		 "DURATION": 79,
		 "DISABILITY_START_DATE": "10-17-2015 ",
		 "DATE_CLAIM_CLOSED": "01-11-2016 ",
		 "ACTUAL_RTW_DATE": "01-04-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rode",
		 "CLAIMANT_FIRST_NAME": "Theresa",
		 "CLAIM_NO": "2016-11-04-0135-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "12-06-2016 ",
		 "DATE_CLAIM_CLOSED": "01-10-2017 ",
		 "ACTUAL_RTW_DATE": "01-03-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rode",
		 "CLAIMANT_FIRST_NAME": "Theresa",
		 "CLAIM_NO": "2017-07-28-0419-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "08-29-2017 ",
		 "DATE_CLAIM_CLOSED": "09-19-2017 ",
		 "ACTUAL_RTW_DATE": "09-15-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rodriguez",
		 "CLAIMANT_FIRST_NAME": "Lissette",
		 "CLAIM_NO": "2020-03-25-0444-VP-01",
		 "DURATION": 124,
		 "DISABILITY_START_DATE": "03-25-2020 ",
		 "DATE_CLAIM_CLOSED": "07-27-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rodriguez",
		 "CLAIMANT_FIRST_NAME": "Nelson",
		 "CLAIM_NO": "2018-08-08-0248-VP-01",
		 "DURATION": 38,
		 "DISABILITY_START_DATE": "09-24-2018 ",
		 "DATE_CLAIM_CLOSED": "11-05-2018 ",
		 "ACTUAL_RTW_DATE": "11-01-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rodriguez",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2021-08-27-0395-VP-01",
		 "DURATION": 146,
		 "DISABILITY_START_DATE": "08-27-2021 ",
		 "DATE_CLAIM_CLOSED": "02-02-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rodriguez",
		 "CLAIMANT_FIRST_NAME": "Ligia",
		 "CLAIM_NO": "2020-11-30-0941-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "11-13-2020 ",
		 "DATE_CLAIM_CLOSED": "12-02-2020 ",
		 "ACTUAL_RTW_DATE": "12-01-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rodriguez",
		 "CLAIMANT_FIRST_NAME": "Nelson",
		 "CLAIM_NO": "2019-05-07-0772-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "06-05-2019 ",
		 "DATE_CLAIM_CLOSED": "07-25-2019 ",
		 "ACTUAL_RTW_DATE": "07-22-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rodriguez",
		 "CLAIMANT_FIRST_NAME": "Irma",
		 "CLAIM_NO": "2020-01-06-0732-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "12-12-2019 ",
		 "DATE_CLAIM_CLOSED": "01-27-2020 ",
		 "ACTUAL_RTW_DATE": "01-23-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rodriguez",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2018-07-16-0011-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "07-12-2018 ",
		 "DATE_CLAIM_CLOSED": "08-06-2018 ",
		 "ACTUAL_RTW_DATE": "07-27-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rodriguez",
		 "CLAIMANT_FIRST_NAME": "Brianna",
		 "CLAIM_NO": "2022-10-25-0875-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "10-19-2022 ",
		 "DATE_CLAIM_CLOSED": "11-21-2022 ",
		 "ACTUAL_RTW_DATE": "10-31-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rodriguez",
		 "CLAIMANT_FIRST_NAME": "Kristofer",
		 "CLAIM_NO": "2022-09-27-0040-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "10-17-2022 ",
		 "DATE_CLAIM_CLOSED": "12-30-2022 ",
		 "ACTUAL_RTW_DATE": "11-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rodriguez",
		 "CLAIMANT_FIRST_NAME": "Marisol",
		 "CLAIM_NO": "2021-01-14-0944-VP-01",
		 "DURATION": 66,
		 "DISABILITY_START_DATE": "01-18-2021 ",
		 "DATE_CLAIM_CLOSED": "03-25-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rodriguez",
		 "CLAIMANT_FIRST_NAME": "Conchita",
		 "CLAIM_NO": "2021-02-26-0764-VP-01",
		 "DURATION": -664721,
		 "DISABILITY_START_DATE": "03-04-2021 ",
		 "DATE_CLAIM_CLOSED": "04-26-2021 ",
		 "ACTUAL_RTW_DATE": "3\/25\/0201",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rodriguez",
		 "CLAIMANT_FIRST_NAME": "Conchita",
		 "CLAIM_NO": "2021-06-07-1041-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "06-08-2021 ",
		 "DATE_CLAIM_CLOSED": "08-05-2021 ",
		 "ACTUAL_RTW_DATE": "06-30-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rodriguez",
		 "CLAIMANT_FIRST_NAME": "Nelson",
		 "CLAIM_NO": "2014-06-20-0068-VP-01",
		 "DURATION": 61,
		 "DISABILITY_START_DATE": "07-02-2014 ",
		 "DATE_CLAIM_CLOSED": "01-12-2015 ",
		 "ACTUAL_RTW_DATE": "09-01-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rodriguez",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2014-07-07-0596-VP-01",
		 "DURATION": 36,
		 "DISABILITY_START_DATE": "07-07-2014 ",
		 "DATE_CLAIM_CLOSED": "12-12-2014 ",
		 "ACTUAL_RTW_DATE": "08-12-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rodriguez",
		 "CLAIMANT_FIRST_NAME": "Vanessa",
		 "CLAIM_NO": "2022-02-15-0944-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "03-21-2022 ",
		 "DATE_CLAIM_CLOSED": "04-13-2022 ",
		 "ACTUAL_RTW_DATE": "04-11-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rodriguez",
		 "CLAIMANT_FIRST_NAME": "Lissette",
		 "CLAIM_NO": "2019-02-04-0080-VP-01",
		 "DURATION": 51,
		 "DISABILITY_START_DATE": "02-11-2019 ",
		 "DATE_CLAIM_CLOSED": "04-10-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rodriguez",
		 "CLAIMANT_FIRST_NAME": "Eusebio",
		 "CLAIM_NO": "2021-08-16-0492-VP-01",
		 "DURATION": 363,
		 "DISABILITY_START_DATE": "08-16-2021 ",
		 "DATE_CLAIM_CLOSED": "08-17-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rodriguez",
		 "CLAIMANT_FIRST_NAME": "Maricela",
		 "CLAIM_NO": "2013-09-05-0006-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "09-16-2013 ",
		 "DATE_CLAIM_CLOSED": "01-09-2014 ",
		 "ACTUAL_RTW_DATE": "10-07-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rodriguez Mejia",
		 "CLAIMANT_FIRST_NAME": "Elisa",
		 "CLAIM_NO": "2018-02-06-0765-VP-01",
		 "DURATION": 44,
		 "DISABILITY_START_DATE": "03-19-2018 ",
		 "DATE_CLAIM_CLOSED": "06-13-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rogers",
		 "CLAIMANT_FIRST_NAME": "Amanda",
		 "CLAIM_NO": "2022-08-30-0367-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "09-06-2022 ",
		 "DATE_CLAIM_CLOSED": "10-03-2022 ",
		 "ACTUAL_RTW_DATE": "09-30-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rogers",
		 "CLAIMANT_FIRST_NAME": "Amanda",
		 "CLAIM_NO": "2022-01-17-0679-VP-01",
		 "DURATION": 86,
		 "DISABILITY_START_DATE": "03-28-2022 ",
		 "DATE_CLAIM_CLOSED": "05-17-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rogers",
		 "CLAIMANT_FIRST_NAME": "Teresa",
		 "CLAIM_NO": "2021-05-18-0292-VP-01",
		 "DURATION": 98,
		 "DISABILITY_START_DATE": "05-10-2021 ",
		 "DATE_CLAIM_CLOSED": "08-17-2021 ",
		 "ACTUAL_RTW_DATE": "08-16-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rojas",
		 "CLAIMANT_FIRST_NAME": "Celia",
		 "CLAIM_NO": "2014-12-23-0292-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "01-22-2015 ",
		 "DATE_CLAIM_CLOSED": "05-21-2015 ",
		 "ACTUAL_RTW_DATE": "02-12-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rojas",
		 "CLAIMANT_FIRST_NAME": "Kevin",
		 "CLAIM_NO": "2017-04-11-0275-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "04-10-2017 ",
		 "DATE_CLAIM_CLOSED": "05-11-2017 ",
		 "ACTUAL_RTW_DATE": "05-08-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rojas",
		 "CLAIMANT_FIRST_NAME": "Kevin",
		 "CLAIM_NO": "2016-09-20-0142-VP-01",
		 "DISABILITY_START_DATE": "08-31-2016 ",
		 "DATE_CLAIM_CLOSED": "09-29-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Roman",
		 "CLAIMANT_FIRST_NAME": "Jester",
		 "CLAIM_NO": "2016-03-31-0224-VP-01",
		 "DURATION": 132,
		 "DISABILITY_START_DATE": "03-31-2016 ",
		 "DATE_CLAIM_CLOSED": "08-19-2016 ",
		 "ACTUAL_RTW_DATE": "08-10-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Roman Delgado",
		 "CLAIMANT_FIRST_NAME": "Marianett",
		 "CLAIM_NO": "2020-12-22-1379-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "01-22-2021 ",
		 "DATE_CLAIM_CLOSED": "03-19-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Roman Delgado",
		 "CLAIMANT_FIRST_NAME": "Marianett",
		 "CLAIM_NO": "2017-10-09-0644-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "11-08-2017 ",
		 "DATE_CLAIM_CLOSED": "01-09-2018 ",
		 "ACTUAL_RTW_DATE": "01-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Romasoc",
		 "CLAIMANT_FIRST_NAME": "Audie",
		 "CLAIM_NO": "2019-12-04-0433-VP-01",
		 "DURATION": 117,
		 "DISABILITY_START_DATE": "12-04-2019 ",
		 "DATE_CLAIM_CLOSED": "04-02-2020 ",
		 "ACTUAL_RTW_DATE": "03-30-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Romero",
		 "CLAIMANT_FIRST_NAME": "Christopher",
		 "CLAIM_NO": "2022-10-03-1102-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "10-03-2022 ",
		 "DATE_CLAIM_CLOSED": "10-27-2022 ",
		 "ACTUAL_RTW_DATE": "10-18-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Romero",
		 "CLAIMANT_FIRST_NAME": "Melissa",
		 "CLAIM_NO": "2020-09-23-0619-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "09-10-2020 ",
		 "DATE_CLAIM_CLOSED": "10-05-2020 ",
		 "ACTUAL_RTW_DATE": "09-28-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Romero",
		 "CLAIMANT_FIRST_NAME": "Cathlyn",
		 "CLAIM_NO": "2021-12-14-1167-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "12-09-2021 ",
		 "DATE_CLAIM_CLOSED": "01-05-2022 ",
		 "ACTUAL_RTW_DATE": "12-28-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Romero",
		 "CLAIMANT_FIRST_NAME": "Laura",
		 "CLAIM_NO": "2020-05-08-0636-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "05-11-2020 ",
		 "DATE_CLAIM_CLOSED": "07-20-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Romero",
		 "CLAIMANT_FIRST_NAME": "Joan",
		 "CLAIM_NO": "2018-12-09-0024-VP-01",
		 "DURATION": 85,
		 "DISABILITY_START_DATE": "01-07-2019 ",
		 "DATE_CLAIM_CLOSED": "04-02-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Romo",
		 "CLAIMANT_FIRST_NAME": "Christina",
		 "CLAIM_NO": "2018-03-15-0260-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "03-26-2018 ",
		 "DATE_CLAIM_CLOSED": "06-18-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Romo",
		 "CLAIMANT_FIRST_NAME": "Karina P.",
		 "CLAIM_NO": "2021-05-11-0966-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "06-25-2021 ",
		 "DATE_CLAIM_CLOSED": "09-17-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Romo",
		 "CLAIMANT_FIRST_NAME": "Christina",
		 "CLAIM_NO": "2015-12-29-0391-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "12-16-2015 ",
		 "DATE_CLAIM_CLOSED": "02-04-2016 ",
		 "ACTUAL_RTW_DATE": "02-03-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Romo",
		 "CLAIMANT_FIRST_NAME": "Karina P.",
		 "CLAIM_NO": "2019-03-13-0702-VP-01",
		 "DURATION": 69,
		 "DISABILITY_START_DATE": "04-15-2019 ",
		 "DATE_CLAIM_CLOSED": "06-24-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Romo",
		 "CLAIMANT_FIRST_NAME": "Helen",
		 "CLAIM_NO": "2018-06-05-0719-VP-01",
		 "DURATION": 110,
		 "DISABILITY_START_DATE": "07-05-2018 ",
		 "DATE_CLAIM_CLOSED": "11-06-2018 ",
		 "ACTUAL_RTW_DATE": "10-23-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Romo",
		 "CLAIMANT_FIRST_NAME": "Helen",
		 "CLAIM_NO": "2019-10-08-0306-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "10-07-2019 ",
		 "DATE_CLAIM_CLOSED": "12-10-2019 ",
		 "ACTUAL_RTW_DATE": "12-09-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rondero",
		 "CLAIMANT_FIRST_NAME": "Everlyn",
		 "CLAIM_NO": "2016-11-09-0166-VP-01",
		 "DURATION": 105,
		 "DISABILITY_START_DATE": "11-07-2016 ",
		 "DATE_CLAIM_CLOSED": "05-31-2017 ",
		 "ACTUAL_RTW_DATE": "02-20-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rondero",
		 "CLAIMANT_FIRST_NAME": "Everlyn",
		 "CLAIM_NO": "2020-01-20-0190-VP-01",
		 "DURATION": 353,
		 "DISABILITY_START_DATE": "01-15-2020 ",
		 "DATE_CLAIM_CLOSED": "01-06-2021 ",
		 "ACTUAL_RTW_DATE": "01-02-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rosales",
		 "CLAIMANT_FIRST_NAME": "Elizabeth",
		 "CLAIM_NO": "2013-02-05-0106-VP-02",
		 "DURATION": 88,
		 "DISABILITY_START_DATE": "02-14-2013 ",
		 "DATE_CLAIM_CLOSED": "07-09-2013 ",
		 "ACTUAL_RTW_DATE": "05-13-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rosales",
		 "CLAIMANT_FIRST_NAME": "Elizabeth",
		 "CLAIM_NO": "2013-02-05-0106-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "02-14-2013 ",
		 "DATE_CLAIM_CLOSED": "05-07-2013 ",
		 "ACTUAL_RTW_DATE": "05-06-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Roscoe",
		 "CLAIMANT_FIRST_NAME": "Kevin",
		 "CLAIM_NO": "2013-01-05-5729-VP-02",
		 "DURATION": 136,
		 "DISABILITY_START_DATE": "06-06-2014 ",
		 "DATE_CLAIM_CLOSED": "01-06-2016 ",
		 "ACTUAL_RTW_DATE": "10-20-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ross",
		 "CLAIMANT_FIRST_NAME": "Justin",
		 "CLAIM_NO": "2022-11-05-0084-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "11-04-2022 ",
		 "DATE_CLAIM_CLOSED": "11-14-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ross",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2023-02-01-0645-VP-01",
		 "DURATION": 36,
		 "DISABILITY_START_DATE": "01-23-2023 ",
		 "DATE_CLAIM_CLOSED": "04-04-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ross",
		 "CLAIMANT_FIRST_NAME": "Dennis",
		 "CLAIM_NO": "2016-09-09-0441-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "09-12-2016 ",
		 "DATE_CLAIM_CLOSED": "10-27-2016 ",
		 "ACTUAL_RTW_DATE": "10-24-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ross",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2022-08-03-0966-VP-01",
		 "DURATION": 75,
		 "DISABILITY_START_DATE": "07-20-2022 ",
		 "DATE_CLAIM_CLOSED": "10-04-2022 ",
		 "ACTUAL_RTW_DATE": "10-03-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rousseau-Espinoza",
		 "CLAIMANT_FIRST_NAME": "Mirelle",
		 "CLAIM_NO": "2014-07-17-0207-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "07-30-2014 ",
		 "DATE_CLAIM_CLOSED": "10-27-2014 ",
		 "ACTUAL_RTW_DATE": "10-06-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rousseau-Espinoza",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2018-08-13-0527-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "08-30-2018 ",
		 "DATE_CLAIM_CLOSED": "11-29-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rousseau-Espinoza",
		 "CLAIMANT_FIRST_NAME": "Mirelle",
		 "CLAIM_NO": "2021-09-02-0431-VP-01",
		 "DURATION": 164,
		 "DISABILITY_START_DATE": "08-31-2021 ",
		 "DATE_CLAIM_CLOSED": "02-11-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rousseau-Espinoza",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2020-05-05-0264-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "06-01-2020 ",
		 "DATE_CLAIM_CLOSED": "08-10-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rowland",
		 "CLAIMANT_FIRST_NAME": "Diane",
		 "CLAIM_NO": "2020-06-15-0559-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "06-19-2020 ",
		 "DATE_CLAIM_CLOSED": "08-06-2020 ",
		 "ACTUAL_RTW_DATE": "08-03-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rowlands",
		 "CLAIMANT_FIRST_NAME": "London",
		 "CLAIM_NO": "2021-08-24-1000-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "08-10-2021 ",
		 "DATE_CLAIM_CLOSED": "10-06-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rowsey",
		 "CLAIMANT_FIRST_NAME": "Eric",
		 "CLAIM_NO": "2013-03-22-0004-VP-01",
		 "DURATION": 25,
		 "DISABILITY_START_DATE": "04-18-2013 ",
		 "DATE_CLAIM_CLOSED": "06-30-2013 ",
		 "ACTUAL_RTW_DATE": "05-13-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Roxas",
		 "CLAIMANT_FIRST_NAME": "Michelle Anne",
		 "CLAIM_NO": "2022-02-17-0930-VP-01",
		 "DURATION": 154,
		 "DISABILITY_START_DATE": "04-01-2022 ",
		 "DATE_CLAIM_CLOSED": "06-27-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Roxas",
		 "CLAIMANT_FIRST_NAME": "Michelle Anne",
		 "CLAIM_NO": "2021-04-15-0295-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "05-19-2021 ",
		 "DATE_CLAIM_CLOSED": "06-07-2021 ",
		 "ACTUAL_RTW_DATE": "06-02-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Roy",
		 "CLAIMANT_FIRST_NAME": "Cassie Leeanne",
		 "CLAIM_NO": "2020-06-15-0274-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "06-15-2020 ",
		 "DATE_CLAIM_CLOSED": "07-29-2020 ",
		 "ACTUAL_RTW_DATE": "06-25-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Roy",
		 "CLAIMANT_FIRST_NAME": "Cassie Leeanne",
		 "CLAIM_NO": "2021-09-27-0838-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "11-04-2021 ",
		 "DATE_CLAIM_CLOSED": "11-22-2021 ",
		 "ACTUAL_RTW_DATE": "11-19-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rozran-Moser",
		 "CLAIMANT_FIRST_NAME": "Wendy",
		 "CLAIM_NO": "2018-08-28-0252-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "09-14-2018 ",
		 "DATE_CLAIM_CLOSED": "10-30-2018 ",
		 "ACTUAL_RTW_DATE": "10-29-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rozran-Moser",
		 "CLAIMANT_FIRST_NAME": "Wendy",
		 "CLAIM_NO": "2021-09-14-0950-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "10-04-2021 ",
		 "DATE_CLAIM_CLOSED": "12-06-2021 ",
		 "ACTUAL_RTW_DATE": "11-29-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ruan",
		 "CLAIMANT_FIRST_NAME": "Feng Qing",
		 "CLAIM_NO": "2020-08-14-0763-VP-01",
		 "DURATION": 119,
		 "DISABILITY_START_DATE": "08-17-2020 ",
		 "DATE_CLAIM_CLOSED": "12-21-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ruan",
		 "CLAIMANT_FIRST_NAME": "Feng Qing",
		 "CLAIM_NO": "2019-11-05-0823-VP-01",
		 "DURATION": 101,
		 "DISABILITY_START_DATE": "10-28-2019 ",
		 "DATE_CLAIM_CLOSED": "02-10-2020 ",
		 "ACTUAL_RTW_DATE": "02-06-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ruark",
		 "CLAIMANT_FIRST_NAME": "Janet",
		 "CLAIM_NO": "2019-02-05-0707-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "02-06-2019 ",
		 "DATE_CLAIM_CLOSED": "02-05-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rubio",
		 "CLAIMANT_FIRST_NAME": "Mandy",
		 "CLAIM_NO": "2013-04-22-0351-VP-01",
		 "DURATION": 103,
		 "DISABILITY_START_DATE": "04-22-2013 ",
		 "DATE_CLAIM_CLOSED": "08-05-2013 ",
		 "ACTUAL_RTW_DATE": "08-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ruckert",
		 "CLAIMANT_FIRST_NAME": "Megan",
		 "CLAIM_NO": "2019-05-13-0219-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "05-06-2019 ",
		 "DATE_CLAIM_CLOSED": "07-09-2019 ",
		 "ACTUAL_RTW_DATE": "06-24-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ruckert",
		 "CLAIMANT_FIRST_NAME": "Megan",
		 "CLAIM_NO": "2019-07-18-0261-VP-01",
		 "DURATION": 365,
		 "DISABILITY_START_DATE": "07-17-2019 ",
		 "DATE_CLAIM_CLOSED": "07-20-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ruckert",
		 "CLAIMANT_FIRST_NAME": "Megan",
		 "CLAIM_NO": "2014-03-21-0382-VP-01",
		 "DURATION": 86,
		 "DISABILITY_START_DATE": "04-22-2014 ",
		 "DATE_CLAIM_CLOSED": "07-24-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rudolf",
		 "CLAIMANT_FIRST_NAME": "Nilli",
		 "CLAIM_NO": "2014-01-30-0147-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "02-06-2014 ",
		 "DATE_CLAIM_CLOSED": "02-18-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rudolph",
		 "CLAIMANT_FIRST_NAME": "Thomas",
		 "CLAIM_NO": "2021-03-11-0674-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "03-12-2021 ",
		 "DATE_CLAIM_CLOSED": "06-07-2021 ",
		 "ACTUAL_RTW_DATE": "06-01-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rudy",
		 "CLAIMANT_FIRST_NAME": "Brian",
		 "CLAIM_NO": "2019-01-29-0614-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "02-12-2019 ",
		 "DATE_CLAIM_CLOSED": "03-14-2019 ",
		 "ACTUAL_RTW_DATE": "03-13-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ruiz",
		 "CLAIMANT_FIRST_NAME": "Renato",
		 "CLAIM_NO": "2019-06-13-0015-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "06-10-2019 ",
		 "DATE_CLAIM_CLOSED": "07-17-2019 ",
		 "ACTUAL_RTW_DATE": "07-08-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ruiz",
		 "CLAIMANT_FIRST_NAME": "Minerva",
		 "CLAIM_NO": "2021-02-15-0417-VP-01",
		 "DURATION": 123,
		 "DISABILITY_START_DATE": "02-05-2021 ",
		 "DATE_CLAIM_CLOSED": "06-08-2021 ",
		 "ACTUAL_RTW_DATE": "06-08-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ruiz",
		 "CLAIMANT_FIRST_NAME": "Max",
		 "CLAIM_NO": "2019-01-28-0650-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "12-21-2018 ",
		 "DATE_CLAIM_CLOSED": "02-04-2019 ",
		 "ACTUAL_RTW_DATE": "01-02-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ruiz",
		 "CLAIMANT_FIRST_NAME": "Abraham",
		 "CLAIM_NO": "2016-03-29-0005-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "04-12-2016 ",
		 "DATE_CLAIM_CLOSED": "05-09-2016 ",
		 "ACTUAL_RTW_DATE": "05-02-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ruiz",
		 "CLAIMANT_FIRST_NAME": "Samuel",
		 "CLAIM_NO": "2013-10-28-0207-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "10-30-2013 ",
		 "DATE_CLAIM_CLOSED": "02-03-2014 ",
		 "ACTUAL_RTW_DATE": "11-30-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ruiz-Price",
		 "CLAIMANT_FIRST_NAME": "Josephine",
		 "CLAIM_NO": "2021-03-17-0021-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "03-16-2021 ",
		 "DATE_CLAIM_CLOSED": "04-28-2021 ",
		 "ACTUAL_RTW_DATE": "04-26-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ruiz-Price",
		 "CLAIMANT_FIRST_NAME": "Josephine",
		 "CLAIM_NO": "2018-03-16-0157-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "03-17-2018 ",
		 "DATE_CLAIM_CLOSED": "03-21-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rumpilla",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2013-03-04-0241-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "03-12-2013 ",
		 "DATE_CLAIM_CLOSED": "05-13-2013 ",
		 "ACTUAL_RTW_DATE": "04-12-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rumpilla",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2021-07-15-0240-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "07-12-2021 ",
		 "DATE_CLAIM_CLOSED": "07-20-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rumpilla",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2013-08-14-0287-VP-01",
		 "DURATION": 96,
		 "DISABILITY_START_DATE": "08-15-2013 ",
		 "DATE_CLAIM_CLOSED": "12-11-2013 ",
		 "ACTUAL_RTW_DATE": "11-19-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rumpilla",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2019-04-09-0528-VP-01",
		 "DURATION": 66,
		 "DISABILITY_START_DATE": "04-26-2019 ",
		 "DATE_CLAIM_CLOSED": "07-09-2019 ",
		 "ACTUAL_RTW_DATE": "07-01-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rumpilla",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2014-05-02-0408-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "04-30-2014 ",
		 "DATE_CLAIM_CLOSED": "08-04-2014 ",
		 "ACTUAL_RTW_DATE": "05-22-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rumpilla",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2014-08-14-0115-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "08-25-2014 ",
		 "DATE_CLAIM_CLOSED": "12-17-2014 ",
		 "ACTUAL_RTW_DATE": "09-15-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rumpilla",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2016-03-30-0491-VP-01",
		 "DURATION": 83,
		 "DISABILITY_START_DATE": "04-19-2016 ",
		 "DATE_CLAIM_CLOSED": "07-11-2016 ",
		 "ACTUAL_RTW_DATE": "07-11-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rumpilla",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2020-07-30-0757-VP-01",
		 "DURATION": 94,
		 "DISABILITY_START_DATE": "08-24-2020 ",
		 "DATE_CLAIM_CLOSED": "12-08-2020 ",
		 "ACTUAL_RTW_DATE": "11-26-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Russell",
		 "CLAIMANT_FIRST_NAME": "Carly",
		 "CLAIM_NO": "2020-04-07-0673-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "04-13-2020 ",
		 "DATE_CLAIM_CLOSED": "06-12-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Russelle",
		 "CLAIMANT_FIRST_NAME": "Brenda",
		 "CLAIM_NO": "2013-01-05-2575-VP-01",
		 "DURATION": 673,
		 "DISABILITY_START_DATE": "03-27-2012 ",
		 "DATE_CLAIM_CLOSED": "02-10-2014 ",
		 "ACTUAL_RTW_DATE": "01-29-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ruster",
		 "CLAIMANT_FIRST_NAME": "Karine",
		 "CLAIM_NO": "2022-01-04-1506-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "01-26-2022 ",
		 "DATE_CLAIM_CLOSED": "03-04-2022 ",
		 "ACTUAL_RTW_DATE": "03-02-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Rylie",
		 "CLAIMANT_FIRST_NAME": "Carmelita",
		 "CLAIM_NO": "2021-12-27-1598-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "12-14-2021 ",
		 "DATE_CLAIM_CLOSED": "01-05-2022 ",
		 "ACTUAL_RTW_DATE": "01-04-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saba",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2020-07-16-0484-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "07-13-2020 ",
		 "DATE_CLAIM_CLOSED": "08-12-2020 ",
		 "ACTUAL_RTW_DATE": "08-10-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saba",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2021-07-08-0263-VP-01",
		 "DURATION": 245,
		 "DISABILITY_START_DATE": "07-12-2021 ",
		 "DATE_CLAIM_CLOSED": "03-15-2022 ",
		 "ACTUAL_RTW_DATE": "03-14-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saberi",
		 "CLAIMANT_FIRST_NAME": "Linda",
		 "CLAIM_NO": "2018-08-19-0012-VP-01",
		 "DURATION": 92,
		 "DISABILITY_START_DATE": "08-29-2018 ",
		 "DATE_CLAIM_CLOSED": "12-14-2018 ",
		 "ACTUAL_RTW_DATE": "11-29-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sabet",
		 "CLAIMANT_FIRST_NAME": "Leyla",
		 "CLAIM_NO": "2019-03-07-0676-VP-01",
		 "DURATION": 108,
		 "DISABILITY_START_DATE": "04-01-2019 ",
		 "DATE_CLAIM_CLOSED": "07-23-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sablaon",
		 "CLAIMANT_FIRST_NAME": "Lorna",
		 "CLAIM_NO": "2022-04-08-0720-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "04-13-2022 ",
		 "DATE_CLAIM_CLOSED": "05-10-2022 ",
		 "ACTUAL_RTW_DATE": "05-09-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sadat",
		 "CLAIMANT_FIRST_NAME": "Said",
		 "CLAIM_NO": "2013-07-18-0252-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "07-10-2013 ",
		 "DATE_CLAIM_CLOSED": "09-28-2013 ",
		 "ACTUAL_RTW_DATE": "09-16-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saelee",
		 "CLAIMANT_FIRST_NAME": "Natho",
		 "CLAIM_NO": "2014-11-13-0593-VP-01",
		 "DURATION": 351,
		 "DISABILITY_START_DATE": "11-11-2014 ",
		 "DATE_CLAIM_CLOSED": "11-09-2015 ",
		 "ACTUAL_RTW_DATE": "10-28-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saelee",
		 "CLAIMANT_FIRST_NAME": "Natho",
		 "CLAIM_NO": "2018-12-12-0484-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "12-11-2018 ",
		 "DATE_CLAIM_CLOSED": "01-08-2019 ",
		 "ACTUAL_RTW_DATE": "12-18-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saenz",
		 "CLAIMANT_FIRST_NAME": "Jessica",
		 "CLAIM_NO": "2019-05-20-0235-VP-01",
		 "DURATION": 65,
		 "DISABILITY_START_DATE": "05-28-2019 ",
		 "DATE_CLAIM_CLOSED": "08-06-2019 ",
		 "ACTUAL_RTW_DATE": "08-01-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saenz",
		 "CLAIMANT_FIRST_NAME": "Jessica",
		 "CLAIM_NO": "2016-11-10-0567-VP-01",
		 "DURATION": 52,
		 "DISABILITY_START_DATE": "11-11-2016 ",
		 "DATE_CLAIM_CLOSED": "01-10-2017 ",
		 "ACTUAL_RTW_DATE": "01-02-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saenz",
		 "CLAIMANT_FIRST_NAME": "Jessica",
		 "CLAIM_NO": "2015-01-14-0162-VP-01",
		 "DURATION": 53,
		 "DISABILITY_START_DATE": "02-12-2015 ",
		 "DATE_CLAIM_CLOSED": "04-16-2015 ",
		 "ACTUAL_RTW_DATE": "04-06-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saephan",
		 "CLAIMANT_FIRST_NAME": "John",
		 "CLAIM_NO": "2022-07-11-1021-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "07-06-2022 ",
		 "DATE_CLAIM_CLOSED": "07-20-2022 ",
		 "ACTUAL_RTW_DATE": "07-16-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saephan",
		 "CLAIMANT_FIRST_NAME": "Sarn",
		 "CLAIM_NO": "2020-11-20-0392-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "11-19-2020 ",
		 "DATE_CLAIM_CLOSED": "12-28-2020 ",
		 "ACTUAL_RTW_DATE": "12-15-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saephan",
		 "CLAIMANT_FIRST_NAME": "Sarn",
		 "CLAIM_NO": "2019-07-17-0497-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "07-08-2019 ",
		 "DATE_CLAIM_CLOSED": "08-12-2019 ",
		 "ACTUAL_RTW_DATE": "07-16-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saephan",
		 "CLAIMANT_FIRST_NAME": "Sarn",
		 "CLAIM_NO": "2018-02-23-0250-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "02-15-2018 ",
		 "DATE_CLAIM_CLOSED": "04-09-2018 ",
		 "ACTUAL_RTW_DATE": "03-01-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saephan",
		 "CLAIMANT_FIRST_NAME": "John",
		 "CLAIM_NO": "2022-04-19-0989-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "04-14-2022 ",
		 "DATE_CLAIM_CLOSED": "05-17-2022 ",
		 "ACTUAL_RTW_DATE": "05-12-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saephanh",
		 "CLAIMANT_FIRST_NAME": "Kao",
		 "CLAIM_NO": "2017-06-23-0504-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "06-22-2017 ",
		 "DATE_CLAIM_CLOSED": "08-24-2017 ",
		 "ACTUAL_RTW_DATE": "07-18-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saeteurn",
		 "CLAIMANT_FIRST_NAME": "Terrance",
		 "CLAIM_NO": "2016-11-01-0568-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "10-29-2016 ",
		 "DATE_CLAIM_CLOSED": "12-15-2016 ",
		 "ACTUAL_RTW_DATE": "11-14-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saeteurn",
		 "CLAIMANT_FIRST_NAME": "Rai Chou",
		 "CLAIM_NO": "2019-01-14-0843-VP-01",
		 "DURATION": 74,
		 "DISABILITY_START_DATE": "02-14-2019 ",
		 "DATE_CLAIM_CLOSED": "05-13-2019 ",
		 "ACTUAL_RTW_DATE": "04-29-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sage",
		 "CLAIMANT_FIRST_NAME": "Sandy",
		 "CLAIM_NO": "2013-01-05-0267-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "12-26-2012 ",
		 "DATE_CLAIM_CLOSED": "02-26-2013 ",
		 "ACTUAL_RTW_DATE": "02-05-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sahagun",
		 "CLAIMANT_FIRST_NAME": "Brittney",
		 "CLAIM_NO": "2020-02-07-0396-VP-01",
		 "DURATION": 260,
		 "DISABILITY_START_DATE": "01-27-2020 ",
		 "DATE_CLAIM_CLOSED": "10-26-2020 ",
		 "ACTUAL_RTW_DATE": "10-13-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sahoo",
		 "CLAIMANT_FIRST_NAME": "Shulamite",
		 "CLAIM_NO": "2016-08-23-0612-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "09-16-2016 ",
		 "DATE_CLAIM_CLOSED": "11-08-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sahota",
		 "CLAIMANT_FIRST_NAME": "Charanjit",
		 "CLAIM_NO": "2019-04-18-0398-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "04-16-2019 ",
		 "DATE_CLAIM_CLOSED": "06-10-2019 ",
		 "ACTUAL_RTW_DATE": "05-20-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sakai",
		 "CLAIMANT_FIRST_NAME": "Karen",
		 "CLAIM_NO": "2020-10-23-0902-VP-01",
		 "DURATION": 44,
		 "DISABILITY_START_DATE": "11-16-2020 ",
		 "DATE_CLAIM_CLOSED": "01-06-2021 ",
		 "ACTUAL_RTW_DATE": "12-30-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sakiyama",
		 "CLAIMANT_FIRST_NAME": "Lisa",
		 "CLAIM_NO": "2016-08-29-0177-VP-01",
		 "DURATION": 78,
		 "DISABILITY_START_DATE": "09-06-2016 ",
		 "DATE_CLAIM_CLOSED": "12-13-2016 ",
		 "ACTUAL_RTW_DATE": "11-23-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sakiyama",
		 "CLAIMANT_FIRST_NAME": "Lisa",
		 "CLAIM_NO": "2013-10-18-0032-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "11-04-2013 ",
		 "DATE_CLAIM_CLOSED": "03-11-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sakoonphong",
		 "CLAIMANT_FIRST_NAME": "Inpong",
		 "CLAIM_NO": "2013-12-12-0397-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "12-06-2013 ",
		 "DATE_CLAIM_CLOSED": "03-10-2014 ",
		 "ACTUAL_RTW_DATE": "12-20-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Salas",
		 "CLAIMANT_FIRST_NAME": "Antonia",
		 "CLAIM_NO": "2015-01-20-0504-VP-01",
		 "DURATION": 0,
		 "DISABILITY_START_DATE": "12-22-2014 ",
		 "DATE_CLAIM_CLOSED": "02-18-2015 ",
		 "ACTUAL_RTW_DATE": "12-22-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Salas Lozano",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2013-01-31-0381-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "01-28-2013 ",
		 "DATE_CLAIM_CLOSED": "05-07-2013 ",
		 "ACTUAL_RTW_DATE": "02-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Salas Lozano",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2014-06-04-8567-VP-01",
		 "DURATION": 69,
		 "DISABILITY_START_DATE": "05-27-2014 ",
		 "DATE_CLAIM_CLOSED": "12-22-2014 ",
		 "ACTUAL_RTW_DATE": "08-04-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Salazar",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2021-08-30-0561-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "10-04-2021 ",
		 "DATE_CLAIM_CLOSED": "11-19-2021 ",
		 "ACTUAL_RTW_DATE": "11-16-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Salcedo",
		 "CLAIMANT_FIRST_NAME": "Rafael",
		 "CLAIM_NO": "2013-01-05-0023-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "01-24-2013 ",
		 "DATE_CLAIM_CLOSED": "05-13-2013 ",
		 "ACTUAL_RTW_DATE": "02-25-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Salcedo",
		 "CLAIMANT_FIRST_NAME": "Serena",
		 "CLAIM_NO": "2021-10-28-0660-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "11-15-2021 ",
		 "DATE_CLAIM_CLOSED": "01-14-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Salcido",
		 "CLAIMANT_FIRST_NAME": "Angelo",
		 "CLAIM_NO": "2013-02-05-0072-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "02-06-2013 ",
		 "DATE_CLAIM_CLOSED": "05-13-2013 ",
		 "ACTUAL_RTW_DATE": "02-20-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Salcido",
		 "CLAIMANT_FIRST_NAME": "Maribel",
		 "CLAIM_NO": "2013-01-05-1868-VP-01",
		 "DURATION": 217,
		 "DISABILITY_START_DATE": "05-30-2012 ",
		 "DATE_CLAIM_CLOSED": "01-07-2013 ",
		 "ACTUAL_RTW_DATE": "01-02-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Salcido",
		 "CLAIMANT_FIRST_NAME": "Angelo",
		 "CLAIM_NO": "2013-01-11-0297-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "01-16-2013 ",
		 "DATE_CLAIM_CLOSED": "02-09-2013 ",
		 "ACTUAL_RTW_DATE": "01-30-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saldivar-Linaga",
		 "CLAIMANT_FIRST_NAME": "Rufino",
		 "CLAIM_NO": "2019-01-03-0325-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "12-07-2018 ",
		 "DATE_CLAIM_CLOSED": "02-05-2019 ",
		 "ACTUAL_RTW_DATE": "01-30-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saldivar-Linaga",
		 "CLAIMANT_FIRST_NAME": "Rufino",
		 "CLAIM_NO": "2013-03-01-0234-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "02-25-2013 ",
		 "DATE_CLAIM_CLOSED": "04-29-2013 ",
		 "ACTUAL_RTW_DATE": "03-12-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saldivar-Linaga",
		 "CLAIMANT_FIRST_NAME": "Rufino",
		 "CLAIM_NO": "2016-11-08-0339-VP-01",
		 "DURATION": 103,
		 "DISABILITY_START_DATE": "11-03-2016 ",
		 "DATE_CLAIM_CLOSED": "09-14-2017 ",
		 "ACTUAL_RTW_DATE": "02-14-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saldivar-Linaga",
		 "CLAIMANT_FIRST_NAME": "Rufino",
		 "CLAIM_NO": "2019-04-09-0275-VP-01",
		 "DURATION": 105,
		 "DISABILITY_START_DATE": "04-08-2019 ",
		 "DATE_CLAIM_CLOSED": "07-29-2019 ",
		 "ACTUAL_RTW_DATE": "07-22-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saleh",
		 "CLAIMANT_FIRST_NAME": "Fatima",
		 "CLAIM_NO": "2018-11-05-0767-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "11-26-2018 ",
		 "DATE_CLAIM_CLOSED": "01-25-2019 ",
		 "ACTUAL_RTW_DATE": "01-21-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saliba",
		 "CLAIMANT_FIRST_NAME": "Mary-Ann",
		 "CLAIM_NO": "2021-09-14-0393-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "09-13-2021 ",
		 "DATE_CLAIM_CLOSED": "10-06-2021 ",
		 "ACTUAL_RTW_DATE": "10-04-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saliba",
		 "CLAIMANT_FIRST_NAME": "Mary-Ann",
		 "CLAIM_NO": "2020-03-27-0628-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "03-20-2020 ",
		 "DATE_CLAIM_CLOSED": "04-13-2020 ",
		 "ACTUAL_RTW_DATE": "03-30-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saliba",
		 "CLAIMANT_FIRST_NAME": "Mary-Ann",
		 "CLAIM_NO": "2019-01-03-0762-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "01-04-2019 ",
		 "DATE_CLAIM_CLOSED": "03-06-2019 ",
		 "ACTUAL_RTW_DATE": "03-04-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saliba",
		 "CLAIMANT_FIRST_NAME": "Mary-Ann",
		 "CLAIM_NO": "2022-02-15-0649-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "02-14-2022 ",
		 "DATE_CLAIM_CLOSED": "03-17-2022 ",
		 "ACTUAL_RTW_DATE": "03-14-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saliba",
		 "CLAIMANT_FIRST_NAME": "Mary-Ann",
		 "CLAIM_NO": "2016-11-01-0410-VP-01",
		 "DURATION": 145,
		 "DISABILITY_START_DATE": "10-21-2016 ",
		 "DATE_CLAIM_CLOSED": "03-28-2017 ",
		 "ACTUAL_RTW_DATE": "03-15-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Salinas",
		 "CLAIMANT_FIRST_NAME": "Felipa",
		 "CLAIM_NO": "2013-01-05-0188-VP-01",
		 "DURATION": 271,
		 "DISABILITY_START_DATE": "11-29-2012 ",
		 "DATE_CLAIM_CLOSED": "09-15-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Salpetrier",
		 "CLAIMANT_FIRST_NAME": "Zany",
		 "CLAIM_NO": "2022-08-15-0770-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "09-15-2022 ",
		 "DATE_CLAIM_CLOSED": "10-18-2022 ",
		 "ACTUAL_RTW_DATE": "10-17-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Salumbides",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2014-11-05-0335-VP-01",
		 "DURATION": 833,
		 "DISABILITY_START_DATE": "11-04-2014 ",
		 "DATE_CLAIM_CLOSED": "02-21-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Salumbides",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2013-02-08-0114-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "02-04-2013 ",
		 "DATE_CLAIM_CLOSED": "05-07-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sam",
		 "CLAIMANT_FIRST_NAME": "Ponlok",
		 "CLAIM_NO": "2019-10-04-0011-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "09-19-2019 ",
		 "DATE_CLAIM_CLOSED": "11-26-2019 ",
		 "ACTUAL_RTW_DATE": "10-01-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Samaniego",
		 "CLAIMANT_FIRST_NAME": "Concepcion",
		 "CLAIM_NO": "2021-08-30-1095-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "08-30-2021 ",
		 "DATE_CLAIM_CLOSED": "12-06-2021 ",
		 "ACTUAL_RTW_DATE": "11-29-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Samaniego",
		 "CLAIMANT_FIRST_NAME": "Concepcion",
		 "CLAIM_NO": "2022-03-22-0230-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "03-16-2022 ",
		 "DATE_CLAIM_CLOSED": "04-22-2022 ",
		 "ACTUAL_RTW_DATE": "04-04-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Samaniego",
		 "CLAIMANT_FIRST_NAME": "Concepcion",
		 "CLAIM_NO": "2021-10-19-1020-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "10-15-2021 ",
		 "DATE_CLAIM_CLOSED": "10-20-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sampayan",
		 "CLAIMANT_FIRST_NAME": "Josefina",
		 "CLAIM_NO": "2022-10-11-0546-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "10-12-2022 ",
		 "DATE_CLAIM_CLOSED": "11-21-2022 ",
		 "ACTUAL_RTW_DATE": "11-15-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sampayan",
		 "CLAIMANT_FIRST_NAME": "Josefina",
		 "CLAIM_NO": "2019-11-05-0785-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "11-04-2019 ",
		 "DATE_CLAIM_CLOSED": "01-14-2020 ",
		 "ACTUAL_RTW_DATE": "01-06-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sampayan",
		 "CLAIMANT_FIRST_NAME": "Josefina",
		 "CLAIM_NO": "2022-07-27-0531-VP-01",
		 "DURATION": 23,
		 "DISABILITY_START_DATE": "07-27-2022 ",
		 "DATE_CLAIM_CLOSED": "08-29-2022 ",
		 "ACTUAL_RTW_DATE": "08-19-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "San Juan",
		 "CLAIMANT_FIRST_NAME": "Marie",
		 "CLAIM_NO": "2013-01-10-0385-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "01-11-2013 ",
		 "DATE_CLAIM_CLOSED": "05-31-2013 ",
		 "ACTUAL_RTW_DATE": "02-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "San Juan",
		 "CLAIMANT_FIRST_NAME": "Marie",
		 "CLAIM_NO": "2014-02-11-0523-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "02-27-2014 ",
		 "DATE_CLAIM_CLOSED": "03-07-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sanborn",
		 "CLAIMANT_FIRST_NAME": "Kathryn",
		 "CLAIM_NO": "2017-02-16-0481-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "02-07-2017 ",
		 "DATE_CLAIM_CLOSED": "03-06-2017 ",
		 "ACTUAL_RTW_DATE": "02-21-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sanborn",
		 "CLAIMANT_FIRST_NAME": "Felicia",
		 "CLAIM_NO": "2020-08-31-0404-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "10-01-2020 ",
		 "DATE_CLAIM_CLOSED": "11-18-2020 ",
		 "ACTUAL_RTW_DATE": "11-16-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sanchez",
		 "CLAIMANT_FIRST_NAME": "Magdalena",
		 "CLAIM_NO": "2014-06-09-0266-VP-01",
		 "DURATION": 100,
		 "DISABILITY_START_DATE": "06-16-2014 ",
		 "DATE_CLAIM_CLOSED": "09-29-2014 ",
		 "ACTUAL_RTW_DATE": "09-24-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sanchez",
		 "CLAIMANT_FIRST_NAME": "Magdalena",
		 "CLAIM_NO": "2015-08-25-0504-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "08-24-2015 ",
		 "DATE_CLAIM_CLOSED": "10-12-2015 ",
		 "ACTUAL_RTW_DATE": "09-01-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sanchez",
		 "CLAIMANT_FIRST_NAME": "Angelina",
		 "CLAIM_NO": "2018-04-25-0665-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "04-25-2018 ",
		 "DATE_CLAIM_CLOSED": "05-31-2018 ",
		 "ACTUAL_RTW_DATE": "05-28-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sanchez",
		 "CLAIMANT_FIRST_NAME": "Thea",
		 "CLAIM_NO": "2021-04-15-0008-VP-01",
		 "DURATION": 197,
		 "DISABILITY_START_DATE": "05-03-2021 ",
		 "DATE_CLAIM_CLOSED": "11-08-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sanchez",
		 "CLAIMANT_FIRST_NAME": "Myra",
		 "CLAIM_NO": "2022-06-23-0692-VP-01",
		 "DURATION": 119,
		 "DISABILITY_START_DATE": "05-25-2022 ",
		 "DATE_CLAIM_CLOSED": "09-30-2022 ",
		 "ACTUAL_RTW_DATE": "09-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sanchez",
		 "CLAIMANT_FIRST_NAME": "Magdalena",
		 "CLAIM_NO": "2014-11-04-0285-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "10-31-2014 ",
		 "DATE_CLAIM_CLOSED": "01-05-2015 ",
		 "ACTUAL_RTW_DATE": "11-10-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sanchez",
		 "CLAIMANT_FIRST_NAME": "Raquel",
		 "CLAIM_NO": "2013-01-05-0705-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "11-05-2012 ",
		 "DATE_CLAIM_CLOSED": "01-28-2013 ",
		 "ACTUAL_RTW_DATE": "01-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sandhu",
		 "CLAIMANT_FIRST_NAME": "Gary",
		 "CLAIM_NO": "2017-09-13-0628-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "09-06-2017 ",
		 "DATE_CLAIM_CLOSED": "09-21-2017 ",
		 "ACTUAL_RTW_DATE": "09-18-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sandiford",
		 "CLAIMANT_FIRST_NAME": "Anthony",
		 "CLAIM_NO": "2013-03-13-0239-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "03-11-2013 ",
		 "DATE_CLAIM_CLOSED": "04-13-2013 ",
		 "ACTUAL_RTW_DATE": "03-25-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sandoval",
		 "CLAIMANT_FIRST_NAME": "Ruth",
		 "CLAIM_NO": "2019-04-16-0751-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "05-08-2019 ",
		 "DATE_CLAIM_CLOSED": "06-11-2019 ",
		 "ACTUAL_RTW_DATE": "06-10-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sandoval",
		 "CLAIMANT_FIRST_NAME": "Mayra",
		 "CLAIM_NO": "2014-09-18-0091-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "09-17-2014 ",
		 "DATE_CLAIM_CLOSED": "12-22-2014 ",
		 "ACTUAL_RTW_DATE": "10-20-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sandoval",
		 "CLAIMANT_FIRST_NAME": "Mayra",
		 "CLAIM_NO": "2015-07-06-0491-VP-01",
		 "DURATION": 76,
		 "DISABILITY_START_DATE": "07-20-2015 ",
		 "DATE_CLAIM_CLOSED": "10-12-2015 ",
		 "ACTUAL_RTW_DATE": "10-04-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sandoval",
		 "CLAIMANT_FIRST_NAME": "Manuel",
		 "CLAIM_NO": "2014-06-18-0282-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "06-04-2014 ",
		 "DATE_CLAIM_CLOSED": "12-08-2014 ",
		 "ACTUAL_RTW_DATE": "06-25-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sanfilippo",
		 "CLAIMANT_FIRST_NAME": "Charles",
		 "CLAIM_NO": "2013-01-28-0237-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "02-15-2013 ",
		 "DATE_CLAIM_CLOSED": "04-26-2013 ",
		 "ACTUAL_RTW_DATE": "03-18-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sanfilippo",
		 "CLAIMANT_FIRST_NAME": "Charles",
		 "CLAIM_NO": "2020-03-10-0623-VP-01",
		 "DURATION": 92,
		 "DISABILITY_START_DATE": "05-05-2020 ",
		 "DATE_CLAIM_CLOSED": "04-29-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sanfilippo",
		 "CLAIMANT_FIRST_NAME": "Charles",
		 "CLAIM_NO": "2020-06-09-0563-VP-01",
		 "DURATION": 53,
		 "DISABILITY_START_DATE": "06-25-2020 ",
		 "DATE_CLAIM_CLOSED": "08-19-2020 ",
		 "ACTUAL_RTW_DATE": "08-17-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sanpedro",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2016-05-06-0456-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "03-21-2016 ",
		 "DATE_CLAIM_CLOSED": "06-09-2016 ",
		 "ACTUAL_RTW_DATE": "03-28-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Santamaria",
		 "CLAIMANT_FIRST_NAME": "Omar",
		 "CLAIM_NO": "2019-11-04-0151-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "12-09-2019 ",
		 "DATE_CLAIM_CLOSED": "11-19-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Santana",
		 "CLAIMANT_FIRST_NAME": "Fabiola",
		 "CLAIM_NO": "2013-01-23-0574-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "01-15-2013 ",
		 "DATE_CLAIM_CLOSED": "05-13-2013 ",
		 "ACTUAL_RTW_DATE": "03-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Santiago",
		 "CLAIMANT_FIRST_NAME": "Ivan",
		 "CLAIM_NO": "2020-09-17-1044-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "09-16-2020 ",
		 "DATE_CLAIM_CLOSED": "10-28-2020 ",
		 "ACTUAL_RTW_DATE": "10-26-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Santiago",
		 "CLAIMANT_FIRST_NAME": "Victor",
		 "CLAIM_NO": "2017-05-31-0570-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "05-30-2017 ",
		 "DATE_CLAIM_CLOSED": "06-19-2017 ",
		 "ACTUAL_RTW_DATE": "06-12-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Santiago",
		 "CLAIMANT_FIRST_NAME": "Irma",
		 "CLAIM_NO": "2018-10-16-0713-VP-01",
		 "DURATION": 357,
		 "DISABILITY_START_DATE": "11-09-2018 ",
		 "DATE_CLAIM_CLOSED": "12-04-2019 ",
		 "ACTUAL_RTW_DATE": "11-01-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Santiago",
		 "CLAIMANT_FIRST_NAME": "Ivan",
		 "CLAIM_NO": "2021-03-08-0626-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "02-17-2021 ",
		 "DATE_CLAIM_CLOSED": "04-30-2021 ",
		 "ACTUAL_RTW_DATE": "04-26-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Santiago Jr",
		 "CLAIMANT_FIRST_NAME": "Nicolas",
		 "CLAIM_NO": "2013-03-01-0239-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "02-26-2013 ",
		 "DATE_CLAIM_CLOSED": "07-06-2013 ",
		 "ACTUAL_RTW_DATE": "05-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Santiesteban",
		 "CLAIMANT_FIRST_NAME": "Alex",
		 "CLAIM_NO": "2019-03-08-0197-VP-01",
		 "DURATION": 330,
		 "DISABILITY_START_DATE": "03-08-2019 ",
		 "DATE_CLAIM_CLOSED": "02-04-2020 ",
		 "ACTUAL_RTW_DATE": "02-01-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Santos",
		 "CLAIMANT_FIRST_NAME": "Joanna",
		 "CLAIM_NO": "2019-03-27-0001-VP-01",
		 "DURATION": 76,
		 "DISABILITY_START_DATE": "03-26-2019 ",
		 "DATE_CLAIM_CLOSED": "06-14-2019 ",
		 "ACTUAL_RTW_DATE": "06-10-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Santos",
		 "CLAIMANT_FIRST_NAME": "Adrian",
		 "CLAIM_NO": "2013-04-12-0219-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "04-17-2013 ",
		 "DATE_CLAIM_CLOSED": "09-03-2013 ",
		 "ACTUAL_RTW_DATE": "06-27-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Santos",
		 "CLAIMANT_FIRST_NAME": "Marina",
		 "CLAIM_NO": "2013-01-05-0424-VP-01",
		 "DURATION": 216,
		 "DISABILITY_START_DATE": "10-30-2012 ",
		 "DATE_CLAIM_CLOSED": "06-10-2013 ",
		 "ACTUAL_RTW_DATE": "06-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sapida",
		 "CLAIMANT_FIRST_NAME": "Robert",
		 "CLAIM_NO": "2021-11-30-1141-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "11-29-2021 ",
		 "DATE_CLAIM_CLOSED": "02-28-2022 ",
		 "ACTUAL_RTW_DATE": "02-14-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sarino",
		 "CLAIMANT_FIRST_NAME": "Dina",
		 "CLAIM_NO": "2019-07-15-0564-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "08-12-2019 ",
		 "DATE_CLAIM_CLOSED": "10-02-2019 ",
		 "ACTUAL_RTW_DATE": "09-23-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sarkar",
		 "CLAIMANT_FIRST_NAME": "Juni",
		 "CLAIM_NO": "2018-09-18-0425-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "10-17-2018 ",
		 "DATE_CLAIM_CLOSED": "12-14-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sartippour",
		 "CLAIMANT_FIRST_NAME": "Maryam",
		 "CLAIM_NO": "2021-08-20-0685-VP-01",
		 "DURATION": 61,
		 "DISABILITY_START_DATE": "08-20-2021 ",
		 "DATE_CLAIM_CLOSED": "08-24-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sasaridis",
		 "CLAIMANT_FIRST_NAME": "Christine",
		 "CLAIM_NO": "2020-05-19-0317-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "06-22-2020 ",
		 "DATE_CLAIM_CLOSED": "08-17-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saso",
		 "CLAIMANT_FIRST_NAME": "Stephanie",
		 "CLAIM_NO": "2014-09-17-0210-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "09-15-2014 ",
		 "DATE_CLAIM_CLOSED": "12-08-2014 ",
		 "ACTUAL_RTW_DATE": "12-08-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saso",
		 "CLAIMANT_FIRST_NAME": "Michael",
		 "CLAIM_NO": "2015-09-16-0234-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "09-14-2015 ",
		 "DATE_CLAIM_CLOSED": "11-18-2015 ",
		 "ACTUAL_RTW_DATE": "11-16-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saso",
		 "CLAIMANT_FIRST_NAME": "Stephanie",
		 "CLAIM_NO": "2015-03-02-0598-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "02-23-2015 ",
		 "DATE_CLAIM_CLOSED": "03-10-2015 ",
		 "ACTUAL_RTW_DATE": "03-09-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Satish",
		 "CLAIMANT_FIRST_NAME": "Swati",
		 "CLAIM_NO": "2021-07-27-0770-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "08-02-2021 ",
		 "DATE_CLAIM_CLOSED": "09-29-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Satishchandra",
		 "CLAIMANT_FIRST_NAME": "Shilpa",
		 "CLAIM_NO": "2016-02-19-0500-VP-01",
		 "DURATION": 67,
		 "DISABILITY_START_DATE": "03-21-2016 ",
		 "DATE_CLAIM_CLOSED": "06-03-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saunders",
		 "CLAIMANT_FIRST_NAME": "Melissa",
		 "CLAIM_NO": "2022-05-27-0392-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "05-31-2022 ",
		 "DATE_CLAIM_CLOSED": "07-14-2022 ",
		 "ACTUAL_RTW_DATE": "06-28-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Saure",
		 "CLAIMANT_FIRST_NAME": "Gabriele",
		 "CLAIM_NO": "2020-12-18-0846-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "12-17-2020 ",
		 "DATE_CLAIM_CLOSED": "02-03-2021 ",
		 "ACTUAL_RTW_DATE": "02-01-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Savan",
		 "CLAIMANT_FIRST_NAME": "Andreea",
		 "CLAIM_NO": "2021-03-03-0274-VP-01",
		 "DURATION": 61,
		 "DISABILITY_START_DATE": "03-08-2021 ",
		 "DATE_CLAIM_CLOSED": "05-10-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sayamnath",
		 "CLAIMANT_FIRST_NAME": "Chanthavieng",
		 "CLAIM_NO": "2013-03-15-0360-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "03-12-2013 ",
		 "DATE_CLAIM_CLOSED": "06-10-2013 ",
		 "ACTUAL_RTW_DATE": "04-10-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sayamnath",
		 "CLAIMANT_FIRST_NAME": "Chanthavieng",
		 "CLAIM_NO": "2016-01-08-0235-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "01-08-2016 ",
		 "DATE_CLAIM_CLOSED": "01-10-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sayavong",
		 "CLAIMANT_FIRST_NAME": "Gayle",
		 "CLAIM_NO": "2022-10-14-0447-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "11-17-2022 ",
		 "DATE_CLAIM_CLOSED": "01-27-2023 ",
		 "ACTUAL_RTW_DATE": "01-24-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Scalzo",
		 "CLAIMANT_FIRST_NAME": "Cristina",
		 "CLAIM_NO": "2013-12-31-0357-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "02-03-2014 ",
		 "DATE_CLAIM_CLOSED": "06-02-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Scheer",
		 "CLAIMANT_FIRST_NAME": "Daniel",
		 "CLAIM_NO": "2015-11-10-0172-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "11-09-2015 ",
		 "DATE_CLAIM_CLOSED": "01-04-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Scheraldi",
		 "CLAIMANT_FIRST_NAME": "Teresa",
		 "CLAIM_NO": "2019-11-30-0039-VP-01",
		 "DURATION": 111,
		 "DISABILITY_START_DATE": "11-26-2019 ",
		 "DATE_CLAIM_CLOSED": "03-17-2020 ",
		 "ACTUAL_RTW_DATE": "03-16-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Scheraldi",
		 "CLAIMANT_FIRST_NAME": "Teresa",
		 "CLAIM_NO": "2017-05-31-0644-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "06-07-2017 ",
		 "DATE_CLAIM_CLOSED": "06-30-2017 ",
		 "ACTUAL_RTW_DATE": "06-14-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Scheraldi",
		 "CLAIMANT_FIRST_NAME": "Teresa",
		 "CLAIM_NO": "2019-04-08-0376-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "04-17-2019 ",
		 "DATE_CLAIM_CLOSED": "05-20-2019 ",
		 "ACTUAL_RTW_DATE": "05-16-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Schildkraut",
		 "CLAIMANT_FIRST_NAME": "Shannon",
		 "CLAIM_NO": "2014-04-22-0244-VP-01",
		 "DURATION": 115,
		 "DISABILITY_START_DATE": "05-02-2014 ",
		 "DATE_CLAIM_CLOSED": "09-08-2014 ",
		 "ACTUAL_RTW_DATE": "08-25-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Schloessmann",
		 "CLAIMANT_FIRST_NAME": "Thomas",
		 "CLAIM_NO": "2013-01-05-2053-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "05-10-2012 ",
		 "DATE_CLAIM_CLOSED": "05-21-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Schmeets",
		 "CLAIMANT_FIRST_NAME": "Richard",
		 "CLAIM_NO": "2019-05-28-0610-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "05-27-2019 ",
		 "DATE_CLAIM_CLOSED": "06-27-2019 ",
		 "ACTUAL_RTW_DATE": "06-24-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Schmidt",
		 "CLAIMANT_FIRST_NAME": "Robert",
		 "CLAIM_NO": "2018-08-01-0545-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "08-06-2018 ",
		 "DATE_CLAIM_CLOSED": "09-11-2018 ",
		 "ACTUAL_RTW_DATE": "09-04-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Schock",
		 "CLAIMANT_FIRST_NAME": "Steven",
		 "CLAIM_NO": "2016-11-17-0189-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "11-15-2016 ",
		 "DATE_CLAIM_CLOSED": "12-27-2016 ",
		 "ACTUAL_RTW_DATE": "12-20-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Schock",
		 "CLAIMANT_FIRST_NAME": "Steven",
		 "CLAIM_NO": "2017-01-24-0303-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "01-23-2017 ",
		 "DATE_CLAIM_CLOSED": "04-05-2017 ",
		 "ACTUAL_RTW_DATE": "04-03-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Schock",
		 "CLAIMANT_FIRST_NAME": "Steven",
		 "CLAIM_NO": "2015-12-09-0389-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "11-30-2015 ",
		 "DATE_CLAIM_CLOSED": "02-02-2016 ",
		 "ACTUAL_RTW_DATE": "12-22-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Schoeller",
		 "CLAIMANT_FIRST_NAME": "Krista",
		 "CLAIM_NO": "2021-02-12-0404-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "02-23-2021 ",
		 "DATE_CLAIM_CLOSED": "03-25-2021 ",
		 "ACTUAL_RTW_DATE": "03-23-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Schoeller",
		 "CLAIMANT_FIRST_NAME": "Krista",
		 "CLAIM_NO": "2013-10-16-0320-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "10-29-2013 ",
		 "DATE_CLAIM_CLOSED": "01-24-2014 ",
		 "ACTUAL_RTW_DATE": "12-27-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Schoeller",
		 "CLAIMANT_FIRST_NAME": "Krista",
		 "CLAIM_NO": "2017-03-02-0406-VP-01",
		 "DURATION": 75,
		 "DISABILITY_START_DATE": "04-03-2017 ",
		 "DATE_CLAIM_CLOSED": "06-20-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Schoensiegel",
		 "CLAIMANT_FIRST_NAME": "Caitlin",
		 "CLAIM_NO": "2018-09-07-0264-VP-01",
		 "DURATION": 227,
		 "DISABILITY_START_DATE": "09-07-2018 ",
		 "DATE_CLAIM_CLOSED": "04-23-2019 ",
		 "ACTUAL_RTW_DATE": "04-22-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Schraner",
		 "CLAIMANT_FIRST_NAME": "Marc",
		 "CLAIM_NO": "2016-07-11-0116-VP-01",
		 "DURATION": 105,
		 "DISABILITY_START_DATE": "08-15-2016 ",
		 "DATE_CLAIM_CLOSED": "12-02-2016 ",
		 "ACTUAL_RTW_DATE": "11-28-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Schraner",
		 "CLAIMANT_FIRST_NAME": "Marc",
		 "CLAIM_NO": "2019-05-02-0478-VP-01",
		 "DURATION": 75,
		 "DISABILITY_START_DATE": "06-26-2019 ",
		 "DATE_CLAIM_CLOSED": "10-09-2019 ",
		 "ACTUAL_RTW_DATE": "09-09-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Schrock",
		 "CLAIMANT_FIRST_NAME": "Jon",
		 "CLAIM_NO": "2019-09-04-0727-VP-01",
		 "DURATION": 30,
		 "DISABILITY_START_DATE": "09-09-2019 ",
		 "DATE_CLAIM_CLOSED": "10-12-2019 ",
		 "ACTUAL_RTW_DATE": "10-09-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Schrock",
		 "CLAIMANT_FIRST_NAME": "Jon",
		 "CLAIM_NO": "2020-09-25-0591-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "10-08-2020 ",
		 "DATE_CLAIM_CLOSED": "12-10-2020 ",
		 "ACTUAL_RTW_DATE": "12-09-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Schumacher",
		 "CLAIMANT_FIRST_NAME": "Rebecca",
		 "CLAIM_NO": "2016-06-17-0267-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "06-17-2016 ",
		 "DATE_CLAIM_CLOSED": "08-30-2016 ",
		 "ACTUAL_RTW_DATE": "08-15-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Schumacher",
		 "CLAIMANT_FIRST_NAME": "Rebecca",
		 "CLAIM_NO": "2014-07-21-0208-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "07-25-2014 ",
		 "DATE_CLAIM_CLOSED": "10-06-2014 ",
		 "ACTUAL_RTW_DATE": "08-25-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Schwartz-Lopez",
		 "CLAIMANT_FIRST_NAME": "Victoria",
		 "CLAIM_NO": "2022-05-05-0703-VP-01",
		 "DURATION": 75,
		 "DISABILITY_START_DATE": "06-01-2022 ",
		 "DATE_CLAIM_CLOSED": "08-17-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Schwem",
		 "CLAIMANT_FIRST_NAME": "Glenda",
		 "CLAIM_NO": "2013-08-08-0461-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "08-08-2013 ",
		 "DATE_CLAIM_CLOSED": "09-23-2013 ",
		 "ACTUAL_RTW_DATE": "09-10-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Schwem",
		 "CLAIMANT_FIRST_NAME": "Glenda",
		 "CLAIM_NO": "2016-04-20-0489-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "04-07-2016 ",
		 "DATE_CLAIM_CLOSED": "06-03-2016 ",
		 "ACTUAL_RTW_DATE": "04-20-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Scoby",
		 "CLAIMANT_FIRST_NAME": "Krista",
		 "CLAIM_NO": "2021-10-21-0570-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "10-20-2021 ",
		 "DATE_CLAIM_CLOSED": "11-30-2021 ",
		 "ACTUAL_RTW_DATE": "11-24-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Scotson",
		 "CLAIMANT_FIRST_NAME": "Jonathan",
		 "CLAIM_NO": "2022-01-18-1391-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "01-25-2022 ",
		 "DATE_CLAIM_CLOSED": "02-28-2022 ",
		 "ACTUAL_RTW_DATE": "02-23-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Scott",
		 "CLAIMANT_FIRST_NAME": "Julian",
		 "CLAIM_NO": "2016-12-12-0213-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "12-14-2016 ",
		 "DATE_CLAIM_CLOSED": "02-01-2017 ",
		 "ACTUAL_RTW_DATE": "01-30-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Scott",
		 "CLAIMANT_FIRST_NAME": "Paula",
		 "CLAIM_NO": "2013-01-09-0261-VP-01",
		 "DURATION": 205,
		 "DISABILITY_START_DATE": "01-08-2013 ",
		 "DATE_CLAIM_CLOSED": "08-12-2013 ",
		 "ACTUAL_RTW_DATE": "08-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Scott",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2014-07-09-0009-VP-01",
		 "DURATION": 186,
		 "DISABILITY_START_DATE": "06-30-2014 ",
		 "DATE_CLAIM_CLOSED": "01-05-2015 ",
		 "ACTUAL_RTW_DATE": "01-02-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Scott",
		 "CLAIMANT_FIRST_NAME": "Julian",
		 "CLAIM_NO": "2017-03-26-0024-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "03-27-2017 ",
		 "DATE_CLAIM_CLOSED": "05-04-2017 ",
		 "ACTUAL_RTW_DATE": "04-25-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Scott",
		 "CLAIMANT_FIRST_NAME": "Amanda",
		 "CLAIM_NO": "2014-02-10-0232-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "01-31-2014 ",
		 "DATE_CLAIM_CLOSED": "10-30-2015 ",
		 "ACTUAL_RTW_DATE": "03-07-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Scullin",
		 "CLAIMANT_FIRST_NAME": "Vincent",
		 "CLAIM_NO": "2015-09-24-0288-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "10-12-2015 ",
		 "DATE_CLAIM_CLOSED": "01-15-2016 ",
		 "ACTUAL_RTW_DATE": "11-30-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Seago",
		 "CLAIMANT_FIRST_NAME": "Stephanie",
		 "CLAIM_NO": "2021-12-02-1087-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "12-20-2021 ",
		 "DATE_CLAIM_CLOSED": "02-10-2022 ",
		 "ACTUAL_RTW_DATE": "02-07-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sear",
		 "CLAIMANT_FIRST_NAME": "Albert",
		 "CLAIM_NO": "2021-05-05-0884-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "05-03-2021 ",
		 "DATE_CLAIM_CLOSED": "07-12-2021 ",
		 "ACTUAL_RTW_DATE": "07-05-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "See",
		 "CLAIMANT_FIRST_NAME": "Liliana",
		 "CLAIM_NO": "2013-02-01-0088-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "01-31-2013 ",
		 "DATE_CLAIM_CLOSED": "08-12-2013 ",
		 "ACTUAL_RTW_DATE": "02-11-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Segerstrom",
		 "CLAIMANT_FIRST_NAME": "Martha",
		 "CLAIM_NO": "2020-12-02-1061-VP-01",
		 "DURATION": 112,
		 "DISABILITY_START_DATE": "11-09-2020 ",
		 "DATE_CLAIM_CLOSED": "03-04-2021 ",
		 "ACTUAL_RTW_DATE": "03-01-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Segui",
		 "CLAIMANT_FIRST_NAME": "Esther",
		 "CLAIM_NO": "2022-09-12-0742-VP-01",
		 "DURATION": 39,
		 "DISABILITY_START_DATE": "09-15-2022 ",
		 "DATE_CLAIM_CLOSED": "01-04-2023 ",
		 "ACTUAL_RTW_DATE": "10-24-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Segura",
		 "CLAIMANT_FIRST_NAME": "Monica",
		 "CLAIM_NO": "2022-04-15-0115-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "04-26-2022 ",
		 "DATE_CLAIM_CLOSED": "06-07-2022 ",
		 "ACTUAL_RTW_DATE": "06-06-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Segura",
		 "CLAIMANT_FIRST_NAME": "Monica",
		 "CLAIM_NO": "2021-01-29-0562-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "02-09-2021 ",
		 "DATE_CLAIM_CLOSED": "04-08-2021 ",
		 "ACTUAL_RTW_DATE": "04-05-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Segura",
		 "CLAIMANT_FIRST_NAME": "Monica",
		 "CLAIM_NO": "2019-12-09-0536-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "12-05-2019 ",
		 "DATE_CLAIM_CLOSED": "01-03-2020 ",
		 "ACTUAL_RTW_DATE": "12-23-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sekhon",
		 "CLAIMANT_FIRST_NAME": "Baljeet",
		 "CLAIM_NO": "2023-01-19-0132-VP-01",
		 "DURATION": 383,
		 "DISABILITY_START_DATE": "02-11-2022 ",
		 "DATE_CLAIM_CLOSED": "02-17-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sekhon",
		 "CLAIMANT_FIRST_NAME": "Baljeet",
		 "CLAIM_NO": "2018-10-22-0672-VP-01",
		 "DURATION": 96,
		 "DISABILITY_START_DATE": "11-21-2018 ",
		 "DATE_CLAIM_CLOSED": "03-04-2019 ",
		 "ACTUAL_RTW_DATE": "02-25-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sekhon",
		 "CLAIMANT_FIRST_NAME": "Baljeet",
		 "CLAIM_NO": "2021-08-23-1102-VP-01",
		 "DURATION": 140,
		 "DISABILITY_START_DATE": "08-30-2021 ",
		 "DATE_CLAIM_CLOSED": "01-18-2022 ",
		 "ACTUAL_RTW_DATE": "01-17-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sekhon",
		 "CLAIMANT_FIRST_NAME": "Baljeet",
		 "CLAIM_NO": "2021-01-14-0528-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "01-11-2021 ",
		 "DATE_CLAIM_CLOSED": "04-06-2021 ",
		 "ACTUAL_RTW_DATE": "01-25-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sellers",
		 "CLAIMANT_FIRST_NAME": "Nicholas",
		 "CLAIM_NO": "2021-03-26-0160-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "02-24-2021 ",
		 "DATE_CLAIM_CLOSED": "04-19-2021 ",
		 "ACTUAL_RTW_DATE": "04-07-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sen",
		 "CLAIMANT_FIRST_NAME": "Suraj",
		 "CLAIM_NO": "2021-10-26-0839-VP-01",
		 "DURATION": 392,
		 "DISABILITY_START_DATE": "10-25-2021 ",
		 "DATE_CLAIM_CLOSED": "12-02-2022 ",
		 "ACTUAL_RTW_DATE": "11-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Seng",
		 "CLAIMANT_FIRST_NAME": "Ly",
		 "CLAIM_NO": "2013-01-05-2149-VP-01",
		 "DURATION": 305,
		 "DISABILITY_START_DATE": "04-29-2012 ",
		 "DATE_CLAIM_CLOSED": "05-14-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sengdala",
		 "CLAIMANT_FIRST_NAME": "Genevieve",
		 "CLAIM_NO": "2020-08-06-0662-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "07-31-2020 ",
		 "DATE_CLAIM_CLOSED": "08-11-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Senini",
		 "CLAIMANT_FIRST_NAME": "Robert",
		 "CLAIM_NO": "2019-11-08-0726-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "11-21-2019 ",
		 "DATE_CLAIM_CLOSED": "12-06-2019 ",
		 "ACTUAL_RTW_DATE": "12-02-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Senini",
		 "CLAIMANT_FIRST_NAME": "Robert",
		 "CLAIM_NO": "2014-01-15-0215-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "01-21-2014 ",
		 "DATE_CLAIM_CLOSED": "05-31-2014 ",
		 "ACTUAL_RTW_DATE": "03-05-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Servin",
		 "CLAIMANT_FIRST_NAME": "Elizabeth",
		 "CLAIM_NO": "2021-06-07-0243-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "06-08-2021 ",
		 "DATE_CLAIM_CLOSED": "08-04-2021 ",
		 "ACTUAL_RTW_DATE": "08-02-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Seto",
		 "CLAIMANT_FIRST_NAME": "Christine",
		 "CLAIM_NO": "2013-06-04-0690-VP-01",
		 "DURATION": 79,
		 "DISABILITY_START_DATE": "07-02-2013 ",
		 "DATE_CLAIM_CLOSED": "09-23-2013 ",
		 "ACTUAL_RTW_DATE": "09-19-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Seyfi Noferest",
		 "CLAIMANT_FIRST_NAME": "Behnaz",
		 "CLAIM_NO": "2022-12-06-0696-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "01-03-2023 ",
		 "DATE_CLAIM_CLOSED": "03-12-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shackleford",
		 "CLAIMANT_FIRST_NAME": "Diane",
		 "CLAIM_NO": "2015-04-28-0245-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "04-27-2015 ",
		 "DATE_CLAIM_CLOSED": "05-20-2015 ",
		 "ACTUAL_RTW_DATE": "05-04-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shackleton",
		 "CLAIMANT_FIRST_NAME": "Paul",
		 "CLAIM_NO": "2014-09-30-0494-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "10-06-2014 ",
		 "DATE_CLAIM_CLOSED": "12-08-2014 ",
		 "ACTUAL_RTW_DATE": "11-03-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shackleton",
		 "CLAIMANT_FIRST_NAME": "Paul",
		 "CLAIM_NO": "2015-06-12-0721-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "06-12-2015 ",
		 "DATE_CLAIM_CLOSED": "07-20-2015 ",
		 "ACTUAL_RTW_DATE": "07-13-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shafie",
		 "CLAIMANT_FIRST_NAME": "Morvarid",
		 "CLAIM_NO": "2015-07-09-0483-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "08-03-2015 ",
		 "DATE_CLAIM_CLOSED": "10-12-2015 ",
		 "ACTUAL_RTW_DATE": "10-10-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shagmirian",
		 "CLAIMANT_FIRST_NAME": "Yvette",
		 "CLAIM_NO": "2018-10-17-0605-VP-01",
		 "DURATION": 140,
		 "DISABILITY_START_DATE": "11-12-2018 ",
		 "DATE_CLAIM_CLOSED": "04-01-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shah",
		 "CLAIMANT_FIRST_NAME": "Priya",
		 "CLAIM_NO": "2013-01-05-0594-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "10-16-2012 ",
		 "DATE_CLAIM_CLOSED": "01-22-2013 ",
		 "ACTUAL_RTW_DATE": "12-26-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shah",
		 "CLAIMANT_FIRST_NAME": "Rutu",
		 "CLAIM_NO": "2022-07-01-0777-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "08-01-2022 ",
		 "DATE_CLAIM_CLOSED": "09-14-2022 ",
		 "ACTUAL_RTW_DATE": "09-12-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shah",
		 "CLAIMANT_FIRST_NAME": "Rachi",
		 "CLAIM_NO": "2022-01-24-0782-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "03-18-2022 ",
		 "DATE_CLAIM_CLOSED": "05-03-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shah",
		 "CLAIMANT_FIRST_NAME": "Priya",
		 "CLAIM_NO": "2014-09-08-0283-VP-01",
		 "DURATION": 119,
		 "DISABILITY_START_DATE": "09-09-2014 ",
		 "DATE_CLAIM_CLOSED": "01-16-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shah",
		 "CLAIMANT_FIRST_NAME": "Priya",
		 "CLAIM_NO": "2017-04-25-0331-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "04-19-2017 ",
		 "DATE_CLAIM_CLOSED": "04-18-2018 ",
		 "ACTUAL_RTW_DATE": "05-01-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shah",
		 "CLAIMANT_FIRST_NAME": "Puja",
		 "CLAIM_NO": "2016-09-13-0260-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "09-29-2016 ",
		 "DATE_CLAIM_CLOSED": "12-07-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shah",
		 "CLAIMANT_FIRST_NAME": "Ayushi",
		 "CLAIM_NO": "2022-01-17-0559-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "03-07-2022 ",
		 "DATE_CLAIM_CLOSED": "05-26-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shaikh",
		 "CLAIMANT_FIRST_NAME": "Jamil",
		 "CLAIM_NO": "2022-05-26-0031-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "06-20-2022 ",
		 "DATE_CLAIM_CLOSED": "07-19-2022 ",
		 "ACTUAL_RTW_DATE": "07-18-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shakhmalian",
		 "CLAIMANT_FIRST_NAME": "Emma",
		 "CLAIM_NO": "2018-05-07-0265-VP-01",
		 "DURATION": 349,
		 "DISABILITY_START_DATE": "04-19-2018 ",
		 "DATE_CLAIM_CLOSED": "04-29-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shanafelter",
		 "CLAIMANT_FIRST_NAME": "Ronald",
		 "CLAIM_NO": "2015-03-17-0195-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "03-09-2015 ",
		 "DATE_CLAIM_CLOSED": "04-17-2015 ",
		 "ACTUAL_RTW_DATE": "04-06-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shand",
		 "CLAIMANT_FIRST_NAME": "Sharon",
		 "CLAIM_NO": "2015-05-01-0416-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "05-04-2015 ",
		 "DATE_CLAIM_CLOSED": "07-13-2015 ",
		 "ACTUAL_RTW_DATE": "06-22-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shang",
		 "CLAIMANT_FIRST_NAME": "Meiling",
		 "CLAIM_NO": "2018-11-09-0316-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "11-12-2018 ",
		 "DATE_CLAIM_CLOSED": "11-22-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shang",
		 "CLAIMANT_FIRST_NAME": "Meiling",
		 "CLAIM_NO": "2013-03-01-0201-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "02-25-2013 ",
		 "DATE_CLAIM_CLOSED": "04-13-2013 ",
		 "ACTUAL_RTW_DATE": "04-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shang",
		 "CLAIMANT_FIRST_NAME": "Mei",
		 "CLAIM_NO": "2019-09-06-0620-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "10-21-2019 ",
		 "DATE_CLAIM_CLOSED": "12-16-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sharifan",
		 "CLAIMANT_FIRST_NAME": "Marti",
		 "CLAIM_NO": "2013-04-18-0337-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "05-03-2013 ",
		 "DATE_CLAIM_CLOSED": "07-06-2013 ",
		 "ACTUAL_RTW_DATE": "06-13-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sharma",
		 "CLAIMANT_FIRST_NAME": "Shalini",
		 "CLAIM_NO": "2013-02-25-0058-VP-01",
		 "DURATION": 138,
		 "DISABILITY_START_DATE": "02-14-2013 ",
		 "DATE_CLAIM_CLOSED": "08-28-2013 ",
		 "ACTUAL_RTW_DATE": "07-02-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sharma",
		 "CLAIMANT_FIRST_NAME": "Shalini",
		 "CLAIM_NO": "2013-08-23-0036-VP-01",
		 "DURATION": 442,
		 "DISABILITY_START_DATE": "08-16-2013 ",
		 "DATE_CLAIM_CLOSED": "11-03-2014 ",
		 "ACTUAL_RTW_DATE": "11-01-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sharma",
		 "CLAIMANT_FIRST_NAME": "Anginita",
		 "CLAIM_NO": "2014-01-31-0386-VP-01",
		 "DURATION": 92,
		 "DISABILITY_START_DATE": "01-30-2014 ",
		 "DATE_CLAIM_CLOSED": "05-31-2014 ",
		 "ACTUAL_RTW_DATE": "05-02-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sharman",
		 "CLAIMANT_FIRST_NAME": "Ann",
		 "CLAIM_NO": "2019-11-14-0345-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "12-20-2019 ",
		 "DATE_CLAIM_CLOSED": "03-25-2020 ",
		 "ACTUAL_RTW_DATE": "03-20-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shaw",
		 "CLAIMANT_FIRST_NAME": "Angel",
		 "CLAIM_NO": "2014-06-09-0295-VP-01",
		 "DURATION": 291,
		 "DISABILITY_START_DATE": "06-05-2014 ",
		 "DATE_CLAIM_CLOSED": "04-01-2015 ",
		 "ACTUAL_RTW_DATE": "03-23-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shaw",
		 "CLAIMANT_FIRST_NAME": "Christine",
		 "CLAIM_NO": "2015-07-09-0359-VP-01",
		 "DURATION": 273,
		 "DISABILITY_START_DATE": "07-10-2015 ",
		 "DATE_CLAIM_CLOSED": "04-15-2016 ",
		 "ACTUAL_RTW_DATE": "04-08-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shearer",
		 "CLAIMANT_FIRST_NAME": "Alana",
		 "CLAIM_NO": "2016-09-30-0370-VP-01",
		 "DURATION": 182,
		 "DISABILITY_START_DATE": "09-29-2016 ",
		 "DATE_CLAIM_CLOSED": "04-03-2017 ",
		 "ACTUAL_RTW_DATE": "03-30-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shearer",
		 "CLAIMANT_FIRST_NAME": "Alana",
		 "CLAIM_NO": "2018-01-16-0229-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "01-30-2018 ",
		 "DATE_CLAIM_CLOSED": "02-20-2018 ",
		 "ACTUAL_RTW_DATE": "02-12-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shearer",
		 "CLAIMANT_FIRST_NAME": "Alana",
		 "CLAIM_NO": "2016-02-26-0271-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "02-19-2016 ",
		 "DATE_CLAIM_CLOSED": "04-01-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sheehan",
		 "CLAIMANT_FIRST_NAME": "Tara",
		 "CLAIM_NO": "2015-02-09-0274-VP-01",
		 "DURATION": 44,
		 "DISABILITY_START_DATE": "03-09-2015 ",
		 "DATE_CLAIM_CLOSED": "06-01-2015 ",
		 "ACTUAL_RTW_DATE": "04-22-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sheehan",
		 "CLAIMANT_FIRST_NAME": "Tara",
		 "CLAIM_NO": "2013-01-05-0374-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "11-27-2012 ",
		 "DATE_CLAIM_CLOSED": "03-26-2013 ",
		 "ACTUAL_RTW_DATE": "02-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shelby",
		 "CLAIMANT_FIRST_NAME": "Diana",
		 "CLAIM_NO": "2013-01-05-1004-VP-01",
		 "DURATION": 88,
		 "DISABILITY_START_DATE": "09-17-2012 ",
		 "DATE_CLAIM_CLOSED": "01-22-2013 ",
		 "ACTUAL_RTW_DATE": "12-14-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shelby",
		 "CLAIMANT_FIRST_NAME": "Diana",
		 "CLAIM_NO": "2016-04-15-0465-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "05-10-2016 ",
		 "DATE_CLAIM_CLOSED": "07-11-2016 ",
		 "ACTUAL_RTW_DATE": "05-31-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shelton",
		 "CLAIMANT_FIRST_NAME": "Clarence",
		 "CLAIM_NO": "2014-07-08-0039-VP-01",
		 "DURATION": 36,
		 "DISABILITY_START_DATE": "07-07-2014 ",
		 "DATE_CLAIM_CLOSED": "12-09-2014 ",
		 "ACTUAL_RTW_DATE": "08-12-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shen",
		 "CLAIMANT_FIRST_NAME": "Fred",
		 "CLAIM_NO": "2020-01-02-0307-VP-01",
		 "DURATION": 156,
		 "DISABILITY_START_DATE": "12-24-2019 ",
		 "DATE_CLAIM_CLOSED": "06-01-2020 ",
		 "ACTUAL_RTW_DATE": "05-28-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shepherd",
		 "CLAIMANT_FIRST_NAME": "Tami",
		 "CLAIM_NO": "2017-07-21-0533-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "07-17-2017 ",
		 "DATE_CLAIM_CLOSED": "09-27-2017 ",
		 "ACTUAL_RTW_DATE": "09-25-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shepherd",
		 "CLAIMANT_FIRST_NAME": "Christopher",
		 "CLAIM_NO": "2016-09-02-0207-VP-01",
		 "DURATION": 82,
		 "DISABILITY_START_DATE": "08-31-2016 ",
		 "DATE_CLAIM_CLOSED": "12-07-2016 ",
		 "ACTUAL_RTW_DATE": "11-21-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shepherd",
		 "CLAIMANT_FIRST_NAME": "Tami",
		 "CLAIM_NO": "2018-01-03-0403-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "01-04-2018 ",
		 "DATE_CLAIM_CLOSED": "03-28-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shepherd",
		 "CLAIMANT_FIRST_NAME": "Christopher",
		 "CLAIM_NO": "2016-02-13-0022-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "01-20-2016 ",
		 "DATE_CLAIM_CLOSED": "04-01-2016 ",
		 "ACTUAL_RTW_DATE": "02-21-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shepherd",
		 "CLAIMANT_FIRST_NAME": "Christopher",
		 "CLAIM_NO": "2019-01-29-0436-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "01-14-2019 ",
		 "DATE_CLAIM_CLOSED": "03-05-2019 ",
		 "ACTUAL_RTW_DATE": "03-01-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shergill",
		 "CLAIMANT_FIRST_NAME": "Jasmine",
		 "CLAIM_NO": "2013-02-19-0216-VP-01",
		 "DURATION": 65,
		 "DISABILITY_START_DATE": "02-18-2013 ",
		 "DATE_CLAIM_CLOSED": "04-10-2014 ",
		 "ACTUAL_RTW_DATE": "04-24-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sherne",
		 "CLAIMANT_FIRST_NAME": "Caryn",
		 "CLAIM_NO": "2021-02-22-0486-VP-01",
		 "DURATION": 69,
		 "DISABILITY_START_DATE": "02-16-2021 ",
		 "DATE_CLAIM_CLOSED": "04-28-2021 ",
		 "ACTUAL_RTW_DATE": "04-26-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sherne",
		 "CLAIMANT_FIRST_NAME": "Caryn",
		 "CLAIM_NO": "2015-08-24-0185-VP-01",
		 "DURATION": 51,
		 "DISABILITY_START_DATE": "08-24-2015 ",
		 "DATE_CLAIM_CLOSED": "10-19-2015 ",
		 "ACTUAL_RTW_DATE": "10-14-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sheth",
		 "CLAIMANT_FIRST_NAME": "Payush",
		 "CLAIM_NO": "2013-01-05-0282-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "11-21-2012 ",
		 "DATE_CLAIM_CLOSED": "02-09-2013 ",
		 "ACTUAL_RTW_DATE": "12-06-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shields",
		 "CLAIMANT_FIRST_NAME": "Remon",
		 "CLAIM_NO": "2017-03-15-0569-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "03-03-2017 ",
		 "DATE_CLAIM_CLOSED": "04-25-2017 ",
		 "ACTUAL_RTW_DATE": "03-27-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shine",
		 "CLAIMANT_FIRST_NAME": "Tiffany",
		 "CLAIM_NO": "2021-04-08-0549-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "03-31-2021 ",
		 "DATE_CLAIM_CLOSED": "06-18-2021 ",
		 "ACTUAL_RTW_DATE": "04-29-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shonuyi",
		 "CLAIMANT_FIRST_NAME": "Chris",
		 "CLAIM_NO": "2014-08-18-0397-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "08-14-2014 ",
		 "DATE_CLAIM_CLOSED": "12-12-2014 ",
		 "ACTUAL_RTW_DATE": "10-23-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shostak",
		 "CLAIMANT_FIRST_NAME": "Karen",
		 "CLAIM_NO": "2018-02-05-0294-VP-01",
		 "DURATION": 50,
		 "DISABILITY_START_DATE": "12-25-2017 ",
		 "DATE_CLAIM_CLOSED": "02-28-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Shousha",
		 "CLAIMANT_FIRST_NAME": "Amy",
		 "CLAIM_NO": "2020-06-30-0369-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "07-14-2020 ",
		 "DATE_CLAIM_CLOSED": "09-10-2020 ",
		 "ACTUAL_RTW_DATE": "09-08-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Siapno",
		 "CLAIMANT_FIRST_NAME": "Christine",
		 "CLAIM_NO": "2020-12-21-1120-VP-01",
		 "DURATION": 65,
		 "DISABILITY_START_DATE": "01-20-2021 ",
		 "DATE_CLAIM_CLOSED": "03-26-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Siapno",
		 "CLAIMANT_FIRST_NAME": "Christine",
		 "CLAIM_NO": "2020-11-30-0549-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "11-23-2020 ",
		 "DATE_CLAIM_CLOSED": "12-03-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Siapno",
		 "CLAIMANT_FIRST_NAME": "Edna",
		 "CLAIM_NO": "2013-01-05-1404-VP-01",
		 "DURATION": 365,
		 "DISABILITY_START_DATE": "07-20-2012 ",
		 "DATE_CLAIM_CLOSED": "07-21-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Siat",
		 "CLAIMANT_FIRST_NAME": "Salvi",
		 "CLAIM_NO": "2020-11-04-0007-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "11-02-2020 ",
		 "DATE_CLAIM_CLOSED": "11-18-2020 ",
		 "ACTUAL_RTW_DATE": "11-17-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sibbu",
		 "CLAIMANT_FIRST_NAME": "Ruben",
		 "CLAIM_NO": "2021-06-15-0623-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "06-16-2021 ",
		 "DATE_CLAIM_CLOSED": "07-19-2021 ",
		 "ACTUAL_RTW_DATE": "07-13-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sibbu",
		 "CLAIMANT_FIRST_NAME": "Ruben",
		 "CLAIM_NO": "2022-01-21-0507-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "01-20-2022 ",
		 "DATE_CLAIM_CLOSED": "02-11-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sigdyal",
		 "CLAIMANT_FIRST_NAME": "Pratigya",
		 "CLAIM_NO": "2019-04-22-0663-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "05-21-2019 ",
		 "DATE_CLAIM_CLOSED": "06-02-2019 ",
		 "ACTUAL_RTW_DATE": "05-30-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Silva",
		 "CLAIMANT_FIRST_NAME": "Gloria",
		 "CLAIM_NO": "2014-01-02-0414-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "01-21-2014 ",
		 "DATE_CLAIM_CLOSED": "05-31-2014 ",
		 "ACTUAL_RTW_DATE": "02-04-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Silva",
		 "CLAIMANT_FIRST_NAME": "Cinthia",
		 "CLAIM_NO": "2014-04-28-0367-VP-01",
		 "DURATION": 85,
		 "DISABILITY_START_DATE": "04-28-2014 ",
		 "DATE_CLAIM_CLOSED": "12-09-2014 ",
		 "ACTUAL_RTW_DATE": "07-22-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Silva Chaix",
		 "CLAIMANT_FIRST_NAME": "Priscila",
		 "CLAIM_NO": "2022-12-19-1088-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "01-11-2023 ",
		 "DATE_CLAIM_CLOSED": "01-31-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Silva Hernandez",
		 "CLAIMANT_FIRST_NAME": "Margarita",
		 "CLAIM_NO": "2016-02-09-0557-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "02-29-2016 ",
		 "DATE_CLAIM_CLOSED": "04-25-2016 ",
		 "ACTUAL_RTW_DATE": "04-25-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Silver",
		 "CLAIMANT_FIRST_NAME": "Jill",
		 "CLAIM_NO": "2017-09-27-0116-VP-01",
		 "DURATION": 102,
		 "DISABILITY_START_DATE": "10-02-2017 ",
		 "DATE_CLAIM_CLOSED": "01-12-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Silvis",
		 "CLAIMANT_FIRST_NAME": "Charles",
		 "CLAIM_NO": "2013-06-17-0388-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "06-27-2013 ",
		 "DATE_CLAIM_CLOSED": "09-16-2013 ",
		 "ACTUAL_RTW_DATE": "07-15-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Simeon",
		 "CLAIMANT_FIRST_NAME": "April",
		 "CLAIM_NO": "2013-02-21-0155-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "03-22-2013 ",
		 "DATE_CLAIM_CLOSED": "08-23-2013 ",
		 "ACTUAL_RTW_DATE": "06-14-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Simmons",
		 "CLAIMANT_FIRST_NAME": "Sedrick",
		 "CLAIM_NO": "2019-01-11-0657-VP-01",
		 "DURATION": 54,
		 "DISABILITY_START_DATE": "01-17-2019 ",
		 "DATE_CLAIM_CLOSED": "03-13-2019 ",
		 "ACTUAL_RTW_DATE": "03-12-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Simmons",
		 "CLAIMANT_FIRST_NAME": "Matthew",
		 "CLAIM_NO": "2016-01-15-0506-VP-01",
		 "DURATION": 104,
		 "DISABILITY_START_DATE": "01-14-2016 ",
		 "DATE_CLAIM_CLOSED": "04-27-2016 ",
		 "ACTUAL_RTW_DATE": "04-27-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Simon",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2019-07-19-0627-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "08-12-2019 ",
		 "DATE_CLAIM_CLOSED": "10-01-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Simon",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2017-05-01-0332-VP-01",
		 "DURATION": 50,
		 "DISABILITY_START_DATE": "05-15-2017 ",
		 "DATE_CLAIM_CLOSED": "07-10-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Simon",
		 "CLAIMANT_FIRST_NAME": "Daniel",
		 "CLAIM_NO": "2019-06-05-0467-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "06-12-2019 ",
		 "DATE_CLAIM_CLOSED": "07-12-2019 ",
		 "ACTUAL_RTW_DATE": "07-01-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Simpraseuth",
		 "CLAIMANT_FIRST_NAME": "Phonesavanh",
		 "CLAIM_NO": "2017-09-11-0418-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "09-07-2017 ",
		 "DATE_CLAIM_CLOSED": "09-26-2017 ",
		 "ACTUAL_RTW_DATE": "09-18-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Simpraseuth",
		 "CLAIMANT_FIRST_NAME": "Phonesavanh",
		 "CLAIM_NO": "2016-07-27-0270-VP-01",
		 "DURATION": 69,
		 "DISABILITY_START_DATE": "07-20-2016 ",
		 "DATE_CLAIM_CLOSED": "10-03-2016 ",
		 "ACTUAL_RTW_DATE": "09-27-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Simpson",
		 "CLAIMANT_FIRST_NAME": "Mark",
		 "CLAIM_NO": "2014-06-25-0225-VP-01",
		 "DURATION": 585,
		 "DISABILITY_START_DATE": "07-18-2014 ",
		 "DATE_CLAIM_CLOSED": "07-20-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Simpson",
		 "CLAIMANT_FIRST_NAME": "Mark",
		 "CLAIM_NO": "2013-01-28-0281-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "01-23-2013 ",
		 "DATE_CLAIM_CLOSED": "02-09-2013 ",
		 "ACTUAL_RTW_DATE": "01-30-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Simpson",
		 "CLAIMANT_FIRST_NAME": "Mark",
		 "CLAIM_NO": "2013-05-14-0039-VP-01",
		 "DURATION": 182,
		 "DISABILITY_START_DATE": "05-08-2013 ",
		 "DATE_CLAIM_CLOSED": "11-07-2013 ",
		 "ACTUAL_RTW_DATE": "11-06-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Singer",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2021-07-15-0666-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "08-16-2021 ",
		 "DATE_CLAIM_CLOSED": "01-25-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Singer",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2020-01-06-0822-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "02-13-2020 ",
		 "DATE_CLAIM_CLOSED": "03-30-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Singh",
		 "CLAIMANT_FIRST_NAME": "Kanwal",
		 "CLAIM_NO": "2016-02-29-0594-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "02-23-2016 ",
		 "DATE_CLAIM_CLOSED": "04-12-2016 ",
		 "ACTUAL_RTW_DATE": "04-11-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Singh",
		 "CLAIMANT_FIRST_NAME": "Harjinder",
		 "CLAIM_NO": "2014-12-16-0181-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "11-24-2014 ",
		 "DATE_CLAIM_CLOSED": "01-23-2015 ",
		 "ACTUAL_RTW_DATE": "12-08-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Singh",
		 "CLAIMANT_FIRST_NAME": "Satwinder",
		 "CLAIM_NO": "2014-10-28-0224-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "11-13-2014 ",
		 "DATE_CLAIM_CLOSED": "12-24-2014 ",
		 "ACTUAL_RTW_DATE": "12-01-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Singh",
		 "CLAIMANT_FIRST_NAME": "Harjinder",
		 "CLAIM_NO": "2015-04-20-0509-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "04-21-2015 ",
		 "DATE_CLAIM_CLOSED": "07-14-2015 ",
		 "ACTUAL_RTW_DATE": "06-08-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Singh",
		 "CLAIMANT_FIRST_NAME": "Satwinder",
		 "CLAIM_NO": "2013-04-08-0164-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "04-29-2013 ",
		 "DATE_CLAIM_CLOSED": "07-15-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Singh",
		 "CLAIMANT_FIRST_NAME": "Harjinder",
		 "CLAIM_NO": "2021-10-28-0934-VP-01",
		 "DURATION": 73,
		 "DISABILITY_START_DATE": "10-12-2021 ",
		 "DATE_CLAIM_CLOSED": "01-14-2022 ",
		 "ACTUAL_RTW_DATE": "12-24-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Singh",
		 "CLAIMANT_FIRST_NAME": "Nishi",
		 "CLAIM_NO": "2017-12-11-0520-VP-01",
		 "DURATION": 72,
		 "DISABILITY_START_DATE": "12-22-2017 ",
		 "DATE_CLAIM_CLOSED": "03-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sinha",
		 "CLAIMANT_FIRST_NAME": "Anjali",
		 "CLAIM_NO": "2021-03-25-0445-VP-01",
		 "DURATION": 112,
		 "DISABILITY_START_DATE": "03-29-2021 ",
		 "DATE_CLAIM_CLOSED": "07-20-2021 ",
		 "ACTUAL_RTW_DATE": "07-19-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sio",
		 "CLAIMANT_FIRST_NAME": "Cassandra",
		 "CLAIM_NO": "2021-05-28-0306-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "07-02-2021 ",
		 "DATE_CLAIM_CLOSED": "08-16-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Siojo Jr",
		 "CLAIMANT_FIRST_NAME": "Aujencio",
		 "CLAIM_NO": "2022-12-15-0584-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "12-05-2022 ",
		 "DATE_CLAIM_CLOSED": "02-06-2023 ",
		 "ACTUAL_RTW_DATE": "12-15-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sipes",
		 "CLAIMANT_FIRST_NAME": "Joelle",
		 "CLAIM_NO": "2013-02-25-0160-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "02-19-2013 ",
		 "DATE_CLAIM_CLOSED": "05-21-2013 ",
		 "ACTUAL_RTW_DATE": "03-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sipes",
		 "CLAIMANT_FIRST_NAME": "Joelle",
		 "CLAIM_NO": "2017-10-02-0198-VP-01",
		 "DURATION": 153,
		 "DISABILITY_START_DATE": "09-29-2017 ",
		 "DATE_CLAIM_CLOSED": "03-02-2018 ",
		 "ACTUAL_RTW_DATE": "03-01-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sipes",
		 "CLAIMANT_FIRST_NAME": "Joelle",
		 "CLAIM_NO": "2013-07-15-0306-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "07-15-2013 ",
		 "DATE_CLAIM_CLOSED": "09-16-2013 ",
		 "ACTUAL_RTW_DATE": "08-05-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sipes",
		 "CLAIMANT_FIRST_NAME": "Joelle",
		 "CLAIM_NO": "2014-10-27-0169-VP-01",
		 "DURATION": 38,
		 "DISABILITY_START_DATE": "10-24-2014 ",
		 "DATE_CLAIM_CLOSED": "12-17-2014 ",
		 "ACTUAL_RTW_DATE": "12-01-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sipes",
		 "CLAIMANT_FIRST_NAME": "Joelle",
		 "CLAIM_NO": "2016-07-06-0042-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "07-04-2016 ",
		 "DATE_CLAIM_CLOSED": "08-02-2016 ",
		 "ACTUAL_RTW_DATE": "07-18-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sipes",
		 "CLAIMANT_FIRST_NAME": "Joelle",
		 "CLAIM_NO": "2016-02-11-0190-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "02-04-2016 ",
		 "DATE_CLAIM_CLOSED": "03-22-2016 ",
		 "ACTUAL_RTW_DATE": "02-22-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sipes",
		 "CLAIMANT_FIRST_NAME": "Joelle",
		 "CLAIM_NO": "2019-04-09-0743-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "04-09-2019 ",
		 "DATE_CLAIM_CLOSED": "05-15-2019 ",
		 "ACTUAL_RTW_DATE": "05-13-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Siripakdi",
		 "CLAIMANT_FIRST_NAME": "Khamtane",
		 "CLAIM_NO": "2013-09-16-0038-VP-01",
		 "DURATION": 104,
		 "DISABILITY_START_DATE": "09-03-2013 ",
		 "DATE_CLAIM_CLOSED": "01-09-2014 ",
		 "ACTUAL_RTW_DATE": "12-16-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sirota",
		 "CLAIMANT_FIRST_NAME": "Fred",
		 "CLAIM_NO": "2014-01-13-0254-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "01-13-2014 ",
		 "DATE_CLAIM_CLOSED": "05-31-2014 ",
		 "ACTUAL_RTW_DATE": "04-07-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sison",
		 "CLAIMANT_FIRST_NAME": "Maria Theresa",
		 "CLAIM_NO": "2020-12-08-0912-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "11-19-2020 ",
		 "DATE_CLAIM_CLOSED": "12-23-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Siu",
		 "CLAIMANT_FIRST_NAME": "Delia",
		 "CLAIM_NO": "2019-03-22-0328-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "04-16-2019 ",
		 "DATE_CLAIM_CLOSED": "05-29-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Siu",
		 "CLAIMANT_FIRST_NAME": "Delia",
		 "CLAIM_NO": "2015-06-08-0604-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "07-06-2015 ",
		 "DATE_CLAIM_CLOSED": "09-22-2015 ",
		 "ACTUAL_RTW_DATE": "09-15-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sivesind Iii",
		 "CLAIMANT_FIRST_NAME": "Jerome",
		 "CLAIM_NO": "2021-03-01-0920-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "02-26-2021 ",
		 "DATE_CLAIM_CLOSED": "04-27-2021 ",
		 "ACTUAL_RTW_DATE": "04-23-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Skinner",
		 "CLAIMANT_FIRST_NAME": "Hilda",
		 "CLAIM_NO": "2017-03-17-0310-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "03-17-2017 ",
		 "DATE_CLAIM_CLOSED": "04-24-2017 ",
		 "ACTUAL_RTW_DATE": "04-18-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Skinner",
		 "CLAIMANT_FIRST_NAME": "Hilda",
		 "CLAIM_NO": "2018-01-10-0095-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "01-19-2018 ",
		 "DATE_CLAIM_CLOSED": "03-01-2018 ",
		 "ACTUAL_RTW_DATE": "01-29-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Skye",
		 "CLAIMANT_FIRST_NAME": "Sarah",
		 "CLAIM_NO": "2020-08-24-0705-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "09-18-2020 ",
		 "DATE_CLAIM_CLOSED": "11-03-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Slabby",
		 "CLAIMANT_FIRST_NAME": "Carmen",
		 "CLAIM_NO": "2014-01-23-0490-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "01-24-2014 ",
		 "DATE_CLAIM_CLOSED": "05-31-2014 ",
		 "ACTUAL_RTW_DATE": "02-11-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Slattery",
		 "CLAIMANT_FIRST_NAME": "Eeva Liisa",
		 "CLAIM_NO": "2022-05-05-0327-VP-01",
		 "DURATION": 230,
		 "DISABILITY_START_DATE": "05-03-2022 ",
		 "DATE_CLAIM_CLOSED": "12-20-2022 ",
		 "ACTUAL_RTW_DATE": "12-19-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Slinko Jr.",
		 "CLAIMANT_FIRST_NAME": "Lawrence",
		 "CLAIM_NO": "2015-05-18-0567-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "06-03-2015 ",
		 "DATE_CLAIM_CLOSED": "08-04-2015 ",
		 "ACTUAL_RTW_DATE": "06-15-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sloan",
		 "CLAIMANT_FIRST_NAME": "Mark",
		 "CLAIM_NO": "2014-09-15-0397-VP-01",
		 "DURATION": 76,
		 "DISABILITY_START_DATE": "09-02-2014 ",
		 "DATE_CLAIM_CLOSED": "01-05-2015 ",
		 "ACTUAL_RTW_DATE": "11-17-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sloan",
		 "CLAIMANT_FIRST_NAME": "Mark",
		 "CLAIM_NO": "2016-05-10-0017-VP-01",
		 "DURATION": 76,
		 "DISABILITY_START_DATE": "05-03-2016 ",
		 "DATE_CLAIM_CLOSED": "07-21-2016 ",
		 "ACTUAL_RTW_DATE": "07-18-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sloane",
		 "CLAIMANT_FIRST_NAME": "Jessica",
		 "CLAIM_NO": "2020-05-14-0191-VP-01",
		 "DURATION": 61,
		 "DISABILITY_START_DATE": "06-01-2020 ",
		 "DATE_CLAIM_CLOSED": "08-03-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Smalls",
		 "CLAIMANT_FIRST_NAME": "Joni",
		 "CLAIM_NO": "2013-01-05-7647-VP-01",
		 "DURATION": 1375,
		 "DISABILITY_START_DATE": "09-21-2009 ",
		 "DATE_CLAIM_CLOSED": "11-06-2013 ",
		 "ACTUAL_RTW_DATE": "06-27-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Smith",
		 "CLAIMANT_FIRST_NAME": "Timothy",
		 "CLAIM_NO": "2018-10-01-0598-VP-01",
		 "DURATION": 53,
		 "DISABILITY_START_DATE": "11-15-2018 ",
		 "DATE_CLAIM_CLOSED": "02-12-2019 ",
		 "ACTUAL_RTW_DATE": "01-07-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Smith",
		 "CLAIMANT_FIRST_NAME": "Alexander",
		 "CLAIM_NO": "2014-07-15-0396-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "07-18-2014 ",
		 "DATE_CLAIM_CLOSED": "11-03-2014 ",
		 "ACTUAL_RTW_DATE": "08-18-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Smith",
		 "CLAIMANT_FIRST_NAME": "Brandon",
		 "CLAIM_NO": "2014-01-31-0513-VP-01",
		 "DURATION": 65,
		 "DISABILITY_START_DATE": "01-27-2014 ",
		 "DATE_CLAIM_CLOSED": "07-19-2014 ",
		 "ACTUAL_RTW_DATE": "04-02-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Smith",
		 "CLAIMANT_FIRST_NAME": "Sharon",
		 "CLAIM_NO": "2013-09-19-0135-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "09-13-2013 ",
		 "DATE_CLAIM_CLOSED": "03-10-2014 ",
		 "ACTUAL_RTW_DATE": "09-23-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Smith",
		 "CLAIMANT_FIRST_NAME": "Christina",
		 "CLAIM_NO": "2013-06-24-0401-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "07-11-2013 ",
		 "DATE_CLAIM_CLOSED": "10-22-2013 ",
		 "ACTUAL_RTW_DATE": "10-10-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Smith",
		 "CLAIMANT_FIRST_NAME": "Jillian",
		 "CLAIM_NO": "2022-10-03-0815-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "10-27-2022 ",
		 "DATE_CLAIM_CLOSED": "12-27-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Smith",
		 "CLAIMANT_FIRST_NAME": "Melissa",
		 "CLAIM_NO": "2022-07-07-0434-VP-01",
		 "DURATION": 112,
		 "DISABILITY_START_DATE": "08-01-2022 ",
		 "DATE_CLAIM_CLOSED": "10-29-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Smith",
		 "CLAIMANT_FIRST_NAME": "Kirk",
		 "CLAIM_NO": "2015-11-04-0430-VP-01",
		 "DURATION": 0,
		 "DISABILITY_START_DATE": "11-04-2015 ",
		 "DATE_CLAIM_CLOSED": "11-10-2015 ",
		 "ACTUAL_RTW_DATE": "11-04-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Smitt",
		 "CLAIMANT_FIRST_NAME": "Maryann",
		 "CLAIM_NO": "2021-10-20-0510-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "10-11-2021 ",
		 "DATE_CLAIM_CLOSED": "12-08-2021 ",
		 "ACTUAL_RTW_DATE": "12-06-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Smolik-Smiech",
		 "CLAIMANT_FIRST_NAME": "Elzbieta",
		 "CLAIM_NO": "2014-01-14-0280-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "01-13-2014 ",
		 "DATE_CLAIM_CLOSED": "05-31-2014 ",
		 "ACTUAL_RTW_DATE": "01-28-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Snell",
		 "CLAIMANT_FIRST_NAME": "Anna",
		 "CLAIM_NO": "2018-01-08-0249-VP-01",
		 "DURATION": 73,
		 "DISABILITY_START_DATE": "01-22-2018 ",
		 "DATE_CLAIM_CLOSED": "04-10-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Snell",
		 "CLAIMANT_FIRST_NAME": "Anna",
		 "CLAIM_NO": "2015-01-06-0597-VP-01",
		 "DURATION": 79,
		 "DISABILITY_START_DATE": "02-02-2015 ",
		 "DATE_CLAIM_CLOSED": "06-01-2015 ",
		 "ACTUAL_RTW_DATE": "04-22-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Snider",
		 "CLAIMANT_FIRST_NAME": "Tracy",
		 "CLAIM_NO": "2017-05-08-0591-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "06-14-2017 ",
		 "DATE_CLAIM_CLOSED": "08-21-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Snider",
		 "CLAIMANT_FIRST_NAME": "Tracy",
		 "CLAIM_NO": "2021-05-07-0832-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "06-09-2021 ",
		 "DATE_CLAIM_CLOSED": "08-19-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Solis",
		 "CLAIMANT_FIRST_NAME": "Lola",
		 "CLAIM_NO": "2020-08-21-0025-VP-01",
		 "DURATION": 93,
		 "DISABILITY_START_DATE": "08-29-2020 ",
		 "DATE_CLAIM_CLOSED": "12-08-2020 ",
		 "ACTUAL_RTW_DATE": "11-30-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Solis",
		 "CLAIMANT_FIRST_NAME": "Lucia",
		 "CLAIM_NO": "2017-01-06-0561-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "01-03-2017 ",
		 "DATE_CLAIM_CLOSED": "03-08-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Solis",
		 "CLAIMANT_FIRST_NAME": "Wendy",
		 "CLAIM_NO": "2015-01-09-0235-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "01-09-2015 ",
		 "DATE_CLAIM_CLOSED": "01-26-2015 ",
		 "ACTUAL_RTW_DATE": "01-19-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Solis",
		 "CLAIMANT_FIRST_NAME": "Lola",
		 "CLAIM_NO": "2020-05-11-0766-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "05-10-2020 ",
		 "DATE_CLAIM_CLOSED": "05-13-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Solis-Armendariz",
		 "CLAIMANT_FIRST_NAME": "Linda",
		 "CLAIM_NO": "2014-11-14-0240-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "11-03-2014 ",
		 "DATE_CLAIM_CLOSED": "01-08-2015 ",
		 "ACTUAL_RTW_DATE": "11-24-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Solis-Armendariz",
		 "CLAIMANT_FIRST_NAME": "Linda",
		 "CLAIM_NO": "2013-02-27-0358-VP-01",
		 "DURATION": 125,
		 "DISABILITY_START_DATE": "02-26-2013 ",
		 "DATE_CLAIM_CLOSED": "08-12-2013 ",
		 "ACTUAL_RTW_DATE": "07-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Solis-Armendariz",
		 "CLAIMANT_FIRST_NAME": "Linda",
		 "CLAIM_NO": "2013-01-05-2622-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "02-27-2012 ",
		 "DATE_CLAIM_CLOSED": "06-20-2013 ",
		 "ACTUAL_RTW_DATE": "03-08-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Solorio",
		 "CLAIMANT_FIRST_NAME": "Fabiola",
		 "CLAIM_NO": "2022-08-05-0110-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "07-18-2022 ",
		 "DATE_CLAIM_CLOSED": "08-23-2022 ",
		 "ACTUAL_RTW_DATE": "08-04-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Som",
		 "CLAIMANT_FIRST_NAME": "Buna",
		 "CLAIM_NO": "2015-05-18-0185-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "05-14-2015 ",
		 "DATE_CLAIM_CLOSED": "06-17-2015 ",
		 "ACTUAL_RTW_DATE": "05-29-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Somers",
		 "CLAIMANT_FIRST_NAME": "Vicki",
		 "CLAIM_NO": "2018-09-25-0189-VP-01",
		 "DURATION": 36,
		 "DISABILITY_START_DATE": "10-10-2018 ",
		 "DATE_CLAIM_CLOSED": "11-28-2018 ",
		 "ACTUAL_RTW_DATE": "11-15-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Somers",
		 "CLAIMANT_FIRST_NAME": "Rose",
		 "CLAIM_NO": "2018-09-13-0428-VP-01",
		 "DURATION": 92,
		 "DISABILITY_START_DATE": "11-07-2018 ",
		 "DATE_CLAIM_CLOSED": "02-12-2019 ",
		 "ACTUAL_RTW_DATE": "02-07-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Somers",
		 "CLAIMANT_FIRST_NAME": "Vicki",
		 "CLAIM_NO": "2018-08-17-0584-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "08-24-2018 ",
		 "DATE_CLAIM_CLOSED": "09-11-2018 ",
		 "ACTUAL_RTW_DATE": "09-10-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Son",
		 "CLAIMANT_FIRST_NAME": "Min-Sun",
		 "CLAIM_NO": "2018-07-02-0731-VP-01",
		 "DURATION": 65,
		 "DISABILITY_START_DATE": "07-17-2018 ",
		 "DATE_CLAIM_CLOSED": "09-20-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Son",
		 "CLAIMANT_FIRST_NAME": "Min-Sun",
		 "CLAIM_NO": "2020-11-30-0547-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "01-18-2021 ",
		 "DATE_CLAIM_CLOSED": "03-16-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sonawane",
		 "CLAIMANT_FIRST_NAME": "Rupa",
		 "CLAIM_NO": "2021-06-17-0976-VP-01",
		 "DURATION": 4,
		 "DISABILITY_START_DATE": "06-17-2021 ",
		 "DATE_CLAIM_CLOSED": "07-26-2021 ",
		 "ACTUAL_RTW_DATE": "06-21-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sonawane",
		 "CLAIMANT_FIRST_NAME": "Rupa",
		 "CLAIM_NO": "2020-04-06-0532-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "05-18-2020 ",
		 "DATE_CLAIM_CLOSED": "08-17-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Song",
		 "CLAIMANT_FIRST_NAME": "Li",
		 "CLAIM_NO": "2016-05-10-0560-VP-01",
		 "DURATION": 146,
		 "DISABILITY_START_DATE": "05-11-2016 ",
		 "DATE_CLAIM_CLOSED": "10-12-2016 ",
		 "ACTUAL_RTW_DATE": "10-04-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Soo Hoo",
		 "CLAIMANT_FIRST_NAME": "Irene",
		 "CLAIM_NO": "2018-01-19-0622-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "02-15-2018 ",
		 "DATE_CLAIM_CLOSED": "04-03-2018 ",
		 "ACTUAL_RTW_DATE": "04-02-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sorensen",
		 "CLAIMANT_FIRST_NAME": "Rachel",
		 "CLAIM_NO": "2022-10-24-0613-VP-01",
		 "DURATION": 73,
		 "DISABILITY_START_DATE": "11-04-2022 ",
		 "DATE_CLAIM_CLOSED": "01-17-2023 ",
		 "ACTUAL_RTW_DATE": "01-16-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sorm",
		 "CLAIMANT_FIRST_NAME": "Chanchaesda",
		 "CLAIM_NO": "2020-09-22-0428-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "10-05-2020 ",
		 "DATE_CLAIM_CLOSED": "12-07-2020 ",
		 "ACTUAL_RTW_DATE": "12-01-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sornberger",
		 "CLAIMANT_FIRST_NAME": "Susan",
		 "CLAIM_NO": "2014-12-17-0213-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "01-07-2015 ",
		 "DATE_CLAIM_CLOSED": "03-09-2015 ",
		 "ACTUAL_RTW_DATE": "03-07-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sosa",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2013-01-28-0433-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "02-11-2013 ",
		 "DATE_CLAIM_CLOSED": "08-06-2013 ",
		 "ACTUAL_RTW_DATE": "04-10-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sosa",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2015-10-07-0346-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "11-23-2015 ",
		 "DATE_CLAIM_CLOSED": "01-25-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sosa",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2014-09-16-0270-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "09-09-2014 ",
		 "DATE_CLAIM_CLOSED": "12-08-2014 ",
		 "ACTUAL_RTW_DATE": "09-29-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Soso",
		 "CLAIMANT_FIRST_NAME": "Cedrick",
		 "CLAIM_NO": "2013-01-10-0008-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "12-26-2012 ",
		 "DATE_CLAIM_CLOSED": "04-29-2013 ",
		 "ACTUAL_RTW_DATE": "01-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Soto",
		 "CLAIMANT_FIRST_NAME": "Dana",
		 "CLAIM_NO": "2013-01-23-0412-VP-01",
		 "DURATION": 66,
		 "DISABILITY_START_DATE": "01-23-2013 ",
		 "DATE_CLAIM_CLOSED": "06-19-2013 ",
		 "ACTUAL_RTW_DATE": "03-30-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Soto Barreto",
		 "CLAIMANT_FIRST_NAME": "Benjamin",
		 "CLAIM_NO": "2016-04-26-0204-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "04-18-2016 ",
		 "DATE_CLAIM_CLOSED": "06-01-2016 ",
		 "ACTUAL_RTW_DATE": "04-26-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Souriolle",
		 "CLAIMANT_FIRST_NAME": "Jason",
		 "CLAIM_NO": "2016-09-19-0274-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "09-28-2016 ",
		 "DATE_CLAIM_CLOSED": "11-29-2016 ",
		 "ACTUAL_RTW_DATE": "10-20-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sousou",
		 "CLAIMANT_FIRST_NAME": "Mona",
		 "CLAIM_NO": "2021-01-25-1061-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "03-04-2021 ",
		 "DATE_CLAIM_CLOSED": "03-22-2021 ",
		 "ACTUAL_RTW_DATE": "03-15-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Souva",
		 "CLAIMANT_FIRST_NAME": "Linda",
		 "CLAIM_NO": "2022-08-05-0836-VP-01",
		 "DURATION": 98,
		 "DISABILITY_START_DATE": "08-15-2022 ",
		 "DATE_CLAIM_CLOSED": "12-30-2022 ",
		 "ACTUAL_RTW_DATE": "11-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Souza",
		 "CLAIMANT_FIRST_NAME": "Angelina",
		 "CLAIM_NO": "2016-05-31-0267-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "05-23-2016 ",
		 "DATE_CLAIM_CLOSED": "07-10-2016 ",
		 "ACTUAL_RTW_DATE": "07-07-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Souza",
		 "CLAIMANT_FIRST_NAME": "Angelina",
		 "CLAIM_NO": "2013-04-30-0207-VP-01",
		 "DURATION": 251,
		 "DISABILITY_START_DATE": "04-26-2013 ",
		 "DATE_CLAIM_CLOSED": "04-10-2014 ",
		 "ACTUAL_RTW_DATE": "01-02-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Souza",
		 "CLAIMANT_FIRST_NAME": "Angelina",
		 "CLAIM_NO": "2017-09-08-0251-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "09-06-2017 ",
		 "DATE_CLAIM_CLOSED": "09-20-2017 ",
		 "ACTUAL_RTW_DATE": "09-18-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Spaargaren",
		 "CLAIMANT_FIRST_NAME": "Cynthia",
		 "CLAIM_NO": "2013-09-30-0168-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "09-27-2013 ",
		 "DATE_CLAIM_CLOSED": "12-24-2013 ",
		 "ACTUAL_RTW_DATE": "10-24-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Spaargaren",
		 "CLAIMANT_FIRST_NAME": "Cynthia",
		 "CLAIM_NO": "2015-06-29-0093-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "06-22-2015 ",
		 "DATE_CLAIM_CLOSED": "09-03-2015 ",
		 "ACTUAL_RTW_DATE": "07-13-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Spakes",
		 "CLAIMANT_FIRST_NAME": "Cory",
		 "CLAIM_NO": "2013-05-03-0342-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "05-06-2013 ",
		 "DATE_CLAIM_CLOSED": "08-12-2013 ",
		 "ACTUAL_RTW_DATE": "07-15-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Spargo",
		 "CLAIMANT_FIRST_NAME": "Catherine",
		 "CLAIM_NO": "2015-01-20-0330-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "01-28-2015 ",
		 "DATE_CLAIM_CLOSED": "03-09-2015 ",
		 "ACTUAL_RTW_DATE": "03-02-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Spayde",
		 "CLAIMANT_FIRST_NAME": "Katharine",
		 "CLAIM_NO": "2013-05-16-0285-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "05-27-2013 ",
		 "DATE_CLAIM_CLOSED": "07-31-2013 ",
		 "ACTUAL_RTW_DATE": "07-26-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Spayde",
		 "CLAIMANT_FIRST_NAME": "Katharine",
		 "CLAIM_NO": "2016-08-17-0123-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "08-26-2016 ",
		 "DATE_CLAIM_CLOSED": "10-21-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sperling",
		 "CLAIMANT_FIRST_NAME": "Leticia",
		 "CLAIM_NO": "2013-03-25-0360-VP-01",
		 "DURATION": 224,
		 "DISABILITY_START_DATE": "03-28-2013 ",
		 "DATE_CLAIM_CLOSED": "11-11-2013 ",
		 "ACTUAL_RTW_DATE": "11-07-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sperling",
		 "CLAIMANT_FIRST_NAME": "Leticia",
		 "CLAIM_NO": "2013-01-05-0308-VP-01",
		 "DURATION": 80,
		 "DISABILITY_START_DATE": "11-26-2012 ",
		 "DATE_CLAIM_CLOSED": "02-26-2013 ",
		 "ACTUAL_RTW_DATE": "02-14-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Spiller",
		 "CLAIMANT_FIRST_NAME": "Patricia",
		 "CLAIM_NO": "2013-02-26-0402-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "03-18-2013 ",
		 "DATE_CLAIM_CLOSED": "05-06-2013 ",
		 "ACTUAL_RTW_DATE": "05-06-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Spiller",
		 "CLAIMANT_FIRST_NAME": "Harry",
		 "CLAIM_NO": "2018-01-22-0509-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "01-19-2018 ",
		 "DATE_CLAIM_CLOSED": "02-26-2018 ",
		 "ACTUAL_RTW_DATE": "02-22-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Spiller",
		 "CLAIMANT_FIRST_NAME": "Patricia",
		 "CLAIM_NO": "2015-04-30-0444-VP-01",
		 "DURATION": 52,
		 "DISABILITY_START_DATE": "06-01-2015 ",
		 "DATE_CLAIM_CLOSED": "07-29-2015 ",
		 "ACTUAL_RTW_DATE": "07-23-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Spinner",
		 "CLAIMANT_FIRST_NAME": "Erin",
		 "CLAIM_NO": "2018-11-02-0208-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "11-26-2018 ",
		 "DATE_CLAIM_CLOSED": "01-09-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Spinola",
		 "CLAIMANT_FIRST_NAME": "Kristen",
		 "CLAIM_NO": "2013-01-23-0570-VP-01",
		 "DURATION": 199,
		 "DISABILITY_START_DATE": "01-14-2013 ",
		 "DATE_CLAIM_CLOSED": "10-07-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Srinivasan",
		 "CLAIMANT_FIRST_NAME": "Sruthi",
		 "CLAIM_NO": "2021-03-05-0296-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "04-05-2021 ",
		 "DATE_CLAIM_CLOSED": "05-24-2021 ",
		 "ACTUAL_RTW_DATE": "05-17-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Srungavarapu",
		 "CLAIMANT_FIRST_NAME": "Saraladevi",
		 "CLAIM_NO": "2021-07-15-0689-VP-01",
		 "DURATION": 74,
		 "DISABILITY_START_DATE": "08-30-2021 ",
		 "DATE_CLAIM_CLOSED": "11-18-2021 ",
		 "ACTUAL_RTW_DATE": "11-12-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Srungavarapu",
		 "CLAIMANT_FIRST_NAME": "Saraladevi",
		 "CLAIM_NO": "2019-03-27-0266-VP-01",
		 "DURATION": 73,
		 "DISABILITY_START_DATE": "04-29-2019 ",
		 "DATE_CLAIM_CLOSED": "07-11-2019 ",
		 "ACTUAL_RTW_DATE": "07-11-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "St. Clair",
		 "CLAIMANT_FIRST_NAME": "Heather",
		 "CLAIM_NO": "2022-02-03-0413-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "02-02-2022 ",
		 "DATE_CLAIM_CLOSED": "03-14-2022 ",
		 "ACTUAL_RTW_DATE": "03-09-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Staehr",
		 "CLAIMANT_FIRST_NAME": "Peter",
		 "CLAIM_NO": "2016-06-15-0433-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "06-16-2016 ",
		 "DATE_CLAIM_CLOSED": "07-22-2016 ",
		 "ACTUAL_RTW_DATE": "07-18-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Standlee",
		 "CLAIMANT_FIRST_NAME": "Angela",
		 "CLAIM_NO": "2018-12-11-0346-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "12-06-2018 ",
		 "DATE_CLAIM_CLOSED": "01-08-2019 ",
		 "ACTUAL_RTW_DATE": "12-27-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Standlee",
		 "CLAIMANT_FIRST_NAME": "Angela",
		 "CLAIM_NO": "2021-01-13-0617-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "01-11-2021 ",
		 "DATE_CLAIM_CLOSED": "03-12-2021 ",
		 "ACTUAL_RTW_DATE": "03-08-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stanley",
		 "CLAIMANT_FIRST_NAME": "Forrest",
		 "CLAIM_NO": "2018-04-25-0394-VP-01",
		 "DURATION": 129,
		 "DISABILITY_START_DATE": "05-22-2018 ",
		 "DATE_CLAIM_CLOSED": "10-04-2018 ",
		 "ACTUAL_RTW_DATE": "09-28-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Staples",
		 "CLAIMANT_FIRST_NAME": "Jeff",
		 "CLAIM_NO": "2017-06-08-0223-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "06-07-2017 ",
		 "DATE_CLAIM_CLOSED": "07-10-2017 ",
		 "ACTUAL_RTW_DATE": "06-21-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stebbing",
		 "CLAIMANT_FIRST_NAME": "Victorina",
		 "CLAIM_NO": "2013-01-16-0485-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "01-17-2013 ",
		 "DATE_CLAIM_CLOSED": "05-21-2013 ",
		 "ACTUAL_RTW_DATE": "03-20-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stebbing",
		 "CLAIMANT_FIRST_NAME": "Victorina",
		 "CLAIM_NO": "2016-05-19-0339-VP-01",
		 "DURATION": 258,
		 "DISABILITY_START_DATE": "04-06-2016 ",
		 "DATE_CLAIM_CLOSED": "12-29-2016 ",
		 "ACTUAL_RTW_DATE": "12-20-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stehula",
		 "CLAIMANT_FIRST_NAME": "Elizabeth",
		 "CLAIM_NO": "2018-10-01-0679-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "09-03-2018 ",
		 "DATE_CLAIM_CLOSED": "10-16-2018 ",
		 "ACTUAL_RTW_DATE": "10-14-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stephens",
		 "CLAIMANT_FIRST_NAME": "Leslie",
		 "CLAIM_NO": "2013-04-23-0250-VP-01",
		 "DURATION": 61,
		 "DISABILITY_START_DATE": "05-13-2013 ",
		 "DATE_CLAIM_CLOSED": "09-04-2013 ",
		 "ACTUAL_RTW_DATE": "07-13-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stephens Ii",
		 "CLAIMANT_FIRST_NAME": "Bryce",
		 "CLAIM_NO": "2015-02-13-0416-VP-01",
		 "DURATION": 0,
		 "DISABILITY_START_DATE": "02-09-2015 ",
		 "DATE_CLAIM_CLOSED": "05-26-2015 ",
		 "ACTUAL_RTW_DATE": "02-09-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Steuwer",
		 "CLAIMANT_FIRST_NAME": "Lisa",
		 "CLAIM_NO": "2022-03-24-0355-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "04-29-2022 ",
		 "DATE_CLAIM_CLOSED": "06-06-2022 ",
		 "ACTUAL_RTW_DATE": "05-30-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stevens",
		 "CLAIMANT_FIRST_NAME": "Paul",
		 "CLAIM_NO": "2015-04-01-0278-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "03-24-2015 ",
		 "DATE_CLAIM_CLOSED": "06-21-2015 ",
		 "ACTUAL_RTW_DATE": "04-20-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stevens",
		 "CLAIMANT_FIRST_NAME": "Adam",
		 "CLAIM_NO": "2020-03-14-0009-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "03-11-2020 ",
		 "DATE_CLAIM_CLOSED": "04-28-2020 ",
		 "ACTUAL_RTW_DATE": "03-23-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stevens",
		 "CLAIMANT_FIRST_NAME": "Donna",
		 "CLAIM_NO": "2013-06-10-0097-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "06-03-2013 ",
		 "DATE_CLAIM_CLOSED": "08-11-2013 ",
		 "ACTUAL_RTW_DATE": "06-25-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stevens",
		 "CLAIMANT_FIRST_NAME": "Paul",
		 "CLAIM_NO": "2018-03-30-0197-VP-01",
		 "DURATION": 140,
		 "DISABILITY_START_DATE": "03-26-2018 ",
		 "DATE_CLAIM_CLOSED": "08-17-2018 ",
		 "ACTUAL_RTW_DATE": "08-13-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stevens",
		 "CLAIMANT_FIRST_NAME": "Paul",
		 "CLAIM_NO": "2017-07-31-0239-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "07-27-2017 ",
		 "DATE_CLAIM_CLOSED": "10-18-2017 ",
		 "ACTUAL_RTW_DATE": "09-11-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stevens",
		 "CLAIMANT_FIRST_NAME": "Donna",
		 "CLAIM_NO": "2013-01-05-0189-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "12-03-2012 ",
		 "DATE_CLAIM_CLOSED": "01-23-2014 ",
		 "ACTUAL_RTW_DATE": "12-12-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stevens",
		 "CLAIMANT_FIRST_NAME": "Adam",
		 "CLAIM_NO": "2020-04-15-0764-VP-01",
		 "DURATION": 139,
		 "DISABILITY_START_DATE": "04-14-2020 ",
		 "DATE_CLAIM_CLOSED": "09-01-2020 ",
		 "ACTUAL_RTW_DATE": "08-31-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stewart",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2013-01-05-3023-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "01-20-2012 ",
		 "DATE_CLAIM_CLOSED": "10-15-2013 ",
		 "ACTUAL_RTW_DATE": "02-17-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stewart",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2019-05-22-0271-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "05-13-2019 ",
		 "DATE_CLAIM_CLOSED": "07-31-2019 ",
		 "ACTUAL_RTW_DATE": "05-27-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stewart",
		 "CLAIMANT_FIRST_NAME": "Eva",
		 "CLAIM_NO": "2019-03-06-0275-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "02-26-2019 ",
		 "DATE_CLAIM_CLOSED": "03-19-2019 ",
		 "ACTUAL_RTW_DATE": "03-06-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stewart",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2017-05-10-0354-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "05-01-2017 ",
		 "DATE_CLAIM_CLOSED": "07-17-2017 ",
		 "ACTUAL_RTW_DATE": "06-01-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stewart",
		 "CLAIMANT_FIRST_NAME": "Eva",
		 "CLAIM_NO": "2020-04-14-0614-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "04-07-2020 ",
		 "DATE_CLAIM_CLOSED": "06-08-2020 ",
		 "ACTUAL_RTW_DATE": "06-01-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stewart",
		 "CLAIMANT_FIRST_NAME": "Eva",
		 "CLAIM_NO": "2020-10-09-0586-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "10-06-2020 ",
		 "DATE_CLAIM_CLOSED": "11-09-2020 ",
		 "ACTUAL_RTW_DATE": "11-02-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stewart",
		 "CLAIMANT_FIRST_NAME": "Patricia",
		 "CLAIM_NO": "2013-06-12-0050-VP-01",
		 "DURATION": 188,
		 "DISABILITY_START_DATE": "06-11-2013 ",
		 "DATE_CLAIM_CLOSED": "12-24-2013 ",
		 "ACTUAL_RTW_DATE": "12-16-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stewart",
		 "CLAIMANT_FIRST_NAME": "Eva",
		 "CLAIM_NO": "2018-10-08-0194-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "10-01-2018 ",
		 "DATE_CLAIM_CLOSED": "10-15-2018 ",
		 "ACTUAL_RTW_DATE": "10-09-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stika",
		 "CLAIMANT_FIRST_NAME": "Candace",
		 "CLAIM_NO": "2013-02-28-0182-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "02-21-2013 ",
		 "DATE_CLAIM_CLOSED": "05-14-2013 ",
		 "ACTUAL_RTW_DATE": "03-05-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stiles",
		 "CLAIMANT_FIRST_NAME": "Kristin",
		 "CLAIM_NO": "2022-08-31-0198-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "08-29-2022 ",
		 "DATE_CLAIM_CLOSED": "09-30-2022 ",
		 "ACTUAL_RTW_DATE": "09-27-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stiles",
		 "CLAIMANT_FIRST_NAME": "Kristin",
		 "CLAIM_NO": "2013-02-06-0314-VP-01",
		 "DURATION": 116,
		 "DISABILITY_START_DATE": "02-04-2013 ",
		 "DATE_CLAIM_CLOSED": "07-31-2013 ",
		 "ACTUAL_RTW_DATE": "05-31-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stiller",
		 "CLAIMANT_FIRST_NAME": "Valerie",
		 "CLAIM_NO": "2015-03-25-0005-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "04-01-2015 ",
		 "DATE_CLAIM_CLOSED": "06-17-2015 ",
		 "ACTUAL_RTW_DATE": "05-11-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stinson",
		 "CLAIMANT_FIRST_NAME": "Janae",
		 "CLAIM_NO": "2018-01-25-0116-VP-01",
		 "DURATION": 425,
		 "DISABILITY_START_DATE": "01-15-2018 ",
		 "DATE_CLAIM_CLOSED": "02-26-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stinson",
		 "CLAIMANT_FIRST_NAME": "Janae",
		 "CLAIM_NO": "2016-07-06-0002-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "07-05-2016 ",
		 "DATE_CLAIM_CLOSED": "09-15-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stinson",
		 "CLAIMANT_FIRST_NAME": "Janae",
		 "CLAIM_NO": "2017-05-31-0520-VP-01",
		 "DURATION": 39,
		 "DISABILITY_START_DATE": "05-23-2017 ",
		 "DATE_CLAIM_CLOSED": "07-03-2017 ",
		 "ACTUAL_RTW_DATE": "07-01-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stinson",
		 "CLAIMANT_FIRST_NAME": "Deborah",
		 "CLAIM_NO": "2013-08-22-0057-VP-01",
		 "DURATION": 145,
		 "DISABILITY_START_DATE": "08-21-2013 ",
		 "DATE_CLAIM_CLOSED": "09-08-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Story",
		 "CLAIMANT_FIRST_NAME": "Rachel",
		 "CLAIM_NO": "2022-12-05-1043-VP-01",
		 "DURATION": 90,
		 "DISABILITY_START_DATE": "01-03-2023 ",
		 "DATE_CLAIM_CLOSED": "03-16-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Story",
		 "CLAIMANT_FIRST_NAME": "Rachel",
		 "CLAIM_NO": "2015-07-02-0379-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "07-20-2015 ",
		 "DATE_CLAIM_CLOSED": "10-26-2015 ",
		 "ACTUAL_RTW_DATE": "09-29-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Story",
		 "CLAIMANT_FIRST_NAME": "Rachel",
		 "CLAIM_NO": "2018-07-08-0030-VP-01",
		 "DURATION": 69,
		 "DISABILITY_START_DATE": "07-23-2018 ",
		 "DATE_CLAIM_CLOSED": "09-24-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stringfield",
		 "CLAIMANT_FIRST_NAME": "Kiana",
		 "CLAIM_NO": "2018-08-03-0590-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "08-27-2018 ",
		 "DATE_CLAIM_CLOSED": "10-08-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stringfield",
		 "CLAIMANT_FIRST_NAME": "Kiana",
		 "CLAIM_NO": "2020-05-11-0796-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "06-16-2020 ",
		 "DATE_CLAIM_CLOSED": "08-03-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Strozewski",
		 "CLAIMANT_FIRST_NAME": "Ferdinand",
		 "CLAIM_NO": "2019-05-01-0614-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "05-02-2019 ",
		 "DATE_CLAIM_CLOSED": "05-22-2019 ",
		 "ACTUAL_RTW_DATE": "05-20-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Strozewski",
		 "CLAIMANT_FIRST_NAME": "Ferdinand",
		 "CLAIM_NO": "2022-10-27-0649-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "11-08-2022 ",
		 "DATE_CLAIM_CLOSED": "12-01-2022 ",
		 "ACTUAL_RTW_DATE": "11-23-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Studebaker",
		 "CLAIMANT_FIRST_NAME": "Tia",
		 "CLAIM_NO": "2019-04-04-0172-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "03-25-2019 ",
		 "DATE_CLAIM_CLOSED": "05-28-2019 ",
		 "ACTUAL_RTW_DATE": "05-27-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Studebaker",
		 "CLAIMANT_FIRST_NAME": "Tia",
		 "CLAIM_NO": "2017-05-03-0555-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "04-28-2017 ",
		 "DATE_CLAIM_CLOSED": "05-30-2017 ",
		 "ACTUAL_RTW_DATE": "05-10-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stulz",
		 "CLAIMANT_FIRST_NAME": "Marne",
		 "CLAIM_NO": "2013-01-07-0387-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "02-04-2013 ",
		 "DATE_CLAIM_CLOSED": "11-19-2013 ",
		 "ACTUAL_RTW_DATE": "04-29-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stuppy",
		 "CLAIMANT_FIRST_NAME": "Sheri",
		 "CLAIM_NO": "2015-07-24-0404-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "07-22-2015 ",
		 "DATE_CLAIM_CLOSED": "08-31-2015 ",
		 "ACTUAL_RTW_DATE": "07-30-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Stuteville",
		 "CLAIMANT_FIRST_NAME": "Kevin",
		 "CLAIM_NO": "2014-10-03-0490-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "11-10-2014 ",
		 "DATE_CLAIM_CLOSED": "12-18-2014 ",
		 "ACTUAL_RTW_DATE": "11-24-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Su",
		 "CLAIMANT_FIRST_NAME": "Stella",
		 "CLAIM_NO": "2022-11-14-0630-VP-01",
		 "DURATION": 3709,
		 "DISABILITY_START_DATE": "01-06-2023 ",
		 "DATE_CLAIM_CLOSED": "03-12-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Suarez",
		 "CLAIMANT_FIRST_NAME": "Amada",
		 "CLAIM_NO": "2014-02-18-0027-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "02-17-2014 ",
		 "DATE_CLAIM_CLOSED": "05-31-2014 ",
		 "ACTUAL_RTW_DATE": "02-25-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Suarez",
		 "CLAIMANT_FIRST_NAME": "Amada",
		 "CLAIM_NO": "2022-03-29-0696-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "04-04-2022 ",
		 "DATE_CLAIM_CLOSED": "04-18-2022 ",
		 "ACTUAL_RTW_DATE": "04-12-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Suarez",
		 "CLAIMANT_FIRST_NAME": "Amada",
		 "CLAIM_NO": "2021-06-09-0665-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "06-07-2021 ",
		 "DATE_CLAIM_CLOSED": "09-02-2021 ",
		 "ACTUAL_RTW_DATE": "07-26-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Suarez",
		 "CLAIMANT_FIRST_NAME": "Amada",
		 "CLAIM_NO": "2022-03-08-0474-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "03-03-2022 ",
		 "DATE_CLAIM_CLOSED": "03-09-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Suk",
		 "CLAIMANT_FIRST_NAME": "Katherine",
		 "CLAIM_NO": "2016-10-19-0473-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "11-21-2016 ",
		 "DATE_CLAIM_CLOSED": "01-23-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sullivan",
		 "CLAIMANT_FIRST_NAME": "Kevin",
		 "CLAIM_NO": "2015-02-12-0446-VP-01",
		 "DURATION": 83,
		 "DISABILITY_START_DATE": "02-13-2015 ",
		 "DATE_CLAIM_CLOSED": "06-18-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sullivan",
		 "CLAIMANT_FIRST_NAME": "Mandi",
		 "CLAIM_NO": "2021-09-20-1196-VP-01",
		 "DURATION": 79,
		 "DISABILITY_START_DATE": "08-23-2021 ",
		 "DATE_CLAIM_CLOSED": "11-22-2021 ",
		 "ACTUAL_RTW_DATE": "11-10-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sullivan",
		 "CLAIMANT_FIRST_NAME": "Mandi",
		 "CLAIM_NO": "2020-08-07-0512-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "08-10-2020 ",
		 "DATE_CLAIM_CLOSED": "09-22-2020 ",
		 "ACTUAL_RTW_DATE": "09-21-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sullivan",
		 "CLAIMANT_FIRST_NAME": "Mandi",
		 "CLAIM_NO": "2013-07-19-0394-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "07-12-2013 ",
		 "DATE_CLAIM_CLOSED": "09-28-2013 ",
		 "ACTUAL_RTW_DATE": "08-02-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sullivan Thompson",
		 "CLAIMANT_FIRST_NAME": "Meghan",
		 "CLAIM_NO": "2013-01-16-0150-VP-01",
		 "DURATION": 75,
		 "DISABILITY_START_DATE": "01-21-2013 ",
		 "DATE_CLAIM_CLOSED": "05-22-2013 ",
		 "ACTUAL_RTW_DATE": "04-06-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sumaran",
		 "CLAIMANT_FIRST_NAME": "Raul",
		 "CLAIM_NO": "2015-02-18-0479-VP-01",
		 "DURATION": 25,
		 "DISABILITY_START_DATE": "03-05-2015 ",
		 "DATE_CLAIM_CLOSED": "06-11-2015 ",
		 "ACTUAL_RTW_DATE": "03-30-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Summers",
		 "CLAIMANT_FIRST_NAME": "Daniel",
		 "CLAIM_NO": "2014-01-06-0253-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "01-13-2014 ",
		 "DATE_CLAIM_CLOSED": "04-03-2014 ",
		 "ACTUAL_RTW_DATE": "03-03-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Summers",
		 "CLAIMANT_FIRST_NAME": "Daniel",
		 "CLAIM_NO": "2014-09-26-0422-VP-01",
		 "DURATION": 98,
		 "DISABILITY_START_DATE": "10-13-2014 ",
		 "DATE_CLAIM_CLOSED": "01-26-2015 ",
		 "ACTUAL_RTW_DATE": "01-19-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Sundaram",
		 "CLAIMANT_FIRST_NAME": "Anjana",
		 "CLAIM_NO": "2023-01-03-1400-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "03-01-2023 ",
		 "DATE_CLAIM_CLOSED": "04-27-2023 ",
		 "ACTUAL_RTW_DATE": "04-26-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Suriadi",
		 "CLAIMANT_FIRST_NAME": "Anna",
		 "CLAIM_NO": "2015-01-26-0294-VP-01",
		 "DURATION": 0,
		 "DISABILITY_START_DATE": "01-22-2015 ",
		 "DATE_CLAIM_CLOSED": "04-07-2015 ",
		 "ACTUAL_RTW_DATE": "01-22-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Svensson",
		 "CLAIMANT_FIRST_NAME": "Bjorn",
		 "CLAIM_NO": "2019-07-12-0696-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "07-15-2019 ",
		 "DATE_CLAIM_CLOSED": "10-02-2019 ",
		 "ACTUAL_RTW_DATE": "08-12-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Svensson",
		 "CLAIMANT_FIRST_NAME": "Bjorn",
		 "CLAIM_NO": "2020-06-10-0841-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "06-08-2020 ",
		 "DATE_CLAIM_CLOSED": "08-19-2020 ",
		 "ACTUAL_RTW_DATE": "08-17-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Swayne",
		 "CLAIMANT_FIRST_NAME": "Nancy",
		 "CLAIM_NO": "2015-09-10-0369-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "09-10-2015 ",
		 "DATE_CLAIM_CLOSED": "10-03-2015 ",
		 "ACTUAL_RTW_DATE": "09-28-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Swearingen",
		 "CLAIMANT_FIRST_NAME": "Mona",
		 "CLAIM_NO": "2017-05-26-0002-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "05-23-2017 ",
		 "DATE_CLAIM_CLOSED": "06-26-2017 ",
		 "ACTUAL_RTW_DATE": "06-12-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Swearingen",
		 "CLAIMANT_FIRST_NAME": "Mona",
		 "CLAIM_NO": "2022-10-09-0003-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "09-30-2022 ",
		 "DATE_CLAIM_CLOSED": "10-27-2022 ",
		 "ACTUAL_RTW_DATE": "10-17-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Swearingen",
		 "CLAIMANT_FIRST_NAME": "Mona",
		 "CLAIM_NO": "2020-02-17-0355-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "03-02-2020 ",
		 "DATE_CLAIM_CLOSED": "04-02-2020 ",
		 "ACTUAL_RTW_DATE": "03-30-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Swearingen",
		 "CLAIMANT_FIRST_NAME": "Mona",
		 "CLAIM_NO": "2018-05-16-0628-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "05-07-2018 ",
		 "DATE_CLAIM_CLOSED": "05-29-2018 ",
		 "ACTUAL_RTW_DATE": "05-23-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Swoboda",
		 "CLAIMANT_FIRST_NAME": "Erica",
		 "CLAIM_NO": "2021-01-07-1008-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "02-19-2021 ",
		 "DATE_CLAIM_CLOSED": "04-16-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Syed",
		 "CLAIMANT_FIRST_NAME": "Irum",
		 "CLAIM_NO": "2020-02-15-0024-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "01-31-2020 ",
		 "DATE_CLAIM_CLOSED": "04-16-2020 ",
		 "ACTUAL_RTW_DATE": "03-27-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Syed",
		 "CLAIMANT_FIRST_NAME": "Izhar",
		 "CLAIM_NO": "2021-04-05-0724-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "04-08-2021 ",
		 "DATE_CLAIM_CLOSED": "04-26-2021 ",
		 "ACTUAL_RTW_DATE": "04-19-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Szot",
		 "CLAIMANT_FIRST_NAME": "Kara",
		 "CLAIM_NO": "2017-06-28-0344-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "07-10-2017 ",
		 "DATE_CLAIM_CLOSED": "08-29-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Szuksztul",
		 "CLAIMANT_FIRST_NAME": "Halina",
		 "CLAIM_NO": "2019-04-08-0642-VP-01",
		 "DURATION": 90,
		 "DISABILITY_START_DATE": "04-08-2019 ",
		 "DATE_CLAIM_CLOSED": "07-11-2019 ",
		 "ACTUAL_RTW_DATE": "07-07-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Szuksztul",
		 "CLAIMANT_FIRST_NAME": "Halina",
		 "CLAIM_NO": "2015-02-06-0055-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "01-16-2015 ",
		 "DATE_CLAIM_CLOSED": "03-02-2015 ",
		 "ACTUAL_RTW_DATE": "02-05-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ta",
		 "CLAIMANT_FIRST_NAME": "Cynthia",
		 "CLAIM_NO": "2020-09-16-0509-VP-01",
		 "DURATION": 127,
		 "DISABILITY_START_DATE": "09-08-2020 ",
		 "DATE_CLAIM_CLOSED": "01-14-2021 ",
		 "ACTUAL_RTW_DATE": "01-13-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tabuno",
		 "CLAIMANT_FIRST_NAME": "Angelica",
		 "CLAIM_NO": "2023-02-21-0004-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "02-20-2023 ",
		 "DATE_CLAIM_CLOSED": "03-28-2023 ",
		 "ACTUAL_RTW_DATE": "03-02-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tachiki",
		 "CLAIMANT_FIRST_NAME": "Corinne",
		 "CLAIM_NO": "2016-07-15-0161-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "07-11-2016 ",
		 "DATE_CLAIM_CLOSED": "08-08-2016 ",
		 "ACTUAL_RTW_DATE": "08-01-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tagasa",
		 "CLAIMANT_FIRST_NAME": "Juan",
		 "CLAIM_NO": "2014-03-04-0073-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "03-03-2014 ",
		 "DATE_CLAIM_CLOSED": "03-17-2014 ",
		 "ACTUAL_RTW_DATE": "03-10-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tagasa",
		 "CLAIMANT_FIRST_NAME": "Juan",
		 "CLAIM_NO": "2013-05-13-0056-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "06-06-2013 ",
		 "DATE_CLAIM_CLOSED": "07-23-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tagasa",
		 "CLAIMANT_FIRST_NAME": "Juan",
		 "CLAIM_NO": "2013-08-28-0095-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "08-26-2013 ",
		 "DATE_CLAIM_CLOSED": "10-29-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tagasa",
		 "CLAIMANT_FIRST_NAME": "Juan",
		 "CLAIM_NO": "2013-05-23-0114-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "05-22-2013 ",
		 "DATE_CLAIM_CLOSED": "12-30-2013 ",
		 "ACTUAL_RTW_DATE": "07-25-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tagasa",
		 "CLAIMANT_FIRST_NAME": "Juan",
		 "CLAIM_NO": "2014-11-25-0007-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "12-04-2014 ",
		 "DATE_CLAIM_CLOSED": "01-05-2015 ",
		 "ACTUAL_RTW_DATE": "12-13-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tagasa",
		 "CLAIMANT_FIRST_NAME": "Juan",
		 "CLAIM_NO": "2013-02-14-0231-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "02-11-2013 ",
		 "DATE_CLAIM_CLOSED": "05-07-2013 ",
		 "ACTUAL_RTW_DATE": "02-18-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Taghizadeh",
		 "CLAIMANT_FIRST_NAME": "Shiva",
		 "CLAIM_NO": "2020-12-02-0690-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "12-16-2020 ",
		 "DATE_CLAIM_CLOSED": "12-30-2020 ",
		 "ACTUAL_RTW_DATE": "12-28-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Taing-Andersen",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2021-01-05-1155-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "02-15-2021 ",
		 "DATE_CLAIM_CLOSED": "04-27-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Takata",
		 "CLAIMANT_FIRST_NAME": "Josanne",
		 "CLAIM_NO": "2021-12-09-1260-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "02-04-2022 ",
		 "DATE_CLAIM_CLOSED": "04-06-2022 ",
		 "ACTUAL_RTW_DATE": "04-04-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Takemoto",
		 "CLAIMANT_FIRST_NAME": "Matt",
		 "CLAIM_NO": "2015-06-12-0778-VP-01",
		 "DURATION": 92,
		 "DISABILITY_START_DATE": "07-07-2015 ",
		 "DATE_CLAIM_CLOSED": "10-27-2015 ",
		 "ACTUAL_RTW_DATE": "10-07-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Talosig",
		 "CLAIMANT_FIRST_NAME": "Oscar",
		 "CLAIM_NO": "2013-01-05-6349-VP-01",
		 "DURATION": 1224,
		 "DISABILITY_START_DATE": "02-03-2011 ",
		 "DATE_CLAIM_CLOSED": "07-19-2014 ",
		 "ACTUAL_RTW_DATE": "06-11-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tam",
		 "CLAIMANT_FIRST_NAME": "Alice",
		 "CLAIM_NO": "2015-08-07-0256-VP-01",
		 "DURATION": 73,
		 "DISABILITY_START_DATE": "09-08-2015 ",
		 "DATE_CLAIM_CLOSED": "11-23-2015 ",
		 "ACTUAL_RTW_DATE": "11-20-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tanamas",
		 "CLAIMANT_FIRST_NAME": "Mariana",
		 "CLAIM_NO": "2015-06-23-0167-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "06-17-2015 ",
		 "DATE_CLAIM_CLOSED": "07-09-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tang",
		 "CLAIMANT_FIRST_NAME": "Anthony",
		 "CLAIM_NO": "2016-10-18-0305-VP-01",
		 "DURATION": 82,
		 "DISABILITY_START_DATE": "10-24-2016 ",
		 "DATE_CLAIM_CLOSED": "04-21-2017 ",
		 "ACTUAL_RTW_DATE": "01-14-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tang",
		 "CLAIMANT_FIRST_NAME": "Emily",
		 "CLAIM_NO": "2021-01-17-0027-VP-01",
		 "DURATION": 73,
		 "DISABILITY_START_DATE": "03-08-2021 ",
		 "DATE_CLAIM_CLOSED": "05-20-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tannenbaum",
		 "CLAIMANT_FIRST_NAME": "Glenn",
		 "CLAIM_NO": "2021-01-13-0161-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "01-25-2021 ",
		 "DATE_CLAIM_CLOSED": "03-08-2021 ",
		 "ACTUAL_RTW_DATE": "03-08-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tansey",
		 "CLAIMANT_FIRST_NAME": "Kimberly",
		 "CLAIM_NO": "2022-04-20-0898-VP-01",
		 "DURATION": 302,
		 "DISABILITY_START_DATE": "04-25-2022 ",
		 "DATE_CLAIM_CLOSED": "03-28-2023 ",
		 "ACTUAL_RTW_DATE": "02-21-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tapia",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2013-09-09-0511-VP-01",
		 "DURATION": 534,
		 "DISABILITY_START_DATE": "09-06-2013 ",
		 "DATE_CLAIM_CLOSED": "02-23-2015 ",
		 "ACTUAL_RTW_DATE": "02-22-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tarabek",
		 "CLAIMANT_FIRST_NAME": "Lori",
		 "CLAIM_NO": "2014-01-02-0212-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "01-08-2014 ",
		 "DATE_CLAIM_CLOSED": "05-31-2014 ",
		 "ACTUAL_RTW_DATE": "01-21-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tarabek",
		 "CLAIMANT_FIRST_NAME": "Lori",
		 "CLAIM_NO": "2017-03-10-0335-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "04-20-2017 ",
		 "DATE_CLAIM_CLOSED": "06-06-2017 ",
		 "ACTUAL_RTW_DATE": "06-02-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tarabek",
		 "CLAIMANT_FIRST_NAME": "Lori",
		 "CLAIM_NO": "2015-11-17-0260-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "12-14-2015 ",
		 "DATE_CLAIM_CLOSED": "01-27-2016 ",
		 "ACTUAL_RTW_DATE": "01-18-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tarabek",
		 "CLAIMANT_FIRST_NAME": "Lori",
		 "CLAIM_NO": "2022-10-28-0480-VP-01",
		 "DURATION": 178,
		 "DISABILITY_START_DATE": "10-31-2022 ",
		 "DATE_CLAIM_CLOSED": "05-01-2023 ",
		 "ACTUAL_RTW_DATE": "04-27-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tarious",
		 "CLAIMANT_FIRST_NAME": "Edna",
		 "CLAIM_NO": "2019-07-02-0748-VP-01",
		 "DURATION": 221,
		 "DISABILITY_START_DATE": "07-26-2019 ",
		 "DATE_CLAIM_CLOSED": "04-09-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tarr",
		 "CLAIMANT_FIRST_NAME": "Pamela",
		 "CLAIM_NO": "2014-02-04-0234-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "03-03-2014 ",
		 "DATE_CLAIM_CLOSED": "06-09-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tasoy",
		 "CLAIMANT_FIRST_NAME": "Dannylyn",
		 "CLAIM_NO": "2013-03-19-0403-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "03-25-2013 ",
		 "DATE_CLAIM_CLOSED": "07-17-2013 ",
		 "ACTUAL_RTW_DATE": "06-10-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tasoy",
		 "CLAIMANT_FIRST_NAME": "Dannylyn",
		 "CLAIM_NO": "2017-10-19-0251-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "11-07-2017 ",
		 "DATE_CLAIM_CLOSED": "11-27-2017 ",
		 "ACTUAL_RTW_DATE": "11-27-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tasoy",
		 "CLAIMANT_FIRST_NAME": "Dannylyn",
		 "CLAIM_NO": "2019-06-25-0332-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "07-08-2019 ",
		 "DATE_CLAIM_CLOSED": "08-29-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tatavarty",
		 "CLAIMANT_FIRST_NAME": "Sai Sriharshada",
		 "CLAIM_NO": "2015-12-02-0517-VP-01",
		 "DURATION": 51,
		 "DISABILITY_START_DATE": "01-04-2016 ",
		 "DATE_CLAIM_CLOSED": "02-24-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tatavarty",
		 "CLAIMANT_FIRST_NAME": "Sai Sriharshada",
		 "CLAIM_NO": "2020-05-18-0350-VP-01",
		 "DURATION": 148,
		 "DISABILITY_START_DATE": "05-18-2020 ",
		 "DATE_CLAIM_CLOSED": "10-13-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tatum",
		 "CLAIMANT_FIRST_NAME": "Jim",
		 "CLAIM_NO": "2013-10-10-0435-VP-01",
		 "DURATION": 95,
		 "DISABILITY_START_DATE": "10-07-2013 ",
		 "DATE_CLAIM_CLOSED": "01-23-2014 ",
		 "ACTUAL_RTW_DATE": "01-10-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tatum",
		 "CLAIMANT_FIRST_NAME": "Dolores",
		 "CLAIM_NO": "2018-06-21-0002-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "06-14-2018 ",
		 "DATE_CLAIM_CLOSED": "10-12-2018 ",
		 "ACTUAL_RTW_DATE": "06-28-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tayahua",
		 "CLAIMANT_FIRST_NAME": "Leah",
		 "CLAIM_NO": "2020-01-02-0419-VP-01",
		 "DURATION": 44,
		 "DISABILITY_START_DATE": "01-07-2020 ",
		 "DATE_CLAIM_CLOSED": "05-29-2020 ",
		 "ACTUAL_RTW_DATE": "02-20-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tayahua",
		 "CLAIMANT_FIRST_NAME": "Leah",
		 "CLAIM_NO": "2018-09-18-0695-VP-01",
		 "DURATION": 306,
		 "DISABILITY_START_DATE": "09-21-2018 ",
		 "DATE_CLAIM_CLOSED": "09-27-2019 ",
		 "ACTUAL_RTW_DATE": "07-24-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tayan",
		 "CLAIMANT_FIRST_NAME": "Amy",
		 "CLAIM_NO": "2013-07-29-0284-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "08-01-2013 ",
		 "DATE_CLAIM_CLOSED": "09-12-2013 ",
		 "ACTUAL_RTW_DATE": "09-12-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Taylor",
		 "CLAIMANT_FIRST_NAME": "Keri",
		 "CLAIM_NO": "2013-04-15-0348-VP-01",
		 "DURATION": 44,
		 "DISABILITY_START_DATE": "05-01-2013 ",
		 "DATE_CLAIM_CLOSED": "07-17-2013 ",
		 "ACTUAL_RTW_DATE": "06-14-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Taylor",
		 "CLAIMANT_FIRST_NAME": "Lindsay",
		 "CLAIM_NO": "2018-11-16-0502-VP-01",
		 "DURATION": 53,
		 "DISABILITY_START_DATE": "11-09-2018 ",
		 "DATE_CLAIM_CLOSED": "01-08-2019 ",
		 "ACTUAL_RTW_DATE": "01-01-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Taylor",
		 "CLAIMANT_FIRST_NAME": "Roger",
		 "CLAIM_NO": "2022-09-12-0418-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "09-08-2022 ",
		 "DATE_CLAIM_CLOSED": "10-12-2022 ",
		 "ACTUAL_RTW_DATE": "09-19-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Taylor",
		 "CLAIMANT_FIRST_NAME": "Cassandra",
		 "CLAIM_NO": "2021-03-11-0113-VP-01",
		 "DURATION": 179,
		 "DISABILITY_START_DATE": "03-01-2021 ",
		 "DATE_CLAIM_CLOSED": "08-31-2021 ",
		 "ACTUAL_RTW_DATE": "08-27-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Taylor-Morelli",
		 "CLAIMANT_FIRST_NAME": "Leah",
		 "CLAIM_NO": "2016-03-14-0648-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "03-21-2016 ",
		 "DATE_CLAIM_CLOSED": "06-03-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Teems",
		 "CLAIMANT_FIRST_NAME": "Kevin",
		 "CLAIM_NO": "2019-07-08-0519-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "08-05-2019 ",
		 "DATE_CLAIM_CLOSED": "11-15-2019 ",
		 "ACTUAL_RTW_DATE": "10-28-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Telep",
		 "CLAIMANT_FIRST_NAME": "Danielle",
		 "CLAIM_NO": "2013-01-05-0008-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "01-10-2013 ",
		 "DATE_CLAIM_CLOSED": "02-21-2013 ",
		 "ACTUAL_RTW_DATE": "01-23-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tellado",
		 "CLAIMANT_FIRST_NAME": "Jessica",
		 "CLAIM_NO": "2015-06-12-0987-VP-01",
		 "DURATION": 38,
		 "DISABILITY_START_DATE": "06-12-2015 ",
		 "DATE_CLAIM_CLOSED": "08-12-2015 ",
		 "ACTUAL_RTW_DATE": "07-20-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tellado",
		 "CLAIMANT_FIRST_NAME": "Jessica",
		 "CLAIM_NO": "2014-02-11-0302-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "02-07-2014 ",
		 "DATE_CLAIM_CLOSED": "09-08-2014 ",
		 "ACTUAL_RTW_DATE": "02-17-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tembe",
		 "CLAIMANT_FIRST_NAME": "Vrushali",
		 "CLAIM_NO": "2015-07-15-0475-VP-01",
		 "DURATION": 122,
		 "DISABILITY_START_DATE": "08-04-2015 ",
		 "DATE_CLAIM_CLOSED": "12-11-2015 ",
		 "ACTUAL_RTW_DATE": "12-04-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Temple",
		 "CLAIMANT_FIRST_NAME": "Barbara",
		 "CLAIM_NO": "2019-03-28-0405-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "03-28-2019 ",
		 "DATE_CLAIM_CLOSED": "05-30-2019 ",
		 "ACTUAL_RTW_DATE": "05-29-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Temple",
		 "CLAIMANT_FIRST_NAME": "Barbara",
		 "CLAIM_NO": "2019-09-16-0307-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "09-27-2019 ",
		 "DATE_CLAIM_CLOSED": "12-04-2019 ",
		 "ACTUAL_RTW_DATE": "11-25-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Templeton",
		 "CLAIMANT_FIRST_NAME": "Danell",
		 "CLAIM_NO": "2014-11-19-0047-VP-01",
		 "DURATION": 289,
		 "DISABILITY_START_DATE": "11-17-2014 ",
		 "DATE_CLAIM_CLOSED": "12-04-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Teoh",
		 "CLAIMANT_FIRST_NAME": "Irene",
		 "CLAIM_NO": "2013-08-15-0469-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "09-11-2013 ",
		 "DATE_CLAIM_CLOSED": "10-07-2013 ",
		 "ACTUAL_RTW_DATE": "10-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Terrizzano",
		 "CLAIMANT_FIRST_NAME": "Alyssa",
		 "CLAIM_NO": "2013-02-21-0421-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "02-22-2013 ",
		 "DATE_CLAIM_CLOSED": "04-29-2013 ",
		 "ACTUAL_RTW_DATE": "03-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thieu",
		 "CLAIMANT_FIRST_NAME": "Nga",
		 "CLAIM_NO": "2016-01-19-0587-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "01-19-2016 ",
		 "DATE_CLAIM_CLOSED": "02-23-2016 ",
		 "ACTUAL_RTW_DATE": "02-22-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thoma",
		 "CLAIMANT_FIRST_NAME": "Elizabeth",
		 "CLAIM_NO": "2017-09-20-0630-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "10-30-2017 ",
		 "DATE_CLAIM_CLOSED": "12-13-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thoma",
		 "CLAIMANT_FIRST_NAME": "Elizabeth",
		 "CLAIM_NO": "2014-11-11-0193-VP-01",
		 "DURATION": 168,
		 "DISABILITY_START_DATE": "11-24-2014 ",
		 "DATE_CLAIM_CLOSED": "01-05-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thoma",
		 "CLAIMANT_FIRST_NAME": "Elizabeth",
		 "CLAIM_NO": "2021-03-22-1620-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "04-29-2021 ",
		 "DATE_CLAIM_CLOSED": "06-14-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thoma",
		 "CLAIMANT_FIRST_NAME": "Elizabeth",
		 "CLAIM_NO": "2015-12-02-0333-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "01-07-2016 ",
		 "DATE_CLAIM_CLOSED": "02-23-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thomas",
		 "CLAIMANT_FIRST_NAME": "Theresa",
		 "CLAIM_NO": "2016-12-01-0368-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "11-30-2016 ",
		 "DATE_CLAIM_CLOSED": "01-09-2017 ",
		 "ACTUAL_RTW_DATE": "12-14-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thomas",
		 "CLAIMANT_FIRST_NAME": "Theresa",
		 "CLAIM_NO": "2020-01-06-0605-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "01-02-2020 ",
		 "DATE_CLAIM_CLOSED": "02-18-2020 ",
		 "ACTUAL_RTW_DATE": "02-17-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thomas",
		 "CLAIMANT_FIRST_NAME": "Theresa",
		 "CLAIM_NO": "2018-05-22-0716-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "05-17-2018 ",
		 "DATE_CLAIM_CLOSED": "06-05-2018 ",
		 "ACTUAL_RTW_DATE": "05-29-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thomas",
		 "CLAIMANT_FIRST_NAME": "Theresa",
		 "CLAIM_NO": "2019-10-24-0152-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "10-18-2019 ",
		 "DATE_CLAIM_CLOSED": "11-14-2019 ",
		 "ACTUAL_RTW_DATE": "11-04-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thomas",
		 "CLAIMANT_FIRST_NAME": "Theresa",
		 "CLAIM_NO": "2017-01-23-0697-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "01-24-2017 ",
		 "DATE_CLAIM_CLOSED": "03-06-2017 ",
		 "ACTUAL_RTW_DATE": "02-27-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thomas",
		 "CLAIMANT_FIRST_NAME": "Ronnie",
		 "CLAIM_NO": "2018-03-09-0374-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "03-07-2018 ",
		 "DATE_CLAIM_CLOSED": "03-28-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thomas",
		 "CLAIMANT_FIRST_NAME": "Theresa",
		 "CLAIM_NO": "2014-02-21-0232-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "02-13-2014 ",
		 "DATE_CLAIM_CLOSED": "05-31-2014 ",
		 "ACTUAL_RTW_DATE": "02-24-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thomas",
		 "CLAIMANT_FIRST_NAME": "Terrell",
		 "CLAIM_NO": "2015-07-10-0202-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "07-01-2015 ",
		 "DATE_CLAIM_CLOSED": "07-30-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thomas",
		 "CLAIMANT_FIRST_NAME": "Theresa",
		 "CLAIM_NO": "2015-12-22-0490-VP-01",
		 "DURATION": 6,
		 "DISABILITY_START_DATE": "01-19-2016 ",
		 "DATE_CLAIM_CLOSED": "01-27-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thomas",
		 "CLAIMANT_FIRST_NAME": "Rebecca",
		 "CLAIM_NO": "2022-03-07-0981-VP-01",
		 "DURATION": 143,
		 "DISABILITY_START_DATE": "03-15-2022 ",
		 "DATE_CLAIM_CLOSED": "08-09-2022 ",
		 "ACTUAL_RTW_DATE": "08-05-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thomas",
		 "CLAIMANT_FIRST_NAME": "William",
		 "CLAIM_NO": "2019-12-13-0419-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "01-10-2020 ",
		 "DATE_CLAIM_CLOSED": "01-21-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thomas",
		 "CLAIMANT_FIRST_NAME": "Theresa",
		 "CLAIM_NO": "2017-10-23-0538-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "10-16-2017 ",
		 "DATE_CLAIM_CLOSED": "10-27-2017 ",
		 "ACTUAL_RTW_DATE": "10-23-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thomas",
		 "CLAIMANT_FIRST_NAME": "Doriona",
		 "CLAIM_NO": "2021-08-17-0556-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "08-17-2021 ",
		 "DATE_CLAIM_CLOSED": "09-22-2021 ",
		 "ACTUAL_RTW_DATE": "08-25-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thomasco",
		 "CLAIMANT_FIRST_NAME": "Tiffany",
		 "CLAIM_NO": "2022-01-11-1550-VP-01",
		 "DURATION": 90,
		 "DISABILITY_START_DATE": "02-01-2022 ",
		 "DATE_CLAIM_CLOSED": "05-04-2022 ",
		 "ACTUAL_RTW_DATE": "05-02-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thompson",
		 "CLAIMANT_FIRST_NAME": "Kenneth",
		 "CLAIM_NO": "2013-01-28-0093-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "01-25-2013 ",
		 "DATE_CLAIM_CLOSED": "04-26-2013 ",
		 "ACTUAL_RTW_DATE": "02-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thompson",
		 "CLAIMANT_FIRST_NAME": "Jane",
		 "CLAIM_NO": "2014-08-06-0512-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "08-25-2014 ",
		 "DATE_CLAIM_CLOSED": "10-20-2014 ",
		 "ACTUAL_RTW_DATE": "10-07-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thompson",
		 "CLAIMANT_FIRST_NAME": "Brenton",
		 "CLAIM_NO": "2021-01-28-1008-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "01-21-2021 ",
		 "DATE_CLAIM_CLOSED": "04-01-2021 ",
		 "ACTUAL_RTW_DATE": "02-01-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thompson",
		 "CLAIMANT_FIRST_NAME": "Brenton",
		 "CLAIM_NO": "2020-07-28-0774-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "07-27-2020 ",
		 "DATE_CLAIM_CLOSED": "07-31-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thompson",
		 "CLAIMANT_FIRST_NAME": "Sophie",
		 "CLAIM_NO": "2013-06-06-0413-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "06-06-2013 ",
		 "DATE_CLAIM_CLOSED": "08-10-2013 ",
		 "ACTUAL_RTW_DATE": "06-17-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thompson",
		 "CLAIMANT_FIRST_NAME": "Brenton",
		 "CLAIM_NO": "2020-05-19-0371-VP-01",
		 "DURATION": 25,
		 "DISABILITY_START_DATE": "05-14-2020 ",
		 "DATE_CLAIM_CLOSED": "06-10-2020 ",
		 "ACTUAL_RTW_DATE": "06-08-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thompson",
		 "CLAIMANT_FIRST_NAME": "Sophary",
		 "CLAIM_NO": "2020-10-27-0745-VP-01",
		 "DURATION": 73,
		 "DISABILITY_START_DATE": "10-27-2020 ",
		 "DATE_CLAIM_CLOSED": "01-12-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thompson",
		 "CLAIMANT_FIRST_NAME": "Brenton",
		 "CLAIM_NO": "2021-02-26-0795-VP-01",
		 "DURATION": 288,
		 "DISABILITY_START_DATE": "02-25-2021 ",
		 "DATE_CLAIM_CLOSED": "12-29-2021 ",
		 "ACTUAL_RTW_DATE": "12-10-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Thongsaone",
		 "CLAIMANT_FIRST_NAME": "Lithsamay",
		 "CLAIM_NO": "2015-01-09-0492-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "02-02-2015 ",
		 "DATE_CLAIM_CLOSED": "06-17-2015 ",
		 "ACTUAL_RTW_DATE": "03-09-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Timbol",
		 "CLAIMANT_FIRST_NAME": "Jhoanna",
		 "CLAIM_NO": "2013-01-05-0179-VP-01",
		 "DURATION": 80,
		 "DISABILITY_START_DATE": "12-06-2012 ",
		 "DATE_CLAIM_CLOSED": "04-01-2013 ",
		 "ACTUAL_RTW_DATE": "02-24-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tivey",
		 "CLAIMANT_FIRST_NAME": "Kimberly",
		 "CLAIM_NO": "2017-11-03-0528-VP-01",
		 "DURATION": 44,
		 "DISABILITY_START_DATE": "11-28-2017 ",
		 "DATE_CLAIM_CLOSED": "01-16-2018 ",
		 "ACTUAL_RTW_DATE": "01-11-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "To",
		 "CLAIMANT_FIRST_NAME": "Hien",
		 "CLAIM_NO": "2018-01-30-0452-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "01-23-2018 ",
		 "DATE_CLAIM_CLOSED": "02-05-2018 ",
		 "ACTUAL_RTW_DATE": "02-01-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Todd",
		 "CLAIMANT_FIRST_NAME": "Rubina",
		 "CLAIM_NO": "2022-03-17-0826-VP-01",
		 "DURATION": 120,
		 "DISABILITY_START_DATE": "04-14-2022 ",
		 "DATE_CLAIM_CLOSED": "07-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Todd",
		 "CLAIMANT_FIRST_NAME": "Rubina",
		 "CLAIM_NO": "2020-06-25-0854-VP-01",
		 "DURATION": 77,
		 "DISABILITY_START_DATE": "07-27-2020 ",
		 "DATE_CLAIM_CLOSED": "10-12-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tokarski",
		 "CLAIMANT_FIRST_NAME": "Stephen",
		 "CLAIM_NO": "2020-03-05-0416-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "03-09-2020 ",
		 "DATE_CLAIM_CLOSED": "04-02-2020 ",
		 "ACTUAL_RTW_DATE": "03-30-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Toledo",
		 "CLAIMANT_FIRST_NAME": "Berith",
		 "CLAIM_NO": "2020-02-03-0327-VP-01",
		 "DURATION": 818,
		 "DISABILITY_START_DATE": "01-22-2020 ",
		 "DATE_CLAIM_CLOSED": "04-22-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Toledo",
		 "CLAIMANT_FIRST_NAME": "Bonifacio",
		 "CLAIM_NO": "2014-05-06-0471-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "05-05-2014 ",
		 "DATE_CLAIM_CLOSED": "07-26-2014 ",
		 "ACTUAL_RTW_DATE": "07-15-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tolentino",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2018-02-21-0763-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "02-23-2018 ",
		 "DATE_CLAIM_CLOSED": "03-23-2018 ",
		 "ACTUAL_RTW_DATE": "03-19-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tolentino",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2020-10-20-0355-VP-01",
		 "DURATION": 102,
		 "DISABILITY_START_DATE": "10-15-2020 ",
		 "DATE_CLAIM_CLOSED": "01-26-2021 ",
		 "ACTUAL_RTW_DATE": "01-25-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tomkoria",
		 "CLAIMANT_FIRST_NAME": "Sarika",
		 "CLAIM_NO": "2016-01-06-0452-VP-01",
		 "DURATION": 55,
		 "DISABILITY_START_DATE": "01-28-2016 ",
		 "DATE_CLAIM_CLOSED": "03-23-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tomson",
		 "CLAIMANT_FIRST_NAME": "Shelly",
		 "CLAIM_NO": "2022-06-24-0294-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "08-22-2022 ",
		 "DATE_CLAIM_CLOSED": "07-25-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tong",
		 "CLAIMANT_FIRST_NAME": "Phuong",
		 "CLAIM_NO": "2016-11-01-0176-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "11-03-2016 ",
		 "DATE_CLAIM_CLOSED": "12-12-2016 ",
		 "ACTUAL_RTW_DATE": "12-05-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tong",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2021-11-05-0340-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "11-01-2021 ",
		 "DATE_CLAIM_CLOSED": "12-13-2021 ",
		 "ACTUAL_RTW_DATE": "12-13-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tong Mak",
		 "CLAIMANT_FIRST_NAME": "Kitty",
		 "CLAIM_NO": "2013-06-06-0053-VP-01",
		 "DURATION": 192,
		 "DISABILITY_START_DATE": "06-04-2013 ",
		 "DATE_CLAIM_CLOSED": "12-24-2013 ",
		 "ACTUAL_RTW_DATE": "12-13-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tonolete",
		 "CLAIMANT_FIRST_NAME": "Jenny",
		 "CLAIM_NO": "2021-06-10-0672-VP-01",
		 "DURATION": 209,
		 "DISABILITY_START_DATE": "06-08-2021 ",
		 "DATE_CLAIM_CLOSED": "01-05-2022 ",
		 "ACTUAL_RTW_DATE": "01-03-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tonolete",
		 "CLAIMANT_FIRST_NAME": "Jenny",
		 "CLAIM_NO": "2021-05-05-0757-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "05-05-2021 ",
		 "DATE_CLAIM_CLOSED": "06-02-2021 ",
		 "ACTUAL_RTW_DATE": "05-20-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tonolete",
		 "CLAIMANT_FIRST_NAME": "Jenny",
		 "CLAIM_NO": "2014-08-27-0368-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "08-27-2014 ",
		 "DATE_CLAIM_CLOSED": "09-11-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tonolete",
		 "CLAIMANT_FIRST_NAME": "Jenny",
		 "CLAIM_NO": "2021-02-18-0328-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "02-17-2021 ",
		 "DATE_CLAIM_CLOSED": "04-05-2021 ",
		 "ACTUAL_RTW_DATE": "03-31-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Topic",
		 "CLAIMANT_FIRST_NAME": "Jessie",
		 "CLAIM_NO": "2016-01-19-0107-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "01-20-2016 ",
		 "DATE_CLAIM_CLOSED": "03-08-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Topic",
		 "CLAIMANT_FIRST_NAME": "Jessie",
		 "CLAIM_NO": "2018-10-17-0565-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "11-12-2018 ",
		 "DATE_CLAIM_CLOSED": "01-04-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Torrefranca",
		 "CLAIMANT_FIRST_NAME": "Gayle",
		 "CLAIM_NO": "2013-01-05-1276-VP-01",
		 "DURATION": 181,
		 "DISABILITY_START_DATE": "08-08-2012 ",
		 "DATE_CLAIM_CLOSED": "02-09-2013 ",
		 "ACTUAL_RTW_DATE": "02-05-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Torrence",
		 "CLAIMANT_FIRST_NAME": "Gail",
		 "CLAIM_NO": "2018-09-06-0338-VP-01",
		 "DURATION": 142,
		 "DISABILITY_START_DATE": "09-04-2018 ",
		 "DATE_CLAIM_CLOSED": "01-28-2019 ",
		 "ACTUAL_RTW_DATE": "01-24-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Torres",
		 "CLAIMANT_FIRST_NAME": "Aureliano",
		 "CLAIM_NO": "2017-02-21-0034-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "02-13-2017 ",
		 "DATE_CLAIM_CLOSED": "03-09-2017 ",
		 "ACTUAL_RTW_DATE": "02-22-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Torres",
		 "CLAIMANT_FIRST_NAME": "Irene",
		 "CLAIM_NO": "2014-08-08-0373-VP-01",
		 "DURATION": 234,
		 "DISABILITY_START_DATE": "07-11-2014 ",
		 "DATE_CLAIM_CLOSED": "03-16-2015 ",
		 "ACTUAL_RTW_DATE": "03-02-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Torres",
		 "CLAIMANT_FIRST_NAME": "Margarita",
		 "CLAIM_NO": "2013-01-05-0652-VP-01",
		 "DURATION": 92,
		 "DISABILITY_START_DATE": "10-02-2012 ",
		 "DATE_CLAIM_CLOSED": "01-07-2013 ",
		 "ACTUAL_RTW_DATE": "01-02-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Torres",
		 "CLAIMANT_FIRST_NAME": "Maricel",
		 "CLAIM_NO": "2021-04-02-0341-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "04-05-2021 ",
		 "DATE_CLAIM_CLOSED": "05-21-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Torres",
		 "CLAIMANT_FIRST_NAME": "Aymme",
		 "CLAIM_NO": "2021-01-12-0236-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "01-25-2021 ",
		 "DATE_CLAIM_CLOSED": "03-12-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Torres",
		 "CLAIMANT_FIRST_NAME": "Rosa",
		 "CLAIM_NO": "2021-10-11-0509-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "10-18-2021 ",
		 "DATE_CLAIM_CLOSED": "12-08-2021 ",
		 "ACTUAL_RTW_DATE": "12-06-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Torres",
		 "CLAIMANT_FIRST_NAME": "Irene",
		 "CLAIM_NO": "2018-04-09-0272-VP-01",
		 "DURATION": 80,
		 "DISABILITY_START_DATE": "04-06-2018 ",
		 "DATE_CLAIM_CLOSED": "06-25-2018 ",
		 "ACTUAL_RTW_DATE": "06-25-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Torres",
		 "CLAIMANT_FIRST_NAME": "Irene",
		 "CLAIM_NO": "2017-09-14-0270-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "09-11-2017 ",
		 "DATE_CLAIM_CLOSED": "09-28-2017 ",
		 "ACTUAL_RTW_DATE": "09-19-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Torres",
		 "CLAIMANT_FIRST_NAME": "Rosa",
		 "CLAIM_NO": "2019-02-26-0791-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "03-27-2019 ",
		 "DATE_CLAIM_CLOSED": "06-04-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Torres",
		 "CLAIMANT_FIRST_NAME": "Rosa",
		 "CLAIM_NO": "2017-10-06-0468-VP-01",
		 "DURATION": 112,
		 "DISABILITY_START_DATE": "11-06-2017 ",
		 "DATE_CLAIM_CLOSED": "01-23-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Torres",
		 "CLAIMANT_FIRST_NAME": "Anthony",
		 "CLAIM_NO": "2023-01-03-1134-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "01-19-2023 ",
		 "DATE_CLAIM_CLOSED": "02-17-2023 ",
		 "ACTUAL_RTW_DATE": "02-07-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Torres",
		 "CLAIMANT_FIRST_NAME": "Angelica",
		 "CLAIM_NO": "2017-11-27-0743-VP-01",
		 "DURATION": 94,
		 "DISABILITY_START_DATE": "11-27-2017 ",
		 "DATE_CLAIM_CLOSED": "03-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Torres",
		 "CLAIMANT_FIRST_NAME": "Yvette",
		 "CLAIM_NO": "2013-04-24-0365-VP-01",
		 "DURATION": 147,
		 "DISABILITY_START_DATE": "04-22-2013 ",
		 "DATE_CLAIM_CLOSED": "10-15-2013 ",
		 "ACTUAL_RTW_DATE": "09-16-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Torres Lopez",
		 "CLAIMANT_FIRST_NAME": "Diana",
		 "CLAIM_NO": "2015-09-25-0089-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "09-22-2015 ",
		 "DATE_CLAIM_CLOSED": "10-12-2015 ",
		 "ACTUAL_RTW_DATE": "10-05-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Torres Reyes",
		 "CLAIMANT_FIRST_NAME": "Jesus",
		 "CLAIM_NO": "2013-12-02-0217-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "12-02-2013 ",
		 "DATE_CLAIM_CLOSED": "06-21-2014 ",
		 "ACTUAL_RTW_DATE": "02-10-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Torres-Fletcher",
		 "CLAIMANT_FIRST_NAME": "Christina",
		 "CLAIM_NO": "2021-08-19-0397-VP-01",
		 "DURATION": 271,
		 "DISABILITY_START_DATE": "09-08-2021 ",
		 "DATE_CLAIM_CLOSED": "06-07-2022 ",
		 "ACTUAL_RTW_DATE": "06-06-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Torrico De Trujillo",
		 "CLAIMANT_FIRST_NAME": "Elizabeth",
		 "CLAIM_NO": "2020-12-21-0823-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "12-08-2020 ",
		 "DATE_CLAIM_CLOSED": "01-20-2021 ",
		 "ACTUAL_RTW_DATE": "01-19-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tortorige",
		 "CLAIMANT_FIRST_NAME": "Rhianna",
		 "CLAIM_NO": "2013-02-19-0374-VP-01",
		 "DURATION": 83,
		 "DISABILITY_START_DATE": "03-15-2013 ",
		 "DATE_CLAIM_CLOSED": "05-07-2013 ",
		 "ACTUAL_RTW_DATE": "06-06-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Touth",
		 "CLAIMANT_FIRST_NAME": "Ravid",
		 "CLAIM_NO": "2014-02-20-0544-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "02-24-2014 ",
		 "DATE_CLAIM_CLOSED": "06-02-2014 ",
		 "ACTUAL_RTW_DATE": "03-11-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tran",
		 "CLAIMANT_FIRST_NAME": "Nghia",
		 "CLAIM_NO": "2019-12-30-1022-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "01-27-2020 ",
		 "DATE_CLAIM_CLOSED": "12-31-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tran",
		 "CLAIMANT_FIRST_NAME": "Camvan",
		 "CLAIM_NO": "2020-01-30-0009-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "03-09-2020 ",
		 "DATE_CLAIM_CLOSED": "03-25-2020 ",
		 "ACTUAL_RTW_DATE": "03-23-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tran",
		 "CLAIMANT_FIRST_NAME": "Phung",
		 "CLAIM_NO": "2020-02-11-0529-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "01-30-2020 ",
		 "DATE_CLAIM_CLOSED": "04-30-2020 ",
		 "ACTUAL_RTW_DATE": "04-30-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tran",
		 "CLAIMANT_FIRST_NAME": "Melody",
		 "CLAIM_NO": "2019-10-24-0654-VP-01",
		 "DURATION": 137,
		 "DISABILITY_START_DATE": "10-24-2019 ",
		 "DATE_CLAIM_CLOSED": "03-12-2020 ",
		 "ACTUAL_RTW_DATE": "03-09-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tran",
		 "CLAIMANT_FIRST_NAME": "Nghia",
		 "CLAIM_NO": "2019-08-19-0674-VP-01",
		 "DURATION": 280,
		 "DISABILITY_START_DATE": "09-16-2019 ",
		 "DATE_CLAIM_CLOSED": "06-24-2020 ",
		 "ACTUAL_RTW_DATE": "06-22-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tran",
		 "CLAIMANT_FIRST_NAME": "Loan",
		 "CLAIM_NO": "2021-11-18-1005-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "11-29-2021 ",
		 "DATE_CLAIM_CLOSED": "01-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tran",
		 "CLAIMANT_FIRST_NAME": "Thuy",
		 "CLAIM_NO": "2022-01-10-1007-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "01-10-2022 ",
		 "DATE_CLAIM_CLOSED": "02-02-2022 ",
		 "ACTUAL_RTW_DATE": "01-31-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tran",
		 "CLAIMANT_FIRST_NAME": "Jennie",
		 "CLAIM_NO": "2015-11-30-0352-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "12-11-2015 ",
		 "DATE_CLAIM_CLOSED": "12-28-2015 ",
		 "ACTUAL_RTW_DATE": "12-21-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tran",
		 "CLAIMANT_FIRST_NAME": "Le Thanh",
		 "CLAIM_NO": "2023-01-04-0174-VP-01",
		 "DURATION": 209,
		 "DISABILITY_START_DATE": "01-03-2023 ",
		 "DATE_CLAIM_CLOSED": "04-18-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tran",
		 "CLAIMANT_FIRST_NAME": "Nhung",
		 "CLAIM_NO": "2014-09-29-0147-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "09-22-2014 ",
		 "DATE_CLAIM_CLOSED": "12-08-2014 ",
		 "ACTUAL_RTW_DATE": "10-08-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tran",
		 "CLAIMANT_FIRST_NAME": "Kelley",
		 "CLAIM_NO": "2021-10-08-0789-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "11-12-2021 ",
		 "DATE_CLAIM_CLOSED": "01-25-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tran",
		 "CLAIMANT_FIRST_NAME": "Melody",
		 "CLAIM_NO": "2020-09-01-0834-VP-01",
		 "DURATION": 101,
		 "DISABILITY_START_DATE": "08-24-2020 ",
		 "DATE_CLAIM_CLOSED": "12-09-2020 ",
		 "ACTUAL_RTW_DATE": "12-03-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tran",
		 "CLAIMANT_FIRST_NAME": "Tuan",
		 "CLAIM_NO": "2014-10-20-0566-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "10-22-2014 ",
		 "DATE_CLAIM_CLOSED": "12-08-2014 ",
		 "ACTUAL_RTW_DATE": "11-24-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tran",
		 "CLAIMANT_FIRST_NAME": "Hung",
		 "CLAIM_NO": "2014-05-19-0418-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "05-20-2014 ",
		 "DATE_CLAIM_CLOSED": "12-09-2014 ",
		 "ACTUAL_RTW_DATE": "06-23-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tran",
		 "CLAIMANT_FIRST_NAME": "Dung",
		 "CLAIM_NO": "2020-07-23-0383-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "07-31-2020 ",
		 "DATE_CLAIM_CLOSED": "09-17-2020 ",
		 "ACTUAL_RTW_DATE": "09-14-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tran",
		 "CLAIMANT_FIRST_NAME": "Phung",
		 "CLAIM_NO": "2022-05-04-0864-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "05-03-2022 ",
		 "DATE_CLAIM_CLOSED": "05-24-2022 ",
		 "ACTUAL_RTW_DATE": "05-17-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tran",
		 "CLAIMANT_FIRST_NAME": "Denise",
		 "CLAIM_NO": "2016-09-21-0158-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "10-03-2016 ",
		 "DATE_CLAIM_CLOSED": "11-28-2016 ",
		 "ACTUAL_RTW_DATE": "11-28-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tran",
		 "CLAIMANT_FIRST_NAME": "Kelley",
		 "CLAIM_NO": "2021-06-08-0884-VP-01",
		 "DURATION": 53,
		 "DISABILITY_START_DATE": "06-10-2021 ",
		 "DATE_CLAIM_CLOSED": "08-06-2021 ",
		 "ACTUAL_RTW_DATE": "08-02-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tran",
		 "CLAIMANT_FIRST_NAME": "Peter",
		 "CLAIM_NO": "2015-03-31-0009-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "03-23-2015 ",
		 "DATE_CLAIM_CLOSED": "04-23-2015 ",
		 "ACTUAL_RTW_DATE": "04-06-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tran",
		 "CLAIMANT_FIRST_NAME": "Loan",
		 "CLAIM_NO": "2014-05-14-0527-VP-01",
		 "DURATION": 119,
		 "DISABILITY_START_DATE": "05-12-2014 ",
		 "DATE_CLAIM_CLOSED": "11-17-2014 ",
		 "ACTUAL_RTW_DATE": "09-08-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tran",
		 "CLAIMANT_FIRST_NAME": "Dung",
		 "CLAIM_NO": "2021-06-08-0133-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "06-07-2021 ",
		 "DATE_CLAIM_CLOSED": "08-13-2021 ",
		 "ACTUAL_RTW_DATE": "07-12-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tran",
		 "CLAIMANT_FIRST_NAME": "Trang",
		 "CLAIM_NO": "2019-09-10-0582-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "11-07-2019 ",
		 "DATE_CLAIM_CLOSED": "12-26-2019 ",
		 "ACTUAL_RTW_DATE": "12-23-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tran",
		 "CLAIMANT_FIRST_NAME": "Minh",
		 "CLAIM_NO": "2019-03-28-0270-VP-01",
		 "DURATION": 90,
		 "DISABILITY_START_DATE": "02-15-2019 ",
		 "DATE_CLAIM_CLOSED": "05-28-2019 ",
		 "ACTUAL_RTW_DATE": "05-16-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tran",
		 "CLAIMANT_FIRST_NAME": "Kimberly",
		 "CLAIM_NO": "2014-01-29-0420-VP-01",
		 "DURATION": 161,
		 "DISABILITY_START_DATE": "01-27-2014 ",
		 "DATE_CLAIM_CLOSED": "07-18-2014 ",
		 "ACTUAL_RTW_DATE": "07-07-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Trejo",
		 "CLAIMANT_FIRST_NAME": "Sylvia",
		 "CLAIM_NO": "2020-11-02-0965-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "10-26-2020 ",
		 "DATE_CLAIM_CLOSED": "12-08-2020 ",
		 "ACTUAL_RTW_DATE": "11-30-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Trejo",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2013-07-19-0149-VP-01",
		 "DURATION": 118,
		 "DISABILITY_START_DATE": "07-16-2013 ",
		 "DATE_CLAIM_CLOSED": "12-11-2013 ",
		 "ACTUAL_RTW_DATE": "11-11-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Trent",
		 "CLAIMANT_FIRST_NAME": "Cathy",
		 "CLAIM_NO": "2013-11-18-0146-VP-01",
		 "DURATION": 90,
		 "DISABILITY_START_DATE": "12-05-2013 ",
		 "DATE_CLAIM_CLOSED": "06-02-2014 ",
		 "ACTUAL_RTW_DATE": "03-05-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Trentham",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2017-09-12-0611-VP-01",
		 "DURATION": 160,
		 "DISABILITY_START_DATE": "09-05-2017 ",
		 "DATE_CLAIM_CLOSED": "03-27-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Trieu",
		 "CLAIMANT_FIRST_NAME": "Angelica",
		 "CLAIM_NO": "2020-12-28-0905-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "02-01-2021 ",
		 "DATE_CLAIM_CLOSED": "03-17-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Trieu",
		 "CLAIMANT_FIRST_NAME": "Angelica",
		 "CLAIM_NO": "2019-08-30-0474-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "09-16-2019 ",
		 "DATE_CLAIM_CLOSED": "11-18-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Triplett",
		 "CLAIMANT_FIRST_NAME": "Larry",
		 "CLAIM_NO": "2016-02-19-0147-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "03-02-2016 ",
		 "DATE_CLAIM_CLOSED": "04-29-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Triplett",
		 "CLAIMANT_FIRST_NAME": "Larry",
		 "CLAIM_NO": "2015-08-18-0248-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "08-19-2015 ",
		 "DATE_CLAIM_CLOSED": "10-02-2015 ",
		 "ACTUAL_RTW_DATE": "09-10-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Trock",
		 "CLAIMANT_FIRST_NAME": "Kristin",
		 "CLAIM_NO": "2016-04-05-0065-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "04-13-2016 ",
		 "DATE_CLAIM_CLOSED": "04-25-2016 ",
		 "ACTUAL_RTW_DATE": "04-25-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Trosper",
		 "CLAIMANT_FIRST_NAME": "Eugene",
		 "CLAIM_NO": "2022-04-06-0516-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "04-20-2022 ",
		 "DATE_CLAIM_CLOSED": "04-18-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Trujillo",
		 "CLAIMANT_FIRST_NAME": "Linda",
		 "CLAIM_NO": "2014-08-18-0320-VP-01",
		 "DURATION": 143,
		 "DISABILITY_START_DATE": "08-15-2014 ",
		 "DATE_CLAIM_CLOSED": "08-04-2015 ",
		 "ACTUAL_RTW_DATE": "01-05-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Trujillo",
		 "CLAIMANT_FIRST_NAME": "Filbert",
		 "CLAIM_NO": "2021-10-19-0644-VP-01",
		 "DISABILITY_START_DATE": "10-01-2021 ",
		 "DATE_CLAIM_CLOSED": "11-02-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Trujillo",
		 "CLAIMANT_FIRST_NAME": "Linda",
		 "CLAIM_NO": "2013-03-08-0233-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "03-05-2013 ",
		 "DATE_CLAIM_CLOSED": "04-13-2013 ",
		 "ACTUAL_RTW_DATE": "03-12-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Truong",
		 "CLAIMANT_FIRST_NAME": "Minh",
		 "CLAIM_NO": "2023-01-03-1328-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "01-03-2023 ",
		 "DATE_CLAIM_CLOSED": "03-10-2023 ",
		 "ACTUAL_RTW_DATE": "03-01-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Truong",
		 "CLAIMANT_FIRST_NAME": "Henry",
		 "CLAIM_NO": "2014-07-23-0201-VP-01",
		 "DURATION": 0,
		 "DISABILITY_START_DATE": "07-31-2014 ",
		 "DATE_CLAIM_CLOSED": "12-08-2014 ",
		 "ACTUAL_RTW_DATE": "07-31-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Truong",
		 "CLAIMANT_FIRST_NAME": "Tracy",
		 "CLAIM_NO": "2023-03-02-0932-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "03-03-2023 ",
		 "DATE_CLAIM_CLOSED": "04-28-2023 ",
		 "ACTUAL_RTW_DATE": "03-15-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tsai",
		 "CLAIMANT_FIRST_NAME": "Linda",
		 "CLAIM_NO": "2016-05-25-0458-VP-01",
		 "DURATION": 112,
		 "DISABILITY_START_DATE": "06-06-2016 ",
		 "DATE_CLAIM_CLOSED": "09-30-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tsai",
		 "CLAIMANT_FIRST_NAME": "Joanne",
		 "CLAIM_NO": "2017-03-27-0588-VP-01",
		 "DURATION": 126,
		 "DISABILITY_START_DATE": "04-03-2017 ",
		 "DATE_CLAIM_CLOSED": "08-08-2017 ",
		 "ACTUAL_RTW_DATE": "08-07-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tsang",
		 "CLAIMANT_FIRST_NAME": "Lai",
		 "CLAIM_NO": "2016-11-21-0250-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "11-28-2016 ",
		 "DATE_CLAIM_CLOSED": "02-07-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tsang",
		 "CLAIMANT_FIRST_NAME": "Lai",
		 "CLAIM_NO": "2013-01-05-0180-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "12-06-2012 ",
		 "DATE_CLAIM_CLOSED": "01-21-2013 ",
		 "ACTUAL_RTW_DATE": "12-17-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tse",
		 "CLAIMANT_FIRST_NAME": "Cindy",
		 "CLAIM_NO": "2013-08-06-0091-VP-01",
		 "DURATION": 51,
		 "DISABILITY_START_DATE": "08-13-2013 ",
		 "DATE_CLAIM_CLOSED": "10-15-2013 ",
		 "ACTUAL_RTW_DATE": "10-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tsui",
		 "CLAIMANT_FIRST_NAME": "Ying-Wai",
		 "CLAIM_NO": "2017-11-21-0471-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "12-07-2017 ",
		 "DATE_CLAIM_CLOSED": "12-06-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tsui",
		 "CLAIMANT_FIRST_NAME": "Ying-Wai",
		 "CLAIM_NO": "2018-02-01-0597-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "02-16-2018 ",
		 "DATE_CLAIM_CLOSED": "04-18-2018 ",
		 "ACTUAL_RTW_DATE": "04-16-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tu-Nguyen",
		 "CLAIMANT_FIRST_NAME": "Mai",
		 "CLAIM_NO": "2019-06-18-0471-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "06-10-2019 ",
		 "DATE_CLAIM_CLOSED": "07-08-2019 ",
		 "ACTUAL_RTW_DATE": "07-01-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tuncer",
		 "CLAIMANT_FIRST_NAME": "Esin",
		 "CLAIM_NO": "2015-06-09-0200-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "06-10-2015 ",
		 "DATE_CLAIM_CLOSED": "08-03-2015 ",
		 "ACTUAL_RTW_DATE": "07-23-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Turian",
		 "CLAIMANT_FIRST_NAME": "Cristina",
		 "CLAIM_NO": "2017-04-12-0529-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "04-19-2017 ",
		 "DATE_CLAIM_CLOSED": "05-04-2017 ",
		 "ACTUAL_RTW_DATE": "05-03-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Turian",
		 "CLAIMANT_FIRST_NAME": "Cristina",
		 "CLAIM_NO": "2019-03-18-0655-VP-01",
		 "DURATION": 179,
		 "DISABILITY_START_DATE": "03-08-2019 ",
		 "DATE_CLAIM_CLOSED": "09-06-2019 ",
		 "ACTUAL_RTW_DATE": "09-03-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Turian",
		 "CLAIMANT_FIRST_NAME": "Cristina",
		 "CLAIM_NO": "2016-09-12-0402-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "09-08-2016 ",
		 "DATE_CLAIM_CLOSED": "10-24-2016 ",
		 "ACTUAL_RTW_DATE": "09-19-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Turner",
		 "CLAIMANT_FIRST_NAME": "Lynette",
		 "CLAIM_NO": "2017-01-04-0202-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "01-30-2017 ",
		 "DATE_CLAIM_CLOSED": "05-03-2017 ",
		 "ACTUAL_RTW_DATE": "05-01-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Turner",
		 "CLAIMANT_FIRST_NAME": "Janaye",
		 "CLAIM_NO": "2022-08-10-0341-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "08-29-2022 ",
		 "DATE_CLAIM_CLOSED": "10-31-2022 ",
		 "ACTUAL_RTW_DATE": "10-26-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tyler",
		 "CLAIMANT_FIRST_NAME": "Janice",
		 "CLAIM_NO": "2015-10-20-0011-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "11-18-2015 ",
		 "DATE_CLAIM_CLOSED": "01-06-2016 ",
		 "ACTUAL_RTW_DATE": "12-21-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tyrrel",
		 "CLAIMANT_FIRST_NAME": "Kimberly",
		 "CLAIM_NO": "2017-04-07-0244-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "04-12-2017 ",
		 "DATE_CLAIM_CLOSED": "06-15-2017 ",
		 "ACTUAL_RTW_DATE": "06-13-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Tyson",
		 "CLAIMANT_FIRST_NAME": "Eric",
		 "CLAIM_NO": "2023-02-13-1055-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "02-08-2023 ",
		 "DATE_CLAIM_CLOSED": "03-06-2023 ",
		 "ACTUAL_RTW_DATE": "03-02-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Uchitel",
		 "CLAIMANT_FIRST_NAME": "Vladimir",
		 "CLAIM_NO": "2018-09-04-0729-VP-01",
		 "DURATION": 170,
		 "DISABILITY_START_DATE": "09-12-2018 ",
		 "DATE_CLAIM_CLOSED": "03-05-2019 ",
		 "ACTUAL_RTW_DATE": "03-01-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Uchitel",
		 "CLAIMANT_FIRST_NAME": "Vladimir",
		 "CLAIM_NO": "2019-04-23-0322-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "05-03-2019 ",
		 "DATE_CLAIM_CLOSED": "06-24-2019 ",
		 "ACTUAL_RTW_DATE": "06-18-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Uclaray",
		 "CLAIMANT_FIRST_NAME": "Teresita",
		 "CLAIM_NO": "2016-02-29-0541-VP-01",
		 "DURATION": 177,
		 "DISABILITY_START_DATE": "03-01-2016 ",
		 "DATE_CLAIM_CLOSED": "08-29-2016 ",
		 "ACTUAL_RTW_DATE": "08-25-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Udo",
		 "CLAIMANT_FIRST_NAME": "Edima",
		 "CLAIM_NO": "2017-08-17-0460-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "08-16-2017 ",
		 "DATE_CLAIM_CLOSED": "09-05-2017 ",
		 "ACTUAL_RTW_DATE": "08-30-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Udo",
		 "CLAIMANT_FIRST_NAME": "Edima",
		 "CLAIM_NO": "2017-11-14-0672-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "11-16-2017 ",
		 "DATE_CLAIM_CLOSED": "11-29-2018 ",
		 "ACTUAL_RTW_DATE": "01-01-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ugarte",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2016-11-02-0049-VP-01",
		 "DURATION": 202,
		 "DISABILITY_START_DATE": "10-11-2016 ",
		 "DATE_CLAIM_CLOSED": "05-08-2017 ",
		 "ACTUAL_RTW_DATE": "05-01-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ugarte",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2015-01-26-0196-VP-01",
		 "DURATION": 70,
		 "DISABILITY_START_DATE": "01-26-2015 ",
		 "DATE_CLAIM_CLOSED": "04-17-2015 ",
		 "ACTUAL_RTW_DATE": "04-06-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ujile",
		 "CLAIMANT_FIRST_NAME": "Itong",
		 "CLAIM_NO": "2022-04-28-0561-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "04-04-2022 ",
		 "DATE_CLAIM_CLOSED": "05-23-2022 ",
		 "ACTUAL_RTW_DATE": "05-16-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ulliman",
		 "CLAIMANT_FIRST_NAME": "William",
		 "CLAIM_NO": "2016-06-02-0001-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "06-08-2016 ",
		 "DATE_CLAIM_CLOSED": "06-29-2016 ",
		 "ACTUAL_RTW_DATE": "06-29-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ulrich",
		 "CLAIMANT_FIRST_NAME": "Leszek",
		 "CLAIM_NO": "2013-01-05-0315-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "11-19-2012 ",
		 "DATE_CLAIM_CLOSED": "01-17-2013 ",
		 "ACTUAL_RTW_DATE": "11-30-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ulrich",
		 "CLAIMANT_FIRST_NAME": "Leszek",
		 "CLAIM_NO": "2013-01-14-0453-VP-01",
		 "DURATION": 509,
		 "DISABILITY_START_DATE": "12-19-2012 ",
		 "DATE_CLAIM_CLOSED": "05-31-2014 ",
		 "ACTUAL_RTW_DATE": "05-12-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ulstad",
		 "CLAIMANT_FIRST_NAME": "Tiffany",
		 "CLAIM_NO": "2020-12-09-0001-VP-01",
		 "DURATION": 104,
		 "DISABILITY_START_DATE": "01-26-2021 ",
		 "DATE_CLAIM_CLOSED": "03-09-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Umali",
		 "CLAIMANT_FIRST_NAME": "Liezl",
		 "CLAIM_NO": "2014-06-20-0094-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "06-17-2014 ",
		 "DATE_CLAIM_CLOSED": "12-09-2014 ",
		 "ACTUAL_RTW_DATE": "07-14-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Unpingco",
		 "CLAIMANT_FIRST_NAME": "Jason",
		 "CLAIM_NO": "2022-11-29-0463-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "11-23-2022 ",
		 "DATE_CLAIM_CLOSED": "01-05-2023 ",
		 "ACTUAL_RTW_DATE": "12-12-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Uralets",
		 "CLAIMANT_FIRST_NAME": "Victor",
		 "CLAIM_NO": "2022-09-13-0607-VP-01",
		 "DURATION": 180,
		 "DISABILITY_START_DATE": "09-08-2022 ",
		 "DATE_CLAIM_CLOSED": "03-08-2023 ",
		 "ACTUAL_RTW_DATE": "03-07-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Urbanski",
		 "CLAIMANT_FIRST_NAME": "Joanna",
		 "CLAIM_NO": "2021-05-19-0454-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "06-07-2021 ",
		 "DATE_CLAIM_CLOSED": "08-02-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Uriostegui Ascevedo",
		 "CLAIMANT_FIRST_NAME": "Tania",
		 "CLAIM_NO": "2018-06-22-0615-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "07-17-2018 ",
		 "DATE_CLAIM_CLOSED": "10-01-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Uyehara",
		 "CLAIMANT_FIRST_NAME": "Babylyn",
		 "CLAIM_NO": "2015-07-02-0450-VP-01",
		 "DURATION": 182,
		 "DISABILITY_START_DATE": "07-06-2015 ",
		 "DATE_CLAIM_CLOSED": "07-11-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vaccarella",
		 "CLAIMANT_FIRST_NAME": "Catherine",
		 "CLAIM_NO": "2018-05-08-0518-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "06-04-2018 ",
		 "DATE_CLAIM_CLOSED": "06-29-2018 ",
		 "ACTUAL_RTW_DATE": "06-25-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vadi",
		 "CLAIMANT_FIRST_NAME": "Bahareh",
		 "CLAIM_NO": "2015-03-25-0262-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "05-15-2015 ",
		 "DATE_CLAIM_CLOSED": "07-14-2015 ",
		 "ACTUAL_RTW_DATE": "06-26-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valadez",
		 "CLAIMANT_FIRST_NAME": "Martha",
		 "CLAIM_NO": "2019-07-16-0715-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "07-29-2019 ",
		 "DATE_CLAIM_CLOSED": "08-27-2019 ",
		 "ACTUAL_RTW_DATE": "08-20-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valadez",
		 "CLAIMANT_FIRST_NAME": "Celia",
		 "CLAIM_NO": "2022-05-03-0426-VP-01",
		 "DURATION": 113,
		 "DISABILITY_START_DATE": "05-11-2022 ",
		 "DATE_CLAIM_CLOSED": "09-02-2022 ",
		 "ACTUAL_RTW_DATE": "09-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valadez",
		 "CLAIMANT_FIRST_NAME": "Martha",
		 "CLAIM_NO": "2021-10-26-0697-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "10-19-2021 ",
		 "DATE_CLAIM_CLOSED": "11-08-2021 ",
		 "ACTUAL_RTW_DATE": "10-29-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valadez",
		 "CLAIMANT_FIRST_NAME": "Martha",
		 "CLAIM_NO": "2016-01-13-0225-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "01-28-2016 ",
		 "DATE_CLAIM_CLOSED": "04-13-2016 ",
		 "ACTUAL_RTW_DATE": "02-15-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valadez",
		 "CLAIMANT_FIRST_NAME": "Martha",
		 "CLAIM_NO": "2021-02-23-0515-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "02-02-2021 ",
		 "DATE_CLAIM_CLOSED": "03-01-2021 ",
		 "ACTUAL_RTW_DATE": "02-11-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valadez",
		 "CLAIMANT_FIRST_NAME": "Martha",
		 "CLAIM_NO": "2017-11-27-0379-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "11-28-2017 ",
		 "DATE_CLAIM_CLOSED": "12-19-2017 ",
		 "ACTUAL_RTW_DATE": "12-18-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valadez",
		 "CLAIMANT_FIRST_NAME": "Celia",
		 "CLAIM_NO": "2019-04-19-0084-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "04-11-2019 ",
		 "DATE_CLAIM_CLOSED": "05-13-2019 ",
		 "ACTUAL_RTW_DATE": "04-29-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valadez",
		 "CLAIMANT_FIRST_NAME": "Martha",
		 "CLAIM_NO": "2013-01-05-0373-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "12-04-2012 ",
		 "DATE_CLAIM_CLOSED": "02-09-2013 ",
		 "ACTUAL_RTW_DATE": "01-07-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valadez",
		 "CLAIMANT_FIRST_NAME": "Celia",
		 "CLAIM_NO": "2016-04-22-0224-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "04-22-2016 ",
		 "DATE_CLAIM_CLOSED": "06-29-2016 ",
		 "ACTUAL_RTW_DATE": "05-16-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valdes",
		 "CLAIMANT_FIRST_NAME": "Michael",
		 "CLAIM_NO": "2014-06-30-0165-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "06-20-2014 ",
		 "DATE_CLAIM_CLOSED": "12-08-2014 ",
		 "ACTUAL_RTW_DATE": "07-07-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valdez",
		 "CLAIMANT_FIRST_NAME": "Stephanie",
		 "CLAIM_NO": "2013-03-19-0303-VP-01",
		 "DURATION": 45,
		 "DISABILITY_START_DATE": "03-15-2013 ",
		 "DATE_CLAIM_CLOSED": "05-13-2013 ",
		 "ACTUAL_RTW_DATE": "04-29-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valdez",
		 "CLAIMANT_FIRST_NAME": "Arnold",
		 "CLAIM_NO": "2021-04-19-0630-VP-01",
		 "DURATION": 53,
		 "DISABILITY_START_DATE": "05-20-2021 ",
		 "DATE_CLAIM_CLOSED": "08-19-2021 ",
		 "ACTUAL_RTW_DATE": "07-12-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valdez",
		 "CLAIMANT_FIRST_NAME": "Edna",
		 "CLAIM_NO": "2013-08-13-0097-VP-01",
		 "DURATION": 155,
		 "DISABILITY_START_DATE": "08-16-2013 ",
		 "DATE_CLAIM_CLOSED": "03-10-2014 ",
		 "ACTUAL_RTW_DATE": "01-18-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valdez",
		 "CLAIMANT_FIRST_NAME": "Giavana",
		 "CLAIM_NO": "2022-01-06-0401-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "01-04-2022 ",
		 "DATE_CLAIM_CLOSED": "01-21-2022 ",
		 "ACTUAL_RTW_DATE": "01-17-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valdez",
		 "CLAIMANT_FIRST_NAME": "Rosalina",
		 "CLAIM_NO": "2013-03-22-0277-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "03-20-2013 ",
		 "DATE_CLAIM_CLOSED": "06-13-2013 ",
		 "ACTUAL_RTW_DATE": "04-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valdez",
		 "CLAIMANT_FIRST_NAME": "Stephanie",
		 "CLAIM_NO": "2013-09-30-0673-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "10-04-2013 ",
		 "DATE_CLAIM_CLOSED": "01-29-2014 ",
		 "ACTUAL_RTW_DATE": "10-14-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valdivia",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2014-06-17-0138-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "07-17-2014 ",
		 "DATE_CLAIM_CLOSED": "12-12-2014 ",
		 "ACTUAL_RTW_DATE": "08-19-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valdovinos",
		 "CLAIMANT_FIRST_NAME": "Violeta",
		 "CLAIM_NO": "2020-01-09-0631-VP-01",
		 "DURATION": 111,
		 "DISABILITY_START_DATE": "01-23-2020 ",
		 "DATE_CLAIM_CLOSED": "05-26-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valencia",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2022-04-13-0963-VP-01",
		 "DURATION": 83,
		 "DISABILITY_START_DATE": "05-10-2022 ",
		 "DATE_CLAIM_CLOSED": "08-02-2022 ",
		 "ACTUAL_RTW_DATE": "08-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valencia",
		 "CLAIMANT_FIRST_NAME": "Erasmo",
		 "CLAIM_NO": "2020-10-19-0727-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "09-24-2020 ",
		 "DATE_CLAIM_CLOSED": "10-22-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valencia",
		 "CLAIMANT_FIRST_NAME": "Erasmo",
		 "CLAIM_NO": "2020-11-04-0643-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "10-27-2020 ",
		 "DATE_CLAIM_CLOSED": "11-20-2020 ",
		 "ACTUAL_RTW_DATE": "11-09-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valenzuela",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2018-02-27-0658-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "03-05-2018 ",
		 "DATE_CLAIM_CLOSED": "04-16-2018 ",
		 "ACTUAL_RTW_DATE": "04-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valles",
		 "CLAIMANT_FIRST_NAME": "Enedina",
		 "CLAIM_NO": "2013-01-09-0315-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "01-07-2013 ",
		 "DATE_CLAIM_CLOSED": "04-26-2013 ",
		 "ACTUAL_RTW_DATE": "01-14-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valles",
		 "CLAIMANT_FIRST_NAME": "Enedina",
		 "CLAIM_NO": "2017-06-26-0595-VP-01",
		 "DURATION": 30,
		 "DISABILITY_START_DATE": "06-26-2017 ",
		 "DATE_CLAIM_CLOSED": "08-23-2017 ",
		 "ACTUAL_RTW_DATE": "07-26-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valmes",
		 "CLAIMANT_FIRST_NAME": "Ingrid",
		 "CLAIM_NO": "2013-01-05-0908-VP-01",
		 "DURATION": 440,
		 "DISABILITY_START_DATE": "09-27-2012 ",
		 "DATE_CLAIM_CLOSED": "03-04-2013 ",
		 "ACTUAL_RTW_DATE": "12-11-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valmes",
		 "CLAIMANT_FIRST_NAME": "Ingrid",
		 "CLAIM_NO": "2018-10-09-0089-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "10-01-2018 ",
		 "DATE_CLAIM_CLOSED": "11-14-2018 ",
		 "ACTUAL_RTW_DATE": "10-15-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Valmes",
		 "CLAIMANT_FIRST_NAME": "Ingrid",
		 "CLAIM_NO": "2015-09-24-0076-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "09-28-2015 ",
		 "DATE_CLAIM_CLOSED": "12-11-2015 ",
		 "ACTUAL_RTW_DATE": "11-24-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Van Cleave",
		 "CLAIMANT_FIRST_NAME": "Kimberly",
		 "CLAIM_NO": "2021-02-22-0509-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "02-22-2021 ",
		 "DATE_CLAIM_CLOSED": "04-06-2021 ",
		 "ACTUAL_RTW_DATE": "04-05-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Van Hoven",
		 "CLAIMANT_FIRST_NAME": "Megan",
		 "CLAIM_NO": "2015-07-17-0158-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "08-10-2015 ",
		 "DATE_CLAIM_CLOSED": "10-12-2015 ",
		 "ACTUAL_RTW_DATE": "10-07-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vanhouwelingen",
		 "CLAIMANT_FIRST_NAME": "Shelly",
		 "CLAIM_NO": "2017-10-31-0540-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "11-16-2017 ",
		 "DATE_CLAIM_CLOSED": "01-04-2018 ",
		 "ACTUAL_RTW_DATE": "01-01-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vanhouwelingen",
		 "CLAIMANT_FIRST_NAME": "Shelly",
		 "CLAIM_NO": "2014-04-30-0512-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "05-01-2014 ",
		 "DATE_CLAIM_CLOSED": "12-09-2014 ",
		 "ACTUAL_RTW_DATE": "05-12-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vardayou",
		 "CLAIMANT_FIRST_NAME": "Darvin",
		 "CLAIM_NO": "2019-04-11-0510-VP-01",
		 "DURATION": 163,
		 "DISABILITY_START_DATE": "04-22-2019 ",
		 "DATE_CLAIM_CLOSED": "12-09-2019 ",
		 "ACTUAL_RTW_DATE": "10-02-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Varga",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2014-02-12-0448-VP-01",
		 "DURATION": 304,
		 "DISABILITY_START_DATE": "02-07-2014 ",
		 "DATE_CLAIM_CLOSED": "12-15-2014 ",
		 "ACTUAL_RTW_DATE": "12-08-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vargas",
		 "CLAIMANT_FIRST_NAME": "Claudia",
		 "CLAIM_NO": "2016-01-20-0348-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "01-18-2016 ",
		 "DATE_CLAIM_CLOSED": "03-30-2016 ",
		 "ACTUAL_RTW_DATE": "02-08-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vargas",
		 "CLAIMANT_FIRST_NAME": "Silvia",
		 "CLAIM_NO": "2013-03-07-0329-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "03-27-2013 ",
		 "DATE_CLAIM_CLOSED": "08-12-2013 ",
		 "ACTUAL_RTW_DATE": "05-25-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vargas",
		 "CLAIMANT_FIRST_NAME": "Claudia",
		 "CLAIM_NO": "2016-05-16-0314-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "05-09-2016 ",
		 "DATE_CLAIM_CLOSED": "06-30-2016 ",
		 "ACTUAL_RTW_DATE": "06-21-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vasquez",
		 "CLAIMANT_FIRST_NAME": "Rachel",
		 "CLAIM_NO": "2022-03-30-0759-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "04-07-2022 ",
		 "DATE_CLAIM_CLOSED": "06-28-2022 ",
		 "ACTUAL_RTW_DATE": "06-27-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vasquez",
		 "CLAIMANT_FIRST_NAME": "Ruel",
		 "CLAIM_NO": "2015-08-11-0013-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "08-10-2015 ",
		 "DATE_CLAIM_CLOSED": "09-14-2015 ",
		 "ACTUAL_RTW_DATE": "08-17-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vasquez",
		 "CLAIMANT_FIRST_NAME": "Ruel",
		 "CLAIM_NO": "2015-02-12-0252-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "02-09-2015 ",
		 "DATE_CLAIM_CLOSED": "03-02-2015 ",
		 "ACTUAL_RTW_DATE": "02-16-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vasquez",
		 "CLAIMANT_FIRST_NAME": "Mario",
		 "CLAIM_NO": "2022-11-17-0040-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "11-08-2022 ",
		 "DATE_CLAIM_CLOSED": "01-06-2023 ",
		 "ACTUAL_RTW_DATE": "11-21-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vasquez",
		 "CLAIMANT_FIRST_NAME": "Guillermina",
		 "CLAIM_NO": "2021-05-13-0759-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "05-13-2021 ",
		 "DATE_CLAIM_CLOSED": "07-07-2021 ",
		 "ACTUAL_RTW_DATE": "06-01-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vasquez Flores",
		 "CLAIMANT_FIRST_NAME": "Fortino",
		 "CLAIM_NO": "2016-11-09-0216-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "11-04-2016 ",
		 "DATE_CLAIM_CLOSED": "12-09-2016 ",
		 "ACTUAL_RTW_DATE": "11-21-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vazquez",
		 "CLAIMANT_FIRST_NAME": "Julissa",
		 "CLAIM_NO": "2020-04-14-0702-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "04-13-2020 ",
		 "DATE_CLAIM_CLOSED": "06-04-2020 ",
		 "ACTUAL_RTW_DATE": "04-27-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vega",
		 "CLAIMANT_FIRST_NAME": "Alicia",
		 "CLAIM_NO": "2016-08-08-0311-VP-01",
		 "DURATION": 25,
		 "DISABILITY_START_DATE": "08-31-2016 ",
		 "DATE_CLAIM_CLOSED": "10-04-2016 ",
		 "ACTUAL_RTW_DATE": "09-25-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vega",
		 "CLAIMANT_FIRST_NAME": "Marie",
		 "CLAIM_NO": "2014-05-22-0187-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "05-19-2014 ",
		 "DATE_CLAIM_CLOSED": "08-04-2014 ",
		 "ACTUAL_RTW_DATE": "05-27-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vega",
		 "CLAIMANT_FIRST_NAME": "Marie",
		 "CLAIM_NO": "2017-09-26-0609-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "09-26-2017 ",
		 "DATE_CLAIM_CLOSED": "10-23-2017 ",
		 "ACTUAL_RTW_DATE": "10-16-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vega",
		 "CLAIMANT_FIRST_NAME": "Alicia",
		 "CLAIM_NO": "2016-05-26-0198-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "05-23-2016 ",
		 "DATE_CLAIM_CLOSED": "06-08-2016 ",
		 "ACTUAL_RTW_DATE": "06-06-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Velasco",
		 "CLAIMANT_FIRST_NAME": "Maria",
		 "CLAIM_NO": "2017-04-18-0223-VP-01",
		 "DURATION": 25,
		 "DISABILITY_START_DATE": "04-13-2017 ",
		 "DATE_CLAIM_CLOSED": "05-16-2017 ",
		 "ACTUAL_RTW_DATE": "05-08-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vele",
		 "CLAIMANT_FIRST_NAME": "Juliana",
		 "CLAIM_NO": "2018-08-20-0710-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "08-13-2018 ",
		 "DATE_CLAIM_CLOSED": "01-23-2019 ",
		 "ACTUAL_RTW_DATE": "11-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Venegas",
		 "CLAIMANT_FIRST_NAME": "Brenda",
		 "CLAIM_NO": "2023-04-17-0419-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "04-17-2023 ",
		 "DATE_CLAIM_CLOSED": "05-03-2023 ",
		 "ACTUAL_RTW_DATE": "05-01-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Venegas Jr",
		 "CLAIMANT_FIRST_NAME": "Gaspar",
		 "CLAIM_NO": "2020-10-27-0627-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "11-10-2020 ",
		 "DATE_CLAIM_CLOSED": "12-07-2020 ",
		 "ACTUAL_RTW_DATE": "11-25-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vepa",
		 "CLAIMANT_FIRST_NAME": "Sunaina",
		 "CLAIM_NO": "2013-04-19-0166-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "04-22-2013 ",
		 "DATE_CLAIM_CLOSED": "07-31-2013 ",
		 "ACTUAL_RTW_DATE": "07-15-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vepa",
		 "CLAIMANT_FIRST_NAME": "Sunaina",
		 "CLAIM_NO": "2016-01-28-0486-VP-01",
		 "DURATION": 66,
		 "DISABILITY_START_DATE": "02-05-2016 ",
		 "DATE_CLAIM_CLOSED": "04-13-2016 ",
		 "ACTUAL_RTW_DATE": "04-11-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Verano",
		 "CLAIMANT_FIRST_NAME": "Joan",
		 "CLAIM_NO": "2020-06-15-0471-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "07-20-2020 ",
		 "DATE_CLAIM_CLOSED": "10-08-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Verbera",
		 "CLAIMANT_FIRST_NAME": "Silvia",
		 "CLAIM_NO": "2013-03-20-0131-VP-01",
		 "DURATION": 350,
		 "DISABILITY_START_DATE": "03-18-2013 ",
		 "DATE_CLAIM_CLOSED": "03-28-2014 ",
		 "ACTUAL_RTW_DATE": "03-03-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Verdekel",
		 "CLAIMANT_FIRST_NAME": "Indira",
		 "CLAIM_NO": "2022-10-13-0694-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "11-14-2022 ",
		 "DATE_CLAIM_CLOSED": "01-26-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Verdekel",
		 "CLAIMANT_FIRST_NAME": "Indira",
		 "CLAIM_NO": "2019-05-24-0462-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "06-27-2019 ",
		 "DATE_CLAIM_CLOSED": "08-22-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vergara",
		 "CLAIMANT_FIRST_NAME": "Josue",
		 "CLAIM_NO": "2017-12-04-0006-VP-01",
		 "DURATION": 50,
		 "DISABILITY_START_DATE": "11-27-2017 ",
		 "DATE_CLAIM_CLOSED": "01-18-2018 ",
		 "ACTUAL_RTW_DATE": "01-16-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vergara",
		 "CLAIMANT_FIRST_NAME": "Josue",
		 "CLAIM_NO": "2022-02-04-0954-VP-01",
		 "DURATION": 110,
		 "DISABILITY_START_DATE": "02-09-2022 ",
		 "DATE_CLAIM_CLOSED": "08-01-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vergel De Dios",
		 "CLAIMANT_FIRST_NAME": "Minhtrang",
		 "CLAIM_NO": "2014-04-18-0155-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "05-06-2014 ",
		 "DATE_CLAIM_CLOSED": "01-05-2015 ",
		 "ACTUAL_RTW_DATE": "05-21-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vergel De Dios",
		 "CLAIMANT_FIRST_NAME": "Minhtrang",
		 "CLAIM_NO": "2015-06-04-0354-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "06-15-2015 ",
		 "DATE_CLAIM_CLOSED": "09-02-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vergel De Dios",
		 "CLAIMANT_FIRST_NAME": "Minhtrang",
		 "CLAIM_NO": "2013-07-17-0189-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "07-25-2013 ",
		 "DATE_CLAIM_CLOSED": "09-28-2013 ",
		 "ACTUAL_RTW_DATE": "08-08-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Verghese",
		 "CLAIMANT_FIRST_NAME": "Joyce",
		 "CLAIM_NO": "2019-04-18-0434-VP-01",
		 "DURATION": 78,
		 "DISABILITY_START_DATE": "05-06-2019 ",
		 "DATE_CLAIM_CLOSED": "07-29-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vermillion",
		 "CLAIMANT_FIRST_NAME": "Jamie",
		 "CLAIM_NO": "2019-01-09-0712-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "12-27-2018 ",
		 "DATE_CLAIM_CLOSED": "02-04-2019 ",
		 "ACTUAL_RTW_DATE": "01-24-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Versoza",
		 "CLAIMANT_FIRST_NAME": "Filipina",
		 "CLAIM_NO": "2017-03-29-0401-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "03-27-2017 ",
		 "DATE_CLAIM_CLOSED": "04-11-2017 ",
		 "ACTUAL_RTW_DATE": "04-04-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vessell",
		 "CLAIMANT_FIRST_NAME": "Susan",
		 "CLAIM_NO": "2014-07-15-0001-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "07-11-2014 ",
		 "DATE_CLAIM_CLOSED": "10-06-2014 ",
		 "ACTUAL_RTW_DATE": "07-23-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vi",
		 "CLAIMANT_FIRST_NAME": "Osonga",
		 "CLAIM_NO": "2021-10-30-0014-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "01-04-2022 ",
		 "DATE_CLAIM_CLOSED": "03-14-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vicente",
		 "CLAIMANT_FIRST_NAME": "Alicia",
		 "CLAIM_NO": "2016-02-12-0162-VP-01",
		 "DURATION": 162,
		 "DISABILITY_START_DATE": "02-08-2016 ",
		 "DATE_CLAIM_CLOSED": "09-15-2016 ",
		 "ACTUAL_RTW_DATE": "07-19-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vicente",
		 "CLAIMANT_FIRST_NAME": "Alicia",
		 "CLAIM_NO": "2019-07-22-0678-VP-01",
		 "DURATION": 218,
		 "DISABILITY_START_DATE": "07-23-2019 ",
		 "DATE_CLAIM_CLOSED": "03-03-2020 ",
		 "ACTUAL_RTW_DATE": "02-26-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Victor",
		 "CLAIMANT_FIRST_NAME": "Arnett",
		 "CLAIM_NO": "2014-11-25-0428-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "11-20-2014 ",
		 "DATE_CLAIM_CLOSED": "01-02-2015 ",
		 "ACTUAL_RTW_DATE": "12-31-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Victor",
		 "CLAIMANT_FIRST_NAME": "Arnett",
		 "CLAIM_NO": "2017-08-08-0006-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "08-30-2017 ",
		 "DATE_CLAIM_CLOSED": "09-20-2017 ",
		 "ACTUAL_RTW_DATE": "09-15-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Victor",
		 "CLAIMANT_FIRST_NAME": "Arnett",
		 "CLAIM_NO": "2019-09-16-0712-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "09-16-2019 ",
		 "DATE_CLAIM_CLOSED": "10-17-2019 ",
		 "ACTUAL_RTW_DATE": "10-14-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Victor",
		 "CLAIMANT_FIRST_NAME": "Arnett",
		 "CLAIM_NO": "2020-07-02-0001-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "06-22-2020 ",
		 "DATE_CLAIM_CLOSED": "07-20-2020 ",
		 "ACTUAL_RTW_DATE": "07-13-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vigil",
		 "CLAIMANT_FIRST_NAME": "Norma",
		 "CLAIM_NO": "2013-01-05-0609-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "10-31-2012 ",
		 "DATE_CLAIM_CLOSED": "04-30-2013 ",
		 "ACTUAL_RTW_DATE": "01-02-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vilaisak",
		 "CLAIMANT_FIRST_NAME": "Jessica",
		 "CLAIM_NO": "2022-03-18-0257-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "05-09-2022 ",
		 "DATE_CLAIM_CLOSED": "07-19-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Villafana",
		 "CLAIMANT_FIRST_NAME": "Dianna",
		 "CLAIM_NO": "2022-01-27-0916-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "01-21-2022 ",
		 "DATE_CLAIM_CLOSED": "03-15-2022 ",
		 "ACTUAL_RTW_DATE": "02-07-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Villafuerte",
		 "CLAIMANT_FIRST_NAME": "Priscilla",
		 "CLAIM_NO": "2019-02-15-0349-VP-01",
		 "DURATION": 67,
		 "DISABILITY_START_DATE": "02-21-2019 ",
		 "DATE_CLAIM_CLOSED": "05-01-2019 ",
		 "ACTUAL_RTW_DATE": "04-29-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Villafuerte",
		 "CLAIMANT_FIRST_NAME": "Priscilla",
		 "CLAIM_NO": "2016-03-09-0152-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "03-07-2016 ",
		 "DATE_CLAIM_CLOSED": "03-30-2016 ",
		 "ACTUAL_RTW_DATE": "03-22-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Villagrana Perez",
		 "CLAIMANT_FIRST_NAME": "Esmeralda",
		 "CLAIM_NO": "2021-11-29-0322-VP-01",
		 "DURATION": 78,
		 "DISABILITY_START_DATE": "11-24-2021 ",
		 "DATE_CLAIM_CLOSED": "02-14-2022 ",
		 "ACTUAL_RTW_DATE": "02-10-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Villalpando",
		 "CLAIMANT_FIRST_NAME": "Gabriela",
		 "CLAIM_NO": "2018-01-30-0459-VP-01",
		 "DURATION": 87,
		 "DISABILITY_START_DATE": "01-29-2018 ",
		 "DATE_CLAIM_CLOSED": "05-15-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Villanueva",
		 "CLAIMANT_FIRST_NAME": "Jacqueline",
		 "CLAIM_NO": "2014-09-25-0381-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "10-20-2014 ",
		 "DATE_CLAIM_CLOSED": "12-08-2014 ",
		 "ACTUAL_RTW_DATE": "12-05-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Villareal",
		 "CLAIMANT_FIRST_NAME": "Plaridel",
		 "CLAIM_NO": "2021-10-18-0667-VP-01",
		 "DURATION": -7,
		 "DISABILITY_START_DATE": "10-25-2021 ",
		 "DATE_CLAIM_CLOSED": "12-09-2021 ",
		 "ACTUAL_RTW_DATE": "10-18-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Villarojas",
		 "CLAIMANT_FIRST_NAME": "Maribel",
		 "CLAIM_NO": "2020-09-02-0270-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "09-22-2020 ",
		 "DATE_CLAIM_CLOSED": "11-09-2020 ",
		 "ACTUAL_RTW_DATE": "11-09-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Villarojas",
		 "CLAIMANT_FIRST_NAME": "Maribel",
		 "CLAIM_NO": "2016-09-23-0502-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "09-19-2016 ",
		 "DATE_CLAIM_CLOSED": "10-07-2016 ",
		 "ACTUAL_RTW_DATE": "09-26-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Villarojas",
		 "CLAIMANT_FIRST_NAME": "Maribel",
		 "CLAIM_NO": "2020-04-03-0429-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "04-02-2020 ",
		 "DATE_CLAIM_CLOSED": "04-08-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Villarojas",
		 "CLAIMANT_FIRST_NAME": "Maribel",
		 "CLAIM_NO": "2021-08-06-0516-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "08-09-2021 ",
		 "DATE_CLAIM_CLOSED": "11-22-2021 ",
		 "ACTUAL_RTW_DATE": "11-08-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Villasenor",
		 "CLAIMANT_FIRST_NAME": "Carmen",
		 "CLAIM_NO": "2022-08-26-0718-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "08-25-2022 ",
		 "DATE_CLAIM_CLOSED": "01-16-2023 ",
		 "ACTUAL_RTW_DATE": "09-13-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vining",
		 "CLAIMANT_FIRST_NAME": "Chelsi",
		 "CLAIM_NO": "2019-05-06-0543-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "06-04-2019 ",
		 "DATE_CLAIM_CLOSED": "08-06-2019 ",
		 "ACTUAL_RTW_DATE": "07-30-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vinmans",
		 "CLAIMANT_FIRST_NAME": "Denise",
		 "CLAIM_NO": "2016-02-23-0375-VP-01",
		 "DURATION": 26,
		 "DISABILITY_START_DATE": "02-17-2016 ",
		 "DATE_CLAIM_CLOSED": "03-22-2016 ",
		 "ACTUAL_RTW_DATE": "03-14-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vira",
		 "CLAIMANT_FIRST_NAME": "Kalpana",
		 "CLAIM_NO": "2014-06-25-0495-VP-01",
		 "DURATION": 363,
		 "DISABILITY_START_DATE": "06-23-2014 ",
		 "DATE_CLAIM_CLOSED": "06-24-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Virula",
		 "CLAIMANT_FIRST_NAME": "Aura",
		 "CLAIM_NO": "2020-03-10-0631-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "03-05-2020 ",
		 "DATE_CLAIM_CLOSED": "04-07-2020 ",
		 "ACTUAL_RTW_DATE": "04-06-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vlacos",
		 "CLAIMANT_FIRST_NAME": "Marianne",
		 "CLAIM_NO": "2022-09-07-0787-VP-01",
		 "DURATION": 95,
		 "DISABILITY_START_DATE": "11-03-2022 ",
		 "DATE_CLAIM_CLOSED": "02-09-2023 ",
		 "ACTUAL_RTW_DATE": "02-06-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vo",
		 "CLAIMANT_FIRST_NAME": "Thien",
		 "CLAIM_NO": "2018-01-19-0349-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "01-15-2018 ",
		 "DATE_CLAIM_CLOSED": "05-31-2018 ",
		 "ACTUAL_RTW_DATE": "01-29-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vo",
		 "CLAIMANT_FIRST_NAME": "Neil",
		 "CLAIM_NO": "2018-03-30-0027-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "03-27-2018 ",
		 "DATE_CLAIM_CLOSED": "04-17-2018 ",
		 "ACTUAL_RTW_DATE": "04-13-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vo",
		 "CLAIMANT_FIRST_NAME": "Trung",
		 "CLAIM_NO": "2017-04-04-0007-VP-01",
		 "DURATION": 31,
		 "DISABILITY_START_DATE": "04-14-2017 ",
		 "DATE_CLAIM_CLOSED": "05-22-2017 ",
		 "ACTUAL_RTW_DATE": "05-15-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vo",
		 "CLAIMANT_FIRST_NAME": "Triet",
		 "CLAIM_NO": "2021-03-04-0020-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "03-02-2021 ",
		 "DATE_CLAIM_CLOSED": "05-13-2021 ",
		 "ACTUAL_RTW_DATE": "04-29-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Voelkel",
		 "CLAIMANT_FIRST_NAME": "Joyce",
		 "CLAIM_NO": "2014-08-15-0429-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "08-15-2014 ",
		 "DATE_CLAIM_CLOSED": "10-27-2014 ",
		 "ACTUAL_RTW_DATE": "10-13-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vogl",
		 "CLAIMANT_FIRST_NAME": "Brian",
		 "CLAIM_NO": "2020-06-11-0605-VP-01",
		 "DURATION": 137,
		 "DISABILITY_START_DATE": "06-11-2020 ",
		 "DATE_CLAIM_CLOSED": "10-28-2020 ",
		 "ACTUAL_RTW_DATE": "10-26-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vondersaar",
		 "CLAIMANT_FIRST_NAME": "Melanie",
		 "CLAIM_NO": "2019-04-04-0596-VP-01",
		 "DURATION": 38,
		 "DISABILITY_START_DATE": "04-22-2019 ",
		 "DATE_CLAIM_CLOSED": "06-27-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vrugt",
		 "CLAIMANT_FIRST_NAME": "Lisa",
		 "CLAIM_NO": "2014-11-19-0438-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "12-08-2014 ",
		 "DATE_CLAIM_CLOSED": "02-09-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vu",
		 "CLAIMANT_FIRST_NAME": "Minh-Thien",
		 "CLAIM_NO": "2015-09-30-0550-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "10-27-2015 ",
		 "DATE_CLAIM_CLOSED": "12-30-2015 ",
		 "ACTUAL_RTW_DATE": "12-23-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vu",
		 "CLAIMANT_FIRST_NAME": "Lan",
		 "CLAIM_NO": "2016-10-31-0394-VP-01",
		 "DURATION": 74,
		 "DISABILITY_START_DATE": "10-27-2016 ",
		 "DATE_CLAIM_CLOSED": "01-11-2017 ",
		 "ACTUAL_RTW_DATE": "01-09-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vu",
		 "CLAIMANT_FIRST_NAME": "Peter",
		 "CLAIM_NO": "2014-02-07-0501-VP-01",
		 "DURATION": 454,
		 "DISABILITY_START_DATE": "02-06-2014 ",
		 "DATE_CLAIM_CLOSED": "05-18-2015 ",
		 "ACTUAL_RTW_DATE": "05-06-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vu",
		 "CLAIMANT_FIRST_NAME": "Truong",
		 "CLAIM_NO": "2019-08-08-0567-VP-01",
		 "DURATION": 3,
		 "DISABILITY_START_DATE": "08-06-2019 ",
		 "DATE_CLAIM_CLOSED": "09-06-2019 ",
		 "ACTUAL_RTW_DATE": "08-09-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vu",
		 "CLAIMANT_FIRST_NAME": "Minh-Thien",
		 "CLAIM_NO": "2014-08-04-0325-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "09-03-2014 ",
		 "DATE_CLAIM_CLOSED": "11-03-2014 ",
		 "ACTUAL_RTW_DATE": "10-30-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vu",
		 "CLAIMANT_FIRST_NAME": "Tammy",
		 "CLAIM_NO": "2014-06-19-0073-VP-01",
		 "DURATION": 52,
		 "DISABILITY_START_DATE": "07-21-2014 ",
		 "DATE_CLAIM_CLOSED": "03-23-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vutam",
		 "CLAIMANT_FIRST_NAME": "Victoria",
		 "CLAIM_NO": "2015-05-05-0434-VP-01",
		 "DURATION": 39,
		 "DISABILITY_START_DATE": "05-07-2015 ",
		 "DATE_CLAIM_CLOSED": "07-13-2015 ",
		 "ACTUAL_RTW_DATE": "06-15-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Vutam",
		 "CLAIMANT_FIRST_NAME": "Victoria",
		 "CLAIM_NO": "2017-06-12-0619-VP-01",
		 "DURATION": 75,
		 "DISABILITY_START_DATE": "06-19-2017 ",
		 "DATE_CLAIM_CLOSED": "09-07-2017 ",
		 "ACTUAL_RTW_DATE": "09-02-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Waldau",
		 "CLAIMANT_FIRST_NAME": "Diana",
		 "CLAIM_NO": "2016-06-13-0031-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "06-06-2016 ",
		 "DATE_CLAIM_CLOSED": "07-26-2016 ",
		 "ACTUAL_RTW_DATE": "06-22-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Walker",
		 "CLAIMANT_FIRST_NAME": "Matthew",
		 "CLAIM_NO": "2014-11-24-0254-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "12-08-2014 ",
		 "DATE_CLAIM_CLOSED": "02-09-2015 ",
		 "ACTUAL_RTW_DATE": "12-29-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Walker",
		 "CLAIMANT_FIRST_NAME": "Matthew",
		 "CLAIM_NO": "2021-11-10-1162-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "11-29-2021 ",
		 "DATE_CLAIM_CLOSED": "01-25-2022 ",
		 "ACTUAL_RTW_DATE": "01-24-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wallace",
		 "CLAIMANT_FIRST_NAME": "Molly",
		 "CLAIM_NO": "2017-07-21-0539-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "07-24-2017 ",
		 "DATE_CLAIM_CLOSED": "08-21-2017 ",
		 "ACTUAL_RTW_DATE": "08-07-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wallace",
		 "CLAIMANT_FIRST_NAME": "Molly",
		 "CLAIM_NO": "2020-03-17-0554-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "05-04-2020 ",
		 "DATE_CLAIM_CLOSED": "08-11-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wallace",
		 "CLAIMANT_FIRST_NAME": "Molly",
		 "CLAIM_NO": "2022-07-25-0598-VP-01",
		 "DURATION": 86,
		 "DISABILITY_START_DATE": "08-08-2022 ",
		 "DATE_CLAIM_CLOSED": "11-04-2022 ",
		 "ACTUAL_RTW_DATE": "11-02-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wallick",
		 "CLAIMANT_FIRST_NAME": "Sarah",
		 "CLAIM_NO": "2020-04-28-0490-VP-01",
		 "DURATION": 96,
		 "DISABILITY_START_DATE": "06-01-2020 ",
		 "DATE_CLAIM_CLOSED": "09-08-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Walters",
		 "CLAIMANT_FIRST_NAME": "Tonya",
		 "CLAIM_NO": "2018-10-18-0128-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "10-18-2018 ",
		 "DATE_CLAIM_CLOSED": "01-29-2019 ",
		 "ACTUAL_RTW_DATE": "01-10-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Walters",
		 "CLAIMANT_FIRST_NAME": "Tonya",
		 "CLAIM_NO": "2019-02-23-0052-VP-01",
		 "DURATION": 218,
		 "DISABILITY_START_DATE": "02-25-2019 ",
		 "DATE_CLAIM_CLOSED": "06-10-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Walters",
		 "CLAIMANT_FIRST_NAME": "Tonya",
		 "CLAIM_NO": "2018-05-29-0557-VP-01",
		 "DURATION": 96,
		 "DISABILITY_START_DATE": "05-30-2018 ",
		 "DATE_CLAIM_CLOSED": "09-05-2018 ",
		 "ACTUAL_RTW_DATE": "09-03-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Walton",
		 "CLAIMANT_FIRST_NAME": "Karen",
		 "CLAIM_NO": "2015-01-23-0541-VP-01",
		 "DURATION": 81,
		 "DISABILITY_START_DATE": "01-15-2015 ",
		 "DATE_CLAIM_CLOSED": "04-17-2015 ",
		 "ACTUAL_RTW_DATE": "04-06-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Walts",
		 "CLAIMANT_FIRST_NAME": "Jan",
		 "CLAIM_NO": "2020-10-09-0223-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "11-02-2020 ",
		 "DATE_CLAIM_CLOSED": "01-06-2021 ",
		 "ACTUAL_RTW_DATE": "01-04-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Walz",
		 "CLAIMANT_FIRST_NAME": "Michele",
		 "CLAIM_NO": "2015-06-12-0833-VP-01",
		 "DURATION": 50,
		 "DISABILITY_START_DATE": "07-07-2015 ",
		 "DATE_CLAIM_CLOSED": "09-10-2015 ",
		 "ACTUAL_RTW_DATE": "08-26-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Walz",
		 "CLAIMANT_FIRST_NAME": "Michele",
		 "CLAIM_NO": "2018-04-18-0405-VP-01",
		 "DURATION": 121,
		 "DISABILITY_START_DATE": "05-07-2018 ",
		 "DATE_CLAIM_CLOSED": "09-17-2018 ",
		 "ACTUAL_RTW_DATE": "09-05-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wang",
		 "CLAIMANT_FIRST_NAME": "Jiaxi",
		 "CLAIM_NO": "2021-05-25-0893-VP-01",
		 "DURATION": 119,
		 "DISABILITY_START_DATE": "06-07-2021 ",
		 "DATE_CLAIM_CLOSED": "11-04-2021 ",
		 "ACTUAL_RTW_DATE": "10-04-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wang",
		 "CLAIMANT_FIRST_NAME": "Xialing",
		 "CLAIM_NO": "2020-10-05-1026-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "11-04-2020 ",
		 "DATE_CLAIM_CLOSED": "12-16-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wang",
		 "CLAIMANT_FIRST_NAME": "Jeffrey",
		 "CLAIM_NO": "2014-07-02-0876-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "07-07-2014 ",
		 "DATE_CLAIM_CLOSED": "12-09-2014 ",
		 "ACTUAL_RTW_DATE": "08-25-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wang",
		 "CLAIMANT_FIRST_NAME": "Hui",
		 "CLAIM_NO": "2019-07-19-0204-VP-01",
		 "DURATION": 182,
		 "DISABILITY_START_DATE": "08-12-2019 ",
		 "DATE_CLAIM_CLOSED": "02-12-2020 ",
		 "ACTUAL_RTW_DATE": "02-10-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wang",
		 "CLAIMANT_FIRST_NAME": "Tianzi",
		 "CLAIM_NO": "2021-04-19-0667-VP-01",
		 "DURATION": 73,
		 "DISABILITY_START_DATE": "05-24-2021 ",
		 "DATE_CLAIM_CLOSED": "07-19-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wang",
		 "CLAIMANT_FIRST_NAME": "Jiaxi",
		 "CLAIM_NO": "2018-06-15-0460-VP-01",
		 "DURATION": 74,
		 "DISABILITY_START_DATE": "06-28-2018 ",
		 "DATE_CLAIM_CLOSED": "09-11-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wang",
		 "CLAIMANT_FIRST_NAME": "Xiaoyue",
		 "CLAIM_NO": "2013-03-08-0169-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "03-25-2013 ",
		 "DATE_CLAIM_CLOSED": "07-03-2013 ",
		 "ACTUAL_RTW_DATE": "05-04-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wang",
		 "CLAIMANT_FIRST_NAME": "Linxiang",
		 "CLAIM_NO": "2019-06-11-0715-VP-01",
		 "DURATION": 67,
		 "DISABILITY_START_DATE": "07-08-2019 ",
		 "DATE_CLAIM_CLOSED": "09-13-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wang",
		 "CLAIMANT_FIRST_NAME": "Xiaoyue",
		 "CLAIM_NO": "2018-06-19-0515-VP-01",
		 "DURATION": 52,
		 "DISABILITY_START_DATE": "07-09-2018 ",
		 "DATE_CLAIM_CLOSED": "08-23-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Ward",
		 "CLAIMANT_FIRST_NAME": "Heather",
		 "CLAIM_NO": "2018-04-23-0604-VP-01",
		 "DURATION": 95,
		 "DISABILITY_START_DATE": "05-14-2018 ",
		 "DATE_CLAIM_CLOSED": "08-20-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wark",
		 "CLAIMANT_FIRST_NAME": "Ginette",
		 "CLAIM_NO": "2018-04-05-0183-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "04-02-2018 ",
		 "DATE_CLAIM_CLOSED": "04-24-2018 ",
		 "ACTUAL_RTW_DATE": "04-23-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wark",
		 "CLAIMANT_FIRST_NAME": "Ginette",
		 "CLAIM_NO": "2017-03-15-0020-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "03-06-2017 ",
		 "DATE_CLAIM_CLOSED": "03-21-2017 ",
		 "ACTUAL_RTW_DATE": "03-20-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wark",
		 "CLAIMANT_FIRST_NAME": "Ginette",
		 "CLAIM_NO": "2018-09-07-0187-VP-01",
		 "DURATION": 201,
		 "DISABILITY_START_DATE": "09-19-2018 ",
		 "DATE_CLAIM_CLOSED": "04-08-2019 ",
		 "ACTUAL_RTW_DATE": "04-08-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wark",
		 "CLAIMANT_FIRST_NAME": "Ginette",
		 "CLAIM_NO": "2013-01-29-0261-VP-01",
		 "DURATION": 111,
		 "DISABILITY_START_DATE": "01-25-2013 ",
		 "DATE_CLAIM_CLOSED": "05-31-2013 ",
		 "ACTUAL_RTW_DATE": "05-16-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wark",
		 "CLAIMANT_FIRST_NAME": "Ginette",
		 "CLAIM_NO": "2016-08-12-0303-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "08-10-2016 ",
		 "DATE_CLAIM_CLOSED": "10-17-2016 ",
		 "ACTUAL_RTW_DATE": "09-28-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wark",
		 "CLAIMANT_FIRST_NAME": "Ginette",
		 "CLAIM_NO": "2020-01-13-0680-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "01-09-2020 ",
		 "DATE_CLAIM_CLOSED": "02-10-2020 ",
		 "ACTUAL_RTW_DATE": "02-06-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wark",
		 "CLAIMANT_FIRST_NAME": "Ginette",
		 "CLAIM_NO": "2015-12-21-0186-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "12-17-2015 ",
		 "DATE_CLAIM_CLOSED": "01-11-2016 ",
		 "ACTUAL_RTW_DATE": "12-29-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wark",
		 "CLAIMANT_FIRST_NAME": "Ginette",
		 "CLAIM_NO": "2017-09-28-0087-VP-01",
		 "DURATION": 51,
		 "DISABILITY_START_DATE": "09-26-2017 ",
		 "DATE_CLAIM_CLOSED": "12-08-2017 ",
		 "ACTUAL_RTW_DATE": "11-16-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wark",
		 "CLAIMANT_FIRST_NAME": "Ginette",
		 "CLAIM_NO": "2021-04-14-0335-VP-01",
		 "DURATION": 179,
		 "DISABILITY_START_DATE": "04-05-2021 ",
		 "DATE_CLAIM_CLOSED": "10-07-2021 ",
		 "ACTUAL_RTW_DATE": "10-01-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wark",
		 "CLAIMANT_FIRST_NAME": "Ginette",
		 "CLAIM_NO": "2016-02-15-0101-VP-01",
		 "DURATION": 7,
		 "DISABILITY_START_DATE": "02-08-2016 ",
		 "DATE_CLAIM_CLOSED": "05-18-2016 ",
		 "ACTUAL_RTW_DATE": "02-15-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wark",
		 "CLAIMANT_FIRST_NAME": "Ginette",
		 "CLAIM_NO": "2013-08-15-0439-VP-01",
		 "DURATION": 192,
		 "DISABILITY_START_DATE": "08-26-2013 ",
		 "DATE_CLAIM_CLOSED": "04-10-2014 ",
		 "ACTUAL_RTW_DATE": "03-06-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Warner",
		 "CLAIMANT_FIRST_NAME": "Cristina",
		 "CLAIM_NO": "2013-06-11-0469-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "06-17-2013 ",
		 "DATE_CLAIM_CLOSED": "07-24-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Warner",
		 "CLAIMANT_FIRST_NAME": "Cristina",
		 "CLAIM_NO": "2013-07-03-0157-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "06-17-2013 ",
		 "DATE_CLAIM_CLOSED": "08-06-2013 ",
		 "ACTUAL_RTW_DATE": "08-05-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Washington",
		 "CLAIMANT_FIRST_NAME": "Marlene",
		 "CLAIM_NO": "2013-01-05-0172-VP-01",
		 "DURATION": 333,
		 "DISABILITY_START_DATE": "11-29-2012 ",
		 "DATE_CLAIM_CLOSED": "11-07-2013 ",
		 "ACTUAL_RTW_DATE": "10-28-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wassom",
		 "CLAIMANT_FIRST_NAME": "D.",
		 "CLAIM_NO": "2013-01-11-0401-VP-01",
		 "DURATION": 44,
		 "DISABILITY_START_DATE": "01-02-2013 ",
		 "DATE_CLAIM_CLOSED": "05-21-2013 ",
		 "ACTUAL_RTW_DATE": "02-15-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Watcha",
		 "CLAIMANT_FIRST_NAME": "Ameliza",
		 "CLAIM_NO": "2017-01-12-0364-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "01-16-2017 ",
		 "DATE_CLAIM_CLOSED": "02-06-2017 ",
		 "ACTUAL_RTW_DATE": "01-31-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Watson",
		 "CLAIMANT_FIRST_NAME": "Merilyn",
		 "CLAIM_NO": "2013-01-28-0175-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "01-28-2013 ",
		 "DATE_CLAIM_CLOSED": "01-29-2014 ",
		 "ACTUAL_RTW_DATE": "01-27-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Watson",
		 "CLAIMANT_FIRST_NAME": "Michael",
		 "CLAIM_NO": "2014-03-07-0119-VP-01",
		 "DURATION": 96,
		 "DISABILITY_START_DATE": "03-26-2014 ",
		 "DATE_CLAIM_CLOSED": "09-08-2014 ",
		 "ACTUAL_RTW_DATE": "06-30-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Watt",
		 "CLAIMANT_FIRST_NAME": "Eugene",
		 "CLAIM_NO": "2016-10-27-0059-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "10-28-2016 ",
		 "DATE_CLAIM_CLOSED": "12-12-2016 ",
		 "ACTUAL_RTW_DATE": "11-14-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Webb",
		 "CLAIMANT_FIRST_NAME": "Donald",
		 "CLAIM_NO": "2019-03-07-0696-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "02-28-2019 ",
		 "DATE_CLAIM_CLOSED": "03-02-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Weidner",
		 "CLAIMANT_FIRST_NAME": "Charles",
		 "CLAIM_NO": "2016-01-08-0502-VP-01",
		 "DURATION": 98,
		 "DISABILITY_START_DATE": "01-11-2016 ",
		 "DATE_CLAIM_CLOSED": "04-18-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Weidner",
		 "CLAIMANT_FIRST_NAME": "Charles",
		 "CLAIM_NO": "2014-04-17-0419-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "04-18-2014 ",
		 "DATE_CLAIM_CLOSED": "06-30-2014 ",
		 "ACTUAL_RTW_DATE": "05-05-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Weinberg",
		 "CLAIMANT_FIRST_NAME": "Lisa",
		 "CLAIM_NO": "2019-01-31-0661-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "01-28-2019 ",
		 "DATE_CLAIM_CLOSED": "03-13-2019 ",
		 "ACTUAL_RTW_DATE": "03-11-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wells",
		 "CLAIMANT_FIRST_NAME": "Jennell",
		 "CLAIM_NO": "2013-01-05-0810-VP-01",
		 "DURATION": 161,
		 "DISABILITY_START_DATE": "09-24-2012 ",
		 "DATE_CLAIM_CLOSED": "05-21-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "West",
		 "CLAIMANT_FIRST_NAME": "Wendi",
		 "CLAIM_NO": "2013-11-27-0178-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "12-02-2013 ",
		 "DATE_CLAIM_CLOSED": "04-07-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "West",
		 "CLAIMANT_FIRST_NAME": "Wendi",
		 "CLAIM_NO": "2016-03-24-0160-VP-01",
		 "DURATION": 97,
		 "DISABILITY_START_DATE": "04-06-2016 ",
		 "DATE_CLAIM_CLOSED": "07-20-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "West",
		 "CLAIMANT_FIRST_NAME": "Wendi",
		 "CLAIM_NO": "2019-11-22-0574-VP-01",
		 "DURATION": 178,
		 "DISABILITY_START_DATE": "11-26-2019 ",
		 "DATE_CLAIM_CLOSED": "05-26-2020 ",
		 "ACTUAL_RTW_DATE": "05-22-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wetzel",
		 "CLAIMANT_FIRST_NAME": "Darren",
		 "CLAIM_NO": "2021-08-16-0500-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "08-16-2021 ",
		 "DATE_CLAIM_CLOSED": "09-01-2021 ",
		 "ACTUAL_RTW_DATE": "08-30-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "White",
		 "CLAIMANT_FIRST_NAME": "Kenneth",
		 "CLAIM_NO": "2019-10-08-0491-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "10-15-2019 ",
		 "DATE_CLAIM_CLOSED": "12-31-2019 ",
		 "ACTUAL_RTW_DATE": "10-31-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "White",
		 "CLAIMANT_FIRST_NAME": "Kenneth",
		 "CLAIM_NO": "2013-08-22-0060-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "08-17-2013 ",
		 "DATE_CLAIM_CLOSED": "10-23-2013 ",
		 "ACTUAL_RTW_DATE": "09-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "White",
		 "CLAIMANT_FIRST_NAME": "Emily",
		 "CLAIM_NO": "2013-08-26-0143-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "08-20-2013 ",
		 "DATE_CLAIM_CLOSED": "11-06-2013 ",
		 "ACTUAL_RTW_DATE": "09-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "White",
		 "CLAIMANT_FIRST_NAME": "James",
		 "CLAIM_NO": "2021-01-27-0509-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "01-28-2021 ",
		 "DATE_CLAIM_CLOSED": "02-28-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "White",
		 "CLAIMANT_FIRST_NAME": "Kelsey",
		 "CLAIM_NO": "2014-06-11-0481-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "06-30-2014 ",
		 "DATE_CLAIM_CLOSED": "12-17-2014 ",
		 "ACTUAL_RTW_DATE": "08-04-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "White",
		 "CLAIMANT_FIRST_NAME": "Vincent",
		 "CLAIM_NO": "2020-10-06-0704-VP-01",
		 "DURATION": 182,
		 "DISABILITY_START_DATE": "08-31-2020 ",
		 "DATE_CLAIM_CLOSED": "02-11-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Whitlow",
		 "CLAIMANT_FIRST_NAME": "Ryan",
		 "CLAIM_NO": "2021-02-10-0175-VP-01",
		 "DURATION": 12,
		 "DISABILITY_START_DATE": "01-29-2021 ",
		 "DATE_CLAIM_CLOSED": "02-25-2021 ",
		 "ACTUAL_RTW_DATE": "02-10-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Whitlow",
		 "CLAIMANT_FIRST_NAME": "Ryan",
		 "CLAIM_NO": "2021-01-27-0419-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "01-18-2021 ",
		 "DATE_CLAIM_CLOSED": "01-28-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Whittington",
		 "CLAIMANT_FIRST_NAME": "Elizabeth",
		 "CLAIM_NO": "2019-10-30-0239-VP-01",
		 "DURATION": 129,
		 "DISABILITY_START_DATE": "10-29-2019 ",
		 "DATE_CLAIM_CLOSED": "03-10-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Whittington",
		 "CLAIMANT_FIRST_NAME": "Elizabeth",
		 "CLAIM_NO": "2015-11-03-0022-VP-01",
		 "DURATION": 89,
		 "DISABILITY_START_DATE": "11-10-2015 ",
		 "DATE_CLAIM_CLOSED": "02-08-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Widen",
		 "CLAIMANT_FIRST_NAME": "Andrea",
		 "CLAIM_NO": "2016-05-27-0370-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "06-03-2016 ",
		 "DATE_CLAIM_CLOSED": "07-05-2016 ",
		 "ACTUAL_RTW_DATE": "07-02-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wieberg",
		 "CLAIMANT_FIRST_NAME": "Laila",
		 "CLAIM_NO": "2015-06-03-0283-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "06-11-2015 ",
		 "DATE_CLAIM_CLOSED": "08-03-2015 ",
		 "ACTUAL_RTW_DATE": "07-24-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wieberg",
		 "CLAIMANT_FIRST_NAME": "Laila",
		 "CLAIM_NO": "2018-01-30-0697-VP-01",
		 "DURATION": 52,
		 "DISABILITY_START_DATE": "02-09-2018 ",
		 "DATE_CLAIM_CLOSED": "04-03-2018 ",
		 "ACTUAL_RTW_DATE": "04-02-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wiggin",
		 "CLAIMANT_FIRST_NAME": "Molly",
		 "CLAIM_NO": "2022-10-06-0969-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "10-18-2022 ",
		 "DATE_CLAIM_CLOSED": "01-24-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wilkerson",
		 "CLAIMANT_FIRST_NAME": "Sherry",
		 "CLAIM_NO": "2013-04-02-0372-VP-01",
		 "DURATION": 183,
		 "DISABILITY_START_DATE": "04-01-2013 ",
		 "DATE_CLAIM_CLOSED": "10-07-2013 ",
		 "ACTUAL_RTW_DATE": "10-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Williams",
		 "CLAIMANT_FIRST_NAME": "Linda",
		 "CLAIM_NO": "2019-11-22-0379-VP-01",
		 "DURATION": 168,
		 "DISABILITY_START_DATE": "12-02-2019 ",
		 "DATE_CLAIM_CLOSED": "05-21-2020 ",
		 "ACTUAL_RTW_DATE": "05-18-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Williams",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2014-01-28-0120-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "01-27-2014 ",
		 "DATE_CLAIM_CLOSED": "09-08-2014 ",
		 "ACTUAL_RTW_DATE": "02-24-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Williams",
		 "CLAIMANT_FIRST_NAME": "Lance",
		 "CLAIM_NO": "2017-06-23-0314-VP-01",
		 "DURATION": 67,
		 "DISABILITY_START_DATE": "09-14-2017 ",
		 "DATE_CLAIM_CLOSED": "11-20-2017 ",
		 "ACTUAL_RTW_DATE": "11-20-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Williams",
		 "CLAIMANT_FIRST_NAME": "Dreama",
		 "CLAIM_NO": "2021-04-19-0622-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "04-12-2021 ",
		 "DATE_CLAIM_CLOSED": "04-28-2021 ",
		 "ACTUAL_RTW_DATE": "04-22-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Williams",
		 "CLAIMANT_FIRST_NAME": "Kristine",
		 "CLAIM_NO": "2013-02-26-0435-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "03-06-2013 ",
		 "DATE_CLAIM_CLOSED": "06-27-2013 ",
		 "ACTUAL_RTW_DATE": "05-13-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Williams",
		 "CLAIMANT_FIRST_NAME": "Dreama",
		 "CLAIM_NO": "2014-10-17-0157-VP-01",
		 "DURATION": 95,
		 "DISABILITY_START_DATE": "10-17-2014 ",
		 "DATE_CLAIM_CLOSED": "01-26-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Williams",
		 "CLAIMANT_FIRST_NAME": "Dreama",
		 "CLAIM_NO": "2013-09-25-0023-VP-01",
		 "DURATION": 104,
		 "DISABILITY_START_DATE": "09-30-2013 ",
		 "DATE_CLAIM_CLOSED": "01-21-2014 ",
		 "ACTUAL_RTW_DATE": "01-12-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Williamson",
		 "CLAIMANT_FIRST_NAME": "Tara",
		 "CLAIM_NO": "2015-12-07-0615-VP-01",
		 "DURATION": 51,
		 "DISABILITY_START_DATE": "01-04-2016 ",
		 "DATE_CLAIM_CLOSED": "02-24-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Williamson",
		 "CLAIMANT_FIRST_NAME": "Robert",
		 "CLAIM_NO": "2014-10-23-0392-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "10-15-2014 ",
		 "DATE_CLAIM_CLOSED": "12-08-2014 ",
		 "ACTUAL_RTW_DATE": "11-06-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Williamson",
		 "CLAIMANT_FIRST_NAME": "Tara",
		 "CLAIM_NO": "2018-04-05-0610-VP-01",
		 "DURATION": 73,
		 "DISABILITY_START_DATE": "04-27-2018 ",
		 "DATE_CLAIM_CLOSED": "07-09-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Williamson",
		 "CLAIMANT_FIRST_NAME": "Robert",
		 "CLAIM_NO": "2022-08-28-0053-VP-01",
		 "DURATION": 19,
		 "DISABILITY_START_DATE": "08-22-2022 ",
		 "DATE_CLAIM_CLOSED": "09-16-2022 ",
		 "ACTUAL_RTW_DATE": "09-10-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Willingham",
		 "CLAIMANT_FIRST_NAME": "Bruce",
		 "CLAIM_NO": "2021-09-28-0705-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "10-29-2021 ",
		 "DATE_CLAIM_CLOSED": "01-03-2022 ",
		 "ACTUAL_RTW_DATE": "12-27-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wills",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2015-10-28-0268-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "11-24-2015 ",
		 "DATE_CLAIM_CLOSED": "12-15-2015 ",
		 "ACTUAL_RTW_DATE": "12-14-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wills",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2017-01-23-0091-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "01-19-2017 ",
		 "DATE_CLAIM_CLOSED": "03-07-2017 ",
		 "ACTUAL_RTW_DATE": "02-20-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wills",
		 "CLAIMANT_FIRST_NAME": "David",
		 "CLAIM_NO": "2014-06-24-0404-VP-01",
		 "DURATION": 111,
		 "DISABILITY_START_DATE": "06-17-2014 ",
		 "DATE_CLAIM_CLOSED": "10-20-2014 ",
		 "ACTUAL_RTW_DATE": "10-06-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Willson",
		 "CLAIMANT_FIRST_NAME": "Donald",
		 "CLAIM_NO": "2020-06-04-0345-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "06-10-2020 ",
		 "DATE_CLAIM_CLOSED": "07-14-2020 ",
		 "ACTUAL_RTW_DATE": "07-13-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wilson",
		 "CLAIMANT_FIRST_NAME": "Mark",
		 "CLAIM_NO": "2013-01-05-0245-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "11-23-2012 ",
		 "DATE_CLAIM_CLOSED": "01-21-2013 ",
		 "ACTUAL_RTW_DATE": "01-21-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wilson",
		 "CLAIMANT_FIRST_NAME": "Robyn",
		 "CLAIM_NO": "2013-01-05-1478-VP-01",
		 "DURATION": 163,
		 "DISABILITY_START_DATE": "07-24-2012 ",
		 "DATE_CLAIM_CLOSED": "01-07-2013 ",
		 "ACTUAL_RTW_DATE": "01-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wilson",
		 "CLAIMANT_FIRST_NAME": "Robyn",
		 "CLAIM_NO": "2014-04-24-0269-VP-01",
		 "DURATION": 66,
		 "DISABILITY_START_DATE": "04-25-2014 ",
		 "DATE_CLAIM_CLOSED": "08-15-2014 ",
		 "ACTUAL_RTW_DATE": "06-30-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wiltermuth",
		 "CLAIMANT_FIRST_NAME": "Erica",
		 "CLAIM_NO": "2013-01-05-0037-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "01-21-2013 ",
		 "DATE_CLAIM_CLOSED": "04-26-2013 ",
		 "ACTUAL_RTW_DATE": "03-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Winantan",
		 "CLAIMANT_FIRST_NAME": "Linda",
		 "CLAIM_NO": "2019-12-06-0061-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "12-02-2019 ",
		 "DATE_CLAIM_CLOSED": "12-24-2019 ",
		 "ACTUAL_RTW_DATE": "12-17-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wiseman",
		 "CLAIMANT_FIRST_NAME": "Lore",
		 "CLAIM_NO": "2013-03-19-0329-VP-01",
		 "DURATION": 120,
		 "DISABILITY_START_DATE": "03-18-2013 ",
		 "DATE_CLAIM_CLOSED": "07-21-2013 ",
		 "ACTUAL_RTW_DATE": "07-16-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Woerman",
		 "CLAIMANT_FIRST_NAME": "Colleen",
		 "CLAIM_NO": "2022-12-13-0978-VP-01",
		 "DURATION": 29,
		 "DISABILITY_START_DATE": "12-14-2022 ",
		 "DATE_CLAIM_CLOSED": "01-16-2023 ",
		 "ACTUAL_RTW_DATE": "01-12-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Woerman",
		 "CLAIMANT_FIRST_NAME": "Colleen",
		 "CLAIM_NO": "2019-05-10-0562-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "05-08-2019 ",
		 "DATE_CLAIM_CLOSED": "08-19-2019 ",
		 "ACTUAL_RTW_DATE": "08-07-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Woerman",
		 "CLAIMANT_FIRST_NAME": "Colleen",
		 "CLAIM_NO": "2022-06-02-0644-VP-01",
		 "DURATION": 57,
		 "DISABILITY_START_DATE": "06-02-2022 ",
		 "DATE_CLAIM_CLOSED": "08-17-2022 ",
		 "ACTUAL_RTW_DATE": "07-29-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Woerman",
		 "CLAIMANT_FIRST_NAME": "Colleen",
		 "CLAIM_NO": "2020-10-22-0564-VP-01",
		 "DURATION": 22,
		 "DISABILITY_START_DATE": "10-21-2020 ",
		 "DATE_CLAIM_CLOSED": "11-13-2020 ",
		 "ACTUAL_RTW_DATE": "11-12-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wong",
		 "CLAIMANT_FIRST_NAME": "Andrew",
		 "CLAIM_NO": "2014-08-25-0316-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "08-21-2014 ",
		 "DATE_CLAIM_CLOSED": "11-03-2014 ",
		 "ACTUAL_RTW_DATE": "09-03-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wong",
		 "CLAIMANT_FIRST_NAME": "Anna",
		 "CLAIM_NO": "2017-07-13-0179-VP-01",
		 "DURATION": 74,
		 "DISABILITY_START_DATE": "07-31-2017 ",
		 "DATE_CLAIM_CLOSED": "10-18-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wong",
		 "CLAIMANT_FIRST_NAME": "Sam",
		 "CLAIM_NO": "2021-06-01-1090-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "06-10-2021 ",
		 "DATE_CLAIM_CLOSED": "06-24-2021 ",
		 "ACTUAL_RTW_DATE": "06-21-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wong",
		 "CLAIMANT_FIRST_NAME": "Caroline",
		 "CLAIM_NO": "2018-07-17-0530-VP-01",
		 "DURATION": 43,
		 "DISABILITY_START_DATE": "07-20-2018 ",
		 "DATE_CLAIM_CLOSED": "09-06-2018 ",
		 "ACTUAL_RTW_DATE": "09-01-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wong",
		 "CLAIMANT_FIRST_NAME": "Anna",
		 "CLAIM_NO": "2021-01-05-0563-VP-01",
		 "DURATION": 95,
		 "DISABILITY_START_DATE": "01-14-2021 ",
		 "DATE_CLAIM_CLOSED": "04-19-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wong",
		 "CLAIMANT_FIRST_NAME": "Linda",
		 "CLAIM_NO": "2012-12-13-6159-VP-01",
		 "DURATION": 94,
		 "DISABILITY_START_DATE": "07-16-2005 ",
		 "DATE_CLAIM_CLOSED": "07-13-2017 ",
		 "ACTUAL_RTW_DATE": "10-18-2005 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wong",
		 "CLAIMANT_FIRST_NAME": "Stanley",
		 "CLAIM_NO": "2014-10-27-0295-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "10-14-2014 ",
		 "DATE_CLAIM_CLOSED": "12-24-2014 ",
		 "ACTUAL_RTW_DATE": "12-15-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Woo",
		 "CLAIMANT_FIRST_NAME": "Jennifer",
		 "CLAIM_NO": "2020-08-03-0773-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "08-06-2020 ",
		 "DATE_CLAIM_CLOSED": "10-01-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Woodruff",
		 "CLAIMANT_FIRST_NAME": "Mary",
		 "CLAIM_NO": "2017-10-23-0316-VP-01",
		 "DURATION": 357,
		 "DISABILITY_START_DATE": "10-09-2017 ",
		 "DATE_CLAIM_CLOSED": "12-05-2018 ",
		 "ACTUAL_RTW_DATE": "10-01-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Woodworth",
		 "CLAIMANT_FIRST_NAME": "Michael",
		 "CLAIM_NO": "2018-09-10-0447-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "09-04-2018 ",
		 "DATE_CLAIM_CLOSED": "10-02-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Worsley",
		 "CLAIMANT_FIRST_NAME": "John",
		 "CLAIM_NO": "2015-08-12-0336-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "09-08-2015 ",
		 "DATE_CLAIM_CLOSED": "10-12-2015 ",
		 "ACTUAL_RTW_DATE": "10-05-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Worsley",
		 "CLAIMANT_FIRST_NAME": "John",
		 "CLAIM_NO": "2015-06-18-0108-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "06-02-2015 ",
		 "DATE_CLAIM_CLOSED": "07-25-2015 ",
		 "ACTUAL_RTW_DATE": "06-18-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Woznicki",
		 "CLAIMANT_FIRST_NAME": "Dai-Trang",
		 "CLAIM_NO": "2015-08-31-0464-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "09-22-2015 ",
		 "DATE_CLAIM_CLOSED": "10-12-2015 ",
		 "ACTUAL_RTW_DATE": "10-06-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wright",
		 "CLAIMANT_FIRST_NAME": "Holly",
		 "CLAIM_NO": "2019-07-29-0729-VP-01",
		 "DURATION": 71,
		 "DISABILITY_START_DATE": "07-19-2019 ",
		 "DATE_CLAIM_CLOSED": "10-03-2019 ",
		 "ACTUAL_RTW_DATE": "09-28-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wright",
		 "CLAIMANT_FIRST_NAME": "Shanna",
		 "CLAIM_NO": "2020-11-03-0757-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "11-11-2020 ",
		 "DATE_CLAIM_CLOSED": "01-14-2021 ",
		 "ACTUAL_RTW_DATE": "12-28-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wright",
		 "CLAIMANT_FIRST_NAME": "Holly",
		 "CLAIM_NO": "2019-02-22-0675-VP-01",
		 "DURATION": 78,
		 "DISABILITY_START_DATE": "02-20-2019 ",
		 "DATE_CLAIM_CLOSED": "05-13-2019 ",
		 "ACTUAL_RTW_DATE": "05-09-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wright",
		 "CLAIMANT_FIRST_NAME": "Holly",
		 "CLAIM_NO": "2019-01-02-0815-VP-01",
		 "DURATION": 38,
		 "DISABILITY_START_DATE": "12-21-2018 ",
		 "DATE_CLAIM_CLOSED": "01-31-2019 ",
		 "ACTUAL_RTW_DATE": "01-28-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wright",
		 "CLAIMANT_FIRST_NAME": "Holly",
		 "CLAIM_NO": "2018-08-29-0488-VP-01",
		 "DURATION": 91,
		 "DISABILITY_START_DATE": "08-29-2018 ",
		 "DATE_CLAIM_CLOSED": "12-12-2018 ",
		 "ACTUAL_RTW_DATE": "11-28-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wu",
		 "CLAIMANT_FIRST_NAME": "Yongjian",
		 "CLAIM_NO": "2019-10-08-0736-VP-01",
		 "DURATION": 39,
		 "DISABILITY_START_DATE": "10-10-2019 ",
		 "DATE_CLAIM_CLOSED": "11-20-2019 ",
		 "ACTUAL_RTW_DATE": "11-18-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wu",
		 "CLAIMANT_FIRST_NAME": "Yongjian",
		 "CLAIM_NO": "2019-04-24-0661-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "04-29-2019 ",
		 "DATE_CLAIM_CLOSED": "05-20-2019 ",
		 "ACTUAL_RTW_DATE": "05-14-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wu",
		 "CLAIMANT_FIRST_NAME": "Yongjian",
		 "CLAIM_NO": "2019-07-30-0716-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "08-02-2019 ",
		 "DATE_CLAIM_CLOSED": "09-06-2019 ",
		 "ACTUAL_RTW_DATE": "08-22-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wurzbacher",
		 "CLAIMANT_FIRST_NAME": "Jacklyn",
		 "CLAIM_NO": "2019-06-21-0366-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "07-16-2019 ",
		 "DATE_CLAIM_CLOSED": "09-06-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wurzbacher",
		 "CLAIMANT_FIRST_NAME": "Jacklyn",
		 "CLAIM_NO": "2021-12-06-0939-VP-01",
		 "DURATION": 41,
		 "DISABILITY_START_DATE": "01-04-2022 ",
		 "DATE_CLAIM_CLOSED": "02-16-2022 ",
		 "ACTUAL_RTW_DATE": "02-14-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wyche",
		 "CLAIMANT_FIRST_NAME": "Celeste",
		 "CLAIM_NO": "2013-04-22-0192-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "05-20-2013 ",
		 "DATE_CLAIM_CLOSED": "08-12-2013 ",
		 "ACTUAL_RTW_DATE": "07-01-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Wynn",
		 "CLAIMANT_FIRST_NAME": "Bruce",
		 "CLAIM_NO": "2013-01-16-0377-VP-01",
		 "DURATION": 364,
		 "DISABILITY_START_DATE": "01-15-2013 ",
		 "DATE_CLAIM_CLOSED": "01-29-2014 ",
		 "ACTUAL_RTW_DATE": "01-14-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Xia",
		 "CLAIMANT_FIRST_NAME": "Su",
		 "CLAIM_NO": "2020-09-30-0456-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "10-01-2020 ",
		 "DATE_CLAIM_CLOSED": "11-13-2020 ",
		 "ACTUAL_RTW_DATE": "11-10-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Xia",
		 "CLAIMANT_FIRST_NAME": "Su",
		 "CLAIM_NO": "2017-01-30-0367-VP-01",
		 "DURATION": 90,
		 "DISABILITY_START_DATE": "01-30-2017 ",
		 "DATE_CLAIM_CLOSED": "05-03-2017 ",
		 "ACTUAL_RTW_DATE": "04-30-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Xia",
		 "CLAIMANT_FIRST_NAME": "Su",
		 "CLAIM_NO": "2019-07-22-0397-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "07-16-2019 ",
		 "DATE_CLAIM_CLOSED": "08-26-2019 ",
		 "ACTUAL_RTW_DATE": "08-20-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Xia",
		 "CLAIMANT_FIRST_NAME": "Songzi",
		 "CLAIM_NO": "2022-07-18-1082-VP-01",
		 "DURATION": 165,
		 "DISABILITY_START_DATE": "08-15-2022 ",
		 "DATE_CLAIM_CLOSED": "10-29-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Xiong",
		 "CLAIMANT_FIRST_NAME": "Choua",
		 "CLAIM_NO": "2021-08-24-0913-VP-01",
		 "DURATION": 98,
		 "DISABILITY_START_DATE": "08-30-2021 ",
		 "DATE_CLAIM_CLOSED": "10-11-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Xiong",
		 "CLAIMANT_FIRST_NAME": "Choua",
		 "CLAIM_NO": "2020-01-29-0265-VP-01",
		 "DURATION": 50,
		 "DISABILITY_START_DATE": "02-06-2020 ",
		 "DATE_CLAIM_CLOSED": "03-30-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Xu",
		 "CLAIMANT_FIRST_NAME": "Shanping",
		 "CLAIM_NO": "2015-03-02-0153-VP-01",
		 "DURATION": 131,
		 "DISABILITY_START_DATE": "02-25-2015 ",
		 "DATE_CLAIM_CLOSED": "07-20-2015 ",
		 "ACTUAL_RTW_DATE": "07-06-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yalung",
		 "CLAIMANT_FIRST_NAME": "Inalyn",
		 "CLAIM_NO": "2020-06-29-0403-VP-01",
		 "DURATION": 30,
		 "DISABILITY_START_DATE": "06-30-2020 ",
		 "DATE_CLAIM_CLOSED": "08-03-2020 ",
		 "ACTUAL_RTW_DATE": "07-30-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yamagami",
		 "CLAIMANT_FIRST_NAME": "Akari",
		 "CLAIM_NO": "2015-02-02-0475-VP-01",
		 "DURATION": 68,
		 "DISABILITY_START_DATE": "02-16-2015 ",
		 "DATE_CLAIM_CLOSED": "05-20-2015 ",
		 "ACTUAL_RTW_DATE": "04-25-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yan",
		 "CLAIMANT_FIRST_NAME": "Kathleen",
		 "CLAIM_NO": "2015-06-12-0491-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "06-11-2015 ",
		 "DATE_CLAIM_CLOSED": "08-03-2015 ",
		 "ACTUAL_RTW_DATE": "06-20-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yang",
		 "CLAIMANT_FIRST_NAME": "Chun Lian",
		 "CLAIM_NO": "2019-03-27-0237-VP-01",
		 "DURATION": 33,
		 "DISABILITY_START_DATE": "03-27-2019 ",
		 "DATE_CLAIM_CLOSED": "04-30-2019 ",
		 "ACTUAL_RTW_DATE": "04-29-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yang",
		 "CLAIMANT_FIRST_NAME": "Yujing",
		 "CLAIM_NO": "2023-01-26-0177-VP-01",
		 "DURATION": 28,
		 "DISABILITY_START_DATE": "01-16-2023 ",
		 "DATE_CLAIM_CLOSED": "03-27-2023 ",
		 "ACTUAL_RTW_DATE": "02-13-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yang",
		 "CLAIMANT_FIRST_NAME": "Chia",
		 "CLAIM_NO": "2020-08-11-0864-VP-01",
		 "DURATION": 48,
		 "DISABILITY_START_DATE": "08-21-2020 ",
		 "DATE_CLAIM_CLOSED": "10-12-2020 ",
		 "ACTUAL_RTW_DATE": "10-08-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yang",
		 "CLAIMANT_FIRST_NAME": "Chun Lian",
		 "CLAIM_NO": "2020-07-24-0332-VP-01",
		 "DURATION": 372,
		 "DISABILITY_START_DATE": "07-24-2020 ",
		 "DATE_CLAIM_CLOSED": "08-02-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yang",
		 "CLAIMANT_FIRST_NAME": "Ming",
		 "CLAIM_NO": "2017-11-14-0589-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "12-05-2017 ",
		 "DATE_CLAIM_CLOSED": "01-19-2018 ",
		 "ACTUAL_RTW_DATE": "01-16-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yap",
		 "CLAIMANT_FIRST_NAME": "Ofelia",
		 "CLAIM_NO": "2022-08-18-0308-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "08-25-2022 ",
		 "DATE_CLAIM_CLOSED": "09-14-2022 ",
		 "ACTUAL_RTW_DATE": "09-09-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yarosh Wayne",
		 "CLAIMANT_FIRST_NAME": "Haley",
		 "CLAIM_NO": "2019-08-01-0225-VP-01",
		 "DURATION": 46,
		 "DISABILITY_START_DATE": "09-05-2019 ",
		 "DATE_CLAIM_CLOSED": "10-20-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yarosh Wayne",
		 "CLAIMANT_FIRST_NAME": "Haley",
		 "CLAIM_NO": "2017-05-30-0289-VP-01",
		 "DURATION": 112,
		 "DISABILITY_START_DATE": "07-05-2017 ",
		 "DATE_CLAIM_CLOSED": "03-19-2018 ",
		 "ACTUAL_RTW_DATE": "10-25-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yaroshevsky",
		 "CLAIMANT_FIRST_NAME": "Alex",
		 "CLAIM_NO": "2013-02-04-0065-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "01-29-2013 ",
		 "DATE_CLAIM_CLOSED": "02-21-2013 ",
		 "ACTUAL_RTW_DATE": "02-13-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yashawanth",
		 "CLAIMANT_FIRST_NAME": "Anusha",
		 "CLAIM_NO": "2023-03-31-0713-VP-01",
		 "DURATION": 11,
		 "DISABILITY_START_DATE": "03-20-2023 ",
		 "DATE_CLAIM_CLOSED": "04-25-2023 ",
		 "ACTUAL_RTW_DATE": "03-31-2023 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2023"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yashawanth",
		 "CLAIMANT_FIRST_NAME": "Anusha",
		 "CLAIM_NO": "2020-10-30-0415-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "11-19-2020 ",
		 "DATE_CLAIM_CLOSED": "01-20-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yassini",
		 "CLAIMANT_FIRST_NAME": "Mostafa",
		 "CLAIM_NO": "2020-05-07-0274-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "04-27-2020 ",
		 "DATE_CLAIM_CLOSED": "07-02-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yates",
		 "CLAIMANT_FIRST_NAME": "Valerie",
		 "CLAIM_NO": "2022-06-13-0986-VP-01",
		 "DURATION": 153,
		 "DISABILITY_START_DATE": "06-10-2022 ",
		 "DATE_CLAIM_CLOSED": "11-27-2022 ",
		 "ACTUAL_RTW_DATE": "11-10-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yates",
		 "CLAIMANT_FIRST_NAME": "Valerie",
		 "CLAIM_NO": "2018-02-05-0168-VP-01",
		 "DURATION": 98,
		 "DISABILITY_START_DATE": "03-19-2018 ",
		 "DATE_CLAIM_CLOSED": "06-27-2018 ",
		 "ACTUAL_RTW_DATE": "06-25-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yates",
		 "CLAIMANT_FIRST_NAME": "Valerie",
		 "CLAIM_NO": "2022-02-04-0896-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "03-14-2022 ",
		 "DATE_CLAIM_CLOSED": "04-25-2022 ",
		 "ACTUAL_RTW_DATE": "04-25-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yee",
		 "CLAIMANT_FIRST_NAME": "Michelle",
		 "CLAIM_NO": "2017-02-22-0645-VP-01",
		 "DURATION": 84,
		 "DISABILITY_START_DATE": "03-13-2017 ",
		 "DATE_CLAIM_CLOSED": "02-27-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yen",
		 "CLAIMANT_FIRST_NAME": "William",
		 "CLAIM_NO": "2017-08-05-0010-VP-01",
		 "DURATION": 63,
		 "DISABILITY_START_DATE": "08-07-2017 ",
		 "DATE_CLAIM_CLOSED": "10-18-2017 ",
		 "ACTUAL_RTW_DATE": "10-09-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yesoyan",
		 "CLAIMANT_FIRST_NAME": "Eduard",
		 "CLAIM_NO": "2014-02-24-0410-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "02-10-2014 ",
		 "DATE_CLAIM_CLOSED": "05-31-2014 ",
		 "ACTUAL_RTW_DATE": "02-24-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yim",
		 "CLAIMANT_FIRST_NAME": "Ravann",
		 "CLAIM_NO": "2015-06-30-0135-VP-01",
		 "DURATION": 0,
		 "DISABILITY_START_DATE": "08-27-2015 ",
		 "DATE_CLAIM_CLOSED": "09-03-2015 ",
		 "ACTUAL_RTW_DATE": "08-27-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yip",
		 "CLAIMANT_FIRST_NAME": "John",
		 "CLAIM_NO": "2015-06-23-0494-VP-01",
		 "DURATION": 181,
		 "DISABILITY_START_DATE": "06-23-2015 ",
		 "DATE_CLAIM_CLOSED": "12-22-2015 ",
		 "ACTUAL_RTW_DATE": "12-21-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yip",
		 "CLAIMANT_FIRST_NAME": "Pamela",
		 "CLAIM_NO": "2013-12-12-0083-VP-01",
		 "DURATION": 21,
		 "DISABILITY_START_DATE": "01-09-2014 ",
		 "DATE_CLAIM_CLOSED": "05-31-2014 ",
		 "ACTUAL_RTW_DATE": "01-30-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yip",
		 "CLAIMANT_FIRST_NAME": "John",
		 "CLAIM_NO": "2014-02-04-0508-VP-01",
		 "DURATION": 141,
		 "DISABILITY_START_DATE": "02-10-2014 ",
		 "DATE_CLAIM_CLOSED": "12-08-2014 ",
		 "ACTUAL_RTW_DATE": "07-01-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yip",
		 "CLAIMANT_FIRST_NAME": "Alice",
		 "CLAIM_NO": "2021-04-06-0490-VP-01",
		 "DURATION": 75,
		 "DISABILITY_START_DATE": "04-06-2021 ",
		 "DATE_CLAIM_CLOSED": "06-24-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yip",
		 "CLAIMANT_FIRST_NAME": "John",
		 "CLAIM_NO": "2014-12-05-0205-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "12-01-2014 ",
		 "DATE_CLAIM_CLOSED": "03-09-2015 ",
		 "ACTUAL_RTW_DATE": "12-16-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "York",
		 "CLAIMANT_FIRST_NAME": "Rebecca",
		 "CLAIM_NO": "2021-02-10-0365-VP-01",
		 "DURATION": 8,
		 "DISABILITY_START_DATE": "02-08-2021 ",
		 "DATE_CLAIM_CLOSED": "04-30-2021 ",
		 "ACTUAL_RTW_DATE": "02-16-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "York",
		 "CLAIMANT_FIRST_NAME": "Rebecca",
		 "CLAIM_NO": "2020-03-02-0864-VP-01",
		 "DURATION": 17,
		 "DISABILITY_START_DATE": "03-06-2020 ",
		 "DATE_CLAIM_CLOSED": "05-26-2020 ",
		 "ACTUAL_RTW_DATE": "03-23-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "You",
		 "CLAIMANT_FIRST_NAME": "Jia",
		 "CLAIM_NO": "2018-08-21-0373-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "09-10-2018 ",
		 "DATE_CLAIM_CLOSED": "10-22-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Younker",
		 "CLAIMANT_FIRST_NAME": "Misty",
		 "CLAIM_NO": "2020-03-30-0290-VP-01",
		 "DURATION": 67,
		 "DISABILITY_START_DATE": "04-06-2020 ",
		 "DATE_CLAIM_CLOSED": "06-15-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yrreverre",
		 "CLAIMANT_FIRST_NAME": "Marianne",
		 "CLAIM_NO": "2014-05-21-0215-VP-01",
		 "DURATION": 24,
		 "DISABILITY_START_DATE": "05-19-2014 ",
		 "DATE_CLAIM_CLOSED": "08-04-2014 ",
		 "ACTUAL_RTW_DATE": "06-12-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MAY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yrreverre",
		 "CLAIMANT_FIRST_NAME": "Marianne",
		 "CLAIM_NO": "2013-10-04-0020-VP-01",
		 "DURATION": 18,
		 "DISABILITY_START_DATE": "09-26-2013 ",
		 "DATE_CLAIM_CLOSED": "01-13-2014 ",
		 "ACTUAL_RTW_DATE": "10-14-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yrreverre",
		 "CLAIMANT_FIRST_NAME": "Marianne",
		 "CLAIM_NO": "2014-02-19-0354-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "02-10-2014 ",
		 "DATE_CLAIM_CLOSED": "05-29-2014 ",
		 "ACTUAL_RTW_DATE": "02-24-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yu",
		 "CLAIMANT_FIRST_NAME": "Katy",
		 "CLAIM_NO": "2014-01-15-0241-VP-01",
		 "DURATION": 66,
		 "DISABILITY_START_DATE": "01-21-2014 ",
		 "DATE_CLAIM_CLOSED": "06-02-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yuen",
		 "CLAIMANT_FIRST_NAME": "Marlene",
		 "CLAIM_NO": "2013-01-05-0623-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "11-27-2012 ",
		 "DATE_CLAIM_CLOSED": "02-21-2013 ",
		 "ACTUAL_RTW_DATE": "12-17-2012 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Yune",
		 "CLAIMANT_FIRST_NAME": "Jane",
		 "CLAIM_NO": "2022-02-21-0510-VP-01",
		 "DURATION": 40,
		 "DISABILITY_START_DATE": "03-02-2022 ",
		 "DATE_CLAIM_CLOSED": "04-25-2022 ",
		 "ACTUAL_RTW_DATE": "04-11-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "MARCH",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zaidi",
		 "CLAIMANT_FIRST_NAME": "Nasim",
		 "CLAIM_NO": "2020-10-05-0677-VP-01",
		 "DURATION": 263,
		 "DISABILITY_START_DATE": "09-24-2020 ",
		 "DATE_CLAIM_CLOSED": "07-07-2021 ",
		 "ACTUAL_RTW_DATE": "06-14-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zaidi",
		 "CLAIMANT_FIRST_NAME": "Nasim",
		 "CLAIM_NO": "2018-01-11-0743-VP-01",
		 "DURATION": 14,
		 "DISABILITY_START_DATE": "01-08-2018 ",
		 "DATE_CLAIM_CLOSED": "02-14-2018 ",
		 "ACTUAL_RTW_DATE": "01-22-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zalewski",
		 "CLAIMANT_FIRST_NAME": "Kristin",
		 "CLAIM_NO": "2016-03-28-0597-VP-01",
		 "DURATION": 20,
		 "DISABILITY_START_DATE": "04-12-2016 ",
		 "DATE_CLAIM_CLOSED": "06-13-2016 ",
		 "ACTUAL_RTW_DATE": "05-02-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zalewski",
		 "CLAIMANT_FIRST_NAME": "Kristin",
		 "CLAIM_NO": "2016-06-03-0489-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "06-29-2016 ",
		 "DATE_CLAIM_CLOSED": "07-15-2016 ",
		 "ACTUAL_RTW_DATE": "07-09-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zaragoza",
		 "CLAIMANT_FIRST_NAME": "Raquel",
		 "CLAIM_NO": "2022-05-09-0598-VP-01",
		 "DURATION": 16,
		 "DISABILITY_START_DATE": "04-20-2022 ",
		 "DATE_CLAIM_CLOSED": "06-03-2022 ",
		 "ACTUAL_RTW_DATE": "05-06-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zarkoob",
		 "CLAIMANT_FIRST_NAME": "Shahrzad",
		 "CLAIM_NO": "2016-06-21-0233-VP-01",
		 "DURATION": 27,
		 "DISABILITY_START_DATE": "06-21-2016 ",
		 "DATE_CLAIM_CLOSED": "07-28-2016 ",
		 "ACTUAL_RTW_DATE": "07-18-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zarkoob",
		 "CLAIMANT_FIRST_NAME": "Shahrzad",
		 "CLAIM_NO": "2016-09-20-0545-VP-01",
		 "DURATION": 52,
		 "DISABILITY_START_DATE": "09-26-2016 ",
		 "DATE_CLAIM_CLOSED": "11-28-2016 ",
		 "ACTUAL_RTW_DATE": "11-17-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zarling",
		 "CLAIMANT_FIRST_NAME": "Amanda",
		 "CLAIM_NO": "2020-07-02-0566-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "07-24-2020 ",
		 "DATE_CLAIM_CLOSED": "09-08-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2020"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zartler",
		 "CLAIMANT_FIRST_NAME": "Jean",
		 "CLAIM_NO": "2015-06-12-0155-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "07-13-2015 ",
		 "DATE_CLAIM_CLOSED": "08-27-2015 ",
		 "ACTUAL_RTW_DATE": "08-24-2015 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2015"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zaugg",
		 "CLAIMANT_FIRST_NAME": "Margaret",
		 "CLAIM_NO": "2016-06-13-0289-VP-01",
		 "DURATION": 190,
		 "DISABILITY_START_DATE": "07-11-2016 ",
		 "DATE_CLAIM_CLOSED": "01-26-2017 ",
		 "ACTUAL_RTW_DATE": "01-17-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zavala",
		 "CLAIMANT_FIRST_NAME": "Linda",
		 "CLAIM_NO": "2022-07-06-0211-VP-01",
		 "DURATION": 10,
		 "DISABILITY_START_DATE": "07-01-2022 ",
		 "DATE_CLAIM_CLOSED": "07-13-2022 ",
		 "ACTUAL_RTW_DATE": "07-11-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zderko",
		 "CLAIMANT_FIRST_NAME": "Carolyn",
		 "CLAIM_NO": "2016-10-06-0328-VP-01",
		 "DURATION": 170,
		 "DISABILITY_START_DATE": "10-03-2016 ",
		 "DATE_CLAIM_CLOSED": "08-08-2017 ",
		 "ACTUAL_RTW_DATE": "03-22-2017 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "OCTOBER",
		 "YEAR": "2017"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zeiss",
		 "CLAIMANT_FIRST_NAME": "Irma",
		 "CLAIM_NO": "2022-07-29-0851-VP-01",
		 "DURATION": 9,
		 "DISABILITY_START_DATE": "08-11-2022 ",
		 "DATE_CLAIM_CLOSED": "08-23-2022 ",
		 "ACTUAL_RTW_DATE": "08-20-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zeng",
		 "CLAIMANT_FIRST_NAME": "Hua",
		 "CLAIM_NO": "2018-08-21-0380-VP-01",
		 "DURATION": 49,
		 "DISABILITY_START_DATE": "08-20-2018 ",
		 "DATE_CLAIM_CLOSED": "10-10-2018 ",
		 "ACTUAL_RTW_DATE": "10-08-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zeng",
		 "CLAIMANT_FIRST_NAME": "Hua",
		 "CLAIM_NO": "2013-01-05-0357-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "11-15-2012 ",
		 "DATE_CLAIM_CLOSED": "01-29-2013 ",
		 "ACTUAL_RTW_DATE": "01-14-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "NOVEMBER",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zhang",
		 "CLAIMANT_FIRST_NAME": "Yan",
		 "CLAIM_NO": "2021-02-17-1069-VP-01",
		 "DURATION": 53,
		 "DISABILITY_START_DATE": "02-22-2021 ",
		 "DATE_CLAIM_CLOSED": "04-16-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zhang",
		 "CLAIMANT_FIRST_NAME": "Shuai",
		 "CLAIM_NO": "2016-06-03-0459-VP-01",
		 "DURATION": 64,
		 "DISABILITY_START_DATE": "06-06-2016 ",
		 "DATE_CLAIM_CLOSED": "08-23-2016 ",
		 "ACTUAL_RTW_DATE": "08-09-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JUNE",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zhang",
		 "CLAIMANT_FIRST_NAME": "Luhong",
		 "CLAIM_NO": "2018-04-19-0699-VP-01",
		 "DURATION": 34,
		 "DISABILITY_START_DATE": "04-25-2018 ",
		 "DATE_CLAIM_CLOSED": "05-31-2018 ",
		 "ACTUAL_RTW_DATE": "05-29-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zhang",
		 "CLAIMANT_FIRST_NAME": "Xiwen",
		 "CLAIM_NO": "2018-08-08-0633-VP-01",
		 "DURATION": 32,
		 "DISABILITY_START_DATE": "08-02-2018 ",
		 "DATE_CLAIM_CLOSED": "09-06-2018 ",
		 "ACTUAL_RTW_DATE": "09-03-2018 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "AUGUST",
		 "YEAR": "2018"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zhang",
		 "CLAIMANT_FIRST_NAME": "Shuai",
		 "CLAIM_NO": "2016-01-14-0147-VP-01",
		 "DURATION": 62,
		 "DISABILITY_START_DATE": "01-12-2016 ",
		 "DATE_CLAIM_CLOSED": "03-14-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zhang",
		 "CLAIMANT_FIRST_NAME": "Shuai",
		 "CLAIM_NO": "2022-08-30-0900-VP-01",
		 "DURATION": 15,
		 "DISABILITY_START_DATE": "09-27-2022 ",
		 "DATE_CLAIM_CLOSED": "10-24-2022 ",
		 "ACTUAL_RTW_DATE": "10-12-2022 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2022"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zhao",
		 "CLAIMANT_FIRST_NAME": "Yuan",
		 "CLAIM_NO": "2019-08-19-0525-VP-01",
		 "DURATION": 58,
		 "DISABILITY_START_DATE": "09-03-2019 ",
		 "DATE_CLAIM_CLOSED": "11-20-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "SEPTEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zhao",
		 "CLAIMANT_FIRST_NAME": "Jasmin",
		 "CLAIM_NO": "2018-11-12-0349-VP-01",
		 "DURATION": 59,
		 "DISABILITY_START_DATE": "12-10-2018 ",
		 "DATE_CLAIM_CLOSED": "02-07-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "DECEMBER",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zhao",
		 "CLAIMANT_FIRST_NAME": "Yuan",
		 "CLAIM_NO": "2021-07-06-0234-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "07-06-2021 ",
		 "DATE_CLAIM_CLOSED": "08-06-2021 ",
		 "ACTUAL_RTW_DATE": "07-19-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zhao",
		 "CLAIMANT_FIRST_NAME": "Jasmin",
		 "CLAIM_NO": "2016-03-07-0302-VP-01",
		 "DURATION": 60,
		 "DISABILITY_START_DATE": "04-07-2016 ",
		 "DATE_CLAIM_CLOSED": "06-14-2016 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2016"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zhou",
		 "CLAIMANT_FIRST_NAME": "Cindy",
		 "CLAIM_NO": "2013-12-17-0327-VP-01",
		 "DURATION": 61,
		 "DISABILITY_START_DATE": "01-20-2014 ",
		 "DATE_CLAIM_CLOSED": "12-11-2014 ",
		 "ACTUAL_RTW_DATE": "03-22-2014 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JANUARY",
		 "YEAR": "2014"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zhu",
		 "CLAIMANT_FIRST_NAME": "Kaiyuan",
		 "CLAIM_NO": "2019-07-22-0502-VP-01",
		 "DURATION": 35,
		 "DISABILITY_START_DATE": "07-24-2019 ",
		 "DATE_CLAIM_CLOSED": "10-12-2019 ",
		 "ACTUAL_RTW_DATE": "08-28-2019 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2019"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zuberbuhler",
		 "CLAIMANT_FIRST_NAME": "Walter",
		 "CLAIM_NO": "2013-01-11-0392-VP-01",
		 "DURATION": 56,
		 "DISABILITY_START_DATE": "02-06-2013 ",
		 "DATE_CLAIM_CLOSED": "04-13-2013 ",
		 "ACTUAL_RTW_DATE": "04-03-2013 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "FEBRUARY",
		 "YEAR": "2013"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zubonja",
		 "CLAIMANT_FIRST_NAME": "Ivana",
		 "CLAIM_NO": "2021-06-14-1011-VP-01",
		 "DURATION": 42,
		 "DISABILITY_START_DATE": "07-26-2021 ",
		 "DATE_CLAIM_CLOSED": "09-07-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zuniga",
		 "CLAIMANT_FIRST_NAME": "Jorge",
		 "CLAIM_NO": "2021-07-23-0060-VP-01",
		 "DURATION": 13,
		 "DISABILITY_START_DATE": "07-13-2021 ",
		 "DATE_CLAIM_CLOSED": "08-18-2021 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "JULY",
		 "YEAR": "2021"
		},
		{
		 "CLAIMANT_LAST_NAME": "Zuniga",
		 "CLAIMANT_FIRST_NAME": "Ana",
		 "CLAIM_NO": "2020-05-01-0227-VP-01",
		 "DURATION": 47,
		 "DISABILITY_START_DATE": "04-22-2020 ",
		 "DATE_CLAIM_CLOSED": "05-07-2020 ",
		 "CLAIM_STATUS": "CLOSED",
		 "MONTH": "APRIL",
		 "YEAR": "2020"
		}
	   ]
	chartElement15: any;
	detail15:any;
	 //AG Grid
	 public gridOptions: GridOptions;
	 columnDefs: ColDef[] = [];
	 public rowData:any=[];
	 public defaultColDef: ColDef = {
	   cellStyle: {'padding-left': 0 ,'text-align':"center"},
	   flex:1,
	   minWidth: 150, 
	   maxWidth: 250,
	   resizable: true,
	   sortable: true,
	   filter: true,
	   editable:true,
	   getQuickFilterText: params => {
		 return params.value.name;
	 }};
	  coldata: any=[];
	  empData: any=[];
	  showGrid: boolean;
	  showmenu:boolean=false;
	  showdropdown:boolean=false;
	  Hdata: any[]=[];
	  Rdata: any[]=[];
	  empdata: any;
	constructor(private messageService: MessageService, public dialogService: DialogService, private http: HttpClient, private dashService: DashboardService, private router: Router) {
		this.gridOptions = {
			rowSelection: 'single',
			context: {},
			pagination: true,
			paginationPageSize: 100,
			paginationAutoPageSize: false,
			onGridReady: (params) => {
			  params.api.sizeColumnsToFit();
			},
			onGridSizeChanged: (params) => {
			  params.api.sizeColumnsToFit();
			}
		  }
	}

	colorOne = ["#FFFF", "#CFE2F3", "#E1CCB6", "#ffd670","#d9d9d9", "#ebb9b9","#e1d0e8", "#E3F2FD","#B2DFDB","#C8E6C9","#FFF9C4"];

	colorSetOne = ["#00A9E9" ,"#BC851C" ,"#004990" ,"#87189D" ,"#C5003E" ,"#28724F" ,"#92C1E9" ,"#CEB888" ,"#678787" ,"#B884CB" ,"#EF6079" ,"#85B09A"]
    colorSetTwo = ["#E95044","#F28A4C","#F0D444","#A2DF9C","#93DBE4","#B091D8","#DD1D24","#E35724","#EFE60C","#39A654","#0C9FDC","#812888"] //third set
    colorSetThree = ["#01204F","#006A8D","#03848C","#64A79D","#E9CFA1","#F2A465","#EF6727","#F03113","#E6DCAB","#dbcb7c","#DDBD36","#926A00"]; //yellow
	colorSetFour = ["#f7e37a", "#fbdd38", "#ebc403", "#f8c32a", "#e2a83d", "#f3b064", "#f68933", "#f5636d", "#ff4e5b", "#e42f24", "#bc2025", "#c02024"]; //yellow to red	
	colorSetFive = ["#012c66", "#86bedf", "#cfe8f7", "#cb2624", "#e34746", "#f47473", "#046365", "#13a1a4", "#68dddb", "#e7c414", "#ffe242", "#ffe87b"]; //Neon
	colorSetSix = ["#6E2C00", "#873600", "#A04000", "#BA4A00", "#D35400", "#DC7633", "#E59866", "#EDBB99", "#F6DDCC", "#FBEEE6", "#fff2eb", "#fff7f2"];// 6 cube set brown
	colorSetSeven = ["#002E55","#014C7C","#005B7C","#008098","#0E89B5","#0695AA","#35B5C1","#397C82","#87C9CF","#ACCBC6","#C0E0DB","#E4EBEA"]; //Brown color set
	colorSetEight = ["#3d818c","#63adb3","#95cdcf","#b9d0d0","#cfe6e6","#e5eeee","#eeebe3","#c3bca1","#c3bca1","#c4ad91","#b89f87","#a08d76"]; //4 cube set
	
	selected= {
		color: '#3580db'
	  }

	dashboardName:any;
	totalWidgets = [];
	eachWidgetRecords = [];
	randomIdArray = [];
	parameterizedDashboardRandomArray = [];
	tempArray = [];
	dashboardRecords = [];
	totalRows = []
	totalColumns = []
	rowIndex = [];
	finalCallCounter = 0;
	parameterElementsArray = [];
	parameterElementsDataArray = [];
	parameterizedFilterArray = [];
	filterPanelToggleBool: boolean = false;
	requestPayloadParametersArray = [];
	userParameterObject = {};
	requestPayloadParameterObj = {filterType: "and", filters: [], sort: [], parameters: []};
	defaultValuesOfParameterArray = [];
	cascadeParametersObject = {};
	dashboardsListSubscrip: Subscription;
	defaultDashboardSubscrip: Subscription;
	listOfDashboardsSubscrip: Subscription;
	getADashboardSubscrip: Subscription;
	getWidgetDataSubscrip: Subscription;
	getPrmDashItmRecsSubscrip: Subscription;
	getPrmDashCasRecsCalSubscrip: Subscription;
	getPrmDashCasRecsMultSubscrip: Subscription;
	getPrmDashCasRecsDropSubscrip: Subscription;
	getPrmDashCasRecsRadioSubscrip: Subscription;
	getPrmDashWidgetDataSubscrip: Subscription;
	usersNameSubscrip: Subscription;
	shareDashSubscrip: Subscription;
	viewDashboardsList = [];
	dashboardRefreshTime: any;
	dashboardInfoObject: any;
	refreshCountDownTimerStr: string = "";
	refreshInterval;
	countDownLabel: any;
	selectDate: Date;  
	shareDashDialBoxDisplayBool: boolean = false;
    displayBasic: boolean = false;
	discontainer: boolean = false;
	menu = false;
	shareDashUserNamesArr = [];
	shareDashSrchUserNamesArr: string[];
	usersArray = [];
	shareDashEmailIdsArr = [];
	shareDashTabIndex = 0; // setting default value as 0 (1st tab) for share dashboard modal window's tab
	selectedChartsColorSet = "three";
    showOrHideLegendBool = true;
	showOrHideSeriesBool = true;
	wigetBackgroundColor:any="#d9d9d9";
	value:any;
	//value1:any;
	ref: DynamicDialogRef;
	backColor:any;
	
	ngAfterViewInit(): void {
		
		d3.select("div#tibo-mydash-dashlist-container-id").attr("non-hide","non-hide")
		d3.select("button#mydash-dashlist-toggle-btn").attr("non-hide","non-hide").selectAll("button#mydash-dashlist-toggle-btn > span").attr("non-hide","non-hide");
		d3.select("div#tibo-mydash-dashlist-container").attr("non-hide","non-hide")
		d3.select("button#mydash-dashlist-toggle-btn2").attr("non-hide","non-hide").selectAll("button#mydash-dashlist-toggle-btn2 > span").attr("non-hide","non-hide");
		
	}
	
	ngOnInit(): void {
		this.rowData=0;
		this.columnDefs=[];
		this.showdropdown=false
		this.showmenu=false;
		this.showGrid=false;
		this.value=1;
		this.backColor="#d9d9d9";
		this.buttonIndex=0;
		//this.value1="#FFCCCB";
		console.log('calling ngOnInit')
		
		acinfotech.requestType(true); // Letting chart library know it's a dashboard module call

		this.countDownLabel = document.getElementById('mydash-countdown-timer-label') as HTMLElement;

		this.moreItems = [
            
			{
				label: 'Turn on page refresh',
				id: "2",
				command: e => this.optionsFunc(e),
				icon: "pi pi-refresh"
			},
			{
				label: 'Share',
				id: "3",
				command: e => this.optionsFunc(e),
				icon: "pi pi-share-alt"
			},
			
			{
                label: 'Hide/Show',
				icon: "pi pi-eye",
                items: [{
                        label: 'Show Series Number',
						id: "7",
						command: e => this.showLegend(e),
                        icon: 'pi pi-eye',
					},
					{
                        label: 'Show Legend Number',
						id: "8",
						command: e => this.showLegend(e),
                        icon: 'pi pi-eye',
					},
                ]
            },	
        ];
		
		d3.select("html").on("click",function(){
			if(!d3.event.target.hasAttribute("non-hide")){
				d3.select('#tibo-mydash-dashlist-container-id').style("display","none");
			}
		})
		d3.select("html").on("click",function(){
			if(!d3.event.target.hasAttribute("non-hide")){
				d3.select('#tibo-mydash-dashlist-container').style("display","none");
			}
		})
		
		d3.select(".tibo-topbar-title").text("Home / Dashboard"); // setting title
		
		if(Number(d3.select("#tibo-large-leftpanel-container").node().getBoundingClientRect().width) == 64){
			setTimeout(function(){
				d3.select(".tibo-right-container").transition().delay(0).duration(0).style("left","64px").ease("linear");
			},10)
		}
		
		//this.dashboardName=this.selectedDashboard;
		//his.getDashboard("")
		this.initialServiceCalls();
		
	} 

		
	initialServiceCalls(){
		this.items1=[{
			"label": "Disability",
			"items": [
				{
					"label": "Disability-Client",
					"id": 2019,
					command: (event) => { this.changeDashboardFunc("Disability-Client"); }
				}
			]},
				{
				"label": "Leave",
				"items": [
				{
					"label": "Leave",
					"id": 2019,
					command: (event) => { this.changeDashboardFunc("Leave"); }
				}
			]},
			]
			
			this.selectedDashboard= "Disability-Client";
			this.dashboardName=this.selectedDashboard;
			//this.changeDashboardFunc(this.selectedDashboard)
			this.getDashboard("")
					
// 		this.dashboardsListSubscrip = this.dashService.dashboardsListService().subscribe( (response: any) => { // dashboards list
   
      
    
// 			let dashTemp = {}, dashItemsTemp = {};
// 			response.body.forEach((element: any, index: any) => {
// 				this.tempArray.push({name: element.widgetName, code: element.widgetMasterId})
// 				dashTemp["label"] = element.domainName;
// 				dashTemp["items"] = [];
				
// 				element.dashboards.forEach((dashboard: any) => {
// 					dashItemsTemp["label"] = dashboard.widgetName;
// 					dashItemsTemp["id"] = dashboard.widgetMasterId;
// 					dashItemsTemp["command"] = e => this.changeDashboardFunc(e);
					
// 					dashTemp["items"].push(dashItemsTemp)
// 					dashItemsTemp = {};
// 				})
				
// 				this.dashboardRecords.push(dashTemp);
// 				dashTemp = {};
// 			});
// 			this.items = this.dashboardRecords;
// 			this.dashboardInfo = this.tempArray;
			
// 			// Additional dashboard domains filtering ---------------------
// 				let filteredDomains = [];
// 				this.items.forEach((domain: any) => {
// 					if(domain.items.length > 0){
// 						filteredDomains.push(domain)
// 					}
					
// 				})
				
// 				this.items = filteredDomains;
// 			//-------------------------------------------------------------

// 		}, error => {

// 			d3.select(".tibo-mydash-dash-spinner").style("display","none") // hidding dashboard spinner

// 			if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
// 			else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
// 			else { this.errorMsg = "Please try after some time." }
			
// 			this.addSingle()
// 		}, () => {}) // dashboardService
		
// 		this.defaultDashboardSubscrip = this.dashService.getDefaultDashboard().subscribe( (response: any) => { // get defaul dashboard
//    console.log("response",response)
// 			this.dashboardRefreshTime = response.body.dashboardRefreshTime;
			
// 			if(response.body.defaultDashboard.toString() != 'null'){
// 				this.selectedDashboard = {name: "", code: response.body.defaultDashboard }
				
// 				this.getDashboard("");
// 			}
// 			else { // if there is no default dashboard set

// 				this.listOfDashboardsSubscrip = this.dashService.dashboardsList().subscribe( (dashboardsListResp: any) => { // get list of dashboards
// 					this.selectedDashboard = {name: "", code: dashboardsListResp.body[0].widgetMasterId }
				
// 					this.getDashboard("");
// 				}, error => { 
       
// 					d3.select(".tibo-mydash-dash-spinner").style("display","none") // hidding dashboard spinner

// 					if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
// 					else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
// 					else { this.errorMsg = "Please try after some time." }
					
// 					this.addSingle()
// 				}, () => {}) // get list of dashboards
// 			}
			
// 			if(response.body?.dashboardRefreshOnOROff.toString().toLowerCase() == "y"){ // checking whether dashboard auto refresh is turned on in settings page
// 				this.moreItems[1].label = "Turn off page refresh"
// 				//clearInterval(this.refreshInterval);
// 				this.countDownLabel.innerHTML = "";
// 				this.refreshTimerFunc()
// 			}
			
// 		}, error => {

// 			d3.select(".tibo-mydash-dash-spinner").style("display","none") // hidding dashboard spinner

// 			if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
// 			else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
// 			else { this.errorMsg = "Please try after some time." }
			
// 			this.addSingle()
// 		}, () => {}) // get default dashboard service

			
		}
		

	datasData:any;

	getDashboard(event: any){
		console.log("called")
		//this.chartElement10='';this.detail10={};
		acinfotech.requestType(true); // Letting chart library know it's a dashboard module call and empty all stored divs id and chart div elements

		d3.select(document).selectAll(".d3-tip").remove() // hiding d3-tip tool tip on dashboard change
		d3.select(".tibo-mydash-dash-spinner").style("display","block") //  showing dashboard spinner
		
		// this.moreItems[0].label = "Show widgets in full view" // changing label text in options dropdown (full, widget view)
		// this.moreItems[0].icon = "pi pi-external-link" // changing icon in options dropdown (full, widget view)
		
		if(this.moreItems[0].label == "Turn off page refresh"){ // to check whether page refresh is on
			clearInterval(this.refreshInterval);
			this.countDownLabel.innerHTML = "";
			this.refreshTimerFunc()
		}
		this.DashboardsList.push(this.selectedDashboard);
		this.viewDashboardsList=this.DashboardsList.filter((item,index) => this.DashboardsList.indexOf(item) === index);	
		this.dashboardName=this.selectedDashboard;
		setTimeout(() => { 
		this.chartElement1= d3.select('#chart1');
		this.detail1={
			data:[
				{
				 "YEAR": "2021",
				 "STD": 1759,
				 "VP": 518
				},
				{
				 "YEAR": "2022",
				 "STD": 1730,
				 "VP": 477
				},
				{
				 "YEAR": "2023",
				 "STD": 484,
				 "VP": 120
				}
			   ],
			title:"New CLaims",
			keyLabel:'YEAR',
			keyGroup: ['STD','VP'],
			xLable: "YEAR",
			yLable: "",
		} },1000)
		setTimeout(() => {
		this.chartElement2= d3.select('#chart2')
		this.detail2={
			data:[
				{
				 "CLIENT": 388,
				 "YEAR_VALUE": "2021",
				 "AVERAGE_DURATION": 64
				},
				{
				 "CLIENT": 388,
				 "YEAR_VALUE": "2022",
				 "AVERAGE_DURATION": 66
				},
				{
				 "CLIENT": 388,
				 "YEAR_VALUE": "2023",
				 "AVERAGE_DURATION": 75
				}
			   ],
			title:"Avg Duration(Closed Claims)",
			keyLabel: "YEAR_VALUE",
			keyGroup:"AVERAGE_DURATION",
			xLable: "YEAR",
			yLable:"AVERAGE_DURATION" ,
		}},1000)
		setTimeout(() => {
		this.chartElement3= d3.select('#chart3')
		this.detail3={
			data:[
				{
				 "YEAR": "2021",
				 "STD": 15.27,
				 "VP": 6.2
				},
				{
				 "YEAR": "2022",
				 "STD": 16.09,
				 "VP": 5.85
				},
				{
				 "YEAR": "2023",
				 "STD": 3.74,
				 "VP": 1.4
				}
			   ],
			title:"Claims Paid in Million",
			keyLabel:'YEAR',
			keyGroup: ['STD','VP'],
			xLable: "YEAR",
			yLable: "",
		}},1000)
		setTimeout(() => {
		this.chartElement4= d3.select('#chart4')
		this.detail4={
			data:[
				{
				 "YEAR": "2021",
				 "APPROVED": 92,
				 "DENIED": 8
				},
				{
				 "YEAR": "2022",
				 "APPROVED": 88,
				 "DENIED": 12
				},
				{
				 "YEAR": "2023",
				 "APPROVED": 92,
				 "DENIED": 8
				}
			   ],
			title:"Approval Denial Rate ",
			keyLabel:'YEAR',
			keyGroup: ['APPROVED','DENIED'],
			xLable: "",
			yLable: "YEAR",
		}},1000)
		setTimeout(() => {
		this.chartElement5= d3.select('#chart5')
		this.detail5={
			data:[
				{
				 "MATRIX_CLIENT_CODE": 388,
				 "DIAGNOSIS": "Pregnancy, childbirth & the puerperium (O00-O9A)",
				 "CLAIM": 85
				},
				{
				 "MATRIX_CLIENT_CODE": 388,
				 "DIAGNOSIS": "Musculoskeletal (M00-M99)",
				 "CLAIM": 79
				},
				{
				 "MATRIX_CLIENT_CODE": 388,
				 "DIAGNOSIS": "Not reported",
				 "CLAIM": 34
				},
				{
				 "MATRIX_CLIENT_CODE": 388,
				 "DIAGNOSIS": "Mental, Behavioral & Neurodevelopmental disorders (F01-F99)",
				 "CLAIM": 64
				},
				{
				 "MATRIX_CLIENT_CODE": 388,
				 "DIAGNOSIS": "Injury, Poisoning (S00-T88)",
				 "CLAIM": 72
				}
			   ],
			title:"Average Duration - Top Diagnose (Previous Year)",
			keyLabel:"DIAGNOSIS",
			keyGroup: "CLAIM",
			xLable: "Avg-duration",
			yLable: "Diagnosis",
		}},1000)
		setTimeout(() => {
		this.chartElement6= d3.select('#chart6')
		this.detail6={
			data:[
				{
				 "MATRIX_CLIENT_CODE": 388,
				 "DIAGNOSIS": "Pregnancy, childbirth & the puerperium (O00-O9A)",
				 "CLAIM": 112
				},
				{
				 "MATRIX_CLIENT_CODE": 388,
				 "DIAGNOSIS": "Musculoskeletal (M00-M99)",
				 "CLAIM": 78
				},
				{
				 "MATRIX_CLIENT_CODE": 388,
				 "DIAGNOSIS": "Not reported",
				 "CLAIM": 50
				},
				{
				 "MATRIX_CLIENT_CODE": 388,
				 "DIAGNOSIS": "Mental, Behavioral & Neurodevelopmental disorders (F01-F99)",
				 "CLAIM": 80
				},
				{
				 "MATRIX_CLIENT_CODE": 388,
				 "DIAGNOSIS": "Injury, Poisoning (S00-T88)",
				 "CLAIM": 90
				}
			   ],
			title:"Average Duration - Top Diagnose YTD",
			keyLabel:"DIAGNOSIS",
			keyGroup: "CLAIM",
			xLable: "Avg-duration",
			yLable: "Diagnosis",
		}},1000)
		setTimeout(() => {
		this.chartElement7= d3.select('#chart7')
		this.detail7={
			data:[
				{
				 "CLI_CODE": 388,
				 "DIAGNOSIS": "Injury Poisoning (S00-T88)",
				 "AMOUNT": 1.69
				},
				{
				 "CLI_CODE": 388,
				 "DIAGNOSIS": "Mental Behavioral & Neurodevelopmental disorders (F01-F99)",
				 "AMOUNT": 2.74
				},
				{
				 "CLI_CODE": 388,
				 "DIAGNOSIS": "Musculoskeletal (M00-M99)",
				 "AMOUNT": 3.35
				},
				{
				 "CLI_CODE": 388,
				 "DIAGNOSIS": "Not reported",
				 "AMOUNT": 0.58
				},
				{
				 "CLI_CODE": 388,
				 "DIAGNOSIS": "Pregnancy childbirth & the puerperium (O00-O9A)",
				 "AMOUNT": 7.22
				}
			   ],
			title:"Claims Paid In Millions - Top Diagnose (Previous Year) ",
			keyLabel:"DIAGNOSIS",
			keyGroup: "AMOUNT",
			xLable: "Avg-duration",
			yLable: "Diagnosis",
		}},1000)
		setTimeout(() => {
		this.chartElement8= d3.select('#chart8')
		this.detail8={
			data:[
				{
				 "CLI_CODE": 388,
				 "DIAGNOSIS": "Injury Poisoning (S00-T88)",
				 "AMOUNT": 0.32
				},
				{
				 "CLI_CODE": 388,
				 "DIAGNOSIS": "Mental Behavioral & Neurodevelopmental disorders (F01-F99)",
				 "AMOUNT": 0.66
				},
				{
				 "CLI_CODE": 388,
				 "DIAGNOSIS": "Musculoskeletal (M00-M99)",
				 "AMOUNT": 0.84
				},
				{
				 "CLI_CODE": 388,
				 "DIAGNOSIS": "Not reported",
				 "AMOUNT": 0.24
				},
				{
				 "CLI_CODE": 388,
				 "DIAGNOSIS": "Pregnancy childbirth & the puerperium (O00-O9A)",
				 "AMOUNT": 1.61
				}
			   ],
			title:"Claims Paid In Millions - Top Diagnose (YTD)",
			keyLabel:"DIAGNOSIS",
			keyGroup: "AMOUNT",
			xLable: "Avg-duration",
			yLable: "Diagnosis",
		}},1000)
		setTimeout(() => {
		this.chartElement10= d3.select('#chart10');
		console.log("called 10")
		this.detail10={
			data:[
				{
				 "STATE": "NC",
				 "CLAIMS": 9
				},
				{
				 "STATE": "ND",
				 "CLAIMS": 2
				},
				{
				 "STATE": "PA",
				 "CLAIMS": 9
				},
				{
				 "STATE": "MD",
				 "CLAIMS": 2
				},
				{
				 "STATE": "NY",
				 "CLAIMS": 30
				},
				{
				 "STATE": "KS",
				 "CLAIMS": 5
				},
				{
				 "STATE": "SC",
				 "CLAIMS": 9
				},
				{
				 "STATE": "UT",
				 "CLAIMS": 2
				},
				{
				 "STATE": "TN",
				 "CLAIMS": 6
				},
				{
				 "STATE": "NH",
				 "CLAIMS": 1
				},
				{
				 "STATE": "CA",
				 "CLAIMS": 508
				},
				{
				 "STATE": "MI",
				 "CLAIMS": 50
				},
				{
				 "STATE": "MN",
				 "CLAIMS": 227
				},
				{
				 "STATE": "MO",
				 "CLAIMS": 23
				},
				{
				 "STATE": "WA",
				 "CLAIMS": 15
				},
				{
				 "STATE": "OTHER",
				 "CLAIMS": 5
				},
				{
				 "STATE": "OR",
				 "CLAIMS": 4
				},
				{
				 "STATE": "ME",
				 "CLAIMS": 78
				},
				{
				 "STATE": "LA",
				 "CLAIMS": 16
				},
				{
				 "STATE": "WI",
				 "CLAIMS": 10
				},
				{
				 "STATE": "ID",
				 "CLAIMS": 1
				},
				{
				 "STATE": "AL",
				 "CLAIMS": 2
				},
				{
				 "STATE": "OK",
				 "CLAIMS": 3
				},
				{
				 "STATE": "CT",
				 "CLAIMS": 1
				},
				{
				 "STATE": "IL",
				 "CLAIMS": 240
				},
				{
				 "STATE": "WV",
				 "CLAIMS": 2
				},
				{
				 "STATE": "OH",
				 "CLAIMS": 147
				},
				{
				 "STATE": "AZ",
				 "CLAIMS": 54
				},
				{
				 "STATE": "NJ",
				 "CLAIMS": 42
				},
				{
				 "STATE": "VA",
				 "CLAIMS": 92
				},
				{
				 "STATE": "MA",
				 "CLAIMS": 60
				},
				{
				 "STATE": "FL",
				 "CLAIMS": 31
				},
				{
				 "STATE": "GA",
				 "CLAIMS": 7
				},
				{
				 "STATE": "KY",
				 "CLAIMS": 7
				},
				{
				 "STATE": "NE",
				 "CLAIMS": 2
				},
				{
				 "STATE": "IN",
				 "CLAIMS": 20
				},
				{
				 "STATE": "CO",
				 "CLAIMS": 6
				},
				{
				 "STATE": "VT",
				 "CLAIMS": 1
				},
				{
				 "STATE": "TX",
				 "CLAIMS": 143
				},
				{
				 "STATE": "AR",
				 "CLAIMS": 5
				},
				{
				 "STATE": "NV",
				 "CLAIMS": 1
				}
			   ],
		title:"Claims Count By State",
		keyLabel:"STATE",
		keyGroup:"CLAIMS",
		xLable: "MONTH",
		yLable: "",
			
		}},1000);
		setTimeout(() => {
		this.chartElement11= d3.select('#chart11');
		console.log("called 11")
		this.detail11={
			data:[
				{
				 "Number_of_employees": 1000
				}
			   ],
			title:"Total No of Claims",
		}},1000);
		setTimeout(() => {
		this.chartElement9= d3.select('#chart9');
		this.detail9={
			data:[
			{
			 "MONTH": "Jan",
			 "Own": 17,
			 "Child": 4,
			 "Military": 0,
			 "Newborn": 4,
			 "Parent": 7,
			 "Personal": 0,
			 "Pregnancy": 1,
			 "Spouse": 2,
			 "Surrogacy": 0
			},
			{
			 "MONTH": "Feb",
			 "Own": 10,
			 "Child": 3,
			 "Military": 1,
			 "Newborn": 8,
			 "Parent": 3,
			 "Personal": 4,
			 "Pregnancy": 0,
			 "Spouse": 5,
			 "Surrogacy": 0
			},
			{
			 "MONTH": "Mar",
			 "Own": 15,
			 "Child": 3,
			 "Military": 0,
			 "Newborn": 4,
			 "Parent": 6,
			 "Personal": 3,
			 "Pregnancy": 0,
			 "Spouse": 4,
			 "Surrogacy": 0
			},
			{
			 "MONTH": "Apr",
			 "Own": 12,
			 "Child": 1,
			 "Military": 1,
			 "Newborn": 11,
			 "Parent": 4,
			 "Personal": 2,
			 "Pregnancy": 0,
			 "Spouse": 5,
			 "Surrogacy": 1
			},
			{
			 "MONTH": "May",
			 "Own": 8,
			 "Child": 0,
			 "Military": 0,
			 "Newborn": 9,
			 "Parent": 5,
			 "Personal": 3,
			 "Pregnancy": 0,
			 "Spouse": 1,
			 "Surrogacy": 0
			},
			{
			 "MONTH": "Jun",
			 "Own": 20,
			 "Child": 1,
			 "Military": 0,
			 "Newborn": 10,
			 "Parent": 12,
			 "Personal": 2,
			 "Pregnancy": 0,
			 "Spouse": 7,
			 "Surrogacy": 0
			},
			{
			 "MONTH": "Jul",
			 "Own": 20,
			 "Child": 3,
			 "Military": 0,
			 "Newborn": 8,
			 "Parent": 7,
			 "Personal": 3,
			 "Pregnancy": 1,
			 "Spouse": 1,
			 "Surrogacy": 0
			},
			{
			 "MONTH": "Aug",
			 "Own": 25,
			 "Child": 3,
			 "Military": 0,
			 "Newborn": 16,
			 "Parent": 5,
			 "Personal": 3,
			 "Pregnancy": 2,
			 "Spouse": 4,
			 "Surrogacy": 0
			},
			{
			 "MONTH": "Sep",
			 "Own": 39,
			 "Child": 0,
			 "Military": 1,
			 "Newborn": 18,
			 "Parent": 8,
			 "Personal": 3,
			 "Pregnancy": 4,
			 "Spouse": 3,
			 "Surrogacy": 0
			},
			{
			 "MONTH": "Oct",
			 "Own": 68,
			 "Child": 8,
			 "Military": 2,
			 "Newborn": 41,
			 "Parent": 20,
			 "Personal": 4,
			 "Pregnancy": 16,
			 "Spouse": 6,
			 "Surrogacy": 0
			},
			{
			 "MONTH": "Nov",
			 "Own": 87,
			 "Child": 4,
			 "Military": 1,
			 "Newborn": 81,
			 "Parent": 23,
			 "Personal": 6,
			 "Pregnancy": 16,
			 "Spouse": 6,
			 "Surrogacy": 0
			},
			{
			 "MONTH": "Dec",
			 "Own": 131,
			 "Child": 4,
			 "Military": 1,
			 "Newborn": 77,
			 "Parent": 25,
			 "Personal": 4,
			 "Pregnancy": 12,
			 "Spouse": 2,
			 "Surrogacy": 1
			}
		   ],
		title:" Open  Leave By Reason",
		//keyLabel:['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
		keyLabel:"MONTH",
		keyGroup:["Own","Child","Military","Newborn","Parent","Personal","Pregnancy","Spouse","Surrogacy"],
		xLable: "MONTH",
		yLable: "",
			
		}},1000);
		setTimeout(() => {
		this.chartElement12= d3.select('#chart12');
		this.detail12={
			data:[
				{
				 "Total": 5,
				 "Leave Type": "Bereave",
				 "Leave_Type": "Bereave"
				},
				{
				 "Total": 37,
				 "Leave Type": "Child",
				 "Leave_Type": "Child"
				},
				{
				 "Total": 8,
				 "Leave Type": "Military",
				 "Leave_Type": "Military"
				},
				{
				 "Total": 326,
				 "Leave Type": "Newborn",
				 "Leave_Type": "Newborn"
				},
				{
				 "Total": 1,
				 "Leave Type": "Other",
				 "Leave_Type": "Other"
				},
				{
				 "Total": 519,
				 "Leave Type": "Own",
				 "Leave_Type": "Own"
				},
				{
				 "Total": 134,
				 "Leave Type": "Parent",
				 "Leave_Type": "Parent"
				},
				{
				 "Total": 41,
				 "Leave Type": "Personal",
				 "Leave_Type": "Personal"
				},
				{
				 "Total": 65,
				 "Leave Type": "Pregnancy",
				 "Leave_Type": "Pregnancy"
				},
				{
				 "Total": 53,
				 "Leave Type": "Spouse",
				 "Leave_Type": "Spouse"
				},
				{
				 "Total": 2,
				 "Leave Type": "Surrogacy",
				 "Leave_Type": "Surrogacy"
				}
			   ],
		title:"New Leave BY Reason",
		keyLabel:"Leave Type",
		keyGroup: "Total",
		xLable: "Leave Type",
		yLable: "Total",
			
		}},1000);
		setTimeout(() => {
		this.chartElement13= d3.select('#chart13')
		this.detail13={
			data:[
				{
				 "Continuous": 13,
				 "Intermittent": 12,
				 "Month": "Jan"
				},
				{
				 "Continuous": 11,
				 "Intermittent": 13,
				 "Month": "Feb"
				},
				{
				 "Continuous": 11,
				 "Intermittent": 13,
				 "Month": "Mar"
				},
				{
				 "Continuous": 14,
				 "Intermittent": 11,
				 "Month": "Apr"
				},
				{
				 "Continuous": 13,
				 "Intermittent": 8,
				 "Month": "May"
				},
				{
				 "Continuous": 22,
				 "Intermittent": 14,
				 "Month": "Jun"
				},
				{
				 "Continuous": 13,
				 "Intermittent": 14,
				 "Month": "Jul"
				},
				{
				 "Continuous": 29,
				 "Intermittent": 16,
				 "Month": "Aug"
				},
				{
				 "Continuous": 25,
				 "Intermittent": 29,
				 "Month": "Sep"
				},
				{
				 "Continuous": 55,
				 "Intermittent": 57,
				 "Month": "Oct"
				},
				{
				 "Continuous": 76,
				 "Intermittent": 75,
				 "Month": "Nov"
				},
				{
				 "Continuous": 90,
				 "Intermittent": 88,
				 "Month": "Dec"
				}
			   ],
			title:"Continous vs Intermittent",
			keyLabel:'Month',
			keyGroup: ['Continuous', 'Intermittent'],
			xLable: "Month",
			yLable: "",
		}},1000);
		
		if(this.selectedDashboard=='Disability-Client')
		{
			setTimeout(() => { this.groupedBarChart(this.chartElement1, this.detail1) },1000)
			setTimeout(() => { this.donutChart(this.chartElement2, this.detail2) },1000);
			setTimeout(() => { this.groupedBarChart(this.chartElement3, this.detail3) },1000);
			setTimeout(() => { this.groupedBarChart(this.chartElement4, this.detail4) },1000);
			setTimeout(() => { this.pieChart(this.chartElement5, this.detail5) },1000);
			setTimeout(() => { this.pieChart(this.chartElement6, this.detail6) },1000);
			setTimeout(() => { this.bubbleChart(this.chartElement7, this.detail7) },1000);
			setTimeout(() => { this.bubbleChart(this.chartElement8, this.detail8) },1000);
			setTimeout(() => { this.numberGauge(this.chartElement11,this.detail11) },1000);
			setTimeout(() => { this.map(this.chartElement10, this.detail10) },1000);
			
		}
		
		if(this.selectedDashboard=='Leave'){
			setTimeout(() => { this.pieChart(this.chartElement12, this.detail12)},1000);
			setTimeout(() => { this.lineChart(this.chartElement13, this.detail13)},1000);
			setTimeout(() => { this.stackedBarChart(this.chartElement9, this.detail9)},1000);
			
		}
		
	} // get a dashboard func
	reloadDashboardWithNewColorSetFunc(){
		//d3.selectAll("#chart1").remove();
		d3.selectAll("#chart1 > *").remove(); 
		d3.selectAll("#chart2 > *").remove();
		d3.selectAll("#chart3 > *").remove();
		d3.selectAll("#chart4 > *").remove();
		d3.selectAll("#chart5 > *").remove();
		d3.selectAll("#chart6 > *").remove();
		d3.selectAll("#chart7 > *").remove();
		d3.selectAll("#chart8 > *").remove();
		d3.selectAll("#chart9 > *").remove();
		d3.selectAll("#chart10 > *").remove();
		d3.selectAll("#chart11 > *").remove(); 
		d3.selectAll("#chart12 > *").remove(); 
		d3.selectAll("#chart12 > *").remove();
		d3.selectAll("#chart13 > *").remove();
		// this.chartElement1.selectAll("*").remove();;
		// this.chartElement2.empty();
		// this.chartElement11.empty();
		this.getDashboard("")
	}
	groupedBarChart(chartElement,widgetdetails){
		
			if(this.value == 1){
				this.wigetBackgroundColor = this.backColor;
			}
			if(this.value ==2){
				this.wigetBackgroundColor = "#E1CCB6";
			}
			let options = {
				data: widgetdetails.data,
				dataForDrillDown: [],
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label:widgetdetails.keyLabel,
					groupBars:widgetdetails.keyGroup,
				},
				chartTitle:widgetdetails.title,
				xAxisLable: widgetdetails.xLable,
				yAxisLable:widgetdetails.yLable,
				showTitle: true,
				colorSet: this.selectedChartsColorSet,
				BGcolor:this.wigetBackgroundColor,
				drillDownColumnName: "",
				showTable: false,
				showComments: false,
				comments:"",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			
			setTimeout(() => { acinfotech.groupedBarChart(chartElement, options) },1000)
		
	}
	columnChart(chartElement,widgetdetails){
		if(this.value == 1){
			this.wigetBackgroundColor = this.backColor;
		}
		if(this.value ==2){
			this.wigetBackgroundColor = "#E1CCB6";
		}
		let options = {
			data: widgetdetails.data,
			dataForTable: [], // for table
			isDataDetailedForTable: false,
			keys: {
				label: widgetdetails.keyLabel,
				value: widgetdetails.keyGroup
			},
			chartTitle:widgetdetails.title,
			xAxisLable:widgetdetails.xLable,
			yAxisLable: widgetdetails.yLable,
			showTitle: true,
			colorSet: this.selectedChartsColorSet,
			BGcolor:this.wigetBackgroundColor, //one or two or three
			drillDownColumnName: "",
			showTable: false, //false - hide, true - show
			showComments: false,
			comments:"",
			resizeButtonID: document.getElementById("resizeBtn").id
		}
		
		setTimeout(() => { acinfotech.columnChart(chartElement, options) 
			chartElement.selectAll('div.ui-resizable-handle').style("display","none");
		},1000)
	}
	horizontalChart(chartElement,widgetdetails){
	
			if(this.value ==1){
				this.wigetBackgroundColor = this.backColor;
				}
				if(this.value ==2){
					this.wigetBackgroundColor =  "#ffd670";
				}
			let options = {
				data: widgetdetails.data,
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label: widgetdetails.keyLabel,
					value: widgetdetails.keyGroup
				},
				chartTitle:widgetdetails.title,
				xAxisLable: widgetdetails.yLable,
				yAxisLable: widgetdetails.xLable,
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three"
				BGcolor:this.wigetBackgroundColor,
				drillDownColumnName: "",
				showTable: false,
				showComments: false,
				comments:"",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { acinfotech.horizontalChart(chartElement, options) },1000)
	}
	donutChart(chartElement,widgetdetails){
			if(this.value ==1){
				this.wigetBackgroundColor = this.backColor;
			}
			if(this.value ==2){
			this.wigetBackgroundColor ="#ebb9b9";
			}
			
			let options = {
				data: widgetdetails.data, // for chart
				dataForTable: [], // for table if there is no data send empty array ([])
				isDataDetailedForTable: false, 
				keys: {
					label: widgetdetails.keyLabel,
					value: widgetdetails.keyGroup
				},
				chartTitle: widgetdetails.title,
				showTitle: true,
				showLegend: true,
				legendSide: "right", //"right" or "left"
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three"
				BGcolor:this.wigetBackgroundColor,
				gradient: "none", //"none" or "smooth" or "elliptical" or "circular" or "radial"
				drillDownColumnName:widgetdetails.keyLabel,
				showTableIcon: true, //false - hide, true - show
				showTable: false, //false - hide, true - show
				showComments: false,
				comments:"",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { acinfotech.donutChart(chartElement, options) },1000)
	}
	pieChart(chartElement,widgetdetails){
			if(this.value == 1){
				this.wigetBackgroundColor = this.backColor;
			}
			if(this.value == 2){
				this.wigetBackgroundColor ="#CFE2F3";
			}
			
			
			let options = {
				data: widgetdetails.data, // for chart
				dataForTable: [], // for table if there is no data send empty array ([])
				isDataDetailedForTable: false, 
				keys: {
					label: widgetdetails.keyLabel,
					value: widgetdetails.keyGroup
				},
				chartTitle:widgetdetails.title,
				showTitle: true,
				showLegend: true,
				legendSide: "right", //"right" or "left"
				colorSet: this.selectedChartsColorSet,
				BGcolor:this.wigetBackgroundColor, //"one" or "two" or "three"
				gradient: "none", //"none" or "smooth" or "elliptical" or "circular" or "radial"
				drillDownColumnName:widgetdetails.keyLabel,
				showTableIcon: true, //false - hide, true - show
				showTable: false, //false - hide, true - show
				showComments: false,
				comments:"",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			
			setTimeout(() => { acinfotech.pieChart(chartElement, options);},1000)
	}
	bubbleChart(chartElement,widgetdetails){
			if(this.value==1){
				this.wigetBackgroundColor = this.backColor;
			}
			if(this.value == 2){
				this.wigetBackgroundColor ="#C8E6C9";
			}
			
			let options = {
				data: widgetdetails.data,
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
				label: widgetdetails.keyLabel,
				value: widgetdetails.keyGroup
				},
				chartTitle:widgetdetails.title,
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				BGcolor:this.wigetBackgroundColor,
				drillDownColumnName:"",
				showTable: false,
				showComments: false,
				comments:"",
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { acinfotech.bubbleChart(chartElement, options) },1000)
	}
	stackedBarChart(chartElement,widgetdetails){
			if(this.value == 1){
				this.wigetBackgroundColor = this.backColor;
			}
			if(this.value ==2){
				this.wigetBackgroundColor = "#FFF9C4";
			}
				let options = {
					data: widgetdetails.data,
					dataForDrillDown: [],
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: widgetdetails.keyLabel, // X-Axis labels
						stackedBars: widgetdetails.keyGroup
					},
					chartTitle:widgetdetails.title,
					xAxisLable:widgetdetails.xLable,
					yAxisLable:widgetdetails.yLable,
					showTitle: true,
					colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
					BGcolor:this.wigetBackgroundColor,
					lineStyle: "cardinal", // "cardinal" or "linear" or "step"
					drillDownColumnName:"",
					showTable: false,
					showComments: false,
					comments:"",
					resizeButtonID: document.getElementById("resizeBtn").id
				}
					
				setTimeout(() => { acinfotech.stackedBarChart(chartElement, options) },1000)
			
	}
	horizontalGroupedBarChart(chartElement,widgetdetails){
			if(this.value ==1){
				this.wigetBackgroundColor = this.backColor;
			}

			let options = {
				data: widgetdetails.data,
				dataForDrillDown: [],
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label:widgetdetails.keyLabel,
					groupBars:widgetdetails.keyGroup,
				},
				chartTitle:widgetdetails.title,
				xAxisLable: widgetdetails.xLable,
				yAxisLable:widgetdetails.yLable,
				showTitle: true,
				colorSet: this.selectedChartsColorSet,
				BGcolor:this.wigetBackgroundColor, //"one" or "two" or "three";
				drillDownColumnName:"",
				showTable: false,
				showComments: false,
				comments:"",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			
			setTimeout(() => { acinfotech.horizontalGroupedBarChart(chartElement, options) },1000)
		
	}
	map(chartElement,widgetdetails){
			if(this.value == 1){
				this.wigetBackgroundColor = this.backColor;
			}
			if(this.value == 2){
			this.wigetBackgroundColor ="#e1d0e8";
			}
			let options = {
				data: widgetdetails.data,
				keys: {
				  label: widgetdetails.keyLabel,
				  value: widgetdetails.keyGroup
				},
				chartTitle: widgetdetails.title,
				showTitle: true,
				maximize:true,
				colorSet: this.selectedChartsColorSet,
				BGcolor:this.wigetBackgroundColor,
				drillDownColumnName:"",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { acinfotech.map(chartElement, options)},1000)
		
	}
	numberGauge(chartElement,widgetdetails){
			if(this.value == 1){
				this.wigetBackgroundColor = this.backColor;
				
			}
			if(this.value == 2){
				this.wigetBackgroundColor ="#ebb9b9";
			}
			
			let options = {
				data: widgetdetails.data[0].Number_of_employees,
				chartTitle: widgetdetails.title,
				showTitle: true,
				BGcolor:this.wigetBackgroundColor,
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { acinfotech.numberGauge(chartElement, options)},1000)
	
	}
	lineChart(chartElement,widgetdetails){
			if(this.value == 1){
				this.wigetBackgroundColor = this.backColor;
			}
			if(this.value == 2){
				this.wigetBackgroundColor ="#B2DFDB";
			}
				let options = {
					data: widgetdetails.data,
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: widgetdetails.keyLabel, // X-Axis labels
						lines:widgetdetails.keyGroup
					},
					chartTitle: widgetdetails.title,
					xAxisLable: widgetdetails.xLable,
					yAxisLable: "",
					showTitle: true,
					colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
					BGcolor:this.wigetBackgroundColor,
					lineStyle: "linear", // "cardinal" or "linear" or "step"
					drillDownColumnName:"",
					showTable: false,
					showComments: false,
					comments:"",
					resizeButtonID: document.getElementById("resizeBtn").id
				}
				
				setTimeout(() => { acinfotech.lineChart(chartElement, options) },1000)
	}
	table(chartElement,widgetdetails){
			let options = {
				data: widgetdetails.data,
				chartTitle: widgetdetails.title,
				showTitle: true,
				drillDownColumnName:"",
				fontStyle: "Normal", // Normal, Italic
				fontWeight: "Bold", // Normal, Bold
				fontFamily: "inter", // inter, Century Gothic, Candara, Verdana, Trebuchet MS, Segoe UI
				resizeButtonID: document.getElementById("resizeBtn").id 
			}
			setTimeout(() => {
				const keys = Object.keys(options.data[0]);
				console.log("keys",keys)
				keys.forEach((key, index) => {
			  	let mappedColumn = {
				headerName: key.toUpperCase(),
				field: key,
				checked:true
			  }
			  this.coldata.push(mappedColumn);
			  });
			  this.empdata=options.data;
			  this.columnDefs=this.coldata;
			  this.rowData=options.data;
			  this.showGrid=true;
			},1000); 
	}
	finalCall(){
		this.finalCallCounter++;

		if(this.finalCallCounter == this.totalWidgets.length){ // when all widgets service calling completes
			
			d3.select(".tibo-mydash-dash-spinner").style("display","none") // hidding dashboard spinner
			
			d3.selectAll(".widget-class")[0].map(function(d, ind){ 
				if(d3.select(d).attr('empty') == "true"){
				    //d3.select(d).remove()
				}
				else {
					d3.select(d).style("visibility","visible")
					d3.select(d).select("p-progressspinner").remove();
				}
			})

         
		}
		
	}

	chartsDivContainerClick(event){
		console.log("event",event);
		let filteredData=[];
		let filterTableData=[];
		// setTimeout(() => {d3.selectAll("#chart14").remove();},1000);
		// setTimeout(() => {d3.selectAll("#chart15").remove();},1000);
		if((event.target.nodeName=="path") && (event.srcElement.farthestViewportElement.parentElement.id == "chart2")){
			setTimeout(() => {d3.selectAll("#chart15").remove();},500);
			let datas=[
				{
				 "YEAR_VALUE": "2021",
				 "MONTH": "JANUARY",
				 "MONTH_VALUE": "01",
				 "AVERAGE_DURATION": 88
				},
				{
				 "YEAR_VALUE": "2021",
				 "MONTH": "FEBRUARY",
				 "MONTH_VALUE": "02",
				 "AVERAGE_DURATION": 52
				},
				{
				 "YEAR_VALUE": "2021",
				 "MONTH": "MARCH",
				 "MONTH_VALUE": "03",
				 "AVERAGE_DURATION": 64
				},
				{
				 "YEAR_VALUE": "2021",
				 "MONTH": "APRIL",
				 "MONTH_VALUE": "04",
				 "AVERAGE_DURATION": 63
				},
				{
				 "YEAR_VALUE": "2021",
				 "MONTH": "MAY",
				 "MONTH_VALUE": "05",
				 "AVERAGE_DURATION": 54
				},
				{
				 "YEAR_VALUE": "2021",
				 "MONTH": "JUNE",
				 "MONTH_VALUE": "06",
				 "AVERAGE_DURATION": 57
				},
				{
				 "YEAR_VALUE": "2021",
				 "MONTH": "JULY",
				 "MONTH_VALUE": "07",
				 "AVERAGE_DURATION": 62
				},
				{
				 "YEAR_VALUE": "2021",
				 "MONTH": "AUGUST",
				 "MONTH_VALUE": "08",
				 "AVERAGE_DURATION": 75
				},
				{
				 "YEAR_VALUE": "2021",
				 "MONTH": "SEPTEMBER",
				 "MONTH_VALUE": "09",
				 "AVERAGE_DURATION": 75
				},
				{
				 "YEAR_VALUE": "2021",
				 "MONTH": "OCTOBER",
				 "MONTH_VALUE": "10",
				 "AVERAGE_DURATION": 62
				},
				{
				 "YEAR_VALUE": "2021",
				 "MONTH": "NOVEMBER",
				 "MONTH_VALUE": "11",
				 "AVERAGE_DURATION": 59
				},
				{
				 "YEAR_VALUE": "2021",
				 "MONTH": "DECEMBER",
				 "MONTH_VALUE": "12",
				 "AVERAGE_DURATION": 60
				},
				{
				 "YEAR_VALUE": "2022",
				 "MONTH": "JANUARY",
				 "MONTH_VALUE": "01",
				 "AVERAGE_DURATION": 67
				},
				{
				 "YEAR_VALUE": "2022",
				 "MONTH": "FEBRUARY",
				 "MONTH_VALUE": "02",
				 "AVERAGE_DURATION": 72
				},
				{
				 "YEAR_VALUE": "2022",
				 "MONTH": "MARCH",
				 "MONTH_VALUE": "03",
				 "AVERAGE_DURATION": 63
				},
				{
				 "YEAR_VALUE": "2022",
				 "MONTH": "APRIL",
				 "MONTH_VALUE": "04",
				 "AVERAGE_DURATION": 75
				},
				{
				 "YEAR_VALUE": "2022",
				 "MONTH": "MAY",
				 "MONTH_VALUE": "05",
				 "AVERAGE_DURATION": 69
				},
				{
				 "YEAR_VALUE": "2022",
				 "MONTH": "JUNE",
				 "MONTH_VALUE": "06",
				 "AVERAGE_DURATION": 68
				},
				{
				 "YEAR_VALUE": "2022",
				 "MONTH": "JULY",
				 "MONTH_VALUE": "07",
				 "AVERAGE_DURATION": 66
				},
				{
				 "YEAR_VALUE": "2022",
				 "MONTH": "AUGUST",
				 "MONTH_VALUE": "08",
				 "AVERAGE_DURATION": 60
				},
				{
				 "YEAR_VALUE": "2022",
				 "MONTH": "SEPTEMBER",
				 "MONTH_VALUE": "09",
				 "AVERAGE_DURATION": 66
				},
				{
				 "YEAR_VALUE": "2022",
				 "MONTH": "OCTOBER",
				 "MONTH_VALUE": "10",
				 "AVERAGE_DURATION": 63
				},
				{
				 "YEAR_VALUE": "2022",
				 "MONTH": "NOVEMBER",
				 "MONTH_VALUE": "11",
				 "AVERAGE_DURATION": 61
				},
				{
				 "YEAR_VALUE": "2022",
				 "MONTH": "DECEMBER",
				 "MONTH_VALUE": "12",
				 "AVERAGE_DURATION": 63
				},
				{
				 "YEAR_VALUE": "2023",
				 "MONTH": "JANUARY",
				 "MONTH_VALUE": "01",
				 "AVERAGE_DURATION": 74
				},
				{
				 "YEAR_VALUE": "2023",
				 "MONTH": "FEBRUARY",
				 "MONTH_VALUE": "02",
				 "AVERAGE_DURATION": 75
				},
				{
				 "YEAR_VALUE": "2023",
				 "MONTH": "MARCH",
				 "MONTH_VALUE": "03",
				 "AVERAGE_DURATION": 77
				},
				{
				 "YEAR_VALUE": "2023",
				 "MONTH": "APRIL",
				 "MONTH_VALUE": "04",
				 "AVERAGE_DURATION": 59
				}
			   ]
			this.showDialog=true;
			filteredData = datas.filter((d) => d.YEAR_VALUE === event.target.__data__.data.YEAR_VALUE);
			console.log("event",filteredData);
			this.firstClick=true;
			this.secondClick=false;
			this.title="Average Duration";
			setTimeout(() => {
			this.chartElement14= d3.select('#chart14')
			this.detail14={
					data:filteredData,
					title:" ",
					keyLabel:"MONTH",
					keyGroup: "AVERAGE_DURATION",
					xLable: "MONTH",
					yLable: "AVERAGE_DURATION",
			}},1000);
			setTimeout(() => {this.pieChart(this.chartElement14, this.detail14)},1500); 
		}
		if((event.target.nodeName=="path") && (event.srcElement.farthestViewportElement.parentElement.id == "chart14")){
			setTimeout(() => {d3.selectAll("#chart14").remove();},500);
			let datas=this.DrillTableData;
			this.showDialog=true;
			this.firstClick=false;
			this.secondClick=true;
			filterTableData=datas.filter((d) => (d.YEAR === event.target.__data__.data.YEAR_VALUE)&&(d.MONTH === event.target.__data__.data.MONTH));
			filterTableData.forEach(function(v){ delete v.YEAR ; delete v.MONTH});
			this.title="Average Duration";
			setTimeout(() => {
			this.chartElement15= d3.select('#chart15')
			this.detail15={
					data:filterTableData ,
					title:" ",
			}},1000);
			setTimeout(() => {this.table(this.chartElement15, this.detail15)},1500);
		}
	}
	navigateToCreate(){
		this.router.navigate(['/create-dashboard'])
		}
	
    selectcolor: boolean = false;

	
	filterPanelToggle() {
    
		if(this.filterPanelToggleBool == false){
			d3.selectAll("#parameterized-dashboard-filter-panel-scroll-div, #submitbutton-id").transition().delay(200).duration(400).style("display","none")
			d3.select("#dashboard-parameterized-filter").transition().delay(200).duration(500).style("width","40px")
			// d3.select("#dashboard-parameterized-scroll-view").transition().delay(200).duration(500).style("left","50px")//.style("width","calc(100% - 50px)")
			d3.select("#dashboard-parameterized-filter").select("button").style("transform","rotate(180deg)")
			d3.select("#filter-id").transition().delay(100).duration(100)
			.style({'left':'3px','top':'50px'}).style("transform","rotate(-90deg)")
			this.filterPanelToggleBool = true;
		}
		else {
			d3.select("#parameterized-dashboard-filter-panel-scroll-div").transition().delay(300).duration(400).style("display","block")
			 d3.select("#submitbutton-id").transition().delay(500).duration(400).style("display","block")
			d3.select("#dashboard-parameterized-filter").transition().delay(200).duration(500).style("width","350px")
			// d3.select("#dashboard-parameterized-scroll-view").transition().delay(200).duration(500).style("left","360px")//.style("width","calc(100% - 360px)")
			d3.select("#dashboard-parameterized-filter").select("button").style("transform","rotate(0deg)")
			d3.select("#filter-id").transition().delay(250).duration(100)
			.style({'left':'10px','top':'10px'}).style("transform","rotate(0deg)")
			this.filterPanelToggleBool = false;
		}
	}
	
	addMessages() {
        this.alertMessage = [ {severity:'error', summary:'Error', detail:"Please fill all required fields."} ];
    }

    clearMessages() { this.alertMessage = []; }

	getcolor(event, index){
		this.buttonIndex = index;
  		console.log(this.buttonIndex)
		  this.showClass=true;
		  if(event == this.colorOne){
			  this.value=2;
			  this.allColorButton = true;
			  this.reloadDashboardWithNewColorSetFunc();
		  }
		  else{
			  this.value=1;
			  this.backColor=event;
			  this.wigetBackgroundColor = this.backColor;
			  this.allColorButton = false;
			  this.reloadDashboardWithNewColorSetFunc();
		  }
		  //this.getDashboard("");
	  }

	addSingle() { this.messageService.add({severity:'error', summary:'', detail:this.errorMsg}); }

    clear() { this.messageService.clear(); }
	
	changeDashboardFunc(event){
		console.log("eve",event)
        this.selectedDashboard = event;
		this.getDashboard("")
		
		d3.select("#tibo-mydash-dashlist-container-id").style("display","none")
		d3.select("#tibo-mydash-dashlist-container").style("display","none")
		dashListPanelToggleBool = false;
		
    }
	
	optionsFunc(event){
		
		 if(event.item.id == 2){
			
			

			if(this.moreItems[0].label == "Turn on page refresh"){
				
				this.moreItems[0].label = "Turn off page refresh"
				this.moreItems[0].icon = "pi pi-times"
				this.refreshTimerFunc()
			}
			else {
				
				this.moreItems[0].label = "Turn on page refresh"
				this.moreItems[0].icon = "pi pi-refresh"
				
				clearInterval(this.refreshInterval);
				this.countDownLabel.innerHTML = "";
				
			}
			
		}
		else if(event.item.id == 3){
			this.shareDashDialBoxDisplayBool = true;
		}
		else if(event.item.id == 4){
			this.selectedChartsColorSet = "one";
			this.getDashboard("");
			//this.reloadDashboardWithNewColorSetFunc();
		}
		else if(event.item.id == 5){
			this.selectedChartsColorSet = "two";
			this.getDashboard("");
			//this.reloadDashboardWithNewColorSetFunc();
		}
		else if(event.item.id == 6){
			this.selectedChartsColorSet = "three";
			this.getDashboard("");
			//this.reloadDashboardWithNewColorSetFunc();
		}
	}

	showLegend(event){
		console.log(event);
		if(event.item.id == 7){
			if(this.showOrHideSeriesBool){	
			d3.selectAll(".series_value").style("display","block");
			
			event.item.label = "Hide series Values";
				event.item.icon = "pi pi-eye-slash"
			this.showOrHideSeriesBool = false;
			}
			else{
				
				d3.selectAll(".series_value").style("display","none");
				this.showOrHideSeriesBool = true;
				event.item.label = "Show series Values";
			event.item.icon = "pi pi-eye"
			}
		}


		else if(event.item.id == 8){
		  if(this.showOrHideLegendBool){
			this.showOrHideLegendBool = false;
			d3.selectAll(".legend_value").style("display","block");
			
			event.item.label = "Hide Legend Values";
			event.item.icon = "pi pi-eye-slash"
		  }
		  else{
			this.showOrHideLegendBool = true;
			d3.selectAll(".legend_value").style("display","none");
			event.item.label = "Show Legend Values"
			event.item.icon = "pi pi-eye"
		}
		}
	}

  changeChartColorsFunc(colorSet){
		if(colorSet == "one"){
			this.selectedChartsColorSet = "one";
			//this.getDashboard("");
			this.reloadDashboardWithNewColorSetFunc();
		}
		else if(colorSet == "two"){
			this.selectedChartsColorSet = "two";
			// this.getDashboard("");
			this.reloadDashboardWithNewColorSetFunc();
		}
		else if(colorSet == "three"){
			this.selectedChartsColorSet = "three";
			// this.getDashboard("");
			this.reloadDashboardWithNewColorSetFunc();
		}
	}

	shareDashTabChanFunc(e){
		//this.shareDashUserNamesArr = [], this.shareDashSrchUserNamesArr = [], this.usersArray = [], this.shareDashEmailIdsArr = [];
		e.index == 0 ? this.shareDashTabIndex = 0 : this.shareDashTabIndex = 1;
	}
	
	search(event) {
        
		this.usersArray = [];
		
		this.usersNameSubscrip = this.dashService.usersNameService(event.query).subscribe( (resp: any) => {
			resp.body?.records.map(x => this.usersArray.push({ userName: x.userName, userId: x.userId }));
			this.showSuggestions(this.usersArray)
		}, error => { 
			if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
			else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
			else { this.errorMsg = "Please try after some time." }
			
			this.addSingle()
		}, () => {}) // get users name service
		
	}
	
	showSuggestions(usersArray){
		this.shareDashSrchUserNamesArr = [];
		usersArray.map(x => this.shareDashSrchUserNamesArr.push(x.userName));
	}
	
	shareDashFunc(event){
		
		//this.shareDashUserNamesArr = [];
		let ids = [];
		
		if(this.shareDashTabIndex == 0){

			if(this.shareDashUserNamesArr.length != 0){
				
				this.shareDashDialBoxDisplayBool = false;

				this.shareDashUserNamesArr.forEach(user => {
					this.usersArray.forEach(d => {
						if(user == d.userName){
							ids.push(Number(d.userId))
						}
					})
				})
				
				//console.log({"userId":ids,"emailId":[],"dashboardId":[Number(this.selectedDashboard.code)]})
				
				this.shareDashSubscrip = this.dashService.shareDashboardService({"userId":ids,"emailId":[],"dashboardId":[Number(this.selectedDashboard.code)]}).subscribe( (resp: any) => {
					//resp.body?.records.map(x => this.usersArray.push({ userName: x.userName, userId: x.userId }));
					
					if(resp.status == 200){
						this.shareDashSuccMsg = "Dashboard shared successfully!";
						this.successAlertMsgFunc()
					}
					
				}, error => { 
					if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
					else { this.errorMsg = "Please try after some time." }
					
					this.addSingle()
				}, () => {}) // share the dashboard service
			}
			else {
				this.errorMsg = "Please enter User Name(s)!"
				this.addSingle()
			}
			
			this.shareDashUserNamesArr = [], this.shareDashSrchUserNamesArr = [], this.usersArray = [], this.shareDashEmailIdsArr = []; // clear all users name and email ids arrays

		}
		else if(this.shareDashTabIndex == 1){
			
			if(this.shareDashUserNamesArr.length != 0){

				this.shareDashDialBoxDisplayBool = false;
			
				this.shareDashSubscrip = this.dashService.shareDashboardService({"userId":[],"emailId":this.shareDashEmailIdsArr,"dashboardId":[Number(this.selectedDashboard.code)]}).subscribe( (resp: any) => {
					//resp.body?.records.map(x => this.usersArray.push({ userName: x.userName, userId: x.userId }));
					
					if(resp.status == 200){
						this.shareDashSuccMsg = "Dashboard shared successfully!";
						this.successAlertMsgFunc()
					}
					
				}, error => { 
					if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
					else { this.errorMsg = "Please try after some time." }
					
					this.addSingle()
				}, () => {}) // share the dashboard service

			}
			else {
				this.errorMsg = "Please enter Email Id(s)!"
				this.addSingle()
			}
			
			this.shareDashUserNamesArr = [], this.shareDashSrchUserNamesArr = [], this.usersArray = [], this.shareDashEmailIdsArr = []; // clear all users name and email ids arrays
		}
		
	}
	
	dialogBoxOnCloseFunc(event){
		this.shareDashTabIndex = 0;
		this.shareDashUserNamesArr = [], this.shareDashSrchUserNamesArr = [], this.usersArray = [], this.shareDashEmailIdsArr = []; // clear all users name and email ids arrays
	}
	
	successAlertMsgFunc(){
		this.messageService.add({severity:'success', summary:'', detail:this.shareDashSuccMsg });
	}
	
	dashboardListFunc(){
		
		
		
		d3.select("#tibo-mydash-dashlist-container-id").style("display") == "none" ? d3.select("#tibo-mydash-dashlist-container-id").style("display","block") : d3.select("#tibo-mydash-dashlist-container-id").style("display","none")
		d3.select("#tibo-mydash-dashlist-container-id").select("p-panelmenu").selectAll("div > div.p-panelmenu-header > a.p-panelmenu-header-link").attr("non-hide","non-hide");
		d3.select("#tibo-mydash-dashlist-container-id").select("p-panelmenu").selectAll("div > div.p-panelmenu-header > a.p-panelmenu-header-link > span").attr("non-hide","non-hide");
		
	}
	dashboardListFunc1(){
		d3.select("#tibo-mydash-dashlist-container-id1").style("display") == "none" ? d3.select("#tibo-mydash-dashlist-container-id1").style("display","block") : d3.select("#tibo-mydash-dashlist-container-id").style("display","none")
		d3.select("#tibo-mydash-dashlist-container-id1").select("p-panelmenu").selectAll("div > div.p-panelmenu-header > a.p-panelmenu-header-link").attr("non-hide","non-hide");
		d3.select("#tibo-mydash-dashlist-container-id1").select("p-panelmenu").selectAll("div > div.p-panelmenu-header > a.p-panelmenu-header-link > span").attr("non-hide","non-hide");
	}

	
	dashboardListFuncs(){
	
		d3.select("#tibo-mydash-dashlist-container").style("display") == "none" ? d3.select("#tibo-mydash-dashlist-container").style("display","block") : d3.select("#tibo-mydash-dashlist-container").style("display","none")
		
		d3.select("#tibo-mydash-dashlist-container").select("p-panelmenu").selectAll("div > div.p-panelmenu-header > a.p-panelmenu-header-link").attr("non-hide","non-hide");
		d3.select("#tibo-mydash-dashlist-container").select("p-panelmenu").selectAll("div > div.p-panelmenu-header > a.p-panelmenu-header-link > span").attr("non-hide","non-hide");
	}
	visitedDashFunc(dashboardName){
		this.selectedDashboard = dashboardName;
		this.getDashboard("")
	}
	
	refreshTimerFunc(){
		//this.refreshCountDownTimerStr = this.dashboardRefreshTime + ":" + "00";
		//let countDownLabel = this.countDownLabel;
		let thisClass = this; // assinging compoment class object to a variable
		let minutes = this.dashboardRefreshTime == undefined ? 0 : this.dashboardRefreshTime;
		let counter = 60;
		let strMin, strSec;
		
		this.refreshCountDownTimerStr = minutes + ":" + "00";
		this.countDownLabel.innerHTML = this.refreshCountDownTimerStr;
		
		minutes--;

		this.refreshInterval = setInterval(function() {
			
			counter = counter - 1;
			
			if(String(minutes).length == 1){ strMin = "0" + minutes; }
			else { strMin = minutes; }
			
			if(String(counter).length == 1){ strSec = "0" + counter; }
			else{
				if(counter == 60){ strSec = "00"; }
				else { strSec = counter; }
			}
			
			this.refreshCountDownTimerStr = minutes + ":" + strSec;
			thisClass.countDownLabel.innerHTML = this.refreshCountDownTimerStr;
			
			if (counter == 0) {
				minutes--;
				counter = 60;
			}
		  
			if (minutes < 0) {
				minutes = this.dashboardRefreshTime;
				this.refreshCountDownTimerStr = minutes + ":" + "00";
				thisClass.countDownLabel.innerHTML = this.refreshCountDownTimerStr;
				minutes--;
				thisClass.getDashboard("");
			}
			
		}, 1000);
	}

	// chartsDivContainerClick(event){ // drill thru
	// 	//console.log(document.querySelector(event.target.nodeName).closest("div.widget-class"))
	// 	//console.log(Object.values(event.path))
		
	// 	//console.log(event.target.querySelector(event.target.nodeName))
	// 	//console.log(event.path || (event.composedPath && event.composedPath()))
	// 	//console.log(event.target.attributes["data-drilldownkey"])
		

	// 	var chartDivObjCoor = "", clickedWidget = {};

	// 	if((event.target.nodeName.toLowerCase() == "rect") || (event.target.nodeName.toLowerCase() == "path") || 
	// 		(event.target.nodeName.toLowerCase() == "circle")){
	// 			if(event.target.attributes["data-drilldownkey"] != undefined){
	// 				//console.log(d3.select(event.target).attr("data-drilldownkey").split(","))
	// 				var drillThruKeyValuesArray = [], dataDrillDownKeyArray = d3.select(event.target).attr("data-drilldownkey").split(",");
	// 				for(var i = 0; i < dataDrillDownKeyArray.length; i++){
	// 					//console.log(dataDrillDownKeyArray[i].substr(dataDrillDownKeyArray[i].indexOf("|"),dataDrillDownKeyArray[i].length))
	// 					drillThruKeyValuesArray.push({"drillThruColumn":dataDrillDownKeyArray[i].substr(0,dataDrillDownKeyArray[i].indexOf("|")),
	// 					"value":dataDrillDownKeyArray[i].substr((dataDrillDownKeyArray[i].indexOf("|")+1),dataDrillDownKeyArray[i].length)})
	// 				}
	// 				//console.log(dataDrillDownKeyArray)
	// 				var nodes = [];
	// 				var element = event.target
	// 				nodes.push(element);
	// 				while(element.parentNode) {
	// 					nodes.push(element.parentNode);
	// 					element = element.parentNode;
	// 				}
					
	// 				//console.log(drillThruKeyValuesArray)
	// 				for(var i = 0; i < nodes.length; i++){
	// 					if(nodes[i].nodeName != undefined){
	// 						if(nodes[i].nodeName.toLowerCase() == "div"){
	// 							if(nodes[i].attributes["class"].value.includes("widget-class")){
	// 								//console.log(nodes[i].attributes["objectCordinates"].value)
	// 								chartDivObjCoor = nodes[i].attributes["objectCordinates"].value;
	// 							}
								
	// 						}
	// 					}
	// 				}
					
	// 				this.dashboardInfoObject?.widgetControls.forEach(widget => {
	// 					if(chartDivObjCoor == widget.objectCordinates){
	// 						//console.log(widget)
	// 						clickedWidget = widget;
	// 						this.showDrillThruOutputBtnClick(drillThruKeyValuesArray,clickedWidget)
	// 					}
	// 				});
					
	// 			}
	// 	}
	// 	else if(event.target.nodeName.toLowerCase() == "td"){
	// 		if(event.target.style["textDecoration"].toLowerCase() == "underline"){
	// 			var drillThruKeyValuesArray = [];
	// 			//console.log(event.target.offsetParent)
	// 			//console.log(event.target.offsetParent.firstChild.children)
	// 			for(var i = 0; i < event.target.offsetParent.firstChild.children.length; i++){
	// 				//console.log(event.target.offsetParent.firstChild.children)
	// 				//console.log(event.target.parentNode.children[i].innerText)
	// 				//drillThruTempObj["drillThruColumn"] = event.target.offsetParent.firstChild.children[i].innerText;
	// 				//drillThruTempObj["value"] = event.target.parentNode.children[i].innerText;
	// 				drillThruKeyValuesArray.push({"drillThruColumn":event.target.offsetParent.firstChild.children[i].textContent,
	// 				"value":event.target.parentNode.children[i].textContent});
	// 			}
				
	// 			//console.log(event.target.parentElement.rowIndex)
	// 			//console.log(event.target.parentNode.children)
				
	// 			var nodes = [];
	// 			var element = event.target
	// 			nodes.push(element);
	// 			while(element.parentNode) {
	// 				nodes.push(element.parentNode);
	// 				element = element.parentNode;
	// 			}

	// 			//console.log(drillThruKeyValuesArray)
	// 			for(var i = 0; i < nodes.length; i++){
	// 				if(nodes[i].nodeName != undefined){
	// 					if(nodes[i].nodeName.toLowerCase() == "div"){
	// 						if(nodes[i].attributes["class"].value.includes("widget-class")){
	// 							//console.log(nodes[i].attributes["objectCordinates"].value)
	// 							chartDivObjCoor = nodes[i].attributes["objectCordinates"].value;
	// 						}
							
	// 					}
	// 				}
	// 			}
				
	// 			this.dashboardInfoObject?.widgetControls.forEach(widget => {
	// 				if(chartDivObjCoor == widget.objectCordinates){
	// 					//console.log(widget)
	// 					clickedWidget = widget;
	// 					this.showDrillThruOutputBtnClick(drillThruKeyValuesArray,clickedWidget)
	// 				}
	// 			});
	// 		} 
	// 	} // td
	// }

	// showDrillThruOutputBtnClick(drillThruKeyValuesArray,clickedWidget) {
		
    //     this.ref = this.dialogService.open(DashboardChartComponent, {
	// 		data: {
	// 			drillThruKeyValuesArray: drillThruKeyValuesArray,
	// 			clickedWidget: clickedWidget,
	// 			selectedChartsColorSet: this.selectedChartsColorSet,
	// 			BGcolor:this.wigetBackgroundColor,
	// 		},
    //         header: clickedWidget?.chartTitle + " - Detail View",
    //         width: '100%',
	// 		height: '100%',
    //         contentStyle: {"min-width": "380px", "min-height": "350px", "height":"100%", "background-color":"#f1f1f4", "overflow": "hidden"},
    //         baseZIndex: 10000
    //     });

	// 	this.ref.onClose.subscribe(() => {
	// 		d3.select(".tibo-mydash-drill-thru-widget").select("*").remove();
	// 		console.log("Close")
	// 	});

	// }

	showBasicDialog() {
        this.displayBasic = true;
    }
	
	toggle(){
	
	
	}
	fullscreen(){
		debugger
			
			let canvas = document.getElementsByClassName('chartDiv') as HTMLCollectionOf<HTMLElement>;
			
			if(canvas[0].classList.contains("full-view-widget-class")){
				//this.moreItems[0].label = "Turn on page refresh"
				
				
				for(var i = 0; i < canvas.length; i++)
				{
					canvas[i].classList.remove('full-view-widget-class');
					this.moreItems[0].icon = "pi pi-th-large"
				}
				window.dispatchEvent(new Event('resize'));
				
			}
			else {
				//this.moreItems[0].label = "Turn off page refresh"
				
				
				for(var i = 0; i < canvas.length; i++)
				{
					canvas[i].classList.add('full-view-widget-class');
					
					
				}
				window.dispatchEvent(new Event('resize'));
				
			}
			
			//d3.selectAll(".widget-class").attr("class","widget-class-2 full-view-widget-class")
			//window.dispatchEvent(new Event('resize'));
			

		

	}
	binSelect(event,data){
		if(!event.target.checked){
		  this.gridOptions.columnApi.setColumnVisible(event.target.name,false);
		 }
		else{
		  this.gridOptions.columnApi.setColumnVisible(event.target.name,true);
		}
		data.checked=event.target.checked;
	   }
	  
	Refresh(data){
		data.forEach(d=>{
		  this.gridOptions.columnApi.setColumnVisible(d.field,true)
		  d.checked=true;
		});
	 }
	download1(){
		// this.Hdata=[];
		// this.Rdata=[];
		// this.Hdata=this.coldata;
		// this.Rdata=this.empData;
		this.download("Drilldown Data",this.coldata,this.empdata);
		
	   }
	download2(){
		console.log("hdata",this.coldata);
		console.log("rdata",this.empdata);
		this.Hdata=[];
		this.Rdata=[];
		let dat=this.coldata.filter(item=>item.checked != false);
		let arr=[];
		for(let i=0;i<dat.length;i++){
			arr[i]=dat[i].field;
		}
		const result1 = this.empdata.map(jsonData =>Object.entries(jsonData).filter(([key, value]) => arr.includes(key)));
		console.log("result",result1)
		var obj=[];
		for(let i=0;i<result1.length;i++){
		   obj[i]= result1[i].reduce(function(result, currentArray) {
			result[currentArray[0]] = currentArray[1];
			return result;
		}, {});
	  }
		// this.Hdata=dat;
		// this.Rdata=obj;
		// console.log("hdata",dat);
		// console.log("rdata",obj);
		this.download("Drilldown Data",dat,obj);
	}
	download(dat,hdata,rdata) {
	   // Excel Title, Header, Data
	  let arr=[];
	  let arr1=[];
	  let header =[];
	  for(let i=0;i<hdata.length;i++){
		arr[i]=hdata[i].headerName;
	  }
		const title = dat;
		header = arr;
		console.log(arr);
		for(let i=0;i<rdata.length;i++){
		  arr1[i]=Object.values(rdata[i]);
		}
		const data = arr1;
		console.log(data)
	
		// Create workbook and worksheet
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet('Sharing Data');
		// Add Row and formatting
		const titleRow = worksheet.addRow([title]);
		titleRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
		worksheet.addRow([]);
		// const subTitleRow = worksheet.addRow(['Date : 06-09-2020']);
		worksheet.mergeCells('A1:D2');
		// Blank Row
		worksheet.addRow([]);
		// Add Header Row
		const headerRow = worksheet.addRow(header);
		// Cell Style : Fill and Border
		headerRow.eachCell((cell, number) => {
	  cell.fill = {
		type: 'pattern',
		pattern: 'solid',
		fgColor: { argb: 'FFFFFF00' },
		bgColor: { argb: 'FF0000FF' }
	  };
	  cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
	});
	
	// Add Data and Conditional Formatting
		data.forEach(d => {
	  const row = worksheet.addRow(d);
	  let color = 'FF99FF99';
	 }
	);
		worksheet.getColumn(3).width = 30;
		worksheet.getColumn(4).width = 30;
		worksheet.addRow([]);
	// Generate Excel File with given name
		workbook.xlsx.writeBuffer().then((data: any) => {
	  const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
	  fs.saveAs(blob,title+'.xlsx');
	});
	  }
}
